
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Просмотр документа
      .px-4
        form(@submit.prevent="" :class="['form']")
          .row.g-2
            .col.mb-2
              app-select(
                ref="selectStatus"
                :type="'form'"
                :placeholder="'Выберите тип документа'"
                :options="typeOpts"
                @select="selectStatus"
              )
            .col-14.mb-2
              label.form__label.text-color--blue Название документа
                input(
                :class="['form__field', 'mt-2']"
                type="text"
                v-model="data.document_name"
                placeholder="Введите номер / название документа"
                :disabled="true"
                )
            .col-14.mb-2
              label.form__label.text-color--blue.mb-2 Работник
                input(
                  :class="['form__field', 'mt-2']"
                  type="text"
                  v-model="data.patient_full_name"
                  placeholder="Введите ФИО пользователя"
                  :disabled="true"
              )
            .col-14
              label.form__label.text-color--blue Организация выдавшая документ
                input(
                    :class="['form__field', 'mt-2']"
                    type="text"
                    v-model="data.med_org_name"
                    placeholder="Укажите организацию, выдавшую документ"
                    :disabled="true"
                )
            .col-14
              label.form__label.text-color--blue Комментарий клиента
                textarea(
                    class="form__field mt-2"
                    v-model="data.client_comment"
                    placeholder="Добавьте комментарий при необходимости"
                    :disabled="true"
                )
            .col-14(v-if='data.document_status==="confirmed" || data.document_status==="rejected"')
              label.form__label.text-color--blue Комментарий медработника
                textarea(
                    class="form__field mt-2"
                    v-model="data.doctor_comment"
                    placeholder="комментарий медработника"
                    :disabled="true"
                )
            .col-14.mt-3
              .col-7
                label.form__label.text-color--blue Дата выдачи документа
                  div(
                    class="mt-2"
                  )
                    date-picker(
                      prefix-class="app"
                      placeholder="Дата выдачи документа"
                      format="DD.MM.YYYY"
                      v-model="data.issue_date"
                      value-type="YYYY-MM-DD"
                      :default-value="new Date()"
                      :disabled-date="disabledBeforeToday"
                      :disabled="true"
                    )
              .col-7
                .form__file.d-flex.align-items-center.mt-4
                  input(
                    class="d-none"
                    type="file"
                    name="document"
                    accept="image/*"
                  )
                  div.form__file-element(v-if="data.file_link" class=['d-flex align-items-center', 'text-size--small'])
                    span.flex-shrink-0.me-3
                      so-icon(icon="image")
                    ul.flex-grow-1.overflow-hidden
                      li.form__file-name.text-color--black {{ data.file_link }}
                  .text-color--blue.text-size--small(v-else) {{ data.file_link }}
                  button(
                    class=['form__file-button', 'd-flex justify-content-end align-items-center']
                    @click="download(data)"
                  )
                    so-icon(icon="download")
        .col-14.mt-25
          button(
            type="submit"
            @click="close"
            class=['button', 'button--accent', 'form__button']
          )
            span.button__content Вернуться
