
section(
  class=['doctor-card', 'profile-card']
  :class="classes"
)
  .row.align-items-center.g-4
    div(v-for="column in columns" :key="column.key" :class="column.class")
      template(v-if="column.key === 'creation_date'")
        span {{ toLocalDate(data[column.key]) }}

      template(v-else-if="column.key === 'clients_count'")
        span(v-if="data[column.key]") {{ data[column.key] }}&nbsp;{{ declOfNum(data[column.key], ['клиент', 'клиента', 'клиентов']) }}
        span.text-color--blue(v-else) нет&nbsp;клиентов

      template(v-else-if="column.key === 'patients_count'")
        .d-flex.align-items-center
          span(v-if="data[column.key]") {{ data[column.key] }}&nbsp;{{ declOfNum(data[column.key], ['пользователь', 'пользователя', 'пользователей']) }}
          span.text-color--blue(v-else) нет&nbsp;пользователей

          span.ms-auto.text-color--accent
            so-icon(icon="right-arrow")

      template(v-else-if="column.key === 'email'")
        span(v-if="data[column.key]") {{ data[column.key] }}
        span.text-color--blue(v-else) нет&nbsp;e-mail

      template(v-else)
        span(v-if="data[column.key]") {{ data[column.key] }}
        span.text-color--blue(v-else) &#8212;

