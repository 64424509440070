import axios from '@/api/axios';

const getOutgoingMedDocuments = (credentials) => axios.get(`/api/v2/med_doc/outcoming_documents?${credentials}`);

const exportOutcomingMeddocs = (credentials) =>
  axios.get(`/api/v2/med_doc/export_outcoming_documents?${credentials}`, {responseType: 'blob'});


export default {
  getOutgoingMedDocuments,
  exportOutcomingMeddocs,
};
