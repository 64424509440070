<template>
<so-table
  model="MedOrganization"
  :columns="columns"
  :data='items'
  sort-key='creation_date'
  :canEdit="true"
  :canRemove="false"
  :pagination-data='{ total: 0, limit: 15 }'
>
</so-table>
</template>

<script>
import SoTable from '@/components/so/table/SoTable.vue';

export default {
  name: 'ModelTable',
  components: { SoTable },
  props: {
    model: String,
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    canEdit: { type: Boolean, default: true },
    canRemove: { type: Boolean, default: true },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    if (this.model) {
      this.loadData();
    }
  },
  methods: {
    handleSorting(key, inverse) {
      this.$emit('sortingRow', key, inverse);
    },
    handleEditModal(data) {
      this.$emit('edit-modal', data);
    },
    handleDeleteModal(data) {
      this.$emit('delete-modal', data);
    },
    handleCurrentPageChange(newPage) {
      this.$emit('change-current-page', newPage);
    },

    loadData() {
      this.loading = true;

      this.Reference[this.model]
        ._get()
        .then((response) => {
          if (response.statusText === 'OK' && response.data?.items) {
            return this.items = response.data.items;
          }

          throw new Error();
        })
        .catch((err) => console.warn(err))
        .finally(() => this.loading = false);
    }
  },
};
</script>
