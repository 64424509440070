<template lang="pug">
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Редактирование медработника<br>{{ doctor.full_name }}

        .px-md-4
          form(@submit.prevent :class="['form', {'is-submitting': isSubmitting}]")
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .row.g-4
              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }, {'is-changed': form.isChanged.firstName}]"
                    type="text"
                    v-model="v$.form.firstName.$model"
                    @keyup="changeField('firstName')"
                    placeholder="Имя"
                  )
                ul.form__error(v-if="v$.form.firstName.$errors.length")
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }, {'is-changed': form.isChanged.lastName}]"
                    type="text"
                    v-model="v$.form.lastName.$model"
                    @keyup="changeField('lastName')"
                    placeholder="Фамилия"
                  )
                ul.form__error(v-if="v$.form.lastName.$errors.length")
                  li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', {'is-changed': form.isChanged.middleName}]"
                    type="text"
                    v-model="form.middleName"
                    @keyup="changeField('middleName')"
                    placeholder="Отчество"
                  )

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.email.$errors.length }, {'is-changed': form.isChanged.email}]"
                    type="email"
                    v-model="v$.form.email.$model"
                    @keyup="changeField('email')"
                    placeholder="E-mail"
                  )
                ul.form__error(v-if="v$.form.email.$errors.length")
                  li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'email'") {{ v.errors.email }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.password.$errors.length }, {'is-changed': form.isChanged.password}]"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="v$.form.password.$model"
                    @keyup="changeField('password')"
                    placeholder="Новый пароль"
                  )
                  span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                    so-icon(v-if="showPassword" :icon="'eye-open'")
                    so-icon(v-else :icon="'eye-close'")
                ul.form__error(v-if="v$.form.password.$errors.length")
                  li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                    template(v-if="v$.form.password.$model")
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                      template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                      template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                      template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                      template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="v$.form.confirmPassword.$model"
                    placeholder="Повторите пароль"
                  )
                  span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                    so-icon(v-if="showPassword" :icon="'eye-open'")
                    so-icon(v-else :icon="'eye-close'")

                ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                  li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="(v$.form.confirmPassword.$model) && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

              .col-14.col-md-7
                .text-size--small.mb-3 Снилс медработника
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.snils.$errors.length }, {'is-changed': form.isChanged.snils}]"
                    type="text"
                    v-mask="'###-###-###-##'"
                    v-model="v$.form.snils.$model"
                    @keyup="changeField('snils')"
                    placeholder="СНИЛС"
                  )
                ul.form__error(v-if="v$.form.snils.$errors.length")
                  li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.snils.required }}
                    template(v-else-if="error.$validator === 'minLength'") {{ v.errors.snils.minLength }}
                    template(v-else-if="error.$validator === 'checkDigit'") {{ v.errors.snils.checkDigit }}

              .col-14.col-md-7
                .text-size--small.mb-3 Документ о медицинском образовании
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.seriesNumber.$errors.length }, {'is-changed': form.isChanged.seriesNumber}]"
                    type="text"
                    v-model="v$.form.seriesNumber.$model"
                    @keyup="changeField('seriesNumber')"
                    placeholder="Серия и номер"
                  )
                ul.form__error(v-if="v$.form.seriesNumber.$errors.length")
                  li(v-for="error of v$.form.seriesNumber.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="v$.form.seriesNumber.$model && error.$validator === 'valid'") {{ v.errors.invalid }}

              .col-14
                .text-size--small.mb-3 Сертификат о подготовке медработника
                .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                  input(
                    class="d-none"
                    type="file"
                    name="document"
                    @change="onFileSelected"
                    @click="v$.$touch()"
                    ref="fileInput"
                    accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                  )

                  div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                    span.flex-shrink-0.me-3
                      so-icon(icon="document")

                    ul.flex-grow-1.overflow-hidden
                      li.form__file-name.text-color--black {{ form.file.name ? form.file.name : 'Документ' }}
                      li.mt-1(v-if="form.file.size") {{ Number(form.file.size/1000000).toFixed(2) }} mb

                    button(
                      type="button"
                      @click="clearFileInput"
                      class=['form__file-delete', 'flex-shrink-0 ms-3']
                    )
                      so-icon(icon="trash")

                  .text-color--blue.text-size--small(v-else) Приложите скан документа

                  button(
                    class=['form__file-button', 'd-flex justify-content-end align-items-center']
                    @click="$refs.fileInput.click()"
                  )
                    so-icon(icon="clip")

                ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                  li(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .row.g-5.mt-0
              .col-14
                .mb-4
                  app-title Назначить в медорганизацию
                  p.text-size--small.text-color--blue.mt-2 Назначьте медработника в выбранную медорганизацию

                app-select(
                  ref="selectMedOrganization"
                  :type="'form'"
                  :placeholder="'Выберите'"
                  :options="medOrganizationsOptions"
                  @select="selectMedOrganization"
                )

            .col-14.mt-5
              button(
                type="submit"
                @click="onSubmit"
                :disabled="isFormValidAndUnchanged"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Сохранить

  // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Данные успешно изменены
        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeSuccessModal"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад
</template>

<script>
import doctorsApi from '@/api/doctors';
import medOrganizationsApi from '@/api/medOrganizations';
import {mapState} from 'vuex';

import AppLoading from '@/components/common/Loading';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppSelect from '@/components/common/Select';

import { mask } from 'vue-the-mask';
import {modalsMixin} from '@/mixins/modals';
import variables from '@/helpers/variables';
import useVuelidate from '@vuelidate/core';
import { isEmptyObj } from '@/helpers/utils';
import { required, requiredIf, minLength, sameAs, email } from '@vuelidate/validators';


export default {
  name: 'AppChangeDoctorModal',
  components: {
    AppLoading,
    AppValidationErrors,
    AppMultipleSelect,
    AppTitle,
    AppModal,
    AppSelect
  },
  directives: {
    mask,
  },
  mixins: [modalsMixin],
  props: {
    doctor: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
data() {
  return {
    doctorsUsers: [],
    v: variables,
    showPassword: false,
    localValidationErrors: null,
    form: {
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        password: '',
        confirmPassword: '',
        snils: '',
        seriesNumber: '',
        file: null,
        med_organization_id: '',
      isChanged: {
          firstName: false,
          lastName: false,
          middleName: false,
          email: false,
          password: false,
          snils: false,
          seriesNumber: false,
          file: false,
          med_organization_id: false,
      },
    },
  };
},
  watch: {
    isVisible: function(newValue) {
      if (newValue) {
        this.getDoctorsUsers();
        this.setInitialValues();
        this.v$.$touch();
        this.v$.$validate();
      }
    }
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.doctors.isSubmitting,
    }),
    medOrganizationsOptions() {
      return this.medOrganizations.map((org) => ({
        caption: `${org.name} - ${org.ogrn}`,
        value: org.id,
        selected: this.form.med_organization_id === org.id,
      }));
    },
    initialValues() {
      if (isEmptyObj(this.doctor)) return {};
      return {
        firstName: this.doctor.first_name,
        lastName: this.doctor.last_name,
        middleName: this.doctor.middle_name,
        email: this.doctor.email,
        snils: this.doctor.snils,
        seriesNumber: this.doctor.series_number,
        file: this.doctor.certificate,
        password: null,
        med_organization_id: this.doctor.med_organization_id,
      };
    },
    isChanged() {
      return Object.values(this.form.isChanged).some((item) => item === true);
    },
    isFormValidAndUnchanged() {
      return this.v$.$invalid || !this.isChanged;
    },
  },
  created() {
    this.loadMedOrganizations();
  },
  validations() {
    const v$ = useVuelidate();
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
        valid: function (value) {
          return !this.form.isChanged.password || this.v.regexes.password.test(value);
        },
        containsUppercase: function (value) {
          return !this.form.isChanged.password || /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return !this.form.isChanged.password || /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return !this.form.isChanged.password || /[0-9]/.test(value);
        },
        minLength: function (value) {
          return !this.form.isChanged.password || value.length >= 6;
        },
        },
        confirmPassword: {
          requiredIfPasswordChanged: requiredIf(() => this.form.isChanged.password),
          sameAsPassword: sameAs(this.form.password),
        },
        snils: {
          required,
          minLength: minLength(14),
          checkDigit: function (snils) {
            let result = false;
            snils = snils.replace(/-/g, '');
            let sum = 0;
            for (let i = 0; i < 9; i++) {
              sum += parseInt(snils[i]) * (9 - i);
            }
            let checkDigit = 0;
            if (sum < 100) {
              checkDigit = sum;
            } else if (sum > 101) {
              checkDigit = parseInt(sum % 101);
              if (checkDigit === 100) {
                checkDigit = 0;
              }
            }
            if (checkDigit === parseInt(snils.slice(-2))) {
              result = true;
            }
            return result
          },
        },
        seriesNumber: {
          required,
          valid: function (value) {
            return this.v.regexes.alphaNumeric.test(value);
          },
        },
        file: {
          required: requiredIf(() => Boolean(this.form.file)),
        },
      },
    }
  },
  methods: {
    async getDoctorsUsers() {
      try {
        const res = await doctorsApi.getDoctorUsersNoFilter(this.doctor.id);
        this.doctorsUsers = res.data.items.map(item => ({
          text: item.full_name,
          value: item.user_id
        }));
      } catch (error) {
        console.error('Error fetching doctor users:', error);
      }
    },
    setInitialValues() {
      if (!isEmptyObj(this.initialValues)) {
        for (let key in this.initialValues) {
          if (Object.prototype.hasOwnProperty.call(this.initialValues, key)) {
            this.form[key] = this.initialValues[key] || ''
          }
        }
      }
    },
    selectMedOrganization(option) {
      this.form.med_organization_id = option.value;
    },
    async loadMedOrganizations() {
      try {
        const result = await medOrganizationsApi.getMedOrganizations();
        this.medOrganizations = result?.data?.items || [];
      } catch {}
    },
    changeField(key) {
      let currentValue = this.form[key];
      if (typeof currentValue === 'string') {
        currentValue = currentValue.replace(/-/g, '');
      }
      const initialValue = this.initialValues[key];

      if (currentValue !== initialValue) {
        this.form.isChanged[key] = true;
        if (key === 'password') {
          this.form.isChanged.confirmPassword = true;
        }
      } else {
        this.form.isChanged[key] = false;
      }
    },
    updateSelectedUsers(data) {
      this.form.users = data.map((el) => el.value);
      this.changeField('users');
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
      this.changeField('file');
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    async onSubmit() {
      if (!this.v$.$invalid) {
        let str = this.form.snils;
        this.form.snils = str.replace(/[^+\d]/g, '');

        const formData = new FormData();
        formData.append('first_name', this.form.firstName);
        formData.append('middle_name', this.form.middleName);
        formData.append('last_name', this.form.lastName);
        formData.append('email', this.form.email);
        formData.append('snils', this.form.snils);
        formData.append('series_number', this.form.seriesNumber);
        formData.append('med_organization_id', this.form.med_organization_id);

        if (this.form.password) formData.append('password', this.form.password);

        if (this.form.file instanceof File)
          formData.append('certificate', this.form.file, this.form.file.name);

        try {
          await doctorsApi.changeDoctor(this.doctor.id, formData);
          this.close();
          this.$toast.success('Изменения сохранены');
          this.$emit('success')
        } catch (error) {
          this.localValidationErrors = error.request.statusText;
        }
      }
    },

    close() {
      this.resetForm();
      this.$emit('close');
    },
    closeSuccessModal() {
      this.closeModal('success');
      this.$emit('success');
    },
    resetForm() {
      this.v$.$reset();
      for (let key in this.form.isChanged) {
        if (Object.prototype.hasOwnProperty.call(this.form.isChanged, key)) {
          this.form.isChanged[key] = false;
        }
      }
      this.localValidationErrors = null;
      this.clearFileInput();
    },
  },
};
</script>
