<template lang="pug">
div
    transition(name="fade" mode="out-in")
        app-modal(v-if="isVisible" @close="close")
            template(v-slot:body)
                .text-center.mb-5
                    app-title(:type="'large'") Добавление пользователя
                    p.text-size--small.text-color--blue.mt-3 Для добавления одного пользователя воспользуйтесь формой ниже, если необходимо добавить больше одного пользователя, можете заполнить файл и загрузить для автоматического добавления
                .px-4
                    form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
                        app-tab-nav(
                            :tabs="[{id:'one', caption:'Один пользователь'}, {id:'several', caption: 'Несколько пользователей'}]"
                            :selected="activeTab"
                            :theme="'jointly'"
                            @selected="setActiveTab"
                            :classes="'mb-20'"
                        )
                        app-tab(:is-selected="activeTab === 'one'")
                            app-validation-errors(
                                v-if="localValidationErrors"
                                :validation-errors="localValidationErrors"
                                :classes="'mb-3'"
                            )
                            .row.g-4
                                .col-14(v-if="currentUser.role === 'ADMIN'")
                                  autocomplete(
                                    :search="search"
                                    placeholder="Введите название компании"
                                    aria-label="Введите название компании"
                                    :get-result-value="getResultValue"
                                    @submit="handleSubmit"
                                    )
                                .col-14.col-md-7(v-if="v$.form.firstName")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                                            type="text"
                                            v-model="v$.form.firstName.$model"
                                            placeholder="Имя"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7(v-if="v$.form.lastName")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }]"
                                            type="text"
                                            v-model="v$.form.lastName.$model"
                                            placeholder="Фамилия"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7
                                    label.form__label
                                        input(
                                            :class="['form__field']"
                                            type="text"
                                            v-model="form.middleName"
                                            placeholder="Отчество"
                                        )
                                .col-14.col-md-7(v-if="v$.form.snils")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.snils.$errors.length }]"
                                            type="text"
                                            v-mask="'###-###-###-##'"
                                            v-model="v$.form.snils.$model"
                                            placeholder="СНИЛС"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7(v-if="v$.form.drivingLicense")
                                  label.form__label
                                    input(
                                      :class="['form__field', { 'is-error': v$.form.drivingLicense.$errors.length }]"
                                      type="text"
                                      v-model="v$.form.drivingLicense.$model"
                                      @input="formatAndSetDriverLicense"
                                      :placeholder="v$.form.drivingLicense.$model ? formattedDriverLicense : 'Серия и номер ВУ'"
                                      :maxlength="v$.form.drivingLicense.$model.match(/^[А-Я]{2}/) ? 8 : 10"
                                    )
                                    ul.form__error(v-if="v$.form.drivingLicense.$errors.length")
                                        li(v-for="error of v$.form.drivingLicense.$errors" :key="error.$uid")
                                          template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                          template(v-if="v$.form.drivingLicense.$model && error.$validator === 'customValidation'") {{ v.errors.invalid }}

                                .col-14.col-md-7(v-if="v$.form.licenseDate")
                                    label(:class="['form__label', { 'is-error': v$.form.licenseDate.$errors.length }]")
                                        date-picker(
                                            v-model="v$.form.licenseDate.$model"
                                            prefix-class="app"
                                            placeholder="Дата выдачи"
                                            format="DD.MM.YYYY"
                                            :lang="datepicker.lang"
                                            :editable="true"
                                            :default-panel="datepicker.defaultValue.panel"
                                            :default-value="new Date()"
                                            :disabled-date="disableAfterToday"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.licenseDate.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                .col-14.col-md-7(v-if="v$.form.birthdate")
                                    label(:class="['form__label', { 'is-error': v$.form.birthdate.$errors.length }]")
                                        date-picker(
                                            v-model="v$.form.birthdate.$model"
                                            prefix-class="app"
                                            placeholder="Дата рождения"
                                            format="DD.MM.YYYY"
                                            :lang="datepicker.lang"
                                            :editable="true"
                                            :default-panel="datepicker.defaultValue.panel"
                                            :default-value="defaultDate"
                                            :disabled-date="disableBeforeAdult"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.birthdate.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                .col-14.col-md-7
                                    .row.align-items-center.g-4
                                        .col-7
                                            label(:class="['form__radio', { 'is-checked': form.gender === '1'}]")
                                                input(
                                                    type="radio"
                                                    name="gender"
                                                    value="1"
                                                    v-model="form.gender"
                                                )
                                                .d-flex.align-items-center
                                                    i.form__radio-icon.me-3.flex-shrink-0
                                                    span.form__radio-caption Мужской
                                        .col-7
                                            label(:class="['form__radio', { 'is-checked': form.gender === '2'}]")
                                                input(
                                                    type="radio"
                                                    name="gender"
                                                    value="2"
                                                    v-model="form.gender"
                                                )
                                                .d-flex.align-items-center
                                                    i.form__radio-icon.me-3.flex-shrink-0
                                                    span.form__radio-caption Женский

                                .col-14.col-md-14
                                    label.form__label
                                        input(
                                            :class="['form__field']"
                                            type="text"
                                            v-model="form.personal_number"
                                            placeholder="Табельный номер"
                                        )
                                .col-14.col-md-7(v-if="v$.form.email")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                                            type="email"
                                            v-model="v$.form.email.$model"
                                            placeholder="E-mail"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                                .col-14.col-md-7(v-if="v$.form.phone")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.phone.$errors.length }]"
                                            type="tel"
                                            v-model="v$.form.phone.$model"
                                            v-mask="'+7 (###) ###-##-##'"
                                            placeholder="Телефон"
                                        )
                                    ul.form__error
                                        li(v-for="error of v$.form.phone.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="v$.form.phone.$model && error.$validator === 'minLength'") {{ v.errors.invalid }}

                                .col-14.col-md-7(v-if="v$.form.password")
                                    .position-relative
                                        label.form__label
                                            input(
                                                :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                                                :type="showPassword ? 'text' : 'password'"
                                                v-model="v$.form.password.$model"
                                                placeholder="Пароль"
                                                autocomplete="nope"
                                            )
                                            span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                                                so-icon(v-if="showPassword" :icon="'eye-open'")
                                                so-icon(v-else :icon="'eye-close'")

                                    ul.form__error
                                        li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                                            template(v-if="v$.form.password.$model")
                                                template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                                                template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                                                template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                                                template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                                                template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                                .col-14.col-md-7(v-if="v$.form.confirmPassword")
                                    label.form__label
                                        input(
                                            :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                                            :type="showPassword ? 'text' : 'password'"
                                            v-model="v$.form.confirmPassword.$model"
                                            placeholder="Повторите пароль"
                                            autocomplete="nope"
                                        )
                                        span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                                            so-icon(v-if="showPassword" :icon="'eye-open'")
                                            so-icon(v-else :icon="'eye-close'")

                                    ul.form__error
                                        li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                                            template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                                            template(v-if="v$.form.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}
                                .col-14.col-md-7
                                    .form__file.d-flex.align-items-center
                                        input(
                                            class="d-none"
                                            type="file"
                                            name="document"
                                            @change="onAvatarSelected"
                                            @click="v$.$touch()"
                                            ref="avatarInput"
                                            accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                                        )

                                        div(
                                            v-if="form.avatar"
                                            class=['form__file-element', 'd-flex align-items-center', 'text-size--small']
                                        )
                                            app-loading(
                                                v-if="avatar.isCropping"
                                                :type="'inline'"
                                                :classes="'flex-shrink-0 me-3'"
                                            )
                                            span.flex-shrink-0.me-3(v-else)
                                                so-icon(icon="image")

                                            ul.flex-grow-1.overflow-hidden
                                                li.form__file-name.text-color--black {{ form.avatar.name }}
                                                li.mt-1 {{ Number(form.avatar.size/1000000).toFixed(4) }} mb

                                            button(
                                                type="button"
                                                @click="clearAvatarInput"
                                                class=['form__file-delete', 'flex-shrink-0 ms-3']
                                            )
                                                so-icon(icon="trash")

                                        .w-100.text-color--blue.text-size--small.text-center(v-else) Загрузите фотографию<br>пользователя

                                        button(
                                            class=['form__file-button', 'd-flex justify-content-end align-items-end align-items-md-center']
                                            @click="$refs.avatarInput.click()"
                                        )
                                            so-icon(icon="clip")

                                .col-14.col-md-7
                                    .form__file.d-flex.align-items-center
                                        input(
                                            class="d-none"
                                            type="file"
                                            name="document"
                                            @change="onSignatureSelected"
                                            @click="v$.$touch()"
                                            ref="signatureInput"
                                            accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                                        )

                                        div(
                                            v-if="form.signature"
                                            class=['form__file-element', 'd-flex align-items-center', 'text-size--small']
                                        )
                                            span.flex-shrink-0.me-3
                                                so-icon(icon="image")

                                            ul.flex-grow-1.overflow-hidden
                                                li.form__file-name.text-color--black {{ form.signature.name }}
                                                li.mt-1 {{ Number(form.signature.size/1000000).toFixed(4) }} mb

                                            button(
                                                type="button"
                                                @click="clearSignatureInput"
                                                class=['form__file-delete', 'flex-shrink-0 ms-3']
                                            )
                                                so-icon(icon="trash")

                                        .w-100.text-color--blue.text-size--small.text-center(v-else) Загрузите образец<br>подписи пользователя

                                        button(
                                            class=['form__file-button', 'd-flex justify-content-end align-items-end align-items-md-center']
                                            @click="$refs.signatureInput.click()"
                                        )
                                            so-icon(icon="clip")

                        app-tab(:is-selected="activeTab === 'several'")
                            app-error-message(
                                v-if="localValidationErrors"
                                :message="'Не удалось разобрать xlsx файл. Обратите внимание, что кодировка должна быть UTF-8'"
                                :classes="'mb-3'"
                            )
                            .row.align-items-center.justify-content-center.g-5
                                .col-14(v-if="currentUser.role === 'ADMIN'")
                                  autocomplete(
                                    :search="search"
                                    placeholder="Введите название компании"
                                    aria-label="Введите название компании"
                                    :get-result-value="getResultValue"
                                    @submit="handleSubmit"
                                  )
                                .col-auto
                                  button(
                                    v-if="activeTab !== 'one'"
                                    @click="downloadExample"
                                    type="button"
                                    class=['link--accent', 'text-size--small text-decoration--underline']
                                  )
                                    span Скачать файл для заполнения.xlsx

                                .col-14(v-if="v$.form.file")
                                    .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                                        input(
                                            class="d-none"
                                            type="file"
                                            name="document"
                                            @change="onFileSelected"
                                            @click="v$.$touch()"
                                            ref="fileInput"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        )
                                        div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                                            span.flex-shrink-0.me-3
                                                so-icon(icon="document")

                                            ul.flex-grow-1.overflow-hidden
                                                li.form__file-name.text-color--black {{ form.file.name }}
                                                li.mt-1 {{ Number(form.file.size/1000000).toFixed(4) }} mb

                                            button(
                                                type="button"
                                                @click="clearFileInput"
                                                class=['form__file-delete', 'flex-shrink-0 ms-3']
                                            )
                                                so-icon(icon="trash")

                                        .text-color--blue.text-size--small(v-else) Загрузите заполненный файл формата .xlsx

                                        button(
                                            class=['form__file-button', 'd-flex justify-content-end align-items-center']
                                            @click="$refs.fileInput.click()"
                                        )
                                            so-icon(icon="clip")

                                    ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                                        li {{ v.errors.required }}

                        .col-14.mt-25
                            button(
                                type="submit"
                                @click="onSubmit"
                                :disabled="v$.$invalid || avatar.isCropping"
                                class=['button', 'button--accent', 'form__button']
                            )
                                span.button__content {{ isOnePatientTab ? 'Добавить пользователя' : 'Загрузить пользователей' }}

    // cropper modal
    transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.cropper" @close="closeAvatarCropper" :classes="'full-content'")
            template(v-slot:body)
                cropper(
                    class=['profile-avatar__cropper', 'mt-4']
                    :class="{'is-loading': avatar.isCropping}"
                    :src="avatar.cropperImg"
                    :stencil-props="{aspectRatio: 1/1}"
                    :stencil-component="$options.components.CircleStencil"
                    ref="cropper"
                )
                .row.align-items-center.justify-content-center.mt-4.g-4
                    .col-auto
                        button(
                            type="button"
                            class=['button button--blue']
                            :disabled="avatar.isCropping"
                            @click="onAvatarCropping"
                        )
                            span.button__content Сохранить

                    .col-auto
                        button(
                            type="button"
                            class=['button button--border-blue']
                            :disabled="avatar.isCropping"
                            @click="closeAvatarCropper"
                        )
                            span.button__content Отмена
    // success modal
    transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
            template(v-slot:body)
                template(v-if="isOnePatientTab")
                    .text-center.pt-4
                        app-title(:type="'large'") Пользователь добавлен

                        .row.justify-content-center.mt-3
                            .col-14.col-xl-10
                                p.text-color--blue.text-size--small Пользователь может авторизоваться в приложении с помощью номера телефона или e-mail и указанного пароля.

                    .modal__patient-card.mt-10.mb-20
                        .modal__patient-card-status
                            span.text-color--success
                                so-icon(icon="allright")

                        .row.g-5
                            .col-auto
                                .d-flex.flex-column
                                    app-avatar(
                                        :localUrl="avatar.croppedImg"
                                        :classes="'middle'"
                                        :viewPhoto="true"
                                        @openGalley="openGallery"
                                    )
                                    app-signature(
                                        v-if="form.signature"
                                        :localUrl="signature.localUrl"
                                        :theme="'shadow'"
                                        :classes="'mt-4'"
                                        @openGalley="openGallery"
                                    )
                            .col
                                .row.g-4
                                    .col-7
                                        .text-color--blue.mb-2 ФИО
                                        span {{ form.lastName }} {{ form.firstName }} {{ form.middleName }}
                                    .col-7
                                        .text-color--blue.mb-2 Водительское удостоверение
                                        span(v-if='form.drivingLicense && form.licenseDate') {{ form.drivingLicense }} от {{ toLocalDate(form.licenseDate) }}
                                        span(v-else) -
                                    .col-7
                                        .text-color--blue.mb-2 Дата рождения
                                        span {{ toLocalDate(form.birthdate) }}
                                    .col-7
                                        .text-color--blue.mb-2 Пол
                                        span {{ form.gender == '1' ? 'Мужской' : 'Женский' }}
                                    .col-7
                                        .text-color--blue.mb-2 Телефон
                                        span {{ form.phone }}
                                    .col-7
                                        .text-color--blue.mb-2 Почта
                                        span {{ form.email }}
                                    .col-7
                                        .text-color--blue.mb-2 СНИЛС
                                        span {{ form.snils }}
                                    .col-7
                                        .text-color--blue.mb-2 Табельный номер
                                        span {{ form.personal_number }}
                                    .col-7(v-if="form.company_name")
                                        .text-color--blue.mb-2 Компания
                                        span {{ form.company_name }}

                template(v-else)
                    button(
                        @click="backToForm"
                        type="button"
                        class=['d-flex', 'modal__back-button']
                    )
                        so-icon(icon="back-arrow")

                    template(v-if="successData")
                        .row.justify-content-center.pt-4.mb-5
                            .col-14.col-xl-10
                                .text-center
                                  app-title(:type="'large'") {{ declOfNum(successData.upload, ['Загружен', 'Загружено', 'Загружено']) }}&nbsp;{{ successData.upload }} {{ declOfNum(successData.upload, ['пользователь', 'пользователя', 'пользователей']) }}&nbsp;из&nbsp;{{ successData.total }}
                                  div(v-if="successData.total !== successData.upload")
                                    p.text-size--small.text-color--blue.my-20 Подробности о том, какие пользователи не были загружены, содержатся в файле по ссылке ниже

                                    button(
                                        type="button"
                                        @click="getFile"
                                        class=['link--accent', 'text-decoration--underline']
                                    )
                                        span.text-size--small Скачать и посмотреть отчет о добавлении

                .d-flex
                    button(
                        @click="closeSuccessModal"
                        type="button"
                        class=['button button--border-blue', 'w-100']
                    )
                        span.button__content Перейти ко всем пользователям
</template>

<script>
import usersApi from '@/api/users';
import patientsApi from '@/api/patients';
import {mapState} from 'vuex';
import {eventBus} from '@/main';
import variables from '@/helpers/variables';
import {
  declOfNum,
  convertDate,
  convertPhone,
  toLocalDate,
  urlToFile,
  exportCSVFile,
} from '@/helpers/utils';
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import Autocomplete from '@trevoreyre/autocomplete-vue';

import useVuelidate from '@vuelidate/core';
import {required, sameAs, email, minLength} from '@vuelidate/validators';

import {EXIF} from 'exif-js';
import is from 'is_js';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import {CircleStencil, Cropper} from 'vue-advanced-cropper';

import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTabNav from '@/components/common/TabNav.vue';
import AppTab from '@/components/common/Tab.vue';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

import AppAvatar from '@/components/profile/common/Avatar';
import AppSignature from '@/components/profile/common/Signature';

import {mask} from 'vue-the-mask';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppCreatePatientsModal',
  directives: {
    mask,
  },
  components: {
    AppSignature,
    AppLoading,
    AppErrorMessage,
    AppValidationErrors,
    AppAvatar,
    AppTab,
    AppTabNav,
    AppModal,
    AppTitle,

    DatePicker,
    Cropper,
    CircleStencil,
    Autocomplete,
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      declOfNum,
      exportCSVFile,
      v: variables,
      isModalVisible: {
        cropper: false,
        success: false,
      },
      form: {
        firstName: '',
        lastName: '',
        middleName: '',
        drivingLicense: '',
        birthdate: '',
        gender: '1',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        avatar: null,
        signature: null,
        file: null,
        snils: '',
        licenseDate: '',
        personal_number: '',
        company_name: '',
      },
      activeTab: 'one',
      showPassword: false,
      localValidationErrors: null,
      avatar: {
        uploadFile: null,
        cropperImg: null,
        croppedImg: null,
        isCropping: false,
        exifdata: {},
      },
      signature: {
        localUrl: null,
      },
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        defaultValue: {
          panel: 'year',
          year: '1980',
        },
      },
      successData: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
    }),
    isOnePatientTab() {
      return this.activeTab === 'one';
    },
    defaultDate() {
      return new Date().setYear(this.datepicker.defaultValue.year);
    },
  },
  validations() {
    if (this.isOnePatientTab) {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          snils: {
            required,
            validateSnils(snils) {
              snils = snils.replace(/-/g, '');
              let result = false;
              if (typeof snils === 'number') {
                snils = snils.toString();
              } else if (typeof snils !== 'string') {
                snils = '';
              }
              if (!snils.length) {
                console.log('СНИЛС пуст');
              } else if (/[^0-9]/.test(snils)) {
                console.log('СНИЛС может состоять только из цифр');
                //error.message = 'СНИЛС может состоять только из цифр';
              } else if (snils.length !== 11) {
                console.log('СНИЛС может состоять только из 11 цифр');
                //error.message = 'СНИЛС может состоять только из 11 цифр';
              } else {
                let sum = 0;
                for (let i = 0; i < 9; i++) {
                  sum += parseInt(snils[i]) * (9 - i);
                }
                let checkDigit = 0;
                if (sum < 100) {
                  checkDigit = sum;
                } else if (sum > 101) {
                  checkDigit = parseInt(sum % 101);
                  if (checkDigit === 100) {
                    checkDigit = 0;
                  }
                }
                if (checkDigit === parseInt(snils.slice(-2))) {
                  result = true;
                } else {
                  //error.code = 4;
                  console.log('Неправильное контрольное число');
                  //	error.message = 'Неправильное контрольное число';
                }
              }
              return result;
            },
          },
          drivingLicense: {
            customValidation: (value) => {
              // Если значение пусто или состоит только из пробелов, принимаем его
              if (value.trim() === '') {
                return true;
              }

              const startsWithLetter = /^[А-Я]/.test(value);
              const startsWithDigit = /^\d/.test(value);
              if (startsWithLetter && /^[А-Я]{2}\s*\d{6}$/.test(value)) {
                // Для формата "АА 123456"
                return true;
              } else if (startsWithDigit && /^\d{2}\s*\d{2}\s*\d{6}$/.test(value)) {
                // Для формата "12 34 567891"
                return true;
              } else {
                return false;
              }
            },
          },
          licenseDate: {
          },
          birthdate: {
            required,
          },
          email: {
            required,
            email,
          },
          phone: {
            required,
            minLength: minLength(18),
          },
          password: {
            required,
            // valid: function (value) {
            //   return this.v.regexes.password.test(value);
            // },
            // containsUppercase: function (value) {
            //   return /[A-Z]/.test(value);
            // },
            // containsLowercase: function (value) {
            //   return /[a-z]/.test(value);
            // },
            // containsNumber: function (value) {
            //   return /[0-9]/.test(value);
            // },
            minLength: minLength(6),
          },
          confirmPassword: {
            required,
            sameAsPassword: sameAs(this.form.password),
          },
        },
      };
    } else {
      return {
        form: {
          file: {
            required,
          },
        },
      };
    }
  },
  mounted() {
    eventBus.$on('openGallery', (data) => {
      this.openGallery(data);
    });
  },
  methods: {
    //форматирование водительского удостоверения
    formatAndSetDriverLicense() {
      if (this.v$.form.drivingLicense.$model) {
        let value = this.v$.form.drivingLicense.$model.replace(/\s/g, '');
        // Если первые две буквы введены строчными, форматируем их как заглавные
        if (/^[а-я]{2}/.test(value)) {
          value = value.substr(0, 2).toUpperCase() + value.substr(2);
        }
        // Проверяем формат и приводим к нужному виду
        if (/^[А-Я]{2}\d{6}$/.test(value)) {
          value = `${value.substr(0, 2)} ${value.substr(2, 6)}`;
        } else if (/^\d{2}\d{8}$/.test(value)) {
          value = `${value.substr(0, 2)} ${value.substr(2, 2)} ${value.substr(4, 8)}`;
        }
        this.v$.form.drivingLicense.$model = value; // обновляем значение после форматирования
      }
    },
    formattedDriverLicense() {
      if (this.v$.form.drivingLicense.$model) {
        let value = this.v$.form.drivingLicense.$model.replace(/\s/g, '');
        if (/^[А-Я]{2}\d{6}$/.test(value)) {
          return `${value.substr(0, 2)} ${value.substr(2, 6)}`;
        } else if (/^\d{2}\d{8}$/.test(value)) {
          return `${value.substr(0, 2)} ${value.substr(2, 2)} ${value.substr(4, 8)}`;
        } else {
          return this.v$.form.drivingLicense.$model;
        }
      }
    },

    //поиск компании - автокомплит
    async search(input) {
      let res = await usersApi.getUsersSuggestionList(input);
      return res.data.items;
    },
    getResultValue(result) {
      this.form.company_name = result.name;
      return result.name;
    },
    handleSubmit(result) {
      this.form.user_id = result.id;
    },

    //скачать образец для заполнения
    async downloadExample() {
      let result = await patientsApi.getTemplateForLoadPatients();
      let frame = document.createElement('iframe');
      frame.src = result.data;
      frame['download'] = 1;
      document.body.appendChild(frame);
    },

    //загрузка и обрезка аватара
    onAvatarSelected({target}) {
      const reader = new FileReader();
      this.avatar.uploadFile = target.files[0];
      this.getMetaDataImage(this.avatar.uploadFile, 'avatar');
      reader.readAsDataURL(this.avatar.uploadFile);
      reader.onload = () => {
        this.avatar.cropperImg = reader.result;
        this.openModal('cropper');
      };
    },
    getMetaDataImage(file, variable) {
      if (file) {
        EXIF.getData(file, () => {
          this[variable].exifdata = file.exifdata;
        });
      }
    },
    onAvatarCropping() {
      const {canvas} = this.$refs.cropper.getResult();
      this.avatar.croppedImg = canvas.toDataURL();
      this.avatar.isCropping = true;
      urlToFile(
        this.avatar.croppedImg,
        this.avatar.uploadFile.name,
        this.avatar.uploadFile.type
      ).then((file) => {
        file.exifdata = this.avatar.exifdata;
        this.form.avatar = file;
        this.avatar.isCropping = false;
        this.closeModal('cropper');
      });
    },
    closeAvatarCropper() {
      this.closeModal('cropper');
      this.clearAvatarInput();
    },

    //загрузка подписи пациента
    onSignatureSelected({target}) {
      const reader = new FileReader();
      this.form.signature = target.files[0];
      reader.readAsDataURL(this.form.signature);
      reader.onload = () => (this.signature.localUrl = reader.result);
    },

    //обработка файла
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    getFile() {
      let frame = document.createElement('iframe');
      frame.src = this.successData.result_link;
      frame['download'] = 1;
      document.body.appendChild(frame);
    },

    //подтверждение
    async onSubmit() {
      if (!this.v$.$invalid) {
        let str = this.form.snils;
        this.form.snils = str.replace(/[^+\d]/g, '');

        const formDataAvatar = new FormData();
        const formDataSignature = new FormData();
        const formDataImportFile = new FormData();

        if (this.isOnePatientTab) {
          const formDataFull = {
            first_name: this.form.firstName,
            middle_name: this.form.middleName,
            last_name: this.form.lastName,
            gender: this.form.gender,
            serial_number_driving_license: this.form.drivingLicense,
            password: this.form.password,
            date_birth: convertDate(this.form.birthdate),
            email: this.form.email,
            phone: convertPhone(this.form.phone),
            driving_license_receiving: convertDate(this.form.licenseDate),
            snils: this.form.snils,
            personal_number: this.form.personal_number,
            user_id: this.form.user_id,
          };
          try {
            let result = await patientsApi.createPatients(formDataFull);
            let patient_code = result.data.code;
            if (is.not.null(this.form.avatar)) {
              formDataAvatar.append('data', this.avatar.uploadFile);
              await patientsApi.setPatientAvatar(patient_code, formDataAvatar);
            }
            if (is.not.null(this.form.signature)) {
              formDataSignature.append('image', this.form.signature);
              await patientsApi.setPatientSignature(patient_code, formDataSignature);
            }
            this.$emit('close');
            this.success(result);
          } catch (error) {
            if (error.response?.data?.detail) {
              this.$toast.error(error.response.data.detail);
            }
            this.localValidationErrors = this.validationErrors;
          }
        } else {
          try {
            formDataImportFile.append('template_csv', this.form.file);
            let result = await patientsApi.massCreatePatients(
              formDataImportFile,
              this.form.user_id ? this.form.user_id : null
            );
            this.success(result.data);
            this.$emit('success');
            this.$emit('close');
            this.resetForm();
          } catch (error) {
            this.localValidationErrors = this.validationErrors;
            this.$toast.error('Произошла ошибка');
          }
        }
      }
    },

    //очистка формы
    resetForm() {
      this.v$.$reset();
      for (let key in this.form) {
        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
          this.form[key] = '';
        }
      }
      this.form.gender = '1';
      this.localValidationErrors = null;
      this.clearFileInput();
      this.clearAvatarInput();
      this.clearSignatureInput();
    },
    clearFileInput() {
      this.form.file = null;
      if (this.$refs.fileInput) this.$refs.fileInput.value = '';
    },
    clearAvatarInput() {
      this.form.avatar = null;
      if (this.$refs.avatarInput) this.$refs.avatarInput.value = '';
    },
    clearSignatureInput() {
      this.form.signature = null;
      if (this.$refs.signatureInput) this.$refs.signatureInput.value = '';
    },

    //модалки
    close() {
      this.resetForm();
      this.$emit('close');
    },
    success(data) {
      if (!this.isOnePatientTab) this.successData = data;
      this.openModal('success');
      this.$emit('success');
    },
    closeSuccessModal() {
      this.closeModal('success');
      this.resetForm();
    },
    backToForm() {
      this.closeSuccessModal();
      this.$emit('open');
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },

    //Датапикер
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    disableBeforeAdult(date) {
      const today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    disableAfterTodayDrive(date) {
      const start = new Date(1930, 1, 1);
      start.setHours(0, 0, 0, 0);
      return date < start;
    },
    //общие функции
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.resetForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &::v-deep .app-datepicker .app-input {
    color: get($colors, black);
  }
}

.modal {
  &__patient-card {
    position: relative;
    @include font-size(get($text-sizes, small));
    padding: $spacer * 1.6;
    color: get($colors, black);
    background: get($gradients, blue-extra-light);
    border-radius: $border-radius-sm;

    &-status {
      position: absolute;
      top: $spacer;
      right: $spacer;
    }
  }
}
</style>
