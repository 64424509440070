<template>
<iconify-icon :icon='iconBody' v-bind='$attrs'/>
</template>

<script lang='ts'>
import { Icon as iconifyIcon } from '@iconify/vue2';
import soIcons from '@/assets/iconify/so.json';
import type { PropType } from 'vue';
import { computed } from 'vue';

export type SoIconsNames = keyof typeof soIcons['icons'];

export default {
  components: {
    iconifyIcon
  },
  props: {
    icon: String as PropType<SoIconsNames>
  },
  setup(props) {
    const iconBody = computed(() => {
      return soIcons.icons[props.icon as SoIconsNames];
    });

    return {
      iconBody
    };
  }
};
</script>
