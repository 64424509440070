
.profile-exams__inner
  .profile-exams__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'profile__title text-center'"
        ) Журнал осмотров

    .profile-exams__content.mt-20.position-relative
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      transition(name="translucent")
      template(v-if="columns")
        .profile-table
          .profile-table__header.d-none.d-xl-block
            .row.align-items-center.g-0
              div(v-for="column in columns" :key="column.key" :class="column.class")
                button(
                  v-if="column.caption || column.icon"
                  type="button"
                  @click="sortingExams(column.key)"
                  class=['profile-table__sort-button']
                  :class="{'is-active': sortKey === column.key}"
                )
                  span.d-flex.align-items-center
                    span(v-if="column.caption") {{ column.caption }}
                    span.flex-shrink-0(v-else-if="column.icon")
                      so-icon(:icon="column.icon")
                    span.flex-shrink-0.d-flex.ms-3
                      so-icon(icon="down-arrow")

          ul.profile-table__body
            li.profile-table__row(v-for="exam in exams" :key="exam.exams_id")
              router-link(:to="{name: 'doctor-card-revieved', params: {id: exam.exams_id}}" class=['d-block'])
                app-doctor-exam-card(
                  :data="exam"
                  :columns="columns"
                )

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )
      template(v-else)
        .text-color--blue.text-size--small Осмотров не найдено

