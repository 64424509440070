<template lang="pug">
  .profile-mobile-tab-bar__content.d-flex.align-items-center(v-click-outside="closeMenu")
    router-link(
      :to="{name: 'dashboard'}"
      :class="{'is-active': $route.name === 'dashboard'}"
      class=['d-flex align-items-center justify-content-center', 'profile-mobile-tab-bar__logo']
    )
      transition(name="fade")
        img(v-if="this.logoMobVersionSrc" :src="logotype" alt="SD")
        img(v-else src="@/assets/img/logo-mobile-gray.svg" alt="SD")

    .profile-mobile-tab-bar__menu
      .row.align-items-center.justify-content-around.flex-nowrap.gx-4.gx-md-5
        .col-auto
          router-link(
            :to="{name: 'requests'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block', 'position-relative']
            active-class="is-active"
          )
            so-icon(icon="add-circle")
            transition(name="translucent")
              span.profile-mobile-tab-bar__menu-notify(v-if="requestsPending.length")

        .col-auto
          router-link(
            :to="{name: 'devices'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            so-icon(icon="smartwatch" view-box="0 0 17 24")

        .col-auto
          router-link(
            :to="{name: 'exams'}"
            :class="['profile-mobile-tab-bar__menu-link', 'd-block', `${$route.name.indexOf('map') > -1 ? 'is-active' : ''}`]"
            active-class="is-active"
          )
            so-icon(icon="diary")

        .col-auto.d-none.d-md-block
          router-link(
            :to="{name: 'users'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            so-icon(icon="users")

        .col-auto.d-none.d-md-block
          router-link(
            :to="{name: 'patients'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            so-icon(icon="patients")

        .col-auto.d-md-none
          button(
            type="button"
            @click="menuIsOpen = !menuIsOpen"
            class=['profile-mobile-tab-bar__menu-button']
            :class="[{'is-open': menuIsOpen}, {'is-active': $route.name === 'users' || $route.name === 'user-detail' ||  $route.name === 'patients' ||  $route.name === 'patient-detail'}]"
          )
            span
              so-icon(icon="menu-points")



    transition(name="fade")
      ul.profile-mobile-tab-bar__menu-hidden(v-show="menuIsOpen")
        li.profile-mobile-tab-bar__menu-hidden-item
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'users'}" :classes="'d-block text-center'")
              span Клиенты

        li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'patients'}" :classes="'d-block text-center'")
              span Пользователи

        li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'med-organizations'}" :classes="'d-block text-center'")
              span Медорганизации

        li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'doctors'}" :classes="'d-block text-center'")
              span Медработники

        //- TODO it was temp removed
        //- li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
        //-   div(@click="menuIsOpen = false")
        //-     app-title(:routerLink="{name: 'chart'}" :classes="'d-block text-center'")
        //-       span Отчеты

</template>

<script>
import {mapState} from 'vuex';

import is from 'is_js';
import variables from '@/helpers/variables';

import ClickOutside from 'vue-click-outside';
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppAdminMobileTabBar',
  components: {
    AppTitle,

  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      v: variables.requests,
      menuIsOpen: false,
      decodedUrl: '',
    };
  },
  computed: {
    ...mapState({
      // isLoading: (state) => state.requests.isLoading,
      requests: (state) => state.requests.data,
      error: (state) => state.requests.error,
      logoMobVersionSrc: (state) => state.settings.logoMobVersionSrc,
      logotype() {
        let spliting = this.logoMobVersionSrc.data.value.split('.');
        let fileFormat = spliting[spliting.length - 1];
        if (fileFormat === 'svg') {
          fetch(this.logoMobVersionSrc.data.download_link)
            .then((response) => response.text())
            .then((svg) => {
              let url = 'data:image/svg+xml;base64,' + window.btoa(svg);
              this.decodedUrl = url;
              return this.decodedUrl;
            });
          return this.decodedUrl;
        } else {
          return this.logoMobVersionSrc.data.download_link;
        }
      },
    }),
    requestsPending() {
      if (this.requests) {
        return this.requests.filter((el) => el.status_code === this.v.statuses.pending);
      } else return false;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
  },
  methods: {
    closeMenu() {
      this.menuIsOpen = false;
    },
  },
};
</script>
