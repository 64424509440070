
.page-not-found.h-100.d-flex.flex-column.align-items-center.justify-content-center
  .text-center
    app-title Такой страницы не существует!
    
    router-link(
      tag="button"
      :to="{name: 'dashboard'}"
      class=['button button--accent button--shadow', 'mt-5']
    )
      span.button__content В личный кабинет
  
