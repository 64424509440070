
div
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showBlockHistory"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Индивидуальные границы выпуска

  .profile-table(v-if='showInfo')
    .profile-table__header.d-none.d-xl-block
      .row.align-items-center.g-4
        div(v-for="column in columns" :key="column.key" :class="column.class")
          button(
            type="button"
            @click="alert(123)"
            class=['profile-table__sort-button']
          )
          div.d-flex.align-items-center
            span.text-overflow(:title="column.caption") {{ column.caption }}

        ul.profile-table__body
          li.profile-table__row(v-for="border in bordersIds" :key="border.num")
              section(class=['user-card', 'user-card-history'])
                .row.align-items-center.g-4.gy-xl-0
                  .col-3
                      div {{ border.name }}
                  .col-4.d-flex
                    span.presonal-border-text от
                    input(
                      :disabled="currentUser.role === 'CLIENT'"
                      :class="['personal-border', 'form__field']"
                      type="email"
                      placeholder="от"
                      v-model="data[border.id].min_value"
                    )
                    span.presonal-border-text до
                    input(
                      :disabled="currentUser.role === 'CLIENT'"
                      :class="['personal-border', 'form__field']"
                      type="email"
                      placeholder="до"
                      v-model="data[border.id].max_value"
                    )
                  .col-2
                    date-picker(
                      :disabled="currentUser.role === 'CLIENT'"
                      prefix-class="app"
                      placeholder="от"
                      format="DD.MM.YYYY"
                      :editable="true"
                      v-model="data[border.id].end_date"
                      value-type="YYYY-MM-DD"
                    )
                  .col-1(v-if="currentUser.role !== 'CLIENT'")
                    button(
                      type="button"
                      @click="sendBorder(data[border.id])"
                      class=['text-decoration--underline text-size--small link--accent']
                    ) Сохранить

