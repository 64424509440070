<template lang="pug">
.profile-doctors.is-filterable
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7.col-md-auto
        app-title(:type="'large'") Журнал отстраненных работников
      .col.col-md-auto.ms-md-auto
        transition(name="translucent")
              button(
                type="button"
                @click="exportSuspendedWorkers()"
                class=['link--accent']
              )
                .d-flex.align-items-center
                span.flex-shrink-0.me-3
                    so-icon(icon="xls-doc")
                span.text-size--small Выгрузить данные .xlsx

  .profile-doctors__content.position-relative.mt-25
    transition(name="translucent")
      div
        app-table(
          v-if="suspendedWorkers.length"
          :columns="getColumns()"
          :sortKey="sortKey"
          :data="suspendedWorkers"
          :paginationData="pagination"
          :currentPage="currentPage"
          @change-current-page="setCurrentPage"
          :baseUrl="baseUrl"
          :canEdit="false"
          :canRemove="false"
        )
          template(v-slot:cell='{ column, row, index }')
            span(v-if='column.key === "admission_datetime"') {{$moment(row[column.key]).format('DD.MM.YYYY')}}  {{ $moment(row[column.key]).format('HH:mm:ss')}}
            span(v-else-if='column.key === "patient_company_name"') {{ row[column.key] }}
            span(v-else-if='column.key === "ra_and_rm_pre_diagnosis"') {{ getDiagnosis(row) }}

          template(v-slot:actionTools='{ row }')
            button(
              type="button"
              @click.prevent="download(row)"
            )
              img(src="@/assets/img/notice.svg")

        template(v-else)
          .text-color--blue.text-size--small Отстраненные работники не найдены

</template>

<script>
import { mapGetters } from 'vuex';
import { gettersTypes as authGettersTypes } from '@/store/modules/auth';
import { blobToFile } from '@/helpers/utils';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import suspendedWorkers from '@/api/suspendedWorkers';
import AppTable from '@/components/common/table/Table';

export default {
  name: 'SuspendedWorkersDashboard',
  components: {
    AppTitle,
    AppLoading,
    AppTable,
  },
  computed: {
    baseUrl() {
      return this.$route.path;
    },
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    isAdmin() {
      return this.userRole === 'admin';
    },
  },
  data() {
    return {
      suspendedWorkers: [],
      pagination: {
        total: 0,
        limit: 0,
      },
      sortKey: 'id',
      sortInverse: true,
      columns: [
        {
          caption: '№',
          key: 'patient_code',
          inverse: true,
        },
        {
          caption: 'Дата и время',
          key: 'admission_datetime',
          inverse: true,
        },
        {
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          caption: 'Предварительный диагноз',
          key: 'pre_diagnosis_array',
          inverse: true,
        },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getSuspendedWorkers();
    },
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
  },
  created() {
    this.getSuspendedWorkers();
  },
  methods: {
    async getSuspendedWorkers() {
      const result = await suspendedWorkers.getSuspendedWorkers(this.currentPage - 1);
      this.suspendedWorkers = result.data.items;
      this.pagination.total = result.data.total;
      this.pagination.limit = result.data.per_page;
    },
    async download(data) {
      try {
        for (const url of data.links) {
          await this.downloadFile(url);
        }
      } catch (error) {
        console.error('Ошибка при скачивании файлов:', error);
      }
    },
    async downloadFile(url) {
      try {
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.download = '';
        document.body.appendChild(iframe);

        iframe.onload = () => {
          document.body.removeChild(iframe);
        };
      } catch (error) {
        console.error(`Ошибка при скачивании файла (${url}):`, error);
      }
    },
    async exportSuspendedWorkers() {
      suspendedWorkers
        .suspendedWorkersExport()
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getSuspendedWorkers();
    },
    getDiagnosis(data) {
      for (let i of data.pre_diagnosis_array) {
        return i;
      }
    },
    getColumns() {
      const columns = [
        {
          caption: 'Дата и время',
          key: 'admission_datetime',
          inverse: true,
        },
        {
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          caption: 'Предварительный диагноз',
          key: 'ra_and_rm_pre_diagnosis',
          inverse: true,
        },
      ];

      if (this.isAdmin) {
        columns.splice(2, 0, {
          caption: 'Компания',
          key: 'patient_company_name',
          inverse: true,
        });
      }

      return columns;
    },
  },
};
</script>
