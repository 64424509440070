<template lang="pug">
section(
  class=['doctor-card', 'profile-card']
  :class="classes"
)
  .row.align-items-center.g-4
    div(v-for="column in columns" :key="column.key" :class="column.class")
      template(v-if="column.key === 'medical_license_start' || column.key === 'medical_license_end'")
        span {{ toLocalDate(data[column.key]) || '—' }}

      template(v-else)
        span(v-if="data[column.key]") {{ data[column.key] }}
        span.text-color--blue(v-else) &#8212;
</template>

<script>
import variables from '@/helpers/variables';
import {declOfNum, toLocalDate} from '@/helpers/utils';

export default {
  name: 'AppAdminDoctorCard',
  components: {

  },
  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      v: variables,
      declOfNum,
    };
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
  },
};
</script>
