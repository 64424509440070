<template lang="pug">
  section.request-card.d-flex(v-if='data')
    template(v-if="(data.status_code === v.requests.statuses.pending)")
      .row.flex-grow-1.g-4(:class="{'align-items-center': isUser || (devices && devices.length < 2)}")
        .col-14.col-xl-auto.col-xxl-1
          .d-flex.align-items-center
            span.flex-shrink-0.me-3.text-color--accent
              so-icon(icon="process")
            span {{ createDate }}

        .col-14.col-xl-2
          span {{ data.user_full_name }}

        template(v-if="isUser")
          .col-14.col-xl-3
            span.text-overflow(:title="data.user_email") {{ data.user_email }}

          .col-14.col-xl-2
            .text-overflow
              span.text-color--blue ИНН&nbsp;
              span {{ data.user_inn }}

        template(v-if="isDevice")
          .col-14.col-xl-5
            .d-flex
              span.text-color--blue.me-3 Устройства:
              ul
                li(v-for="(device, index) in devices" :class="{'mt-2': index > 0}") {{device.device_serial}}  <span class="tooltipOverText"><app-tooltip classes="d-block text-size--extra-small tooltipWidth']" :content="`<div class='tooltipRequest'><div class='tooltipRequest__item'><b>Номер свидетельства</b><p>${device.device_attestation}	</p></div><div class='tooltipRequest__item'><b>Поверено от</b><p>${device.device_attestations_start}</p></div><div class='tooltipRequest__item pe-0'><b>Поверено до</b><p>${device.device_attestations_end}</p></div></div>`" class='tooltipOverText'></app-tooltip>№ {{device.device_attestation}}</span>	от {{device.device_attestations_start}}

          .col-14.col-xl-auto(v-if="data.documents")
            button(
              class=['request-card__document-button', 'link--accent']
              type="button"
              v-for="document in data.documents"
              @click="getFile(document)"
            )
              .d-flex.align-items-center
                span.d-flex.me-3.flex-shrink-0
                  so-icon(icon="document")
                span Документ


        .col-14.col-xl-auto.ms-xl-auto
          .row.g-3.flex-xl-column.flex-sl-row
            .col-auto
              button(
                type="button"
                class=['button button--small button--white button--border-blue']
                @click="reject(data)"
              )
                .button__content.d-flex.align-items-center
                  span.d-flex.me-3.flex-shrink-0
                    so-icon(icon="wrong")
                  span Отказать
            .col-auto
              button(
                type="button"
                class=['button button--small button--accent']
                @click="approve(data)"
              )
                .button__content.d-flex.align-items-center
                  span.d-flex.me-3.flex-shrink-0
                    so-icon(icon="allright")
                  span Одобрить

    // rejected requests
    app-accordion(
      v-else
      :id="String(data.id)"
      :parent-id="'profile-requests'"
    )
      template(v-slot:header)
        .row.flex-column.flex-xl-row.gy-4.gy-xl-0(:class="{'align-items-center': isUser || (devices && devices.length < 2)}")
          .col-14.col-xl-auto
            .d-flex.align-items-center
              span.me-3.text-color--danger
                so-icon(icon="wrong")
              span {{ createDate }}
          .col
            span {{ data.user_full_name }}

          template(v-if="isUser")
            .col
              span(v-if="isPerson") Физ.лицо
              span(v-if="isCompany") Юр.лицо
            .col
              span {{ data.user_email }}
            .col
              span.text-color--blue ИНН&#32;
              span {{ data.user_inn }}

          template(v-if="isDevice")
            .col
              .d-flex
                span.text-color--blue.me-3 Устройства:
                ul
                  li.mb-2(v-for="device in devices") {{ device }}

            .col(v-if="data.documents")
              //- button(
              //-   class=['request-card__document-button', 'link--accent']
              //-   type="button"
              //-   v-for="document in data.documents"
              //-   @click.prevent="getFile(document)"
              //- )
              //-   .d-flex.align-items-center
              //-     span.flex-shrink-0.me-3
              //-       so-icon(icon="document")
              //-     span Документ&nbsp;покупки

      template(v-slot:body)
        .row.justify-content-end
          .col-14.col-xxl-11
            .request-card__message
              p.text-color--danger Заявка на регистрацию отклонена {{ updateDate }}
              p.text-color--black.text-family--light {{ data.reason }}

</template>

<script>
import filesApi from '@/api/files';

import variables from '@/helpers/variables';
import {toLocalDate} from '@/helpers/utils';

import AppAccordion from '@/components/common/Accordion';

import AppTooltip from '@/components/common/Tooltip';
export default {
  name: 'AppAdminRequestCard',
  components: {
    AppAccordion,
    
    AppTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      v: variables,
    };
  },
  computed: {
    isDevice() {
      return this.data.type_code === this.v.requests.types.device;
    },
    isUser() {
      return this.data.type_code === this.v.requests.types.user;
    },
    isPerson() {
      return this.data.user_type === this.v.user.types.person;
    },
    isCompany() {
      return this.data.user_type === this.v.user.types.company;
    },
    createDate() {
      return toLocalDate(this.data.creation_date);
    },
    updateDate() {
      return toLocalDate(this.data.last_update_date);
    },
    devices() {
      if (this.data.additional_params) {
        let devices = [];
        if (typeof this.data.additional_params === 'string'){
          let params = JSON.parse(this.data.additional_params);
          devices.push({
            'device_serial': params.serial,
            'device_attestation': params.device_attestation,
            'device_attestations_start': toLocalDate(params.device_attestation_start),
            'device_attestations_end': toLocalDate(params.device_attestation_end)
          });
        }
        else {
          for(let i = 0; i < this.data.additional_params.device_serials.length; i++){
            devices.push({'device_serial': this.data.additional_params.device_serials[i],
              'device_attestation': this.data.additional_params.device_attestations[i],
              'device_attestations_start': toLocalDate(this.data.additional_params.device_attestations_starts[i]),
              'device_attestations_end': toLocalDate(this.data.additional_params.device_attestations_ends[i])
            });
          }
        }
        return devices;
      } else return null;
    },
  },
  methods: {
    reject(data) {
      this.$emit('reject', data);
    },
    approve(data) {
      this.$emit('approve', data);
    },
    getFile(hash) {
      filesApi.getFile(hash);
    },
  },
};
</script>

<style lang="scss">
.request-card {
  width: 100%;
  height: 100%;
  padding: $spacer $spacer * 1.8;
  border-radius: $border-radius-sm;
  background-color: get($colors, white);
  @include font-size(get($text-sizes, small));

  @include mediaToDown($xl) {
    padding: $spacer * 1.2;
    box-shadow: get($shadows, default);
  }
  .accordion {
    width: 100%;

    &__button {
      @include mediaToDown($xl) {
        top: $spacer * 2;
      }
    }
  }
  &__message {
    background-color: rgba(get($colors, blue-light), 0.5);
    border-radius: $border-radius-sm;
    padding: $spacer * 1.6 $spacer * 3;
    white-space: pre-wrap;

    @include mediaToDown($xl) {
      padding: $spacer * 1.4 $spacer;
    }
  }
  &__document-button {
    svg {
      fill: get($colors, blue);
      width: $spacer * 2;
      height: $spacer * 2;
      @include transition(fill);
    }
    @include interaction('mouse') {
      &:hover {
        svg {
          fill: get($colors, orange-hover);
        }
      }
    }
  }
}
.tooltipOverText{
  display: inline-block;
  position: relative;
  button{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    svg{
      display: none !important;
    }
  }
}
.tooltipRequest{
  display: flex;
  &__item{
    white-space: nowrap;
    padding-right: 26px;
  }

}
.tooltipWidth{
   width: 5000px !important;
  max-width: 100%;

}
.tooltip-inner{

      max-width: max-content !important;
  }
</style>
