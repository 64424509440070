<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Создание группы печати
      form(@submit.prevent="onSubmit" :class="['form']")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
            .row.g-4.posRelative
              .col
                label.form__label
                  input(
                    v-model="v$.form.name.$model"
                    :class="['form__field']"
                    type="text"
                    name="name"
                    placeholder="Наименование группы"
                    autocomplete="off"
                    id="id"
                  )

          .col-14.col-md-10.mt-5
            button(
              type="submit"
              class=['button', 'button--accent', 'form__button']
              :disabled="v$.form.$invalid"
            )
              span.button__content Добавить

</template>

<script>
import printGroupApi from '@/api/printGroup';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

//validate
import variables from '@/helpers/variables';
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'AppAddPrintingGroupModal',
  components: {
    AppModal,
    AppTitle,
    
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      devices: {},
      form: {
        name: '',
      },
      v: variables,
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },

  methods: {
    async onSubmit() {
      if (!this.v$.$invalid) {
        try {
          let formData = {};
          formData.name = this.form.name;
          await printGroupApi.createPintGroup(formData);
          this.$emit('success');
          this.closeModal();
        } catch (error) {
          this.$toast.error(error);
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.resetForm();
      this.$emit('close');
      this.$emit('updateData');
    },
    resetForm() {
      this.form.name = '';
    },
  },
};
</script>

<style lang="scss">
.outer-form {
  position: relative;
  border: 1px solid #989fb8;
  border-radius: 8px;
  padding: 12px;
  padding-top: 4;
}
.maw365 {
  max-width: 365px;
}
.form__field--no-border {
  border: none !important;
  padding-left: 0 !important;
  transition: 0.2s ease !important;
  border-bottom: 1px solid #dfe4f8 !important;
  border-radius: 0 !important;
}
.addCheck {
  position: absolute;
  top: 25px;
  right: 15px;
  z-index: 999;
  font-size: 12px;
  cursor: pointer;
}
.form__label--float {
  position: relative;
}
.fl {
  position: absolute;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(10px);
  transition: 0.2s ease-out;
}
.form__field--no-border:focus + .fl,
.form__field--no-border:not(:placeholder-shown) + .fl {
  transform: translateY(-10px) scale(0.85);
}
.form__field--no-border::placeholder,
.form__field--animate::placeholder {
  color: #fff !important;
}
.textFloat {
  position: absolute;
  display: block;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(-28px);
  transition: 0.2s ease-out;
  padding-left: 20px;
  cursor: inherit;
}
.form__field--animate {
  height: auto !important;
  padding-top: 18px !important;
  padding-bottom: 2px !important;
  text-transform: uppercase;
  padding-left: 12px !important;
}
.form__field--animate:focus + .textFloat,
.form__field--animate:not(:placeholder-shown) + .textFloat {
  transform: translateY(-43px) translateX(7px) scale(0.65);
  margin-right: 20px;
}
.posRelative {
  position: relative;
}
</style>
