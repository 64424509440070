
.inspection-card__table.col-14
  .row
    .col-14
      .table__header.mb-4.d-flex.flex-xl-row.flex-column.title.title--small(
          @click="openAccordeon"
          class=['text-color--blue-dark', 'mb-2', 'mb-xl-0', 'block-history-title']
          ) результаты осмотра
          span.d-flex.align-items-center.ms-3
            so-icon(
              :class='{rotate: isActiveIcon}'
              name="down-arrow")

      transition(name="translucent")
        .profile-table(v-if="this.showInfo")
          .profile-table__header.d-none.d-xl-block
            table.table.table-striped.w-100
                thead
                  th
                  th.text-center.pt-2.px-3 АД сист.
                  th.text-center.px-3 АД диаст.
                  th.text-center.px-3 Пульс
                  th.text-center.px-3 Температура
                  th.text-center.px-3 Алкоголь
                  th.text-center.px-3 Жалобы
                tbody
                  tr.bg-blue
                    td Результат осмотра
                    td.text-center
                      span(:class='{yellowText: exam.bp_high_alarm}') {{ exam.bp_high }}
                    td.text-center
                      span(:class='{yellowText: exam.bp_low_alarm}') {{ exam.bp_low }}
                    td.text-center
                      span(:class='{yellowText: exam.heart_rate_alarm}') {{ exam.heart_rate }}
                    td.text-center
                      span(:class='{yellowText: exam.body_temp_alarm}') {{ exam.body_temp }}
                    td.text-center
                      span(:class='{yellowText: exam.alcohol_alarm}') {{ alcoFormatted(exam.alcohol ) }}
                    td.health_complaint.text-center
                      app-tooltip(
                        v-if="exam.health_complaint"
                        :inline="true"
                        :withoutIcon="true"
                        :content="exam.health_complaint"
                      )
                        template(v-slot:body)
                          span.text-overflow.text-color--orange {{ exam.health_complaint }}
                      span(v-else)  &#8212;

                  tr.py-2
                    td Индивидуальные границы
                    td.text-center
                      span(v-if="patient_borders.bp_high") {{ patient_borders.bp_high.min_value }} - {{ patient_borders.bp_high.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.bp_low") {{ patient_borders.bp_low.min_value }} - {{ patient_borders.bp_low.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.heart_rate") {{ patient_borders.heart_rate.min_value }} - {{ patient_borders.heart_rate.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.body_temp") {{ patient_borders.body_temp.min_value }} - {{ patient_borders.body_temp.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.alcohol") {{ alcoFormatted(patient_borders.alcohol.min_value) }} - {{ alcoFormatted(patient_borders.alcohol.max_value) }}
                      span(v-else) &#8212;
                    td
                    td

                  tr
                    td Средние показатели
                    td.text-center
                      span {{ avg_results.avg_bp_high }}
                    td.text-center
                      span {{ avg_results.avg_bp_low }}
                    td.text-center
                      span {{ avg_results.avg_heart_rate }}
                    td.text-center
                      span {{ avg_results.avg_body_temp }}
                    td.text-center
                      span {{ alcoFormatted(avg_results.avg_alcohol) }}
                    td
                    td

