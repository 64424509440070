import axios from 'axios';

if (process.env.NODE_ENV !== 'production') {
  //axios.defaults.baseURL = 'http://safedrive.itfactory.site/api/v1';

  const host = import.meta.env.VITE_API_HOST;
  const protocol = import.meta.env.VITE_API_PROTOCOL || 'https';
  // const baseURL = `http://${host}`;
  const baseURL = `${protocol}://${host}`;

  if (!host) {
    console.warn(
      `[SO internal warning] Can't use axios with ${baseURL} baseURL. Set the VITE_API_HOST variable`,
    );
  }

  axios.defaults.baseURL = baseURL;
  axios.defaults.withCredentials = true;
} else {
  axios.defaults.baseURL = '';
}

export default axios;
