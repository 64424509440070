
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14
        .profile-patients__content.mt-20.position-relative
          transition-(name="translucent")
            div
              app-loading(v-if="isLoading")
              app-error-message(v-if="error" :message="error")
              app-table(
                v-if="outgoingMedDocs.length"
                :columns="columns"
                :sortKey="sortKey"
                :data="outgoingMedDocs"
                :paginationData="pagination"
                :currentPage="currentPage"
                @change-current-page="setCurrentPage"
                @sortingRow="sortingMedDocs"
                :baseUrl="baseUrl"
                :canEdit="false"
                :canRemove="false"
              )
                template(v-slot:cell='{ column, row }')
                  span(v-if='column.key === "created_at"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}
                  span(v-else-if='column.key === "doc_type"') {{ getMedDocType(row[column.key]) }}

                template(v-slot:actionTools='{ row }')
                  button(
                    type="button"
                    @click.prevent="download(row)"
                  )
                    img(src="@/assets/img/notice.svg")

              div(v-else-if="!isLoading")
                .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-outgoing(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
