<template lang="pug">
.inspection-card__table.col-14
  .row
    .col-14
      .table__header.mb-4.d-flex.flex-xl-row.flex-column.title.title--small(
          @click="openAccordeon"
          class=['text-color--blue-dark', 'mb-2', 'mb-xl-0', 'block-history-title']
          ) результаты осмотра
          span.d-flex.align-items-center.ms-3
            so-icon(
              :class='{rotate: isActiveIcon}'
              name="down-arrow")

      transition(name="translucent")
        .profile-table(v-if="this.showInfo")
          .profile-table__header.d-none.d-xl-block
            table.table.table-striped.w-100
                thead
                  th
                  th.text-center.pt-2.px-3 АД сист.
                  th.text-center.px-3 АД диаст.
                  th.text-center.px-3 Пульс
                  th.text-center.px-3 Температура
                  th.text-center.px-3 Алкоголь
                  th.text-center.px-3 Жалобы
                tbody
                  tr.bg-blue
                    td Результат осмотра
                    td.text-center
                      span(:class='{yellowText: exam.bp_high_alarm}') {{ exam.bp_high }}
                    td.text-center
                      span(:class='{yellowText: exam.bp_low_alarm}') {{ exam.bp_low }}
                    td.text-center
                      span(:class='{yellowText: exam.heart_rate_alarm}') {{ exam.heart_rate }}
                    td.text-center
                      span(:class='{yellowText: exam.body_temp_alarm}') {{ exam.body_temp }}
                    td.text-center
                      span(:class='{yellowText: exam.alcohol_alarm}') {{ alcoFormatted(exam.alcohol ) }}
                    td.health_complaint.text-center
                      app-tooltip(
                        v-if="exam.health_complaint"
                        :inline="true"
                        :withoutIcon="true"
                        :content="exam.health_complaint"
                      )
                        template(v-slot:body)
                          span.text-overflow.text-color--orange {{ exam.health_complaint }}
                      span(v-else)  &#8212;

                  tr.py-2
                    td Индивидуальные границы
                    td.text-center
                      span(v-if="patient_borders.bp_high") {{ patient_borders.bp_high.min_value }} - {{ patient_borders.bp_high.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.bp_low") {{ patient_borders.bp_low.min_value }} - {{ patient_borders.bp_low.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.heart_rate") {{ patient_borders.heart_rate.min_value }} - {{ patient_borders.heart_rate.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.body_temp") {{ patient_borders.body_temp.min_value }} - {{ patient_borders.body_temp.max_value }}
                      span(v-else) &#8212;
                    td.text-center
                      span(v-if="patient_borders.alcohol") {{ alcoFormatted(patient_borders.alcohol.min_value) }} - {{ alcoFormatted(patient_borders.alcohol.max_value) }}
                      span(v-else) &#8212;
                    td
                    td

                  tr
                    td Средние показатели
                    td.text-center
                      span {{ avg_results.avg_bp_high }}
                    td.text-center
                      span {{ avg_results.avg_bp_low }}
                    td.text-center
                      span {{ avg_results.avg_heart_rate }}
                    td.text-center
                      span {{ avg_results.avg_body_temp }}
                    td.text-center
                      span {{ alcoFormatted(avg_results.avg_alcohol) }}
                    td
                    td

</template>

<script>
import patientApi from '@/api/patients';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'DoctorExamCardInspectionResult',
  components: {
    
    AppTooltip,
  },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActiveIcon: false,
      patient_borders: {},
      avg_results: {},
      showInfo: true,
    };
  },
  watch: {
    async exam() {
      await this.getFullResults();
    },
  },
  methods: {
    openAccordeon() {
      this.isActiveIcon = !this.isActiveIcon;
      this.showInfo = !this.showInfo;
    },
    async getFullResults() {
      let resultBorders = await patientApi.getPatientBorders(this.exam.patient_code);
      this.patient_borders = resultBorders.data;
      let res = await patientApi.getPatientAvgExamsResult(this.exam.patient_code);
      this.avg_results = res.data;
    },
    alcoFormatted(alcoData) {
      let number = Number(alcoData);
      return number.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scope>
.inspection-card__table {
  margin-left: auto;
  .table__header {
    height: 50px;
    border-radius: 6px;
    padding: 1rem;
    font-weight: 600;
    &__button {
      margin: 0 auto;
      @include mediaToDown($xl) {
        top: $spacer * 2;
      }
    }
  }
  .profile-table__header {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
  }
  .table {
    color: #59658d;
  }
  tr:nth-child(1) td:nth-child(7) {
    @include font-size(get($text-sizes, small));
    font-weight: 400;
  }
  tr:nth-child(1) td:nth-child(8) {
    font-size: 12px;
    font-weight: 400;
  }
  thead {
    font-size: 12px;
    border-bottom: 1px solid #59658d;
    th {
      font-weight: 400;
      padding-bottom: 8px;
    }
  }
  .bg-blue {
    font-weight: 700;
    background-color: #f6f9fe;
  }
  tbody {
    tr {
      border-bottom: 1px solid #59658d;
      &:last-child {
        border: none;
      }
    }

    td {
      @include font-size(get($text-sizes, normal));
      padding-top: 10px;
      padding-bottom: 10px;
    }
    td:first-child {
      font-size: 12px;
    }
  }
  .tooltip-button {
    max-width: 100px;
  }

  .rotate {
    transform: rotate(-180deg);
  }
}
</style>
