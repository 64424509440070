
<div class="profile">
  <so-modal v-if="userRole === 'doctor'"
    :modelValue="esiaModalValue"
    title-size="large"
    :can-close="true"
    @on-close="() => onClose()">
    <template v-slot:title>
      <span class="text-color--orange">Пожалуйста, войдите в личный кабинет через ЕСИА</span>
    </template>
    <p class="text-center py-4">Ниже нажмите кнопку 'Войти через Госуслуги’ для перехода к аутентификации через ЕСИА</p>
    <template v-slot:footer>
      <so-button-esia @on-click="onOk" size="large"></so-button-esia>
    </template>
  </so-modal>
  <app-sidebar></app-sidebar>
  <app-mobile-tab-bar></app-mobile-tab-bar>
  <simplebar id="v.dom.mainScroll">
    <div class="container-fluid">
      <div class="d-flex justify-content-end">
        <div class="profile__inner">
          <transition name="fade" mode="out-in">
            <app-mobile-header v-if="$route.name === 'dashboard'"></app-mobile-header>
          </transition>
          <div class="profile__content">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </simplebar>
</div>

