
.notification(:class="[classes, `is-${data.type}`, isWelcome ? 'order-0' : 'order-1']")
  .row.align-items-center.flex-column.flex-lg-row.g-4.g-lg-0
    .col
      .d-flex.flex-column.flex-xxl-row.align-items-xxl-center
        span.text-size--small.mb-2.mb-xxl-0.me-xxl-3(v-if="!isWelcome") {{ data.creation_date }}
        app-title {{ data.title }}
      
      .mt-2.text-size--small {{ data.text }}

    .col.col-lg-auto(v-if="linkContent")
      router-link(
        :to="{name: String(data.content_type)}"
        class=['text-color--accent link--accent', 'd-flex align-items-xl-center']
      )
        span.text-uppercase.ls-lg {{ linkContent }}
        span.ms-3.d-flex
          so-icon(icon="right-long-arrow")

  button(
    type="button"
    @click="deleteNotification"
    class=['notification__delete-button', 'link--accent']
  )
    so-icon(icon="close")

