
div
  label.submit-form-comment
    textarea(
    id="editor"
    :class="['form__field w-100 doctor-exam-detail-modal__text']"
    placeholder="Добавить комментарий"
    v-model="currentComment"
    )
    button(
    type="button"
    @click="createComment()"
    class=['send-comment']
    )
        span.send-comment-content >
  .comments-section
    ul
      li.comment-header(v-if="comments.length" v-for="comment in comments" :key="comment.id") {{ comment.user.fio_name }} {{ $moment(comment.created_at).format('HH:mm DD.MM.YYYY') }}
        p {{ comment.comment }}

