
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__header
    .row.align-items-center.flex-column.flex-md-row.g-5.gy-md-0
      .col.position-relative
        .d-flex.align-items-center.flex-column-reverse.flex-md-row
          app-title(:type="'large'") Мои устройства

          .text-family--light.text-color--blue.mb-2.ms-md-4.mb-md-0(v-if="devices")
            transition(name="translucent")
              span(v-if="devices.length") {{ totalDevices }} {{ declOfNum(totalDevices, ['устройство', 'устройства', 'устройств']) }}
              span(v-if="devices.length === 0 && !isTouchDevice") Нет устройств

        button(
          v-if="devices && devices.length"
          type="button"
          class=['profile-devices__add-button', 'button--blue', 'd-md-none']
          @click="openModal('createDevice')"
        )
          so-icon(icon="plus-thin")

      //- .col.col-md-auto.ms-md-auto(v-if="devices && devices.length")
        button(
          type="button"
          class=['profile-sidebar__button', 'button button--blue', 'mt-auto px-3 w-100']
          @click="isModalVisible.createDevice = true"
        )
          span.button__content
            span.d-flex.flex-shrink-0.me-3
              so-icon(icon="plus-thin")
            span Добавить устройства

  .profile-devices__content.position-relative.mt-20
    app-loading(v-if="isLoading")
    app-error-message(v-if="error" :message="error")

    transition(name="translucent")
      div(v-if="devices")
        .row.flex-column.align-items-start(v-if="devices.length")
          .col
            .row.g-4
              .col-14(v-for="device in devices" :key="device.serial")
                app-client-device-card(:data="device" @delete="openDeleteModal")

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          .col.col-md-auto
            button(
              type="button"
              class=['button button--blue', 'mt-5 w-100', 'd-xl-none']
              @click="openModal('createDevice')"
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  so-icon(icon="plus")
                span Добавить устройства

      // if there are no devices
      template(v-else)
        .row.justify-content-center.justify-content-md-start.mb-md-5(v-if="isTouchDevice")
          .col-10.col-md-auto
            .text-size--small.text-family--light.text-center На данный момент нет зарегистрированных устройств и&nbsp;активных&nbsp;заявок

        button(
          type="button"
          class=['profile-devices__create-button', 'button button--accent button--middle']
          @click="openModal('createDevice')"
        )
          .button__content
            span.d-flex.flex-shrink-0.me-3
              so-icon(icon="plus-thin")
            span Добавить устройства



  // create device
  app-create-device-modal(
    :is-visible="isModalVisible.createDevice"
    @close="closeModal('createDevice')"
    @success="createdDevice"
  )
  // delete modal
  app-delete-device-modal(
    :data="deleteDevice"
    :is-visible="isModalVisible.deleteDevice"
    @close="closeModal('deleteDevice')"
    @success="deletedDevice"
  )
  // success modals
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successDeleted" @close="closeModal('successDeleted')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Устройство успешно удалено
          p.text-size--small.text-color--blue.mt-3
            span Вам придет уведомление на e-mail об удалении устройства
            span.text-family--medium.text-color--blue-dark &ensp;{{ deleteDevice.serial}}

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeModal('successDeleted')"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successCreated" @close="closeModal('successCreated')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Успешно отправлено на рассмотрение

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeModal('successCreated')"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад
  // filter
  app-client-device-filter(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="closeFilter"
    @clear="clearFilter"
    @submit="submitFilter"
  )

