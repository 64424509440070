
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          v-if="isDirty && Object.values(this.collectData).length"
          type="button"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Медорганизация:
                autocomplete(
                  :search="search"
                  placeholder="Начните вводить наименование"
                  aria-label="Начните вводить наименование"
                  :get-result-value="getResultValue"
                  @submit="handleSubmit"
                )

            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Поиск по ОГРН:
                input(
                  :class="['form__field']"
                  type="string"
                  name="id"
                  v-model="ogrn"
                  placeholder="Введите номер"
                  autocomplete="off"
                  id="id"
                )

      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !Object.values(this.collectData).length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
          @click="submit"
        )
          span.button__content
            span Показать
            transition(name="translucent" mode="out-in")

