<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Добавить устройство в группу печати талонов {{ data.name }}
      form( :class="['form']")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
            autocomplete(
              :search="search"
              placeholder="Введите серийный номер МИ"
              aria-label="Введите серийный номер МИ"
              :get-result-value="getResultValue"
              @submit="handleSubmit"
              )

          .col-14.col-md-10.mt-5
            button(
              @click.prevent="addDevice"
              type="submit"
              class=['button', 'button--accent', 'form__button']
              :disabled="!filteredDevices.length"
            )
              span.button__content Добавить

</template>

<script>
import printGroupApi from '@/api/printGroup';
import devicesApi from '@/api/devices';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import Autocomplete from '@trevoreyre/autocomplete-vue';

import is from 'is_js';

import {arrayDifference} from '@/helpers/utils';

export default {
  name: 'AppEditPrintingGroupModal',
  components: {
    AppModal,
    AppTitle,
    
    Autocomplete,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      v: variables,
      filteredDevices: [],
      form: {
        device_serial: [],
        isChanged: {
          device_serial: false,
        },
      },
    };
  },
  computed: {
    isChanged() {
      return Object.values(this.form.isChanged).some((item) => item === true);
    },
  },
  methods: {
    //autocomplete
    async search(input) {
      this.resultFilter = `?page=0&per_page=5&serial=${input}`;
      let res = await devicesApi.getDevices(this.resultFilter);
      this.filteredDevices = res.data.items;
      return this.filteredDevices;
    },
    getResultValue(result) {
      this.form.device_serial = result.serial;
      return result.serial;
    },
    handleSubmit(result) {
      this.form.device_serial = result.serial;
    },
    //update data
    changeField(key) {
      this.form.isChanged[key] = is.array(this.initialValues[key])
        ? Boolean(arrayDifference(this.form[key], this.initialValues[key]).length)
        : this.form[key] !== (this.initialValues[key] || '');
    },
    //set results
    async addDevice() {
      try {
        let data = {
          group_id: this.data.id,
          device_serials: [this.form.device_serial],
        };
        await printGroupApi.addDevicePrintGroup(data);
        this.$emit('success');
        this.$emit('updateData');
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.closeModal();
      }
    },
    closeModal() {
      this.resetForm();
      this.$emit('close');
      this.$emit('updateData');
    },

    //clear
    resetForm() {
      this.device_ids_selected = [];
      if (this.$refs.multipeSelectDevices) this.$refs.multipeSelectDevices.clear();
      for (let key in this.form.isChanged) {
        if (Object.prototype.hasOwnProperty.call(this.form.isChanged, key)) {
          this.form.isChanged[key] = false;
        }
      }
      this.form.device_serials = [];
      this.form.name = '';
    },
  },
};
</script>
<style lang="scss">
.outer-form {
  position: relative;
  border: 1px solid #989fb8;
  border-radius: 8px;
  padding: 12px;
  padding-top: 4;
}
.maw365 {
  max-width: 365px;
}
.form__field--no-border {
  border: none !important;
  padding-left: 0 !important;
  transition: 0.2s ease !important;
  border-bottom: 1px solid #dfe4f8 !important;
  border-radius: 0 !important;
}
.addCheck {
  position: absolute;
  top: 25px;
  right: 15px;
  z-index: 999;
  font-size: 12px;
  cursor: pointer;
}
.form__label--float {
  position: relative;
}
.fl {
  position: absolute;
  //top: 10px;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(10px);
  transition: 0.2s ease-out;
}
.form__field--no-border:focus + .fl,
.form__field--no-border:not(:placeholder-shown) + .fl {
  transform: translateY(-10px) scale(0.85);
}
.form__field--no-border::placeholder,
.form__field--animate::placeholder {
  color: #fff !important;
}
.textFloat {
  position: absolute;
  //top: 10px;
  display: block;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(-28px);
  transition: 0.2s ease-out;
  padding-left: 20px;
  cursor: inherit;
}
.form__field--animate {
  height: auto !important;
  padding-top: 18px !important;
  padding-bottom: 2px !important;
  text-transform: uppercase;
  padding-left: 12px !important;
}
.form__field--animate:focus + .textFloat,
.form__field--animate:not(:placeholder-shown) + .textFloat {
  transform: translateY(-43px) translateX(7px) scale(0.65);
  margin-right: 20px;
}
.posRelative {
  position: relative;
}
</style>
