
<div class="tab-nav">
  <app-title v-if="needTitle && selectedTabObj" :type="'large'">{{ selectedTabObj.label  }}</app-title>
  <div class="tab-nav-content">
    <button v-for="(tab, index) in tabs"
        :class="['tab-nav__item', {'selected': index === selectedTab}]"
        :key="tab.name"
        @click="clickOnTab(tab, index)">
          {{ tab.label }}
      </button>
  </div>
        <slot></slot>
</div>
