<template lang="pug">
.profile-doctors
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7
        .d-flex.align-items-center
          app-title(:type="'large'") Группы риска
    .col-14.d-none.d-md-block
      .d-flex.download-risks-groups

        .col-2.date-picker-margins
          date-picker(
            prefix-class="app"
            placeholder="от"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="startDate"
            :disabled-date="disableAfterToday"
          )

        .col-2.date-picker-margins
          date-picker(
            prefix-class="app"
            placeholder="до"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="endDate"
            :disabled-date="disableExpiration"
          )

        .col-3.date-picker-margins(v-if="currentUser.role==='ADMIN'")
          autocomplete(
            :style='{ marginTop: "-5px" }'
            :search="search"
            placeholder="Наименование организации"
            aria-label="Наименование организации"
            :get-result-value="getResultValue"
            @submit="handleSubmit"
          )

        .col-3
          button(
            type="button"
            @click="downloadRisksGroups"
            class=['button button--border-blue']
          )
            span.button__content
              span  Скачать группы риска
</template>

<script>
import risksGroups from '@/api/risksGroup';

import DatePicker from 'vue2-datepicker';
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';

import {blobToFile} from '@/helpers/utils';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import { mapGetters } from 'vuex';
import { disableAfterToday, disableExpirationFilters } from '@/helpers/datepicker';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import usersApi from '@/api/users';
import { convertDate } from '@/helpers/utils';

export default {
  name: 'RiskGroupsDashboard',
  components: {
    AppTitle,
    Autocomplete,
    DatePicker,
    AppMultipleSelect,
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      user: null,
      userName: '',
      userId: null
    };
  },
  methods: {
    async search(input) {
      this.userName = '';
      this.userId = null;
      let res = await usersApi.getUsersSuggestionList(input);
      return res.data.items;
    },
    getResultValue(result) {
      this.userName = result.name;
      return result.name;
    },
    handleSubmit(result) {
      this.userId = result.id;
    },
    //datepicker
    disableAfterToday(date) {
      return disableAfterToday(date, this.endDate);
    },
    disableExpiration(date) {
      return disableExpirationFilters(date, this.startDate);
    },
    updateSelectedUsers(data) {
      this.user = data[0].value;
    },
    async downloadRisksGroups() {
      let data = {};

      if (this.startDate !== null) {
        data['dt_from'] = `${convertDate(this.startDate)} 00:00:00`;
      }
      if (this.endDate !== null) {
        data['dt_to'] = `${convertDate(this.endDate)} 23:59:59`;
      }
      if (this.userId) {
        data['user_id'] = this.userId
      }

      risksGroups
        .exportRisksGroup(data)
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
  },
};
</script>

<style>
.download-risks-groups {
  margin-top: 50px;
}

.date-picker-margins {
  margin-right: 20px;
  margin-top: 2px;
}
</style>
