<template lang="pug">
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          type="button"
          v-if="isDirty && this.collectData.length"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Пользователи:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="patient_full_name"
                    placeholder="Введите ФИО"
                    autocomplete="off"
                    id="id"
                  )
              template
              .row.flex-column.align-items-center.g-3.mt-3(v-if="currentUser.role==='ADMIN'")
                .col
                  .form__caption.mb-3 Организация:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="company_name"
                    placeholder="Введите название организции"
                    autocomplete="off"
                    id="id"
                  )
              template
              .row.flex-column.align-items-center.g-3.mt-3(v-if="currentUser.role==='CLIENT'")
                .col
                  app-select(
                      ref="selectDocTypes"
                      :type="'form'"
                      :placeholder="'Тип документа'"
                      :options="doc_types"
                      @select="selectDocTypes"
                    )
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Медработник:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="doctor_full_name"
                    placeholder="Введите ФИО"
                    autocomplete="off"
                    id="id"
                  )
              template
              .row.flex-column.align-items-center.g-3
                .col
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="pre_diagnosis"
                    placeholder="Предварительный диагноз"
                    autocomplete="off"
                    id="id"
                  )
              template(v-if="options.date")
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Интервал даты создания документа:
                  .row.g-3
                    .col-7
                      date-picker(
                        v-model="form.date.issueDateFrom"
                        prefix-class="app"
                        placeholder="от"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableAfterToday"
                        @change="datepickerChange('issueDateFrom')"
                        @clear="datepickerClear('issueDateFrom')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")

                    .col-7
                      date-picker(
                        v-model="form.date.issueDateTo"
                        prefix-class="app"
                        placeholder="до"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableExpiration"
                        @change="datepickerChange('issueDateTo')"
                        @clear="datepickerClear('issueDateTo')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")

      // submit button
      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !this.collectData.length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
        )
          span.button__content
            span Показать

</template>

<script>
import {toLocalDateExam} from '@/helpers/utils';
import is from 'is_js';

import simplebar from 'simplebar-vue';
import Slider from '@vueform/slider/dist/slider.vue2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppMultipleSelectFull from '@/components/common/MultipleSelectFull';
import AppSelect from '@/components/common/Select';
import AppTooltip from '@/components/common/Tooltip';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';

export default {
  name: 'AppFilterPageOutgoing',
  components: {
    AppTooltip,
    AppSelect,
    AppMultipleSelect,
    AppValidationErrors,
    DatePicker,
    Slider,
    simplebar,
    AppMultipleSelectFull,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      style: {
        top: null,
      },
      delay: 2000,
      patient_full_name: '',
      company_name: '',
      doctor_full_name: '',
      pre_diagnosis: '',
      anchor: '#isAnchorForBtn',
      form: {
        isDirty: {
          status: false,
          issueDateFrom: false,
          issueDateTo: false,
          expirationDateFrom: false,
          expirationDateTo: false,
          timeFrom: false,
          timeTo: false,
          patient_full_name: false,
          company_name: false,
          doctor_full_name: false,
          pre_diagnosis: false,
          doc_type_selected: false,
        },
        doc_type_selected: null,
        status: null,
        date: {
          issueDateFrom: null,
          issueDateTo: null,
          expirationDateFrom: null,
          expirationDateTo: null,
        },
      },
      document_statuses: [
        {
          caption: 'Подтверждено',
          icon: 'allright',
          value: 'confirmed',
          selected: false,
        },
        {
          caption: 'На подтверждении',
          icon: 'allright',
          value: 'awaiting',
          selected: false,
        },
        {
          caption: 'Отклонено',
          icon: 'allright',
          value: 'rejected',
          selected: false,
        },
        {
          caption: 'В архиве',
          icon: 'allright',
          value: 'archived',
          selected: false,
        },
      ],
      doc_types: [
        {
          caption: 'Направление на МО',
          icon: 'allright',
          value: 'alco',
          selected: false,
        },
        {
          caption: 'Справка',
          icon: 'allright',
          value: 'med',
          selected: false,
        },
      ],
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    isDirty() {
      return Object.values(this.form.isDirty).some((item) => item === true);
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    collectData() {
      let data = '';
      //patient_full_name
      if (this.patient_full_name !== '') {
        data += `&patient_full_name=${this.patient_full_name}`;
      }
      //company_name
      if (this.company_name !== '') {
        data += `&company_name=${this.company_name}`;
      }
      //doctor_full_name
      if (this.doctor_full_name !== '') {
        data += `&doctor_full_name=${this.doctor_full_name}`;
      }
      //pre_diagnosis
      if (this.pre_diagnosis !== '') {
        data += `&pre_diagnosis=${this.pre_diagnosis}`;
      }
      //datepicker issue
      if (this.form.date.issueDateFrom) {
        data += `&created_at_from=${this.$moment(this.form.date.issueDateFrom).format(
          'YYYY-MM-DD'
        )} 00:00:00`;
      }
      if (this.form.date.issueDateTo) {
        data += `&created_at_to=${this.$moment(this.form.date.issueDateTo).format(
          'YYYY-MM-DD'
        )} 00:00:00`;
      }
      //doc_type
      if (this.form.doc_type_selected) {
        data += `&doc_type=${this.form.doc_type_selected}`;
      }

      return data;
    },
  },
  watch: {
    isDirty(value) {
      if (!value) this.$emit('clear');
    },
    patient_full_name: function () {
      this.form.isDirty.patient_full_name = true;
    },
    company_name: function () {
      this.form.isDirty.company_name = true;
    },
    doctor_full_name: function () {
      this.form.isDirty.doctor_full_name = true;
    },
    pre_diagnosis: function () {
      this.form.isDirty.pre_diagnosis = true;
    },
  },
  methods: {
    //datepicker
    datepickerChange(slug) {
      this.form.isDirty[slug] = true;
      this.collectData;
    },
    datepickerClear(slug) {
      this.form.isDirty[slug] = false;
      this.form.isDirty[slug] = false;
      this.$emit('submit', this.collectData);

      if (slug === 'dateTo') {
        this.form.time.to = null;
        this.form.isDirty.timeTo = false;
      }
      if (slug === 'dateFrom') {
        this.form.time.from = null;
        this.form.isDirty.timeFrom = false;
      }
    },
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.form.date.issueDateTo) {
        return date > today;
      } else if (this.form.date.issueDateTo < today) {
        return date > this.form.date.issueDateTo;
      } else {
        return date > today;
      }
    },
    disableExpiration(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < this.form.date.issueDateFrom;
    },

    disableAfterTodayExp(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.form.date.expirationDateTo) {
        return date > today;
      } else if (this.form.date.expirationDateTo < today) {
        return date > this.form.date.expirationDateTo;
      } else {
        return date > today;
      }
    },
    disableExpirationExp(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < this.form.date.expirationDateFrom;
    },
    //doc_type
    selectDocTypes(option) {
      this.doc_types.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.doc_type_selected = true;
      this.form.doc_type_selected = option.value;
    },
    setBtnPosition(anchor) {
      const el = document.querySelector(anchor || this.anchor);
      this.style.top = el ? `${el.getBoundingClientRect().y}px` : null;
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      if (this.isTouchDevice) this.submit();
    },
    async clear() {
      //patient_full_name
      this.patient_full_name = '';
      this.form.isDirty.patient_full_name = false;

      //company_name
      this.company_name = '';
      this.form.isDirty.company_name = false;

      //doctor_full_name
      this.doctor_full_name = '';
      this.form.isDirty.doctor_full_name = false;

      //pre_diagnosis
      this.pre_diagnosis = '';
      this.form.isDirty.pre_diagnosis = false;

      //datepicker
      this.form.date.issueDateFrom = null;
      this.form.date.issueDateTo = null;
      this.form.isDirty.issueDateFrom = false;
      this.form.isDirty.issueDateTo = false;
      this.form.date.expirationDateFrom = null;
      this.form.date.expirationDateTo = null;
      this.form.isDirty.expirationDateFrom = false;
      this.form.isDirty.expirationDateTo = false;

      //doc_type
      if (this.currentUser.role === 'CLIENT') {
        this.form.doc_type_selected = null;
        this.form.isDirty.doc_type_selected = false;
        this.$refs.selectDocTypes.clear();
      }

      this.$emit('submit', '');
    },
    //common
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    async submit() {
      if (is.falsy(this.isDirty) || !this.collectData.length) {
        this.$emit('submit', '');
      } else {
        this.$emit('submit', this.collectData);
      }
    },
  },
};
</script>

<style lang="scss">
$bg-color: #0c1120;

.profile-filter {
  pointer-events: none;
  position: fixed;
  z-index: get($index, xl);
  top: 0;
  right: 0;
  width: 16.66667%;
  height: 100%;
  padding-top: $header-height;
  @include transition(transform);
  transform: translateX(85%);

  &:before {
    @include pseudo-wrapper();
    background-color: rgba($bg-color, 0.8);
    backdrop-filter: blur(4px);
    @include transition();
    opacity: 0;
  }
  @include mediaToDown($xxl) {
    width: 25%;
  }
  @include mediaToDown($xl) {
    width: 50%;
    transform: translateX(100%);

    &.is-open {
      .profile-filter {
        &__container {
          box-shadow: get($shadows, default);
        }
      }
    }
  }
  @include mediaToDown($md) {
    padding-top: 0;
    width: 100%;

    &.is-open {
      padding-top: $spacer;
      padding-left: $spacer;
      padding-right: $spacer;

      &:before {
        opacity: 1;
        transition-delay: #{$transition-duration}s;
      }
    }
  }
  &.is-open {
    transform: translateX(0);

    .profile-filter {
      &__content {
        opacity: 1;
        visibility: visible;
      }
      &__clear-button,
      &__open-button-mobile {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__container {
    position: relative;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: $border-radius-sm 0 0 0;
    color: get($colors, blue);
    padding-top: $spacer * 1.3;
    @include transition();

    @include mediaToDown($xl) {
      box-shadow: none;
    }
  }
  &__header {
    width: 100%;
  }
  &__content {
    position: relative;
    @include transition();
    opacity: 0;
    visibility: hidden;
    height: 90%;
  }
  &__form {
    max-height: 90%;
    overflow-x: hidden;
    padding-bottom: $spacer;
  }
  &__submit-button {
    position: relative;
    width: 105%;
    left: -5%;
    padding: $spacer * 1.2 $spacer * 1.2 $spacer * 1.2 $spacer * 2.3;
    border: $border-width solid get($colors, blue-light);
    border-radius: $border-radius-lg 0 0 0;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);

    @include mediaToDown($xl) {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
    }
    @include mediaToDown($md) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__count {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
  }
  &__clear-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 15%;
    background: get($gradients, orange);
    color: get($colors, white);
    padding: $spacer 0;
    @include transition();

    @include interaction('mouse') {
      &:hover {
        box-shadow: get($shadows, default);
      }
    }
  }
  &__open-button,
  &__close-button {
    @include transition(color);

    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);
      }
    }
  }
  &__open-button-mobile {
    position: absolute;
    z-index: get($index, default);
    width: $spacer * 4.4;
    height: $spacer * 4.4;
    right: 100%;
    top: 0;
    background-color: get($colors, white);
    border-radius: $spacer * 3 $spacer * 1.6 0 $spacer * 3;
    padding: $spacer;
    box-shadow: get($shadows, default);
    transition: background-color #{$transition-duration}s, color #{$transition-duration}s;
    transform: translateY($spacer);

    &.is-dirty {
      background-color: get($colors, blue-dark);
      color: get($colors, white);
    }
  }
  .icon {
    &__filter {
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
  }
}
</style>
