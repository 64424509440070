
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Редактирование медработника<br>{{ doctor.full_name }}

        .px-md-4
          form(@submit.prevent :class="['form', {'is-submitting': isSubmitting}]")
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .row.g-4
              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }, {'is-changed': form.isChanged.firstName}]"
                    type="text"
                    v-model="v$.form.firstName.$model"
                    @keyup="changeField('firstName')"
                    placeholder="Имя"
                  )
                ul.form__error(v-if="v$.form.firstName.$errors.length")
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }, {'is-changed': form.isChanged.lastName}]"
                    type="text"
                    v-model="v$.form.lastName.$model"
                    @keyup="changeField('lastName')"
                    placeholder="Фамилия"
                  )
                ul.form__error(v-if="v$.form.lastName.$errors.length")
                  li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', {'is-changed': form.isChanged.middleName}]"
                    type="text"
                    v-model="form.middleName"
                    @keyup="changeField('middleName')"
                    placeholder="Отчество"
                  )

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.email.$errors.length }, {'is-changed': form.isChanged.email}]"
                    type="email"
                    v-model="v$.form.email.$model"
                    @keyup="changeField('email')"
                    placeholder="E-mail"
                  )
                ul.form__error(v-if="v$.form.email.$errors.length")
                  li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'email'") {{ v.errors.email }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.password.$errors.length }, {'is-changed': form.isChanged.password}]"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="v$.form.password.$model"
                    @keyup="changeField('password')"
                    placeholder="Новый пароль"
                  )
                  span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                    so-icon(v-if="showPassword" :icon="'eye-open'")
                    so-icon(v-else :icon="'eye-close'")
                ul.form__error(v-if="v$.form.password.$errors.length")
                  li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                    template(v-if="v$.form.password.$model")
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                      template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                      template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                      template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                      template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="v$.form.confirmPassword.$model"
                    placeholder="Повторите пароль"
                  )
                  span(class="form__icon cursor-pointer" @click="showPassword = !showPassword")
                    so-icon(v-if="showPassword" :icon="'eye-open'")
                    so-icon(v-else :icon="'eye-close'")

                ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                  li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="(v$.form.confirmPassword.$model) && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

              .col-14.col-md-7
                .text-size--small.mb-3 Снилс медработника
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.snils.$errors.length }, {'is-changed': form.isChanged.snils}]"
                    type="text"
                    v-mask="'###-###-###-##'"
                    v-model="v$.form.snils.$model"
                    @keyup="changeField('snils')"
                    placeholder="СНИЛС"
                  )
                ul.form__error(v-if="v$.form.snils.$errors.length")
                  li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.snils.required }}
                    template(v-else-if="error.$validator === 'minLength'") {{ v.errors.snils.minLength }}
                    template(v-else-if="error.$validator === 'checkDigit'") {{ v.errors.snils.checkDigit }}

              .col-14.col-md-7
                .text-size--small.mb-3 Документ о медицинском образовании
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.seriesNumber.$errors.length }, {'is-changed': form.isChanged.seriesNumber}]"
                    type="text"
                    v-model="v$.form.seriesNumber.$model"
                    @keyup="changeField('seriesNumber')"
                    placeholder="Серия и номер"
                  )
                ul.form__error(v-if="v$.form.seriesNumber.$errors.length")
                  li(v-for="error of v$.form.seriesNumber.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="v$.form.seriesNumber.$model && error.$validator === 'valid'") {{ v.errors.invalid }}

              .col-14
                .text-size--small.mb-3 Сертификат о подготовке медработника
                .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                  input(
                    class="d-none"
                    type="file"
                    name="document"
                    @change="onFileSelected"
                    @click="v$.$touch()"
                    ref="fileInput"
                    accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                  )

                  div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                    span.flex-shrink-0.me-3
                      so-icon(icon="document")

                    ul.flex-grow-1.overflow-hidden
                      li.form__file-name.text-color--black {{ form.file.name ? form.file.name : 'Документ' }}
                      li.mt-1(v-if="form.file.size") {{ Number(form.file.size/1000000).toFixed(2) }} mb

                    button(
                      type="button"
                      @click="clearFileInput"
                      class=['form__file-delete', 'flex-shrink-0 ms-3']
                    )
                      so-icon(icon="trash")

                  .text-color--blue.text-size--small(v-else) Приложите скан документа

                  button(
                    class=['form__file-button', 'd-flex justify-content-end align-items-center']
                    @click="$refs.fileInput.click()"
                  )
                    so-icon(icon="clip")

                ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                  li(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .row.g-5.mt-0
              .col-14
                .mb-4
                  app-title Назначить в медорганизацию
                  p.text-size--small.text-color--blue.mt-2 Назначьте медработника в выбранную медорганизацию

                app-select(
                  ref="selectMedOrganization"
                  :type="'form'"
                  :placeholder="'Выберите'"
                  :options="medOrganizationsOptions"
                  @select="selectMedOrganization"
                )

            .col-14.mt-5
              button(
                type="submit"
                @click="onSubmit"
                :disabled="isFormValidAndUnchanged"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Сохранить

  // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Данные успешно изменены
        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeSuccessModal"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад
