
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close" :classes="`modal-refuse`")
    template(v-slot:body)
      .row
        .col-14
          .text-center.pt-4
            app-title(:type="'large'") Изменить индивидуальные границы?
            p.text-color--blue.mt-3.text-uppercase Для пользователя {{ data.patient_full_name }}
        .col-14.mt-4.mb-3(v-if='toggle')
          li.profile-table__row(v-for="border of bordersData" :key="border.num")
            section(class=['user-card', 'user-card-history'])
              .row.align-items-center.g-4.gy-xl-0
                .col-1
                    div {{ border.num }}
                .col-4
                    div {{ border.name }}
                .col-5
                    span.presonal-border-text от
                    input(
                        :class="['personal-border', 'form__field']"
                        type="text"
                        placeholder=""
                        v-model="border.min_value"
                    )
                    span.presonal-border-text до
                    input(
                        :class="['personal-border', 'form__field']"
                        type="text"
                        placeholder=""
                        v-model="border.max_value"
                    )
                .col-3
                    date-picker(
                        prefix-class="app"
                        placeholder="до"
                        format="DD.MM.YYYY"
                        :editable="true"
                        v-model="border.end_date"
                        value-type="YYYY-MM-DD"
                    )
                    //data[border.id].max_val
        template(v-if='!toggle')
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="rejectEditBorders"
                type="button"
                class=['button form__button button--border-blue']
              )
                span.button__content Нет
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="toggle = true"
                type="button"
                class=['button button--accent w-100']
              )
                span.button__content Да
        template(v-else)
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="rejectEditBorders"
                type="button"
                class=['button form__button button--border-blue']
              )
                span.button__content Отменить
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="confirmEditBorders"
                type="button"
                class=['button button--accent w-100']
              )
                span.button__content Подтвердить
