export const errors = {
  user_or_pass_incorrect: 'Неверный e-mail или пароль',
  user_not_found: 'Неверный e-mail или пароль',
  not_approved: 'Дождитесь рассмотрения заявки',
  password_is_malformed: 'Пароль должен быть не короче 6 символов и содержать цифры и буквы верхнего и нижнего регистра',
  operation_not_authorized: 'Старый пароль введен неверно',
  request_not_found: 'Заявки с таким e-mail не существует',
  not_found: 'Заявка не найдена',
  request_is_not_pending: 'Данная ссылка уже была использована ранее',
  such_an_email_is_already_taken: 'Данный e-mail уже зарегистрирован',
  no_access_to_the_patient: 'Нет доступа к пациенту',
  such_an_email_or_phone_is_already_taken: 'Данный e-mail или номер телефона уже зарегистрирован',
  email_or_phone_must_be_filled: 'Поле e-mail или номер телефона должно быть заполнено',
  such_an_serial_number_driving_license_is_already_taken: 'Данное водительское удостоверение уже зарегистрировано',
  such_an_inn_number_is_already_taken: 'Данный ИНН уже зарегистрирован',
  email_is_not_registered: 'Данный e-mail не зарегистрирован',
  device_exists: 'Заявка уже существует',
  device_already_exists: 'Устройство уже существует',
  device_not_found: 'Устройство было удалено ранее',
  device_double: 'Устройства дублируются',
  a_device_serial_must_be_unique: 'Устройство с таким серийным номером уже существует',
  websocket_connection_error: 'Не получилось подсоединиться к очереди, перезагрузите страницу и попробуйте еще раз',
  consumer_already_connected: 'Медработник уже покдлючен к данной очереди',
  stomp_died: 'Смена была завершена из-за отсутствия активности. Попробуйте подключиться снова.',
  Conflict: 'Данный СНИЛС уже существует',
  esia_unavailable: 'Портал ЕСИА недоступен. Войдите с помощью email',
  certificate_expiry_date_ukvp: 'Вход с помощью ЕСИА временно недоступен. Войдите с помощью email',
  cryptopro_csp_license_expired: 'Вход с помощью ЕСИА временно недоступен. Войдите с помощью email',
  incorrect_esia_username_or_password: 'Ошибка входа с помощью ЕСИА. Повторите или войдите с помощью email',
  user_failed_to_provide_second_authentication_factor_sms_password: 'Ошибка входа с помощью ЕСИА. Повторите или войдите с помощью email',
  user_declined_to_share_snils: 'Ошибка доступа к данным учётной записи ЕСИА. Повторите вход или войдите с помощью email',
  user_is_not_registered_in_the_system: 'Пользователь не зарегистрирован',
  phone_is_not_unique: 'Данный мобильный телефон уже зарегистрирован',
  medical_license_is_not_unique: 'Данная лицензия уже зарегистрирована',
  email_is_not_unique: 'Данный e-mail уже зарегистрирован',
  a_integrity_control_sum_and_version_must_be_unique: 'Сумма или версия прошивки должны быть уникальными'
};
