
.row.justify-content-center
  .col-14.col-xl-12.col-xxl-10
    .text-center.mb-5
      app-title(
        :type="'large'"
        :classes="'mb-3'"
      ) Придумайте новый пароль

    app-validation-errors(
      v-if='validationErrors'
      :classes="'mb-3'"
      :validation-errors='validationErrors'
    )
    form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
      .row.flex-column.align-items-center
        .col.mb-4
          .position-relative
            .form__hint.d-none.d-md-block
              app-tooltip(
                :classes="'text-color--accent'"
                :placement="'left'"
                :content="v.errors.password.requirements"
              )
            label.form__label
              input(
                :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                :type="showPass ? 'text' : 'password'"
                v-model="v$.form.password.$model"
                placeholder="Пароль"
              )
              span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                so-icon(v-if="showPass" :icon="'eye-open'")
                so-icon(v-else :icon="'eye-close'")

          ul.form__error
            li(v-for="error of v$.form.password.$errors" :key="error.$uid")
              template(v-if="error.$validator === 'required'") {{ v.errors.required }}
  
              template(v-if="v$.password.$model")
                template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

          app-tooltip(
            :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
            :placement="'right'"
            :content="v.errors.password.requirements"
          )
            template(v-slot:body)
              span.ms-2 Требования к паролю

        .col.mb-5
          label.form__label
            input(
              :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
              :type="showPass ? 'text' : 'password'"
              v-model="v$.form.confirmPassword.$model"
              placeholder="Повторите пароль"
            )
            span(class="form__icon cursor-pointer" @click="showPass = !showPass")
              so-icon(v-if="showPass" :icon="'eye-open'")
              so-icon(v-else :icon="'eye-close'")

          ul.form__error
            li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
              template(v-if="error.$validator === 'required'") {{ v.errors.required }}
              template(v-if="v$.form.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

        .col.mb-4
          button(
            type="submit"
            :disabled="v$.$invalid"
            class=['button', 'button--accent', 'form__button']
          )
            span.button__content Восстановить доступ и войти
        .col-auto
          router-link(:to="{name: 'login'}" class=['text-center', 'text-color--blue-dark text-decoration--underline text-size--small']) Войти в личный кабинет

  // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="$router.push({name: 'dashboard'})" classes="small")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Пароль успешно изменён!
          p.text-size--small.text-color--blue.mt-3 Используйте новый пароль при следующей авторизации

        .d-flex.justify-content-center.mt-5
          button(
            @click="$router.push({name: 'dashboard'})"
            type="button"
            class=['button button--accent', 'w-100']
          )
            span.button__content В личный кабинет

