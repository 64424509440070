<template lang="pug">
.inspection-card__table.col-14
  .row
    .col-14
      .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column(
          @click="getDocuments"
          class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
        ) Медицинские документы
        span.d-flex.align-items-center.ms-3

      .profile-table
        transition(name="translucent")
          .profile-table(v-if="showDocs")
            .profile-table__header.d-none.d-xl-block
              template(v-if="incomingDocs.length || outcomingDocs.length")
                ul.text-size--normal

                  // Входящие документы
                  template(v-if="incomingDocs.length") Входящие документы
                    li.text-size--small(v-for="doc in incomingDocs.slice(0, pagination.incomingDocs.total)" :key="doc.text")
                      a(@click="download(doc)" href="#") {{ doc.text }}
                    div.mb-2
                      button(
                        v-if="pagination.incomingDocs.total < incomingDocs.length"
                        type="button"
                        @click="loadMoreIncomingDocuments"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ Math.min(pagination.incomingDocs.offset, incomingDocs.length - pagination.incomingDocs.total) }})

                      button(
                        v-if="pagination.incomingDocs.total > pagination.incomingDocs.start"
                        type="button"
                        @click="clearPagination('incomingDocs')"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть

                  // Исходящие документы
                  template(v-if="outcomingDocs.length") Исходящие документы
                    li.text-size--small(v-for="doc in outcomingDocs.slice(0, pagination.outcomingDocs.total)" :key="doc.text")
                      a(@click="download(doc)" href="#") {{ doc.text }}
                    div.mb-2
                      button(
                        v-if="pagination.outcomingDocs.total < outcomingDocs.length"
                        type="button"
                        @click="loadMoreOutcomingDocuments"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ Math.min(pagination.outcomingDocs.offset, outcomingDocs.length - pagination.outcomingDocs.total) }})

                      button(
                        v-if="pagination.outcomingDocs.total > pagination.outcomingDocs.start"
                        type="button"
                        @click="clearPagination('outcomingDocs')"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть
              div(v-else-if="isLoading") Документы загружаются

              template(v-else)
                div Документы отсутствуют

</template>

<script>
import patientApi from '@/api/patients';
import { downloadDocs } from '@/helpers/utils';

export default {
  name: 'AppPatientMedDocs',
  components: {

  },
  props: {
    patientCode: {
      type: String,
      required: false,
      default: null,
    },
    showDocInfo: {
      required: false,
      type: Function,
    },
  },
  data() {
    return {
      isActiveIcon: false,
      showDocs: false,
      isLoading: false,
      incomingDocs: [],
      outcomingDocs: [],
      currentPage: 0,
      pagination: {
        incomingDocs: {
          start: 3,
          total: 3,
          offset: 10,
        },
        outcomingDocs: {
          start: 3,
          total: 3,
          offset: 10,
        },
      },
    };
  },
  methods: {
    async getDocuments() {
      try {
        this.isActiveIcon = !this.isActiveIcon;
        this.showDocs = !this.showDocs;
        if (!this.incomingDocs.length || !this.outcomingDocs.length) {
          this.isLoading = true;
        }
        const [incoming, outcoming] = await Promise.all([
          patientApi.docsForExam(this.patientCode, 'incoming', this.currentPage),
          patientApi.docsForExam(this.patientCode, 'outcoming', this.currentPage),
        ]);

        this.incomingDocs = incoming.data.items;
        this.outcomingDocs = outcoming.data.items;

        this.isLoading = false;
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Доступ запрещен. Пожалуйста, обновите страницу или выполните вход снова.');
        } else {
          console.error('Ошибка при получении документов:', error);
        }
        this.isLoading = false;
      }
    },
    async downloadFiles(doc) {
      try {
        await this.downloadFile(doc.file, 'main_file.pdf');
        if (doc.protocol_file) {
          await this.downloadFile(doc.protocol_file, 'protocol_file.pdf');
        }
      } catch (error) {
        console.error('Ошибка при скачивании файлов:', error);
      }
    },
    download(data) {
      downloadDocs(data, ['file', 'protocol_file'])
    },

    async loadMoreIncomingDocuments() {
      this.pagination.incomingDocs.total += this.pagination.incomingDocs.offset;
      this.currentPage += 1;
      await this.loadDocuments('incoming');
    },

    async loadMoreOutcomingDocuments() {
      this.pagination.outcomingDocs.total += this.pagination.outcomingDocs.offset;
      this.currentPage += 1;
      await this.loadDocuments('outcoming');
    },

    async loadDocuments(documentType) {
      try {
        this.isLoading = true;
        const response = await patientApi.docsForExam(
          this.patientCode,
          documentType,
          this.currentPage
        );

        if (documentType === 'incoming') {
          this.incomingDocs = [...this.incomingDocs, ...response.data.items];
        } else if (documentType === 'outcoming') {
          this.outcomingDocs = [...this.outcomingDocs, ...response.data.items];
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Ошибка при получении документов:', error);
      }
    },

    clearPagination(document_group) {
      this.pagination[document_group].total = this.pagination[document_group].start;
      this.currentPage = 0;
    },
  },
};
</script>

<style lang="scss">
.block-history-title:hover {
  cursor: pointer;
}

.user-card-history {
  padding: 10px 0;
  color: #505e8e;
}
</style>
