
li.profile-table__row
    section(
        class=['user-card', 'user-card-history']
    )
      .row.align-items-center.g-4.gy-xl-0
        .col-1
            div {{ id }}
        .col-4
            div {{ name }}
        .col-4
            span.presonal-border-text от
            input(
                :class="['personal-border', 'form__field']"
                type="text"
                placeholder=""
                v-model="bottomValue"
            )
            span.presonal-border-text до
            input(
                :class="['personal-border', 'form__field']"
                type="text"
                placeholder=""
                v-model="topValue"
            )
        .col-3
            date-picker(
                prefix-class="app"
                placeholder="от"
                format="DD.MM.YYYY"
                :editable="true"
                v-model="end_date"
                value-type="DD.MM.YYYY"
            )
        .col-2
          button(
            type="button"
            class=['text-decoration--underline text-size--small link--accent']
          ) Сохранить
