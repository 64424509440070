
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center'"
        ) Пользователи

      .col-auto.ms-auto.d-none.d-xl-block
        button(
        type="button"
        class=['button button--border-blue']
        @click="openModal('createPatients')"
      )
          span.button__content
           span.d-flex.flex-shrink-0.me-3
            so-icon(icon="plus-thin")
           span Добавить пользователя

  .profile-patients__content.mt-20.position-relative
    transition(name="translucent")
      app-loading(v-if="isLoading")

      div(v-if="patientsLocal")
        template(v-if="patientsLocal  && patientsLocal.length")
          .profile-table.is-exams-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.g-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    type="button"
                    @click="sortingPatients(column.key, column.inverse)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span.text-overflow(:title="column.caption") {{ column.caption }}
                      span.d-flex.ms-3
                        so-icon(icon="down-arrow")


            ul.profile-table__body
              li.profile-table__row(v-for="patient in patientsLocal" :key="patient.code")
                router-link(:to="{name: 'patient-detail', params: {id: patient.code}}" class=['d-block'])
                  app-admin-patient-card(
                    :data="patient"
                  )

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        template(v-else)
          .text-color--blue.text-size--small Пользователи не найдены

  // create patients modal
  app-create-patients-modal(
    :is-visible="isModalVisible.createPatients"
    @open="openModal('createPatients')"
    @close="closeModal('createPatients')"
    @openGallery="openGallery"
    @success="getPatients()"
  )
  // filter
  admin-patients-filter(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilter"
  )
