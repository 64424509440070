
simplebar.police
  .container-fluid
    .row.flex-column.align-items-center
      .col-14.col-md-9.col-lg-12.col-xl-10.col-xxl-7
        router-link(:to="{name: 'home'}")
          app-logo

        .mt-50.mt-md-100
          app-police-exam-detail

