
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Замена фотографии для пользователя<br>{{ patient.full_name }}
          p.text-size--small.text-color--blue.mt-3 Фотография должна быть размером не менее 300*300 px, формата JPEG или PNG, на фото должно быть четко видно лицо пользователя без лишних деталей. Подставьте лицо приблизительно в рамки

        .row.justify-content-center
          .col-14.col-xl-10
            form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
              app-validation-errors(
                v-if="localValidationErrors"
                :validation-errors="localValidationErrors"
                :classes="'mb-3'"
              )
              .row.align-items-center.flex-wrap.g-5
                .col-14
                  .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                    input(
                      class="d-none"
                      type="file"
                      name="document"
                      @change="onFileSelected"
                      @click="v$.$touch()"
                      ref="fileInput"
                      accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                    )
                    div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                      span.flex-shrink-0.me-3
                        so-icon(icon="image")

                      ul.flex-grow-1.overflow-hidden
                        li.form__file-name.text-color--black {{ form.file.name }}
                        li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                      button(
                        type="button"
                        @click="clearFileInput"
                        class=['form__file-delete', 'flex-shrink-0 ms-3']
                      )
                        so-icon(icon="trash")

                    .text-color--blue.text-size--small(v-else) Загрузите новую<br>фотографию пользователя

                    button(
                      class=['form__file-button', 'd-flex justify-content-end align-items-center']
                      @click="$refs.fileInput.click()"
                    )
                      so-icon(icon="clip")

                  ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                    li {{ v.errors.required }}

                .col-14
                  button(
                    type="submit"
                    @click="onSubmit"
                    :disabled="v$.$invalid"
                    class=['button', 'button--accent', 'form__button']
                  )
                    span.button__content Готово

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.cropper" @close="closeCropperModal" :classes="'full-content'")
      template(v-slot:body)
        cropper(
          ref="cropper"
          class=['profile-avatar__cropper', 'mt-4']
          :class="{'is-loading': isSubmitting}"
          :src="cropperImg"
          :stencil-props="{aspectRatio: 1/1}"
          :stencil-component="$options.components.CircleStencil"
        )
        .row.align-items-center.justify-content-center.mt-4.g-4
          .col-auto
            button(
              type="button"
              class=['button button--blue']
              @click="onCropping"
            )
              span.button__content Сохранить

          .col-auto
            button(
              type="button"
              class=['button button--border-blue']
              @click="closeCropperModal"
            )
              span.button__content Отмена
