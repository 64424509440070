
button(
  type="button"
  data-bs-toggle="tooltip"
  class=['tooltip-button']
  :class="[classes, {'is-without-icon': withoutIcon}, inline ? 'd-inline' : 'd-flex align-items-center justify-content-center']"
  :title="content"
  ref="tooltip"
  :data-bs-placement="placement"
  :data-bs-trigger="trigger"
  v-click-outside="hide"
)
  so-icon(v-if="!withoutIcon" :icon="icon")
  slot(name="body")
