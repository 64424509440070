<template lang="pug">
.row.align-items-center
  .col-8.mb-4
    app-title(:type="'normal'") {{ title }}
  .row.align-items-center
    .col-8.mb-4
      .form__file.d-flex.align-items-center
        input(
          class="d-none"
          type="file"
          name="document"
          @change="handleSelect"
          ref="fileToRead"
          accept="image/*"
        )
        div(v-if="form.fileToRead" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
          span.flex-shrink-0.me-3
            so-icon(icon="document")
          ul.flex-grow-1.overflow-hidden
            li.form__file-name.text-color--black {{ form.fileToRead.name }}
            li.mt-1 {{ Number(form.fileToRead.size/1024).toFixed(1) }} Kb
          button(
            type="button"
            @click="clearFileInput"
            class=['form__file-delete', 'flex-shrink-0 ms-3']
          )
            so-icon(icon="trash")
        .text-color--blue.text-size--small(v-else) {{ subtitle }}
        button(
          class=['form__file-button', 'd-flex justify-content-end align-items-center']
          @click="$refs.fileToRead.click()"
        )
          so-icon(icon="clip")
    .col-8.mb-4
      button(
        :disabled="!form.fileToRead"
        type="submit"
        class=['button', 'button--accent', 'form__button']
        @click="onSubmit(typeOfProject, linkOfProject)"
      )
        span.button__content Загрузить
  .col-2.align-items-center
    img(:src='imageSrc' :alt="imageAlt")
</template>

<script>
import settingsApi from '@/api/settings';

import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters, mapState} from 'vuex';

import vueHeadful from 'vue-headful';

export default {
  name: 'AppSystemSetupInput',
  components: {
    AppTitle,
    AppMultipleSelect,
    
    vueHeadful,
  },
  data() {
    return {
      user: null,
      imageSrc: '',
      imageAlt: '',
      form: {
        fileToRead: null,
        typeOfProject: '',
        linkOfProject: '',
      },
      linkToUpload: '',
      fileToUpload: null,
    };
  },
  props: {
    title: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    subtitle: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    type: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    typeOfProject: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    linkOfProject: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    fileToRead: {
      type: File,
      required: false,
      default() {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.settings.isLoading,
    }),
  },
  watch: {
    fileToRead() {
      this.form.fileToRead = this.fileToRead;
      this.form.typeOfProject = this.typeOfProject;
      this.form.linkOfProject = this.linkOfProject;
    },
  },
  methods: {
    handleSelect(event) {
      console.log(event);
      this.form.fileToRead = event.target.files[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.form.fileToRead);
      reader.onload = (event) => {
        this.fileToUpload = event.target.result;
      };
    },

    async onSubmit(type_name, type_value) {
      let res = await settingsApi.createProjectSetup({
        type_name: type_name,
        type_value: type_value,
        value: this.form.fileToRead.name,
      });
      this.linkToUpload = res.data.value;
      function uploadFile(file, url) {
        fetch(url, {
          method: 'PUT',
          body: file,
        })
          .then(() => {
            console.log('Файл отправлен');
            location.reload();
          })
          .catch((e) => {
            console.error(e);
          });
      }
      uploadFile(this.fileToUpload, this.linkToUpload);
    },

    clearFileInput() {
      this.form.fileToRead = null;
      this.$refs.fileToRead.value = '';
    },
  },
};
</script>

<style></style>
