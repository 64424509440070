
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Добавить устройство в группу печати талонов {{ data.name }}
      form( :class="['form']")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
            autocomplete(
              :search="search"
              placeholder="Введите серийный номер МИ"
              aria-label="Введите серийный номер МИ"
              :get-result-value="getResultValue"
              @submit="handleSubmit"
              )

          .col-14.col-md-10.mt-5
            button(
              @click.prevent="addDevice"
              type="submit"
              class=['button', 'button--accent', 'form__button']
              :disabled="!filteredDevices.length"
            )
              span.button__content Добавить

