<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(v-if="isVisible" @close="closeForm")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Добавление клиента
            app-validation-errors(
                v-if="localValidationErrors"
                :validation-errors="localValidationErrors"
                :classes="'mb-3'"
            )
          .px-md-4
            form(@submit.prevent="onSubmit")

            .row.flex-column.align-items-center.g-4
              .col
                  app-tab-nav(
                    :tabs="[{id:'company', caption:'Юридическое лицо'}, {id:'person', caption: 'Физическое лицо'}]"
                    :selected="activeTab"
                    :theme="'jointly'"
                    @selected="setActiveTab"
                  )

              app-tab(:is-selected="activeTab === 'company'")
                .row.g-4
                  .col-14.col-md-14
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                        type="text"
                        v-model="v$.form.firstName.$model"
                        placeholder="Полное наименование юридического лица*"
                      )
                    ul.form__error(v-if="v$.form.firstName.$errors.length")
                      li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                  .col-14.col-md-7
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                        type="text"
                        v-model="v$.form.inn.$model"
                        placeholder="ИНН*"
                        v-mask="'##########'"
                      )
                    ul.form__error(v-if="v$.form.inn.$errors.length")
                      li(v-for="error of v$.form.inn.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                  .col-14.col-md-7
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                        type="email"
                        v-model="v$.form.email.$model"
                        placeholder="E-mail*"
                        autocomplete="nope"
                      )
                    ul.form__error(v-if="v$.form.email.$errors.length")
                      li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                        template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                  .col-14.col-md-7
                    .position-relative
                      .form__hint.d-none.d-md-block
                        app-tooltip(
                          :classes="'text-color--accent'"
                          :placement="'left'"
                          :content="v.errors.password.requirements"
                        )
                      label.form__label
                        input(
                          :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                          :type="showPass ? 'text' : 'password'"
                          v-model="v$.form.password.$model"
                          placeholder="Пароль*"
                          autocomplete="nope"
                        )
                        span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                          so-icon(v-if="showPass" :icon="'eye-open'")
                          so-icon(v-else :icon="'eye-close'")

                    ul.form__error(v-if="v$.form.password.$errors.length")
                      li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                        template(v-if="v$.form.password.$model")
                          template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                          template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                          template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                          template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                          template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                    app-tooltip(
                      :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                      :placement="'right'"
                      :content="v.errors.password.requirements"
                    )
                      template(v-slot:body)
                        span.ms-2 Требования к паролю

                  .col-14.col-md-7
                    .position-relative
                      .form__hint.d-none.d-md-block
                      label.form__label
                        input(
                          :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                          :type="showPass ? 'text' : 'password'"
                          v-model="v$.form.confirmPassword.$model"
                          placeholder="Повторите пароль*"
                          autocomplete="nope"
                        )
                        span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                          so-icon(v-if="showPass" :icon="'eye-open'")
                          so-icon(v-else :icon="'eye-close'")

                    ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                      li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                        template(v-if="v$.form.confirmPassword.$model")
                          template(v-if="error.$validator === 'minLength'") {{ v.errors.confirmPassword.minLength }}
                          template(v-if="error.$validator === 'valid'") {{ v.errors.confirmPassword.valid }}
                          template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.confirmPassword.containsUppercase }}
                          template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.confirmPassword.containsLowercase }}
                          template(v-if="error.$validator === 'containsNumber'") {{ v.errors.confirmPassword.containsNumber }}

                    app-tooltip(
                      :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                      :placement="'right'"
                      :content="v.errors.password.requirements"
                    )
                      template(v-slot:body)
                        span.ms-2 Требования к паролю

              app-tab(:is-selected="activeTab === 'person'")
                .row.g-4
                  .col-14.col-md-7
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                        type="text"
                        v-model="v$.form.firstName.$model"
                        placeholder="Имя*"
                      )
                    ul.form__error(v-if="v$.form.firstName.$errors.length")
                      li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                  .col-14.col-md-7
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }]"
                        type="text"
                        v-model="v$.form.lastName.$model"
                        placeholder="Фамилия*"
                      )
                    ul.form__error(v-if="v$.form.lastName.$errors.length")
                      li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                  .col-14.col-md-7
                    label.form__label
                      input(
                        class=['form__field']
                        type="text"
                        v-model="form.middleName"
                        placeholder="Отчество"
                      )

                  .col-14.col-md-7
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                        type="text"
                        v-model="v$.form.inn.$model"
                        placeholder="ИНН*"
                        v-mask="'############'"
                      )
                    ul.form__error(v-if="v$.form.inn.$errors.length")
                      li(v-for="error of v$.form.inn.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                  .col-14.col-md-14
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                        type="email"
                        v-model="v$.form.email.$model"
                        placeholder="E-mail*"
                        autocomplete="nope"
                      )
                    ul.form__error(v-if="v$.form.email.$errors.length")
                      li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                        template(v-if="error.$validator === 'email'") {{ v.errors.email }}


                  .col-14.col-md-7
                    .position-relative
                      .form__hint.d-none.d-md-block
                        app-tooltip(
                          :classes="'text-color--accent'"
                          :placement="'left'"
                          :content="v.errors.password.requirements"
                        )
                      label.form__label
                        input(
                          :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                          :type="showPass ? 'text' : 'password'"
                          v-model="v$.form.password.$model"
                          placeholder="Пароль*"
                          autocomplete="nope"
                        )
                        span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                          so-icon(v-if="showPass" :icon="'eye-open'")
                          so-icon(v-else :icon="'eye-close'")

                    ul.form__error(v-if="v$.form.password.$errors.length")
                      li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                        template(v-if="v$.form.password.$model")
                          template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                          template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                          template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                          template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                          template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                    app-tooltip(
                      :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                      :placement="'right'"
                      :content="v.errors.password.requirements"
                    )
                      template(v-slot:body)
                        span.ms-2 Требования к паролю

                  .col-14.col-md-7
                    .position-relative
                      .form__hint.d-none.d-md-block
                      label.form__label
                        input(
                          :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                          :type="showPass ? 'text' : 'password'"
                          v-model="v$.form.confirmPassword.$model"
                          placeholder="Повторите пароль*"
                          autocomplete="nope"
                        )
                        span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                          so-icon(v-if="showPass" :icon="'eye-open'")
                          so-icon(v-else :icon="'eye-close'")

                    ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                      li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                        template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                        template(v-if="v$.form.confirmPassword.$model")
                          template(v-if="error.$validator === 'minLength'") {{ v.errors.confirmPassword.minLength }}
                          template(v-if="error.$validator === 'valid'") {{ v.errors.confirmPassword.valid }}
                          template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.confirmPassword.containsUppercase }}
                          template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.confirmPassword.containsLowercase }}
                          template(v-if="error.$validator === 'containsNumber'") {{ v.errors.confirmPassword.containsNumber }}

                    app-tooltip(
                      :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                      :placement="'right'"
                      :content="v.errors.password.requirements"
                    )
                      template(v-slot:body)
                        span.ms-2 Требования к паролю

              template
                app-title Назначить в медорганизацию
                .text-size--small.text-color--blue.mt-2 Назначьте медработника в выбранную медорганизацию

                autocomplete(
                  :search="search"
                  placeholder="Выберите"
                  aria-label="Выберите"
                  :get-result-value="getResultValue"
                  @submit="handleSubmit"
                )

              .row.g-5.mt-0
                .col-14
                  button(
                    type="submit"
                    @click="onSubmit"
                    class=['button', 'button--accent', 'form__button']
                    :disabled="!buttonActive"
                  )
                    span.button__content Добавить клиента
    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Новый медработник добавлен

          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeSuccessModal"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content На страницу медработников
</template>

<script>
import medOrganizationsApi from '@/api/medOrganizations';
import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required, minLength, sameAs, email} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppTooltip from '@/components/common/Tooltip';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTabNav from '@/components/common/TabNav.vue';
import AppTab from '@/components/common/Tab.vue';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import {modalsMixin} from '@/mixins/modals';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import {mask} from 'vue-the-mask';
import usersApi from '@/api/users';

export default {
  name: 'AppCreateDoctorModal',
  components: {
    AppTitle,
    AppModal,
    AppTooltip,
    Autocomplete,
    AppMultipleSelect,
    AppValidationErrors,
    DatePicker,
    AppTabNav,
    AppTab
  },
  directives: {
    mask,
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      required: false,
      type: Boolean,
      default: false,
    },
    close: {
      required: true,
      type: Function
    }
  },
  computed: {
  passwordsMatch() {
    return this.form.password === this.form.confirmPassword;
    },
  buttonActive() {
    if (this.activeTab === 'company') {
      return this.companyTabValid;
    } else if (this.activeTab === 'person') {
      return this.personTabValid;
    }
    return false;
  },
  companyTabValid() {
    if (this.v$ && this.v$.form) {
      const form = this.v$.form;
      return (
        !form.$error &&
        form.firstName &&
        form.inn &&
        form.email &&
        this.passwordsMatch &&
        !form.firstName.$invalid &&
        !form.inn.$invalid &&
        !form.email.$invalid &&
        !form.password.$invalid &&
        !form.confirmPassword.$invalid
      );
    }
    return false;
  },
  personTabValid() {
    if (this.v$ && this.v$.form) {
      const form = this.v$.form;
      return (
        !form.$error &&
        form.firstName &&
        form.lastName &&
        form.inn &&
        form.email &&
        this.passwordsMatch &&
        !form.firstName.$invalid &&
        !form.lastName.$invalid &&
        !form.inn.$invalid &&
        !form.email.$invalid &&
        !form.password.$invalid &&
        !form.confirmPassword.$invalid
      );
    }
      return false;
    },

  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      activeTab: 'company',
      form: {
        firstName: null,
        lastName: null,
        middleName: '',
        email: null,
        password: null,
        confirmPassword: null,
        seriesNumber: null,
        file: null,
        users: [],
        inn: null,
        licensenNumber: null,
        dateOfIssue: '',
        expirationDate: '',
        med_organization_id: ''
      },
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        defaultValue: {
          panel: 'year',
          year: '1980',
        },
      },
      showPass: false,
      localValidationErrors: null,
      showModal: this.isVisible,
      medOrganizationName: ''
    };
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          valid: function (value) {
            return this.v.regexes.password.test(value);
          },
          containsUppercase: function (value) {
            return /[A-Z]/.test(value);
          },
          containsLowercase: function (value) {
            return /[a-z]/.test(value);
          },
          containsNumber: function (value) {
            return /[0-9]/.test(value);
          },
          minLength: minLength(6),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs(this.form.password),
        },
        inn: {
          required,
          minLength: minLength(10),
        },
      },
    };
  },
  methods: {
    async search(input) {
      let res = await medOrganizationsApi.getMedOrganizations({
        medical_organization_name: input
      });
      return res.data.items;
    },
    getResultValue(result) {
      this.medOrganizationName = result.name;
      return result.name;
    },
    handleSubmit(option) {
      this.form.med_organization_id = option.id;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    updateSelectedUsers(data) {
      this.form.users = data.map((el) => el.value);
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    resetForm() {
      this.v$.$reset();

      for (const key in this.form) {
        this.form[key] = null;
      }
      this.localValidationErrors = null;
    },
    closeForm() {
      this.resetForm();
      this.close();
      this.showModal = false;
    },
    closeSuccessModal() {
      this.$emit('success');
      this.closeModal('success');
    },
    async onSubmit() {
      let data = {
        'user_type': this.activeTab,
        'inn': this.form.inn,
        'email': this.form.email,
        'password': this.form.password
      };
      if (this.activeTab === 'person'){
        data['first_name'] = this.form.firstName;
        data['middle_name'] = this.form.firstName;
        data['last_name'] = this.form.lastName;
      } else {
        data['company_name'] = this.form.firstName;
      }
      try{
        await usersApi.createUser(data);
        this.closeForm();
        this.$toast.success('Клиент создан');
      } catch {
        this.localValidationErrors = 'error';
      }
    },
    //Отключаем лишние даты
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!this.form.expirationDate) {
        return date > today;
      } else if (this.form.expirationDate < today) {
        return date > this.form.expirationDate;
      } else {
        return date > today;
      }
    },
    disableExpiration(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        return date < today;
      } else {
        return date < this.v$.form.dateOfIssue.$model;
      }
    },
    defaultDate() {
      return new Date().setYear(this.datepicker.defaultValue.year);
    },
  },
};
</script>
