
<div class="client-med-docs__container">
  <app-title :type="'large'">Медицинская документация</app-title>
  <so-tabs v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex">
    <so-tab label="Входящая" name="client-incoming-med-docs">
      <page-references />
    </so-tab>
    <so-tab label="Исходящая" name="client-outgoing-med-docs">
      <page-notices />
    </so-tab>
  </so-tabs>
</div>
