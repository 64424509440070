
.profile-map
  .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-map__header
      .row.align-items-center.flex-column-reverse.flex-md-row.g-4
        .col
          .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
            app-back-button(:classes="'me-5'")
            .row.justify-content-center
              .col-8.col-sm-14
                app-title(
                  :type="'large'"
                  :classes="'text-center'"
                ) Перемещение устройств

        .col-auto
          router-link(:to="{name: 'map'}" class=['d-flex align-items-center', 'text-size--small link--accent'])
            span.d-flex.flex-shrink-0.me-2
              so-icon(icon="map-point")
            span.text-size--small {{ isTouchDevice ? 'Геокарта' : 'Просмотреть геокарту' }}


    .profile-map__content.position-relative.mt-30.mt-md-20
      app-loading(v-if="examsLoading")
      app-error-message(v-if="examsError" :message="examsError")

      transition(name="fade" mode="out-in")
        div(v-if="map.devices && map.devices.length")
          .profile-map__container
            app-loading(v-if="map.loading")
            yandex-map(
              :settings="v.map.default"
              :class="map.class"
              :options="map.options"
              :coords="map.center"
              :scroll-zoom="false"
              :zoom="map.zoom"
              :controls="map.controls"
              :behaviors="map.behaviors"
              @map-was-initialized="initializedMap"
            )
          // devices
          .profile-map__devices.mt-5
            ul.mb-4.text-size--small
              li.mb-1
                span На карте отображены перемещения устройств:&ensp;
                span.me-2.text-family--medium(v-for="device in selectedDevices" :key="device.serial" :style="{'color': device.color}") {{ device.serial }}
              li.mb-3
                span Дата:&ensp;{{ selectedDate ? selectedDate : defaultDate }}
              li.text-family--italic *Для выбора нужной даты или устройства воспользуйтесь фильтром

            swiper(
              class=['profile-map__devices-slider', 'js-map-devices-slider']
              ref="slider"
              :options="slider.swiperOptions"
              :auto-update="true"
              :auto-destroy="false"
              @ready="initializedSlider"
            )
              swiper-slide(v-for="device in map.devices.slice(0, pagination.offset)" :key="device.serial")
                app-map-device-card(
                  ref="device"
                  :data="device"
                  :current-day="currentDay"
                  :trigger="slider.ready"
                  @changeDate="changeDate"
                )
              div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-map-devices-slider-pagination'])

  // filter
  app-filter(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    :count="examsCount"
    @getCount="getCountFilter"
    @open="openFilter"
    @close="closeFilter"
    @clear="clearFilter"
    @submit="submitFilter"
  )
