<template lang="pug">
  .profile-sidebar__content.d-flex.flex-column
    .profile-sidebar__header
      .profile-sidebar__user.d-flex.align-items-center(v-if="currentUser")
        router-link(:to="{name: 'settings'}" class=['d-block', 'me-4'])
          app-avatar(
            :classes="'large'"
            :src="currentUser.avatar"
          )
        ul.text-size--small
          li.mb-1
            span.text-color--gray-light Личный кабинет
          li.mb-1
            app-title(:routerLink="{name: 'settings'}") {{ currentUser.full_name }}
          li
            span {{ currentUser.email }}
        img(v-if="currentUser.from_esia" src="/esia.svg", alt="esia-logotype" width='24').ms-5

      transition(name="translucent")
        .profile-sidebar__system-info.mt-4(v-if="isValidSystemSetup || systemError")
          .d-flex.align-items-center(v-if="isValidSystemSetup")
            ul.me-auto
              li
                span Версия плагина {{ systemInfo.cadesVersion }}
              li
                span Версия CSP {{ systemInfo.cspVersion }}

            span.ms-3.d-flex.flex-shrink-0.text-color--success
              so-icon(icon="check")

          .d-flex.align-items-center(v-if="systemError")
            ul.me-auto
              li.text-color--danger.mb-2
                span Плагин не найден
                span.ms-3
                  so-icon(icon="attention")

              li
                a(
                  href="https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows"
                  target="_blank"
                  rel="nofollow"
                  class=['link--accent', 'text-decoration--underline']
                ) Инструкция по установке плагина


    .d-flex.flex-column.align-items-center.flex-grow-1.pt-4
      ul.profile-sidebar__menu.text-center
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'exams'}" :classes="`d-block profile-sidebar__menu-link ${isActive('exams') ? 'is-active': ''}`")
            span Журнал осмотров
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'exam_queue'}" :classes="`d-block profile-sidebar__menu-link ${isActive('exam_queue') ? 'is-active': ''}`")
            span очередь

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {
  actionTypes as doctorsActionTypes,
  gettersTypes as doctorsGettersTypes,
} from '@/store/modules/doctors';

import variables from '@/helpers/variables';
import {getSystemInfo} from 'crypto-pro';

import AppTitle from '@/components/common/Title';
import AppAvatar from '@/components/profile/common/Avatar';
import AppLoading from '@/components/common/Loading';

import {routes} from '@/router/index.js';

export default {
  name: 'AppDoctorSidebar',
  components: {
    AppLoading,
    AppAvatar,

    AppTitle,
  },
  data() {
    return {
      v: variables,
      systemInfo: null,
      examsPendingCount: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.doctors.isLoading,
      isValidSystemSetup: (state) => state.doctors.isValidSystemSetup,
      systemError: (state) => state.doctors.systemError,
    }),
  },
  created() {
    this.checkValidSystemSetup();

    getSystemInfo()
      .then((info) => (this.systemInfo = info))
      .catch((error) => console.log(error));
  },
  methods: {
    checkValidSystemSetup() {
      this.$store.dispatch(doctorsActionTypes.checkValidSystemSetup);
    },
    isActive(route) {
      this.paths = routes;
      return this.$route.path.includes(route);
    },
  },
};
</script>

<style lang="scss">
.profile-sidebar {
  &__system-info {
    @include font-size(get($text-sizes, small));
    padding: $spacer $spacer * 1.6;
    border-radius: calc($border-radius-lg/2);
    box-shadow: get($shadows, default);
  }
}
</style>
