<template>
  <div
    v-if="shouldBeRender"
    :id="`pane-${name}`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'so-tab',
  props: {
    label: String,
    name: String,
  },
  computed: {
    shouldBeRender() {
      return this.$parent.selectedTab === this.$parent.tabs.indexOf(this);
    }
  },
  created() {
    // Регистрация вкладки при создании компонента
    this.$parent.registerTab(this);
  }
};
</script>
