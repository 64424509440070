import axios from '@/api/axios';

/** Получение всех медицинских организаций */
export type GetMedOrganizationsParams = {
  page?: number,
  per_page?: number,
  client_id?: number,
  medical_organization_id?: number
};
export const getMedOrganizations = (params: GetMedOrganizationsParams) => axios.get('/api/v2/med_organization', { params });

/** Добавление мед организации */
export type SaveMedOrganizationParams = {
  name: string,
  ogrn: string,
  // guid: string,
  medical_license: string,
  medical_license_start: string,
  medical_license_end: string,
  doctor_ids: number[]
};
export const createMedOrganization = (params: SaveMedOrganizationParams) => axios.post('/api/v2/med_organization', params);

/** Добавление мед организации */
export type ChangeMedOrganizationParams = {
  med_organization_id: number
} & SaveMedOrganizationParams;
export const changeMedOrganization = (params: ChangeMedOrganizationParams) => axios.put('/api/v2/med_organization', params);

/** Удаление мед организации */
export type DeleteMedOrganizationsParams = { id: number };
export const deleteMedOrganization = (params: DeleteMedOrganizationsParams) => axios.delete(`/api/v2/med_organization`, { data: params });

/** Прикрепление докторов к мед организации */
export type AttachDoctorParams = {
  med_organization_id: number[],
  doctor_ids: number[]
};
export const attachDoctor = (params: AttachDoctorParams) => axios.post(`api/v2/med_organization/attach/doctors`, params);

/** Открепление докторов от мед организации */
export type DetachDoctorParams = {
  med_organization_id: number[],
  doctor_ids: number[]
};
export const detachDoctor = (params: DetachDoctorParams) => axios.delete(`api/v2/med_organization/detach/doctors`, { data: params });

/** Прикрепление клиентов к мед организации */
export type AttachClientParams = {
  med_organization_id: number[],
  client_ids: number[]
};
export const attachClient = (params: AttachClientParams) => axios.post(`api/v2/med_organization/attach/clients`, params);

/** Открепление клиентов от мед организации */
export type DetachClientParams = {
  med_organization_id: number[],
  client_ids: number[]
};
export const detachClient = (params: DetachClientParams) => axios.delete(`api/v2/med_organization/detach/doctors`, { data: params });

export default {
  getMedOrganizations,
  createMedOrganization,
  changeMedOrganization,
  deleteMedOrganization,
  attachDoctor,
  detachDoctor,
  attachClient,
  detachClient
};
