
<so-tabs v-model="activeIndex" :needTitle="true" @changeTab="changeActiveTab" :selectedTab="activeIndex">
  <so-tab label="Устройства" name="admin-devices">
    <app-admin-devices />
  </so-tab>
  <so-tab label="Контроль целостности ПО" name="devices-control">
    <app-admin-devices-control />
  </so-tab>
  <so-tab label="Поверка СИ" name="devices-check">
    <app-admin-devices-check />
  </so-tab>
  <so-tab label="Группы печати талонов" name="devices-printing-group">
    <app-admin-devices-printing-group />
  </so-tab>
</so-tabs>
