<template lang="pug">
.profile-device-detail
  .profile-device-detail__inner.is-filterable
    app-loading(v-if="isLoading" key="loading")
    .profile-device-detail__header
      .row.align-items-center
        .col-14.col-md-auto.me-auto.order-last.order-md-first
          div
            .row.align-items-center.g-5
              .col-3.col-md-auto
                app-back-button(:prev-page="'devices'")
              .col-7.col-md.flex-grow-1
                app-title(
                  :type="'large'"
                  :classes="'text-center'"
                ) Просмотр группы
    //карточка группы
    .profile-device-detail__card.mt-20.position-relative

      app-admin-printing-group-card(
        :data="printGroup"
        @change="openChangeModal"
        @delete="openDeleteModal"
      )

      transition(name="translucent")
        app-tab-nav(
          :tabs="tabs"
          :selected="activeTabId"
          :theme="'separate'"
          @selected="setActiveTab"
          :classes="'my-20'"
        )

      .position-relative
        template(v-if="!devices && !talons")
          .text-color--blue.text-size--small.mt-20 Нет талонов и устройств

      // devices tab
      transition(name="translucent")
        app-tab(
          v-if="devices"
          :id="devicesTab.id"
          :is-selected="activeTabId === devicesTab.id"
        )
          template(v-if="devices.length")
            .profile-table.is-devices-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in devicesTab.columns" :key="column.key" :class="column.class")
                    button(
                      v-if="column.caption || column.icon"
                      type="button"
                      @click="sortingDevices(column.key)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': devicesTab.sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span(v-if="column.caption") {{ column.caption }}
                        span.flex-shrink-0(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.flex-shrink-0.d-flex.ms-3
                          so-icon(icon="down-arrow")

              ul.profile-table__body(id="print-group-devices")
                li.profile-table__row(v-for="device in devices" :key="device.device_id")
                  section.profile-card
                    .row.align-items-md-center.gy-4.gy-md-0
                      .col-3
                        .text-color--blue.mb-2.d-xl-none Серийный номер МИ
                        span(v-if="device.device_serial") {{ device.device_serial }}
                        span(v-else) &#8212;
                      .col-2.col-md-2
                        .d-flex.align-items-center.justify-content-end.justify-content-md-start
                          span(v-if="device.device_created_at") {{ toLocalDate(device.device_created_at) }}
                          span(v-else) &#8212;
                      .col-2
                        .text-color--blue.mb-2.d-xl-none Владелец
                        span(v-if="device.device_owner_full_name") {{ device.device_owner_full_name }}
                        span(v-else) &#8212;
                      .col-3
                        .text-color--blue.mb-2.d-xl-none Контроль целостности ПО
                        span(v-if="device.device_integrity === null") &#8212;
                        span(v-if="device.device_integrity === true") Пройден
                        span.text-color--orange(v-if="device.device_integrity === false") Не пройден
                      .col-2
                        .text-color--blue.mb-2.d-xl-none Поверка СИ
                        span.text-color--orange(v-if="device.device_attestation === 'attestation_ends'") Заканчивается
                        span.text-color--orange(v-else-if="device.device_attestation === 'not_attestation'") Не поверено
                        span.text-color--blue-dark(v-else-if="device.device_attestation === 'attestation'") Поверено
                        span(v-else) &#8212;
                      .col-1
                        .d-flex.align-items-center
                          .text-color--blue.mb-2.d-xl-none Целостность корпуса МИ
                          span(v-if="device.device_case_opened == null")  &#8212;
                          span(v-if="device.device_case_opened == false") Не вскрыт
                          span.text-color--orange(v-if="device.device_case_opened == true") Вскрыт
                          span.text-color--accent.ms-auto.flex-shrink-0.d-none.d-md-inline
                      .col-auto
                        button(
                          type="button"
                          @click.prevent="deleteDevice(device.device_serial)"
                          class=['profile-card__button profile-card__button--delete no-border', 'd-flex align-items-center justify-content-center']
                        )
                          span.flex-shrink-0
                          so-icon(icon="trash")

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentDevicesPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Устройства не найдены

      // talons tab
      transition(name="translucent")
        app-tab(
          v-if="talons"
          :id="talonsTab.id"
          :is-selected="activeTabId === talonsTab.id"
        )

          template(v-if="talons.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in talonsTab.columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingTalons(column.key)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': talonsTab.sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(v-if="column.caption") {{ column.caption }}
                        span(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.d-flex.ms-3
                          so-icon(icon="down-arrow")
            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.examsPagination.total"
                :limit="pagination.examsPagination.limit"
                :current-page="currentTalonsPage"
                :url="baseUrl"
              )
              ul.profile-table__body
                li.profile-table__row(v-for="talon in talons" :key="talon.id")
                  router-link(:to="{name: 'talon-detail', params: {id: talon.talon_id}}" class=['d-block'])
                    app-admin-user-card(:data="talon")

          template(v-else)
            .text-color--blue.text-size--small Талоны не найдены

  // Edit printing group
  app-edit-printing-group-modal(
    :data="printGroup"
    :is-visible="isModalVisible.editPrintingGroup"
    @close="closeModal('editPrintingGroup')"
    @success="openModal('successEdited')"
    @updateData='getDevices'
  )
      // delete modal
  app-delete-printing-group-modal(
    :groupId="printGroup.id"
    :is-visible="isModalVisible.deletePrintingGroup"
    @close="closeModal('deletePrintingGroup')"
    @updateData='$router.go(-1)'
  )

    // success modals
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successEdited" @close="closeModal('successEdited')" :without-close-btn="true")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Устройство успешно добавлено!

        .d-flex.justify-content-center.mt-5
          button(
            @click="closeModal('successEdited')"
            type="button"
            class=['button button--accent']
          )
            span.button__content Закрыть

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successDeleted" @close="closeModal('successDeleted')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Группа печати талонов успешно удалена
          p.text-size--small.text-color--blue.mt-3
            span.text-family--medium.text-color--blue-dark &ensp; название группы талонов

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeModal('successDeleted')"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад


</template>

<script>
import printGroupApi from '@/api/printGroup';
import AppTitle from '@/components/common/Title';
import AppBackButton from '@/components/common/BackButton';
import AppModal from '@/components/common/Modal';
import AppAdminPrintingGroupCard from '@/components/profile/admin/devices/AdminPrintingGroupCard';
import AppTab from '@/components/common/Tab';
import AppTabNav from '@/components/common/TabNav';
import AppPagination from '@/components/common/Pagination';
import AppLoading from '@/components/common/Loading';

import AppEditPrintingGroupModal from '@/components/profile/admin/devices/AppEditPrintingGroupModal';
import AppDeletePrintingGroupModal from '@/components/profile/admin/devices/AppDeletePrintingGroupModal';
import {modalsMixin} from '@/mixins/modals';

import {toLocalDate} from '@/helpers/utils';

export default {
  name: 'AppAdminPrintingGroupDetail',
  components: {
    AppBackButton,
    AppModal,
    AppTitle,

    AppAdminPrintingGroupCard,
    AppTab,
    AppTabNav,
    AppPagination,
    AppEditPrintingGroupModal,
    AppDeletePrintingGroupModal,
    AppLoading,
  },
  mixins: [modalsMixin],
  props: {},
  data() {
    return {
      groupId: null,
      printGroup: {},
      devices: {},
      talons: {},
      isModalVisible: {
        deleteDevice: false,
        changeDevice: false,
        editPrintingGroup: false,
        deletePrintingGroup: false,
        successDeleted: false,
        successEdited: false,
      },
      sortInverse: false,
      activeTabId: 'devices',
      tabs: [
        {
          id: 'talons',
          caption: 'Талоны',
          sortKey: 'talon_number',
          columns: [],
        },
        {
          id: 'devices',
          caption: 'Устройства',
          sortKey: 'device_created_at',
          columns: [
            {
              class: 'col-3',
              caption: 'Серийный номер МИ',
              key: 'device_serial',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Дата регистрации',
              key: 'device_created_at',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Владелец устройства',
              key: 'device_owner_full_name',
              inverse: false,
            },
            {
              class: 'col-3',
              caption: 'Контроль целостности ПО',
              key: 'device_integrity',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Поверка СИ',
              key: 'device_attestation_status',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Целостность корпуса МИ',
              key: 'device_case_opened',
              inverse: true,
            },
          ],
        },
      ],
      pagination: {
        limit: 10,
        total: 0,
      },
      isLoading: false,
    };
  },
  computed: {
    talonsTab() {
      return this.tabs[0];
    },
    devicesTab() {
      return this.tabs[1];
    },
    baseUrl() {
      return this.$route.path;
    },
    currentDevicesPage() {
      return Number(this.$route.query.page || '1');
    },
    currentTalonsPage() {
      return Number(this.$route.query.page || '1');
    },
  },
  watch: {
    currentDevicesPage() {
      this.getDevices();
    },
    currentTalonsPage() {
      this.getTalons();
    },
  },
  async created() {
    this.getPrintGroupInfo();
  },
  methods: {
    //get data
    async getPrintGroupInfo() {
      this.isLoading = true;
      this.printGroup = {
        name: this.$route.query.additionalData,
        id: Number(this.$route.params.id),
      };
      this.groupId = this.printGroup.id;
      this.getDevices();
    },

    async getDevices() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.devicesTab.sortKey}`;
      let result = await printGroupApi.getPrintGroupDevices(
        this.$route.params.id,
        this.currentDevicesPage - 1,
        order_by
      );
      this.devices = result.data.items;
      this.pagination.total = result.data.total;
      this.isLoading = false;
      this.printGroup.device_serials = this.devices.map((device) => device.device_serial);
    },
    async getTalons() {
      return this.talons;
    },
    //delete
    async deleteDevice(serial) {
      let data = {
        group_id: null,
        device_serials: [serial],
      };
      try {
        await printGroupApi.addDevicePrintGroup(data);
        this.$toast.success('Устройство успешно удалено');
        this.getPrintGroupInfo();
      } catch (error) {
        this.$toast.error(error);
      }
    },
    //tabs
    setActiveTab(id) {
      if (id === 'talons') {
        this.getTalons();
      } else {
        this.getDevices();
      }
      // this.goToFirstPage();
      this.activeTabId = id;
    },
    activeTab() {
      return this.tabs.find((el) => el.id === this.activeTabId);
    },
    //modals
    openChangeModal() {
      this.isModalVisible.editPrintingGroup = true;
    },
    openDeleteModal() {
      this.isModalVisible.deletePrintingGroup = true;
    },
    //sorting
    sortingDevices(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.devicesTab.sortKey = sortKey;
      this.getDevices();
    },
    sortingTalons(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.talonsTab.sortKey = sortKey;
      this.getTalons();
    },
    //common
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.profile-device-detail {
  &__header {
    .icon {
      &__plus {
        width: $spacer * 1.2;
        height: $spacer * 1.2;
      }
      &__map-point,
      &__xls-doc {
        width: $spacer * 2;
        height: $spacer * 2;
        fill: get($colors, blue);
      }
    }
  }
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
