<template lang="pug">
  section.dashboard-card(:class="[{'is-active': goTo}, classes]")
    .dashboard-card__header
      .row.align-items-center
        .col
          app-title
            slot(name="title")

        .col-auto(v-if="goTo && Boolean($slots.button) && Boolean($slots.button[0].text)")
          router-link(
            :to="{name: goTo}"
            class=['text-color--accent link--accent', 'd-flex align-items-center', 'text-uppercase ls-lg']
          )
            span.d-none.d-md-inline
              slot(name="button")
            span.d-md-none Все
            
            span.ms-3.d-flex
              so-icon(icon="right-long-arrow")

    .dashboard-card__content.mt-2
      slot(name="body")


</template>

<script>
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppDashboardCard',
  components: {
    AppTitle,
    
  },
  props: {
    goTo: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.dashboard-card {
  @include mediaToUp($xl) {
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: $border-radius-sm;
    padding: $spacer * 1.5 $spacer * 3;
  }
  &.is-empty {
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: $border-radius-sm;
    padding: $spacer * 1.5 $spacer * 3;

    @include mediaToDown($md) {
      padding: $spacer * 2 $spacer * 1.5 $spacer * 2.5 $spacer * 1.5;
    }
  }
  .profile-table {
    &__header {
      padding: $spacer $spacer * 4 $spacer $spacer * 2;
    }
  }
  .request-card {
    background: get($gradients, blue-light);

    @include mediaToDown($xl) {
      background: get($colors, white);
    }
  }
  .exam-card,
  .patient-card {
    .accordion {
      &__header {
        padding: $spacer $spacer * 4 $spacer $spacer * 2;
      }
      &__button {
        right: $spacer;
      }
    }
  }
  .exam-card {
    &__content {
      &-info-item,
      &-footer {
        padding-right: $spacer;

        @include mediaToDown($xxl) {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
