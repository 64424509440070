<template lang="pug">
.profile-dashboard__content
  // exams
  .position-relative.mb-5
    app-loading(v-if="examsLoading")

    transition(name="translucent")
      app-dashboard-card(
        v-if="exams"
        :goTo="'exams'"
      )
        template(v-slot:title)
          .d-flex.align-items-center.mb-4(v-if="exams.length")
            span.flex-shrink-0.text-color--blue.me-3.d-xl-none
              so-icon(icon="smartwatch" view-box="0 0 17 24")
            span Последние данные с&nbsp;устройств

          .text-color--gray-light(v-else) Здесь будут отображаться последние данные с&nbsp;устройств

        template(v-slot:body)
          template(v-if="exams.length")
            .profile-table.d-none.d-xl-block
              .profile-table__header
                .row.align-items-center.g-4
                  div(v-for="column in columns.exams" :key="column.key" :class="column.class")
                    button(
                      v-if="column.caption || column.icon"
                      type="button"
                      @click="sortingExams(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span(v-if="column.caption") {{ column.caption }}
                        span.flex-shrink-0(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.flex-shrink-0.d-flex.ms-3
                          so-icon(icon="down-arrow")


              ul.profile-table__body(id="dashboard-exams")
                li.profile-table__row(v-for="exam in exams.slice(0, 3)" :key="exam.id")
                  app-exam-card(
                    :data="exam"
                    :parent-id="'dashboard-exams'"
                    :columns="columns.exams"
                    @openModal="openExamDetailModal"
                    @openCertificate="openCertificate"
                  )

            // slider for mobile
            .profile-modile-slider.d-xl-none
              swiper(
                :options="Object.assign({}, slider.common, slider.exams)"
                :auto-update="true"
                :auto-destroy="false"
              )
                swiper-slide(v-for="exam in exams.slice(0, 5)" :key="exam.id")
                  app-exam-card-mobile(:data="exam" @openModal="openExamDetailMobile")

                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])

          .text-size--small.text-color--gray-light(v-else) Пока нет осмотров

        template(v-slot:button)
          template(v-if="exams.length") Все осмотры


  // patients
  .position-relative.mb-5
    app-loading(v-if="patientsLoading")

    transition(name="translucent")
      app-dashboard-card(
        v-if="patients"
        :goTo="'patients'"
      )
        template(v-slot:title)
          .d-flex.align-items-center.mb-4(v-if="patients.length")
            span.flex-shrink-0.text-color--blue.me-3.d-xl-none
              so-icon(icon="users")
            span Последние добавленные пользователи

          .text-color--gray-light(v-else) Здесь будут отображаться последние добавленные пользователи

        template(v-slot:body)
          template(v-if="patients.length")
            .profile-table.d-none.d-xl-block
              .profile-table__header
                .row.align-items-center.g-4
                  div(v-for="column in columns.patients" :key="column.key" :class="column.class")
                    button(
                      v-if="column.caption || column.icon"
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey.patients === column.key}"
                    )
                      span.d-flex.align-items-center
                        span(v-if="column.caption") {{ column.caption }}
                        span(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.d-flex.flex-shrink-0.ms-3
                          so-icon(icon="down-arrow")

              ul.profile-table__body(id="patient-exams")
                li.profile-table__row(v-for="patient in patients" :key="patient.code")
                  app-client-patient-card(
                    :data="patient"
                    @openModal="openExamDetailModal"
                    @openGallery="openGallery"
                    @openCertificate="openCertificate"
                    @delete="openDeletePatientModal"
                    @change="openChangePatientModal"
                    @changeAvatar="openChangeAvatarModal"
                    @changeSignature="openChangeSignatureModal"
                  )
            // slider for mobile
            .profile-modile-slider.d-xl-none
              swiper(
                :options="Object.assign({}, slider.common, slider.patients)"
                :auto-update="true"
                :auto-destroy="false"
              )
                swiper-slide(v-for="patient in patients" :key="patient.code")
                  router-link(:to="{name: 'patient-detail', params: {id: patient.code}}" class=['d-block'])
                    app-admin-patient-card(:data="patient" @openModal="openExamDetailModal")

                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-patients-pagination'])
          .text-size--small.text-color--gray-light(v-else) Пока нет пользователей

        template(v-slot:button)
          template(v-if="patients") Все пользователи

  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @openCertificate="openCertificate"
    @close="closeModal('examDetailMobile')"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
    @openEditModal="openModal"
    @openDeleteModal="openModal"
  )
  // change patient modal
  app-change-patient-modal(
    :patient="currentPatient"
    :is-visible="isModalVisible.changePatient"
    @close="closeModal('changePatient')"
    @success="getPatients"
  )
  // change avatar modal
  app-patient-change-avatar-modal(
    ref="changeAvatar"
    :patient="currentPatient"
    :is-visible="isModalVisible.changeAvatar"
    @success="successChangePatient('changeAvatar')"
    @close="closeModal('changeAvatar')"
  )
  // change signature modal
  app-patient-change-signature-modal(
    ref="changeSignature"
    :patient="currentPatient"
    :is-visible="isModalVisible.changeSignature"
    @success="successChangePatient('changeSignature')"
    @close="closeModal('changeSignature')"
  )
  // delete avatar modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить фотографию пользователя?
          p.text-color--blue.mt-3 {{ currentPatient.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteAvatar')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять

          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteAvatar(currentPatient)"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // delete signature modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteSignature" @close="closeModal('deleteSignature')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить подпись пользователя?
          p.text-color--blue.mt-3 {{ currentPatient.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteSignature')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять

          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteSignature(currentPatient)"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // delete patient modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deletePatient" @close="closeModal('deletePatient')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить пользователя?
          p.text-color--blue.mt-3 {{ currentPatient.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deletePatient')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять

          .col-7.col-md-auto
            button(
              type="button"
              @click="deletePatient(currentPatient)"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! При удалении пользователя удалятся все его осмотры.<br>Отменить действие нельзя.

  // success for patient modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="closeModal('success')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") {{ successMessage }}
          p.text-color--blue.mt-3 Ф.И.О. пользователя: {{ currentPatient.full_name }}.

        .d-flex.justify-content-center.mt-5
          button(
            @click="closeModal('success')"
            type="button"
            class=['button button--accent']
          )
            span.button__content Назад

</template>

<script>
import patientApi from '@/api/patients';
import usersApi from '@/api/users';

import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import variables from '@/helpers/variables';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Pagination} from 'swiper';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppLoading from '@/components/common/Loading';
import AppNotification from '@/components/common/Notification';
import AppValidationErrors from '@/components/common/ValidationErrors';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppDashboardCard from '@/components/profile/common/dashboard/DashboardCard';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppClientPatientCard from '@/components/profile/client/patients/ClientPatientCard';
import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';

import AppChangePatientModal from '@/components/profile/common/patients/ChangePatientModal';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import AppPatientChangeSignatureModal from '@/components/profile/common/patients/PatientChangeSignatureModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

SwiperCore.use([Pagination]);

export default {
  name: 'AppClientDashboard',
  components: {
    AppTitle,
    AppModal,
    AppGallery,
    AppNotification,
    AppLoading,
    AppValidationErrors,
    AppCertificateModal,
    AppDashboardCard,
    AppClientPatientCard,
    AppAdminPatientCard,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailModal,
    AppExamDetailMobile,
    AppChangePatientModal,
    AppPatientChangeAvatarModal,
    AppPatientChangeSignatureModal,
    Swiper,
    SwiperSlide,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, certificateModalMixin, galleryMixin],
  data() {
    return {
      examsLoading: false,
      patientsLoading: false,
      patients: [],
      exams: [],
      v: variables,
      isModalVisible: {
        changePatient: false,
        changeAvatar: false,
        changeSignature: false,
        deleteAvatar: false,
        deleteSignature: false,
        deletePatient: false,
      },
      sortKey: {
        exams: 'exam_date_time',
        patients: 'creation_date',
      },
      sortInverse: {
        exams: false,
        patients: false,
      },
      columns: {
        exams: [
          {
            class: 'col-4 col-xl-3 col-xxl-2',
            caption: 'Дата',
            key: 'exam_date_time',
            inverse: true,
          },
          {
            class: 'col-3 col-xxl-4',
            caption: 'ФИО пользователя',
            key: 'patient_full_name',
            inverse: false,
          },
          {
            class: 'col-1',
            icon: 'pulse',
            key: 'bp_high',
            inverse: true,
          },
          {
            class: 'col-1',
            icon: 'cardio',
            key: 'heart_rate',
            inverse: true,
          },
          {
            class: 'col-1',
            icon: 'temp',
            key: 'body_temp',
            inverse: true,
          },
          {
            class: 'col-1',
            icon: 'breathalyzer',
            key: 'alcohol',
            inverse: true,
          },
          {
            class: 'col-1',
            key: 'health_complaint',
          },
          {
            class: 'col-2 ms-auto',
            caption: 'Допуск',
            key: 'admission_to_work',
            inverse: false,
          },
        ],
        patients: [
          {
            class: 'col-3',
            caption: 'E-mail',
            key: 'email',
            inverse: false,
          },
          {
            class: 'col-2',
            caption: 'Телефон',
            key: 'phone',
            inverse: false,
          },
          {
            class: 'col-2',
            caption: 'Дата регистрации',
            key: 'creation_date',
            inverse: true,
          },
          {
            class: 'col-3',
            caption: 'ФИО пользователя',
            key: 'full_name',
            inverse: false,
          },
          {
            class: 'col-2',
            caption: 'Кол-во осмотров',
            key: 'exams_count',
            inverse: true,
          },
        ],
      },
      slider: {
        common: {
          slidesPerView: 1,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2,
            },
          },
        },
        exams: {
          pagination: {
            el: '.js-exams-pagination',
            dynamicBullets: true,
            renderBullet: function (index, className) {
              const slide = this.slides[index];
              const status = slide.querySelector('[data-status]');

              return `<span data-status="${status.dataset.status}" class="${className}"></span>`;
            },
          },
        },
        patients: {
          pagination: {
            el: '.js-patients-pagination',
            dynamicBullets: true,
          },
        },
      },
      currentPatient: {},
      successMessage: 'Успешно',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  created() {
    this.getExams();
    this.getPatients();
  },
  methods: {
    //getData
    async getExams() {
      try {
        this.examsLoading = true;
        let res = await usersApi.getUsersExams(this.currentUser.id);
        this.exams = res.data.items;
      } catch (error) {
        console.error(error);
      } finally {
        this.examsLoading = false;
      }
    },

    async getPatients() {
      try {
        this.patientsLoading = true;
        let sortInverse = '-';
        if (this.sortInverse.patients) {
          sortInverse = '';
        }
        let order_by = `${sortInverse}${this.sortKey.patients}`;

        let credentials = {
          page: 0,
          per_page: 3,
          patient_order_by: order_by,
          user_id: this.currentUser.id,
        };
        let res = await patientApi.getPatientsShortList(credentials);
        this.patients = res.data.items;
      } catch (error) {
        console.error(error);
      } finally {
        this.patientsLoading = false;
      }
    },


    // sorting
    sortingExams(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse.exams = !this.sortInverse.exams;
        this.sortKey.exams = sortKey;
        this.getExams();
      }
    },
    sortingPatients(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse.patients = !this.sortInverse.patients;
        this.sortKey.patients = sortKey;
        this.getPatients();
      }
    },

    // modals
    openDeletePatientModal(data) {
      this.currentPatient = data;
      this.openModal('deletePatient');
    },
    openChangePatientModal(data) {
      this.currentPatient = data;
      this.openModal('changePatient');
    },
    openChangeAvatarModal(data) {
      this.currentPatient = data;
      this.openModal('changeAvatar');
    },
    openChangeSignatureModal(data) {
      this.currentPatient = data;
      this.openModal('changeSignature');
    },
    deletePatient({code}) {
      this.$store
        .dispatch(patientsActionTypes.deletePatient, {
          id: code,
        })
        .then(() => {
          this.successMessage = 'Успешно удалён!';
          this.closeModal('deletePatient');
          this.openModal('success');
          this.getPatients();
        });
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    deleteSignature({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientSignature, formData).then(() => {
        this.successChangePatient('deleteSignature');
      });
    },
    successChangePatient(slug) {
      switch (slug) {
      case 'changeAvatar':
        this.successMessage = 'Фотография успешно изменена!';
        break;

      case 'changeSignature':
        this.successMessage = 'Подпись успешно изменена!';
        break;

      case 'deleteAvatar':
        this.successMessage = 'Фотография успешно удалена!';
        break;

      case 'deleteSignature':
        this.successMessage = 'Подпись успешно удалена!';
        break;
      }

      //common
      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');
      this.getPatients();
    },
  },
};
</script>
