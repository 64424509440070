
.profile-doctors
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7
        .d-flex.align-items-center
          app-title(:type="'large'") Группы риска
    .col-14.d-none.d-md-block
      .d-flex.download-risks-groups

        .col-2.date-picker-margins
          date-picker(
            prefix-class="app"
            placeholder="от"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="startDate"
            :disabled-date="disableAfterToday"
          )

        .col-2.date-picker-margins
          date-picker(
            prefix-class="app"
            placeholder="до"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="endDate"
            :disabled-date="disableExpiration"
          )

        .col-3.date-picker-margins(v-if="currentUser.role==='ADMIN'")
          autocomplete(
            :style='{ marginTop: "-5px" }'
            :search="search"
            placeholder="Наименование организации"
            aria-label="Наименование организации"
            :get-result-value="getResultValue"
            @submit="handleSubmit"
          )

        .col-3
          button(
            type="button"
            @click="downloadRisksGroups"
            class=['button button--border-blue']
          )
            span.button__content
              span  Скачать группы риска
