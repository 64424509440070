
.loading-container.d-flex.align-items-center.justify-content-center(
  :class="[classes, type && `is-${type}`]"
)
  span.loading
    svg(viewBox="0 0 100 100")
      circle(class="loader-svg bg" cx="50" cy="50" r="45")
      circle(class="loader-svg animate" cx="50" cy="50" r="45")

    //img(src="@/assets/img/loader.svg")
