
section.map-device-card.profile-card(:class="{'is-inactive': isInactive}")
  .map-device-card__title.d-flex.align-items-center.mb-4
    app-loading(v-if="!trigger")
    
    span.d-flex.me-3(:style="{'color': data.color}")
      so-icon(icon="map-marker")
    span.text-color--black.text-size--normal Устройство {{ data.serial }}
    
  .map-device-card__exams-slider(:class="`js-exams-slider-${data.serial}`")
    .map-device-card__exams-slider-pagination.mb-20
      swiper(
        ref="days"
        :options="slider.daysOptions"
        :auto-update="true"
        :auto-destroy="false"
      )
        swiper-slide(v-for="(day, index) in data.days" :key="day.date")
          span(
            :data-key="day.date"
            @click="changeDate(day.date, index)"
            :class="{'is-active': day.date === currentDay}"
            class="map-device-card__exams-slider-pagination-item"
          ) {{ day.date }}
        
      .map-device-card__exams-slider-prev-btn.js-days-prev
        so-icon(icon="left-arrow")
      .map-device-card__exams-slider-next-btn.js-days-next
        so-icon(icon="right-arrow")
      
    swiper.d-flex.flex-column-reverse(
      ref="exams"
      :options="slider.examsOptions"
      :auto-update="true"
      :auto-destroy="false"
    )
      swiper-slide(v-for="day in data.days" :key="day.date" :data-key="day.date")
        simplebar.map-device-card__exams-list(data-simplebar-auto-hide="false")
          ul.list-circle
            li.mb-3(v-for="exam in day.exams" :key="exam.id")
              span.me-3 {{ exam.exam_time }}
              span {{ exam.lat }}, {{ exam.long }}

