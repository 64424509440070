
<button
:class="['so-btn', ...classes]"
:disabled="disabled"
@click.stop.prevent="handleClick"
>
  <div class="so-btn__content">
    <img v-if='icon' src="/esia.svg" width='32'/>
    <so-icon v-else-if='icon' :icon="icon" class="so-btn__icon" />
    <slot>{{ title }}</slot>
  </div>
</button>
