
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col.col-md-auto.ms-md-auto
        button(
        type="button"
        class=['button button--border-blue']
        @click="openModal('loadDoc')"
        )
          span.button__content Загрузить документ

  .profile-patients__content.mt-20.position-relative
    transition(name="translucent")
      div
        app-loading(v-if="isLoading" key="loading")
        app-error-message(v-if="error" :message="error" key='error')
        app-table(
          v-if="outgoingMedDocs.length"
          :columns="columns"
          :sortKey="sortKey"
          @sortingRow="sortingOutgoingMedDocs"
          :data="outgoingMedDocs"
          :paginationData="pagination"
          :currentPage="currentPage"
          @change-current-page="setCurrentPage"
          :baseUrl="baseUrl"
          :canEdit="false"
          :canRemove="false"
        )
          template(v-slot:cell='{ column, row }')
            span(v-if='column.key === "document_status"') {{ getDocumentStatus(row[column.key]) }}
            span(v-else-if='column.key === "create_datetime"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}
            span(v-else-if='column.key === "issue_date"') {{ toLocalDateExam(row[column.key]) }}
            span(v-else-if='column.key === "expiration_date"') {{ toLocalDateExam(row[column.key]) }}
            span(v-else-if='column.key === "verification_datetime"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}

          template(v-slot:actionTools='{ row }')
            button(
              type="button"
              @click.prevent="openShowModal(row)"
            )
              so-icon(icon="search")

        div(v-else-if="!isLoading")
          .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-incoming(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
  // create patients modal
  load-doc-modal(
    :is-visible="isModalVisible.loadDoc"
    @open="openModal('loadDoc')"
    @close="createDoc"
    @openGallery="openGallery"
    @success="getPatients()"
  )
  // create patients modal
  show-doc-modal(
    :is-visible="isShowDataVisible"
    :closeModal="closeShowModal"
    :data="currentDoc"
  )
