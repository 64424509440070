<template lang="pug">
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Редактирование медорганизации {{ item.name }}

        .px-md-4
          form(@submit.prevent :class="['form']")
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .row.g-4
              //- template(v-if='form.registering_type === "PERSONAL"')
              //-   .col-14.col-md-5
              //-     label.form__label
              //-       input(
              //-         :class="['form__field', { 'is-error': v$.form.name.$errors.length }]"
              //-         type="text"
              //-         v-model="v$.form.first_name.$model"
              //-         placeholder="* Имя"
              //-         @keyup="changeField('name')"
              //-       )
              //-     ul.form__error(v-if="v$.form.first_name.$errors.length")
              //-       li(v-for="error of v$.form.first_name.$errors" :key="error.$uid")
              //-         template(v-if="error.$validator === 'required'") {{ v.errors.required }}


              //- template(v-else)
              template
                .col-14.col-md-14
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.name.$errors.length }]"
                      type="text"
                      v-model="v$.form.name.$model"
                      placeholder="* Наименование медорганизации"
                      @keyup="changeField('name')"
                    )
                  ul.form__error(v-if="v$.form.name.$errors.length")
                    li(v-for="error of v$.form.name.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              //- template(v-if='form.registering_type === "PERSONAL"')
              //-   .col-14.col-md-7
              //-     label.form__label
              //-       input(
              //-         :class="['form__field', { 'is-error': v$.form.ogrnip.$errors.length }]"
              //-         type="text"
              //-         v-mask='"#-##-##-##-#####-###"'
              //-         v-model="v$.form.ogrnip.$model"
              //-         placeholder='* ОГРНИП'
              //-         @keyup="changeField('ogrn')"
              //-       )
              //-     ul.form__error(v-if="v$.form.ogrn.$errors.length")
              //-       li(v-for="error of v$.form.ogrn.$errors" :key="error.$uid")
              //-         template(v-if="error.$validator === 'required'") {{ v.errors.ogrn.required }}
              //-         template(v-if="error.$validator === 'minLength'") {{ v.errors.ogrn.minLength }}

              //- template(v-else)
              template
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.ogrn.$errors.length }]"
                      type="text"
                      v-model="v$.form.ogrn.$model"
                      placeholder='* ОГРН'
                      @keyup="changeField('ogrn')"
                    )
                  ul.form__error(v-if="v$.form.ogrn.$errors.length")
                    li(v-for="error of v$.form.ogrn.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.ogrn.required }}
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.ogrn.minLength }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                    type="number"
                    v-model="v$.form.inn.$model"
                    placeholder='* ИНН'
                    @keyup="changeField('inn')"
                  )
                ul.form__error(v-if="v$.form.inn.$errors.length")
                  li(v-for="error of v$.form.inn.$errors" :key="error.$uid")
                    //- template(v-if="error.$validator === 'required'") {{ form.registering_type === 'PERSONAL' ? v.errors.inn_person.required : v.errors.inn_company.required }}
                    template(v-if="error.$validator === 'required'") {{ v.errors.inn_company.required }}
                    //- template(v-if="error.$validator === 'minLength'") {{ form.registering_type === 'PERSONAL' ? v.errors.inn_person.minLength : v.errors.inn_company.minLength }}
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.inn_company.minLength }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.phone.$errors.length }]"
                    type="tel"
                    v-mask="'+7 (###) ###-##-##'"
                    v-model="v$.form.phone.$model"
                    placeholder='* Телефон'
                    @keyup="changeField('phone')"
                  )
                ul.form__error(v-if="v$.form.phone.$errors.length")
                  li(v-for="error of v$.form.phone.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.phone.required }}
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.phone.minLength }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                    type="email"
                    v-model="v$.form.email.$model"
                    placeholder='* Электронная почта'
                    @keyup="changeField('email')"
                  )
                ul.form__error(v-if="v$.form.email.$errors.length")
                  li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              span МЕДИЦИНСКАЯ ЛИЦЕНЗИЯ
              .col-14
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.medical_license.$errors.length }]"
                    type="text"
                    v-model="v$.form.medical_license.$model"
                    placeholder="* Серия и номер"
                    @keyup="changeField('medical_license')"
                  )
                ul.form__error(v-if="v$.form.medical_license.$errors.length")
                  li(v-for="error of v$.form.medical_license.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14
                .row
                  .col-14.col-md-5
                    label(:class="['form__label', { 'is-error': v$.form.medical_license_start.$errors.length }]")
                      date-picker(
                        v-model="v$.form.medical_license_start.$model"
                        prefix-class="app"
                        placeholder="* Срок начала"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableAfterToday"
                        @change="changeField('medical_license_start')"
                      )
                  .col-7.col-md-5
                    label.form__label
                      date-picker(
                        v-model="v$.form.medical_license_end.$model"
                        prefix-class="app"
                        placeholder="* Срок окончания"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableExpiration"
                        :disabled='form.hasNoLimit'
                        @change="changeField('medical_license_end')"
                      )

                  .col-7.col-md-4
                    label.form__checkbox.my-2
                      input(
                        type="checkbox"
                        name="user-type"
                        v-model='form.hasNoLimit'
                      )
                      i.form__checkbox-icon.me-3
                      span.form__checkbox-caption Бессрочно

              span ИДЕНТИФИКАТОР МЕДОРГАНИЗАЦИИ
              .col-14
                label.form__label
                  input(
                    :class="['form__field']"
                    type="text"
                    v-model="form.guid"
                    v-mask="'########-####-####-####-############'"
                    placeholder='GUID'
                    @change="changeField('guid')"
                  )

              .col-14
                button(
                  type="submit"
                  @click="onSubmit"
                  :disabled="isFormValidAndUnchanged"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Изменить медорганизацию

  // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Данные успешно изменены

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeSuccessModal"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад
</template>

<script>
import medOrganizationsApi from '@/api/medOrganizations';
import variables from '@/helpers/variables';
import useVuelidate from '@vuelidate/core';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppLoading from '@/components/common/Loading';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppMultipleSelect from '@/components/common/MultipleSelect';

import { mask } from 'vue-the-mask';
import { modalsMixin } from '@/mixins/modals';
import { isEmptyObj, convertDate } from '@/helpers/utils';
import { disableAfterToday, disableExpiration } from '@/helpers/datepicker';
import { required, minLength, email } from '@vuelidate/validators';


export default {
  name: 'AppChangeMedOrganizationModal',
  components: {
    AppLoading,
    AppValidationErrors,
    AppTitle,
    AppModal,
    AppMultipleSelect,
    DatePicker,
  },
  directives: {
    mask,
  },
  mixins: [modalsMixin],
  props: {
    item: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      localValidationErrors: null,
      form: {
        registering_type: 'COMPANY',
        name: '',
        ogrn: '',
        guid: '',
        inn: '',
        phone: '',
        email: '',
        medical_license: '',
        medical_license_start: '',
        medical_license_end: '',
        hasNoLimit: false,
        ogrnip: '',
        exams_type: 'SYNCHRONOUS',
        isChanged: {
          name: false,
          ogrn: false,
          guid: false,
          inn: false,
          phone: false,
          email: false,
          medical_license: false,
          medical_license_start: false,
          medical_license_end: false,
          hasNoLimit: false,
          ogrnip: false,
          exams_type: false,
        },
      },
    };
  },
  watch: {
    isVisible: function(newValue) {
      if (newValue) {
        this.setInitialValues();
        this.v$.$touch();
        this.v$.$validate();
      }
    }
  },
  computed: {
    isChanged() {
      return Object.values(this.form.isChanged).some((item) => item === true);
    },
    initialValues() {
      if (isEmptyObj(this.item)) return {};
      return {
        name: this.item.name,
        ogrn: this.item.ogrn,
        medical_license_start: this.item.medical_license_start
          ? new Date(new Date(this.item.medical_license_start).setHours(0, 0, 0, 0))
          : null,
        medical_license_end: this.item.medical_license_end
          ? new Date(new Date(this.item.medical_license_end).setHours(0, 0, 0, 0))
          : null,
        medical_license: this.item.medical_license,
        phone: this.item.phone,
        email: this.item.email,
        inn: this.item.inn,
        exams_type: this.item.exams_type,
        exams_type: this.item.exams_type,
        guid: this.item.guid,
      };
    },
    isChanged() {
      return Object.values(this.form.isChanged).some((item) => item === true);
    },
    isFormValidAndUnchanged() {
      return this.v$.$invalid || !this.isChanged;
    },
  },
  validations() {
    // const isPersonal = this.form.registering_type === 'PERSONAL'
    const isPersonal = false;
    return {
      form: {
        name: !isPersonal ? { required } : {},
        ogrn: !isPersonal ? { required, minLength: minLength(13) } : {},
        inn: {
          required,
          minLength: isPersonal ? minLength(12) : minLength(10)
        },
        first_name: isPersonal ? { required } : {},
        last_name: isPersonal ? { required } : {},
        middle_name: isPersonal ? { required } : {},
        ogrnip: isPersonal ? { required, minLength: minLength(20) } : {},
        email: {
          email,
          required
        },
        phone: {
          required,
          minLength: minLength(18)
        },
        medical_license: { required },
        medical_license_start: { required },
        medical_license_end: !this.form.hasNoLimit ? { required } : {},
      },
    };
  },
  methods: {
    setInitialValues() {
      if (!isEmptyObj(this.initialValues)) {
        for (let key in this.initialValues) {
          if (Object.prototype.hasOwnProperty.call(this.initialValues, key)) {
            this.form[key] = this.initialValues[key] || ''
          }
        }
      }
    },
    changeField(key) {
      let currentValue = this.form[key];
      if (typeof currentValue === 'string') {
        currentValue = currentValue.replace(/-/g, '');
      }
      const initialValue = this.initialValues[key];
      if (currentValue !== initialValue) {
        this.form.isChanged[key] = true;
      } else {
        this.form.isChanged[key] = false;
      }
    },
    async onSubmit() {
      let data = {};
      if (!this.v$.$invalid) {
        data = {
        "med_organization_id": this.item.id,
        'name': this.form.name,
        'ogrn': this.form.ogrn,
        'inn': this.form.inn,
        'phone': this.form.phone,
        'email': this.form.email,
        'medical_license': this.form.medical_license,
        'medical_license_start': convertDate(this.form.medical_license_start),
        'medical_license_end': this.form.hasNoLimit ? null : convertDate(this.form.medical_license_end),
        'exams_type': this.form.exams_type,
        'guid': this.form.guid,
        }
        try {
          await medOrganizationsApi.changeMedOrganization(data);
          this.close();
          this.$toast.success('Изменения сохранены');
          this.$emit('success')
        } catch (error) {
          this.localValidationErrors = error.request.statusText;
        }
      }
    },
    close() {
      this.resetForm();
      this.$emit('close');
    },
    closeSuccessModal() {
      this.closeModal('success');
      this.$emit('success');
    },
    resetForm() {
      this.v$.$reset();
      for (let key in this.form.isChanged) {
        if (Object.prototype.hasOwnProperty.call(this.form.isChanged, key)) {
          this.form.isChanged[key] = false;
        }
      }
      this.localValidationErrors = null;
    },
    disableAfterToday(date) {
      return disableAfterToday(date, this.item.medical_license_start);
    },
    disableExpiration(date) {
      return disableExpiration(date, this.item.medical_license_end);
    },
  },
};
</script>
