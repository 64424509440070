
<div class="profile-doctors">
  <div class="profile-doctors__header">
    <div class="row align-items-center">
      <div class="col-14 col-md-7">
        <div class="d-flex align-items-center"></div>
      </div>
    </div>
  </div>
  <div class="profile-doctors__content position-relative mt-25">
    <transition name="translucent">
      <div>
        <app-loading v-if="isLoading"></app-loading>
        <app-table v-if="userAgreements.length" :columns="columns" :sortKey="sortKey" :data="userAgreements" :paginationData="pagination" :currentPage="currentPage" @change-current-page="setCurrentPage" :baseUrl="baseUrl" :canEdit="false" :canRemove="false">
          <template v-slot:cell='{ column, row }'>
            <span v-if='column.key === "last_name"'>{{ getFullName(row) }}</span>
            <span v-else-if='column.key === "signed_time"'>{{$moment(row[column.key]).format('DD.MM.YYYY')}}  {{$moment(row[column.key]).format('HH:mm:ss')}}</span>
            <span v-else-if='column.key === "link"'>
              <button v-if="row.link" class="profile-card__button--document link--accent text-overflow" type="button" @click.prevent="download(row)">
                <span v-if="row.link" class="text-color--blue text-color-md-blue-dark incomingAction">скачать</span>
                <span v-else>&#8212;</span>
              </button>
            </span>
          </template>
        </app-table>
      </div>
    </transition>
  </div>
</div>
