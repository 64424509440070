
.inspection-card__header
  .row.gx-3.text-size--middle.justify-content-start
    .col-3
      span <b>№</b>&nbsp;
      span <b>{{ exam.exam_number }}</b>
    .col-3
      span {{exam.type_name}} &nbsp;
    .col-4
      span {{ exam.patient_full_name}}
  .row.gx-3.mt-4.text-size--middle

    .col-3
      span {{ toLocalDateExam(exam.exam_datetime) }} &nbsp;
      span {{ toLocalTimeExam(exam.exam_datetime) }}
    .col-3
      span {{ exam.device_owner_full_name }}
    .col-auto
      span Пол:&nbsp; {{ exam.gender  === 2 ? 'Жен.' : 'Муж.'}} &nbsp;
      span {{ patientAge }}&nbsp;{{ declOfNum(patientAge, ['год', 'года', 'лет']) }}
        .row.mt-3

