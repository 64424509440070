
#carouselExampleControls.carousel.slide(data-bs-ride="carousel"  data-bs-interval="false")
  .carousel-inner
    .carousel-item(v-for="(image, index) in archivePhotos" :key="index" :class="{ 'carousel-item active': index === 0, 'carousel-item': index !== 0 }")
      img.d-block(:src="image.path" :alt="image.name")
  button.carousel-control-prev(type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev")
    span.carousel-control-prev-icon(aria-hidden="true")
    span.visually-hidden Предыдущий
  button.carousel-control-next(type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next")
    span.carousel-control-next-icon(aria-hidden="true")
    span.visually-hidden Следующий
