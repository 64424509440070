
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeForm")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Добавление клиента
          app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
          )
        .px-md-4
          form(@submit.prevent="onSubmit")

          .row.flex-column.align-items-center.g-4
            .col
                app-tab-nav(
                  :tabs="[{id:'company', caption:'Юридическое лицо'}, {id:'person', caption: 'Физическое лицо'}]"
                  :selected="activeTab"
                  :theme="'jointly'"
                  @selected="setActiveTab"
                )

            app-tab(:is-selected="activeTab === 'company'")
              .row.g-4
                .col-14.col-md-14
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                      type="text"
                      v-model="v$.form.firstName.$model"
                      placeholder="Полное наименование юридического лица*"
                    )
                  ul.form__error(v-if="v$.form.firstName.$errors.length")
                    li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                      type="text"
                      v-model="v$.form.inn.$model"
                      placeholder="ИНН*"
                      v-mask="'##########'"
                    )
                  ul.form__error(v-if="v$.form.inn.$errors.length")
                    li(v-for="error of v$.form.inn.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                      type="email"
                      v-model="v$.form.email.$model"
                      placeholder="E-mail*"
                      autocomplete="nope"
                    )
                  ul.form__error(v-if="v$.form.email.$errors.length")
                    li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                      app-tooltip(
                        :classes="'text-color--accent'"
                        :placement="'left'"
                        :content="v.errors.password.requirements"
                      )
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.form.password.$model"
                        placeholder="Пароль*"
                        autocomplete="nope"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        so-icon(v-if="showPass" :icon="'eye-open'")
                        so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.form.password.$errors.length")
                    li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                      template(v-if="v$.form.password.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю

                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.form.confirmPassword.$model"
                        placeholder="Повторите пароль*"
                        autocomplete="nope"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        so-icon(v-if="showPass" :icon="'eye-open'")
                        so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                    li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                      template(v-if="v$.form.confirmPassword.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.confirmPassword.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.confirmPassword.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.confirmPassword.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.confirmPassword.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.confirmPassword.containsNumber }}

                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю

            app-tab(:is-selected="activeTab === 'person'")
              .row.g-4
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                      type="text"
                      v-model="v$.form.firstName.$model"
                      placeholder="Имя*"
                    )
                  ul.form__error(v-if="v$.form.firstName.$errors.length")
                    li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }]"
                      type="text"
                      v-model="v$.form.lastName.$model"
                      placeholder="Фамилия*"
                    )
                  ul.form__error(v-if="v$.form.lastName.$errors.length")
                    li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      class=['form__field']
                      type="text"
                      v-model="form.middleName"
                      placeholder="Отчество"
                    )

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                      type="text"
                      v-model="v$.form.inn.$model"
                      placeholder="ИНН*"
                      v-mask="'############'"
                    )
                  ul.form__error(v-if="v$.form.inn.$errors.length")
                    li(v-for="error of v$.form.inn.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-14
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                      type="email"
                      v-model="v$.form.email.$model"
                      placeholder="E-mail*"
                      autocomplete="nope"
                    )
                  ul.form__error(v-if="v$.form.email.$errors.length")
                    li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'email'") {{ v.errors.email }}


                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                      app-tooltip(
                        :classes="'text-color--accent'"
                        :placement="'left'"
                        :content="v.errors.password.requirements"
                      )
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.form.password.$model"
                        placeholder="Пароль*"
                        autocomplete="nope"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        so-icon(v-if="showPass" :icon="'eye-open'")
                        so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.form.password.$errors.length")
                    li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                      template(v-if="v$.form.password.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю

                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.form.confirmPassword.$model"
                        placeholder="Повторите пароль*"
                        autocomplete="nope"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        so-icon(v-if="showPass" :icon="'eye-open'")
                        so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                    li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                      template(v-if="v$.form.confirmPassword.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.confirmPassword.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.confirmPassword.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.confirmPassword.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.confirmPassword.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.confirmPassword.containsNumber }}

                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю

            template
              app-title Назначить в медорганизацию
              .text-size--small.text-color--blue.mt-2 Назначьте медработника в выбранную медорганизацию

              autocomplete(
                :search="search"
                placeholder="Выберите"
                aria-label="Выберите"
                :get-result-value="getResultValue"
                @submit="handleSubmit"
              )

            .row.g-5.mt-0
              .col-14
                button(
                  type="submit"
                  @click="onSubmit"
                  class=['button', 'button--accent', 'form__button']
                  :disabled="!buttonActive"
                )
                  span.button__content Добавить клиента
  // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Новый медработник добавлен

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeSuccessModal"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content На страницу медработников
