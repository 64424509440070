
.profile-users
  .profile-exams__inner(:class="{'filter-is-open': isFilterOpen}")
    .profile-users__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center mb-3 mb-md-0'"
          ) Выгруженные документы

    .profile-users__content.mt-20.position-relative
      transition(name="translucent")
        div
          app-loading(v-if="isLoading" key='loading')
          app-table(
            v-if="reports.length"
            :columns="columns"
            :sortKey="sortKey"
            :data="reports"
            :paginationData="pagination"
            :currentPage="currentPage"
            @change-current-page="setCurrentPage"
            :baseUrl="baseUrl"
            :canEdit="false"
            :canRemove="false"
          )
            template(v-slot:cell='{ column, row }')
              span(v-if='column.key === "created_at"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}

              span(v-else-if='column.key === "path"')
                button(
                  v-if="row.path"
                  class=['profile-card__button--document', 'link--accent', 'text-overflow']
                  type="button"
                  @click.prevent="download(row)"
                )
                  span(v-if="row.path").text-color--blue.text-color-md-blue-dark.incomingAction {{ truncatePath(row[column.key]) }}
                  span(v-else) &#8212;

              span(v-else-if='column.key === "pending_task_type"' :class="{'text-color--orange': highlightParams(row[column.key])}") {{ getDocumentStatus(row[column.key]) }}

          div(v-else)
            .text-color--blue.text-size--small Отчеты не найдены

