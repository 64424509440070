
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          v-if="isDirty && this.collectData.length"
          type="button"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3
                .col
                  .form__caption.mb-3 Фамилия:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="fio"
                    placeholder="Фамилия"
                    autocomplete="off"
                    id="id"
                  )
                .col
                  .form__caption.mb-3 Телефон:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="phone"
                    placeholder="Телефон"
                    autocomplete="off"
                    id="id"
                  )
                .col(v-if="$store.state.auth.userRole !== 'client'")
                  .form__caption.mb-3 Компания:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="company_name"
                    placeholder="Компания"
                    autocomplete="off"
                    id="id"
                  )
                .col
                  .form__caption.mb-3 СНИЛС:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="snils"
                    placeholder="СНИЛС"
                    autocomplete="off"
                    id="id"
                  )
                .col
                  .form__caption.mb-3 RFID карта:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="rfid"
                    placeholder="RFID карта"
                    autocomplete="off"
                    id="id"
                  )
                .col
                  .form__caption.mb-3 Табельный номер:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="personal_number"
                    placeholder="Табельный номер"
                    autocomplete="off"
                    id="id"
                  )
                .col
                  .form__caption.mb-3 Водительское удостоверение:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="serial_number_driving_license"
                    placeholder="Водительское удостоверение"
                    autocomplete="off"
                    id="id"
                  )

            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Дата регистрации:
                .row.g-3
                  .col-7
                    date-picker(
                      v-model="form.date.from"
                      prefix-class="app"
                      placeholder="от"
                      format="DD-MM-YYYY"
                      :lang="datepicker.lang"
                      :editable="false"
                      :disabled-date="disableAfterToday"
                      @change="datepickerChange('dateFrom')"
                      @clear="datepickerClear('dateFrom')"
                    )
                      template(v-slot:icon-calendar)
                        so-icon(icon="calendar")

                  .col-7
                    date-picker(
                      v-model="form.date.to"
                      prefix-class="app"
                      placeholder="до"
                      format="DD-MM-YYYY"
                      :lang="datepicker.lang"
                      :editable="false"
                      :disabled-date="disableExpiration"
                      @change="datepickerChange('dateTo')"
                      @clear="datepickerClear('dateTo')"
                    )
                      template(v-slot:icon-calendar)
                        so-icon(icon="calendar")

      // submit button
      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          :disabled="!isDirty || !this.collectData.length"
          form="filter-form"
          @click='submit'
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
        )
          span.button__content
            span Показать
            transition(name="translucent" mode="out-in")

