import axios from '@/api/axios';

// V1


// Используется в компоненте PoliceExamDetail - не понимаю, что за компонент и где он в проекте // Удалил - вроде заменил на getExamDetail
// const getExamById = (id) => axios.get(`/api/v1/exams/detail/${id}`);

// Используется в mapExams, компонент рабочий, но неактуальный
const getCountExams = (credentials) => axios.post('/api/v1/exams/count', credentials);

// неактуально, вместо него 266н
// const exportExams835 = (credentials) => axios.post('/api/v1/exams/export_835', credentials, {responseType: 'blob'});

// V2
const getExams = (params) => axios.get('/api/v2/exams', { params });
const exportExams = (credentials) => axios.post('/api/v2/exams/export', credentials, {responseType: 'blob'});

// используется в DoctorDashboard. Компонент неактуальный
const getExamsNew = (page) => axios.get(`/api/v2/exams?page=${page}`);

const getExamsOrderBy = (credentials) => axios.get(`/api/v2/exams?${credentials}`);

const getExamsForAdminPanel = (page, order_by) => axios.get(`/api/v2/exams?page=${page}&per_page=10&exams_order_by=${order_by}`);

const getExamsNewNew = (page, params, order_by) => axios.get(`/api/v2/exams?page=${page}&admission_to_work=${params}&exams_order_by=${order_by}`);

const getExamsByDoctorId = (page, order_by, doctor_id) => axios.get(`/api/v2/exams?page=${page}&per_page=10&exams_order_by=${order_by}&doctor_id=${doctor_id}`);

const getHistoryExamsByPatientCode = (patient_code, credentials) => axios.get(`/api/v2/exams/${patient_code}`, credentials);

const getExamsByDevice = (page, device_serial) => axios.get(`/api/v2/exams?page=${page}&serial=${device_serial}`);

const getExamDetail = (exams_id) => axios.get(`/api/v2/exams/detail/${exams_id}`);

const getExamsReasons = (exam_id) => axios.get(`/api/v2/exams/medical_helper/${exam_id}`);

const avgExams = (patient_code) => axios.get(`/api/v2/patient/avg_exams_result/${patient_code}`);

const exportExamsV2 = (credentials) =>
  axios.post(`/api/v2/exams/export?${credentials}`, {}, {responseType: 'blob'});

const exportExams266 = (credentials) => axios.post('/api/v2/exams/export266', credentials);

const getExamStats = (query_string) =>
  axios.post(`/api/v2/stats/generate${query_string}`, {}, {responseType: 'blob'});

export default {
  getExams,
  // getExamById,
  getCountExams,
  exportExams,
  // exportExams835,
  getExamsNew,
  getExamsOrderBy,
  getExamsForAdminPanel,
  getExamsNewNew,
  getExamsByDoctorId,
  getHistoryExamsByPatientCode,
  getExamsByDevice,
  getExamDetail,
  getExamsReasons,
  avgExams,
  exportExamsV2,
  exportExams266,
  getExamStats,
};
