import axios from '@/api/axios';

const setAdmission = (credentials) => axios.post('/api/v2/doctor/set_admission_to_work', credentials);

const createDoctor = (credentials) => axios.post('/api/v2/doctor', credentials);

const deleteDoctor = (id) => axios.delete(`/api/v2/doctor/delete/${id}`);

const changeDoctor = (doctor_id, credentials) => axios.put(`/api/v2/doctor/${doctor_id}`, credentials);

const getDoctorsNew = (page, order_by, additionals) => axios.get(`/api/v2/doctor/?page=${page}&per_page=10&doctor_order_by=${order_by}`, { params: additionals });

const getDoctorInfo = (doctor_id) => axios.get(`/api/v2/doctor/${doctor_id}/info`);

const getDoctorUsers = (doctor_id, page, order_by) => axios.get(`/api/v2/doctor/${doctor_id}/users?page=${page}&per_page=10&doctor_order_by=${order_by}`);

const getDoctorUsersNoFilter = (doctor_id) => axios.get(`/api/v2/doctor/${doctor_id}/users?page=0&per_page=10`);

const getToken = () => axios.get('/api/v2/broker/token');

const getExamsCount = () => axios.get('/api/v2/broker/incoming_exams');

const setDoctorStatus = (credentials) => axios.post('/api/v2/doctor/work', credentials);


export default {
  createDoctor,
  deleteDoctor,
  changeDoctor,
  setAdmission,
  getDoctorsNew,
  getDoctorInfo,
  getDoctorUsers,
  getDoctorUsersNoFilter,
  getToken,
  getExamsCount,
  setDoctorStatus,
};
