<template>
  <i
  :style='{ width, height, display: "inline-block", color: color || "inherit" }'
  :class='className'
  ></i>
</template>

<script>
export default {
  props: {
    name: String,
    width: {
      type: String,
      default: '1rem',
    },
    height: {
      type: String,
      default: '1rem',
    },
    viewBox: String,
    color: String
  },
  data() {
    return {
      iconContent: null
    }
  },
  computed: {
    iconPath() {
      return window.location.origin + `/svg/${this.name}.svg`;
    },
    className() {
      return `icon icon__${this.name} icon--${this.name}`;
    }
  }
};
</script>
