
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") редактировать поверку устройства
      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
          app-validation-errors(
            v-if="localValidationErrors"
            :validation-errors="localValidationErrors"
            :classes="'mb-3'"
          )
          .col-14.col-md-10
            .row.g-4.posRelative
              .col
                div
                  input(
                    :class="['form__field form__field--animate', { 'is-error': v$.form.serial.$errors.length }]"
                    type="text"
                    name="id"
                    @keydown="v$.$touch()"
                    v-model="form.serial"
                    placeholder="&nbsp;"
                    autocomplete="off"
                    id="id"
                    disabled
                  )
                  label.textFloat(for='id') Серийный номер МИ
                .row.gx-4
                 .col-14.mt-3
                  input(
                    :class="['form__field form__field--animate', { 'is-error': v$.form.attestation.$errors.length }]"
                    type="text"
                    name="id"
                    @keydown="v$.$touch()"
                    v-model="form.attestation"
                    placeholder="&nbsp;"
                    autocomplete="off"
                    id="ids"
                  )
                  label.textFloat(for='ids') Номер поверки
                 .col-7
                    .text-size--small.mb-2.mt-4 Дата поверки
                     label(:class="['form__label pt-2', { 'is-error': v$.form.dateStart.$errors.length }]")
                      date-picker(
                          prefix-class="app"
                          placeholder="Дата поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                          v-model="form.dateStart"
                          :disabled-date="disableAfterToday"
                      )
                 .col-7
                   .text-size--small.mb-2.mt-4  Окончание срока поверки
                    label(:class="['form__label pt-2', { 'is-error': v$.form.dateEnd.$errors.length }]")
                      date-picker(
                            prefix-class="app"
                            placeholder="Окончание срока поверки"
                            format="DD.MM.YYYY"
                            lang="ru"
                            :editable="true"
                            v-model="form.dateEnd"
                            :disabled-date="disableExpiration"
                        )
          .col-14.col-md-10.mt-5
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Отправить

