
.inspection-card
  doctor-exam-card-header(
    :exam="exam"
  )
  .inspection-card__media-container
    .row
      .col-14
          .row.gx-3
            .col-11
              .row.gx-3.mb-4
                .col-6
                  doctor-exam-card-avatar(
                    :avatarSource="archiveAvatar"
                  )
                .col-8
                  doctor-exam-card-video(
                    :src="exam.exam_video"
                  )
              doctor-exam-card-conclusion(
                :exam="exam"
              )
              doctor-exam-card-inspection-result(
                :exam="exam"
              )
              doctor-exam-card-med-docs-history(
                :exam="exam"
              )
              doctor-exam-card-med-docs(
                :exam="exam"
              )
            .col-3
              doctor-exam-card-signatures(
                :exam="exam"
              )
              exam-detail-comments(
                :patientCode="currentPatientId"
              )
              app-gallery(
                :is-visible="isModalVisible.gallery"
                :options="{'sources': [{'src': exam.patient_avatar}]}"
                @close="closeGallery"
                @openEditModal="openModal"
                @openDeleteModal="openModal"
              )


