
.accordion(:class="classes" :id="id")
  .accordion__header(
    :id="`heading${id}`"
    data-bs-toggle="collapse"
    :data-bs-target="`#collapse${id}`"
    :aria-controls="`collapse${id}`"
  )
    slot(name="header")
    button(
      v-if="!withoutBtn"
      type='button'
      class=['accordion__button', 'd-flex align-items-center']
    )
      so-icon(icon="down-arrow")

  .accordion__collapse.collapse(
    :id="`collapse${id}`"
    :aria-labelledby="`heading${id}`"
    :data-bs-parent="`#${parentId}`"
  )
    .accordion__body.pt-4
      slot(name="body")

