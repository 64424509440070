
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" classes="small")
    template(v-slot:body)
      app-title(:classes="'mb-4'") Смена {{ isPerson ? 'Ф.И.О.' : 'наименования' }}
      app-validation-errors(
        v-if='error && showError'
        :validation-errors='error'
        :classes="'mb-4'"
      )
      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
        .row.flex-column.align-items-center.g-3.g-md-4
          template(v-if="isPerson")
            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.firstName.$errors.length }]"
                  type="text"
                  v-model="v$.firstName.$model"
                  placeholder="Имя"
                )
                ul.form__error
                  li(v-for="error of v$.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.lastName.$errors.length }]"
                  type="text"
                  v-model="v$.lastName.$model"
                  placeholder="Фамилия"
                )
                ul.form__error
                  li(v-for="error of v$.lastName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

            .col
              label.form__label
                input(
                  class="form__field"
                  type="text"
                  v-model="middleName"
                  placeholder="Отчество"
                )

          template(v-if="isCompany")
            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.companyName.$errors.length }]"
                  type="text"
                  v-model="v$.companyName.$model"
                  placeholder="Полное наименование юридического лица"
                )
                ul.form__error
                  li(v-for="error of v$.companyName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

          .col.mt-5
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Подтвердить смену {{ isPerson ? 'Ф.И.О.' : 'наименования' }}

          .col-auto
            p.text-center.text-color--blue.text-size--small Для смены {{ isPerson ? 'Ф.И.О.' : 'наименования' }} введите новые данные
