
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          v-if="isDirty && this.collectData.length"
          type="button"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Интервал даты регистрации:
                .row.g-3
                  .col-7
                    date-picker(
                      v-model="form.date.dateFrom"
                      prefix-class="app"
                      placeholder="от"
                      format="DD-MM-YYYY"
                      :lang="datepicker.lang"
                      :editable="false"
                      :disabled-date="disableAfterToday"
                      @change="datepickerChange('dateFrom')"
                      @clear="datepickerClear('dateFrom')"
                    )
                      template(v-slot:icon-calendar)
                        so-icon(icon="calendar")
                  .col-7
                    date-picker(
                      v-model="form.date.dateTo"
                      prefix-class="app"
                      placeholder="до"
                      format="DD-MM-YYYY"
                      :lang="datepicker.lang"
                      :editable="false"
                      :disabled-date="disableExpiration"
                      @change="datepickerChange('dateTo')"
                      @clear="datepickerClear('dateTo')"
                    )
                      template(v-slot:icon-calendar)
                        so-icon(icon="calendar")
            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Владелец устройства:
                input(
                  :class="['form__field']"
                  type="string"
                  name="id"
                  v-model="deviceOwner"
                  placeholder="Введите владельца устройства"
                  autocomplete="off"
                  id="id"
                )
            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Серийный номер МИ:
                input(
                  :class="['form__field']"
                  type="string"
                  name="id"
                  v-model="serial_number"
                  placeholder="Введите серийный номер МИ"
                  autocomplete="off"
                  id="id"
                )

            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Контроль целостности ПО:
                app-select(
                  ref="selectIntegrity"
                  :type="'form'"
                  :placeholder="'Контроль целостности ПО'"
                  :options="integrityOpts"
                  @select="selectIntegrity"
                )

            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Поверка СИ:
                app-select(
                  ref="selectAttestation"
                  :type="'form'"
                  :placeholder="'Поверка СИ'"
                  :options="attestationOpts"
                  @select="selectAttestation"
                )
            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Целостность корпуса МИ:
                app-select(
                  ref="selectCase"
                  :type="'form'"
                  :placeholder="'Целостность корпуса МИ'"
                  :options="caseOpts"
                  @select="selectCase"
                )

      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !this.collectData.length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
          @click="submit"
        )
          span.button__content
            span Показать
            transition(name="translucent" mode="out-in")

