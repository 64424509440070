
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" :classes="`doctor-exam-detail-modal modal-reasons`")
    template(v-slot:body)
      .text-center.mb-2
        app-title(:type="'large'" v-if="!type") Выберите причину отстранения
        app-title(:type="'large'" v-else) Укажите причину
      p.text-size--small.text-color--blue.mt-0.pt-0.text-uppercase.text-center {{ data.patient_full_name }}
      .row.justify-content-center.mt-5
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="pulse")
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_high_alarm}') {{ data.bp_high }}/
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="cardio")
            span(v-if="data.heart_rate" :class='{yellowText: data.heart_rate_alarm}') {{ data.heart_rate }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="temp")
            span(v-if="data.body_temp" :class='{yellowText: data.body_temp_alarm}') {{ data.body_temp + "\u2103" }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="breathalyzer")
            span(v-if="data.alcohol >= 0  && data.alcohol !== null" :class='{yellowText: data.alcohol_alarm}') {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
            span(v-else) &#8212;

      .row
        .col-14
          .reasonsHeader Медицинские
          .reasons
            template(v-for='reason in reasonsByType.medical')
              .reason.reason-input
                input(
                  v-if="reason.title !== 'Травма'"
                  type='checkbox'
                  :id="'med'+reason.id"
                  v-model='reason.checked'
                  :disabled='isReasonDisabled(reason) && !protector || reason.checked && !protector && !reason.manuallyAdded'
                  @change='addReason(reason)'
                )
                label(
                  v-if="reason.title !== 'Травма'"
                  :for="'med'+reason.id"
                ) {{ reason.title }}

                input(
                  v-if="reason.title == 'Травма'"
                  type='checkbox'
                  :checked='isAnyInjurySelected'
                  @click='showinjuries(reason.title)')
                label(
                  v-if="reason.title == 'Травма'"
                  :for="'med'+reason.id"
                  @click='showinjuries(reason.title)'
                ) {{ reason.title }}

        .col-14.mt-5(v-if="injury")
          .row
            .col-7
              .row.mb-4
                .col-8.black.text-bold Локализация травмы
                .col-6
                  .row
                    .col-auto.black.text-bold Слева
                    .col-auto.black.text-bold Справа
                    .col-auto.black.text-bold Общая
            .col-7
              .row.mb-4
                .col-8.black.text-bold Локализация травмы
                .col-6
                  .row
                    .col-auto.black.text-bold Слева
                    .col-auto.black.text-bold Справа
                    .col-auto.black.text-bold Общая
            .col-7(v-for='injurie in reasonsByType.injuries' :key='injurie.id')
              .row.align-items-center.mb-3
                .col-8 {{injurie.title}}
                .col-6
                  .row
                    .col-auto.reason-short
                      .reason
                        div(v-if='typeof injurie.left === "boolean"')
                          input(:id="'left'+injurie.id" v-model='injurie.left' type='checkbox' @change="checkInjuriePart(injurie)")
                          label(:for="'left'+injurie.id")
                    .col-auto.reason-short
                      .reason
                        div(v-if='typeof injurie.right === "boolean"')
                          input(:id="'right'+injurie.id" v-model='injurie.right' type='checkbox' @change="checkInjuriePart(injurie)")
                          label(:for="'right'+injurie.id")
                    .col-auto.reason-short
                      .reason
                        div(v-if='typeof injurie.both === "boolean"')
                          input(:id="'both'+injurie.id" type='checkbox' v-model='injurie.both' @change="checkInjurieBoth(injurie)")
                          label(:for="'both'+injurie.id")

        .col-14(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no' || type == '5no'" )
          .reasonsHeader Административные
          .reasons
            .reason.reason-input(v-for='reason in reasonsByType.administrative' :key='reason.id')
              input(type='checkbox' :id="'adm'+reason.id" v-model='reason.checked' @change="addReason(reason)")
              label(:for="'adm'+reason.id") {{reason.title}}

        .col-14(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no'  || type == '5no'" )
          .reasonsHeader Технические
          .reasons
            .reason.reason-input(v-for='reason in reasonsByType.technical' :key='reason.id')
              input(type='checkbox' :id="'tecn'+reason.id" v-model='reason.checked' @change="addReason(reason)")
              label(:for="'tecn'+reason.id") {{reason.title}}

        .col-14
          .reason.d-flex.justify-content-end(:class="classObject")
            button(
              type="button"
              @click="refuseOpen()"
              class="button button--accent button--height mt-3"
              :disabled="!(isAnyCheckboxSelected || type === '2yes' || type === '4yes' || type === '5yes')"
            )
              span.button__content.d-flex.align-items-center {{ btnContent }}

