<template lang="pug">
  .profile-settings
    .profile-settings__inner
      .profile-settings__header
        .row
          .col.col-md-auto
            app-title(:type="'large'" :classes="'text-center'") Настройки личного кабинета

      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      transition(name="fade" mode="out-in")
        .profile-settings__content.mt-5(v-if="currentUser")
          .profile-settings__user.d-flex.align-items-start
            app-avatar(
              :src="currentUser.avatar"
              :viewPhoto="true"
              :edit="true"
              :classes="'extra-large me-25'"
              @submit="onChangeUser"
              @openGallery="openGallery"
              ref="changeAvatar"
              :key="avatarKey"
            )
            ul
              li.mb-3.text-color--accent.text-family--medium(v-if="userRole === 'admin'") ADMIN
              li.mb-3(v-if="userRole !== 'client'") {{ currentUser.full_name }}
              li.mb-3(v-if="userRole === 'client'") {{ currentUser.company_name || currentUser.full_name }}
              li.mb-3(v-if="currentUser.inn") ИНН {{ currentUser.inn }}
              li {{ currentUser.email }}

          .row.align-items-center.g-3.mt-5
            .col-14.col-sm-6.col-md-auto
              button(
                type="button"
                @click="isModalVisible.changeEmail = true"
                class=['button button--white button--border-blue', 'w-100']
              )
                span.button__content Сменить e-mail

            .col-14.col-sm-6.col-md-auto
              button(
                type="button"
                @click="isModalVisible.changeNames = true"
                class=['button button--white button--border-blue', 'w-100']
              )
                span.button__content Сменить {{ isPerson ? 'Ф.И.О.' : 'наименование' }}

            .col-14.col-sm-6.col-md-auto
              button(
                type="button"
                @click="isModalVisible.changePassword = true"
                class=['button button--white button--border-blue', 'w-100']
              )
                span.button__content Сменить пароль

          .mt-5(v-if="userRole !== 'doctor'")
            button(
              type="button"
              @click="isModalVisible.deleteUser = true"
              class=['text-color--danger text-decoration--underline text-size--small link--accent']
            ) Отменить регистрацию на портале


    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')" classes="small")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") {{ captionModal.title }}
            p.text-size--small.text-color--blue.mt-3(v-if="captionModal.text") {{ captionModal.text }}

          .d-flex.justify-content-center.mt-5
            button(
              @click="closeModal('success')"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content В личный кабинет

    // delete user modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteUser" @close="closeModal('deleteUser')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Отменить регистрацию на портале?

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteUser')"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Не отменять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteUser"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, отменить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Также удалятся все связанные устройства и осмотры.<br>Данное действие необратимо.

    // change names modal
    app-change-names-modal(
      ref="changeNames"
      :is-visible="isModalVisible.changeNames"
      :initial-values="initialValues.names"
      :is-loading="usersLoading"
      :error="usersError"
      @submit="onChangeUser"
      @close="closeModal"
      :userId='currentUser.id'
    )
    // change email modal
    app-change-email-modal(
      ref="changeEmail"
      :is-visible="isModalVisible.changeEmail"
      :initial-values="initialValues.email"
      :is-loading="usersLoading"
      :error="usersError"
      @submit="onChangeUser"
      @close="closeModal"
      :userId='currentUser.id'
    )
    // change password modal
    app-change-pass-modal(
      ref="changePassword"
      :is-visible="isModalVisible.changePassword"
      :is-loading="usersLoading"
      :error="usersError"
      @submit="onChangeUser"
      @close="closeModal"
      :userId='currentUser.id'
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {
  actionTypes as authActionTypes,
  mutationTypes as authMutationTypes
} from '@/store/modules/auth';
import {actionTypes as usersActionTypes} from '@/store/modules/users';

import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppGallery from '@/components/common/Gallery';

import AppChangeNamesModal from '@/components/profile/common/settings/ChangeNamesModal';
import AppChangeEmailModal from '@/components/profile/common/settings/ChangeEmailModal';
import AppChangePassModal from '@/components/profile/common/settings/ChangePassModal';
import AppAvatar from '@/components/profile/common/Avatar';

import {modalsMixin} from '@/mixins/modals';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'AppSettings',
  components: {
    AppAvatar,
    AppModal,
    AppTitle,
    AppGallery,
    AppLoading,
    AppErrorMessage,
    AppChangeNamesModal,
    AppChangeEmailModal,
    AppChangePassModal,
  },
  mixins: [modalsMixin, galleryMixin],
  data() {
    return {
      v: variables,
      avatarKey: 0,
      isModalVisible: {
        changeEmail: false,
        changeNames: false,
        changePassword: false,
        deleteUser: false,
        pending: false,
        success: false,
      },
      captionModal: {
        title: 'Успешно изменено!',
        text: null,
      },
    };
  },
  mounted() {
    console.log(this.currenUser);
    setTimeout(() => console.log(this.currentUser), 5000)
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
      userRole: (state) => state.auth.userRole,
      isLoading: (state) => state.auth.isLoading,
      error: (state) => state.auth.error,
    }),
    ...mapState({
      usersLoading: (state) => state.users.isLoading,
      usersError: (state) => state.users.error,
    }),
    initialValues() {
      if (!this.currentUser) return null;

      return {
        names: {
          companyName: this.currentUser.company_name,
          firstName: this.currentUser.first_name,
          middleName: this.currentUser.middle_name,
          lastName: this.currentUser.last_name,
        },
        email: this.currentUser.email,
      };
    },
    isPerson() {
      if (!this.currentUser) return null;
      return this.currentUser.user_type === this.v.user.types.person;
    },
    isCompany() {
      if (!this.currentUser) return null;
      return this.currentUser.user_type === this.v.user.types.company;
    },
  },
  methods: {
    ...mapMutations({
      setCurrentUser: authMutationTypes.getCurrentUserSuccess
    }),
    getCurrentUser(slug) {
      this.$store.dispatch(authActionTypes.getCurrentUser).then(() => this.$refs[slug].resetForm());
    },
    onLogout() {
      this.$store.dispatch(authActionTypes.logout).then(() => this.$router.push({name: 'home'}));
    },
    onChangeUser(data) {
      let params;
      const { slug, user_id, form } = data;
      if (slug === 'changeAvatar') {
        params = { user_id: this.currentUser.id, params: form }
      } else {
        params = form
        params.user_id = user_id;
      }
      this.$store.dispatch(usersActionTypes[slug], params).then((responseData) => {
        this.closeModal(slug);

        console.log(responseData)

        if (slug === 'changeAvatar') {
          this.avatarKey++;
          const newCurrentUser = { ...this.currentUser }
          newCurrentUser.avatar = responseData;
          this.setCurrentUser(newCurrentUser);
          this.captionModal.title = 'Фото пользователя успешно изменено!';
          this.captionModal.text = 'Изменения отобразятся при следующей авторизации';
        }
        else if (slug === 'changeNames') {
          this.captionModal.title = 'Ф.И.О. успешно изменены!';
          this.captionModal.text = 'Изменения отобразятся при следующей авторизации';
        }
        else if (slug === 'changeEmail') {
          this.captionModal.title = 'Email успешно изменён!';
          this.captionModal.text = 'Используйте новый email при следующей авторизации';
        }
        else if (slug === 'changePassword') {
          this.captionModal.title = 'Пароль успешно изменён!';
          this.captionModal.text = 'Используйте новый пароль при следующей авторизации';
        }

        this.openModal('success');
        this.getCurrentUser(slug);
      });
    },
    deleteUser() {
      this.$store
        .dispatch(usersActionTypes.deleteUser, {
          id: this.currentUser.id,
        })
        .then(() => {
          this.onLogout();
        });
    },
  },
};
</script>

<style lang="scss">
.profile-settings {
  .form {
    &__field {
      color: get($colors, blue-dark);
    }
  }
}
</style>
