const state = {
  exam_in_work: false,
  exam_body: '',
  tasks_count: null,
  //переименовать
  pause_exam: false,
};

const getters = {
  EXAM_IN_WORK: state => {
    return state.exam_in_work;
  },
  EXAM_BODY: state => {
    return state.exam_body;
  },
  TASKS_COUNT: state => {
    return state.tasks_count;
  },
  PAUSE_EXAM: state => {
    return state.pause_exam;
  },
};

const mutations = {
  SET_EXAM_IN_WORK: (state, payload) => {
    state.exam_in_work = payload;
  },
  SET_EXAM_BODY: (state, payload) => {
    state.exam_body = payload;
  },
  SET_TASKS_COUNT: (state, payload) => {
    state.tasks_count = payload;
  },
  SET_PAUSE_EXAM: (state, payload) => {
    state.pause_exam = payload;
  },
};

export default {
  state,
  mutations,
  getters,
};
