<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close" :classes="`full-content close-outside ${options.type || 'gallery'}`")
    template(v-slot:body)
      .modal__preview.d-flex.align-items-center.justify-content-center(v-if="options.preview")
      ul(v-if="!changePhoto")
        li(v-for="image in options.sources" :key="image.src")
          img.mb-4(:src="image.src" class=['d-block mx-auto'])
          .row(v-if="changeSignature")
            .col-7(v-if="options.edit")
              button(
                type="button"
                @click="openEditModal(options.edit)"
                class=['button button--blue', 'w-100']
              )
                span.button__content Изменить
            .col-7(v-if="options.delete")
              button(
                type="button"
                @click="openDeleteModal(options.delete)"
                class=['button button--blue', 'w-100']
              )
                span.button__content Удалить

    template(v-slot:footer)
      .row.gx-4(v-if="changePhoto")
        #carouselExampleControls.carousel(data-bs-ride="carousel"  data-bs-interval="false")
          .carousel-inner
            .carousel-item(v-for="(image, index) in archivePhotos" :key="index" :class="{ 'carousel-item active': index === 0, 'carousel-item': index !== 0 }")
              p.archive-date.text-end(v-if="index !== 0") Дата архивации: {{ toLocalDateExam(image.updated_at) }} {{ toLocalTimeExam(image.updated_at) }}
              img.d-block.mb-5(:src="image.path" :alt="image.name")
              .row(v-if="index == 0 && options.userType !== 'doctor'" )
                .col-7
                  button(
                    type="button"
                    @click="openEditModal(options.edit)"
                    class=['button button--blue', 'w-100']
                  )
                    span.button__content Изменить
                .col(v-if="options.delete" :class="options.userType !== 'doctor' ? 'col-7' : 'col'")
                  button(
                    type="button"
                    @click="openDeleteModal(options.delete)"
                    class=['button button--blue', 'w-100']
                  )
                    span.button__content Архивировать

        div(v-if="archivePhotos.length > 1" )
          button.carousel-control-prev(type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev")
            span.carousel-control-prev-icon(aria-hidden="true")
            span.visually-hidden Предыдущий
          button.carousel-control-next(type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next")
            span.carousel-control-next-icon(aria-hidden="true")
            span.visually-hidden Следующий

</template>

<script>
import AppModal from '@/components/common/Modal';
import AppSlider from '@/components/common/Slider';
import patientsApi from '@/api/patients';
import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';

export default {
  name: 'AppGallery',
  components: {
    
    AppModal,
    AppSlider,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      carouselInterval: false,
      archivePhotos: [],
    };
  },
  computed: {
    changePhoto() {
      return this.options.delete === 'deleteAvatar' && this.options.delete !== 'deleteSignature';
    },
    changeSignature() {
      return this.options.delete !== 'deleteAvatar' && this.options.delete == 'deleteSignature';
    },
  },
  watch: {
    options: {
      handler() {
        this.getArchivePhotos();
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    openEditModal(slug) {
      this.$emit('openEditModal', slug);
    },
    openDeleteModal(slug) {
      this.$emit('openDeleteModal', slug);
    },
    async getArchivePhotos() {
      const patientId = this.options.patient_code
        ? this.options.patient_code
        : this.$route.params.id;
      const res = await patientsApi.getAllPatientAvatars(patientId);
      this.archivePhotos = res.data.items;
    },
    toLocalDateExam(date) {
      return toLocalDateExam(date);
    },
    toLocalTimeExam(time) {
      return toLocalTimeExam(time);
    },
  },
};
</script>

<style lang="scss">
.image_description {
  color: white;
  text-align: left;
}

.carousel-inner {
  .carousel-item {
    width: auto;
  }
  img {
    width: 413px;
    height: auto;
  }
  .archive-date {
    color: white;
  }
}
</style>
