<template lang="pug">
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeForm")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Добавление медорганизации

        .px-md-4
          .row.g-4.mb-4
            //- .col-14.col-md-7
            //-   so-button(
            //-     :type='form.registering_type === "COMPANY" ? "primary" : "default"'
            //-     @on-click='form.registering_type = "COMPANY"'
            //-   ) Юридическое лицо
            //- .col-14.col-md-7
            //-   so-button(
            //-     :type='form.registering_type === "PERSONAL" ? "primary" : "default"'
            //-     @on-click='form.registering_type = "PERSONAL"'
            //-   ) Физическое лицо

          form(@submit.prevent="onSubmit" :class="['form']")
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .row.g-4
              //- template(v-if='form.registering_type === "PERSONAL"')
              //-   .col-14.col-md-5
              //-     label.form__label
              //-       input(
              //-         :class="['form__field', { 'is-error': v$.form.first_name.$errors.length }]"
              //-         type="text"
              //-         v-model="v$.form.first_name.$model"
              //-         placeholder="* Имя"
              //-       )
              //-     ul.form__error(v-if="v$.form.first_name.$errors.length")
              //-       li(v-for="error of v$.form.first_name.$errors" :key="error.$uid")
              //-         template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              //-   .col-14.col-md-5
              //-     label.form__label
              //-       input(
              //-         :class="['form__field', { 'is-error': v$.form.last_name.$errors.length }]"
              //-         type="text"
              //-         v-model="v$.form.last_name.$model"
              //-         placeholder="* Фамилия"
              //-       )
              //-     ul.form__error(v-if="v$.form.last_name.$errors.length")
              //-       li(v-for="error of v$.form.last_name.$errors" :key="error.$uid")
              //-         template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              //-   .col-14.col-md-4
              //-     label.form__label
              //-       input(
              //-         :class="['form__field', { 'is-error': v$.form.middle_name.$errors.length }]"
              //-         type="text"
              //-         v-model="v$.form.middle_name.$model"
              //-         placeholder="* Отчество"
              //-       )
              //-     ul.form__error(v-if="v$.form.middle_name.$errors.length")
              //-       li(v-for="error of v$.form.middle_name.$errors" :key="error.$uid")
              //-         template(v-if="error.$validator === 'required'") {{ v.errors.required }}


              template
                .col-14.col-md-14
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.name.$errors.length }]"
                      type="text"
                      v-model="v$.form.name.$model"
                      placeholder="* Наименование медорганизации"
                    )
                  ul.form__error(v-if="v$.form.name.$errors.length")
                    li(v-for="error of v$.form.name.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              //- template(v-if='form.registering_type === "PERSONAL"')
              //-   .col-14.col-md-7
              //-     label.form__label
              //-       input(
              //-         :class="['form__field', { 'is-error': v$.form.ogrnip.$errors.length }]"
              //-         type="text"
              //-         v-mask='"#-##-##-##-#####-###"'
              //-         v-model="v$.form.ogrnip.$model"
              //-         placeholder='* ОГРНИП'
              //-       )
              //-     ul.form__error(v-if="v$.form.ogrnip.$errors.length")
              //-       li(v-for="error of v$.form.ogrnip.$errors" :key="error.$uid")
              //-         template(v-if="error.$validator === 'required'") {{ v.errors.ogrnip.required }}
              //-         template(v-if="error.$validator === 'minLength'") {{ v.errors.ogrnip.minLength }}

              template
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.ogrn.$errors.length }]"
                      type="text"
                      v-model="v$.form.ogrn.$model"
                      placeholder='* ОГРН'
                    )
                  ul.form__error(v-if="v$.form.ogrn.$errors.length")
                    li(v-for="error of v$.form.ogrn.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.ogrn.required }}
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.ogrn.minLength }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                    type="number"
                    v-model="v$.form.inn.$model"
                    placeholder='* ИНН'
                  )
                ul.form__error(v-if="v$.form.inn.$errors.length")
                  li(v-for="error of v$.form.inn.$errors" :key="error.$uid")
                    //- template(v-if="error.$validator === 'required'") {{ form.registering_type === 'PERSONAL' ? v.errors.inn_person.required : v.errors.inn_company.required }}
                    template(v-if="error.$validator === 'required'") {{ v.errors.inn_company.required }}
                    //- template(v-if="error.$validator === 'minLength'") {{ form.registering_type === 'PERSONAL' ? v.errors.inn_person.minLength : v.errors.inn_company.minLength }}
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.inn_company.minLength }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.phone.$errors.length }]"
                    type="tel"
                    v-mask="'+7 (###) ###-##-##'"
                    v-model="v$.form.phone.$model"
                    placeholder='* Телефон'
                  )
                ul.form__error(v-if="v$.form.phone.$errors.length")
                  li(v-for="error of v$.form.phone.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.phone.required }}
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.phone.minLength }}

              .col-14.col-md-7
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                    type="email"
                    v-model="v$.form.email.$model"
                    placeholder='* Электронная почта'
                  )
                ul.form__error(v-if="v$.form.email.$errors.length")
                  li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              span МЕДИЦИНСКАЯ ЛИЦЕНЗИЯ
              .col-14
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.medical_license.$errors.length }]"
                    type="text"
                    v-model="v$.form.medical_license.$model"
                    placeholder="* Серия и номер"
                  )
                ul.form__error(v-if="v$.form.medical_license.$errors.length")
                  li(v-for="error of v$.form.medical_license.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14
                .row
                  .col-14.col-md-5
                    label(:class="['form__label', { 'is-error': v$.form.medical_license_start.$errors.length }]")
                      date-picker(
                        v-model="v$.form.medical_license_start.$model"
                        prefix-class="app"
                        placeholder="* Срок начала"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableAfterToday"
                      )
                  .col-7.col-md-5
                    label.form__label
                      date-picker(
                        v-model="form.medical_license_end"
                        prefix-class="app"
                        placeholder="* Срок окончания"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableExpiration"
                        :disabled='form.hasNoLimit'
                      )

                  .col-7.col-md-4
                    label.form__checkbox.my-2
                      input(
                        type="checkbox"
                        name="user-type"
                        v-model='form.hasNoLimit'
                      )
                      i.form__checkbox-icon.me-3
                      span.form__checkbox-caption Бессрочно

              span ИДЕНТИФИКАТОР МЕДОРГАНИЗАЦИИ
              .col-14
                label.form__label
                  input(
                    :class="['form__field']"
                    type="text"
                    v-model="form.guid"
                    v-mask="'########-####-####-####-############'"
                    placeholder='GUID'
                  )

              .col-14
                button(
                  type="submit"
                  :disabled="v$.form.$invalid"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Добавить медорганизацию

  // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Новая медорганизация добавлена

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeSuccessModal"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content На страницу медорганизаций
</template>

<script>
import medOrganizationsApi from '@/api/medOrganizations';

import {mapState} from 'vuex';

import variables from '@/helpers/variables';
import useVuelidate from '@vuelidate/core';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppTooltip from '@/components/common/Tooltip';
import AppValidationErrors from '@/components/common/ValidationErrors';
import { SoButton } from '@/components/so';

import { required, minLength, email } from '@vuelidate/validators';
import {modalsMixin} from '@/mixins/modals';
import {mask} from 'vue-the-mask';
import { convertDate } from '@/helpers/utils';
import { disableAfterToday, disableExpiration } from '@/helpers/datepicker';

export default {
  name: 'AppCreateMedOrganizationModal',
  components: {
    AppTitle,
    AppModal,
    AppTooltip,
    AppMultipleSelect,
    AppValidationErrors,
    DatePicker,
    SoButton
  },
  directives: {
    mask,
  },
  mixins: [modalsMixin],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        registering_type: 'COMPANY',
        name: '',
        ogrn: '',
        guid: '',
        inn: '',
        phone: '',
        email: '',
        medical_license: '',
        medical_license_start: '',
        medical_license_end: '',
        hasNoLimit: false,
        first_name: '',
        last_name: '',
        middle_name: '',
        ogrnip: '',
      },
      localValidationErrors: null,
    };
  },
  validations() {
    // const isPersonal = this.form.registering_type === 'PERSONAL'
    const isPersonal = false;
    return {
      form: {
        name: !isPersonal ? { required } : {},
        ogrn: !isPersonal ? { required, minLength: minLength(13) } : {},
        inn: {
          required,
          minLength: isPersonal ? minLength(12) : minLength(10)
        },
        first_name: isPersonal ? { required } : {},
        last_name: isPersonal ? { required } : {},
        middle_name: isPersonal ? { required } : {},
        ogrnip: isPersonal ? { required, minLength: minLength(20) } : {},
        email: {
          email,
          required
        },
        phone: {
          required,
          minLength: minLength(18)
        },
        medical_license: { required },
        medical_license_start: { required },
        medical_license_end: !this.form.hasNoLimit ? { required } : {},
      },
    };
  },

  watch: {
    form: {
      deep: true,
      handler(v) {
        console.log(v)
      }
    }
  },

  methods: {
    async onSubmit() {
      if (!this.v$.$invalid) {
        let str = this.form.ogrn;
        if (this.form.ogrn) {
          this.form.ogrn = str.replace(/[^+\d]/g, '');
        }
        if (this.form.ogrnip) {
          this.form.ogrnip = this.form.ogrnip.replace(/[^+\d]/g, '');
        }
        if (this.form.guid) {
          this.form.guid = this.form.guid.replace(/[^+\d]/g, '');
        }
        this.form.inn = this.form.inn.replace(/[^+\d]/g, '');

        const method = medOrganizationsApi.createMedOrganization;

        const additionals = {};

        const common = {
          // registering_type: this.form.registering_type,
          registering_type: 'COMPANY',
          phone: this.form.phone,
          email: this.form.email,
          medical_license: this.form.medical_license,
          medical_license_start: convertDate(this.form.medical_license_start),
          medical_license_end: this.form.hasNoLimit ? null : convertDate(this.form.medical_license_end),
          inn: this.form.inn,
          guid: this.form.guid || null,
          exams_type: 'SYNCHRONOUS'
        }

        // const params = this.form.registering_type === 'PERSONAL'
        // ? {
        //   first_name: this.form.first_name,
        //   middle_name: this.form.middle_name,
        //   last_name: this.form.last_name,
        //   ogrnip: this.form.ogrnip,
        // }
        // : {
        //   name: this.form.name,
        //   ogrn: this.form.ogrn,
        // };
        const params = {
          name: this.form.name,
          ogrn: this.form.ogrn,
        };

        try {
          await method({ ...common, ...params, ...additionals });
          this.openModal('success');
          this.closeForm();
        } catch (error) {
          this.localValidationErrors = error.request.statusText;
        }
      }
    },
    resetForm() {
      this.v$.$reset();
      for (const key in this.form) {
        this.form[key] = null;
      }
      this.form.registering_type = 'COMPANY';
      this.localValidationErrors = null;
    },
    closeForm() {
      this.resetForm();
      this.$emit('close');
    },
    closeSuccessModal() {
      this.$emit('success');
      this.closeModal('success');
    },
    disableAfterToday(date) {
      return disableAfterToday(date, this.form.medical_license_start);
    },
    disableExpiration(date) {
      return disableExpiration(date, this.form.medical_license_end);
    },
  },
};
</script>
