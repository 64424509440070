<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-3
        app-title(:type="'large'") Добавить версию прошивки
        app-validation-errors(
          v-if="validationErrors"
          :validation-errors="validationErrors"
          :classes="'mb-2'"
      )
      form(@submit.prevent="onSubmit" class="form")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
            .row.g-4.posRelative
              .col
                .row.gx-4
                  .col-14.mt-3
                    input(
                      :class="['form__field form__field--animate']"
                      type="text"
                      name="id"
                      v-model="v$.form.version.$model"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                    )
                    label.textFloat(for='id') Версия прошивки
                  .col-14.mt-3
                    input(
                      :class="['form__field form__field--animate']"
                      type="text"
                      name="id"
                      v-model="v$.form.controlSum.$model"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="ids"
                    )
                    label.textFloat(for='ids') Контрольная сумма
          .col-14.col-md-10.mt-5
            button(
              type="submit"
              :disabled="v$.form.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Добавить

</template>

<script>
import {mapState} from 'vuex';
import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required, minLength} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
export default {
  name: 'addFirmwareModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,

    DatePicker,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        version: '',
        controlSum: '',
      },
      validationErrors: '',
    };
  },
  validations() {
    return {
      form: {
        version: {
          required,
          minLength: minLength(1),
        },
        controlSum: {
          required,
          minLength: minLength(1),
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetForm();
    },
    success() {
      this.$emit('success');
    },

    async onSubmit() {
      try {
        let data = {
          'version': this.form.version,
          'sum': this.form.controlSum
        };
        const response = await this.$store.dispatch('actionNewFirmware', data);
        if (response !== 'A integrity_control sum and version must be unique') {
          this.$emit('firmwareEdited');
          this.$toast.success('Добавлена версия прошивки');
          this.closeModal();
          this.resetForm();
        } else {
          this.validationErrors = 'a_integrity_control_sum_and_version_must_be_unique'
        }
      } catch {
      }
    },

    resetForm() {
      this.v$.$reset();
      this.form.version = '';
      this.form.controlSum = '';
      this.validationErrors = '';
    },
  },
};
</script>
<style lang="scss">
.outer-form {
  position: relative;
  border: 1px solid #989fb8;
  border-radius: 8px;
  padding: 12px;
  padding-top: 4;
}
.maw365 {
  max-width: 365px;
}
.form__field--no-border {
  border: none !important;
  padding-left: 0 !important;
  transition: 0.2s ease !important;
  border-bottom: 1px solid #dfe4f8 !important;
  border-radius: 0 !important;
}
.addCheck {
  position: absolute;
  top: 25px;
  right: 15px;
  z-index: 999;
  font-size: 12px;
  cursor: pointer;
}
.form__label--float {
  position: relative;
}
.fl {
  position: absolute;
  //top: 10px;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(10px);
  transition: 0.2s ease-out;
}
.form__field--no-border:focus + .fl,
.form__field--no-border:not(:placeholder-shown) + .fl {
  transform: translateY(-10px) scale(0.85);
}
.form__field--no-border::placeholder,
.form__field--animate::placeholder {
  color: #fff !important;
}
.textFloat {
  position: absolute;
  //top: 10px;
  display: block;
  left: 0px;
  transform-origin: 0% 100%;
  transform: translateY(-28px);
  transition: 0.2s ease-out;
  padding-left: 20px;
  cursor: inherit;
}
.form__field--animate {
  height: auto !important;
  padding-top: 18px !important;
  padding-bottom: 2px !important;
  text-transform: uppercase;
  padding-left: 12px !important;
}
.form__field--animate:focus + .textFloat,
.form__field--animate:not(:placeholder-shown) + .textFloat {
  transform: translateY(-43px) translateX(7px) scale(0.65);
  margin-right: 20px;
}
.posRelative {
  position: relative;
}
</style>
