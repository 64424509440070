<template lang="pug">
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__content.position-relative
    transition(name="translucent")
      div
        app-table(
          v-if="devices.length"
          :columns="columns"
          :sortKey="sortKey"
          @sortingRow="sortingDevices"
          :data="devices"
          :paginationData="pagination"
          :currentPage="currentPage"
          :baseUrl="baseUrl"
          :canEdit="true"
          :canRemove="false"
          @change-current-page="setCurrentPage"
          @edit-modal="editModal"
        )
          template(v-slot:cell='{ column, row }')
            span(v-if='column.key==="device_attestation_start"') {{ toLocalDate(row[column.key]) }}
            span(v-else-if='column.key==="device_attestation_end"') {{ toLocalDate(row[column.key]) }}
            span(v-else-if='column.key==="attestation"' :class="{'text-color--orange': highlightParams(row[column.key])}") {{ formatDeviceAttestation(row[column.key]) }}

        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              so-icon(icon="wrong")
            span Нет устройств

        app-edit(
          :is-visible="isModalVisible.edit"
          @close="closeModal('edit')"
          @update="getDevices"
          :toEdit="toEdit"
        )
  // filter
  app-filter-check(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="closeFilter"
    @clear="closeFilter"
    @submit="submitFilter"
    )

</template>

<script>
import devicesApi from '@/api/devices';
import { actionTypes as devicesActionTypes } from '@/store/modules/devices';

import { blobToFile, toLocalDate } from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppErrorMessage from '@/components/common/ErrorMessage';

import AppFilterCheck from '@/components/profile/common/FilterCheck';
import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import { modalsMixin } from '@/mixins/modals';
import AppEdit from '@/components/profile/admin/devices/AppDeviceCheckEditModal';

import AppTable from '@/components/common/table/Table';

export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    AppFilterCheck,
    AppTitle,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    AppEdit,
    AppTable,
  },
  mixins: [modalsMixin],
  data() {
    return {
      isFilterOpen: false,
      devices: [],
      filterString: '',
      toEdit: {},
      isModalVisible: {
        create: false,
        edit: false,
      },
      sortKey: 'device_attestation_start',
      columns: [
        {
          caption: 'Серийный номер МИ',
          key: 'serial',
          inverse: true,
        },
        {
          caption: 'Дата поверки',
          key: 'device_attestation_start',
          inverse: true,
        },
        {
          caption: 'Действие до',
          key: 'device_attestation_end',
          inverse: true,
        },
        {
          caption: 'Номер свидетельства',
          key: 'device_attestation',
          inverse: true,
        },
        {
          caption: 'Поверка СИ',
          key: 'attestation',
          inverse: false,
        },
        {
          caption: '',
          key: 'buttons',
          inverse: false,
        },
      ],
      pagination: {
        limit: 0,
        total: 0,
      },
    };
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          devices: [],
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getDevices();
    },
  },
  async created() {
    this.getDevices();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({ query: { page: '1' } });
    },

    async getDevices() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = '';
      if (this.sortKey === 'company_name') {
        order_by = `&order_by=${sortInverse}${this.sortKey}`;
      } else {
        order_by = `&device_order_by=${sortInverse}${this.sortKey}`;
      }
      let resultFilter =
        `?page=${this.currentPage - 1}${this.filterString}` + `${order_by}`;
      let res = await devicesApi.getDevices(resultFilter);

      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.devices = res.data.items;
    },
    sortingDevices(sortKey, inverse) {
      if (inverse === false) {
        return;
      }
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getDevices();
    },
    closeModal(slug) {
      this.isModalVisible[slug] = false;
    },
    editModal(data) {
      this.isModalVisible.edit = true;
      this.toEdit = data;
    },
    async submitFilter(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getDevices();
    },
    //pagination
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getDevices();
    },
    // Обработка данных
    toLocalDate(date) {
      return toLocalDate(date);
    },
    formatDeviceAttestation(data) {
      return data === 'attestation_ends'
        ? 'Заканчивается'
        : data === 'not_attestation'
          ? 'Не поверено'
          : 'Поверено';
    },
    highlightParams(data) {
      return data === 'not_attestation' || data === 'attestation_ends';
    },
    openFilter() {
      this.isFilterOpen = true;
    },
    closeFilter() {
      this.isFilterOpen = false;
    },
  },
};
</script>
