
div
    template
     .doctor-exam-detail-modal__info.doctor-exam-detail-modal__info--white(v-for="exam in $store.state.chart.lastInspections.five_exams" :key="exam.id")
        .doctor-exam-detail-modal__info-item
          .row.align-items-center.gx-0
            .col-3
              .roundGreen(v-if="exam.admission_to_work && exam.exam_completed")
              .roundYellow(v-if="exam.admission_to_work === null && exam.exam_completed")
              .roundRed(v-if="exam.admission_to_work === false && exam.exam_completed")
              .text-color--blue.mb-2 Дата и время осмотра
              span {{$moment(exam.exam_datetime).format('DD.MM.YYYY HH:mm')}}
            .col-5
             .row
              .col-3
               .d-flex.align-items-center
                  span(:class="[bpHighColor(exam.bp_high), bpLowColor(exam.bp_low)]").flex-shrink-0.me-2
                      so-icon(icon="pulse")
                  span(:class="bpHighColor(exam.bp_high)") {{exam.bp_high}}
                  span &nbsp;/&nbsp;
                  span(:class="bpLowColor(exam.bp_low)") {{exam.bp_low}}
              .col-3
                .d-flex.align-items-center.ps-4
                  span.flex-shrink-0.me-2
                      so-icon(icon="cardio")
                  span {{exam.heart_rate}}
              .col-3
                .d-flex.align-items-center
                  span.flex-shrink-0.me-2
                      so-icon(icon="temp")
                  span(:class="bpTemp(exam.body_temp)") {{exam.body_temp}}&nbsp;c&#176;
              .col-3
               .d-flex.align-items-center
                span(:class="alcoholColor(exam.alcohol)").flex-shrink-0.me-2
                  so-icon(icon="breathalyzer")
                span(:class="alcoholColor(exam.alcohol)") {{exam.alcohol}}&nbsp;мг&#65279;/&#65279;л
              .col-2
               .d-flex.align-items-center.btn-comment
                app-tooltip(
                   v-if="exam.health_complaint"
                   :content="`<p class='mb-2'>Жалоба на состояние здоровья:</p><p class='text-color--black'>${exam.health_complaint}<p>`"
                  :icon="'comment'"
                 )
            .col-2
              .text-color--blue.mb-2.ps-4 Тип осмотра:
              span.ps-4 {{exam.exam_type_name}}
            .col-2
              .text-color--blue.mb-2 Допуск
              span.fw(v-if="exam.admission_to_work && exam.exam_completed") Допущен
              span.fw(v-if="exam.admission_to_work === null && exam.exam_completed") Нет заключения
              span.fw(v-if="exam.admission_to_work === false && exam.exam_completed") Не допущен
            .col-2
              .text-color--blue.mb-2 Медработник
              span(v-if='exam.exam_doctor_full_name') {{exam.exam_doctor_full_name}}
              span -

