<template lang="pug">
.profile-avatar.d-flex.flex-column.align-items-center(:class="classes")
  //- Картинка аватара
  figure.profile-avatar__img.position-relative
    template(v-if="localUrl")
      template(v-if="!isError")
        button(v-if="viewPhoto" class="stretched-link" type="button" @click.prevent="openGallery(type, avatarUrl, id)")
        img(:src="avatarUrl")

      template(v-else)
        //- Если произошла ошибка при загрузке изображения
        button(v-if="type === 'patient'" class="stretched-link" type="button" @click="changeAvatar")
        img(src='@/assets/svg/no-image.svg')

    //- если нет изображения
    template(v-else-if='src')
      button(v-if="type === 'patient'" class="stretched-link" type="button" @click="changeAvatar")
      img(v-else :src='src')

    //- если нет изображения
    template(v-else)
      button(v-if="type === 'patient'" class="stretched-link" type="button" @click="changeAvatar")
      so-icon(v-if="type === 'patient'" icon="no-image")
      img(v-else src='@/assets/svg/no-image.svg')

  //- модальное окно для изменения аватарки
  template(v-if="edit")
    input(class="d-none" type="file" name="document" @change="onFileSelected" ref="fileInput" accept="image/*")
    button(class=['profile-avatar__edit-button', 'd-flex align-items-center justify-content-center', 'mt-4'] type="button" @click="$refs.fileInput.click()")
      span.d-flex.flex-shrink-0
        so-icon(icon="edit")

    //- Модальное окно для кроппера
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.cropper" @close="closeCropperModal" :classes="'full-content'")
        template(v-slot:body)
          cropper(ref="cropper" class=['profile-avatar__cropper', 'mt-4'] :class="{'is-loading': usersLoading}" :src="cropperImg" :stencil-props="{aspectRatio: 1/1}" :stencil-component="$options.components.CircleStencil")
          .row.align-items-center.justify-content-center.mt-4.g-4
            .col-auto
              button(type="button" class=['button button--blue'] @click="onSubmit" :disabled="usersLoading")
                span.button__content Сохранить
            .col-auto
              button(type="button" class=['button button--border-blue'] @click="closeCropperModal")
                span.button__content Отмена
</template>

<script>
import {mapState} from 'vuex';

import {eventBus} from '@/main';
import {urlToFile} from '@/helpers/utils';
import {Cropper, CircleStencil} from 'vue-advanced-cropper';

import AppModal from '@/components/common/Modal';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppAvatar',
  components: {

    AppModal,
    Cropper,
    CircleStencil,
  },
  mixins: [modalsMixin],
  props: {
    src: {
      type: String,
    },
    localUrl: {
      type: String,
    },
    type: {
      type: String,
    },
    edit: {
      type: Boolean,
    },
    viewPhoto: {
      type: Boolean,
    },
    classes: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      cropperImg: '',
      uploadFile: '',
      isModalVisible: {
        cropper: false,
      },
      isError: false,
      slug: 'changeAvatar',
    };
  },
  computed: {
    ...mapState({
      usersLoading: (state) => state.users.isLoading,
    }),
    avatarUrl() {
      return this.localUrl;
    },
  },
  methods: {
    // Изменение аватара
    changeAvatar() {
      this.$emit('change');
    },

    // Открытие галереи
    openGallery(itemType, itemAvatarUrl, itemId) {
      const data = {
        type: 'gallery',
        preview: null,
        edit: itemType === 'patient' || itemType === 'doctor' ? 'changeAvatar' : false,
        delete: itemType === 'patient' || itemType === 'doctor' ? 'deleteAvatar' : false,
        sources: [
          {
            src: itemAvatarUrl,
          },
        ],
        currentAvatarSrc: itemAvatarUrl,
        patient_code: itemId,
        userType: itemType,
      };
      this.$emit('openGallery', data);
      eventBus.$emit('openGallery', data);
    },

    // Создание объекта FormData из base64-строки
    createFormData(base64File) {
      const formData = new FormData();
      return urlToFile(base64File, this.uploadFile.name, this.uploadFile.type).then((file) => {
        formData.append('avatar', file, this.uploadFile.name);
        console.log(formData)
        return formData;
      });
    },

    // Обработка выбора файла для загрузки
    onFileSelected({target}) {
      const reader = new FileReader();
      this.uploadFile = target.files[0];
      reader.readAsDataURL(this.uploadFile);
      reader.onload = () => {
        this.cropperImg = reader.result;
        this.openModal('cropper');
      };
    },

    // Обработка сохранения результатов кроппера
    onSubmit() {
      try {
        const {canvas} = this.$refs.cropper.getResult();
        const croppedImg = canvas.toDataURL();
        urlToFile(croppedImg, this.uploadFile.name, this.uploadFile.type).then((file) => {
          const formData = new FormData();
          console.log(file);
          console.log(this.uploadFile.name);
          formData.append('avatar', file, this.uploadFile.name);
          console.log(formData)
          this.$emit('submit', {slug: this.slug, form: formData});
        });

        // console.log(canvas.toDataURL())
        // const formData = await this.createFormData(canvas.toDataURL());
        // console.log(formData)
      } catch (error) {
        console.error('Error processing crop result:', error);
      }
    },

    // Закрытие модального окна кроппера
    closeCropperModal() {
      this.closeModal('cropper');
      this.resetForm();
    },

    // Сброс формы
    resetForm() {
      this.$refs.fileInput.value = '';
    },
  },
};
</script>

<style lang="scss">
.profile-avatar {
  position: relative;

  &__img {
    overflow: hidden;
    width: $spacer * 3;
    height: $spacer * 3;
    border-radius: 50%;
    background-color: get($colors, blue-extra-light);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    svg {
      fill: get($colors, blue-light);
      width: 100%;
      height: 100%;
    }
  }
  &__edit-button {
    @include transition();
    position: absolute;
    z-index: get($index, default);
    left: 0;
    bottom: 0;
    width: $spacer * 3.4;
    height: $spacer * 3.4;
    border-radius: 50%;
    background-color: get($colors, white);
    color: get($colors, blue-dark);
    box-shadow: get($shadows, default);
    transform: translateY(30%);

    svg {
      width: $spacer * 1.4;
      height: $spacer * 1.4;
    }
    @include interaction('mouse') {
      &:hover {
        background-color: get($colors, blue-dark);
        color: get($colors, white);
      }
    }
  }
  &__cropper {
    @include transition(opacity);
    width: 100%;
    height: $spacer * 30;
    border-radius: $border-radius-sm;
    overflow: hidden;

    &.is-loading {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  &.square {
    .profile-avatar {
      &__img {
        border-radius: 0;
      }
    }
  }
  &.extra-large {
    .profile-avatar {
      &__img {
        width: $spacer * 8;
        height: $spacer * 8;

        @include mediaToDown($md) {
          width: $spacer * 6;
          height: $spacer * 6;
        }
      }
    }
  }
  &.large {
    .profile-avatar {
      &__img {
        width: $spacer * 4;
        height: $spacer * 4;
      }
    }
  }
  &.middle {
    .profile-avatar {
      &__img {
        width: $spacer * 5.4;
        height: $spacer * 5.4;
      }
    }
  }
}
</style>
