
<tr class="profile-table-row">
  <slot>
    <td
      v-for="column in columns"
      :key="column.key"
      class="profile-table-cell"
    >
      <slot name="cell" v-bind="{ column, row }">
        <span>{{ row[column.key] }}</span>
      </slot>
    </td>
    <td>
      <div class="action-buttons">
        <slot name="actionTools" v-bind="{ row }"></slot>
        <button
          v-if="canEdit"
          type="button"
          @click.prevent="editModalHandler(row)"
          class="action-buttons__button--edit"
        >
          <so-icon icon="edit"></so-icon>
        </button>
        <button
          v-if="canRemove"
          type="button"
          @click.prevent="deleteModalHandler(row)"
          class="action-buttons__button--delete"
        >
          <so-icon icon="trash"></so-icon>
        </button>
      </div>
    </td>
  </slot>
</tr>
