<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Удалить версию прошивки?

      app-validation-errors(
        v-if="validationErrors"
        :classes="'mb-3'"
        :validation-errors="validationErrors"
      )
      .row.flex-column.align-items-center.g-4
        .col-14.col-md-8
          .row.align-items-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteDevice"
                :disabled="isLoading"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
  </template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import filesApi from '@/api/files';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';

export default {
  name: 'AppDeleteDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,

  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    deleteFirmvareId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      validationErrors: '',
      id: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      error: (state) => state.devices.error,
    }),
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },
    getFile(hash) {
      filesApi.getFile(hash);
    },
    async deleteDevice() {
      try {

        await this.$store.dispatch('actionDeleteFirmware', this.deleteFirmvareId);
        this.$emit('firmwareEdited');
        this.$toast.success('Версия прошивки успешно удалена');
        this.closeModal();
      } catch (error) {
        this.$toast.error('Произошла ошибка при удалении версии прошивки');
        console.error('Ошибка при удалении прошивки:', error);
        this.validationErrors = 'Произошла ошибка во время отправки данных.';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__field {
    color: get($colors, blue-dark);
  }
}
</style>
