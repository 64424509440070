
<so-modal
ok='Пауза'
:modelValue='modelValue'
@on-close='() => onClose()'
@on-ok='() => onOk()'
title-size='large'
:can-close='false'
>
  <template v-slot:title>
    <span class='text-color--orange'>НЕ УДАЛОСЬ ПОДПИСАТЬ ДАННЫЕ</span>
  </template>
  <template>
    <p class='text-center py-4'>Убедитесь в работоспособности сертификата</p>
  </template>
</so-modal>
