
.profile-doctors
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7
        .d-flex.align-items-center
          app-title(:type="'large'") Просмотр медработников

          .d-md-none.ms-auto
            button(
              type="button"
              @click="openModal('createDoctor')"
              class=['button button--icon button--border-blue']
            )
              span.flex-shrink-0
                so-icon(icon="plus-thin")

      .col-14.col-md-7.d-none.d-md-block
        .d-flex.justify-content-end
          button(
            type="button"
            @click="openModal('createDoctor')"
            class=['button button--border-blue']
          )
            span.button__content
              span.d-flex.flex-shrink-0.me-3
                so-icon(icon="plus-thin")
              span Добавить медработника


  .profile-doctors__content.position-relative.mt-25
      transition(name="translucent")
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      div(v-if="doctors")
        template(v-if="doctors && doctors.length")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.g-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    type="button"
                    @click="sortingDoctors(column.key, column.inverse)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span.text-overflow(:title="column.caption") {{ column.caption }}
                      span.d-flex.ms-3
                        so-icon(icon="down-arrow")

            ul.profile-table__body
              li.profile-table__row(v-for="doctor in doctors" :key="doctor.id")
                router-link(:to="{name: 'doctor-detail', params: {id: doctor.id}}" class=['d-block'])
                  app-admin-doctor-card(
                    :data="doctor"
                    :columns="columns"
                  )

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        template(v-else-if="!isLoading && !doctors.length")
          .text-color--blue.text-size--small Медработники отсутствуют


  // create doctor modal
  app-create-doctor-modal(
    :is-visible="isModalVisible.createDoctor"
    @close="closeModal('createDoctor')"
    @success="getDoctors(pagination.limit, 0)"
  )

