<template lang="pug">
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-doctors
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(:type="'large'") Просмотр медорганизаций

            .d-md-none.ms-auto
              button(
                type="button"
                @click="openModal('createMedOrganization')"
                class=['button button--icon button--border-blue']
              )
                span.flex-shrink-0
                  so-icon(icon="plus-thin")

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              @click="openModal('createMedOrganization')"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  so-icon(icon="plus-thin")
                span Добавить медорганизацию

    .profile-doctors__content.position-relative.mt-25
        transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="medOrganizations")
          template(v-if="medOrganizations && medOrganizations.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingMedOrganizations(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          so-icon(icon="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="medOrganization in medOrganizations" :key="medOrganization.id")
                  router-link(:to="{name: 'med-organization-detail', params: {id: medOrganization.id}}" class=['d-block'])
                    app-admin-med-organization-card(
                      :data="medOrganization"
                      :columns="columns"
                    )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else-if="!isLoading && !medOrganizations.length")
            .text-color--blue.text-size--small Медработники отсутствуют


  // create medOrganization modal
  app-create-med-organization-modal(
    :is-visible="isModalVisible.createMedOrganization"
    @close="closeModal('createMedOrganization')"
    @success="getMedOrganizations(pagination.limit, 0)"
  )

  // filter
  app-med-organization-filter(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilter"
  )

  //- MedOrganizationsTable
</template>

<script>
import {mapState} from 'vuex';
import medOrganizationsApi from '@/api/medOrganizations.ts';

import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppPagination from '@/components/common/Pagination';

import AppCreateMedOrganizationModal from '@/components/profile/admin/doctors/CreateMedOrganizationModal';
import AppMedOrganizationFilter from '@/components/profile/admin/doctors/AppMedOrganizationFilter';
import AppAdminMedOrganizationCard from '@/components/profile/admin/doctors/AdminMedOrganizationCard';

import MedOrganizationsTable from '@/templates/tables/MedOrganizationsTable.vue';

import AppTabNav from '@/components/common/TabNav.vue';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppAdminMedOrganizations',
  components: {
    AppTitle,

    AppLoading,
    AppPagination,
    AppErrorMessage,
    AppValidationErrors,
    AppModal,
    AppAdminMedOrganizationCard,
    AppCreateMedOrganizationModal,
    AppMedOrganizationFilter,
    AppTabNav,
    MedOrganizationsTable
  },
  mixins: [modalsMixin],
  data() {
    return {
      isFilterOpen: false,
      isLoading: false,
      medOrganizations: {},
      sortKey: 'creation_date',
      sortInverse: false,
      v: variables,
      isModalVisible: {
        createMedOrganization: false,
      },
      pagination: {
        limit: 0,
        total: 0,
      },
      columns: [
        {
          class: 'col-14 col-md-2',
          caption: 'Наименование',
          key: 'name',
          inverse: true
        },
        {
          class: 'col-14 col-md-3',
          caption: 'ОГРН',
          key: 'ogrn',
          inverse: true
        },
        {
          class: 'col-14 col-md-3',
          caption: 'Лицензия',
          key: 'medical_license',
          inverse: true
        },
        {
          class: 'col-auto col-md-2',
          caption: 'С',
          key: 'medical_license_start',
          inverse: true
        },
        {
          class: 'col-auto col-md-2',
          caption: 'По',
          key: 'medical_license_end',
          inverse: true
        }
      ],
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.medOrganizations.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
  },
  watch: {
    currentPage() {
      this.getMedOrganizations();
    },
  },
  async created() {
    this.getMedOrganizations();
  },
  methods: {
    async getMedOrganizations(filters) {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.sortKey}`;

      this.isLoading = true;
      let res = await medOrganizationsApi.getMedOrganizations({
        page: this.currentPage - 1,
        order_by,
        ...filters
      });
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.medOrganizations = res.data.items;
      this.isLoading = false;
    },
    sortingMedOrganizations(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getMedOrganizations();
      }
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
    async submitFilter(data) {
      this.filterString = data;
      this.getMedOrganizations(data);
    },

    //временно не актуально

    // changeActiveTab(tab) {
    //   switch (tab) {
    //     case 'medOrganizations':
    //       this.$router.push({name: 'medOrganizations'});
    //       break;
    //     case 'auto-medOrganization':
    //       this.$router.push({name: 'auto-medOrganization'});
    //       break;
    //   }
    // },
  },
};
</script>
