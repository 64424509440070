
simplebar.police
  .container-fluid
    .row.flex-column.align-items-center
      .col-14.col-md-9.col-lg-12.col-xl-10.col-xxl-7
        router-link(:to="{name: 'home'}")
          app-logo
        .mt-50.mt-md-100
          .police-exam-detail.police-exam-detail--short
            .police-exam-detail__content.position-relative.check
              .check__header Проверка осмотра
              .check__desc Введите идентификатор осмотра. Идентификатор указан на талоне осмотра и отображается в мобильном приложении пользователя
              form.check__form
              input.check__input(v-model="number" placeholder="Идентификатор осмотра")
              button.check__button(@click="sendNumber()" :disabled="number=='' ? '' : disabled") Поиск
