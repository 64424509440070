<template>
  <so-tabs v-model="activeIndex" :needTitle="true" @changeTab="changeActiveTab" :selectedTab="activeIndex">
    <so-tab label="Устройства" name="admin-devices">
      <app-admin-devices />
    </so-tab>
    <so-tab label="Контроль целостности ПО" name="devices-control">
      <app-admin-devices-control />
    </so-tab>
    <so-tab label="Поверка СИ" name="devices-check">
      <app-admin-devices-check />
    </so-tab>
    <so-tab label="Группы печати талонов" name="devices-printing-group">
      <app-admin-devices-printing-group />
    </so-tab>
  </so-tabs>
</template>


<script>
import SoTab from '@/components/so/tabs/SoTab';
import SoTabs from '@/components/so/tabs/SoTabs';
import AppAdminDevices from '@/components/profile/admin/devices/AdminDevices';
import AppAdminDevicesCheck from '@/components/profile/admin/devices/AdminDevicesCheck';
import AppAdminDevicesControl from '@/components/profile/admin/devices/AdminDevicesControl';
import AppAdminDevicesPrintingGroup from '@/components/profile/admin/devices/AdminDevicesPrintingGroup';

export default {
  name: 'so-admin-devices',
  components: {
    SoTab,
    SoTabs,
    AppAdminDevices,
    AppAdminDevicesCheck,
    AppAdminDevicesControl,
    AppAdminDevicesPrintingGroup
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    changeActiveTab(index) {
      this.activeIndex = index;
    }
  },
};
</script>
