<template>
<div class="profile-doctor-exam__container">
  <div class="profile-doctor-exam" v-show="!examInWork">
    <div class="profile-doctor-exam__header">

      <div class="col">
        <button class="mb-2 mt-5 button button--accent button--medium" v-show="!shiftStarted || shiftPaused" type="button" @click="startShift" style="width: 350px;">
          <span class="button__content">
            <span class="text-uppercase">{{ !shiftPaused ? 'Начать рабочую смену' : 'Продолжить рабочую смену' }}</span>
          </span>
        </button>
        <app-validation-errors v-if="validationErrors.length" :validation-errors="validationErrors"></app-validation-errors>
        <div class="col-14" v-if="shiftStarted && exam.body === '' && !shiftPaused">
          <app-title :type="'large'"></app-title>
          <div class="d-flex system-setup-container">
            <div class="button button--medium button--white button--shadow noHover col-10">
              <div class="d-flex align-items-center text-left">Осмотров в очереди нет. Ожидайте назначения осмотра.</div>
            </div>
          </div>
        </div>
        <div class="col-14" v-if="shiftStarted && exam.body !== '' && !shiftPaused">
          <app-title :type="'large'"></app-title>
          <div class="d-flex system-setup-container">
            <div class="button button--medium button--white button--shadow col-10">
              <div class="d-flex justify-content-between align-items-center text-center pl-5">Вам назначен осмотр
                <button class="button button--accent button--medium" type="button" @click="getExamToWork" style="width: 350px;">
                  <span class="button__content">
                    <span>Взять в работу</span>
                  </span>
                </button>
                <div class="pl-5">осталось {{ remainingTime }} секунд</div>
              </div>
            </div>
          </div>
        </div>
        <div class="memo pt-5">
          <ul class="memo-list">
            <li class="memo-link"><strong>Памятка</strong></li>
            <li class="memo-link">Для начала рабочего дня, нажмите кнопку «Начать рабочую смену»;</li>
            <li class="memo-link">Для перерыва в назначении осмотров, нажмите кнопку «Пауза»;</li>
            <li class="memo-link">Для завершения рабочей смены, нажмите  «Окончить смену», но прежде, вы должны быть на паузе;</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="profile-doctor-exam__footer">
      <div class="row align-items-center">
        <div class="col-14 mb-5">
          <app-title :type="'large'">Информация о смене</app-title>
          <div class="d-flex system-setup-container">
            <ul class="list-group d-flex shift-buttons flex-wrap">
              <li class="profile-sidebar__menu-item" v-for="(card, index) in shiftInformationCards" :key="index">
                <button class="button button--medium button--white button--shadow w-100 noHover">
                  <div class="d-flex align-items-left">{{ card.label }}
                    <span class="ms-3" :class="card.class" v-if="card.value !== undefined && card.value !== null"><strong>{{ card.value }}</strong></span>
                    <span class="ms-3" v-else>&#8212;</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-14 mb-5">
          <app-title :type="'large'">Информация об осмотрах</app-title>
          <div class="d-flex system-setup-container">
            <ul class="list-group d-flex shift-buttons flex-wrap">
              <li class="profile-sidebar__menu-item" v-for="(card, index) in ExamInformationCards" :key="index">
                <button class="button button--medium button--white button--shadow w-100 noHover">
                  <div class="d-flex align-items-left">{{ card.label }}
                    <span class="ms-3" v-if="card.value !== undefined && card.value !== null"><strong>{{ card.value }}</strong></span>
                    <span class="ms-3" v-else>&#8212;</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-14" v-if="shiftStarted">
          <app-title :type="'large'">Доступные действия</app-title>
          <div class="d-flex system-setup-container">
            <ul class="list-group d-flex action-buttons flex-wrap">
              <li class="profile-sidebar__menu-item" v-for="(button, index) in actionButtons" :key="index">
                <button class="button button--medium button--white button--shadow w-100 button--border-blue" @click="handleButtonClick(index)" :disabled="button.disabled">
                  <div class="d-flex align-items-center justify-content-center">
                    <so-icon :name="button.iconName" class="me-3"></so-icon>
                    <span>{{ button.label }}</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="examInWork">
    <doctor-exams-detail :showed="examInWork" :uuid="exam.body" :examProcessed="examProcessed" :examReturned="examReturned" :sendExamAndStop="sendExamAndStop"></doctor-exams-detail>
  </div>
</div>
</template>

<script>
import doctorsApi from '@/api/doctors';
import {mapGetters, mapMutations} from 'vuex';
import { gettersTypes as authGettersTypes } from '@/store/modules/auth';
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import DoctorExamsDetail from '@/components/profile/doctor/exams/DoctorExamsDetail';
import Stomp from 'webstomp-client';
import AppValidationErrors from '@/components/common/ValidationErrors';


export default {
  name: 'SystemSetupDashboard',
  components: {
    AppTitle,
    AppMultipleSelect,
    DoctorExamsDetail,
    AppValidationErrors,
  },
  data() {
    return {
      getTaskCountTimer: null,
      shiftStarted: false,
      shiftPaused: false,
      exam: {body: ''},
      remainingTime: 25,
      timer: null,
      examInWork: false,
      stompClient: null,
      subscription: null,
      //shift info
      tasksCount: null,
      passedExamsInRow: null,
      shiftDuration: null,
      breaksPerShift: null,
      reviewedExams: null,
      missedExams: null,
      validationErrors: '',
      actionButtons: [
        {
          label: 'Встать на паузу',
          action: this.pauseShift,
          isActive: false,
          iconName: 'pause',
          disabled: false,
        },
        {
          label: 'Окончить смену',
          action: this.stopShift,
          iconName: 'remove-circle',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
      pause_exam: 'PAUSE_EXAM',
    }),
    //информация в карточках
    shiftInformationCards() {
      return [
        {
          label: 'Текущий статус:',
          value: this.dynamicValues.shiftStatusText,
          class: this.shiftStatusTextClass,
        },
        {label: 'Продолжительность смены:', value: this.dynamicValues.shiftDuration},
        {label: 'Паузы за смену:', value: this.dynamicValues.breaksPerShift},
      ];
    },
    ExamInformationCards() {
      return [
        {label: 'Осмотров в очереди:', value: this.dynamicValues.tasksCount},
        {label: 'Рассмотрено за смену:', value: this.dynamicValues.shiftDuration},
        {label: 'Пропущено за смену:', value: this.dynamicValues.missedExams},
      ];
    },
    dynamicValues() {
      return {
        shiftStatusText: this.shiftStarted && !this.shiftPaused ? 'ОНЛАЙН' : 'ОФФЛАЙН',
        shiftDuration: this.shiftDuration,
        breaksPerShift: this.breaksPerShift,
        tasksCount: this.tasksCount,
        reviewedExams: this.reviewedExams,
        missedExams: this.missedExams,
      };
    },
    shiftStatusTextClass() {
      return this.shiftStarted && !this.shiftPaused ? 'text-color--success' : 'text-color--orange';
    },
  },
  watch: {
    exam(oldVal, newVal) {
      if (oldVal.body === '' && newVal !== '') {
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },
    passedExamsInRow(value) {
      if (value === 25) {
        this.stopShift();
      }
    },
  },
  created() {
    this.getTaskCountTimer = setInterval(this.fetchTasksCount, 5000);
  },
  beforeDestroy() {
    clearInterval(this.getTaskCountTimer);
    clearInterval(this.esiaModalTimer);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.stompClient) {
      this.stompClient.disconnect(() => {
        console.warn('WSS has been disconnected');
      });
    }
    this.stopShift();
    this.validationErrors = '';
    // вернули к базовым настройкам
    this.setExamInWork(false);
    this.setExamBody('');
    this.setTasksCount(null);
  },
  methods: {
    ...mapMutations({
      setExamInWork: 'SET_EXAM_IN_WORK',
      setTasksCount: 'SET_TASKS_COUNT',
      setExamBody: "SET_EXAM_BODY",
    }),
    //получить количество осмотров в очереди
    async fetchTasksCount() {
      let tasksCount = await doctorsApi.getExamsCount();
      this.tasksCount = tasksCount.data.messages;
      this.setTasksCount(this.tasksCount)

    },
    //начало рабочей смены
    async startShift() {
      this.shiftPaused = false;
      this.actionButtons[0].disabled = false;
      this.actionButtons[0].label = 'Встать на паузу';
      this.actionButtons[1].disabled = true;
      this.actionButtons[0].isActive = true;
      this.validationErrors = '';

      try {
        const resultToken = await doctorsApi.getToken();
        const token = resultToken.data.access_token;
        await doctorsApi.setDoctorStatus({ status: 'ONLINE', start_datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS') });
        this.shiftStarted = true;

        this.stompClient = Stomp.client(
          `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://` + (import.meta?.env?.VITE_API_HOST || window.location.hostname) + '/ws'
        );

        this.stompClient.connect(
          { passcode: token, 'client-id': 'my-client-id' },
          () => {
            this.connected = true;
            this.subscription = this.stompClient.subscribe(
              '/queue/incoming-exams',
              (tick) => {
                this.play();
                this.exam = tick;
                if (this.exam) {
                  this.setExamBody(this.exam.body);
                  if (this.exam.body === '') {
                    this.exam.ack();
                  }
                }
                this.shiftStarted = true;
                this.timer = setInterval(() => {
                  if (this.remainingTime <= 0) {
                    clearInterval(this.timer);
                    if (this.exam !== null) {
                      this.exam.nack();
                    }
                    this.remainingTime = 25;
                    this.exam = {body: ''};
                    this.passedExamsInRow += 1;
                    if (!this.examInWork) {
                      this.pauseShift();
                    }
                    return;
                  }
                  this.remainingTime = this.remainingTime - 1;
                  if (this.remainingTime % 3 === 0 && this.remainingTime !== 24) {
                    this.play();
                  }
                }, 1000);
              },
              {'x-max-priority': 5, ack: 'client', 'prefetch-count': '1', id: this.currentUser.id}
            );
          },
          (error) => {
            console.error('HEY, QA! WSS CONNECT ERROR:', error);
            this.shiftStarted = false;
            
            if (error.response && error.response.data.detail === 'consumer already connected') {
              this.validationErrors = 'consumer_already_connected';
            } else if (error?.reason === 'STOMP died') {
              this.validationErrors = 'stomp_died'
            } else {
              this.validationErrors = 'websocket_connection_error';
            }
            this.connected = false;
          }
        );
      } catch (error) {
        console.error('HEY, QA! WSS START SHIFT ERROR:', error);
        this.shiftStarted = false;
        if (error.response && error.response.data.detail === 'consumer already connected') {
          this.validationErrors = 'consumer_already_connected';
        } else {
          this.validationErrors = 'websocket_connection_error';
        }
        this.connected = false;
      }
    },
    //работа с осмотром
    getExamToWork() {
      clearInterval(this.timer);
      this.remainingTime = 25;
      this.examInWork = true;
      this.setExamInWork(this.examInWork)
    },
    sendExamAndStop() {
      this.examProcessed();
      this.stopShift();
    },
    examProcessed() {
      this.exam.ack();
      this.exam = {body: ''};
      this.examInWork = false;
      this.setExamInWork(this.examInWork)
    },
    examReturned() {
      this.exam.nack();
      this.exam = {body: ''};
      clearInterval(this.timer);
      this.remainingTime = 25;
      this.examInWork = false;
      this.pauseShift();
    },
    //поставить смену на паузу
    pauseShift() {
      this.shiftPaused = true;
      this.actionButtons[0].disabled = true;
      this.actionButtons[0].label = 'Вы на паузе';
      this.actionButtons[1].disabled = false;

      this.actionButtons[0].isActive = true;
      doctorsApi.setDoctorStatus({
        status: 'PAUSE',
        start_datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
      this.subscription.unsubscribe();
      this.stompClient.disconnect(() => {
        console.log('WSS has been disconnected');
      });

      clearInterval(this.timer);
      this.exam = { body: '' };
      // вернули к базовым настройкам
      this.setExamInWork(false);
      this.setExamBody('');
      this.setTasksCount(null);
    },
    //окончание рабочей смены
    stopShift() {
      doctorsApi.setDoctorStatus({
        status: 'OFFLINE',
        start_datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
      this.subscription.unsubscribe();
      this.stompClient.disconnect(() => {
        console.log('WSS has been disconnected');
      });
      this.actionButtons[1].isActive = false;
      this.actionButtons[0] = {
        label: 'Встать на паузу',
        action: this.pauseShift,
        isActive: false,
        iconName: 'pause',
        disabled: false,
      };
      this.actionButtons[0].label = !this.shiftPaused ? 'Встать на паузу' : 'Вы на паузе';
      this.shiftStarted = false;
      this.shiftPaused = false;
      clearInterval(this.timer);
      this.exam = { body: '' };
      // вернули к базовым настройкам
      this.setExamInWork(false);
      this.setExamBody('');
      this.setTasksCount(null);
    },
    //кнопки
    handleButtonClick(index) {
      this.actionButtons.forEach((button) => {
        button.isActive = false;
      });
      this.actionButtons[index].isActive = true;
      this.actionButtons[index].action();
    },
    //общие методы
    play() {
      let audio = new Audio('/zvuk-kapli.mp3');
      audio.play();
    },
    },
};
</script>

<style scoped>
.profile-doctor-exam__header {
  margin-bottom: 100px;
}
.memo-link {
  margin-bottom: 5px;
}
.memo-link:first-child {
  margin-bottom: 10px;
}
.memo-link:last-child {
  margin-bottom: 0;
}
.action-buttons {
  justify-content: flex-start;
}

.action-buttons > li {
  width: 350px;
  margin-right: 20px;
}

.shift-buttons > li {
  width: 350px;
  margin-right: 20px;
}
.shift-buttons > li:last-child {
  margin-right: 0;
}

.noHover {
  pointer-events: none;
}

.system-setup-container {
  justify-content: space-between;
  margin-top: 20px;
}
.system-setup-container:last-child {
  margin-bottom: 0;
}
</style>
