
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-doctors
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(:type="'large'") Просмотр медорганизаций

            .d-md-none.ms-auto
              button(
                type="button"
                @click="openModal('createMedOrganization')"
                class=['button button--icon button--border-blue']
              )
                span.flex-shrink-0
                  so-icon(icon="plus-thin")

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              @click="openModal('createMedOrganization')"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  so-icon(icon="plus-thin")
                span Добавить медорганизацию

    .profile-doctors__content.position-relative.mt-25
        transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="medOrganizations")
          template(v-if="medOrganizations && medOrganizations.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingMedOrganizations(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          so-icon(icon="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="medOrganization in medOrganizations" :key="medOrganization.id")
                  router-link(:to="{name: 'med-organization-detail', params: {id: medOrganization.id}}" class=['d-block'])
                    app-admin-med-organization-card(
                      :data="medOrganization"
                      :columns="columns"
                    )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else-if="!isLoading && !medOrganizations.length")
            .text-color--blue.text-size--small Медработники отсутствуют


  // create medOrganization modal
  app-create-med-organization-modal(
    :is-visible="isModalVisible.createMedOrganization"
    @close="closeModal('createMedOrganization')"
    @success="getMedOrganizations(pagination.limit, 0)"
  )

  // filter
  app-med-organization-filter(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilter"
  )

  //- MedOrganizationsTable
