
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Добавление устройств
        p.text-size--small.text-color--blue.mt-3 Для успешного добавления укажите серийный номер и скан документа, подтверждающего покупку. Если все устройства указаны в одном документе, приложите его для каждого устройства

      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10(v-if="validationErrors.message")
            app-validation-errors(:validation-errors="validationErrors.message")

            template(v-if="validationErrors.info")
              ul(
                v-for="(request, i) in validationErrors.info"
                :key="request.serial"
                class=['form__info', 'text-size--small', 'mt-2']
              )
                li
                  span.text-family--italic.me-3
                    template(v-if="request.serial") {{ `#${request.serial}` }}
                    template(v-else) {{ `#${request}` }}

                  span(v-if="request.comment") {{ request.comment }}


          .col-14.col-md-10(v-for="(device, i) in form.devices" :key="i" :set="$response = v$.form.devices.$each.$response")
            .row.g-4
              .col
               .outer-form

                label.form__label.form__label--float
                  input(
                    :class="['form__field form__field--no-border',{ 'is-error': $response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty }]"
                    type="text"
                    name="id"
                    @keydown="v$.$touch()"
                    v-model="device.value"
                    autocomplete="off"
                    placeholder="&nbsp;"
                  )
                  span.fl Серийный номер МИ
                ul.form__error(v-if="$response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty")
                 li(v-for="error of $response.$errors[i].value" :key="error.$uid")
                   template(v-if="error.$params.type === 'required'") {{ v.errors.required }}
                   template(v-if="error.$params.type === 'alphaNum'") {{ v.errors.alphaNum }}
                   template(v-if="error.$params.type === 'isUnique'") Серийные номера не должны совпадать
                .row.gx-4
                 .col-7
                    .text-size--small.mb-2.mt-4 Дата поверки
                    date-picker(
                      prefix-class="app"
                      placeholder="Дата поверки"
                      format="DD.MM.YYYY"
                      lang="ru"
                      :editable="true"
                      v-model="device.starts"
                      :disabled-date="disableAfterToday"
                    )
                 .col-7
                   .text-size--small.mb-2.mt-4  Окончание срока поверки
                   date-picker(
                      prefix-class="app"
                      placeholder="Окончание срока поверки"
                      format="DD.MM.YYYY"
                      lang="ru"
                      :editable="true"
                      v-model="device.ends"
                      :disabled-date="disableExpiration"
                    )
                 .col-14
                  .text-size--small.mb-2.mt-4  Номер свидетельства
                  input(
                    :class="['form__field', { 'is-error': $response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty}]"
                    type="text"
                    name="id"
                    @keydown="v$.$touch()"
                    v-model="device.device_attestations"
                    placeholder="Номер свидетельства"
                    autocomplete="off"
                  )
              .col-auto
                button(
                  v-if="i === form.devices.length - 1"
                  type="button"
                  :disabled="form.devices.length >= limit"
                  @click="changeNumOfDevices('add')"
                  class=['form__add-button']
                )
                  so-icon(icon="plus-thin")

                button(
                  v-else
                  type="button"
                  @click="changeNumOfDevices('delete', i)"
                  class=['form__delete-button']
                )
                  so-icon(icon="minus")



            ul.form__error(v-if="$response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty")
              li(v-for="error of $response.$errors[i].device_attestations" :key="error.$uid")
                template(v-if="error.$params.type === 'required'") {{ v.errors.required }}
                template(v-if="error.$params.type === 'alphaNum'") {{ v.errors.alphaNum }}
                template(v-if="error.$params.type === 'isUnique'") Серийные номера не должны совпадать

          //- .col-14.col-md-10
          //-   .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
          //-     input(
          //-       class="d-none"
          //-       type="file"
          //-       name="document"
          //-       @change="onFileSelected"
          //-       @click="v$.$touch()"
          //-       ref="fileInput"
          //-       accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
          //-     )

          //-     div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
          //-       span.flex-shrink-0.me-3
          //-         so-icon(icon="document")

          //-       ul.flex-grow-1.overflow-hidden
          //-         li.form__file-name.text-color--black {{ form.file.name }}
          //-         li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

          //-       button(
          //-         type="button"
          //-         @click="clearFileInput"
          //-         class=['form__file-delete', 'flex-shrink-0 ms-3']
          //-       )
          //-         so-icon(icon="trash")

          //-     .text-color--blue.text-size--small(v-else) Приложите скан документа

          //-     button(
          //-       class=['form__file-button', 'd-flex justify-content-end align-items-center maw365']
          //-       @click="$refs.fileInput.click()"
          //-     )
          //-       so-icon(icon="clip")

          //-   ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
          //-     li {{ v.errors.required }}

          .col-14.col-md-10.mt-5
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button','maw365']
            )
              span.button__content Отправить

