
.multiple-select(v-click-outside="close")
  .multiple-select__search(:class="[{'is-open': isOpen}, {'is-disabled': !searchDataLocal.length}]")
    label.multiple-select__search-label.d-flex.align-items-center
      template(v-if="searchDataLocal.length")
        button(
          type="button"
          @click="close"
          class=['d-flex']
        )
          so-icon(icon="plus-thin")
        input(
          v-if="multiple===false && initialValues.length > 0"
          type="text"
          :title="placeholder"
          class=['multiple-select__search-field', 'mx-3', 'flex-grow-1']
          :placeholder="placeholder"
          v-model="selected[0].text"
          @input="search"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
        )
        input(
          v-else
          type="text"
          :title="placeholder"
          class=['multiple-select__search-field', 'mx-3', 'flex-grow-1']
          :placeholder="placeholder"
          v-model="searchInput"
          @input="search"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
        )
        span.d-flex
          so-icon(icon="search")

      .text-size--small(v-else) У выбранных клиентов нет пользователей

    transition(name="fade")
      .multiple-select__search-dropdown(v-if="isOpen")
        simplebar(:click-on-track="false" class="multiple-select__search-dropdown__inner")
          ul.multiple-select__search-dropdown-list(v-if="filtered.length")
            li.multiple-select__search-dropdown-list-el.d-flex.align-items-center(
              :class="{'is-selected': option.selected}"
              v-for="(option, index) in filtered" :key="index"
              @click="toggleSelect(option)"
            )
              span.multiple-select__search-dropdown-list-el-value.flex-grow-1 {{ option.text }}

              span.d-flex.flex-shrink-0.ms-3
                so-icon(v-if="option.selected" icon="remove-circle")
                so-icon(v-else icon="plus-circle")

          .text-family--italic.text-size--small.text-color--blue(v-else) Ничего не найдено

        button(
          class=['button button--small button--accent', 'mt-4 w-100']
          @click="close"
          type="button"
          v-if='multiple'
        )
          span.button__content Готово


  // selected list
  ul(
    v-if="selected.length && multiple===true"
    class=['multiple-select__selected-list', 'mt-3']
    :class="`multiple-select__selected-list--${resultsView}`"
  )
    template(v-if="resultsView === 'detail'")
      li(
        class=['multiple-select__selected-list-el', 'd-flex align-items-center']
        v-for="(option, index) in selected" :key="index"
      )
        span.text-size--small {{ option.text }}
        button(
          type="button"
          class=['ms-auto', 'text-color--danger']
          @click="toggleSelect(option)"
        )
          so-icon(icon="close")

    template(v-else-if="resultsView === 'simple'")
      li(
        class=['multiple-select__selected-list-el', 'd-flex align-items-center']
        v-for="(option, index) in selected" :key="index"
        @click="toggleSelect(option)"
        v-if='multiple'
      )
        span.me-2
          so-icon(icon="close")
        span.text-size--small {{ option.text }}
      li(
        class=['multiple-select__selected-list-el', 'd-flex align-items-center']
        v-for="(option, index) in selected" :key="index"
        @click="toggleSelectMultiple(option)"
        v-else
      )
        span.me-2
          so-icon(icon="close")
        span.text-size--small {{ option.text }}

    template(v-else)
      slot(name="body")

