import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import is from 'is_js';

import {actionTypes} from '@/store/modules/auth';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import LoadScript from 'vue-plugin-load-script';

import moment from 'moment';
import 'moment/dist/locale/ru';
moment.locale('ru');

/** DEPRECATED - не нужно импортировать глобально. Проверить где нет импортов и удалить */
import SoIcon from '@/components/so/icon/SoIcon.vue';
import { models } from './store/models';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403 || error.response.status === 401) {
      if (is.truthy(store.state.auth.isLoggedIn)) {
        store.dispatch(actionTypes.logout).then(() => {
          if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'}).catch(() => {});
          }
        });
      }
    }

    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

/** DEPRECATED - не нужно импортировать глобально. Проверить где нет импортов и удалить */
Vue.component('so-icon', SoIcon);

Vue.use(VueToast);
Vue.use(LoadScript);
Vue.prototype.Reference = { ...models };
Vue.prototype.$moment = moment;

// ??? WTF?!
export const eventBus = new Vue();

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
