<template lang="pug">
.inspection-card__header
  .row.gx-3.text-size--middle.justify-content-start
    .col-3
      span <b>№</b>&nbsp;
      span <b>{{ exam.exam_number }}</b>
    .col-3
      span {{exam.type_name}} &nbsp;
    .col-4
      span {{ exam.patient_full_name}}
  .row.gx-3.mt-4.text-size--middle

    .col-3
      span {{ toLocalDateExam(exam.exam_datetime) }} &nbsp;
      span {{ toLocalTimeExam(exam.exam_datetime) }}
    .col-3
      span {{ exam.device_owner_full_name }}
    .col-auto
      span Пол:&nbsp; {{ exam.gender  === 2 ? 'Жен.' : 'Муж.'}} &nbsp;
      span {{ patientAge }}&nbsp;{{ declOfNum(patientAge, ['год', 'года', 'лет']) }}
        .row.mt-3

</template>

<script>
import AppDoctorReasons from '@/components/profile/doctor/exams/AppDoctorReasons';
import {toLocalDateExam, declOfNum, toLocalTimeExam} from '@/helpers/utils';

export default {
  name: 'DoctorExamCardHeader',
  components: {
    AppDoctorReasons,
    toLocalDateExam,
    declOfNum,
    toLocalTimeExam,
  },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
  computed: {
    patientAge() {
      return new Date().getFullYear() - new Date(this.exam.date_birth).getFullYear();
    },
  },
  methods: {
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.inspection-card__header {
  .orange {
    color: orange;
  }
  span {
    color: #5b6483;
  }
  @include font-size(get($text-sizes, middle));
}
</style>
