
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Обработка входящего документа
        .px-4
          form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
            .row.gy-2
              .col.mb-2
                app-select(
                  ref="selectStatus"
                  :type="'form'"
                  :placeholder="'Выберите тип документа'"
                  :options="typeOpts"
                  @select="selectStatus"
              )

              .col-14(v-if="v$.form.firstName")
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                    type="text"
                    v-model="rowData.document_name"
                    placeholder="Введите номер / название документа"
                  )
                ul.form__error
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}

              .col-14
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                    type="text"
                    v-model="rowData.patient_full_name"
                    placeholder="Введите ФИО пациента"
                  )
                ul.form__error
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
              .col-14(v-if="v$.form.firstName")
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                    type="text"
                    v-model="rowData.med_org_name"
                    placeholder="Укажите организацию, выдавшую документ"
                  )
                ul.form__error
                  li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
              .col-14
                label.form__label.text-color--blue Комментарий клиента
                  textarea(
                    class="form__field mt-2"
                    v-model="rowData.client_comment"
                    placeholder="Комментарий клиента "
                  )
              .col-7
                label.form__label.text-color--blue Ответ медработника
                  textarea(
                    class="form__field mt-2"
                    v-model="rowData.doctor_comment"
                    placeholder="Ответ медработника"
                  )
              .col-7
                label.form__label.text-color--blue Диагноз
                  textarea(
                    class="form__field mt-2"
                    v-model="rowData.diagnosis"
                    placeholder="Причина отказа"
                  )

              .col-7
                .text-color--blue.mb-2 Дата выдачи
                date-picker(
                  prefix-class="app"
                  placeholder="Дата выдачи документа"
                  format="DD.MM.YYYY"
                  v-model="rowData.issue_date"
                  :disabled-date="disableAfterToday"
                  value-type="YYYY-MM-DD"
              )
              .col-7
                .d-flex.mb-2
                  .form__caption.w-50.me-4 Срок действия
                  label.form__checkbox.d-flex.w-50
                    input(
                      type="checkbox"
                      name="user-type"
                      value="person"
                      v-model="rowData.expiration_date === null"
                      )
                    i.form__checkbox-icon.me-2
                    span.form__checkbox-caption Бессрочно
                date-picker(
                  prefix-class="app"
                  placeholder="Укажите дату действия документа"
                  format="DD.MM.YYYY"
                  v-model="rowData.expiration_date"
                  value-type="YYYY-MM-DD"
                  :disabled-date="disableExpiration"
                )
              .col-7
                .form__file.d-flex.align-items-center
                  input(
                    class="d-none"
                    type="file"
                    name="document"
                    accept="image/*"
                  )
                  div(v-if="rowData.file_link" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                    span.flex-shrink-0.me-3
                      so-icon(icon="image")
                    ul.flex-grow-1.overflow-hidden
                      li.form__file-name.text-color--black {{ rowData.file_link }}
                  .text-color--blue.text-size--small(v-else) {{ rowData.file_link }}
                  button(
                    class=['form__file-button', 'd-flex justify-content-end align-items-center']
                    @click="download(rowData)"
                  )
                    so-icon(icon="download")

              .col-7
                label.form__checkbox.d-flex.pt-4.mt-2
                  input(
                    type="checkbox"
                    name="user-type"
                    v-model="rowData.acc_risk_groups"
                  )
                  i.form__checkbox-icon.me-2
                  span.form__checkbox-caption Учитывать в группах риска
              .col-7.mt-25(v-if="showData===false")
                button(
                  type="submit"
                  @click="onReject"
                  :disabled="false"
                  class=['button', 'form__button','button', 'button--border-blue']
                )
                  span.button__content Отклонить
              .col-7.mt-25(v-if="showData===false")
                button(
                  type="submit"
                  @click="onSubmit"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Подтвердить

  //окно с выбором действия
  handler-select-modal(
    :is-visible="isSelectVisible"
    :data="rowData"
    @close="closeSelectModal"
    @reject="closeSelectRejected"
    @confirm="closeSelectSubmitted"
  )

