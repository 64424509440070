
<transition name="fade" mode="out-in">
  <app-modal
  v-if="modelValue"
  @close="onClose"
  :withoutCloseBtn='!canClose'
  >
    <template v-slot:body>
      <slot name='header'>
        <div class='text-center mb-2'>
          <app-title :type='titleSize'>
            <slot name='title'></slot>
          </app-title>
        </div>
      </slot>
      <slot></slot>
      <slot name='footer'>
        <div :class='`d-flex ${cancel && ok ? "justify-content-between" : "justify-content-center"}`'>
          <div v-if='cancel' class='d-flex justify-content-end'>
            <so-button
            @on-click="() => onCancel()"
            >
              <span class='button__content d-flex align-items-center'>{{ cancelText }}</span>
            </so-button>
          </div>

          <div v-if='ok' class='d-flex justify-content-end'>
            <so-button
            @on-click="() => onOk()"
            >
              <span class='button__content d-flex align-items-center'>{{ okText }}</span>
            </so-button>
          </div>
        </div>
      </slot>
    </template>
  </app-modal>
</transition>
