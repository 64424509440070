<template lang="pug">
section(
  v-if='data'
  class=['user-card', 'profile-card']
)
  .row.align-items-center.g-4.gy-xl-0
    .col-2.col-md-2
      span(v-if="data.creation_date").text-color--blue.text-color-md-blue-dark {{$moment(data.creation_date).format('DD.MM.YYYY')}}
      span(v-else) &#8212;

    .col-2.col-md-2
      .d-flex.align-items-center.justify-content-end.justify-content-md-start
        span.text-color--gray-light.me-2.d-md-none
          so-icon(icon="user-one")
        span(v-if="data.user_type").text-color--blue.text-color-md-blue-dark {{ getUserType }}
        span(v-else) &#8212;

    .col-3.col-md-3.word-wrap.text-wrap
      span(v-if="data.full_name").text-overflow {{ data.full_name }}
      span(v-else) &#8212;

    .d-none.d-md-block.col-4.text-truncate
      span(v-if=" data.email").text-overflow {{ data.email }}
      span(v-else) &#8212;

    .col-3
      .d-flex.align-items-center
        span.text-color--gray-light.me-2.d-md-none
          so-icon(icon="diary")
        span(v-if="data.exams_count") {{ data.exams_count }} {{ declOfNum(data.exams_count, ['осмотр', 'осмотра', 'осмотров']) }}
        span.text-color--blue(v-else) нет осмотров

        span.d-none.d-md-inline.ms-auto.text-color--accent
          so-icon(icon="right-arrow")


</template>

<script>
import {declOfNum} from '@/helpers/utils';


export default {
  name: 'AppAdminUserCard',
  components: {
    
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
  computed: {
    getUserType() {
      return this.data.user_type === 'person' ? 'Физическое лицо' : 'Юридическое лицо';
    }
  },
};
</script>

<style lang="scss"></style>
