
.profile-doctors.is-filterable
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7.col-md-auto
        app-title(:type="'large'") Журнал отстраненных работников
      .col.col-md-auto.ms-md-auto
        transition(name="translucent")
              button(
                type="button"
                @click="exportSuspendedWorkers()"
                class=['link--accent']
              )
                .d-flex.align-items-center
                span.flex-shrink-0.me-3
                    so-icon(icon="xls-doc")
                span.text-size--small Выгрузить данные .xlsx

  .profile-doctors__content.position-relative.mt-25
    transition(name="translucent")
      div
        app-table(
          v-if="suspendedWorkers.length"
          :columns="getColumns()"
          :sortKey="sortKey"
          :data="suspendedWorkers"
          :paginationData="pagination"
          :currentPage="currentPage"
          @change-current-page="setCurrentPage"
          :baseUrl="baseUrl"
          :canEdit="false"
          :canRemove="false"
        )
          template(v-slot:cell='{ column, row, index }')
            span(v-if='column.key === "admission_datetime"') {{$moment(row[column.key]).format('DD.MM.YYYY')}}  {{ $moment(row[column.key]).format('HH:mm:ss')}}
            span(v-else-if='column.key === "patient_company_name"') {{ row[column.key] }}
            span(v-else-if='column.key === "ra_and_rm_pre_diagnosis"') {{ getDiagnosis(row) }}

          template(v-slot:actionTools='{ row }')
            button(
              type="button"
              @click.prevent="download(row)"
            )
              img(src="@/assets/img/notice.svg")

        template(v-else)
          .text-color--blue.text-size--small Отстраненные работники не найдены

