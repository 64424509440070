<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Удалить устройство?
        p.text-color--blue.mt-3 Серийный номер МИ: {{ data.serial }}

      app-validation-errors(
        v-if="validationErrors"
        :classes="'mb-3'"
        :validation-errors="validationErrors"
      )
      .row.flex-column.align-items-center.g-4
        .col-14.col-md-8
          .row.align-items-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteDevice"
                :disabled="isLoading"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import devicesApi from '@/api/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import filesApi from '@/api/files';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';

export default {
  name: 'AppDeleteDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,

  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },

  },
  data() {
    return {
      validationErrors: '',
    };
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      error: (state) => state.devices.error,
    }),
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },
    getFile(hash) {
      filesApi.getFile(hash);
    },
    async deleteDevice() {
      console.log(this.data);
      try {
        await devicesApi.deleteDevice(this.data.serial);
        this.closeModal();
        this.success();
      } catch(error) {
        this.$toast.error('Возникла ошибка при удалении устройства');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__field {
    color: get($colors, blue-dark);
  }
}
</style>
