<template lang="pug">
.profile-doctor-detail
  .profile-doctor-detail__inner
    .profile-doctor-detail__header
      .row.align-items-center
        .col-14.col-md-auto.me-auto.order-last.order-md-first
          .row.align-items-center.g-4
            .col-auto
              app-back-button(:prev-page="'med-organizations'")
            .col
              app-title(:type="'large'") {{ isTouchDevice ? 'Медорганизация' : 'Страница Медорганизации' }}

    .profile-doctor-detail__content.mt-20
      transition(name="translucent")
        div(v-if="medOrganization")
          .row.align-items-center
            .col
              section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                .row.g-4.justify-content-between
                  .col
                    .text-color--blue.mb-3 Наименование
                    span(v-if="medOrganization.name") &#32;{{ medOrganization.name }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 ОГРН
                    span(v-if="medOrganization.ogrn") {{ medOrganization.ogrn }}
                    span(v-else) &#8212;

                  .col.d-none.d-md-block
                    .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeMedOrganization')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          so-icon(icon="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteMedOrganization')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          so-icon(icon="trash")

              section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                .row.g-4.justify-content-between
                  .col
                    .text-color--blue.mb-3 Лицензия
                    span(v-if="medOrganization.medical_license") {{ medOrganization.medical_license }}
                    span(v-else) &#8212;
                  .col
                    .text-color--blue.mb-3 С
                    span(v-if="medOrganization.medical_license_start") {{ $moment(medOrganization.medical_license_start).format('DD.MM.YYYY') }}
                    span(v-else) &#8212;
                  .col
                    .text-color--blue.mb-3 По
                    span(v-if="medOrganization.medical_license_end") {{ $moment(medOrganization.medical_license_end).format('DD.MM.YYYY') }}
                    span(v-else) &#8212;

                  .col.d-md-none
                    .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeMedOrganization')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          so-icon(icon="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteMedOrganization')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          so-icon(icon="trash")

          .position-relative
            template(v-if="!doctors && !users")
              .text-color--blue.text-size--small.mt-20 Нет клиентов и осмотров
            app-error-message(v-if="error" :message="error")

          // doctors tab
          so-tabs(v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex")
            so-tab(label="Медработники" name="doctors")
              app-loading(v-if="isLoading.doctors")
              template(v-if="doctors.length")
                .profile-table__row(v-for="doctor in doctors" :key="doctor.id")
                  .d-block
                    app-admin-doctor-card(
                      :data="doctor"
                      :columns="doctorsTab.columns"
                    )
                .d-flex.justify-content-center.mt-5
                  app-pagination(
                    :total="pagination.doctorsPagination.total"
                    :limit="pagination.doctorsPagination.limit"
                    :current-page="currentDoctorsPage"
                    :url="baseUrl"
                  )
              template(v-else-if="!isLoading.doctors && !doctors.length")
                .text-color--blue.text-size--small Осмотры не найдены

            so-tab(label="Клиенты" name="users")
              app-loading(v-if="isLoading.users")
              template(v-if="users && users.length")
                .profile-table
                  .profile-table__header.d-none.d-xl-block
                    .row.align-items-center.gx-4
                      div(v-for="column in usersTab.columns" :key="column.key" :class="column.class")
                        button(
                          type="button"
                          @click="sortingUserTable(column.key)"
                          class=['profile-table__sort-button']
                          :class="{'is-active': usersTab.sortKey === column.key}"
                        )
                          span.d-flex.align-items-center
                            span.text-overflow(v-if="column.caption") {{ column.caption }}
                            span(v-else-if="column.icon")
                              so-icon(:icon="column.icon")
                            span.d-flex.ms-3
                              so-icon(icon="down-arrow")
                  ul.profile-table__body
                    li.profile-table__row(v-for="user in users" :key="user.id")
                      router-link(:to="{name: 'user-detail', params: {id: user.user_id}}" class=['d-block'])
                        app-admin-user-card(:data="user")
                .d-flex.justify-content-center.mt-5
                  app-pagination(
                    :total="pagination.usersPagination.total"
                    :limit="pagination.usersPagination.limit"
                    :current-page="currentUsersPage"
                    :url="baseUrl"
                  )
              template(v-else-if="!isLoading.users && !users.length")
                .text-color--blue.text-size--small Клиенты не найдены

        template(v-else-if="!isLoading")
          .text-color--blue.text-size--small Медработника не существует в системе


    // change doctor modal
    app-change-med-organization-modal(
      v-if="medOrganization"
      :item="medOrganization"
      :is-visible="isModalVisible.changeMedOrganization"
      @close="closeModal('changeMedOrganization')"
      @success="getCurrentMedOrganization"
    )


  // delete medOrganization modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteMedOrganization" @close="closeModal('deleteMedOrganization')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить медорганизацию?
          p.text-color--blue.mt-3 {{ medOrganization.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteMedOrganization')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteMedOrganization(medOrganization)"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // success delete medOrganization modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Медорганизация успешно удалена!
          p(v-if="medOrganization.name").text-color--blue.mt-3 {{ medOrganization.name }}

        .d-flex.justify-content-center.mt-5
          button(
            @click="$router.go(-1)"
            type="button"
            class=['button button--accent']
          )
            span.button__content Назад в список
</template>

<script>
import doctorsApi from '@/api/doctors';
import usersApi from '@/api/users';
import medOrganizationsApi from '@/api/medOrganizations';

import {mapState} from 'vuex';

import is from 'is_js';
import {declOfNum, toLocalDate, getFile} from '@/helpers/utils';

import AppTab from '@/components/common/Tab';
import AppTabNav from '@/components/common/TabNav';
import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppSelect from '@/components/common/Select';
import AppPagination from '@/components/common/Pagination';
import AppGallery from '@/components/common/Gallery';
import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import SoTab from '@/components/so/tabs/SoTab';
import SoTabs from '@/components/so/tabs/SoTabs';

import AppAdminDoctorCard from '@/components/profile/admin/doctors/AdminDoctorCard';
import AppAdminUserCard from '@/components/profile/admin/users/AdminUserCard';
import AppChangeMedOrganizationModal from '@/components/profile/admin/doctors/ChangeMedOrganizationModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';


export default {
  name: 'AppAdminMedOrganizationDetail',
  components: {
    AppBackButton,
    AppTitle,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppModal,
    AppSelect,
    AppGallery,
    AppTab,
    AppTabNav,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppAdminDoctorCard,
    AppAdminUserCard,
    AppChangeMedOrganizationModal,
    SoTab,
    SoTabs,
  },
  mixins: [galleryMixin, examDetailModalMixin, examDetailMobileMixin, certificateModalMixin],
  data() {
    return {
      isLoading: {
        users: false,
        doctors: false,
      },
      users: {},
      doctors: {},
      medOrganization: {},
      pagination: {
        usersPagination: {
          limit: 5,
          total: 0,
        },
        doctorsPagination: {
          limit: 5,
          total: 0,
        },
      },
      filterString: '',
      declOfNum,
      isModalVisible: {
        changeMedOrganization: false,
        deleteMedOrganization: false,
      },
      activeTabId: 'doctors',
      tabs: [
        {
          id: 'doctors',
          caption: 'Медработники',
          sortKey: 'creation_date',
          columns: [
            {
              class: 'col-14 col-md-2',
              caption: 'Дата регистрации',
              key: 'creation_date',
              inverse: true,
            },
            {
              class: 'col-14 col-md-3',
              caption: 'ФИО медработника',
              key: 'full_name',
              inverse: true,
            },
            {
              class: 'col-14 col-md-2',
              caption: 'E-mail',
              key: 'email',
              inverse: true,
            },
            {
              class: 'col-14 col-md-3',
              caption: 'Название мед.организации',
              key: 'med_organization_name',
              inverse: true,
            },
            {
              class: 'col-auto col-md-2',
              caption: 'Кол-во клиентов',
              key: 'clients_count',
              inverse: true,
            },
            {
              class: 'col-auto col-md-2 flex-grow-1',
              caption: 'Пользователей на осмотре',
              key: 'patients_count',
              inverse: true,
            },
          ],
        },
        {
          id: 'clients',
          caption: 'Клиенты',
          sortKey: 'creation_date',
          columns: [
            {
              class: 'col-2',
              caption: 'Дата регистрации',
              key: 'creation_date',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Тип клиента',
              key: 'user_type',
              inverse: false,
            },
            {
              class: 'col-3',
              caption: 'Клиент',
              key: 'full_name',
              inverse: false,
            },
            {
              class: 'col-4',
              caption: 'E-mail',
              key: 'email',
              inverse: false,
            },
            {
              class: 'col-3',
              caption: 'Осмотры',
              key: 'exam_count',
              inverse: true,
            },
          ],
        },
      ],
      activeIndex: 0,
    };
  },
  computed: {
    ...mapState({
      globalSettings: (state) => state.settings.data,
      error: (state) => state.medOrganizations.error,
    }),
    currentDoctorsPage() {
      return Number(this.$route.query.page || '1');
    },
    currentUsersPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    medOrganizationId() {
      return Number(this.$route.params.id);
    },
    activeTab() {
      return this.tabs.find((el) => el.id === this.activeTabId);
    },
    doctorsTab() {
      return this.tabs[0];
    },
    usersTab() {
      return this.tabs[1];
    },
  },
  watch: {
    currentDoctorsPage() {
      this.getDoctors();
    },
    currentUsersPage() {
      this.getUsers();
    },
  },
  async created() {
    this.getDoctors();
    this.getCurrentMedOrganization();
    this.getUsers();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({query: Object.assign({}, this.$route.query, {page: 1})});
    },
    async getDoctors() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.doctorsTab.sortKey}`;
      this.isLoading.doctors = true;
      let res = await doctorsApi.getDoctorsNew(
        this.currentDoctorsPage - 1,
        order_by,
        { medical_organization_id: this.medOrganizationId }
      );
      this.doctors = res.data.items;
      this.pagination.doctorsPagination.total = res.data.total;
      this.pagination.doctorsPagination.limit = res.data.per_page;
      this.isLoading.doctors = false;
    },
    async getCurrentMedOrganization() {
      let res = await medOrganizationsApi.getMedOrganizations({ medical_organization_id: this.medOrganizationId });
      this.medOrganization = res.data.items[0];
    },

    async getUsers() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }

      let order_by = `${sortInverse}${this.usersTab.sortKey}`;

      this.isLoading.users = true;
      let res = await usersApi.getUsersNew('', {
        page: this.currentUsersPage - 1,
        order_by,
        med_organization_ids: [ this.medOrganizationId ]
      });
      this.users = res.data.items;
      this.pagination.usersPagination.total = res.data.total;
      this.pagination.usersPagination.limit = res.data.per_page;
      this.isLoading.users = false;
    },
    toLocalDate(date) {
      return toLocalDate(date);
    },
    setActiveTab(id) {
      if (id === 'clients') {
        this.getUsers();
      } else {
        this.getDoctors();
      }
      this.goToFirstPage();
      this.activeTabId = id;
    },
    getFile(src) {
      getFile(src);
    },
    sortingTable(sortKey ) {
      this.sortInverse = !this.sortInverse;
      this.doctorsTab.sortKey = sortKey;
      this.getDoctors();
    },
    sortingUserTable(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.usersTab.sortKey = sortKey;
      this.getUsers();
    },
    async deleteMedOrganization(medOrganization) {
        const { id } = medOrganization;

        try {
          console.log(medOrganization)
          await medOrganizationsApi.deleteMedOrganization({ id });
          this.closeModal('deleteMedOrganization');
          this.openModal('success');
        } catch (error) {
          this.$toast.error('Произошла ошибка при удалении доктора:', error);
        }
    },
    changeActiveTab(index) {
      console.log(index);
      this.activeIndex = index;
    }
  },
};
</script>

<style lang="scss">
.profile-doctor-detail {
  &__card {
    border-radius: $border-radius-sm;
    padding: $spacer $spacer * 1.6;
  }
}
</style>
