
.row.align-items-center
  .col-8.mb-4
    app-title(:type="'normal'") {{ title }}
  .row.align-items-center
    .col-8.mb-4
      .form__file.d-flex.align-items-center
        input(
          class="d-none"
          type="file"
          name="document"
          @change="handleSelect"
          ref="fileToRead"
          accept="image/*"
        )
        div(v-if="form.fileToRead" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
          span.flex-shrink-0.me-3
            so-icon(icon="document")
          ul.flex-grow-1.overflow-hidden
            li.form__file-name.text-color--black {{ form.fileToRead.name }}
            li.mt-1 {{ Number(form.fileToRead.size/1024).toFixed(1) }} Kb
          button(
            type="button"
            @click="clearFileInput"
            class=['form__file-delete', 'flex-shrink-0 ms-3']
          )
            so-icon(icon="trash")
        .text-color--blue.text-size--small(v-else) {{ subtitle }}
        button(
          class=['form__file-button', 'd-flex justify-content-end align-items-center']
          @click="$refs.fileToRead.click()"
        )
          so-icon(icon="clip")
    .col-8.mb-4
      button(
        :disabled="!form.fileToRead"
        type="submit"
        class=['button', 'button--accent', 'form__button']
        @click="onSubmit(typeOfProject, linkOfProject)"
      )
        span.button__content Загрузить
  .col-2.align-items-center
    img(:src='imageSrc' :alt="imageAlt")
