<template lang="pug">
  .profile-device-detail
    .profile-device-detail__inner.is-filterable
      .profile-device-detail__header
        .row.align-items-center
          .col-14.col-md-auto.me-auto.order-last.order-md-first
            div
              .row.align-items-center.g-5
                .col-3.col-md-auto
                  app-back-button(:prev-page="'devices'")
                .col-7.col-md.flex-grow-1
                  app-title(
                    :type="'large'"
                    :classes="'text-center'"
                  ) {{ isTouchDevice ? 'Устройство' : 'Просмотр устройства' }}
                .col-3.d-md-none
                  .d-flex.justify-content-end
                    button(
                      type="button"
                      @click.prevent="openModal('deleteDevice')"
                      class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        so-icon(icon="trash")

          .col-auto.order-1.order-md-0.ms-auto
            //- transition(name="translucent")
            //-   router-link(
            //-     :to="{name: 'map'}"
            //-     class=['d-flex align-items-center', 'link--accent']
            //-   )
            //-     span.flex-shrink-0.me-2
            //-       so-icon(icon="map-point")
            //-     span.text-size--small {{ isTouchDevice ? 'Геокарта' : 'Просмотреть геокарту' }}

          .col-auto
            transition(name="translucent")
              button(
                v-if="exams && exams.length"
                type="button"
                @click="() => exportExams()"
                class=['link--accent']
              )
                .d-flex.align-items-center
                  span.flex-shrink-0.me-3
                    so-icon(icon="xls-doc")
                  span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}

      .profile-device-detail__card.mt-20.position-relative
        app-loading(v-if="devicesLoading")
        app-error-message(v-if="devicesError" :message="devicesError")

        app-admin-device-card(
          v-if="current_device"
          :data="current_device"
          :no-hover="true"
          @delete="openModal('deleteDevice')"
          @change="openChangeModal(current_device)"
        )

      .profile-device-detail__content.pt-20(id="isAnchorForBtn")
        transition(name="translucent")
          app-loading(v-if="examsLoading")
          app-error-message(v-if="examsError" :message="examsError")

          div(v-if="exams")
            app-title(
              :type="'large'"
              :classes="'text-center mb-20 mb-md-5 d-xl-none'"
            ) Осмотры

            template(v-if="exams && exams.length")
              .profile-table.is-exams-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        type="button"
                        @click="sortingExams(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            so-icon(:icon="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            so-icon(icon="down-arrow")


                ul.profile-table__body(id="device-exams")
                  li.profile-table__row(v-for="exam in exams" :key="exam.exams_id")
                    app-exam-card(
                      :data="exam"
                      :parent-id="'device-exams'"
                      :type="'device-detail'"
                      :columns="columns"
                      :classes="'d-none d-xl-block'"
                      @openModal="openExamDetailModal"
                      @openCertificate="openCertificate"
                    )
                    app-exam-card-mobile(
                      :data="exam"
                      :classes="'d-xl-none'"
                      @openModal="openExamDetailMobile"
                    )

            template(v-else)
              .mt-4.text-color--blue.text-size--small(:class="{'text-center': isTouchDevice}") Осмотров не найдено

        // pagination
        .d-flex.justify-content-center.mt-5
          app-pagination(
            :total="pagination.total"
            :limit="pagination.limit"
            :current-page="currentPage"
            :url="baseUrl"
          )

    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
    // delete modal
    app-delete-device-modal(
      v-if="current_device"
      :data="current_device"
      :is-visible="isModalVisible.deleteDevice"
      @close="closeModal('deleteDevice')"
      @success="openModal('success')"
    )
    //Edit
    change-device-modal(
      :is-visible="isModalVisible.changeDevice"
      :toEdit="toEdit"
      @close="closeModal('changeDevice')"
      @success="getDevice"
    )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :type="'device-detail'"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @close="closeModal('examDetailMobile')"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // success deleted modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Устройство успешно удалено

          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="$router.go(-1)"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content Назад в список

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import devicesApi from '@/api/devices';
import examsApi from '@/api/exams';

import is from 'is_js';
import variables from '@/helpers/variables';
import {blobToFile} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppBackButton from '@/components/common/BackButton';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';

import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppDeleteDeviceModal from '@/components/profile/common/devices/DeleteDeviceModal';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {galleryMixin} from '@/mixins/gallery';
import ChangeDeviceModal from '@/components/profile/client/devices/ChangeDeviceModal';
import AppCertificateModal from '@/components/profile/common/CertificateModal';
import {certificateModalMixin} from '@/mixins/certificateModal';

import { kludgeRemakeParams } from '@/api/exams.draft.ts';

export default {
  name: 'AppAdminDeviceDetail',
  components: {
    AppBackButton,
    AppErrorMessage,
    AppLoading,
    AppModal,
    AppGallery,
    AppPagination,
    AppTitle,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppDeleteDeviceModal,
    AppAdminDeviceCard,
    ChangeDeviceModal,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, galleryMixin, certificateModalMixin,],
  data() {
    return {
      current_device: {},
      exams: {},
      v: variables,
      isModalVisible: {
        deleteDevice: false,
        changeDevice: false,
      },
      sortKey: '',
      sortInverse: false,
      columns: [
        {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата и время',
          key: 'exam_datetime',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Тип осмотра',
          key: 'exam_type_name',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-2 d-flex justify-content-end flex-row-reverse',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
      toEdit: {},
    };
  },
  computed: {
    ...mapState({
      devicesLoading: (state) => state.devices.isLoading,
      devicesError: (state) => state.devices.error,
    }),
    isTouchDevice() {
      return is.touchDevice();
    },
    ...mapState({
      examsLoading: (state) => state.exams.isLoading,
      examsError: (state) => state.exams.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    deviceId() {
      return this.$route.params.id;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          devices: [this.deviceId],
          patients: {
            negative: true,
            payload: [],
          },
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getExams();
    },
  },
  async created() {
    this.getDevice();
    this.getExams();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    async getDevice() {
      let res = await devicesApi.getDeviceBySerialId(this.$route.params.id);
      this.current_device = res.data;
    },
    async getExams() {
      let res = await examsApi.getExamsByDevice(this.currentPage - 1, this.$route.params.id);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.exams = res.data.items;
    },
    exportExams() {
      let data = {
        filters: Object.assign({}, this.settings.filters),
        sorting_params: this.settings.sorting_params,
      };

      const _data = kludgeRemakeParams(data);

      this.$store
        .dispatch(examsActionTypes.exportExams, _data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    sortingExams(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExams(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openChangeModal(data) {
      //this.deleteDevice = data;
      this.isModalVisible.changeDevice = true;
      this.toEdit = data;
    },
  },
};
</script>

<style lang="scss">
.profile-device-detail {
  &__header {
    .icon {
      &__plus {
        width: $spacer * 1.2;
        height: $spacer * 1.2;
      }
      &__map-point,
      &__xls-doc {
        width: $spacer * 2;
        height: $spacer * 2;
        fill: get($colors, blue);
      }
    }
  }
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
