<template lang="pug">
.profile-devices__inner.is-filterable
  .profile-devices__header.mb-4
    .row.align-items-center
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
          .d-flex.justify-content-end
            button(
              type="button"
              @click="addModal"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  so-icon(icon="plus-thin")
                span Добавить КЦ

  .profile-devices__content.mt-20.position-relative
    app-loading(v-if="isLoading")
    app-validation-errors(
      v-if="localValidationErrors"
      :validation-errors="localValidationErrors"
      :classes="'mb-3'"
    )
    transition(name="translucent")
      app-table(
        v-if="devices.length"
        :columns="columns"
        :sortKey="sortKey"
        @sortingRow="sortingDevices"
        :data="devices"
        :paginationData="pagination"
        :currentPage="currentPage"
        :baseUrl="baseUrl"
        :canEdit="true"
        :canRemove="true"
        @edit-modal="editModal"
        @delete-modal="deleteModal"
      )
        template(v-slot)


      div(v-else)
        .d-flex.align-items-center.text-color--blue.text-size--small
          span.d-flex.me-3
            so-icon(icon="wrong")
          span Нет устройств

  // delete modal
  app-delete-firmware-modal(
    :deleteFirmvareId="deleteFirmvareId"
    :is-visible="isModalVisible.deleteDevice"
    @close="closeModal('deleteDevice')"
    @success="closeModal('deleteDevice')"
    @firmwareEdited="getDevices"
  )
  // Edit modal
  app-edit-firmware-modal(
    :data="editDate"
    :is-visible="isModalVisible.editFirmware"
    @close="closeModal('editFirmware')"
    @success="closeModal('editFirmware')"
    @firmwareEdited="getDevices"
  )
   // Add modal
  app-add-firmware-modal(
    :data="deleteDevice"
    :is-visible="isModalVisible.addFirmware"
    @close="closeModal('addFirmware')"
    @success="closeModal('editFirmware')"
    @firmwareEdited="getDevices"
  )

</template>

<script>
import devicesApi from '@/api/devices';

import variables from '@/helpers/variables';
import {declOfNum} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';

import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import {modalsMixin} from '@/mixins/modals';

import NavDevice from '@/components/profile/common/NavDevice';
import AppDeleteFirmwareModal from '@/components/profile/admin/devices/AppDeleteFirmwareModal';
import AppEditFirmwareModal from '@/components/profile/admin/devices/AppEditFirmwareModal';
import AppAddFirmwareModal from '@/components/profile/admin/devices/AppAddFirmwareModal';
import AppValidationErrors from '@/components/common/ValidationErrors';


import AppTable from '@/components/common/table/Table';
export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    AppTitle,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    NavDevice,
    AppDeleteFirmwareModal,
    AppEditFirmwareModal,
    AppAddFirmwareModal,
    AppTable,
    AppValidationErrors
  },
  mixins: [modalsMixin],
  data() {
    return {
      devices: [],
      localValidationErrors: null,
      isLoading: false,
      v: variables,
      declOfNum,
      isModalVisible: {
        createDevice: false,
        deleteDevice: false,
        successDeleted: false,
        successCreated: false,
        editFirmware: false,
        addFirmware: false,
      },
      deleteDevice: {},
      sortKey: 'version',
      sortInverse: false,
      columns: [
        {
          caption: 'Версия прошивки',
          key: 'version',
          inverse: false,
        },
        {
          caption: 'Контрольная сумма',
          key: 'sum',
          inverse: true,
        },
        {
          caption: 'Автор записи',
          key: 'email',
          inverse: false,
        },
        {
          caption: 'Дата записи',
          key: 'creation_time_formatted',
          inverse: true,
        },
      ],
      pagination: {
        limit: 0,
        total: 0,
      },
      editDate: {},
      deleteFirmvareId: null,
    };
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
  },
  watch: {
    currentPage() {
      this.getDevices();
    },
  },
  created() {
    this.getDevices();
  },
  methods: {
    async getDevices() {
      try {
        this.isLoading = true
        let response = await devicesApi.getDeviceIntegrity();
        this.devices = response.data.integrity_controls;

        this.pagination.total = res.data.integrity_controls.length;
        this.pagination.limit = 10;
      } catch(error) {
        this.localValidationErrors = error.request?.statusText;
      } finally {
        this.isLoading = false
      }
    },
    sortingDevices(sortKey, inverse) {
      if (inverse === false) {
        return;
      }
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getDevices();
    },
    deleteModal(data) {
      this.deleteFirmvareId = data.id;
      this.isModalVisible.deleteDevice = true;
    },
    editModal(data) {
      this.editDate = data;
      this.isModalVisible.editFirmware = true;
    },
    addModal() {
      this.isModalVisible.addFirmware = true;
    },
  },
};
</script>
