
.inspection-card__signatures
  .signature.d-flex.flex-column.justify-content-center.align-items-center.text-align-center.position-relative
    p.signature__caption Подпись осмотра
    app-signature.align-self-stretch(
    :localUrl="exam.signature"
    :theme="'square'"
    :classes="'large d-flex justify-content-center'"
    @openGallery="openGallery"
    )
  .signature.d-flex.flex-column.justify-content-center.align-items-center.text-align-center
    p.signature__caption Подпись оригинал
    app-signature(
    :localUrl="patientSign"
    :theme="'square'"
    :classes="'large d-flex justify-content-center'"
    @openGallery="openGallery"
    )

