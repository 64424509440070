<template lang="pug">
    .profile-doctors
      .profile-doctors__header
        .row.align-items-center
          .col-14.col-md-12
            .d-flex.align-items-center
              app-title(:type="'large'") Настройки системы. Настройки фильтрации осмотров

              .profile-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                          type="button"
                          @click="alert(123)"
                          class=['profile-table__sort-button']
                      )
                      span.d-flex.align-items-center
                              span.text-overflow(:title="column.caption") {{ column.caption }}
                              span.d-flex.ms-3
                              so-icon(icon="down-arrow"
                      )


                    ul.profile-table__body
                      li.profile-table__row(v-for="border in bordersIds" :key="border.num")
                          section(
                              class=['user-card', 'user-card-history']
                            )
                            .row.align-items-center.g-4.gy-xl-0
                              .col-2
                                  div {{ border.name }}
                              .col-3
                                span.presonal-border-text от
                                input(
                                    :class="['personal-border', 'form__field']"
                                    type="email"
                                    placeholder="от"
                                    v-model="data[border.id].min_value"
                                )
                                span.presonal-border-text до
                                input(
                                    :class="['personal-border', 'form__field']"
                                    type="email"
                                    placeholder="до"
                                    v-model="data[border.id].max_value"
                                )
                              .col-2
                                date-picker(
                                    prefix-class="app"
                                    placeholder="от"
                                    format="DD.MM.YYYY"
                                    :editable="true"
                                    v-model="data[border.id].end_date"
                                    value-type="YYYY-MM-DD"
                                )
                              .col-2(v-if="currentUser.role !== 'CLIENT'")
                                button(
                                    type="button"
                                    @click="sendBorder(data[border.id])"
                                    class=['text-decoration--underline text-size--small link--accent']
                                ) Сохранить
</template>

<script>
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';

export default {
  name: 'SystemSetupFilteringInspections',
  components: {
    AppTitle,
    AppMultipleSelect,
  },
  data() {
    return {
      user: null,
      bordersIds: [
        {
          'id': 'bp_high',
          'num': 1,
          'name': 'Давление систолическое'
        },
        {
          'id': 'bp_low',
          'num': 2,
          'name': 'Давление диастолическое'
        },
        {
          'id': 'heart_rate',
          'num': 3,
          'name': 'ЧСС'
        },
        {
          'id': 'body_temp',
          'num': 4,
          'name': 'Температура'
        },
        {
          'id': 'alcohol',
          'num': 5,
          'name': 'Алкоголь',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },

  methods: {},
};
</script>

<style></style>
