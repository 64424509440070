
section(:class="['profile-card', 'profile-card-device']")
  .row.align-items-center.g-4.flex-xl-nowrap.justify-content-between
    div(class=['col-auto col-md-3 col-xl-3 paddingLeft d-flex align-items-center'])
      .d-none.d-md-flex.flex-shrink-0.profile-card-device__icon
        so-icon(icon="printer" view-box="0 0 17 24")
      .ms-md-4
      div.d-flex.flex-column
        span {{ data.name }}
    .col-md-2.d-flex.justify-content-end
      div.me-4
        button(
          type="button"
          @click.prevent="changeModal"
          class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
          so-icon(icon="plus-thin")
      div
        button(
          type="button"
          @click.prevent="deleteModal"
          class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
          so-icon(icon="trash")
