
.js-status.profile-status.flex-shrink-0(:class="[classes, `${theme}-theme`]" :data-status="status.className")
  .d-md-none(v-if="line")
    span.profile-status__top-line(:class="status.className")

  div(:class="{'d-none d-md-block': line}")
    template(v-if="type === 'device'")
      span(:class="`text-color--${status.color}`")
        so-icon(:icon="status.icon")

    template(v-else-if="type === 'exam'")
      .d-flex.align-items-center(:class="`text-color--${status.color}`")
        span.d-flex.me-3
          so-icon(icon="circle")
        span.profile-status__caption(v-if="caption") {{ status.caption }}

    template(v-else-if="type === 'doctor'")
      span.profile-status__bottom-line(v-if="theme === 'bottom-line'" :class="status.className")
      .d-flex.align-items-center(:class="[theme === 'bottom-line' ? 'text-color--white' : `text-color--${status.color}`]")
        span.d-flex.me-3
          so-icon(:icon="status.icon")
        span.profile-status__caption(v-if="caption") {{ status.caption }}

    template(v-else)
      span(:class="`text-color--${status.color}`") {{ status.caption }}

