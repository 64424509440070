
.row.justify-content-center
  template(v-if="showResult")
    button(
      @click="showResult = !showResult"
      type="button"
      class=['d-flex', 'auth__back-button']
    )
      so-icon(icon="back-arrow")

    .col-14.col-xl-10
      .text-center.mb-5
        app-title(:type="'large'" :classes="'mb-3'") Статус заявки
        p.text-color--blue.text-size--small Почта: {{ email }}

      app-loading(v-if="isLoading")

      template(v-if="status")
        .d-flex.flex-column
          template(v-if="status.status_code === v.requests.statuses.pending")
            .auth__status.mb-5
              .row.align-items-center.justify-content-center.g-4
                .col-auto
                  so-icon(icon="process")
                .col-9
                  ul.text-size--small.text-color--blue-dark
                    li.text-color--accent.text-family--medium.mb-2 На проверке
                    li.mb-1 Заявка зарегистрирована: {{ status.creation_date }}
                    li Последнее обновление: {{ currentDate }}
            button(
              type="button"
              @click="onSubmit"
              :disabled="isSubmitting"
              class=['button', 'button--accent']
            )
              span.button__content Обновить

          template(v-if="status.status_code === v.requests.statuses.approved")
            .auth__status.mb-5
              .row.align-items-center.justify-content-center.g-4
                .col-auto
                  so-icon(icon="allright")
                .col-9
                  ul.text-size--small.text-color--blue-dark
                    li.text-color--success.text-family--medium.mb-2 Вы зарегистрированы
                    li Вы можете пользоваться порталом с {{ status.last_update_date }}
            router-link(
              :to="{name: 'login'}"
              tag="button"
              class=['button', 'button--accent']
            )
              span.button__content Войти в личный кабинет

          template(v-if="status.status_code === v.requests.statuses.rejected")
            .d-flex.flex-column
              .auth__status.mb-5
                .row.align-items-center.justify-content-center.g-4
                  .col-auto
                    so-icon(icon="wrong")
                  .col-9
                    ul.text-size--small.text-color--blue-dark
                      li.text-color--danger.text-family--medium.mb-2 {{ status.status_name }}
                      li.mb-2(v-if="status.reason") Причина отказа: {{ status.reason }}
                      li Необходимо заполнить заявку заново

              router-link(
                :to="{name: 'register'}"
                tag="button"
                class=['button', 'button--accent']
              )
                span.button__content Новая заявка на регистрацию


      template(v-if="validationErrors")
        .d-flex.flex-column
          .auth__status.mb-5
            .row.align-items-center.justify-content-center.g-4
              .col-auto
                so-icon(icon="wrong")
              .col-auto
                .text-size--small.text-family--medium.text-color--danger
                  app-validation-errors(:validation-errors='validationErrors')

          router-link(
            :to="{name: 'register'}"
            tag="button"
            class=['button', 'button--accent']
          )
            span.button__content Новая заявка на регистрацию

  template(v-else)
    .col-14.col-xl-12.col-xxl-10
      .text-center.mb-5
        app-title(:type="'large'" :classes="'mb-3'") Проверка статуса заявки
        p.text-color--blue.text-size--small Здесь Вы можете отследить статус<br>Вашей заявки на регистрацию

      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isSubmitting}]")
        .row.flex-column.align-items-center
          .col.mb-5
            label.form__label
              input(
                :class="['form__field', { 'is-error': v$.email.$errors.length }]"
                type="email"
                v-model="v$.email.$model"
                placeholder="E-mail"
              )
            ul.form__error
              li(v-for="error of v$.email.$errors" :key="error.$uid")
                template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                template(v-if="error.$validator === 'email'") {{ v.errors.email }}

          .col.mb-4
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Проверить

          .col-auto
            app-tooltip(
              :content="'Заявка проверяется администратором. По времени рассмотрение может занять от 1-ого до 4-х рабочих дней.'"
              :classes="'text-color--blue-dark text-decoration--underline text-size--small'"
            )
              template(v-slot:body)
                span.ms-3 Как проверяется моя заявка?

