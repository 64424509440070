<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") редактировать поверку устройства
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-4
            .col-14.col-md-10
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .col-14.col-md-10
              .row.g-4.posRelative
                .col
                  div
                    input(
                      :class="['form__field form__field--animate', { 'is-error': v$.form.serial.$errors.length }]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="form.serial"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                      disabled
                    )
                    label.textFloat(for='id') Серийный номер МИ
                  .row.gx-4
                   .col-14.mt-3
                    input(
                      :class="['form__field form__field--animate', { 'is-error': v$.form.attestation.$errors.length }]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="form.attestation"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="ids"
                    )
                    label.textFloat(for='ids') Номер поверки
                   .col-7
                      .text-size--small.mb-2.mt-4 Дата поверки
                       label(:class="['form__label pt-2', { 'is-error': v$.form.dateStart.$errors.length }]")
                        date-picker(
                            prefix-class="app"
                            placeholder="Дата поверки"
                            format="DD.MM.YYYY"
                            lang="ru"
                            :editable="true"
                            v-model="form.dateStart"
                            :disabled-date="disableAfterToday"
                        )
                   .col-7
                     .text-size--small.mb-2.mt-4  Окончание срока поверки
                      label(:class="['form__label pt-2', { 'is-error': v$.form.dateEnd.$errors.length }]")
                        date-picker(
                              prefix-class="app"
                              placeholder="Окончание срока поверки"
                              format="DD.MM.YYYY"
                              lang="ru"
                              :editable="true"
                              v-model="form.dateEnd"
                              :disabled-date="disableExpiration"
                          )
            .col-14.col-md-10.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Отправить

</template>

<script>
import devicesApi from '@/api/devices';
import {mapState} from 'vuex';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import { disableAfterToday, disableExpiration } from '@/helpers/datepicker';
export default {
  name: 'ChangeDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,

    DatePicker,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    toEdit: {
      required: true,
      type: Object,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isLoading: false,
      v: variables,
      form: {
        devices: [
          {
            id: 0,
            value: '',
          },
        ],
        serial: '',
        attestation: '',
        number: '',
        dateStart: '',
        dateEnd: '',
      },
      localValidationErrors: null,
      limit: 100,
    };
  },
  validations() {
    return {
      form: {
        serial: {
          required,
        },
        attestation: {
          required,
        },
        dateStart: {
          required,
        },
        dateEnd: {
          required,
        },
      },
    };
  },
  watch: {
    toEdit: {
      immediate: true,
      handler() {
        this.form.serial = this.toEdit.serial;
        this.form.attestation = this.toEdit.device_attestation;
        this.form.number = this.toEdit.device_attestation;
        this.form.id = this.toEdit.id;
        this.form.dateStart = new Date(
          new Date(this.toEdit.device_attestation_start).setHours(0, 0, 0, 0)
        );
        this.form.dateEnd = new Date(
          new Date(this.toEdit.device_attestation_end).setHours(0, 0, 0, 0)
        );
      },
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      let data =  {
        'device_attestation': this.form.attestation,
        'device_attestation_start': this.$moment(this.form.dateStart).format('YYYY-MM-DD'),
        'device_attestation_end': this.$moment(this.form.dateEnd).format('YYYY-MM-DD'),
      }
      try {
        await devicesApi.updateDeviceCheck(this.form.id, data);
        this.$toast.success('Данные успешно изменены');
        this.closeModal();
      } catch (error) {
        if (error.request.statusText === 'Conflict') {
          this.validationErrors = 'a_device_serial_must_be_unique';
        } else this.localValidationErrors = error.request.statusText;
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.resetForm();
      this.$emit('close');
      this.$emit('success');
    },
    resetForm() {
      this.v$.$reset();
      this.localValidationErrors = null;
      this.form.devices = [{id: 0, value: ''}];
    },
    disableAfterToday(date) {
      return disableAfterToday(date, this.form.dateEnd);
    },
    disableExpiration(date) {
      return disableExpiration(date, this.form.dateStart);
    },
  },
};
</script>
