// Функция, которая определяет, должна ли дата быть отключена после сегодняшнего дня или после определенной переданной даты.
export const disableAfterToday = (date, end) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (end && end < date) {
    return date > end;
  } else {
    return date > today;
  }
};

// Функция, которая определяет, должна ли дата быть отключена до сегодняшнего дня или до определенной переданной даты.
export const disableExpiration = (date, start) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (start) {
    return date < today;
  } else {
    return date < today;
  }
};

//**Функция, которая определяет, должна ли дата быть отключена до сегодняшнего дня или до определенной переданной даты. Если указана дата начала (start), то функция возвращает true для всех дат, которые раньше этой даты (date < start). Если дата начала (start) не указана, то функция всегда возвращает false, что означает, что все даты будут доступными. Используется в фильтрах * /

export const disableExpirationFilters = (date, start) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (start) {
    return date < start;
  } else {
    return false;
  }
};
