
.inspection-card__table.col-14
  .row
    .col-14
      .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column(
          @click="getDocuments"
          class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
        ) Медицинские документы
        span.d-flex.align-items-center.ms-3

      .profile-table
        transition(name="translucent")
          .profile-table(v-if="showDocs")
            .profile-table__header.d-none.d-xl-block
              template(v-if="incomingDocs.length || outcomingDocs.length")
                ul.text-size--normal

                  // Входящие документы
                  template(v-if="incomingDocs.length") Входящие документы
                    li.text-size--small(v-for="doc in incomingDocs.slice(0, pagination.incomingDocs.total)" :key="doc.text")
                      a(@click="download(doc)" href="#") {{ doc.text }}
                    div.mb-2
                      button(
                        v-if="pagination.incomingDocs.total < incomingDocs.length"
                        type="button"
                        @click="loadMoreIncomingDocuments"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ Math.min(pagination.incomingDocs.offset, incomingDocs.length - pagination.incomingDocs.total) }})

                      button(
                        v-if="pagination.incomingDocs.total > pagination.incomingDocs.start"
                        type="button"
                        @click="clearPagination('incomingDocs')"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть

                  // Исходящие документы
                  template(v-if="outcomingDocs.length") Исходящие документы
                    li.text-size--small(v-for="doc in outcomingDocs.slice(0, pagination.outcomingDocs.total)" :key="doc.text")
                      a(@click="download(doc)" href="#") {{ doc.text }}
                    div.mb-2
                      button(
                        v-if="pagination.outcomingDocs.total < outcomingDocs.length"
                        type="button"
                        @click="loadMoreOutcomingDocuments"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ Math.min(pagination.outcomingDocs.offset, outcomingDocs.length - pagination.outcomingDocs.total) }})

                      button(
                        v-if="pagination.outcomingDocs.total > pagination.outcomingDocs.start"
                        type="button"
                        @click="clearPagination('outcomingDocs')"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть
              div(v-else-if="isLoading") Документы загружаются

              template(v-else)
                div Документы отсутствуют

