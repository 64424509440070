
.profile-avatar.d-flex.flex-column.align-items-center(:class="classes")
  //- Картинка аватара
  figure.profile-avatar__img.position-relative
    template(v-if="localUrl")
      template(v-if="!isError")
        button(v-if="viewPhoto" class="stretched-link" type="button" @click.prevent="openGallery(type, avatarUrl, id)")
        img(:src="avatarUrl")

      template(v-else)
        //- Если произошла ошибка при загрузке изображения
        button(v-if="type === 'patient'" class="stretched-link" type="button" @click="changeAvatar")
        img(src='@/assets/svg/no-image.svg')

    //- если нет изображения
    template(v-else-if='src')
      button(v-if="type === 'patient'" class="stretched-link" type="button" @click="changeAvatar")
      img(v-else :src='src')

    //- если нет изображения
    template(v-else)
      button(v-if="type === 'patient'" class="stretched-link" type="button" @click="changeAvatar")
      so-icon(v-if="type === 'patient'" icon="no-image")
      img(v-else src='@/assets/svg/no-image.svg')

  //- модальное окно для изменения аватарки
  template(v-if="edit")
    input(class="d-none" type="file" name="document" @change="onFileSelected" ref="fileInput" accept="image/*")
    button(class=['profile-avatar__edit-button', 'd-flex align-items-center justify-content-center', 'mt-4'] type="button" @click="$refs.fileInput.click()")
      span.d-flex.flex-shrink-0
        so-icon(icon="edit")

    //- Модальное окно для кроппера
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.cropper" @close="closeCropperModal" :classes="'full-content'")
        template(v-slot:body)
          cropper(ref="cropper" class=['profile-avatar__cropper', 'mt-4'] :class="{'is-loading': usersLoading}" :src="cropperImg" :stencil-props="{aspectRatio: 1/1}" :stencil-component="$options.components.CircleStencil")
          .row.align-items-center.justify-content-center.mt-4.g-4
            .col-auto
              button(type="button" class=['button button--blue'] @click="onSubmit" :disabled="usersLoading")
                span.button__content Сохранить
            .col-auto
              button(type="button" class=['button button--border-blue'] @click="closeCropperModal")
                span.button__content Отмена
