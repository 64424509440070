<template>
<transition name="fade" mode="out-in">
  <app-modal v-if="isVisible" @close="closeModal">
    <template v-slot:body>
      <div class="text-center mb-5">
        <app-title :type="'large'">Добавление устройств</app-title>
        <app-loading v-if="isLoading"></app-loading>
        <p class="text-size--small text-color--blue mt-3">Для успешного добавления укажите серийный номер и скан документа, подтверждающего покупку. Если все устройства указаны в одном документе, приложите его для каждого устройства</p>
      </div>
      <form @submit.prevent="" class="form">
        <div class="row flex-column align-items-center g-4">
          <div class="col-14 col-md-10">
            <app-validation-errors v-if='validationErrors' :validation-errors='validationErrors'></app-validation-errors>
          </div>
          <div class="col-14 col-md-10">
            <div class="form__caption text-center maw365 pb-2">Владелец устройства</div>
            <div class="maw365">
              <autocomplete :search="search" placeholder="Введите владельца устройства" aria-label="Введите владельца устройства" :get-result-value="getResultValue" @submit="handleSubmit"></autocomplete>
            </div>
          </div>
          <div class="col-14 col-md-10" v-for="(device, i) in form.devices" :key="i" :set="$response = v$.form.devices.$each.$response">
            <div class="row g-4">
              <div class="col">
                <div class="outer-form">
                  <label class="form__label form__label--float">
                    <input :class="['form__field form__field--no-border', { 'is-error': $response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty }]" type="text" name="id" @keydown="v$.$touch()" v-model="device.value" autocomplete="off" placeholder="&nbsp;">
                    <span class="fl">Серийный номер МИ</span>
                  </label>
                  <ul class="form__error" v-if="$response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty">
                    <li v-for="error of $response.$errors[i].value" :key="error.$uid">
                      <template v-if="error.$params.type === 'required'">{{ v.errors.required }}</template>
                      <template v-if="error.$params.type === 'alphaNum'">{{ v.errors.alphaNum }}</template>
                      <template v-if="error.$params.type === 'isUnique'">Серийные номера не должны совпадать</template>
                    </li>
                  </ul>
                  <div class="row gx-4">
                    <div class="col-7">
                      <div class="text-size--small mb-2 mt-4">Дата поверки</div>
                      <date-picker prefix-class="app" placeholder="Дата поверки" format="DD.MM.YYYY" lang="ru" :editable="true" v-model="device.starts" :disabled-date="disableAfterToday"></date-picker>
                    </div>
                    <div class="col-7">
                      <div class="text-size--small mb-2 mt-4">Окончание срока поверки</div>
                      <date-picker prefix-class="app" placeholder="Окончание срока поверки" format="DD.MM.YYYY" lang="ru" :editable="true" v-model="device.ends" :disabled-date="disableExpiration"></date-picker>
                    </div>
                    <div class="col-14">
                      <div class="text-size--small mb-2 mt-4">Номер свидетельства</div>
                      <input :class="['form__field', { 'is-error': $response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty}]" type="text" name="id" @keydown="v$.$touch()" v-model="device.device_attestations" placeholder="Введите номер свидетельства" autocomplete="off">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button v-if="i === form.devices.length - 1" type="button" :disabled="form.devices.length >= limit" @click="changeNumOfDevices('add')" class="form__add-button">
                  <so-icon icon="plus-thin"></so-icon>
                </button>
                <button v-else type="button" @click="changeNumOfDevices('delete', i)" class="form__delete-button">
                  <so-icon icon="minus"></so-icon>
                </button>
              </div>
            </div>
            <ul class="form__error" v-if="$response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty">
              <li v-for="error of $response.$errors[i].device_attestations" :key="error.$uid">
                <template v-if="error.$params.type === 'required'">{{ v.errors.required }}</template>
                <template v-if="error.$params.type === 'alphaNum'">{{ v.errors.alphaNum }}</template>
                <template v-if="error.$params.type === 'isUnique'">Серийные номера не должны совпадать</template>
              </li>
            </ul>
          </div>
          <div class="col-14 col-md-10">
            <!-- <div class="form__file d-flex align-items-center" :class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }">
              <input class="d-none" type="file" name="document" @change="onFileSelected" @click="v$.$touch()" ref="fileInput" accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif">
              <div v-if="form.file" class="form__file-element d-flex align-items-center text-size--small">
                <span class="flex-shrink-0 me-3"><so-icon icon="document"></so-icon></span>
                <ul class="flex-grow-1 overflow-hidden">
                  <li class="form__file-name text-color--black">{{ form.file.name }}</li>
                  <li class="mt-1">{{ Number(form.file.size/1000000).toFixed(2) }} mb</li>
                </ul>
                <button type="button" @click="clearFileInput" class="form__file-delete flex-shrink-0 ms-3"><so-icon icon="trash"></so-icon></button>
              </div>
              <div class="text-color--blue text-size--small" v-else>Приложите скан документа</div>
              <button class="form__file-button d-flex justify-content-end align-items-center maw365" @click="$refs.fileInput.click()"><so-icon icon="clip"></so-icon></button>
            </div>
            <ul class="form__error" v-if="v$.form.file.$invalid && v$.form.$dirty">
              <li>{{ v.errors.required }}</li>
            </ul> -->
          </div>
          <div class="col-14 col-md-10 mt-5">
            <button type="submit" :disabled="v$.$invalid" @click="onSubmit" class="button button--accent form__button maw365"><span class="button__content">Отправить</span></button>
          </div>
        </div>
      </form>
    </template>
  </app-modal>
</transition>

</template>

<script>
import usersApi from '@/api/users';
import devicesApi from '@/api/devices';

import variables from '@/helpers/variables';
import useVuelidate from '@vuelidate/core';
import Autocomplete from '@trevoreyre/autocomplete-vue';

import AppLoading from '@/components/common/Loading';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import { convertDate } from '@/helpers/utils';

import { disableAfterToday, disableExpiration } from '@/helpers/datepicker';
import { helpers, required, requiredIf, alphaNum } from '@vuelidate/validators';

export default {
  name: 'AppCreateDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppLoading,
    DatePicker,
    Autocomplete,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      validationErrors: null,
      isLoading: false,
      document_id: null,
      v: variables,
      form: {
        devices: [
          {
            id: 0,
            value: '',
            device_attestations: '',
            starts: '',
            ends: '',
          },
        ],
        file: null,
        user: true,
        userId: '',
      },
      limit: 100,
    };
  },
  validations() {
    return {
      form: {
        devices: {
          $each: helpers.forEach({
            id: {},
            value: {
              required,
              alphaNum,
              isUnique: function (value) {
                if (value === '') return true;

                let count = 0;
                this.form.devices.forEach((device) => {
                  if (value === device.value) ++count;
                });
                return count < 2;
              },
            },
            device_attestations: {required},
            starts: {required},
            ends: {required},
          }),
        },
        userId: {required},
        file: {
          // required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  methods: {
    // поиск владельца устройства
    async search(input) {
      let res = await usersApi.getUsersSuggestionList(input);
      return res.data.items;
    },
    getResultValue(result) {
      this.form.company_name = result.name;
      return result.name;
    },
    handleSubmit(result) {
      this.form.userId = result.id;
    },
    // добавление устройства
    changeNumOfDevices(slug, index) {
      slug === 'add'
        ? this.form.devices.push({id: index, value: ''})
        : this.form.devices.splice(index, 1);
    },
    async onSubmit() {
      this.isLoading = true;
      // const formDataImportFile = new FormData();
      // formDataImportFile.append('file', this.form.file);
      // let result = await devicesApi.getDeviceDociment(formDataImportFile);
      if (!this.v$.$invalid) {
        const createDeviceForm = {
          devices: [],
          // document_id: result.data.document_id,
          user_id: this.form.userId,
          case_opened: false,
          device_integrity: true,
          device_verified: true,
        };
        this.form.devices.forEach((el) => {
          createDeviceForm.devices.push({
            serial: el.value,
            device_attestation: el.device_attestations,
            device_attestation_start: convertDate(el.starts),
            device_attestation_end: convertDate(el.ends),
          });
        });
        try {
          const res = await devicesApi.createDevice(createDeviceForm);
          console.log(11111)
          this.closeModal();
          console.log(22222)
          this.success();
          console.log(33333)
        } catch (error) {
          if (error.request.statusText === 'Conflict') {
            this.validationErrors = 'a_device_serial_must_be_unique';
          } else this.validationErrors = error.request.statusText;
        } finally {
          this.isLoading = false;
        }
      }
    },
    // датапикеры
    disableAfterToday(date) {
      return disableAfterToday(date, this.form.devices.ends);
    },
    disableExpiration(date) {
      return disableExpiration(date, this.form.devices.starts);
    },
    // модальное окно
    closeModal() {
      this.resetForm();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
      this.$toast.success('Устройство добавлено');
    },
    resetForm() {
      this.v$.$reset();
      this.validationErrors = null;
      this.form.devices = [{id: 0, value: ''}];
    },
    onFileSelected({ target }) {
      this.form.file = target.files[0];
    },
  },
};
</script>
