
.profile-doctor-detail
  .profile-doctor-detail__inner
    .profile-doctor-detail__header
      .row.align-items-center
        .col-14.col-md-auto.me-auto.order-last.order-md-first
          .row.align-items-center.g-4
            .col-auto
              app-back-button(:prev-page="'med-organizations'")
            .col
              app-title(:type="'large'") {{ isTouchDevice ? 'Медорганизация' : 'Страница Медорганизации' }}

    .profile-doctor-detail__content.mt-20
      transition(name="translucent")
        div(v-if="medOrganization")
          .row.align-items-center
            .col
              section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                .row.g-4.justify-content-between
                  .col
                    .text-color--blue.mb-3 Наименование
                    span(v-if="medOrganization.name") &#32;{{ medOrganization.name }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 ОГРН
                    span(v-if="medOrganization.ogrn") {{ medOrganization.ogrn }}
                    span(v-else) &#8212;

                  .col.d-none.d-md-block
                    .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeMedOrganization')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          so-icon(icon="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteMedOrganization')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          so-icon(icon="trash")

              section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                .row.g-4.justify-content-between
                  .col
                    .text-color--blue.mb-3 Лицензия
                    span(v-if="medOrganization.medical_license") {{ medOrganization.medical_license }}
                    span(v-else) &#8212;
                  .col
                    .text-color--blue.mb-3 С
                    span(v-if="medOrganization.medical_license_start") {{ $moment(medOrganization.medical_license_start).format('DD.MM.YYYY') }}
                    span(v-else) &#8212;
                  .col
                    .text-color--blue.mb-3 По
                    span(v-if="medOrganization.medical_license_end") {{ $moment(medOrganization.medical_license_end).format('DD.MM.YYYY') }}
                    span(v-else) &#8212;

                  .col.d-md-none
                    .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeMedOrganization')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          so-icon(icon="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteMedOrganization')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          so-icon(icon="trash")

          .position-relative
            template(v-if="!doctors && !users")
              .text-color--blue.text-size--small.mt-20 Нет клиентов и осмотров
            app-error-message(v-if="error" :message="error")

          // doctors tab
          so-tabs(v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex")
            so-tab(label="Медработники" name="doctors")
              app-loading(v-if="isLoading.doctors")
              template(v-if="doctors.length")
                .profile-table__row(v-for="doctor in doctors" :key="doctor.id")
                  .d-block
                    app-admin-doctor-card(
                      :data="doctor"
                      :columns="doctorsTab.columns"
                    )
                .d-flex.justify-content-center.mt-5
                  app-pagination(
                    :total="pagination.doctorsPagination.total"
                    :limit="pagination.doctorsPagination.limit"
                    :current-page="currentDoctorsPage"
                    :url="baseUrl"
                  )
              template(v-else-if="!isLoading.doctors && !doctors.length")
                .text-color--blue.text-size--small Осмотры не найдены

            so-tab(label="Клиенты" name="users")
              app-loading(v-if="isLoading.users")
              template(v-if="users && users.length")
                .profile-table
                  .profile-table__header.d-none.d-xl-block
                    .row.align-items-center.gx-4
                      div(v-for="column in usersTab.columns" :key="column.key" :class="column.class")
                        button(
                          type="button"
                          @click="sortingUserTable(column.key)"
                          class=['profile-table__sort-button']
                          :class="{'is-active': usersTab.sortKey === column.key}"
                        )
                          span.d-flex.align-items-center
                            span.text-overflow(v-if="column.caption") {{ column.caption }}
                            span(v-else-if="column.icon")
                              so-icon(:icon="column.icon")
                            span.d-flex.ms-3
                              so-icon(icon="down-arrow")
                  ul.profile-table__body
                    li.profile-table__row(v-for="user in users" :key="user.id")
                      router-link(:to="{name: 'user-detail', params: {id: user.user_id}}" class=['d-block'])
                        app-admin-user-card(:data="user")
                .d-flex.justify-content-center.mt-5
                  app-pagination(
                    :total="pagination.usersPagination.total"
                    :limit="pagination.usersPagination.limit"
                    :current-page="currentUsersPage"
                    :url="baseUrl"
                  )
              template(v-else-if="!isLoading.users && !users.length")
                .text-color--blue.text-size--small Клиенты не найдены

        template(v-else-if="!isLoading")
          .text-color--blue.text-size--small Медработника не существует в системе


    // change doctor modal
    app-change-med-organization-modal(
      v-if="medOrganization"
      :item="medOrganization"
      :is-visible="isModalVisible.changeMedOrganization"
      @close="closeModal('changeMedOrganization')"
      @success="getCurrentMedOrganization"
    )


  // delete medOrganization modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteMedOrganization" @close="closeModal('deleteMedOrganization')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить медорганизацию?
          p.text-color--blue.mt-3 {{ medOrganization.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteMedOrganization')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteMedOrganization(medOrganization)"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // success delete medOrganization modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Медорганизация успешно удалена!
          p(v-if="medOrganization.name").text-color--blue.mt-3 {{ medOrganization.name }}

        .d-flex.justify-content-center.mt-5
          button(
            @click="$router.go(-1)"
            type="button"
            class=['button button--accent']
          )
            span.button__content Назад в список
