/** File is for migrating /v1/exams to /v2/exams */

export type oldGetExamsParams = {
  filters: {
    admission_to_work: ('not_admitted' | 'admitted' | 'not_considered' | 'considered')[],
    body_temp_max: number,
    body_temp_min: number,
    bp_high_max: number,
    bp_high_min: number,
    bp_low_max: number,
    bp_low_min: number,
    case_opened: boolean,
    date_from: string,
    date_to: string,
    devices: string[],
    exam_completed: boolean,
    exam_type_id: number[],
    has_video: boolean,
    heart_rate_max: number,
    heart_rate_min: number,
    is_auto_doctor: boolean,
    location_known: boolean,
    ntp_sync: boolean,
    patients: {
      negative: boolean,
      payload: string[]
    },
    time_from: string,
    time_to: string,
    users: {
      negative: boolean,
      payload: number[]
    }
  },
  paging_params: {
    limit: number,
    offset: number
  },
  sorting_params: {
    desc: boolean,
    field: string
  }
};

export type GetExamsParams = {
  page: number // TODO offset to page
  per_page: number
  admission_to_work: 'not_admitted' | 'admitted' | 'not_considered' | 'considered'
  exams_complete: 'complete' | 'not_complete'
  exams_location: 'present' | 'not_present'
  exams_video: 'present' | 'not_present'
  patient_full_name: string
  patient_id: number
  type_id: number
  exam_datetime_lte: string
  exam_datetime_gte: string
  exams_order_by: string[]
  id: number
  doctor_id: number
  bp_low_lte: number
  bp_low_gte: number
  bp_high_lte: number
  bp_high_gte: number
  heart_rate_lte: number
  heart_rate_gte: number
  body_temp_lte: number
  body_temp_gte: number
  alcohol_lte: number
  alcohol_gte: number
  case_opened: boolean
  ntp_sync: boolean
  serial: string
  user_id: number
  company_name: string
};

export const kludgeRemakeParams = (data: oldGetExamsParams): Partial<GetExamsParams> => {
  console.log('debug', data);

  const _data = {} as GetExamsParams;

  if (data.paging_params) {
    _data.per_page = data.paging_params.limit;
  }
  if (data.filters) {
    _data.admission_to_work = data.filters.admission_to_work?.[0];
    _data.exams_complete = data.filters.exam_completed ? 'complete' : 'not_complete';
    _data.exams_location = data.filters.location_known ? 'present' : 'not_present';
    _data.exams_video = data.filters.has_video ? 'present' : 'not_present';
    _data.type_id = data.filters.exam_type_id?.[0];
    _data.exam_datetime_lte = data.filters.date_to;
    _data.exam_datetime_gte = data.filters.date_from;
    _data.bp_low_lte = data.filters.bp_low_max;
    _data.bp_low_gte = data.filters.bp_low_min;
    _data.bp_high_lte = data.filters.bp_high_max;
    _data.bp_high_gte = data.filters.bp_high_min;
    _data.heart_rate_lte = data.filters.heart_rate_max;
    _data.heart_rate_gte = data.filters.heart_rate_min;
    _data.body_temp_lte = data.filters.body_temp_max;
    _data.body_temp_gte = data.filters.body_temp_min;
    _data.serial = data.filters.devices[0];
  }
  if (data.sorting_params) {
    _data.exams_order_by = [`${data.sorting_params.desc ? "+" : "-"}${data.sorting_params.field}`];
  }

  // _data.page = data.aaaaaaaaaa;
  // _data.patient_full_name = data.aaaaaaaaaa; // ? check
  // _data.patient_id = data.aaaaaaaaaa; // ? check
  // _data.id = data.aaaaaaaaaa; // ! DO NOT TOUCH
  // _data.doctor_id = data.aaaaaaaaaa; // no
  // _data.alcohol_lte = data.aaaaaaaaaa; // no
  // _data.alcohol_gte = data.aaaaaaaaaa; // no
  // _data.case_opened = data.aaaaaaaaaa; // no
  // _data.ntp_sync = data.aaaaaaaaaa; // ! DO NOT TOUCH
  // _data.user_id = data.aaaaaaaaaa; // ? check
  // _data.company_name = data.aaaaaaaaaa; // ! DO NOT TOUCH

  // filter undefined values
  for (let key in _data) {
    const _key = key as keyof typeof _data;
    if (!_data[_key] === undefined) delete _data[_key];
  }

  return _data;
};
