
section(class=['patient-card', 'profile-card'] :class="classes")
  .row.align-items-center.g-4.gy-xl-0
    .col-1
      span.idDoc {{data.id}}
    .d-none.d-xl-block.col-2
      span(v-if="data.created_at !== null") {{$moment(data.created_at).format('DD.MM.YYYY')}}&nbsp;
      span(v-if="data.created_at !== null") {{$moment(data.created_at).format('HH:mm:ss')}}
      span(v-else) &#8212;
    .col-auto.col-2
      span(v-if="data.doc_type && data.doc_type === 'med'") Справка
      span(v-if="data.doc_type && data.doc_type === 'alco'") Направление на МО

    .col-14.col-md-2.col-xl-2
      span.word-wrap {{data.doctor_full_name}}

    .col-7.col-md-3.col-xl-3
      span.word-wrap {{data.patient_full_name}}
    .col-7.col-md-2.col-xl-4
      .d-flex.align-items-center
        .d-flex.align-items-center
          span {{data.pre_diagnosis}}

        span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='download')
          span
            img(src="@/assets/img/notice.svg" )

