<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация
      .col.col-md-auto.ms-md-auto
        transition-group(name="translucent")
          template
            button(
              v-if="outgoingMedDocs.length"
              type="button"
              key="one"
              @click="exportExamsExls()"
              class=['link--accent']
            )
              .d-flex.align-items-center(key="ones")
                span.flex-shrink-0.me-3
                  so-icon(icon="xls-doc")
                span.text-size--small Выгрузить данные .xlsx'
      .col-14
        AppNavDocs
        so-tabs(v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex")
          so-tab(label="Справки" name="med")
          so-tab(label="Направления на МО" name="alco")


  .profile-patients__content.mt-2.position-relative
    transition(name="translucent")
      div
        app-loading(v-if="isLoading" key='loading')
        app-error-message(v-if="error" :message="error")
        div(key="oneadad")
          app-table(
            v-if="outgoingMedDocs.length"
            :columns="columns"
            :sortKey="sortKey"
            @sortingRow="sortingPatients"
            :data="outgoingMedDocs"
            :paginationData="pagination"
            :currentPage="currentPage"
            @change-current-page="setCurrentPage"
            :baseUrl="baseUrl"
            :canEdit="false"
            :canRemove="false"
          )
            template(v-slot:cell='{ column, row }')
              span(v-if='column.key==="created_at"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}

              span(v-else-if='column.key==="date_birth"') {{ $moment(row[column.key]).format('DD.MM.YYYY') }}

              span(v-else-if='column.key==="doctor_full_name" && userRole === "admin"')
                router-link(
                  :to="{name: 'doctor-detail', params: {id: row.doctor_id}}"
                  class="text-decoration--underline link--accent"
                ) {{ row.doctor_full_name }}

            template(v-slot:actionTools='{row}')
              button(
                type="button"
                @click.prevent="download(row)"
              )
                img(src="@/assets/img/notice.svg")

              button(
                type="button"
                @click.prevent="openCertificate(row)"
              )
                span.doctorSign

          div(v-else)
            .text-color--blue.text-size--small Медицинская документация не найдена

  //certificate modal
  app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
  )
  // filter
  app-filter-page-outgoing(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
</template>

<script>
import outgoingMedDocs from '@/api/outgoingMedDocs';
import { mapState, mapGetters } from 'vuex';

import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppSelect from '@/components/common/Select';

import AppFilterPageOutgoing from '@/components/FilterPageOutgoing';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import NavNoticesAdmin from '@/components/profile/common/NavNoticesAdmin';
import AppNavDocs from '@/components/common/NavDocsAdmin';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import { certificateModalMixin } from '@/mixins/certificateModal';
import { modalsMixin } from '@/mixins/modals';
import { blobToFile, downloadDocs } from '@/helpers/utils';
import useVuelidate from '@vuelidate/core';
import { gettersTypes as authGettersTypes } from '@/store/modules/auth';

import { toLocalDateExam, toLocalTimeExam } from '@/helpers/utils';

import AppTable from '@/components/common/table/Table';
import SoTab from '@/components/so/tabs/SoTab';
import SoTabs from '@/components/so/tabs/SoTabs';

export default {
  name: 'PageOutgoing',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppLoading,
    AppErrorMessage,
    AppFilterPageOutgoing,
    AppPatientChangeAvatarModal,
    NavNoticesAdmin,
    AppNavDocs,
    AppCertificateModal,
    AppTable,
    SoTab,
    SoTabs,
  },
  mixins: [modalsMixin, certificateModalMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isLoading: false,
      outgoingMedDocs: {},
      doc_type: 'med',
      filterString: '',
      resultFilterForLoad: '',
      isFilterOpen: false,
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getPatients'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 15,
        total: 0,
      },
      sortKey: 'exams_data_id',
      sortInverse: true,
      columns: [
        {
          caption: '№',
          key: 'exams_data_id',
          inverse: true,
        },
        {
          caption: 'Дата и время',
          key: 'created_at',
          inverse: true,
        },
        {
          caption: 'Организация',
          key: 'company_name',
          inverse: true,
        },
        {
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          caption: 'Дата рождения',
          key: 'date_birth',
          inverse: true,
        },
        {
          caption: 'Предварительный диагноз',
          key: 'pre_diagnosis',
          inverse: true,
        },
        {
          caption: 'ФИО медработника',
          key: 'doctor_full_name',
          inverse: true,
        },
      ],
      isModalVisible: {
        changeAvatar: false,
        deleteAvatar: false,
        addPdn: false,
      },
      filter: {},
      type: 'ref',
      activeIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),

    ...mapState({
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    setup() {
      return {
        v$: useVuelidate(),
      };
    },
  },
  watch: {
    currentPage() {
      this.getPatients();
    },
  },
  created() {
    this.getPatients();
  },
  methods: {
    async getPatients() {
      let sortInverse = '';
      if (this.sortInverse) {
        sortInverse = '-';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      resultString = `doc_type=${this.doc_type}&page=${
        this.currentPage - 1
      }&per_page=10${this.filterString}&reports_order_by=${order_by}`;

      this.isLoading = true;
      let res = await outgoingMedDocs.getOutgoingMedDocuments(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.outgoingMedDocs = res.data.items;
      this.isLoading = false;
    },
    changeActiveTab(index, docType) {
      this.activeIndex = index;
      this.doc_type = docType;
      this.getPatients();
    },
    // export
    exportExamsExls() {
      let filterStringNoSmbl = this.filterString.substring(1);
      if (this.filterString.length) {
        this.resultFilterForLoad =
          `${filterStringNoSmbl}` + `&reports_order_by=-${this.sortKey}`;
      } else {
        this.resultFilterForLoad = `reports_order_by=-${this.sortKey}`;
      }
      outgoingMedDocs
        .exportOutcomingMeddocs(this.resultFilterForLoad, {})
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'))
        .catch(function (error) {
          if (error.request.status == '303') {
            console.log(error);
          }
        });
    },
    // filters
    openFilter() {
      this.isFilterOpen = true;
    },
    async submitFilterDoc(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getPatients();
    },
    close() {
      this.isFilterOpen = false;
    },
    //pagination
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getPatients();
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    // sorting
    sortingPatients(sortKey, inverse) {
      if (inverse === false) {
        return;
      }
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getPatients();
    },
    // formatting
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
    // download
    download(data) {
      downloadDocs(data, ['med_file', 'protocol_file']);
    },
  },
};
</script>

<style lang="scss" scoped>
  .tab-nav {
    background-color: white;
    padding-left: 10px;
    border-radius: 3px;
  }

</style>
