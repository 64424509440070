<template lang="pug">
  .profile-mobile-header.d-flex.align-items-center.d-xl-none
    .profile-mobile-header__user.d-flex.align-items-center(v-if="currentUser")
      router-link(:to="{name: 'dashboard'}" class=['d-block', 'me-4'])
        app-avatar(
          :classes="'large'"
          :src="currentUser.avatar"
        )
      ul.text-size--small
        li.mb-1
          span.text-color--gray-light Личный кабинет
          span.text-color--accent(v-if="userRole === 'admin'") &ensp;admin
        li.mb-1
          app-title(:routerLink="{name: 'settings'}") {{ currentUser.full_name }}
        li
          span {{ currentUser.email }}
    
    .profile-mobile-header__menu.ms-auto
      .row.flex-column.align-items-center.g-3
        .col
          button(
            @click="onLogout"
            type="button"
            class=['link--accent']
          )
            .d-flex.align-items-center
              so-icon(icon="exit")
        .col
          router-link(
            :to="{name: 'settings'}"
            active-class='is-active'
            class=['link--accent', 'd-flex align-items-center']
          )
            so-icon(icon="settings")


</template>

<script>
import {mapGetters} from 'vuex';
import {
  actionTypes as authActionTypes,
  gettersTypes as authGettersTypes,
} from '@/store/modules/auth';

import AppTitle from '@/components/common/Title';
import AppAvatar from '@/components/profile/common/Avatar';

export default {
  name: 'AppMobileHeader',
  components: {
    AppAvatar,
    
    AppTitle,
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
      userRole: authGettersTypes.userRole,
    }),
  },
  methods: {
    onLogout() {
      this.$store.dispatch(authActionTypes.logout).then(() => {
        this.$router.push({name: 'home'});
      });
    },
  },
};
</script>

<style lang="scss">
.profile-mobile-header {
  padding-top: $spacer * 2;

  &__menu {
    svg {
      width: $spacer * 2.4;
      height: $spacer * 2.4;
      fill: get($colors, blue);
    }
  }
}
</style>
