<template>
  <button
  :class="['so-btn', ...classes]"
  :disabled="disabled"
  @click.stop.prevent="handleClick"
  >
    <div class="so-btn__content">
      <img src="/esia.svg" class="so-btn__icon"/>
      Войти через Госуслуги
    </div>
  </button>
</template>

<script lang='ts'>
import type { PropType } from 'vue';
import { computed } from 'vue';

export type ButtonSize = 'large';

export default {
  name: 'so-button-esia',
  props: {
    size: {
      type: String as PropType<ButtonSize>,
    },
    disabled:  Boolean,
  },
  emits: ['on-click'],
  setup(props, { emit }) {
    /** Sets dynamic classes to a root element */
    const classes = computed(() => {
      return `so-btn__${props.size}`;
    });

    /** On button click - emits custom event */
    function handleClick() {
      emit('on-click');
    }

    return {
      classes,
      handleClick
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  font: inherit;
  color: inherit;

  &[type="button"], &[type="submit"] {
    &:focus {
      outline: none;
    }
  }
}

$block: 'so-btn';

.#{$block} {
  @include transition();
  @include font-size(get($text-sizes, middle));
  display: inline-block;
  user-select: none;
  position: relative;
  padding: 0.5rem 1.875rem;
  min-width: $spacer * 37;
  width: 100%;
  text-align: center;
  letter-spacing: get($letter-spacing, default);
  background-color: get($colors, white);
  color: get($colors,  esia);
  border: 1px solid get($colors,  esia);
  border-radius: $border-radius-sm;
  text-transform: none;

  &[disabled] {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }

  @include mediaToDown($md) {
    padding: $spacer;
  }

  &:before {
    @include pseudo-wrapper();
    @include transition();
    opacity: 0;
    visibility: hidden;
    border-radius: $border-radius-sm;
  }

  &__content {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: get($index, default);
    gap: 10px;

    & .#{$block}__icon {
      width: 22px;
      height: 22px;
      flex: none;
    }
  }
  @include mediaToDown($md) {
    min-width: 100%;
    padding: $spacer * 1.6;
  }
}

.so-btn__large {
  padding: 0.9rem;
  min-width: $spacer * 50;

  @include mediaToDown($xl) {
    min-width: 100%;
    width: 100%;
  }

  @include mediaToDown($md) {
    padding: $spacer * 1.4;
  }
}
</style>
