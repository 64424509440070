
app-accordion(
  :id="String(data.exams_id || data.id)"
  :parent-id="'patient-exams'"
  :class="'patient-exam-card'"
  :withoutBtn="true"
)
  template(v-slot:header)
    .patient-exam-card__header
    .col-14.d-flex.align-items-center
      app-status(:type="'exam'" :data="data" :caption="false")
      div(v-for="column in columns" :key="column.key" :class="column.class")
        span(v-if="column.key === 'exam_number'").text-color--blue.d-block.mb-3.text-center {{ column.caption }}
        span(v-else).text-color--blue.d-block.mb-3 {{ column.caption }}

        div(v-if="column.caption === 'Результаты осмотра'").d-flex
          .col.d-inline.me-2
            span.flex-shrink-0.me-2
              so-icon(icon="pulse")
            span.text-color--blue-dark(v-if="data.bp_high && data.bp_low")
              span(:class="{yellowText: data.bp_high_alarm}") {{ data.bp_high }} /
              span(:class="{yellowText: data.bp_low_alarm}") {{ data.bp_low }}
            span.text-color--blue(v-else) &#8212;

          .col.d-inline.me-2
            span.flex-shrink-0.me-2
              so-icon(icon="cardio")
            span.text-color--blue-dark(v-if="data.heart_rate")
              span(v-if="data.heart_rate" :class="{yellowText: data.heart_rate_alarm}") {{ data.heart_rate }}&nbsp;уд&#65279;/&#65279;мин
            span.text-color--blue(v-else) &#8212;

          .col.d-inline.me-2
              span.flex-shrink-0.me-2
                so-icon(icon="temp")
              span.text-color--blue-dark(v-if="data.body_temp")
                span(v-if="data.body_temp" :class="{yellowText: data.body_temp_alarm}") {{ data.body_temp + "\u2103" }};
              span.text-color--blue(v-else) &#8212;

          .col.d-inline.me-2
            span.flex-shrink-0.me-2.text-color--blue-dark
              so-icon(icon="breathalyzer")
            span.text-color--blue-dark(v-if="data.alcohol >= 0 && data.alcohol !== null" :class='{yellowText: data.alcohol_alarm}') {{ alcoFormatted(data.alcohol) }}&nbsp;мг&#65279;/&#65279;л
            span.text-color--blue-dark(v-else) &#8212;


        div(v-if="column.key === 'health_complaint'")
          app-tooltip(
            v-if="data.health_complaint"
            :content='`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--blue-dark\">${data.health_complaint}<p>`'
            :icon="'comment'"
          )

        div(v-if="column.caption === 'Допуск'")
          .col.me-2
            app-status(:type="'card'" :data="data" :caption="true")
        span.text-color--blue-dark {{ column.result }}

  template(v-slot:body)
    .patient-exam-card__content
      .patient-exam-card__content-info
        .patient-exam-card__content-info-item.py-20
          .row.g-4
            .col-3
              .text-color--blue.mb-2 Подпись и e-mail
              .d-flex.align-items-center.text-wrap
                app-signature(
                  :sample="data_detail.signature_sample"
                  :instance='data_detail.signature'
                  :theme="'shadow'"
                  :classes="'small'"
                  @openGallery="openGallery"
                )
                ul.ms-20
                  li {{ data_detail.email ? data_detail.email : data_detail.patient_login }}

            .col-2
              .text-color--blue.mb-2 Координаты
              span(v-if="data_detail.geo_location_lat >= 0 && data_detail.geo_location_long >= 0") {{ trimNum(data_detail.geo_location_lat) }}, {{ trimNum(data_detail.geo_location_long) }}
              span(v-else) &#8212;

            .col-2
              .text-color--blue.mb-2 Тип осмотра
              span(v-if="data_detail.exam_type_name || data_detail.type_name") {{ data_detail.exam_type_name || data_detail.type_name }}
              span(v-else) &#8212;

            .col-2
              .text-color--blue.mb-2 Статус
              span(v-if="data.exam_completed === true") Завершен
              span(v-else-if="data.exam_completed === false").text-color--orange Не завершен
              span(v-else) &#8212;

            .col-2.col-sl-1
              template
                .text-color--blue.mb-2 Медработник
                .d-flex(v-if="userRole === 'admin' && data_detail.doctor_full_name")
                  router-link(
                    :to="{name: 'doctor-detail', params: {id: data_detail.doctor_id}}"
                    class="text-decoration--underline link--accent"
                  ) {{ data_detail.doctor_full_name }}
                span(v-else-if="data_detail.doctor_full_name") {{ data_detail.doctor_full_name }}
                span(v-else) &#8212;

            .col-1
              template(v-if="data_detail.doctor_id")
                .text-color--blue.mb-2.d-flex.flex-column &nbsp;
                  span.doctorSign(type="button" @click.prevent="openCertificate(data_detail)")

            .col-3
              .text-color--blue.mb-2 Причины отказа
              span(v-if="data_detail.exam_comment && data_detail.exam_comment !== 'undefined' || data_detail.reasons_array && data_detail.exam_comment !== 'undefined' ") {{data_detail.exam_comment}}
                span.text-wrap(v-if="data_detail.reasons_array")
                    ul(v-for="reason in data_detail.reasons_array")
                      li {{reason}}
              span(v-else) &#8212;
            .col-14
              hr.mt-0.mb-0
            .col-3
              .text-color--blue.mb-2 Серийный номер МИ
              span(v-if="data_detail.device_serial") {{ data_detail.device_serial }}
              span(v-else) &#8212;
            .col-2
              .text-color--blue.mb-2 Целостность корпуса МИ
              template(v-if="data_detail.case_opened")
                .d-flex.align-items-center
                  span.text-color--danger.me-2 Вскрыт
                  span.d-flex
                    so-icon(icon="attention")
              template(v-else)
                span Не вскрыт
            .col-2
              .text-color--blue.mb-2 Контроль целостности ПО
              span(v-if="data_detail.device_integrity !== null")
                span(v-if='data_detail.device_integrity') Пройден
                span.text-color--orange(v-else) Не пройден
              span(v-else) &#8212;
            .col-2
              .text-color--blue.mb-2 Температура
              span(v-if='data_detail.area_temp') {{data_detail.area_temp + "\u2103" }}
              span(v-else) &#8212;
            .col-1
              .text-color--blue.mb-2 Влажность
              span(v-if='data_detail.area_humidity') {{data_detail.area_humidity + `%` }}
              span(v-else) &#8212;
            .col-2
              .text-color--blue.mb-2 Освещенность
              span(v-if='data_detail.lux') {{data_detail.lux + 'Лк' }}
              span(v-else) &#8212;
            .col-auto
              .text-color--blue.mb-2 Поверка СИ
              span(v-if='data_detail.device_verified') Поверено
              span(v-else).text-color--orange Не поверено

