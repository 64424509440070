
section(
  class=['exam-card']
  :class="classes"
  @click="onOpen"
)
  app-accordion(
    :id="data.exams_id"
    :parent-id="parentId"
    :classes="'profile-card'"
  )
    template(v-slot:header)
      .exam-card__header
        .row.align-items-center.g-4
          div(v-for="column in columns" :key="column.key" :class="column.class")

            template(v-if="column.key === 'admission_datetime'")
              span {{ getDateAndtime(data.admission_datetime) }}

            template(v-else-if="column.key === 'exam_datetime' || column.key === 'exam_date_time'")
              span {{ getDateAndtime(data.exam_datetime) }}

            template(v-else-if="column.key === 'patient_full_name' || column.key === 'patient_code'")
              span(v-if="data.patient_full_name") {{ data.patient_full_name }}
              span(v-else) &#8212;

            template(v-else-if="column.key === 'lat'")
              span(v-if="data[column.key] && data.long") {{ data[column.key] }}, {{ data.long }}
              span(v-else) &#8212;

            template(v-else-if="column.key === 'bp_high'")
              span(v-if="data[column.key] && data.bp_low")
                span(:class='{yellowText: data.bp_high_alarm}') {{ data[column.key] }}/
                span(:class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
              span(v-else) &#8212;

            template(v-else-if="column.key === 'heart_rate'")
              span(v-if="data[column.key]" :class='{yellowText: data.heart_rate_alarm}') {{ data[column.key] }}&nbsp;уд&#65279;/&#65279;мин
              span(v-else) &#8212;

            template(v-else-if="column.key === 'body_temp'")
              span(:class='{yellowText: data.body_temp_alarm}' v-if="data[column.key]") {{ data[column.key] + "\u2103"}};
              span(v-else) &#8212;

            template(v-else-if="column.key === 'alcohol'")
              span(v-if="data[column.key] || data[column.key] === 0")
                span(:class='{yellowText: data.alcohol_alarm}') {{ alcoFormatted(data[column.key])}}&nbsp;мг&#65279;/&#65279;л
              span(v-else) &#8212;

            template(v-else-if="column.key === 'health_complaint'")
              app-tooltip(
                v-if="data[column.key]"
                :content="`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--black\">${data[column.key]}<p>`"
                :icon="'comment'"
                classes='text-color--blue'
              )

            template(v-else-if="column.key === 'admission_to_work'")
              .exam-card__status.d-flex
                app-status(:type="'exam'" :data="data" :id="data.exams_id")

            template(v-else)
              span(v-if="data[column.key]") {{ data[column.key] }}
              span(v-else) &#8212;

    template(v-slot:body)
      .exam-card__content
        .exam-card__content-info
          .exam-card__content-info-item.pt-20.pb-4(v-if="userRole === 'admin' && type !== 'device-detail'")
            .row
              .col(v-if="type !== 'user-detail'")
                span.mb-3 Владелец устройства:&ensp;
                span(v-if="data_detail.device_owner_full_name") {{ data_detail.device_owner_full_name }}

          .exam-card__content-info-item.py-20
            .row.g-4
              .col-2
                .text-color--blue.mb-2 Подпись и e-mail
                .d-flex.align-items-center.text-wrap
                  app-signature(
                    v-if="data_detail.signature"
                    :instance='data_detail.signature'
                    :theme="'shadow'"
                    :classes="'small'"
                    @openGallery="openGallery"
                  )
                  ul.ms-20
                    li(v-if="data_detail.email || data_detail.patient_login") {{ data_detail.email ? data_detail.email : data_detail.patient_login }}
                    li(v-else) &#8212;

              .col-2
                .text-color--blue.mb-2 Координаты
                span(v-if="data_detail.geo_location_lat >= 0 && data_detail.geo_location_long >= 0") {{ trimNum(data_detail.geo_location_lat) }}, {{ trimNum(data_detail.geo_location_long) }}
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Тип осмотра
                span(v-if="data_detail.exam_type_name") {{ data_detail.exam_type_name }}
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Жалобы
                ul(v-if='data_detail.health_complaint')
                  li {{ data_detail.health_complaint }}
                span(v-else) &#8212;
              .col-1
                .text-color--blue.mb-2 Статус
                span(v-if="data_detail.exam_completed === true") Завершен
                span(v-else-if="data_detail.exam_completed === false").text-color--orange Не завершен
                span(v-else) &#8212;
              .col-2
                template
                  .text-color--blue.mb-2 Медработник
                  .d-flex(v-if="userRole === 'admin' && data_detail.doctor_full_name")
                    router-link(
                      :to="{name: 'doctor-detail', params: {id: data_detail.doctor_id}}"
                      class="text-decoration--underline link--accent"
                    ) {{ data_detail.doctor_full_name }}
                  span(v-else-if="data_detail.doctor_full_name") {{ data_detail.doctor_full_name }}
                  span(v-else) &#8212;
              .col-1
                template(v-if="data_detail.doctor_id")
                  .text-color--blue.mb-2.d-flex.flex-column &nbsp;
                    span.doctorSign(type="button" @click.prevent="openCertificate(data_detail)")
              .col-2
                .text-color--blue.mb-2 Причины отказа
                span(v-if="data_detail.exam_comment && data_detail.exam_comment !== 'undefined' || data_detail.reasons_array && data_detail.exam_comment !== 'undefined'  ") {{data_detail.exam_comment}}
                  span.text-wrap(v-if="data_detail.reasons_array")
                      ul(v-for="reason in data_detail.reasons_array")
                        li {{reason}}
                span(v-else) &#8212;

              .col-14
                hr.mt-0.mb-0
              .col-2
                .text-color--blue.mb-2 Серийный номер МИ
                span(v-if="data_detail.device_serial") {{ data_detail.device_serial }}
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Целостность корпуса МИ
                template(v-if="data_detail.case_opened")
                  .d-flex.align-items-center.text-color--danger
                    span.text-color--orange.me-2 Вскрыт
                    span.d-flex
                      so-icon(icon="attention")
                template(v-else)
                  span Не вскрыт
              .col-2
                .text-color--blue.mb-2 Контроль целостности ПО
                span(v-if="data_detail.device_integrity !== null")
                  span(v-if='data_detail.device_integrity') Пройден
                  span.text-color--orange(v-else) Не пройден
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Температура
                span(v-if='data_detail.area_temp >= 0') {{data_detail.area_temp + "\u2103" }}
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Влажность
                span(v-if='data_detail.area_humidity') {{data_detail.area_humidity + `%` }}
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Освещенность
                span(v-if='data_detail.lux') {{data_detail.lux + `Лк` }}
                span(v-else) &#8212;
              .col-2
                .text-color--blue.mb-2 Поверка СИ
                span(v-if='data_detail.device_verified') Поверено
                span(v-else).text-color--orange Не поверено
        .exam-card__content-footer.py-4
          .row.align-items-center.g-4.justify-content-between
            .col-auto
              .row.align-items-center
                .col-auto(v-if="data_detail.patient_code && $route.name !== 'doctor-card-revieved' && $route.name !== 'exam_queue'")
                  router-link(
                    :to="{name: 'patient-detail', params: {id: data_detail.patient_code}}"
                    class=['d-flex align-items-center', 'link--accent']
                  )
                    span.me-2
                      so-icon(icon="search")
                    span Поиск по пользователю

                .col-auto(v-if="userRole === 'admin' && type !== 'user-detail' && data_detail.device_owner_id && $route.name !== 'user-detail'")
                  router-link(
                    :to="{name: 'user-detail', params: {id: data_detail.device_owner_id}}"
                    class=['d-flex align-items-center', 'link--accent']
                  )
                    span.me-2
                      so-icon(icon="search")
                    span Поиск по владельцу

                .col-auto(v-if="data_detail && data_detail.exam_video")
                  button(
                    type="button"
                    @click="openModal('video', newData)"
                    class=['link--accent']
                  )
                    span.d-flex.align-items-center
                      span.flex-shrink-0.d-flex.me-2
                        so-icon(icon="video")
                      span {{ userRole === 'admin' ? 'Видеозапись осмотра' : 'Просмотреть видеозапись' }}

                .col-auto(v-if="userRole === 'admin' && data_detail.geo_location_lat >= 0 && data_detail.geo_location_long >= 0")
                  button(
                    type="button"
                    @click="type === 'map' ? showToMap(data) : openModal('map', newData)"
                    class=['link--accent']
                  )
                    span.d-flex.align-items-center
                      span.flex-shrink-0.d-flex.me-2
                        so-icon(icon="map-point")
                      span {{ userRole === 'admin' ? 'На карте' : 'Посмотреть на карте' }}

                .col-auto(v-if="userRole === 'admin'")
                  button(
                    type="button"
                    @click="printExam(data_detail)"
                    class=['link--accent']
                  )
                    span.d-flex.align-items-center
                      span.flex-shrink-0.d-flex.me-2
                        so-icon(icon="printer")
                      span Печать талона

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.isPrinted" @close="closeModal")
      template(v-slot:body)
        div.text-center.pt-4
          app-title(:type="'large'") {{ printErrorMessage.title }}
          p.text-size--medium.text-color--blue-dark.mt-3 {{ printErrorMessage.subtitle }}

        div.row.justify-content-center.mt-5
          div.col-14.col-md-7
            button(@click="closeModal" type="button" class=['button button--accent', 'w-100'])
              span.button__content Назад

