<template lang="pug">
.serverInformation
  .serverInformation__header
    .row.align-items-center
      .col-14.col-md-12
        .d-flex.align-items-center.mb-5
          app-title(:type="'large'") Настройки системы. Информация о сервере
    form(@submit.prevent :class="['form']")
      app-system-setup-input(
        :title="'Загрузите логотип компании для верхнего колонтитула'"
        :subtitle="'Загрузите логотип в формате  *.jpg, *.svg, *.png'"
        :type="'file'"
        :typeOfProject="'LOGO_PROJECT'"
        :linkOfProject="'S3_LINK'")
      app-system-setup-input(
        :title="'Загрузите логотип компании для мобильной версии'"
        :subtitle="'Загрузите логотип в формате  *.jpg, *.svg, *.png'"
        :type="'file'"
        :typeOfProject="'LOGO_MOBILE_PROJECT'"
        :linkOfProject="'S3_LINK'")
      app-system-setup-input(
        :title="'Загрузите фавикон для вкладки браузера'"
        :subtitle="'Загрузите фавикон в формате  *.iso'"
        :type="'file'"
        :typeOfProject="'FAVICON_PROJECT'"
        :linkOfProject="'S3_LINK'")


      //НАЗВАНИЕ ВКЛАДКИ
    form(@submit.prevent :class="['form']")
      .row.align-items-center
        .col-8.mb-4
          app-title(:type="'normal'") Введите текст для названия вкладки
        .row.align-items-center
          .col-8.mb-4
            .form__file.d-flex.align-items-center.p-0
              input(
                class="form__field"
                type="text"
                name="title"
                v-model="form.title"
                placeholder="Название вкладки"
                autocomplete="off"
              )
          .col-8.mb-4
            button(
              :disabled="!this.form.title"
              type="submit"
              class=['button', 'button--accent', 'form__button']
              @click="onSubmitTitle"
            )
              span.button__content Загрузить
        .col-2.align-items-center
          img(:src='imageSrc' :alt="imageAlt")
</template>

<script>
import settingsApi from '@/api/settings';

import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppSystemSetupInput from '@/components/profile/admin/system_setup/SystemSetupInput';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters, mapState} from 'vuex';

import vueHeadful from 'vue-headful';

export default {
  name: 'SystemSetupServerInformation',
  components: {
    AppTitle,
    AppMultipleSelect,
    
    vueHeadful,
    AppSystemSetupInput,
  },
  data() {
    return {
      user: null,
      imageSrc: '',
      imageAlt: '',
      form: {
        fileToRead: null,
        title: '',
      },
      linkToUpload: '',
      fileToUpload: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.settings.isLoading,
    }),
  },
  methods: {
    async onSubmitTitle() {
      await settingsApi.createProjectSetup({
        type_name: 'NAME_PROJECT',
        type_value: 'STR',
        value: this.form.title,
      });
      this.form.title = '';
      location.reload();
    },
  },
};
</script>

<style></style>
