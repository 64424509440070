<template lang="pug">
.inspection-card__patient-avatar
  .innerExamDetailDoctor__ava
    app-avatar(
      :localUrl="avatarSource.source"
      :viewPhoto="true"
      @openGallery="openGallery"
      :classes="'square extra-large'"
      :archivedPhoto="avatarSource.archived"
    )
    span
      so-icon(v-if="avatarSource.archived" icon="attention-yellow")
</template>

<script>
import AppAvatar from '@/components/profile/common/Avatar';
import AppModal from '@/components/common/Modal';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'DoctorExamCardAvatar',
  components: {
    AppAvatar,
    AppModal,
    
  },
  mixins: [galleryMixin],
  props: {
    avatarSource: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openGallery(data) {
      this.$emit('openGallery', data);
    },
  },
};
</script>

<style lang="scss">
.innerExamDetailDoctor__ava {
  position: relative;
  span {
    position: absolute;
    left: 20px;
    top: 20px;
  }
}
</style>
