import axios from '@/api/axios';

// V1
const getUsers = (credentials) => axios.post('/api/v1/user', credentials);
const getCountUsers = (credentials) => axios.post('/api/v1/user/count', credentials);
// const changeUserEmail = (credentials) => axios.post('/api/v1/user/changeEmail', credentials);
// const changeUserNames = (credentials) => axios.post('/api/v1/user/changeNames', credentials);
// const changeUserAvatar = (credentials) => axios.post('/api/v1/user/setAvatar', credentials);
const forgotPassword = (credentials) => axios.post('/api/v1/user/forgotPassword', credentials);
const deleteUser = (id) => axios.delete(`/api/v1/user/${id}`);

// V2
const changeUserAvatar = (user_id, credentials) => axios.post(`/api/v2/users/set_avatar/${user_id}`, credentials);
const changeUserEmail = (user_id, credentials) => axios.put(`/api/v2/users/${user_id}`, credentials);
const changeUserNames = (user_id, credentials) => axios.put(`/api/v2/users/${user_id}`, credentials);
const changeUserPassword = (user_id, credentials) => axios.put(`/api/v2/users/${user_id}`, credentials);

const getUsersNew = (credentials, additionals) => axios.get(`/api/v2/users${credentials}`, { params: additionals });
const getUsersSuggestionList = (company_name) => axios.get(`/api/v2/users/suggestion?page=0&per_page=10&name=${company_name}`);
const getCurrentUser = (user_id) => axios.get(`/api/v2/users/${user_id}`);
const createUser = (credentials) => axios.post('/api/v2/users', credentials);
const getUsersExams = (user_id) => axios.get(`/api/v2/users/${user_id}/exams`);
const getUserDevices = (user_id) => axios.get(`/api/v2/users/${user_id}/devices`);

export default {
  getUsers,
  getUsersNew,
  getCountUsers,
  changeUserEmail,
  changeUserNames,
  changeUserPassword,
  changeUserAvatar,
  forgotPassword,
  deleteUser,
  getUsersSuggestionList,
  getCurrentUser,
  createUser,
  getUsersExams,
  getUserDevices,
};
