
.profile-signature(:class="[theme && `${theme}-theme`, classes]")
  button(
    v-if="localUrl || sample || instance"
    type="button"
    @click.prevent="openGallery"
    class=['profile-signature__button', 'd-flex align-items-center justify-content-center']
  )
    img(:src="signatureUrl")

  button(
    v-else
    type="button"
    @click.prevent="changeSignature"
    class=['profile-signature__button', 'd-flex align-items-center justify-content-center', 'link--accent', 'text-color--blue text-size--extra-small text-decoration--underline']
  )
    span Подпись

