// import { apiCall, type RequestParams } from './helpers/axios';

import axios from 'axios';

// const getRisksGroup = (credentials) => apiCall('get', '/api/v2/risk_group/export_xlsx', credentials, {responseType: 'blob'});
// const exportRisksGroup = (credentials: RequestParams<'/api/v2/risk_groups/export', 'get'>) => apiCall(
//   'get',
//   '/api/v2/risk_groups/export',
//   credentials,
//   {responseType: 'blob'}
// );
const exportRisksGroup = (params: any) => axios.get(
  '/api/v2/risk_groups/export',
  { params }
);

export default {
  // getRisksGroup,
  exportRisksGroup
};
