
.profile-users
  .profile-exams__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-users__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(
              :type="'large'"
              :classes="'text-center mb-3 mb-md-0'"
            ) Просмотр клиентов

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              @click="openCreateClientModal"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  so-icon(icon="plus-thin")
                span Добавить клиента

    .profile-users__content.mt-20.position-relative
      app-error-message(v-if="error" :message="error")
      transition(name="translucent")
        div(v-if="users")
          template(v-if="users && users.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingUsers(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key && column.key !== 'exam_count'}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(v-if="column.caption") {{ column.caption }}
                        span(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.d-flex.ms-3
                          so-icon(icon="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="user in users")
                  router-link(:to="{name: 'user-detail', params: {id: user.id}}" class=['d-block'])
                    app-admin-user-card(:data="user")

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Клиенты не найдены


    // filter
    app-admin-user-filter(
      ref="filter"
      :is-open="isFilterOpen"
      @open="openFilter"
      @close="close"
      @clear="close"
      @submit="submitFilter"
    )

    // create doctor modal
    app-create-client-modal(
      :close="closeClientCreateModal"
      :isVisible="isVisibleForm"
      @success="getUsers(pagination.limit, 0)"
    )

