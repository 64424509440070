<template lang="pug">
  section.map-device-card.profile-card(:class="{'is-inactive': isInactive}")
    .map-device-card__title.d-flex.align-items-center.mb-4
      app-loading(v-if="!trigger")
      
      span.d-flex.me-3(:style="{'color': data.color}")
        so-icon(icon="map-marker")
      span.text-color--black.text-size--normal Устройство {{ data.serial }}
      
    .map-device-card__exams-slider(:class="`js-exams-slider-${data.serial}`")
      .map-device-card__exams-slider-pagination.mb-20
        swiper(
          ref="days"
          :options="slider.daysOptions"
          :auto-update="true"
          :auto-destroy="false"
        )
          swiper-slide(v-for="(day, index) in data.days" :key="day.date")
            span(
              :data-key="day.date"
              @click="changeDate(day.date, index)"
              :class="{'is-active': day.date === currentDay}"
              class="map-device-card__exams-slider-pagination-item"
            ) {{ day.date }}
          
        .map-device-card__exams-slider-prev-btn.js-days-prev
          so-icon(icon="left-arrow")
        .map-device-card__exams-slider-next-btn.js-days-next
          so-icon(icon="right-arrow")
        
      swiper.d-flex.flex-column-reverse(
        ref="exams"
        :options="slider.examsOptions"
        :auto-update="true"
        :auto-destroy="false"
      )
        swiper-slide(v-for="day in data.days" :key="day.date" :data-key="day.date")
          simplebar.map-device-card__exams-list(data-simplebar-auto-hide="false")
            ul.list-circle
              li.mb-3(v-for="exam in day.exams" :key="exam.id")
                span.me-3 {{ exam.exam_time }}
                span {{ exam.lat }}, {{ exam.long }}

</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Navigation, EffectFade} from 'swiper';

import simplebar from 'simplebar-vue';
import AppLoading from '@/components/common/Loading';

SwiperCore.use([Navigation, EffectFade]);

export default {
  name: 'AppMapDeviceCard',
  components: {
    AppLoading,
    
    Swiper,
    SwiperSlide,
    simplebar,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    currentDay: {
      type: String,
      required: false,
      default: null,
    },
    trigger: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
  },
  data() {
    const self = this;

    return {
      slider: {
        speed: 100,
        daysOptions: {
          slidesPerView: 3,
          spaceBetween: 10,
          navigation: {
            prevEl: `.js-exams-slider-${self.data.serial} .js-days-prev`,
            nextEl: `.js-exams-slider-${self.data.serial} .js-days-next`,
            disabledClass: 'is-disabled',
          },
          observer: true,
          observeParents: true,
          breakpoints: {
            1200: {
              slidesPerView: 4,
            },
          },
        },
        examsOptions: {
          slidesPerView: 1,
          simulateTouch: false,
          autoHeight: true,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          observer: true,
          observeParents: true,
        },
      },
      isInactive: false,
    };
  },
  computed: {
    examsSwiper() {
      return this.$refs.exams.$swiper;
    },
    daysSwiper() {
      return this.$refs.days.$swiper;
    },
  },
  watch: {
    trigger(value) {
      if (value) {
        this.examsSwiper.init();
        this.daysSwiper.init();
      }
    },
    currentDay(value) {
      this.isInactive = true;

      this.examsSwiper.slides.forEach((el, index) => {
        if (el.dataset.key === value) {
          this.examsSwiper.slideTo(index, this.slider.speed);
          this.daysSwiper.slideTo(index, this.slider.speed);
          this.isInactive = false;
        }
      });
    },
  },
  methods: {
    changeDate(date) {
      if (this.currentDay !== date) this.$emit('changeDate', date);
    },
  },
};
</script>

<style lang="scss">
.map-device-card {
  width: 100%;
  height: 100%;
  border-radius: calc($border-radius-lg / 1.25);
  box-shadow: get($shadows, default);
  @include transition();

  &:before {
    display: none;
  }
  &.is-inactive {
    opacity: 0.6;
    box-shadow: none;

    @include interaction('mouse') {
      &:hover {
        opacity: 1;
        box-shadow: get($shadows, default);
      }
    }
  }
  @include mediaToDown($md) {
    padding: $spacer * 1.6;

    &.is-inactive {
      opacity: 1;
      box-shadow: get($shadows, default);
    }
  }
  &__title {
    svg {
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
  }
  &__exams {
    &-slider {
      width: 100%;
      position: relative;

      &-prev-btn,
      &-next-btn {
        position: absolute;
        width: $spacer * 1.6;
        height: $spacer * 1.6;
        z-index: get($index, xs);
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition();

        &.is-disabled {
          opacity: 0;
        }
        svg {
          width: $spacer;
          height: $spacer;
        }
      }
      &-prev-btn {
        left: 0;
        justify-content: flex-start;
      }
      &-next-btn {
        right: 0;
        justify-content: flex-end;
      }
      &-pagination {
        position: relative;
        padding: 0 $spacer * 1.6;

        &-item {
          cursor: pointer;

          &.is-active {
            color: get($colors, orange);
          }
        }
      }
    }
    &-list {
      max-height: $spacer * 15;
    }
  }
}
</style>
