import axios from '@/api/axios';
// V1

const createIncomingMedDocument = (credentials) => axios.post('/api/v1/incoming_med_document/create', credentials);
const archiveIncomingMedDocument = (med_doc_id, credentials) => axios.post(`/api/v1/incoming_med_document/archived/${med_doc_id}`, credentials);

// V2
const acceptIncomingMedDocument = (med_doc_id, credentials) => axios.post(`/api/v2/med_doc/accept/${med_doc_id}/`, credentials);

// необходимо разобраться в компоненте PageNotices.vue - почему тут используются обе ручки
const getIncomingMedDocument = (credentials) => axios.post('/api/v1/incoming_med_document', credentials);
const getIncomingMedDocumentV2 = (credentials) => axios.get(`/api/v2/med_doc/incoming_document_list?${credentials}`);


export default {
  createIncomingMedDocument,
  archiveIncomingMedDocument,
  acceptIncomingMedDocument,
  getIncomingMedDocument,
  getIncomingMedDocumentV2,
};
