import medOrganizations, {
  type SaveMedOrganizationParams,
  type GetMedOrganizationsParams
} from '@/api/medOrganizations';
import { CoreModel } from '@/store/model/model'

export class MedOrganization extends CoreModel {
  // TODO
  // static $fields() {

  // }

  _get(params: GetMedOrganizationsParams) {
    return medOrganizations
      .getMedOrganizations(params);
  }

  _store(params: SaveMedOrganizationParams) {
    return medOrganizations
      .createMedOrganization(params);
  }
}
