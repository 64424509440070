<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(v-if="isVisible" @close="close" :classes="`largeNotices`")
        template(v-slot:body)
          a.downloadNotice(href='#')
          .text-center.mb-5
            app-title(:type="'large'") Извещение №145648
            p.text-size--large.mt-3 от 15.08.2022, 16:34:00

          .row.justify-content-center.px-5
            .col-14 <hr> 
             .mt-4.text-black Контроль трезвости водителя автотранспортного средства 
            .col-7
             .text-size--large.text-uppercase.mt-3.text-black Логвиненко Петр Петрович
            .col-4
             .text-size--large.text-uppercase.mt-3.text-black 24.04.1996 
            .col-3
             .text-size--large.text-uppercase.mt-3.text-black ООО «Автотур»
            .col-14.mt-3 <hr>
            .col-14
             .row.mb-4.mt-3
              .col-4
               .textNoticesLbl Место проведения
              .col-10 
               .textNoticesDesc г.Ростов-на-Дону, 546456778979 : 79879879877
             .row.mb-4
              .col-4
               .textNoticesLbl Дата и время осмотра
              .col-10 
               .textNoticesDesc 15.06.2022    15:50:32
             .row.mb-4
              .col-4
               .textNoticesLbl Тип
              .col-10 
               .textNoticesDesc  Предрейсовый
             .row.mb-4
              .col-4
               .textNoticesLbl  Жалобы
              .col-10 
               .textNoticesDesc  Кружится голова и болит зуб
             .row.mb-4
              .col-4
               .textNoticesLbl Пульс
              .col-10 
               .textNoticesDesc 80 уд/мин
             .row.mb-4
              .col-4
               .textNoticesLbl Давление
              .col-10 
               .textNoticesDesc  140/65 мм рт.ст.
             .row.mb-4
              .col-4
               .textNoticesLbl Алкоголь (Алкометр)
              .col-10 
               .textNoticesDesc 0.2 мг/л
             .col-14.mt-2
              hr
              .noticeHeaderBlock.pt-2 Данные устройства   
             .row.gx-2
              .col-3
               .text-color--blue.mb-1.text-size--small Серийный номер МИ
               span.text-color--blue-dark.text-size--small 455213066000045
              .col-2
               .text-color--blue.mb-1.text-size--small Целостность корпуса МИ
               span.text-color--blue-dark.text-size--small Не вскрыт
              .col-2
               .text-color--blue.mb-1.text-size--small Контроль целостности ПО
               span.text-color--blue-dark.text-size--small  Пройден
              .col-3
               .text-color--blue.mb-1.text-size--small Поверка СИ
               span.text-color--blue-dark.text-size--small Поверено
              .col-4
               .text-color--blue.mb-1.text-size--small Номер свидетельства 
               span.text-color--blue-dark.text-size--small С-АБЭ/15-08-2022/178554681	
              .col-14.mt-3
                <hr>
                .noticeHeaderBlock.mt-4 Медицинский работник
                .medWorker.mb-3 
                  .row.gx-2
                    .col-1
                    .col-5.text-center 
                     .text-color--blue.mb-1.text-size--small Сертификат
                     span.text-color--blue-dark.text-size--small 98646545646513687968516
                    .col-4.text-center 
                     .text-color--blue.mb-1.text-size--small Владелец
                     span.text-color--blue-dark.text-size--small МИ ФНС России по ЦОД
                    .col-4.text-center
                     .text-color--blue.mb-1.text-size--small Действителен
                     span.text-color--blue-dark.text-size--small с 09.10.2021 до 23.10.2023
              .col-14
               hr
              .col-7.text-black
               .noticeBottom
                .noticeHeaderBlock.mt-3 Медицинский работник
                p.text-uppercase Генеральный директор ООО «Автотур»
                .noticeBottom__sign
                 .noticeBottom__sign-img 
                 .noticeBottom__sign-name М.А. Максимов
              .col-7.text-black
               .noticeBottom
                .noticeHeaderBlock.mt-3 Клиника
                p.text-uppercase Генеральный директор<br> АО «Технология здоровья»  
                .noticeBottom__sign
                 .noticeBottom__sign-name ________________________ / М.А. Максимов
                 

                
               
              
             
                
                    

                  


</template>

<script>
import {mapState} from 'vuex';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {requiredIf} from '@vuelidate/validators';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppPatientChangeAvatarModal',
  components: {
    AppModal,
    AppTitle,
    
    AppValidationErrors,

  },
  mixins: [modalsMixin],
  props: {
    patient: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      isModalVisible: {
        cropper: false,
      },
      form: {
        file: null,
      },
      uploadFile: '',
      cropperImg: '',
      isCropping: false,
      exifdata: {},
      localValidationErrors: null,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
    }),
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },



  },
};
</script>

<style scoped lang="scss">
.medWorker {
  position: relative;
  width: calc(100% - 30px);
  background: linear-gradient(112.84deg, #F1F4FD 4.77%, #E5EFFE 95.1%);
border-radius: 20px;
float: right;
padding-top: 20px;
padding-bottom: 20px;
padding-right: 37px;
&:before{
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
 box-shadow: 0px 5px 11px -1px rgba(0, 37, 170, 0.15), inset 1px 1px 20px rgba(26, 255, 227, 0.26);
border-radius: 20px;
 background: url('../../../../assets/img/seal.svg') 50% 50% no-repeat #fff;
      left: -31px;
    top: 9px;
}
}
.noticeHeaderBlock{
  font-size: 14px;
line-height: 20px;
color: #000000;
margin-bottom: 10px;
}
.textNoticesDesc{
  color: #000000;
}
.text-black{
  color: #000000;
}
.downloadNotice{
  position: absolute;
  width: 43px;
  height: 43px;
  top: 20px;
  border: 1px solid #59658D;
border-radius: 6px;
   background: url('../../../../assets/img/download.svg') 50% 50% no-repeat #fff;
}
</style>
