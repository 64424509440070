
.profile-exams__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-exams__header
    .row.align-items-center
      .col-14.col-md-auto.order-last.order-md-first
        app-title(
          :type="'large'"
          :id="'isAnchorForBtn'"
          :classes="'profile__title text-center mt-4 mt-md-0'"
        ) Журнал осмотров
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
        .col-auto
          .d-flex.flex-column
            button(
              v-if="exams && exams.length"
              type="button"
              @click="exportExams()"
              class=['link--accent']
            )
        .col-auto
          .d-flex.flex-column
            button(
              v-if="exams && exams.length"
              type="button"
              @click="export266()"
              class=['link--accent']
            )
              span.d-flex.align-items-center
                span.flex-shrink-0.me-3
                  so-icon(icon="xls-doc")
                span.text-size--small Журналы МО по форме 266н .xlsx'
  .profile-exams__content.mt-20.position-relative
    transition(name="translucent")
      app-error-message(v-if="error" :message="error")
      div(v-if="exams")
        template(v-if="exams && exams.length")
          .profile-table.is-exams-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.g-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    v-if="column.caption || column.icon"
                    type="button"
                    @click="sortingExams(column.key)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span(v-if="column.caption") {{ column.caption }}
                      span.flex-shrink-0(v-else-if="column.icon")
                        so-icon(:icon="column.icon")
                      span.flex-shrink-0.d-flex.ms-3
                        so-icon(icon="down-arrow")
            ul.profile-table__body(v-if='exams' id="admin-exams")
              li.profile-table__row(v-for="exam in exams" :key="exam.id")
                app-exam-card(
                  :data="exam"
                  :parent-id="'admin-exams'"
                  :columns="columns"
                  :classes="'d-none d-xl-block'"
                  @openModal="openExamDetailModal"
                  @openCertificate="openCertificate"
                  @openGallery="openGallery"
                )
                app-exam-card-mobile(
                  :data="exam"
                  :classes="'d-xl-none'"
                  @openModal="openExamDetailMobile"
                )
          .d-flex.justify-content-center.align-items-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )
          .exams_count.d-flex.flex-column.align-items-end.p-2
            span(v-if="pagination.total") Всего: {{ pagination.total }}
            span.mt-2(v-if="exams_per_page") На странице: {{ exams_per_page }}
        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              so-icon(icon="wrong")
            span Нет осмотров

  // filter
  app-filter-exams(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilter"
    @getDataFromFilter="sentDataFromFilter"
    @getDataToFilter="sentDataToFilter"
    @getComanynameFilter="sentCompanyNameFilter"
  )
  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @openCertificate="openCertificate"
    @close="closeModal('examDetailMobile')"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )

