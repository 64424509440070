<template lang="pug">
  .police-exam-detail.police-exam-detail--short
    .police-exam-detail__content.position-relative
      transition(name="translucent")
        app-loading(v-if="isLoading")
        //app-error-message(v-if="error" :message="error")
        .not-found(v-if="(error) || (exam.admission_to_work === null && exam.exam_completed) || (exam.admission_to_work === false && exam.exam_completed) || (exam.admission_to_work === null && exam.exam_completed===false)")
         p
         .check__header.mb-2 Осмотра №{{$route.params.id}} не найдено
         .check__desc.mt-5 Проверьте правильность указанного номера и <br> попробуйте снова

        div(v-if="exam")
          .police-exam-detail__patient.mb-35
            app-avatar(
              :classes="'extra-large mb-4'"
              :src="exam.patient_avatar"
            )
            app-title(:classes="'text-center'") {{ exam.patient_first_name }} {{ exam.patient_middle_name }} {{ exam.patient_last_name.charAt(0) }}.

          .police-exam-detail__info
            .row.gy-5.justify-content-center.flex-wrap.flex-lg-nowrap
              .col-14.col-md-7.col-lg-auto.flex-grow-1
                .text-color--blue.text-size--small.mb-3 Водительское удостоверение
                span {{ exam.serial_number_driving_license }}

              .col-14.col-md-7.col-lg-auto.flex-grow-1
                .text-color--blue.text-size--small.mb-3 Дата и время осмотра
                span {{ toLocalDate(exam.exam_date_time) }}, {{ toLocalTime(exam.exam_date_time) }}

              .col-14.col-md-7.col-lg-auto.flex-grow-1
                .text-color--blue.text-size--small.mb-3 Допуск
                app-status(
                  :data="exam"
                  :type="'exam-caption'"
                  :line="false"
                )

              .col-14.col-md-7.col-lg-auto.flex-grow-1(v-if="exam.doctor_fullname")
                .text-color--blue.text-size--small.mb-3 Медработник
                span {{ exam.doctor_fullname }}



          .police-exam-detail__certificate.mt-70.text-size--small.text-center(v-if="exam.number_kep")
            .police-exam-detail__certificate-emblem.d-flex.justify-content-center.align-items-center
              so-icon(icon="eagle")
                template(v-slot:defs)
                  defs
                    linearGradient#gradient-eagle(x1="43.6627" y1="39.2409" x2="6.46999" y2="2.04815" gradientUnits="userSpaceOnUse")
                      stop(stop-color="#6C9EFF")
                      stop(offset="1" stop-color="#53E7BA")

            .row.justify-content-end
              .col-14.col-lg-12
                .row.gy-4
                  .col-14.col-lg-5
                    .text-color--blue.mb-2 Сертификат
                    span.text-break {{ exam.number_kep }}

                  .col-14.col-lg-4
                    .text-color--blue.mb-2 Владелец
                    span {{ exam.owner_kep }}

                  .col-14.col-lg-5
                    .text-color--blue.mb-2 Действителен
                    span {{ exam.validity_kep }}

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import {toLocalDate, toLocalTime} from '@/helpers/utils';

import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppTitle from '@/components/common/Title';
import AppAvatar from '@/components/profile/common/Avatar';
import AppStatus from '@/components/profile/common/Status';

export default {
  name: 'AppPoliceExamDetail',
  components: {
    AppStatus,
    AppLoading,
    AppErrorMessage,
    
    AppAvatar,
    AppTitle,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.exams.isLoading,
      exam: (state) => state.exams.data,
      error: (state) => state.exams.error,
    }),
    examId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getExam();
  },
  methods: {
    getExam() {
      this.$store.dispatch(examsActionTypes.getExamById, this.examId);
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.police-exam-detail {
  $emblemSize: $spacer * 7.4;

  padding: $spacer * 2 $spacer * 3 $spacer * 5 $spacer * 3;
  border-radius: $border-radius-lg;
  background-color: get($colors, white);
  box-shadow: get($shadows, large);

  @include mediaToDown($md) {
    padding: $spacer * 2 $spacer * 2 $spacer * 3 $spacer * 2;
  }
  &__info {
    color: get($colors, black);
  }
  &__certificate {
    position: relative;
    padding: $spacer 0;
    background: get($gradients, blue-light);
    border-radius: $border-radius-lg calc($border-radius-lg/2) calc($border-radius-lg/2) $border-radius-lg;

    @include mediaToDown($lg) {
      margin-top: calc($emblemSize/2);
      padding: $spacer * 6 $spacer $spacer * 3 $spacer;
      border-radius: $border-radius-lg * 1.5;
    }
    &-emblem {
      position: absolute;
      width: $emblemSize;
      height: $emblemSize;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      border-radius: $border-radius-lg;
      background-color: get($colors, white);
      box-shadow: get($shadows, small), get($shadows, green-line);

      svg {
        fill: url(#gradient-eagle);
        width: 70%;
        height: 70%;
      }
      @include mediaToDown($lg) {
        top: 0;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
}
</style>
