
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          type="button"
          v-if="isDirty && this.collectData.length"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3
              template
                .col
                    app-select(
                        ref="selectComplete"
                        :type="'form'"
                        :placeholder="'Завершенность осмотра'"
                        :options="examCompleteOpts"
                        @select="selectComplete"
                    )
                .col
                    app-select(
                        ref="selectAdmission"
                        :type="'form'"
                        :placeholder="'Заключение медработника'"
                        :options="admissionOpts"
                        @select="selectAdmission"
                    )
                .col
                  app-select(
                    ref="selectType"
                    :type="'form'"
                    :placeholder="'Тип осмотра'"
                    :options="typeOpts"
                    @select="selectType"
                  )

                .col
                  .form__caption.mb-3 ФИО пользователя:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="patient_full_name"
                    placeholder="ФИО пользователя"
                    autocomplete="off"
                    id="id"
                  )

                .col(v-if="$store.state.auth.userRole !== 'client'")
                  .form__caption.mb-3 Компания:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="company_name"
                    placeholder="Компания"
                    autocomplete="off"
                    id="id"
                  )
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Интервал даты регистрации:
                  .row.g-3
                    .col-7
                      date-picker(
                        v-model="form.date.dateFrom"
                        prefix-class="app"
                        placeholder="от"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableAfterToday"
                        @change="datepickerChange('dateFrom')"
                        @clear="datepickerClear('dateFrom')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")
                    .col-7
                      date-picker(
                        v-model="form.date.dateTo"
                        prefix-class="app"
                        placeholder="до"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableExpirationFilters"
                        @change="datepickerChange('dateTo')"
                        @clear="datepickerClear('dateTo')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3
                    span Интервал значений&ensp;
                    app-tooltip(
                      :inline="true"
                      :withoutIcon="true"
                      :content="'Систолическое давление называют верхним. На данную величину оказывает влияние сосудистое сопротивление, а также частота и сила сердечных сокращений.'"
                    )
                      template(v-slot:body)
                        span.text-decoration--underline верхнего
                    span &ensp;давления:

                  .form__range.d-flex.align-items-end(:class="{'is-changed': bpHighOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="pulse")
                    .flex-grow-1
                      Slider(
                        v-model="bpHighOpts.value"
                        class="range"
                        :min="bpHighOpts.min"
                        :max="bpHighOpts.max"
                        @change="bpHighChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3
                    span Интервал значений&ensp;
                    app-tooltip(
                      :inline="true"
                      :withoutIcon="true"
                      :content="'В промежутке между сокращениями (фаза систолы), фиксируется величина диастолического или нижнего артериального давления. Данная величина зависит исключительно от сосудистого сопротивления.'"
                    )
                      template(v-slot:body)
                        span.text-decoration--underline нижнего
                    span &ensp;давления:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': bpLowOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="pulse")
                    .flex-grow-1
                      Slider(
                        v-model="bpLowOpts.value"
                        class="range"
                        :min="bpLowOpts.min"
                        :max="bpLowOpts.max"
                        @change="bpLowChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Интервал значений пульса:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': heartRateOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="cardio")
                    .flex-grow-1
                      Slider(
                        v-model="heartRateOpts.value"
                        class="range"
                        :min="heartRateOpts.min"
                        :max="heartRateOpts.max"
                        @change="heartRateChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.d-flex.mb-3 Интервал значений температуры тела,&nbsp;&#176;C:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': bodyTempOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="temp")
                    .flex-grow-1
                      Slider(
                        v-model="bodyTempOpts.value"
                        class="range"
                        :step="bodyTempOpts.step"
                        :format="bodyTempOpts.format"
                        :min="bodyTempOpts.min"
                        :max="bodyTempOpts.max"
                        @change="bodyTempChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.d-flex.mb-3 Алкоголь:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': alcoOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="breathalyzer")
                    .flex-grow-1
                      Slider(
                        v-model="alcoOpts.value"
                        class="range"
                        :step="alcoOpts.step"
                        :format="alcoOpts.format"
                        :min="alcoOpts.min"
                        :max="alcoOpts.max"
                        @change="alcoChange"
                      )

      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !this.collectData.length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
        )
          span.button__content
            span Показать

