
<so-tabs v-model="activeIndex" :needTitle="true" @changeTab="changeActiveTab" :selectedTab="activeIndex">
  <so-tab label="Пользовательские соглашения" name="pdn">
    <pdn />
  </so-tab>
  <so-tab label="ИДС" name="ids">
    <ids />
  </so-tab>
  <so-tab label="Подтверджения пользовательских соглашений" name="PdnUsers">
    <pdn-users />
  </so-tab>
</so-tabs>
