<template lang="pug">
section(class=['patient-card', 'profile-card'] :class="classes")
  .row.align-items-center.g-4.gy-xl-0
    .col-auto
        span.idDoc {{data.id}}
    .d-none.d-xl-block.col-2
      .blue(v-if='data.document_status=="confirmed"') {{ "Подтверждено" }}
      .yellow(v-else-if='data.document_status=="awaiting"') {{ "На подтверждении" }}
      .orange(v-else-if='data.document_status=="rejected"') {{ "Отклонено" }}
      .blue(v-else) {{" В архиве "}}
    .col-auto.col-2
      // подкладываем ровно то время которое пришло с сервера
      span {{ toLocalDateExam(data.create_datetime) }}  {{ toLocalTimeExam(data.create_datetime ) }}
    .col-auto.col-2
      span {{ data.patient_full_name }}
    .col-2
      span.text-overflow {{ toLocalDateExam(data.issue_date) }}
    .col-2
      span.text-overflow(v-if='data.expiration_date') {{ toLocalDateExam(data.expiration_date) }}
    .col-2
      span.text-overflow(v-if='data.document_status!=="awaiting" && data.document_status!=="archived"') {{ toLocalDateExam(data.verification_datetime) }}  {{ toLocalTimeExam(data.verification_datetime ) }}
    .col-1.text-center
      span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='openShowModal(data)')
        span
          img(src="@/assets/img/search.svg" )
</template>

<script>
import {mapState} from 'vuex';

import {toLocalDateExam, toLocalTimeExam} from '@/helpers/utils';

import AppAvatar from '@/components/profile/common/Avatar';

import ShowDocModal from '@/components/profile/client/docs/ShowDocModal.vue';

export default {
  name: 'ClientIncomingNotice',
  components: {
    AppAvatar,
    
    ShowDocModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
    openShowModal: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  methods: {
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.idDoc {
  min-width: 30px;
  display: block;
}
.patient-card {
  &.profile-card {
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;

    .icon {
      &__breathalyzer {
        width: $spacer * 1.8;
        height: $spacer * 1.8;
      }
    }

    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
