
<so-table
  model="MedOrganization"
  :columns="columns"
  :data='items'
  sort-key='creation_date'
  :canEdit="true"
  :canRemove="false"
  :pagination-data='{ total: 0, limit: 15 }'
>
</so-table>
