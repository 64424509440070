<template lang="pug">
  .profile-mobile-tab-bar__content.d-flex.align-items-center(v-click-outside="closeMenu")
    router-link(
      :to="{name: 'dashboard'}"
      :class="{'is-active': $route.name === 'dashboard'}"
      class=['d-flex align-items-center justify-content-center', 'profile-mobile-tab-bar__logo']
    )
      transition(name="fade")
        img(v-if="$route.name === 'dashboard'" src="@/assets/img/logo-mobile.svg" alt="SD")
        img(v-else src="@/assets/img/logo-mobile-gray.svg" alt="SD")

    .profile-mobile-tab-bar__menu
      .row.align-items-center.justify-content-around
        .col-auto
          router-link(
            :to="{name: 'devices'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            so-icon(icon="smartwatch" view-box="0 0 17 24")

        .col-auto
          router-link(
            :to="{name: 'exams'}"
            :class="['profile-mobile-tab-bar__menu-link', 'd-block', `${$route.name.indexOf('map') > -1 ? 'is-active' : ''}`]"
            active-class="is-active"
          )
            so-icon(icon="diary")

        .col-auto
          router-link(
            :to="{name: 'patients'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            so-icon(icon="users")
        .col-auto.d-md-none
          button(
            type="button"
            @click="menuIsOpen = !menuIsOpen"
            class=['profile-mobile-tab-bar__menu-button']
            :class="[{'is-open': menuIsOpen}, {'is-active': $route.name === 'users' || $route.name === 'user-detail' ||  $route.name === 'patients' ||  $route.name === 'patient-detail'}]"
          )
            span
              so-icon(icon="menu-points")
    transition(name="fade")
      ul.profile-mobile-tab-bar__menu-hidden(v-show="menuIsOpen")
        //- TODO it was temp removed
        //- li.profile-mobile-tab-bar__menu-hidden-item
        //-   div(@click="menuIsOpen = false")
        //-     app-title(:routerLink="{name: 'chart'}" :classes="'d-block text-center'")
        //-       span Отчёты
</template>

<script>
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppClientMobileTabBar',
  data() {
    return {
      menuIsOpen: false,
    };
  },
  components: {
    AppTitle,

  },
  methods: {
    closeMenu() {
      this.menuIsOpen = false;
    },
  },
};
</script>
