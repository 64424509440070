
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Редактирование пользователя<br>{{ patient.full_name }}
      .px-4
        form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
          app-validation-errors(
            v-if="localValidationErrors"
            :validation-errors="localValidationErrors"
            :classes="'mb-3'"
          )
          .row.g-4
            .col-14.col-md-7
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }, {'is-changed': form.isChanged.firstName}]"
                  type="text"
                  v-model="v$.form.firstName.$model"
                  @keyup="changeField('firstName')"
                  placeholder="Имя"
                )
              ul.form__error(v-if="v$.form.firstName.$errors.length")
                li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .col-14.col-md-7
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }, {'is-changed': form.isChanged.lastName}]"
                  type="text"
                  v-model="v$.form.lastName.$model"
                  @keyup="changeField('lastName')"
                  placeholder="Фамилия"
                )
              ul.form__error(v-if="v$.form.lastName.$errors.length")
                li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .col-14.col-md-7
              label.form__label
                input(
                  :class="['form__field', {'is-changed': form.isChanged.middleName}]"
                  type="text"
                  v-model="form.middleName"
                  @keyup="changeField('middleName')"
                  placeholder="Отчество"
                )
            .col-14.col-md-7
              label.form__label
                  input(
                      :class="['form__field', { 'is-error': v$.form.snils.$errors.length }, {'is-changed': form.isChanged.snils}]"
                      type="text"
                      v-mask="'###-###-###-##'"
                      placeholder="СНИЛС"
                      @keyup="changeField('snils')"
                      v-model="v$.form.snils.$model"

                  )
              ul.form__error
                  li(v-for="error of v$.form.snils.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .col-14.col-md-7(v-if="v$.form.drivingLicense")
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.drivingLicense.$errors.length }, {'is-changed': form.isChanged.drivingLicense}]"
                  type="text"
                  v-model="v$.form.drivingLicense.$model"
                  @input="formatAndSetDriverLicense"
                  :placeholder="v$.form.drivingLicense.$model ? formattedDriverLicense : 'Серия и номер ВУ'"
                  :maxlength="v$.form.drivingLicense.$model.match(/^[А-Я]{2}/) ? 8 : 10"
                  @keyup="changeField('drivingLicense')"
                )
                ul.form__error(v-if="v$.form.drivingLicense.$errors.length")
                  li(v-for="error of v$.form.drivingLicense.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="v$.form.drivingLicense.$model && error.$validator === 'customValidation'") {{ v.errors.invalid }}

            .col-14.col-md-7
              label(:class="['form__label',{'is-changed': form.isChanged.driving_license_receiving}]")
                  date-picker(
                      prefix-class="app"
                      placeholder="Дата выдачи"
                      format="DD.MM.YYYY"
                      v-model="v$.form.driving_license_receiving.$model"
                      :lang="datepicker.lang"
                      :editable="true"
                      :default-panel="datepicker.defaultValue.panel"
                      :default-value="defaultDate"
                      :disabled-date="disableAfterToday"
                      @change="changeField('driving_license_receiving')"
                  )

            .col-14.col-md-7(v-if="v$.form.birthdate")
              label(:class="['form__label', { 'is-error': v$.form.birthdate.$errors.length }, {'is-changed': form.isChanged.birthdate}]")
                date-picker(
                  v-model="v$.form.birthdate.$model"
                  prefix-class="app"
                  placeholder="Дата рождения"
                  format="DD.MM.YYYY"
                  :lang="datepicker.lang"
                  :editable="true"
                  :default-panel="datepicker.defaultValue.panel"
                  :default-value="defaultDate"
                  :disabled-date="disableBeforeAdult"
                  @change="changeField('birthdate')"
                )
              ul.form__error(v-if="v$.form.birthdate.$errors.length")
                li(v-for="error of v$.form.birthdate.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}

            .col-14.col-md-7
              .row.align-items-center.g-4
                .col-7
                  label(:class="['form__radio', { 'is-checked': form.gender == '1'}, {'is-changed': form.gender === '1' && form.isChanged.gender}]")
                    input(
                      type="radio"
                      name="gender"
                      value="1"
                      v-model="form.gender"
                      @change="changeField('gender')"
                    )
                    .d-flex.align-items-center
                      i.form__radio-icon.me-3.flex-shrink-0
                      span.form__radio-caption Мужской
                .col-7
                  label(:class="['form__radio', { 'is-checked': form.gender == '2'}, {'is-changed': form.gender === '2' && form.isChanged.gender}]")
                    input(
                      type="radio"
                      name="gender"
                      value="2"
                      v-model="form.gender"
                      @change="changeField('gender')"
                    )
                    .d-flex.align-items-center
                      i.form__radio-icon.me-3.flex-shrink-0
                      span.form__radio-caption Женский

            .col-14.col-md-14
              label.form__label
                input(
                  :class="['form__field']"
                  type="text"
                  v-model="v$.form.personal_number.$model"
                  @keyup="changeField('personal_number')"
                  placeholder="Табельный номер"
                )
            .col-14.col-md-7(v-if="v$.form.email")
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.email.$errors.length }, {'is-changed': form.isChanged.email}]"
                  type="email"
                  v-model="v$.form.email.$model"
                  @keyup="changeField('email')"
                  placeholder="E-mail"
                )
              ul.form__error(v-if="v$.form.email.$errors.length")
                li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  template(v-if="error.$validator === 'email'") {{ v.errors.email }}

            .col-14.col-md-7(v-if="v$.form.phone")
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.phone.$errors.length }, {'is-changed': form.isChanged.phone}]"
                  type="tel"
                  v-model="v$.form.phone.$model"
                  v-mask="'+7 (###) ###-##-##'"
                  @keyup="changeField('phone')"
                  placeholder="Телефон"
                )
              ul.form__error(v-if="v$.form.phone.$errors.length")
                li(v-for="error of v$.form.phone.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  template(v-if="v$.form.phone.$model && error.$validator === 'minLength'") {{ v.errors.invalid }}

            .col-14.col-md-7
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.password.$errors.length }, {'is-changed': form.isChanged.password}]"
                  :type="isShowPassword ? 'text' : 'password'"
                  v-model="v$.form.password.$model"
                  @keyup="changeField('password')"
                  placeholder="Новый пароль"
                )
                span(class="form__icon cursor-pointer" @click="isShowPassword = !isShowPassword")
                  so-icon(v-if="isShowPassword" :icon="'eye-open'")
                  so-icon(v-else :icon="'eye-close'")

              ul.form__error(v-if="v$.form.password.$errors.length")
                li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                  template(v-if="v$.form.password.$model")
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                    template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                    template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                    template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                    template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

            .col-14.col-md-7
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                  :type="isShowPassword ? 'text' : 'password'"
                  v-model="v$.form.confirmPassword.$model"
                  placeholder="Повторите пароль"
                )
                span(class="form__icon cursor-pointer" @click="isShowPassword = !isShowPassword")
                  so-icon(v-if="isShowPassword" :icon="'eye-open'")
                  so-icon(v-else :icon="'eye-close'")

              ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  template(v-if="v$.form.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

            .col-14
              .row.align-items-end.g-4
                template(v-if="isRFIDDeleted")
                  .col
                    .position-relative.d-flex.align-items-center.justify-content-center.text-color--danger
                      span.flex-shrink-0.d-flex.me-3
                        so-icon(icon="wrong")
                      span.text-size--small Карта удалена

                      button(
                        type="button"
                        @click.prevent="cancelRFIDInput"
                        class=['form__button--cancel', 'link--accent', 'text-decoration--underline text-size--small text-color--blue']
                      ) Отмена

                template(v-else)
                  .col
                    .d-flex.mb-1(v-if="isRFIDChange")
                      span.text-size--extra-small.text-color--blue Введите новый номер карты

                      ul.form__error.mt-0.ms-auto(v-if="v$.form.RFID.$errors.length")
                        li(v-for="error of v$.form.RFID.$errors" :key="error.$uid")
                          template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                          template(v-if="error.$validator === 'alphaNum'") {{ v.errors.alphaNum }}

                    template(v-else)
                      span.text-size--extra-small.text-color--success(v-if="form.isChanged.RFID") Новый номер карты сохранен

                    label(
                      class=['form__label form__label--border-theme', 'd-flex align-items-center']
                      :class="[`justify-content-${isRFIDChange ? 'start' : 'center'}`, { 'is-error': v$.form.RFID.$errors.length }, {'is-changed': form.isChanged.RFID}]"
                    )
                      span.d-flex.flex-shrink-0.me-3(:class="{'text-color--blue': isRFIDChange || !form.RFID}")
                        so-icon(icon="card")

                      template(v-if="isRFIDChange")
                        span.me-2.text-uppercase.text-color--black Новая&nbsp;RFID&nbsp;Карта&nbsp;№
                        input(
                          :class="['form__field']"
                          type="text"
                          v-model="v$.form.RFID.$model"
                          placeholder="0123456789AaZz"
                        )
                      template(v-else)
                        template(v-if="form.RFID")
                          span.me-2.text-uppercase RFID&nbsp;Карта
                          span.text-family--medium №&nbsp;{{ form.RFID }}

                        span.text-uppercase.text-color--blue(v-else) нет RFID карты

                  .col-auto
                    button(
                      v-if="isRFIDChange"
                      type="button"
                      title="Изменить"
                      :disabled="v$.form.RFID.$invalid"
                      @click.prevent="changeRFIDInput"
                      class=['profile-card__button profile-card__button--confirm', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        so-icon(icon="checkmark")

                    button(
                      v-else
                      type="button"
                      @click.prevent="isRFIDChange = !isRFIDChange"
                      class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        so-icon(icon="edit")

                  .col-auto
                    button(
                      v-if="isRFIDChange"
                      type="button"
                      title="Отмена"
                      @click.prevent="clearRFIDInput"
                      class=['profile-card__button profile-card__button--cancel', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        so-icon(icon="close")

                    button(
                      v-else
                      type="button"
                      @click.prevent="deleteRFIDInput"
                      :disabled="!form.RFID"
                      class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        so-icon(icon="trash")


          .row.mt-5
            .col-14
              button(
                type="submit"
                @click="onSubmit"
                :disabled="v$.$invalid || !isChanged"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Сохранить

