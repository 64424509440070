
.profile-exams__inner
  .row.gx-3.text-size--middle
    .col-3.text-color--black
      span.flex-shrink-0.me-2 №
      span {{ exam.exam_number }} ({{ formattedTime }})
    .col-3
      span.flex-shrink-0.me-2 {{ $moment(exam.exam_datetime).format('DD.MM.YYYY') }}
      span {{ $moment(exam.exam_datetime).format('HH:mm') }}
    .col-4
      span.flex-shrink-0 {{ exam.exam_type_name }} &nbsp;
      span.text-color--orange {{ getExamRepeat(exam.exams_count) }} &nbsp;
    .col-4
      span.flex-shrink-0.me-2 {{ exam.patient_full_name }}
  .row.gx-3.mt-4.text-size--middle
    .col-6
      .d-flex
        span.flex-shrink-0.me-2 Рекомендация:&nbsp;
          span.text-color--orange(v-if="medDoctorRecomendations === 'Отстранить'") {{ medDoctorRecomendations }}
          span.text-color--success(v-if="medDoctorRecomendations === 'Допустить'") {{ medDoctorRecomendations }}
        label.form__checkbox.ms-2
          input(
            type="checkbox"
            name="user-type"
            v-model='protector'
            @change='protector ? isModalVisible.protector = true : ""'
          )
          i.form__checkbox-icon.me-3
          span.form__checkbox-caption Отключить
    .col-4
      span.flex-shrink-0.me-2 {{ exam.device_owner_full_name }}
    .col-4
      span.flex-shrink-0.me-2 Пол: {{ exam.gender === 1 ? 'Муж.' : 'Жен.' }} {{ age }} {{ agePostfix }}
  .row.mt-3
    .col-14
      .doctor-exam-detail-modal.innerExamDetailDoctor
        .row.gx-4
          .col-14
            .border-line(:class="{'allow': medDoctorRecomendations === 'Допустить', 'not_allow': medDoctorRecomendations === 'Отстранить'}")
          .col-11
            .row.gx-3
              .col-6
                .innerExamDetailDoctor__ava
                  app-avatar(
                    :type="'doctor'"
                    :localUrl="exam.patient_avatar"
                    :viewPhoto="true"
                    @openGallery="openGallery"
                    :classes="'square extra-large'"
                    :id="exam.patient_code"

                )
                  p.w-100.text-color--blue-dark.text-center.pointer.underline.mt-2.text-uppercase(v-if="exam.patient_avatar" @click='archive') Архивировать фото
              .col-8
                  template(v-if="exam")
                    transition(name="fade" mode="out-in")
                      doctor-exam-card-video(
                        :src="exam.exam_video"
                        :stopVideo="stopVideo"
                      )
                  .text-color--gray-light(v-else)
                    so-icon(icon="no-video")

            doctor-exam-card-inspection-result(
              :exam="exam"
            )
            doctor-exam-card-med-docs-history(
              :exam="exam"
              :id="exam.patient_code"
            )
            doctor-exam-card-med-docs(
              :exam="exam"
            )
          .col-3
            doctor-exam-card-signatures(
              :exam="exam"
            )
            exam-detail-comments(:patientCode="exam.patient_code")

        .row.gx-5.mt-4.pt-2
          .col-7
            .row.gx-5
              .col-7
                button(
                  v-if="exam.type_id == 3 || exam.type_id == 1"
                  type="button"
                  @click="openModal('reasons'), admissionValue = false, typeAdmission = `${exam.type_id}no`, clearCounter = clearCounter + 1"
                  :class="['button', 'button--border-blue', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="wrong")
                    span отстранить

                button(
                  v-if="exam.type_id == 2 || exam.type_id == 4|| exam.type_id == 5"
                  type="button"
                  @click="openModal('reasons'), admissionValue = false, typeAdmission = `${exam.type_id}no`, clearCounter = clearCounter + 1"
                  :class="['button', 'button--border-blue', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="wrong")
                    span не прошел

              .col-7
                button(
                type="button"
                :disabled="prolongationUsed"
                @click="addToTimer"
                :class="['button', 'button--shadow', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center продлить

          .col-7
            .row.gx-5
              .col-7
                button(
                  type="button"
                  @click="stopAfterExam"
                  :class="['button', 'button--shadow', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center.green пауза

              .col-7
                button(
                  v-if="exam.type_id == 2 || exam.type_id == 4 || exam.type_id == 5 "
                  type="button"
                  @click="openModal('reasons'), admissionValue = true, typeAdmission = `${exam.type_id}yes`, clearCounter = clearCounter + 1"
                  :class="['button', 'button--accent', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="allright")
                    span Прошел

                button(
                  v-if="exam.type_id == 3 || exam.type_id == 1"
                  type="button"
                  :disabled="!reject_reasons.approve && !protector"
                  @click="onSetAdmission('yes'), admissionValue = true, typeAdmission = `${exam.type_id}yes`"
                  :class="['button', 'button--accent', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="allright")
                    span Допустить

  app-protector(
    :is-visible='isModalVisible.protector'
    @update:protector='(v) => setProtector(v)'
    @close='() => closeModal("protector")'
  )
  sign-error-modal(
    :model-value='isModalVisible.signError'
    @update:model-value='(v) => setProtector(v)'
  )
  //Reasons modal
  app-doctor-reasons(
    :is-visible="isModalVisible.reasons"
    @close="closeModal('reasons')"
    :data="exam"
    :reject_reasons_ids="reject_reasons.reject_reason_ids"
    :clearForm="clearForm"
    :type="typeAdmission"
    @refuseOpen='refuseOpen'
    @sendReasons="onSetAdmission('yes')"
    @cleanReasons='cleanReasons'
    :time='clearCounter'
    :protector="protector"
  )
  // Отказать причина
  app-doctor-reasons-refuse(
    :is-visible="isModalVisible.refuse"
    @closeReasons="closeModal('reasons')"
    @close="closeModal('refuse')"
    @setRefuseText='setRefuseText'
    @sendReasons="onSetAdmission('no')"
    :type="typeAdmission"
    :data="exam"
    :reasons="reasons"
    :time='clearCounter'
  )

  sign-error-modal(v-model='isModalVisible.signError')

    // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="loading" :withoutCloseBtn="true")
      template(v-slot:body)
        .text-center.pt-4.d-flex.flex-column.justify-content-center.align-items-center
          div(class="spinner-border mb-4" role="status")
          app-title(:type="'large'") Документ находится на подписании
          p.text-size--small.text-color--blue.mt-3 Не обновляйте страницу и не закрывайте данное окно

  // delete avatar modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Архивировать фотографию пользователя?
          p.text-color--blue.mt-3  {{ exam.patient_full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteAvatar')"
              :class="['button', 'button--accent', 'form__button']"
            )
              .button__content Не архивировать
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteAvatar(exam.patient_code)"
              :class="['button', 'button--border-blue', 'form__button']"
            )
              .button__content Да, архивировать

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // success change modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successChange" @close="closeModal('successChange')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") {{ successMessage }}
          p.text-color--blue.mt-3(v-if="exam") ФИО пользователя: {{ exam.patient_full_name }}
