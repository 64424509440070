
fragment
  router-link(
    v-if="routerLink"
    :to="routerLink"
    :class="['title', `title--${type}`, classes || null]"
    :id="id"
  )
    slot

  h2(
    v-else
    :id="id"
    :class="['title', `title--${type}`, classes || null]"
  )
    slot

