<template lang="pug">
.accordion(:class="classes" :id="id")
  .accordion__header(
    :id="`heading${id}`"
    data-bs-toggle="collapse"
    :data-bs-target="`#collapse${id}`"
    :aria-controls="`collapse${id}`"
  )
    slot(name="header")
    button(
      v-if="!withoutBtn"
      type='button'
      class=['accordion__button', 'd-flex align-items-center']
    )
      so-icon(icon="down-arrow")

  .accordion__collapse.collapse(
    :id="`collapse${id}`"
    :aria-labelledby="`heading${id}`"
    :data-bs-parent="`#${parentId}`"
  )
    .accordion__body.pt-4
      slot(name="body")

</template>

<script>
import {Collapse} from 'bootstrap';

export default {
  name: 'AppAccordion',
  components: {

    Collapse,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    parentId: {
      type: String,
      required: true,
      default: '',
    },
    withoutBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showClass: 'is-show',
    };
  },
  mounted() {
    // this.toggleShowClass();
  },
  methods: {
    // toggleShowClass() {
    //   const accEl = document.getElementById(this.id);
    //   accEl.addEventListener('show.bs.collapse', () => accEl.classList.add(this.showClass));
    //   accEl.addEventListener('hide.bs.collapse', () => accEl.classList.remove(this.showClass));
    // }
  },
};
</script>

<style lang="scss">
.accordion {
  &__header {
    position: relative;
  }
  [data-bs-toggle] {
    cursor: pointer;

    &[aria-expanded='true'] {
      .accordion {
        &__button {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__button {
    position: absolute;
    top: 50%;
    right: 0;
    @include transition();

    svg {
      width: $spacer;
      height: $spacer;
    }
  }
}
</style>
