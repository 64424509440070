<template>
  <div class="tab-nav">
    <app-title v-if="needTitle && selectedTabObj" :type="'large'">{{ selectedTabObj.label  }}</app-title>
    <div class="tab-nav-content">
      <button v-for="(tab, index) in tabs"
          :class="['tab-nav__item', {'selected': index === selectedTab}]"
          :key="tab.name"
          @click="clickOnTab(tab, index)">
            {{ tab.label }}
        </button>
    </div>
          <slot></slot>
  </div>
</template>

<script>
import AppTitle from '@/components/common/Title';

export default {
  name: 'so-tab',
  components: {
    AppTitle
  },
  props: {
    selectedTab: {
      type: Number,
      required: true
    },
    needTitle: Boolean
  },
  data() {
    return {
      tabs: []
    };
  },
  computed: {
    selectedTabObj() {
    return this.tabs.find((tab, index) => index === this.selectedTab)
  }
  },
  methods: {
    // Генерация события для изменения активной вкладки
    clickOnTab(tab, index) {
      this.$emit('changeTab', index, tab.name);

    },
    // Метод для регистрации вкладки
    registerTab(tab) {
      this.tabs.push(tab);
    }
  }
};
</script>


<style lang="scss">
.tab-nav {
  display: flex;
  flex-direction: column;
}
.tab-nav-content {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tab-nav__item {
  padding: 0 30px;
  color: get($colors, blue);
  background-color: get($colors, white);
  border: $border-width solid get($colors, blue);
  border-radius: $border-radius-sm;
  white-space: nowrap;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  margin-right: 6px;
  &.selected {
    color: get($colors, white);
    background-color: get($colors, blue-dark);
    border-color: transparent;
  }
}
.tab-nav__item:last-child {
  margin-right: 0;
}

.tab-nav-content::-webkit-scrollbar {
    display: none;
}
</style>
