
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Редактировать поверку устройства
      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")

        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
          app-validation-errors(
            v-if="localValidationErrors"
            :validation-errors="localValidationErrors"
            :classes="'mb-3'"
          )
          .col-14.col-md-10.mt-3
            .row.g-4.posRelative
              .col
               div
                input(
                    :class="['form__field form__field--animate']"
                    type="text"
                    name="id"
                    placeholder="&nbsp;"
                    autocomplete="off"
                    id="id"
                    v-model="form.serial"
                    disabled
                  )
                label.textFloat(for='id') Серийный номер МИ
                input(
                    :class="['form__field form__field--animate','mt-3']"
                    type="text"
                    name="id"
                    placeholder="&nbsp;"
                    autocomplete="off"
                    id="id"
                    v-model="form.device_attestation"
                  )
                label.textFloat(for='id') Номер поверки
                .row.gx-4
                 .col-7
                    .text-size--small.mb-2.mt-3 Дата поверки
                    date-picker(
                        prefix-class="app"
                        placeholder="Дата поверки"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        :disabled-date="disableAfterToday"
                        v-model='form.device_attestation_start'
                    )
                 .col-7
                   .text-size--small.mb-2.mt-3  Окончание срока поверки
                   date-picker(
                        prefix-class="app"
                        placeholder="Окончание срока поверки"
                        format="DD.MM.YYYY"
                        lang="ru"
                        :editable="true"
                        v-model='form.device_attestation_end'
                        :disabled-date="disableExpiration"
                    )

          .col-14.col-md-10.mt-5
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Отправить
          .col-14.col-md-9

