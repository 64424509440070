<template>
  <button
  :class="['so-btn', ...classes]"
  :disabled="disabled"
  @click.stop.prevent="handleClick"
  >
    <div class="so-btn__content">
      <img v-if='icon' src="/esia.svg" width='32'/>
      <so-icon v-else-if='icon' :icon="icon" class="so-btn__icon" />
      <slot>{{ title }}</slot>
    </div>
  </button>
</template>

<script lang='ts'>
import type { PropType } from 'vue';
import { computed } from 'vue';
import { SoIconsNames } from '@/components/so/icon/SoIcon.vue';
import SoIcon from '@/components/so/icon/SoIcon.vue'

export type ButtonType = 'default' | 'primary' | 'accent' | 'danger' | 'info' | 'esia';
export type ButtonSize = 'small' | 'medium' | 'large';

export default {
  name: 'so-button',
  components: {
    SoIcon
  },
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'default'
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'medium'
    },
    /** Button title text - doesn't work wwith custom slot */
    title: String,
    disabled:  Boolean,
    icon: String as PropType<SoIconsNames>,
  },
  emits: ['on-click'],
  setup(props, { emit }) {
    /** Sets dynamic classes to a root element */
    const classes = computed(() => {
      return [`so-btn--${props.type}`, `so-btn_${props.size}`, ];
    });

    /** On button click - emits custom event */
    function handleClick() {
      emit('on-click');
    }

    return {
      classes,
      handleClick
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  font: inherit;
  color: inherit;

  &[type="button"], &[type="submit"] {
    &:focus {
      outline: none;
    }
  }
}

$block: 'so-btn';

.#{$block} {
  @include transition();
  @include font-size(get($text-sizes, normal));
  display: inline-block;
  user-select: none;
  position: relative;
  border-radius: $border-radius-sm;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: get($letter-spacing, default);
  padding: $spacer * 1.2 $spacer * 3;
  width: 100%;

  &[disabled] {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }

  @include mediaToDown($md) {
    padding: $spacer;
  }

  &:before {
    @include pseudo-wrapper();
    @include transition();
    opacity: 0;
    visibility: hidden;
    border-radius: $border-radius-sm;
  }

  &__content {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: get($index, default);
    gap: 10px;

    & .#{$block}__icon {
      width: $spacer * 1.2;
      height: $spacer * 1.2;
    }
  }

  // types
&--default {
  color: get($colors, blue-dark);
  box-shadow: inset 0 0 0 $border-width get($colors, blue-dark);

  @include interaction('mouse') {
    &:hover {
      background-color: get($colors, blue-dark);
      color: get($colors, white);
      box-shadow: inset 0 0 0 $border-width transparent, get($shadows, default);
    }
  }

  &:active {
    background-color: get($colors, blue-dark);
    color: get($colors, white);
    box-shadow: inset 0 0 0 $border-width get($colors, blue-dark);
  }
}

  &--primary {
    background: get($gradients, blue-dark);
    color: get($colors, white);

    &:before {
      background-color: get($colors, blue-hover);
    }

    @include interaction('mouse') {
      &:hover {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:active {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &--accent {
    background: get($gradients, turq);
    color: get($colors, white);

    &:before {
      background-color: get($colors, turq-hover);
    }

    @include interaction('mouse') {
      &:hover {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:active {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &--danger {
    background: get($gradients, orange);
    color: get($colors, white);

    &:before {
      background-color: get($colors, pink);
    }

    @include interaction('mouse') {
      &:hover {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:active {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &--info {
    background-color: get($colors, white);
    color: get($colors, blue-dark);
    border: 1px solid get($colors, blue-dark);

      &:before {
      background-color: get($colors, blue-dark);
    }

    @include interaction('mouse') {
      &:hover {
        color: get($colors, white);
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:active {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &--esia {
    background-color: get($colors, white);
    color: get($colors,  esia);
    border: 1px solid get($colors,  esia);
    text-transform: none;
    border-radius: 8px
  }
}

// Группа стилей для кнопок различного размера
.button--small {
  text-transform: none;
  padding: $spacer * 0.5 $spacer * 1.5 $spacer * 0.6 $spacer * 1.5;
}
.button--medium {
  padding: 0.75rem 1.875rem;
  min-width: $spacer * 37;

  @include mediaToDown($md) {
    min-width: 100%;
    padding: $spacer * 1.6;
  }
}
.button--large {
  padding: $spacer * 1.85;
  min-width: $spacer * 50;

  @include mediaToDown($xl) {
    min-width: 100%;
    width: 100%;
  }

  @include mediaToDown($md) {
    padding: $spacer * 1.4;
  }
}
</style>
