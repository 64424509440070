
.profile-doctors
  .row.align-items-center
    .col-14.align-items-center
      app-title(:type="'large'") Настройки системы. Настройки автобота
    .row.align-items-center.g-4.mb-5
      ul.profile-table__body
        li.profile-table__row(v-for="border in bordersIds" :key="border.num")
          section(
            v-if='resultBorders[border.id]'
            class=['user-card', 'user-card-history']
          )
            .row.align-items-center.g-4.gy-xl-0.mb-3
              .col-8.d-flex
                label.form__checkbox.check
                  input(
                    type="checkbox"
                    value="person"
                    name="autobot-settings"
                    :checked="resultBorders[border.id].enabled"
                    v-on:change="setCheckbox(resultBorders[border.id])"
                    )
                  i.form__checkbox-icon.me-4
                  span.form__checkbox-caption.text-uppercase {{ border.name }}
                div.d-flex(v-if="border.num <= 8")
                  .col.d-flex
                    span.presonal-border-text.text-uppercase от
                    input(
                      :class="['personal-border', 'form__field me-5']"
                      type="number"
                      v-model="resultBorders[border.id].min_value"
                    )
                    span.presonal-border-text.text-uppercase до
                    input(
                      :class="['personal-border', 'form__field me-5']"
                      type="number"
                      v-model="resultBorders[border.id].max_value"
                    )
                    button(
                      v-if="currentUser.role !== 'CLIENT'"
                      type="button"
                      @click="sendBorder(resultBorders[border.id])"
                      class=['text-decoration--underline text-size--small link--accent text-uppercase']
                    ) Сохранить

