<template lang="pug">
section(
  class=['profile-card', 'doctor-exam-card', 'cursor-pointer']
  :class="classes"
)
  //- @click.capture="openModal(type, data)"

  .row.align-items-center.g-4
    div(v-for="column in columns" :key="column.key" :class="column.class")
      template(v-if="column.key === 'admission_datetime' || column.key === 'admission_datetime'")
        span {{ toLocalDate(data.exam_date_time) || toLocalDate(data.exam_datetime)}}
        span.ms-2 {{ toLocalTimeExam(data.admission_datetime) || toLocalTimeExam(data.admission_datetime)}}

      template(v-else-if="column.key === 'patient_full_name' || column.key === 'patient_code'")
        span(v-if="data.patient_full_name") {{ data.patient_full_name }}
        span(v-else) &#8212;

      template(v-else-if="column.key === 'lat'")
        span(v-if="data[column.key] && data.long") {{ data[column.key] }}, {{ data.long }}
        span(v-else) &#8212;

      template(v-else-if="column.key === 'bp_high'")
        span(v-if="data[column.key] && data.bp_low")
          span(:class='{yellowText: data.bp_high_alarm}') {{ data[column.key] }}/
          span(:class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
        span(v-else) &#8212;

      template(v-else-if="column.key === 'heart_rate'")
        span(v-if="data[column.key]" :class='{yellowText: data.heart_rate_alarm}') {{ data[column.key] }}&nbsp;уд&#65279;/&#65279;мин
        span(v-else) &#8212;

      template(v-else-if="column.key === 'body_temp'")
        span(:class='{yellowText: data.body_temp_alarm}' v-if="data[column.key]") {{ data[column.key] }}&nbsp;c&#176;
        span(v-else) &#8212;

      template(v-else-if="column.key === 'alcohol'")
        span(v-if="data[column.key] || data[column.key] === 0")
          span(:class='{yellowText: data.alcohol_alarm}') {{ alcoFormatted(data[column.key])}}&nbsp;мг&#65279;/&#65279;л
        span(v-else) &#8212;

      template(v-else-if="column.key === 'health_complaint'")
        app-tooltip(
          v-if="data[column.key]"
          :content="`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--black\">${data[column.key]}<p>`"
          :icon="'comment'"
        )

      template(v-else-if="column.key === 'admission_to_work'")
        .exam-card__status.d-flex
          app-status(
          :type="'doctor'"
          :data="data"
          :id="data.exams_id")

      template(v-else)
        span(v-if="data[column.key]") {{ data[column.key] }}
        span(v-else) &#8212;

</template>

<script>
import is from 'is_js';
import variables from '@/helpers/variables';
import {toLocalDate, toLocalTime, toLocalTimeExam} from '@/helpers/utils';

import AppTooltip from '@/components/common/Tooltip';
import AppStatus from '@/components/profile/common/Status';

export default {
  name: 'AppDoctorExamCard',
  components: {
    
    AppTooltip,
    AppStatus,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      exams: {},
      v: variables,
    };
  },
  computed: {
    type() {
      return is.null(this.data.admission_to_work)
        ? this.v.exams.statuses.pending
        : this.v.exams.statuses.checked;
    },
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
    // openModal(type, data) {
    //   this.$emit('openModal', type, data);
    // },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
    alcoFormatted(alcoData) {
      let number = Number(alcoData);
      return number.toFixed(2);
    },
  },
};
</script>

<style lang="scss">
.yellowText {
  color: #ec9125;
}
.doctor-exam-card {
  padding-top: $spacer;
  padding-bottom: $spacer;
  padding-right: $spacer * 4;
  .yellowText {
    color: #ec9125;
  }
  .icon {
    &__eye-open {
      width: $spacer * 2;
      height: $spacer * 1.2;
    }
  }
}
</style>
