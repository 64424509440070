
.profile-device-detail
  .profile-device-detail__inner.is-filterable
    .profile-device-detail__header
      .row.align-items-center
        .col-14.col-md-auto.me-auto.order-last.order-md-first
          div
            .row.align-items-center.g-5
              .col-3.col-md-auto
                app-back-button(:prev-page="'devices'")
              .col-7.col-md.flex-grow-1
                app-title(
                  :type="'large'"
                  :classes="'text-center'"
                ) {{ isTouchDevice ? 'Устройство' : 'Просмотр устройства' }}
              .col-3.d-md-none
                .d-flex.justify-content-end
                  button(
                    type="button"
                    @click.prevent="openModal('deleteDevice')"
                    class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
                  )
                    span.flex-shrink-0
                      so-icon(icon="trash")

        .col-auto.order-1.order-md-0.ms-auto
          //- transition(name="translucent")
          //-   router-link(
          //-     :to="{name: 'map'}"
          //-     class=['d-flex align-items-center', 'link--accent']
          //-   )
          //-     span.flex-shrink-0.me-2
          //-       so-icon(icon="map-point")
          //-     span.text-size--small {{ isTouchDevice ? 'Геокарта' : 'Просмотреть геокарту' }}

        .col-auto
          transition(name="translucent")
            button(
              v-if="exams && exams.length"
              type="button"
              @click="() => exportExams()"
              class=['link--accent']
            )
              .d-flex.align-items-center
                span.flex-shrink-0.me-3
                  so-icon(icon="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}

    .profile-device-detail__card.mt-20.position-relative
      app-loading(v-if="devicesLoading")
      app-error-message(v-if="devicesError" :message="devicesError")

      app-admin-device-card(
        v-if="current_device"
        :data="current_device"
        :no-hover="true"
        @delete="openModal('deleteDevice')"
        @change="openChangeModal(current_device)"
      )

    .profile-device-detail__content.pt-20(id="isAnchorForBtn")
      transition(name="translucent")
        app-loading(v-if="examsLoading")
        app-error-message(v-if="examsError" :message="examsError")

        div(v-if="exams")
          app-title(
            :type="'large'"
            :classes="'text-center mb-20 mb-md-5 d-xl-none'"
          ) Осмотры

          template(v-if="exams && exams.length")
            .profile-table.is-exams-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingExams(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span(v-if="column.caption") {{ column.caption }}
                        span.flex-shrink-0(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.flex-shrink-0.d-flex.ms-3
                          so-icon(icon="down-arrow")


              ul.profile-table__body(id="device-exams")
                li.profile-table__row(v-for="exam in exams" :key="exam.exams_id")
                  app-exam-card(
                    :data="exam"
                    :parent-id="'device-exams'"
                    :type="'device-detail'"
                    :columns="columns"
                    :classes="'d-none d-xl-block'"
                    @openModal="openExamDetailModal"
                    @openCertificate="openCertificate"
                  )
                  app-exam-card-mobile(
                    :data="exam"
                    :classes="'d-xl-none'"
                    @openModal="openExamDetailMobile"
                  )

          template(v-else)
            .mt-4.text-color--blue.text-size--small(:class="{'text-center': isTouchDevice}") Осмотров не найдено

      // pagination
      .d-flex.justify-content-center.mt-5
        app-pagination(
          :total="pagination.total"
          :limit="pagination.limit"
          :current-page="currentPage"
          :url="baseUrl"
        )

  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )
  // delete modal
  app-delete-device-modal(
    v-if="current_device"
    :data="current_device"
    :is-visible="isModalVisible.deleteDevice"
    @close="closeModal('deleteDevice')"
    @success="openModal('success')"
  )
  //Edit
  change-device-modal(
    :is-visible="isModalVisible.changeDevice"
    :toEdit="toEdit"
    @close="closeModal('changeDevice')"
    @success="getDevice"
  )
  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :type="'device-detail'"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @close="closeModal('examDetailMobile')"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // success deleted modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Устройство успешно удалено

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="$router.go(-1)"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад в список

