
simplebar.auth
  .container-fluid
    .row.flex-column.align-items-center
      .col-14.col-xl-6.col-xxl-5
        router-link(:to="{name: 'home'}")
          app-logo
        .auth__container
          transition(name="fade" mode="out-in")
            router-view
