
div
  .patient-exams__header.text-size--normal.mb-12.d-flex.flex-xl-row.flex-column
    span(
      @click="showComments"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Комментарии к этому пользователю

  .comments(v-if="showBlock")
    label.submit-form-comment
      textarea(
        id="editor"
        :class="['form__field w-100 doctor-exam-detail-modal__text']"
        placeholder="Добавить комментарий"
        v-model="currentComment"
      )
      button(
        type="button"
        @click="createComment()"
        class=['send-comment']
      )
        span.send-comment-content >
    .comments-section
      div(v-for="comment in comments" :key="comment.id")
        p.comment-header.text-color--blue-dark {{ comment.user.fio_name }} {{ toLocalDateExam(comment.created_at) }} {{ toLocalTimeExam(comment.created_at) }}
        p.text-color--blue-dark {{ comment.comment }}

  