<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col.col-md-auto.ms-md-auto
        button(
        type="button"
        class=['button button--border-blue']
        @click="openModal('loadDoc')"
        )
          span.button__content Загрузить документ

  .profile-patients__content.mt-20.position-relative
    transition(name="translucent")
      div
        app-loading(v-if="isLoading" key="loading")
        app-error-message(v-if="error" :message="error" key='error')
        app-table(
          v-if="outgoingMedDocs.length"
          :columns="columns"
          :sortKey="sortKey"
          @sortingRow="sortingOutgoingMedDocs"
          :data="outgoingMedDocs"
          :paginationData="pagination"
          :currentPage="currentPage"
          @change-current-page="setCurrentPage"
          :baseUrl="baseUrl"
          :canEdit="false"
          :canRemove="false"
        )
          template(v-slot:cell='{ column, row }')
            span(v-if='column.key === "document_status"') {{ getDocumentStatus(row[column.key]) }}
            span(v-else-if='column.key === "create_datetime"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}
            span(v-else-if='column.key === "issue_date"') {{ toLocalDateExam(row[column.key]) }}
            span(v-else-if='column.key === "expiration_date"') {{ toLocalDateExam(row[column.key]) }}
            span(v-else-if='column.key === "verification_datetime"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}

          template(v-slot:actionTools='{ row }')
            button(
              type="button"
              @click.prevent="openShowModal(row)"
            )
              so-icon(icon="search")

        div(v-else-if="!isLoading")
          .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-incoming(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
  // create patients modal
  load-doc-modal(
    :is-visible="isModalVisible.loadDoc"
    @open="openModal('loadDoc')"
    @close="createDoc"
    @openGallery="openGallery"
    @success="getPatients()"
  )
  // create patients modal
  show-doc-modal(
    :is-visible="isShowDataVisible"
    :closeModal="closeShowModal"
    :data="currentDoc"
  )
</template>

<script>

import { mapState } from 'vuex';
import incomingMedDocs from '@/api/incomingMedDocs';


import useVuelidate from '@vuelidate/core';
import variables from '@/helpers/variables';
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppSelect from '@/components/common/Select';
import ClientIncomingNotice from '@/components/profile/client/patients/ClientIncomingNotice';
import AppFilterPageIncoming from '@/components/FilterPageIncoming';
import ClientNotice from '@/components/profile/client/patients/ClientPatientNotice';
import ModalNotice from '@/components/profile/common/patients/ModalNotice';
import NavNotices from '@/components/profile/common/NavNotices';
import LoadDocModal from '@/components/profile/client/docs/LoadDocModal';
import ShowDocModal from '@/components/profile/client/docs/ShowDocModal';
import AppTable from '@/components/common/table/Table';

import { requiredIf } from '@vuelidate/validators';
import { galleryMixin } from '@/mixins/gallery';
import { toLocalDateExam, toLocalTimeExam } from '@/helpers/utils';

export default {
  name: 'PageNotices',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppLoading,
    AppErrorMessage,
    ClientNotice,
    ModalNotice,
    NavNotices,
    LoadDocModal,
    ClientIncomingNotice,
    ShowDocModal,
    AppFilterPageIncoming,
    AppTable,
  },
  mixins: [galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      activeIndex: 0,
      isLoading: false,
      outgoingMedDocs: {},
      isFilterOpen: false,
      isShowDataVisible: false,
      filterString: '',
      sortKey: 'id',
      currentDoc: {},
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getPatients'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 15,
        total: 0,
      },
      sortInverse: true,
      columns: [
        {
          caption: '№',
          key: 'id',
          inverse: true,
        },
        {
          caption: 'Статус',
          key: 'document_status',
          inverse: true,
        },
        {
          caption: 'Дата и время',
          key: 'create_datetime',
          inverse: true,
        },
        {
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          caption: 'Дата выдачи',
          key: 'issue_date',
          inverse: true,
        },
        {
          caption: 'Срок действия',
          key: 'expiration_date',
          inverse: true,
        },
        {
          caption: 'Дата и время подтверждения',
          key: 'verification_datetime',
          inverse: true,
        },
      ],
      results: [],
      isModalVisible: {
        notice: false,
        loadDoc: false,
      },
      successMessage: 'Успех!',
    };
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
  },
  watch: {
    currentPage() {
      this.getOutgoingMedDocs();
    },
  },
  created() {
    this.getOutgoingMedDocs();
  },
  methods: {
    async getOutgoingMedDocs() {
      let sortInverse = '';
      if (this.sortInverse) {
        sortInverse = '-';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      resultString = `page=${this.currentPage - 1}&per_page=10${
        this.filterString
      }&incoming_documents_order_by=${order_by}`;

      this.isLoading = true;
      let res = await incomingMedDocs.getIncomingMedDocumentV2(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.outgoingMedDocs = res.data.items;
      this.isLoading = false;
    },
    // modals
    closeShowModal() {
      this.isShowDataVisible = false;
    },
    openShowModal(data) {
      this.currentDoc = data;
      this.isShowDataVisible = true;
    },
    // filters
    openFilter() {
      this.isFilterOpen = true;
    },
    async submitFilterDoc(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getOutgoingMedDocs();
    },
    close() {
      this.isFilterOpen = false;
    },
    // upload file
    async createDoc() {
      try {
        let data = await incomingMedDocs.getIncomingMedDocument({});
        this.results = data.data.result;
        this.pagination.total = data.data.total;
        await this.getOutgoingMedDocs();
        this.closeModal('loadDoc');
      } catch (error) {
        this.$toast.error('Ошибка при создании документа. Пожалуйста, попробуйте еще раз.', error);
      }
    },
    onFileSelected({ target }) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    // sorting
    sortingOutgoingMedDocs(sortKey, inverse) {
      if (inverse === false) {
        return;
      }
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getOutgoingMedDocs();
    },
    //pagination
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getOutgoingMedDocs();
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    // formatting
    getDocumentStatus(status) {
      return status === 'confirmed'
        ? 'Подтверждено'
        : status === 'awaiting'
          ? 'На подтверждении'
          : status === 'rejected'
            ? 'Отклонено'
            : 'В архиве';
    },
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return '';
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return '';
    },
    // tabs
    changeActiveTab(index) {
      this.activeIndex = index;
    }
  },
};
</script>
