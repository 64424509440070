
.inspection-card__request-card.col-13
  .row.gx-1
    .row.mb-4
      .col-6.col-sl-8.col-xl-8
        .row.align-items-center
          .col.col-sl-4.px-2
            .title.title--small.pointer
              span Заключение:
          .col.admission.text-center
            span.text-color--success.text-uppercase(v-if="exam.admission_to_work === true") Допущен
            span.text-color--danger.text-uppercase(v-else) Не допущен
          .col
            span.text-uppercase {{ toLocalDateExam(exam.admission_datetime) }}&nbsp;
            span.text-uppercase {{ toLocalTimeExam(exam.admission_datetime) }}
        .row.mt-4.align-items-center
          .col-4
            .row
              .col.px-2
                .title.title--small.pointer
                  span Медработник:
          .col
            span.text-color--blue-dark {{ exam.doctor_full_name }}
      .col-7.col-sl-6.col-xl-6.d-flex
        .col.px-2
          .title.title--small.pointer
            span(v-if='exam.reasons_array !== null') Комментарий медработника:
        .col-10.col-sl-8.col-xl-6
          span
            span(v-if="exam.exam_comment && exam.exam_comment !== 'undefined'") {{ exam.exam_comment }}
            ul.list-inline
              li.list-inline-item(v-for="reason in exam.reasons_array") {{`${reason}. `}}

