
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          type="button"
          v-if="isDirty && this.collectData.length"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 ФИО пользователя:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="patient_full_name"
                    placeholder="ФИО"
                    autocomplete="off"
                    id="id"
                  )
              template(v-if="options.date")
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Дата выдачи документа:
                  .row.g-3
                    .col-7
                      date-picker(
                        v-model="form.date.issueDateFrom"
                        prefix-class="app"
                        placeholder="от"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableAfterToday"
                        @change="datepickerChange('issueDateFrom')"
                        @clear="datepickerClear('issueDateFrom')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")

                    .col-7
                      date-picker(
                        v-model="form.date.issueDateTo"
                        prefix-class="app"
                        placeholder="до"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableExpiration"
                        @change="datepickerChange('issueDateTo')"
                        @clear="datepickerClear('issueDateTo')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")


              template(v-if="options.date")
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Срок действия документа:
                  .row.g-3
                    .col-7
                      date-picker(
                        v-model="form.date.expirationDateFrom"
                        prefix-class="app"
                        placeholder="от"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableAfterTodayExp"
                        @change="datepickerChange('expirationDateFrom')"
                        @clear="datepickerClear('expirationDateFrom')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")

                    .col-7
                      date-picker(
                        v-model="form.date.expirationDateTo"
                        prefix-class="app"
                        placeholder="до"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableExpirationExp"
                        @change="datepickerChange('expirationDateTo')"
                        @clear="datepickerClear('expirationDateTo')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")

                .col
                  app-select(
                    ref="selectDocumentStatus"
                    :type="'form'"
                    :placeholder="'Статус документа'"
                    :options="document_statuses"
                    @select="selectDocumentStatus"
                  )


      // submit button
      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !this.collectData.length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
        )
          span.button__content
            span Показать

