<template lang="pug">
.profile-users
  .profile-exams__inner(:class="{'filter-is-open': isFilterOpen}")
    .profile-users__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center mb-3 mb-md-0'"
          ) Выгруженные документы

    .profile-users__content.mt-20.position-relative
      transition(name="translucent")
        div
          app-loading(v-if="isLoading" key='loading')
          app-table(
            v-if="reports.length"
            :columns="columns"
            :sortKey="sortKey"
            :data="reports"
            :paginationData="pagination"
            :currentPage="currentPage"
            @change-current-page="setCurrentPage"
            :baseUrl="baseUrl"
            :canEdit="false"
            :canRemove="false"
          )
            template(v-slot:cell='{ column, row }')
              span(v-if='column.key === "created_at"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}

              span(v-else-if='column.key === "path"')
                button(
                  v-if="row.path"
                  class=['profile-card__button--document', 'link--accent', 'text-overflow']
                  type="button"
                  @click.prevent="download(row)"
                )
                  span(v-if="row.path").text-color--blue.text-color-md-blue-dark.incomingAction {{ truncatePath(row[column.key]) }}
                  span(v-else) &#8212;

              span(v-else-if='column.key === "pending_task_type"' :class="{'text-color--orange': highlightParams(row[column.key])}") {{ getDocumentStatus(row[column.key]) }}

          div(v-else)
            .text-color--blue.text-size--small Отчеты не найдены

</template>

<script>
import ReportsApi from '@/api/reports';

import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';

import AppTable from '@/components/common/table/Table';
import { toLocalDateExam, toLocalTimeExam } from '@/helpers/utils';
import { downloadDocs } from '@/helpers/utils';

export default {
  name: 'AppAdminReport',
  components: {
    AppTitle,

    AppLoading,
    AppTable,
  },
  data() {
    return {
      isLoading: false,
      pagination: {
        limit: 10,
        total: 0,
      },
      sortKey: 'created_at',
      filterString: '',
      isFilterOpen: false,
      reports: {},
      sortInverse: false,
      columns: [
        {
          caption: 'Дата и время',
          key: 'created_at',
          inverse: false,
        },
        {
          caption: 'Имя файла',
          key: 'path',
          inverse: false,
        },
        {
          caption: 'Тип документа',
          key: 'description',
          inverse: false,
        },
        {
          caption: 'Статус',
          key: 'pending_task_type',
          inverse: false,
        },
      ],
    };
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
  },
  watch: {
    currentPage() {
      this.getPendingTasks();
    },
  },
  created() {
    this.getPendingTasks();
  },
  methods: {
    async getPendingTasks() {
      this.isLoading = true;
      let res = await ReportsApi.getReports(this.currentPage - 1);
      this.reports = res.data.items;
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.isLoading = false;
    },
    //get file
    download(data) {
      downloadDocs(data, ['path'])
    },
    //pagination
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getPendingTasks();
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    // formatting
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    toLocalTimeExam(date) {
      if (date) {
        return toLocalTimeExam(date);
      } else return false;
    },
    truncatePath(path) {
      let maxLength = 50;
      return path.substring(0, maxLength);
    },
    getDocumentStatus(status) {
      return status === 'complete'
        ? 'Готов к скачиванию'
        : status === 'pending'
          ? 'В ожидании обработки'
          : status === 'work'
            ? 'Документ обрабатывается'
            : 'Произошла ошибка';
    },
    highlightParams(data) {
      return data === 'error';
    },
  },
};
</script>
