<template lang="pug">
  section(class=['user-card', 'profile-card'] :class="classes")
    .row.align-items-center.g-4.gy-xl-0
      .col-14
        .d-flex.align-items-center
          .col-auto.col-xl-4
            span.word-wrap(:title="data.full_name") {{  data.last_name !== null? data.last_name : '' }} {{  data.first_name !== null? data.first_name : '' }} {{  data.middle_name !== null? data.middle_name : '' }}

                  //- span {{ data.owner_kep !== null ? data.owner_kep : data.doctor_full_name  }}

          .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2(v-if="currentUser.role !== 'CLIENT'")
            span.word-wrap {{ data.company_name }}

          .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2
            span {{$moment(data.date_birth).format('DD.MM.YYYY')}}

          .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2
            span(v-if="data.phone") {{ data.phone }}
            span.text-color--blue(v-else) &#8212;

          .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2
            span(v-if="data.personal_number") {{ data.personal_number }}
            span.text-color--blue(v-else) &#8212;

          .col-7.col-md-2
            .d-flex.align-items-center
              span.text-color--gray-light.me-2.d-md-none
                so-icon(icon="diary")
              span(v-if="data.exams_count") {{ data.exams_count }} {{ declOfNum(data.exams_count, ['осмотр', 'осмотра', 'осмотров']) }}
              span.text-color--blue(v-else) нет осмотров

          .col-7.col-md-auto(v-if="currentUser.role !== 'CLIENT'")
            .d-flex.align-items-right
              span.ms-auto.text-color--accent.d-none.d-xl-inline
                so-icon(icon="right-arrow")

          .col-7.col-md-2(v-if="currentUser.role !== 'ADMIN'")
            .d-flex.align-items-right
              span.ms-auto.text-color--accent.d-none.d-xl-inline
                so-icon(icon="right-arrow")


          //- app-avatar(
          //-   :src="data.avatar"
          //-   :viewPhoto="true"
          //-   :type="'patient'"
          //-   @openGallery="openGallery"
          //-   @change="changeAvatar"
          //- )
          //- .ms-3
          //-   ul
          //-     li(v-if="data.email")
          //-       span(v-if="data.email" :title="data.email") {{ data.email }}
          //-       span(v-else) нет e-mail

      //- //- .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2
      //-   span(v-if="data.phone") {{ data.phone }}
      //-   span.text-color--blue(v-else) нет телефона

                //- (v-if="currentUser.role === 'CLIENT'")
      //- .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2
      //-   span {{ data.company_name }}

          //- (v-if="currentUser.role !== 'CLIENT'")

      //- .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-2
        span {{ toLocalDate(data.creation_date) }}

      //- .col-14.col-md-3.col-xl-4
        span.word-wrap(:title="data.full_name") {{ `${data.first_name} ${data.last_name} ${data.middle_name}` }}

      //- .col-7.col-md-2
        .d-flex.align-items-center
          span.text-color--gray-light.me-2.d-md-none
            so-icon(icon="diary")
          span(v-if="data.exams_count") {{ data.exams_count }} {{ declOfNum(data.exams_count, ['осмотр', 'осмотра', 'осмотров']) }}
          span.text-color--blue(v-else) нет осмотров

      //- .col-7.col-md-1
        .d-flex.align-items-right
          .d-flex.align-items-right
          span.ms-auto.text-color--accent.d-none.d-xl-inline
            so-icon(icon="right-arrow")

</template>

<script>
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import {toLocalDate, declOfNum} from '@/helpers/utils';

import AppAvatar from '@/components/profile/common/Avatar';

export default {
  name: 'AppAdminPatientCard',
  components: {
    AppAvatar,
    
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
  computed: {
    devicesCount() {
      return Array.from(new Set(this.data.device_serials)).length;
    },
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    openGallery(data) {
      this.$emit('openGallery', {gallery: data, patient: this.data});
    },
    changeAvatar() {
      this.$emit('changeAvatar', this.data);
    },
  },
};
</script>

<style lang="scss">
.patient-card {
  span {
    word-break: break-word;
  }
  &.profile-card {
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;

    .icon {
      &__breathalyzer {
        width: $spacer * 1.8;
        height: $spacer * 1.8;
      }
    }

    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
    }
  }
}
</style>
