
.profile-map
  .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-map__header
      .row.align-items-center.flex-column-reverse.flex-md-row.g-4
        .col
          .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
            app-back-button(:classes="'me-5'")
            app-title(
              :type="'large'"
              :classes="'text-center'"
            ) {{ isTouchDevice ? 'Геокарта' : 'Просмотр геокарты' }}

        .col-auto
          router-link(:to="{name: 'map-routes'}" class=['d-flex align-items-center', 'text-size--small link--accent'])
            span.flex-shrink-0.d-flex.me-3
              so-icon(icon="map-route")
            span Просмотреть перемещения устройств
    // map
    .profile-map__content.position-relative.mt-30.mt-md-20
      .profile-map__container
        app-loading(v-if="map.loading")
        yandex-map(
          :settings="v.map.default"
          :options="map.options"
          :coords="map.center"
          :scroll-zoom="false"
          :zoom="map.zoom"
          :controls="map.controls"
          :behaviors="map.behaviors"
          :show-all-markers="true"
          @map-was-initialized="initializedMap"
        )
          ymap-marker(
            v-for="(placemark, index) in map.placemarks"
            :key="index"
            :marker-id="index"
            :coords="placemark.coords"
            :icon="placemark.marker"
            @balloonopen="bindListener(placemark.balloon)"
            @balloonclose="unbindListener(placemark.balloon)"
          )
            app-map-balloon(slot="balloon" :data="placemark.balloon")

      // exams
      .mt-5.position-relative
        transition(name="translucent")
          app-loading(v-if="examsLoading")
          app-error-message(v-if="examsError" :message="examsError")

          div(v-if="exams")
            .d-none.d-xl-block
              .profile-table.is-exams-table(v-if="map.filteredExams.length" :key="map.filteredExams.length")
                .profile-table__header
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        type="button"
                        @click="sortingExams(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            so-icon(:icon="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            so-icon(icon="down-arrow")

                ul.profile-table__body(id="map-exams")
                  li.profile-table__row(v-for="exam in map.filteredExams.slice(0, pagination.offset)" :key="exam.id")
                    app-exam-card(
                      :data="exam"
                      :type="'map'"
                      :parent-id="'map-exams'"
                      :columns="columns"
                      @map="showToMap"
                      @openCertificate="openCertificate"
                      @openModal="openExamDetailModal"
                    )

              button(
                v-if="pagination.offset < map.filteredExams.length"
                type="button"
                @click="pagination.offset += pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4 me-4']
              ) Показать еще (+{{ (map.filteredExams.length - pagination.offset) >= pagination.limit ? pagination.limit : map.filteredExams.length - pagination.offset}})

              button(
                v-if="pagination.offset > pagination.limit"
                type="button"
                @click="pagination.offset = pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4']
              ) Свернуть


            // slider for mobile
            .profile-modile-slider.d-xl-none
              swiper(
                :options="Object.assign({}, slider.common, slider.exams)"
                :auto-update="true"
                :auto-destroy="false"
              )
                swiper-slide(v-for="exam in map.filteredExams.slice(0, pagination.offset)" :key="exam.id")
                  app-exam-card-mobile(
                    :data="exam"
                    @openModal="openExamDetailMobile"
                    :type="'map'"
                    @map="showToMap"
                  )
                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])



  // filter
  app-filter(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    :count="examsCount"
    @getCount="getCountFilter"
    @open="openFilter"
    @close="closeFilter"
    @clear="clearFilter"
    @submit="submitFilter"
  )
  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :type="'map'"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @openCertificate="openCertificate"
    @close="closeModal('examDetailMobile')"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )
