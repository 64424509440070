<template lang="pug">
  ul
    li(v-for="(el) in data")
      .mb-3(v-if="el.device") Устройство {{el.device}}
      .text-color--blue.text-size--small.mb-1 {{el.coords[0]}}, {{el.coords[1]}}
      button(
        v-if="el.count"
        type="button"
        :data-device="el.device",
        :data-coords="el.coords"
        class=['text-decoration--underline', 'text-size--small', 'link--accent']
      ) {{ el.count }} {{ declOfNum(el.count, ['осмотр', 'осмотра', 'осмотров'])}} за данный период
</template>

<script>
import {declOfNum} from '@/helpers/utils';

export default {
  name: 'AppMapBalloon',
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
};
</script>

<style lang="scss">
.profile-map {
  & [class*='balloon'] {
    border: none;
    box-shadow: none;
  }
  & [class*='balloon__layout'] {
    border-radius: calc($border-radius-lg / 1.25);
    box-shadow: get($shadows, default);
  }
  & [class*='balloon__content'] {
    font-family: $font-family-base;
    line-height: 1.3;
    @include font-size(get($text-sizes, normal));
    color: get($colors, blue-dark);
    padding: $spacer * 1.6 $spacer * 1.6 $spacer * 2.2 $spacer * 1.6;

    ul {
      li {
        & + li {
          margin-top: $spacer * 3;
        }
      }
    }
    & > ymaps {
      min-width: $spacer * 24 !important;
      padding-bottom: $border-width;
    }
  }
  & [class*='balloon__close'] + [class*='balloon__content'] {
    padding-right: $spacer * 3;
    margin-right: 0;
  }
  & [class*='balloon__close'] {
    float: none;
    position: absolute;
    top: $spacer * 0.5;
    right: $spacer * 0.5;
    margin: 0;
  }
  & [class*='balloon__close-button'] {
    width: $spacer * 1.2;
    height: $spacer * 1.2;
    opacity: 1;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNSAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGw9IiNGMzkyMDAiIGQ9Ik02LjIzODA0IDdMMS4zNTI0MiAyLjA3ODE3QzEuMTA3NiAxLjgzMTUyIDEuMTA3NiAxLjQzMTYzIDEuMzUyNDIgMS4xODQ5OUMxLjU5NzI2IDAuOTM4MzM2IDEuOTk0MjMgMC45MzgzMzYgMi4yMzkwNSAxLjE4NDk5TDcuMTI0NyA2LjEwNjgyTDEyLjAxMDMgMS4xODQ5OUMxMi4yNTUyIDAuOTM4MzM2IDEyLjY1MjEgMC45MzgzMzYgMTIuODk2OSAxLjE4NDk5QzEzLjE0MTggMS40MzE2NSAxMy4xNDE4IDEuODMxNTQgMTIuODk2OSAyLjA3ODE3TDguMDExMzEgN0wxMi44OTY5IDExLjkyMThDMTMuMTQxOCAxMi4xNjg1IDEzLjE0MTggMTIuNTY4NCAxMi44OTY5IDEyLjgxNUMxMi42NTIxIDEzLjA2MTcgMTIuMjU1MSAxMy4wNjE2IDEyLjAxMDMgMTIuODE1TDcuMTI0NyA3Ljg5MzE4TDIuMjM5MSAxMi44MTVDMS45OTQyNSAxMy4wNjE3IDEuNTk3MjggMTMuMDYxNiAxLjM1MjQ2IDEyLjgxNUMxLjEwNzY1IDEyLjU2ODQgMS4xMDc2NSAxMi4xNjg1IDEuMzUyNDYgMTEuOTIxOEw2LjIzODA0IDdaIi8+DQo8L3N2Zz4NCg==')
      50% no-repeat;
  }
  & [class*='balloon__tail'] {
    background: none;
  }
}
</style>
