
ul.pagination.d-flex.align-items-center(v-if="pages.length > 1")
  li.pagination__item(v-if="pages.length > 5")
    router-link(
      :disabled="currentPage < 2"
      :class="['pagination__link', 'd-block', 'pe-0']"
      :to="{path: url, query: mergeQuery(1)}"
    )
      so-icon(icon="left-double-arrow")

  li.pagination__item
    router-link(
      :disabled="currentPage < 2"
      :class="['pagination__link', 'd-block']"
      :to="{path: url, query: mergeQuery(currentPage - 1)}"
    )
      so-icon(icon="left-arrow")

  template(v-if="pages.length < 5")
    li.pagination__item(v-for="page in pages" :key="page")
      router-link(
        :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
        :to="{path: url, query: mergeQuery(page)}"
      ) {{ page }}


  template(v-else)
    template(v-if="currentPage < 2")
      li.pagination__item(v-for="page in pages.slice(currentPage - 1, currentPage + 2)" :key="page")
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
          :to="{path: url, query: mergeQuery(page)}"
        ) {{ page }}

      li.pagination__item.mx-3 ...

      li.pagination__item
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === pages.length}]"
          :to="{path: url, query: mergeQuery(pages.length)}"
        ) {{ pages.length }}

    template(v-else-if="currentPage < (pages.length - 2)")
      li.pagination__item(v-for="page in pages.slice(currentPage - 2, currentPage + 1)" :key="page")
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
          :to="{path: url, query: mergeQuery(page)}"
        ) {{ page }}

      li.pagination__item.mx-3 ...

      li.pagination__item
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === pages.length}]"
          :to="{path: url, query: mergeQuery(pages.length)}"
        ) {{ pages.length }}


    template(v-else)
      li.pagination__item
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === 1}]"
          :to="{path: url, query: mergeQuery(1)}"
        ) 1

      li.pagination__item.mx-3 ...

      li.pagination__item(v-for="page in pages.slice(pages.length - 4, pages.length)" :key="page")
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
          :to="{path: url, query: mergeQuery(page)}"
        ) {{ page }}


  li.pagination__item
    router-link(
      :disabled="currentPage === pages.length"
      :class="['pagination__link', 'd-block']"
      :to="{path: url, query: mergeQuery(currentPage + 1)}"
    )
      so-icon(icon="right-arrow")

  li.pagination__item(v-if="pages.length > 5")
    router-link(
      :disabled="currentPage === pages.length"
      :class="['pagination__link', 'd-block', 'ps-0']"
      :to="{path: url, query: mergeQuery(pages.length)}"
    )
      so-icon(icon="right-double-arrow")

