<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close" :classes="`modal-refuse`")
    template(v-slot:body)
      .row
        .col-14
          .text-center.pt-4
            app-title(:type="'large'") Изменить индивидуальные границы?
            p.text-color--blue.mt-3.text-uppercase Для пользователя {{ data.patient_full_name }}
        .col-14.mt-4.mb-3(v-if='toggle')
          li.profile-table__row(v-for="border of bordersData" :key="border.num")
            section(class=['user-card', 'user-card-history'])
              .row.align-items-center.g-4.gy-xl-0
                .col-1
                    div {{ border.num }}
                .col-4
                    div {{ border.name }}
                .col-5
                    span.presonal-border-text от
                    input(
                        :class="['personal-border', 'form__field']"
                        type="text"
                        placeholder=""
                        v-model="border.min_value"
                    )
                    span.presonal-border-text до
                    input(
                        :class="['personal-border', 'form__field']"
                        type="text"
                        placeholder=""
                        v-model="border.max_value"
                    )
                .col-3
                    date-picker(
                        prefix-class="app"
                        placeholder="до"
                        format="DD.MM.YYYY"
                        :editable="true"
                        v-model="border.end_date"
                        value-type="YYYY-MM-DD"
                    )
                    //data[border.id].max_val
        template(v-if='!toggle')
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="rejectEditBorders"
                type="button"
                class=['button form__button button--border-blue']
              )
                span.button__content Нет
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="toggle = true"
                type="button"
                class=['button button--accent w-100']
              )
                span.button__content Да
        template(v-else)
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="rejectEditBorders"
                type="button"
                class=['button form__button button--border-blue']
              )
                span.button__content Отменить
          .col-7
            .d-flex.justify-content-center.mt-4
              button(
                @click="confirmEditBorders"
                type="button"
                class=['button button--accent w-100']
              )
                span.button__content Подтвердить
</template>

<script>
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import patientApi from '@/api/patients';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'handlerSelectModal',
  components: {
    AppModal,
    AppTitle,
    
    DatePicker,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      bordersData: [
        {
          id: 'bp_high',
          num: 1,
          name: 'Давление систолическое',
          min_value: null,
          max_value: null,
          end_date: null,
        },
        {
          id: 'bp_low',
          num: 2,
          name: 'Давление диастолическое',
          min_value: null,
          max_value: null,
          end_date: null,
        },
        {
          id: 'heart_rate',
          num: 3,
          name: 'ЧСС',
          min_value: null,
          max_value: null,
          end_date: null,
        },
        {
          id: 'body_temp',
          num: 4,
          name: 'Температура',
          min_value: null,
          max_value: null,
          end_date: null,
        },
        {
          id: 'alcohol',
          num: 5,
          name: 'Алкоголь',
          min_value: null,
          max_value: null,
          end_date: null,
        },
      ],
      toggle: false,
    };
  },
  watch: {
    toggle(newVal) {
      if (newVal === true) {
        this.getPatientBorders();
      }
    },
  },
  methods: {
    async getPatientBorders() {
      let res = await patientApi.getPatientBorders(this.data.patient_code);
      for (const key in res.data) {
        if (res.data[key] && res.data[key].border_type) {
          const borderType = res.data[key].border_type;
          const min_value = res.data[key].min_value;
          const max_value = res.data[key].max_value;
          const matchingBorder = this.bordersData.find((border) => border.id === `${borderType}`);
          if (matchingBorder) {
            matchingBorder.min_value = min_value;
            matchingBorder.max_value = max_value;
          }
        }
      }
    },
    rejectEditBorders() {
      this.toggle = false;
      this.$emit('reject');
    },
    confirmEditBorders() {
      this.toggle = false;
      this.data['personal_borders'] = {};
      for (const key in this.bordersData) {
        const borderData = this.bordersData[key];
        this.data['personal_borders'][borderData.id] = {
          border_type: borderData.id,
          end_date: borderData.end_date,
          min_value: Number(borderData.min_value),
          max_value: Number(borderData.max_value),
        };
      }
      this.$emit('confirm', this.data);
    },
    close() {
      this.toggle = false;
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.refuseCauseHeader {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 14px;
  color: #000000;
}
.refuseCause {
  font-size: 14px;
  color: #fff;
  background: #59658d;
  border-radius: 4px;
  padding: 4px 14px;
}
.modal-refuse {
  width: 771px;
}

.block-history-title:hover {
  cursor: pointer;
}

.user-card-history {
  padding: 10px 0;
  color: #505e8e;
}

.personal-border {
  width: 80px !important;
}

.presonal-border-text {
  padding: 10px;
}
</style>
