import axios from '@/api/axios';

const getDevices = (credentials) => axios.get(`/api/v2/devices${credentials}`);

const updateDeviceCheck = (device_id, credentials) => axios.put(`/api/v2/devices/${device_id}`, credentials);

const getDeviceBySerialId = (serial_id) => axios.get(`/api/v2/devices/${serial_id}`);

const getDeviceIntegrity = (serial_id) => axios.get(`/api/v2/devices_integrity_controls/`);

const getDeviceDociment = (credentials) => axios.post('/api/v2/devices/document', credentials);

const createDevice = (credentials) => axios.post('/api/v2/devices', credentials);

const exportNewDevices = (credentials) => axios.post(`/api/v2/devices/export?${credentials}`, {}, {responseType: 'blob'});

const deleteDevice = (device_serial) => axios.delete(`/api/v2/devices/?device_serial=${device_serial}`);


export default {
  getDevices,
  getDeviceBySerialId,
  getDeviceDociment,
  createDevice,
  exportNewDevices,
  deleteDevice,
  getDeviceIntegrity,
  updateDeviceCheck
};
