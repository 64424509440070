<template lang="pug">
.profile-doctors
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-7
        .d-flex.align-items-center
          app-title(:type="'large'") Настройки системы
    .d-flex.system-setup-container.mt-5
      ul.list-group.col-12

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-pdn'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Пользовательские соглашения

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-borders'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Границы выпуска (по умолчанию)

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-autobot-settings'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Настройки автобота

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-filtering-inspections'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Настройки фильтрации осмотров

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-access-control'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Управление доступом

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-inspection-control'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Управление осмотрами

        li.profile-sidebar__menu-item
            router-link(
              tag="button"
              :to="{name: 'system-setup-server-information'}"
              class=['button button--medium button--white button--shadow w-100']
            )
              .d-flex.align-items-left Информация о сервере

</template>

<script>
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';

export default {
  name: 'SystemSetupDashboard',
  components: {
    AppTitle,
    AppMultipleSelect,
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },

  methods: {},
};
</script>
