<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(
    v-if="isVisible"
    @close="() => closeModal(false)"
    :classes="`doctor-exam-detail-modal modal-reasons`"
  )
    template(v-slot:body)
      .text-center.mb-2
        app-title(:type="'large'") ВЫ УВЕРЕНЫ ЧТО ХОТИТЕ ОТКЛЮЧИТЬ АССИСТЕНТА?

        p.text-color--orange.py-4 Будьте внимательны при принятии решения по осмотру самостоятельно!

        .d-inline-flex.justify-content-between.col-10
          .d-flex.justify-content-end
            button(
              type="button"
              @click="() => closeModal(false)"
              class="button button--accent button--height mt-3"
            )
              span.button__content.d-flex.align-items-center Нет, оставить ассистента

          .d-flex.justify-content-end
            button(
              type="button"
              @click="() => closeModal(true)"
              class="button button--border-blue button--height mt-3"
            )
              span.button__content.d-flex.align-items-center Подтвердить отключение
</template>

<script>
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

export default {
  name: 'AddDoctorReasons',
  components: {
    AppModal,
    AppTitle
  },
  props: {
    isVisible: Boolean,
    protector: Boolean
  },
  methods: {
    closeModal(v) {
      this.$emit('update:protector', v);
      this.$emit('close');
    }
  }
};
</script>
