
//- .profile-requests#profile-requests
      .profile-requests__header.col-14.col-md-auto
        app-title(:type="'large'") Заявки на регистрацию
                //- button(
                //-   type="button"
                //-   @click="getRequests"
                //-   class=['d-flex align-items-center ms-4', 'text-color--blue text-size--small link--accent']
                //- )
                //-   span.flex-shrink-0.me-2
                //-     so-icon(icon="reload")
                //-   span Обновить

      //- .col-12.d-xl-none.text-center.mb-4.mb-md-5
      //-   p.text-color--blue.text-size--small.text-family--light(v-if="requestsPending") {{ requestsPending.length }} заявки на регистрацию
      //-   app-title(:type="'large'") Заявки

      .position-relative
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

      transition(name="translucent")
        div(v-if="requests" :class="{'is-loading': isLoading}")
          .d-flex.align-items-center.mb-5
            app-tab-nav(
              :tabs="tabs"
              :selected="activeTab"
              :theme="'separate'"
              @selected="setActiveTab"
            )

          app-tab(v-for="tab in tabs" :key="tab.id" :is-selected="activeTab === tab.id")
            .devices-tab(v-if="(activeTab === allId || activeTab === v.types.device) && requestsPendingDevices.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Устройства (+{{requestsPendingDevices.length}})
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.pending.devices.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1.mb-5
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsPendingDevices" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")


            //- .pending-users-tab(v-if="(activeTab === allId || activeTab === v.types.user) && requestsPendingUsers.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Клиенты (+{{requestsPendingUsers.length}})
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.pending.users.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsPendingUsers" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

          app-tab(:is-selected="activeTab === v.statuses.rejected")
            .mt-5(v-if="requestsRejectedDevices.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Отказаные заявки устройств
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.rejected.devices.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsRejectedDevices.slice(0, pagination.offset.devices)" :key="index")
                  app-admin-request-card(:data="request")

              button(
                v-if="pagination.offset.devices < requestsRejectedDevices.length"
                type="button"
                @click="pagination.offset.devices += pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4 me-4']
              ) Показать еще (+{{ (requestsRejectedDevices.length - pagination.offset.devices) >= pagination.limit ? pagination.limit : requestsRejectedDevices.length - pagination.offset.devices}})

              button(
                v-if="pagination.offset.devices > pagination.limit"
                type="button"
                @click="pagination.offset.devices = pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4']
              ) Свернуть

            //- .mt-5(v-if="requestsRejectedUsers.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Отказаные заявки клиентов
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.rejected.users.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsRejectedUsers.slice(0, pagination.offset.users)" :key="index")
                  app-admin-request-card(:data="request")

              button(
                v-if="pagination.offset.users < requestsRejectedUsers.length"
                type="button"
                @click="pagination.offset.users += pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4 me-4']
              ) Показать еще (+{{ (requestsRejectedUsers.length - pagination.offset.users) >= pagination.limit ? pagination.limit : requestsRejectedUsers.length - pagination.offset.users}})

              button(
                v-if="pagination.offset.users > pagination.limit"
                type="button"
                @click="pagination.offset.users = pagination.limit"
                class=['text-size--small text-decoration--underline link--accent', 'mt-4']
              ) Свернуть
          app-tab(v-for="tab in tabs" :key="tab.id" :is-selected="activeTab === tab.id")
            .mt-5(v-if="(activeTab === allId || activeTab === v.types.device_update) && requestsUpdateDevices.length")
              .row.align-items-center.g-4.mb-4
                .col-14.col-md-auto.flex-grow-1
                  app-title(:classes="'text-color--blue'") Поверка СИ (+{{requestsUpdateDevices.length}})
                .col-auto
                  app-select(
                    @select="sortBy"
                    :options="sorting.pending.devices.sortOptions"
                    :placeholder="'выберите...'"
                  )

              .row.g-2.g-md-4.g-xl-1
                .col-14.col-md-7.col-xl-14(v-for="(request, index) in requestsUpdateDevices" :key="index")
                  app-admin-update-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

      // reject modal
      transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.reject" @close="closeModal('reject')")
          template(v-slot:body)
            .row.justify-content-center
              .col-14.col-md-10
                .text-center
                  app-title(:type="'large'") Отказать в регистрации
                  .mt-3.text-uppercase
                    ul(v-if="currentRequest.type_code === v.types.device")
                      li(v-for="num in currentRequest.additional_params.device_serials") Серийный номер МИ {{ num }}
                    p(v-if="currentRequest.type_code === v.types.user") {{ currentRequest.user_full_name }}

                .mt-5
                  app-validation-errors(
                    v-if="validationErrors"
                    :classes="'mb-3'"
                    :validation-errors="validationErrors"
                  )
                  form(@submit.prevent="onSubmitRejectForm" :class="['form', {'is-submitting': isLoading}]")
                    .row.flex-column.align-items-center
                      .col.mb-5
                        label.form__label
                          textarea(
                            :class="['form__field', { 'is-error': v$.form.message.$errors.length }]"
                            v-model="v$.form.message.$model"
                            @focus="v$.form.message.$touch"
                            placeholder="Причина отказа"
                          )
                      .col
                        button(
                          type="submit"
                          :disabled="v$.$invalid"
                          class=['button', 'button--accent', 'form__button']
                        )
                          span.button__content Отправить


      // success modal
      transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.success" @close="closeModal('success')" :classes="'d-flex flex-column justify-content-center'")
          template(v-slot:body)
            .text-center
              app-title(:type="'large'") {{ successMessage }}
              p.text-size--small.text-color--blue.mt-3 {{ currentRequest.user_full_name }} будет уведомлен о принятом решении
      //Выбрать ответственного при нажатии
      app-confirm-check-modal(
        :is-visible="isModalVisible.confirm"
        :currentRequest="currentRequest"
        @openModal="openExamDetailModal"
        @close="closeModal('confirm')"
      )
