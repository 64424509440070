
<div class="so-table__container">
  <table class="SO-table">
    <thead>
      <tr class='flex'>
        <th
          v-for="column in columns"
          :key="column.key"
          :style="{ flex: column.flex || 1 }"
        >
          <button
            v-if="column.caption || column.icon"
            type="button"
            @click="handleSorting(column.key, column.inverse)"
            :class="[
              'SO-table__sort-button',
              { 'is-active': sortKey === column.key },
            ]"
          >
            <span
              v-if="column.caption"
            >{{ column.caption }}<span class="me-4"></span>
            </span>
            <so-icon
              v-else
              :icon="column.icon"
              class="me-4"
            ></so-icon>
            <so-icon icon="down-arrow"></so-icon>
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <so-table-row
        v-for="row in data"
        :key="row.id"
        :row="row"
        :columns="columns"
        :canEdit="canEdit"
        :canRemove="canRemove"
        @edit-modal="handleEditModal"
        @delete-modal="handleDeleteModal"
      >
        <template v-slot="{ row }">
          <slot v-bind="{ row }"></slot>
        </template>

        <template v-slot:actionTools="{ row, column }">
          <slot name="actionTools" v-bind="{ row, column }"></slot>
        </template>

        <template v-slot:cell="{ column, row }">
          <slot name="cell" v-bind="{ column, row }"></slot>
        </template>
      </so-table-row>
    </tbody>
  </table>

  <div class="SO-pagination__container">
    <so-pagination
      v-if="paginationData.total"
      :total="paginationData.total"
      :limit="paginationData.limit"
      :current-page="currentPage"
      @change-current-page="handleCurrentPageChange"
    ></so-pagination>
  </div>

  <slot name="count"></slot>
</div>
