
<div class="profile-doctor-exam__container">
  <div class="profile-doctor-exam" v-show="!examInWork">
    <div class="profile-doctor-exam__header">

      <div class="col">
        <button class="mb-2 mt-5 button button--accent button--medium" v-show="!shiftStarted || shiftPaused" type="button" @click="startShift" style="width: 350px;">
          <span class="button__content">
            <span class="text-uppercase">{{ !shiftPaused ? 'Начать рабочую смену' : 'Продолжить рабочую смену' }}</span>
          </span>
        </button>
        <app-validation-errors v-if="validationErrors.length" :validation-errors="validationErrors"></app-validation-errors>
        <div class="col-14" v-if="shiftStarted && exam.body === '' && !shiftPaused">
          <app-title :type="'large'"></app-title>
          <div class="d-flex system-setup-container">
            <div class="button button--medium button--white button--shadow noHover col-10">
              <div class="d-flex align-items-center text-left">Осмотров в очереди нет. Ожидайте назначения осмотра.</div>
            </div>
          </div>
        </div>
        <div class="col-14" v-if="shiftStarted && exam.body !== '' && !shiftPaused">
          <app-title :type="'large'"></app-title>
          <div class="d-flex system-setup-container">
            <div class="button button--medium button--white button--shadow col-10">
              <div class="d-flex justify-content-between align-items-center text-center pl-5">Вам назначен осмотр
                <button class="button button--accent button--medium" type="button" @click="getExamToWork" style="width: 350px;">
                  <span class="button__content">
                    <span>Взять в работу</span>
                  </span>
                </button>
                <div class="pl-5">осталось {{ remainingTime }} секунд</div>
              </div>
            </div>
          </div>
        </div>
        <div class="memo pt-5">
          <ul class="memo-list">
            <li class="memo-link"><strong>Памятка</strong></li>
            <li class="memo-link">Для начала рабочего дня, нажмите кнопку «Начать рабочую смену»;</li>
            <li class="memo-link">Для перерыва в назначении осмотров, нажмите кнопку «Пауза»;</li>
            <li class="memo-link">Для завершения рабочей смены, нажмите  «Окончить смену», но прежде, вы должны быть на паузе;</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="profile-doctor-exam__footer">
      <div class="row align-items-center">
        <div class="col-14 mb-5">
          <app-title :type="'large'">Информация о смене</app-title>
          <div class="d-flex system-setup-container">
            <ul class="list-group d-flex shift-buttons flex-wrap">
              <li class="profile-sidebar__menu-item" v-for="(card, index) in shiftInformationCards" :key="index">
                <button class="button button--medium button--white button--shadow w-100 noHover">
                  <div class="d-flex align-items-left">{{ card.label }}
                    <span class="ms-3" :class="card.class" v-if="card.value !== undefined && card.value !== null"><strong>{{ card.value }}</strong></span>
                    <span class="ms-3" v-else>&#8212;</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-14 mb-5">
          <app-title :type="'large'">Информация об осмотрах</app-title>
          <div class="d-flex system-setup-container">
            <ul class="list-group d-flex shift-buttons flex-wrap">
              <li class="profile-sidebar__menu-item" v-for="(card, index) in ExamInformationCards" :key="index">
                <button class="button button--medium button--white button--shadow w-100 noHover">
                  <div class="d-flex align-items-left">{{ card.label }}
                    <span class="ms-3" v-if="card.value !== undefined && card.value !== null"><strong>{{ card.value }}</strong></span>
                    <span class="ms-3" v-else>&#8212;</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-14" v-if="shiftStarted">
          <app-title :type="'large'">Доступные действия</app-title>
          <div class="d-flex system-setup-container">
            <ul class="list-group d-flex action-buttons flex-wrap">
              <li class="profile-sidebar__menu-item" v-for="(button, index) in actionButtons" :key="index">
                <button class="button button--medium button--white button--shadow w-100 button--border-blue" @click="handleButtonClick(index)" :disabled="button.disabled">
                  <div class="d-flex align-items-center justify-content-center">
                    <so-icon :name="button.iconName" class="me-3"></so-icon>
                    <span>{{ button.label }}</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="examInWork">
    <doctor-exams-detail :showed="examInWork" :uuid="exam.body" :examProcessed="examProcessed" :examReturned="examReturned" :sendExamAndStop="sendExamAndStop"></doctor-exams-detail>
  </div>
</div>
