import axios from '@/api/axios';

const register = (credentials) => axios.post('/api/v1/auth/regRequest', credentials);

const regStatus = (credentials) =>
  axios.get('/api/v1/auth/regRequest', {params: credentials});

const login = (credentials) => axios.post('/api/v2/login/login', credentials);

const logout = () => axios.post('/api/v2/login/logout');

const getCurrentUser = () => axios.get('/api/v2/login/me');

// V2
const loginEsia = () => axios.get('/api/v2/login/esia/get_link')

export default {
  register,
  regStatus,
  login,
  logout,
  getCurrentUser,
  loginEsia
};
