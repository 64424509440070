
.profile-mobile-header.d-flex.align-items-center.d-xl-none
  .profile-mobile-header__user.d-flex.align-items-center(v-if="currentUser")
    router-link(:to="{name: 'dashboard'}" class=['d-block', 'me-4'])
      app-avatar(
        :classes="'large'"
        :src="currentUser.avatar"
      )
    ul.text-size--small
      li.mb-1
        span.text-color--gray-light Личный кабинет
        span.text-color--accent(v-if="userRole === 'admin'") &ensp;admin
      li.mb-1
        app-title(:routerLink="{name: 'settings'}") {{ currentUser.full_name }}
      li
        span {{ currentUser.email }}
  
  .profile-mobile-header__menu.ms-auto
    .row.flex-column.align-items-center.g-3
      .col
        button(
          @click="onLogout"
          type="button"
          class=['link--accent']
        )
          .d-flex.align-items-center
            so-icon(icon="exit")
      .col
        router-link(
          :to="{name: 'settings'}"
          active-class='is-active'
          class=['link--accent', 'd-flex align-items-center']
        )
          so-icon(icon="settings")


