<template lang="pug">
.profile-doctors
  .row.align-items-center
    .col-14.align-items-center
      app-title(:type="'large'") Настройки системы. Настройки автобота
    .row.align-items-center.g-4.mb-5
      ul.profile-table__body
        li.profile-table__row(v-for="border in bordersIds" :key="border.num")
          section(
            v-if='resultBorders[border.id]'
            class=['user-card', 'user-card-history']
          )
            .row.align-items-center.g-4.gy-xl-0.mb-3
              .col-8.d-flex
                label.form__checkbox.check
                  input(
                    type="checkbox"
                    value="person"
                    name="autobot-settings"
                    :checked="resultBorders[border.id].enabled"
                    v-on:change="setCheckbox(resultBorders[border.id])"
                    )
                  i.form__checkbox-icon.me-4
                  span.form__checkbox-caption.text-uppercase {{ border.name }}
                div.d-flex(v-if="border.num <= 8")
                  .col.d-flex
                    span.presonal-border-text.text-uppercase от
                    input(
                      :class="['personal-border', 'form__field me-5']"
                      type="number"
                      v-model="resultBorders[border.id].min_value"
                    )
                    span.presonal-border-text.text-uppercase до
                    input(
                      :class="['personal-border', 'form__field me-5']"
                      type="number"
                      v-model="resultBorders[border.id].max_value"
                    )
                    button(
                      v-if="currentUser.role !== 'CLIENT'"
                      type="button"
                      @click="sendBorder(resultBorders[border.id])"
                      class=['text-decoration--underline text-size--small link--accent text-uppercase']
                    ) Сохранить

</template>

<script>
import AppTitle from '@/components/common/Title';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';
import AppTooltip from '@/components/common/Tooltip';

import settingsApi from '@/api/settings';

export default {
  name: 'SystemSetupAutobotSettings',
  components: {
    AppTitle,
    AppTooltip,
  },
  data() {
    return {
      user: null,
      resultBorders: {},
      bordersIds: [
        {
          id: 'bp_high',
          num: 1,
          name: 'Давление систолическое',
        },
        {
          id: 'bp_low',
          num: 2,
          name: 'Давление диастолическое',
        },
        {
          id: 'heart_rate',
          num: 3,
          name: 'ЧСС',
        },
        {
          id: 'body_temp',
          num: 4,
          name: 'Температура',
          info: 'Температура тела',
        },
        {
          id: 'alcohol',
          num: 5,
          name: 'Алкоголь',
        },
        {
          id: 'luxmeter',
          num: 6,
          name: 'Освещенность окружающей среды',
        },
        {
          id: 'area_humidity',
          num: 7,
          name: 'Влажность окружающей среды',
        },
        {
          id: 'area_temp',
          num: 8,
          name: 'Температура окружающей среды',
        },
        {
          id: 'device_verified',
          num: 9,
          name: 'Статус поверки устройства',
        },
        {
          id: 'case_opened',
          num: 10,
          name: 'Целостность корпуса МИ',
        },
        {
          id: 'device_integrity',
          num: 11,
          name: 'Контроль целостности ПО',
        },
        {
          id: 'video_object_name',
          num: 12,
          name: 'Видео осмотра',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  async created() {
    let data = await settingsApi.getAutoBotSettings();
    this.resultBorders = data.data.items.reduce(
      (acc, elm) => ({
        ...acc,
        [elm.border_type]: {
          border_type: elm.border_type,
          max_value: elm.max_value,
          min_value: elm.min_value,
          id: elm.id,
          exists: elm.exists,
          enabled: elm.enabled,
        },
      }),
      {}
    );
  },
  methods: {
    async sendBorder(data) {
      try {
        await settingsApi.setAutoBotSettings(data);
        this.$toast.success('Изменения применены');
      } catch (err) {
        this.$toast.error('Произозошла ошибка при изменении данных');
      }
    },
    async setCheckbox(data) {
      data.enabled = !data.enabled;
      try {
        await settingsApi.setAutoBotSettings(data);
        this.$toast.success('Изменения применены');
      } catch (err) {
        this.$toast.error('Произозошла ошибка при изменении данных');
      }
    },
  },
};
</script>
