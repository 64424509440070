
<ul class="pagination d-flex align-items-center" v-if="pages.length > 1">
  <!-- Кнопка "В начало" -->
  <li class="pagination__item" v-if="pages.length > 5">
    <button
      :disabled="currentPage < 2"
      :class="['pagination__link', 'd-block', 'pe-0']"
      @click="handlePageClick(1)"
    >
      <so-icon icon="left-double-arrow"></so-icon>
    </button>
  </li>

  <!-- Кнопка "Назад" -->
  <li class="pagination__item">
    <button
      :disabled="currentPage < 2"
      :class="['pagination__link', 'd-block']"
      @click="handlePageClick(currentPage - 1)"
    >
      <so-icon icon="left-arrow"></so-icon>
    </button>
  </li>

  <!-- Кнопки для страниц (если страниц меньше 5) -->
  <template v-if="pages.length < 5">
    <li class="pagination__item" v-for="page in pages" :key="page">
      <button
        :class="[
          'pagination__link',
          'd-block',
          { 'is-active': currentPage === page },
        ]"
        @click="handlePageClick(page)"
      >
        {{ page }}
      </button>
    </li>
  </template>

  <!-- Кнопки для страниц (если страниц 5 и больше) -->
  <template v-else>
    <!-- Если текущая страница ближе к началу -->
    <template v-if="currentPage < 2">
      <li
        v-for="page in pages.slice(currentPage - 1, currentPage + 2)"
        :key="page"
        class="pagination__item"
      >
        <button
          :class="[
            'pagination__link',
            'd-block',
            { 'is-active': currentPage === page },
          ]"
          @click="handlePageClick(page)"
        >
          {{ page }}
        </button>
      </li>

      <li class="pagination__item mx-3">...</li>

      <li class="pagination__item">
        <button
          :class="[
            'pagination__link',
            'd-block',
            { 'is-active': currentPage === pages.length },
          ]"
          @click="handlePageClick(pages.length)"
        >
          {{ pages.length }}
        </button>
      </li>
    </template>

    <!-- Если текущая страница в середине -->
    <template v-else-if="currentPage < pages.length - 2">
      <li
        v-for="page in pages.slice(currentPage - 2, currentPage + 1)"
        :key="page"
        class="pagination__item"
      >
        <button
          :class="[
            'pagination__link',
            'd-block',
            { 'is-active': currentPage === page },
          ]"
          @click="handlePageClick(page)"
        >
          {{ page }}
        </button>
      </li>

      <li class="pagination__item mx-3">...</li>

      <li class="pagination__item">
        <button
          :class="[
            'pagination__link',
            'd-block',
            { 'is-active': currentPage === pages.length },
          ]"
          @click="handlePageClick(pages.length)"
        >
          {{ pages.length }}
        </button>
      </li>
    </template>

    <!-- Если текущая страница ближе к концу -->
    <template v-else>
      <li class="pagination__item">
        <button
          :class="[
            'pagination__link',
            'd-block',
            { 'is-active': currentPage === 1 },
          ]"
          @click="handlePageClick(1)"
        >
          1
        </button>
      </li>

      <li class="pagination__item mx-3">...</li>

      <li
        v-for="page in pages.slice(pages.length - 4, pages.length)"
        :key="page"
        class="pagination__item"
      >
        <button
          :class="[
            'pagination__link',
            'd-block',
            { 'is-active': currentPage === page },
          ]"
          @click="handlePageClick(page)"
        >
          {{ page }}
        </button>
      </li>
    </template>
  </template>

  <!-- Кнопка "Вперёд" -->
  <li class="pagination__item">
    <button
      :disabled="currentPage === pages.length"
      :class="['pagination__link', 'd-block']"
      @click="handlePageClick(currentPage + 1)"
    >
      <so-icon icon="right-arrow"></so-icon>
    </button>
  </li>

  <!-- Кнопка "В конец" -->
  <li class="pagination__item" v-if="pages.length > 5">
    <button
      :disabled="currentPage === pages.length"
      :class="['pagination__link', 'd-block', 'ps-0']"
      @click="handlePageClick(pages.length)"
    >
      <so-icon icon="right-double-arrow"></so-icon>
    </button>
  </li>
</ul>
