<template lang="pug">
section(class=['patient-card'] :class="classes")
  app-accordion(
    :id="data.code"
    :parent-id="'patient-exams'"
    class=['profile-card', 'patient-card__accordion']
  )
    template(v-slot:header)
      .patient-card__header
        .row.align-items-center.gx-4
          .col-3
            .d-flex.align-items-center
              app-avatar(
                :src="data.avatar"
                :viewPhoto="true"
                :type="'patient'"
                @openGallery="openGallery"
                @change="changeAvatar"
              )
              .ms-3
                span.text-overflow(v-if="data.email" :title="data.email") {{ data.email }}
                span.text-color--blue(v-else) нет e-mail

          .col-2
            span(v-if="data.phone") {{ data.phone }}
            span.text-color--blue(v-else) нет телефона

          .col-2
            span {{ toLocalDate(data.creation_date) }}

          .col-3
            span.text-overflow(:title="data.first_name") {{ fullName }}

          .col-2
            .d-flex.align-items-center
              span(v-if="data.exams_count") {{ data.exams_count }} {{ declOfNum(data.exams_count, ['осмотр', 'осмотра', 'осмотров']) }}
              span.text-color--blue(v-else) нет осмотров

    template(v-slot:body)
      .patient-card__content.pb-4
        .patient-card__detail
          .row.align-items-center.gx-4
            .col-auto
              app-signature(
                :sample="data.signature"
                :theme="'shadow'"
                @openGallery="openGallery"
                @change="changeSignature"
              )
            .col
              .patient-card__detail-content
                .row.align-items-center
                  .col
                    .text-color--blue.mb-2 Водительское&nbsp;удостоверение
                    span(v-if="data.serial_number_driving_license") {{ data.serial_number_driving_license }} от {{ toLocalDate(data.driving_license_receiving) }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 СНИЛС
                    span(v-if="data.snils") {{data.snils}}
                    span.text-color--blue(v-else) &#8212;
                  .col
                    .text-color--blue.mb-2 Дата рождения
                    span(v-if="data.date_birth") {{ toLocalDate(data.date_birth) }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 Пол
                    span(v-if="data.gender") {{ data.gender == '1' ? 'Мужской' : 'Женский' }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 Табельный&nbsp;номер
                    span(v-if="data.personal_number") {{ data.personal_number }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 Карта RFID
                    span(v-if="data.rfid_id") {{ data.rfid_id }}
                    span.text-color--blue(v-else) &#8212;

                  .col-auto
                    .d-flex.flex-column.align-items-end
                      button(
                        type="button"
                        @click.prevent="changePatient(data)"
                        class=['text-color--blue-dark', 'text-decoration--underline', 'link--accent', 'mb-2']
                      ) Изменить

                      button(
                        type="button"
                        @click.prevent="deletePatient(data)"
                        class=['text-color--danger', 'text-decoration--underline', 'link--accent']
                      ) Удалить

          ul.patient-exams__list(id="patient-exams")
            li.patient-exams__list-el(v-for="exam in data.exams" :key="exam.exams_id")
              app-patient-exam-card(
                :parent-id="'patient-exams'"
                :classes="'d-none d-xl-block'"
                :data="exam"
              )

</template>

<script>
import variables from '@/helpers/variables';
import {declOfNum, toLocalDate, toLocalTimeExam, sortBy} from '@/helpers/utils';
import is from 'is_js';

import AppSignature from '@/components/profile/common/Signature';
import AppAvatar from '@/components/profile/common/Avatar';
import AppAccordion from '@/components/common/Accordion';
import AppStatus from '@/components/profile/common/Status';
import AppTooltip from '@/components/common/Tooltip';
import AppPatientExamCard from '@/components/profile/common/patients/PatientExamCard';

export default {
  name: 'AppClientPatientCard',
  components: {
    AppAccordion,
    AppAvatar,
    AppStatus,
    AppTooltip,
    AppSignature,
    AppPatientExamCard
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      is,
      v: variables,
      declOfNum,
      pagination: {
        limit: 10,
        offset: 10,
      },
      isBlocked: false,
      sortKey: 'exam_datetime',
      exams: []
    };
  },
  computed: {
    fullName() {
      return `${this.data?.last_name || ''} ${this.data?.first_name || ''} ${this.data?.middle_name || ''}`;
    }
  },
  methods: {
    toLocalDate(date) {
        return toLocalDate(date);
    },
    toLocalTime(time) {
        return toLocalTimeExam(time);
    },
    openModal(slug, data) {
      this.$emit('openModal', slug, data);
    },
    openGallery(data) {
      this.$emit('openGallery', {gallery: data, patient: this.data});
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    changeAvatar() {
      this.$emit('changeAvatar', this.data);
    },
    changeSignature() {
      this.$emit('changeSignature', this.data);
    },
    changePatient(data) {
      this.$emit('change', data);
    },
    deletePatient(data) {
      this.$emit('delete', data);
    },
  },
};
</script>

<style lang="scss">
.patient-card {
  &.is-blocked {
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }
  &__detail {
    padding: $spacer $spacer * 2.5 $spacer $spacer * 1.6;

    &-content {
      padding: $spacer $spacer * 1.6;
      background: get($gradients, blue-extra-light);
      border-radius: $border-radius-sm;
    }
  }
  .accordion {
    &__button {
      top: 50%;
      right: $spacer * 3;
      transform: translateY(-50%);

      @include mediaToDown($xxl) {
        right: $spacer * 1.6;
      }
    }
  }
  .icon {
    &__circle {
      width: $spacer;
      height: $spacer;
    }
    &__pulse,
    &__cardio,
    &__temp,
    &__breathalyzer {
      fill: get($colors, blue);
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
    &__video {
      height: $spacer * 2;
      width: $spacer * 2.5;

      @include mediaToDown($xxl) {
        height: $spacer * 2;
        width: $spacer * 2;
      }
    }
    &__map-point {
      width: $spacer * 2;
      height: $spacer * 2;

      @include mediaToDown($xxl) {
        height: $spacer * 1.6;
        width: $spacer * 1.6;
      }
    }
    &__video,
    &__map-point {
      fill: get($colors, blue-dark);
    }
  }
}
.patient-exams {
  &__list {
  }
  &__card {
    &-content {
      background: get($gradients, blue-extra-light);
      border-radius: $border-radius-sm;
      padding: 0 $spacer * 1.3;

      &-el {
        padding: $spacer * 1.2 0;

        & + .patient-exams__card-content-el {
          margin-top: $spacer;
          border-top: $border-width solid get($colors, gray);
        }
      }
      @include mediaToDown($xxl) {
        padding: $spacer;
      }
    }
  }
}
.profileItem{
  .patient-card__header{
  .row>div:nth-child(2){
  display: none;
  }
   .row>div:nth-child(6){
   display: none;
  }
  }

}
.ps-55{
  padding-left: 55px;
}
</style>
