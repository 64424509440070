import axios from '@/api/axios';

// неактуально, временно убрали уведомления

const getNotifications = () => axios.get('/api/v1/notification');

const deleteNotification = (id) => axios.delete(`/api/v1/notification/${id}`);

export default {
  getNotifications,
  deleteNotification,
};
