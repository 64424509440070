
<fragment>
  <div class="row justify-content-center g-4" >
    <div class="col-14">
      <div class="text-center">
        <app-title :type="'large'" :classes="'mb-3'">{{ title }}</app-title>
        <p class="text-color--blue text-size--small">{{ subtitle }}</p>
      </div>
    </div>
    <div class="col-14 col-xl-12 col-xxl-10">
      <app-validation-errors v-if="errors || authError" :classes="'mb-3'" :validation-errors="errors || authError"></app-validation-errors>
      <form @submit.prevent="onSubmit" :class="['form', {'is-submitting': isSubmitting}]">
        <div class="row flex-column align-items-center g-4">
          <div class="col">
            <label class="form__label">
              <input :class="['form__field', { 'is-error': v$.email.$errors.length }]" type="email" v-model="v$.email.$model" placeholder="E-mail">
            </label>
            <ul class="form__error">
              <li v-for="error of v$.email.$errors" :key="error.$uid">
                <template v-if="error.$validator === 'required'">{{ v.errors.required }}</template>
                <template v-if="error.$validator === 'email'">{{ v.errors.email }}</template>
              </li>
            </ul>
          </div>
          <div class="col">
            <label class="form__label">
              <input :class="['form__field', { 'is-error': v$.password.$errors.length }]" :type="showPass ? 'text' : 'password'" v-model="v$.password.$model" placeholder="Пароль">
              <span class="form__icon cursor-pointer" @click="showPass = !showPass">
                <so-icon v-if="showPass" :icon="'eye-open'"></so-icon>
                <so-icon v-else icon="eye-close"></so-icon>
              </span>
            </label>
            <ul class="form__error">
              <li v-for="error of v$.password.$errors" :key="error.$uid">
                <template v-if="error.$validator === 'required'">{{ v.errors.required }}</template>
              </li>
            </ul>
          </div>
          <div class="col mt-5">
            <button type="submit" :disabled="v$.$invalid" class="button button--accent form__button">
              <span class="button__content">Войти</span>
            </button>
          </div>
          <div class="col mt-3">
            <so-button-esia @on-click="loginEsia"></so-button-esia>
          </div>
          <!-- <div class="col-auto">
            <router-link :to="{name: 'restore'}" class="text-center text-color--blue-dark text-decoration--underline text-size--small">Забыли пароль?</router-link>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</fragment>

