
.select(:id="id" :class="[classes, {'is-open': isOpen}, `is-${type}`, {'text-size--small': isSorting}]")
  button(
    type="button"
    class=['select__button', 'd-flex align-items-center']
    v-click-outside="close"
    @click="isOpen = !isOpen"
  )
    span.me-2(v-if="isSorting") Сортировать&nbsp;по:
    span(v-if="selected")
      span.me-3(v-if="selected.icon")
        so-icon(:icon="selected.icon")
      span {{ selected.caption }}

    span.text-color--blue.text-family--light(v-else) {{ placeholder }}
    span.select__arrow.d-flex(:class="[isSorting ? 'ms-3' : 'ms-auto']")
      so-icon(icon="down-arrow")

  transition(name="fade")
    .select__dropdown(v-if="isOpen")
      ul.select__list
        li.select__list-el.d-flex.align-items-center(
          v-for="option in options"
          :key="option.value"
          :class="{'is-active': Boolean(option.selected)}"
          @click="select(option)"
        )
          span.d-flex.me-3(v-if="option.icon")
            so-icon(:icon="option.icon")
          span {{ option.caption }}

