
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__header.mb-4
    .row.align-items-center
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
          //- router-link(v-if="devices.length" :to="{name: 'map'}" class=['d-flex align-items-center', 'link--accent'])
          //-   span.flex-shrink-0.me-2
          //-     so-icon(icon="map-point")
          //-   span.text-size--small 'Просмотреть геокарту'

        .col-auto
          .d-flex.flex-column
            button(
              v-if="devices.length"
              type="button"
              @click="exportDevices()"
              class=['link--accent']
            )
              span.d-flex.align-items-center
                span.flex-shrink-0.me-3
                  so-icon(icon="xls-doc")
                span.text-size--small Выгрузить данные .xlsx

  .profile-devices__content.position-relative
    transition(name="translucent")
    app-loading(v-if="isLoading")

    div
      template(v-if="devices.length")
        .profile-table
          .profile-table__header.d-none.d-xl-block
            .row.align-items-center
              div(v-for="column in columns" :key="column.key" :class="column.class")
                button(
                  v-if="column.caption || column.icon"
                  type="button"
                  class=['profile-table__sort-button']
                  :class="{'is-active': sortKey === column.key && column.key !== 'attestation' && column.key !== 'device_verification_owner'}"
                  @click="sortingDevices(column.key, column.inverse)"
                )
                  span.d-flex.align-items-center
                    span {{ column.caption }}
                    span.d-flex.ms-3
                      so-icon(icon="down-arrow")

          ul.profile-table__body
            li.profile-table__row(v-for="device in devices" :key="device.serial")
              router-link(:to="{name: 'device-detail', params: {id: device.serial}}" class=['d-block'])
                section.profile-card
                  .row.align-items-md-center.gy-4.gy-md-0
                    .col-3
                      .text-color--blue.mb-2.d-xl-none Серийный номер МИ
                      span(v-if="device.serial") {{ device.serial }}
                      span(v-else) &#8212;
                    .col-2.col-md-2
                      .d-flex.align-items-center.justify-content-end.justify-content-md-start
                        span(v-if="device.creation_date") {{ toLocalDate(device.creation_date) }}
                        span(v-else) &#8212;
                    .col-2
                      .text-color--blue.mb-2.d-xl-none Владелец
                      span(v-if="device.company_name") {{ device.company_name }}
                      span(v-else-if="device.device_owner_full_name") {{ device.device_owner_full_name }}
                      span(v-else) &#8212;

                    .col-2
                      .text-color--blue.mb-2.d-xl-none Контроль целостности ПО
                      span(v-if="device.device_integrity === null") &#8212;
                      span(v-if="device.device_integrity === true") Пройден
                      span.text-color--orange(v-if="device.device_integrity === false") Не пройден
                    .col-2
                      .text-color--blue.mb-2.d-xl-none Поверка СИ
                      span.text-color--orange(v-if="device.attestation === 'attestation_ends'") Заканчивается
                      span.text-color--orange(v-if="device.attestation === 'not_attestation'") Не поверено
                      span.text-color--blue-dark(v-if="device.attestation === 'attestation'") Поверено
                    .col-3
                      .d-flex.align-items-center
                        .text-color--blue.mb-2.d-xl-none Целостность корпуса МИ
                        span(v-if="device.case_opened == null")  &#8212;
                        span(v-if="device.case_opened == false") Не вскрыт
                        span.text-color--orange(v-if="device.case_opened == true") Вскрыт
                        span.text-color--accent.ms-auto.flex-shrink-0.d-none.d-md-inline
                          so-icon(icon="right-arrow")

        .d-flex.justify-content-center.mt-5
          app-pagination(
            :total="pagination.total"
            :limit="pagination.limit"
            :current-page="currentPage"
            :url="baseUrl"
          )

      template(v-else)
        .d-flex.align-items-center.text-color--blue.text-size--small
          span.d-flex.me-3
            so-icon(icon="wrong")
          span Нет устройств

  // filter
  app-admin-devices-filter(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="closeFilter"
    @clear="closeFilter"
    @submit="submitFilter"
  )

