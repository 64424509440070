
.inspection-card__table.col-14
  .row
    .col-14
      .table__header.mb-4.d-flex.flex-xl-row.flex-column.title.title--small(
          @click="getLastExams(); openCard()"
          class=['text-color--blue-dark', 'mb-2', 'mb-xl-0', 'block-history-title']
        ) история осмотров
        span.d-flex.align-items-center.ms-3
          so-icon(icon="down-arrow" :class='{rotate: isActiveIcon}')

      .position-relative
        transition(name="translucent")
          app-dashboard-card(
            v-if="showLastExams"
            :goTo="'exams'"
            :classes="`${lastExams && lastExams.length === 0 ? 'is-empty' : ''}`"
          )

            template(v-slot:body)
              template(v-if="lastExams.length && !isLoading")
                .profile-table.d-none.d-xl-block
                  .profile-table__header
                    .row.align-items-center.g-4
                      div(v-for="column in columns" :key="column.key" :class="column.class")
                        button(
                          v-if="column.caption || column.icon"
                          type="button"
                          class=['profile-table__sort-button']
                        )
                          span.d-flex.align-items-center
                            span(v-if="column.caption") {{ column.caption }}
                            span.flex-shrink-0(v-else-if="column.icon")
                              so-icon(:icon="column.icon")
                            span.flex-shrink-0.d-flex.ms-3
                              so-icon(icon="down-arrow" )

                  ul.profile-table__body(id="patient-last-exams")
                    li.profile-table__row(v-for="lastExam in lastExams" :key="lastExam.exams_id")
                      app-exam-card(
                        :data="lastExam"
                        :parent-id="'patient-last-exams'"
                        :columns="columns"
                        :classes="'d-none d-xl-block'"
                        @openModal="openExamDetailModal"
                        @openCertificate="openCertificate"
                        @openGallery="openGallery"
                      )
              .text-size--small.text-color--gray-light(v-if="isLoading") История осмотров загружается
              .text-size--small.text-color--gray-light(v-if="!lastExams.length && !isLoading") История осмотров отсутствует

      //certificate modal
    app-certificate-modal(
        :is-visible="isModalVisible.certificate"
        :data="certificate"
        @close="closeModal('certificate')"
    )

        // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )

    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
