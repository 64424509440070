
.inspection-card__patient-avatar
  .innerExamDetailDoctor__ava
    app-avatar(
      :localUrl="avatarSource.source"
      :viewPhoto="true"
      @openGallery="openGallery"
      :classes="'square extra-large'"
      :archivedPhoto="avatarSource.archived"
    )
    span
      so-icon(v-if="avatarSource.archived" icon="attention-yellow")
