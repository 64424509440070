
section(:class="['profile-card', 'profile-card-device', {'no-hover': noHover}]")
  .row.align-items-center.g-4
    .col-14.col-xl-3
      .d-flex.align-items-center
        app-status(:type="'device'" :data="data")
        .profile-card-device__icon.d-none.d-md-flex.flex-shrink-0.ms-4
          so-icon(icon="smartwatch" view-box="0 0 17 24")
        .ms-md-4.ls-lg
          .text-color--blue.mb-2 Серийный номер МИ
          span {{ data.serial }}
        button(
          type="button"
          @click.prevent="deleteModal(data)"
          class=['profile-card__button profile-card__button--delete', 'ms-auto flex-shrink-0 d-xl-none d-flex align-items-center justify-content-center']
        )
          span.flex-shrink-0
            so-icon(icon="trash")
    .col-14.col-xl-2.ps-5
      template(v-if="isApproved")
        .text-color--blue.mb-2 Дата регистрации
        span {{ toLocalDate(data.creation_date) }}
        app-tooltip(
          v-if="data.reason"
          :content="data.reason"
          :classes="'d-xl-none ms-auto profile-card-device__message'"
          :icon="'message'"
        )
      template(v-else-if="isPending")
        .text-color--blue.mb-2.yellow На проверке
        span {{ toLocalDate(data.creation_date) }}
      template(v-else-if="isRejected")
        .text-color--blue.mb-2.red Отклонено
        span.text-color--orange {{ toLocalDate(data.creation_date) }}
        app-tooltip(
          v-if="data.reason"
          :content="data.reason"
          :classes="'ms-2 profile-card-device__message mt-14'"
          :icon="'comment'"
        )
    .col-xl-2.ps-5
      .text-color--blue.mb-2 Целостность корпуса МИ
      span(v-if="data.case_opened==false") Не вскрыт
      span.text-color--orange(v-if="data.case_opened==true") Вскрыт
      span(v-else) &#8212;
    .col-14.col-xl-2
      .text-color--blue.mb-2 Контроль целостности ПО
      span(v-if='data.device_integrity === true') Пройден
      span.text-color--orange(v-else-if='data.device_integrity === false') Не пройден
      span(v-else) &#8212;

    .col-14.col-xl-2
      .text-color--blue.mb-2 Кол-во осмотров
      span(v-if='data.exams_count >= 0') {{data.exams_count}}
      span(v-else) &#8212;

    .col-auto.col-md-2.d-none.d-md-block
        //- button(
        //-   class=['profile-card-device__document-button', 'link--accent']
        //-   type="button"
        //-   @click.prevent="getFile(data.document)"
        //- )
        //-   .d-flex.align-items-center
        //-     span.flex-shrink-0.me-3
        //-       so-icon(icon="document")
        //-     span Документ&nbsp;покупки

    .d-none.d-xl-block.col-auto.ms-auto
      button(
        type="button"
        @click.prevent="deleteModal(data)"
        class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
      )
        span.flex-shrink-0
          so-icon(icon="trash")
  .col-14
   hr.d-none.d-md-block
   .row.g-4.align-items-center
    .col-xl-3.d-flex.align-items-center.d-none.d-md-flex
     .tooltipCheck.d-flex.justify-content-between
      span.flex-shrink-0(v-if='data.device_attestation_ending < 0')
          so-icon(icon="attention-red")
      span.flex-shrink-0(v-else-if='data.device_attestation_ending < 30 && data.device_attestation_ending >= 0')
          so-icon(icon="attention-yellow")
      app-tooltip(
          v-if="data.comment"
          :content="data.comment"
          :classes="'profile-card-device__message me-5'"
          :icon="'comment'"
        )

     div
      .text-color--blue.mb-2 Поверка СИ
      span(v-if="data.attestation === 'attestation'") {{ getAlarmByDays(data.attestation) }}
      span.text-color--orange(v-else) {{ getAlarmByDays(data.attestation) }}
    .col-xl-2.ps-5
      .text-color--blue.mb-2.mt-4.mt-md-0 Номер свидетельства
      span(v-if='data.device_attestation')  {{data.device_attestation}}
      span(v-else) &#8212;
    .col-xl-2.ps-5
      .text-color--blue.mb-2 Поверено от
      span(v-if='data.device_attestation_start') {{toLocalDate(data.device_attestation_start)}}
      span(v-else) &#8212;
    .col-xl-2
      .text-color--blue.mb-2 Поверено до
      span(v-if='data.device_attestation_end') {{ toLocalDate(data.device_attestation_end) }}
      span(v-else) &#8212;

    .d-none.d-xl-block.col-auto.ms-auto(v-if='data.device_verification_owner == null && !isRejected')
      button(
        type="button"
        @click.prevent="changeModal(data)"
        class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
      )
        span.flex-shrink-0
          so-icon(icon="edit")
    .col-auto.col-md-2.d-md-none
        //- button(
        //-   class=['profile-card-device__document-button', 'link--accent']
        //-   type="button"
        //-   @click.prevent="getFile(data.document)"
        //- )
        //-   .d-flex.align-items-center
        //-     span.flex-shrink-0.me-3
        //-       so-icon(icon="document")
        //-     span Документ&nbsp;покупки

