<template lang="pug">
.inspection-card__table.col-14
  .row
    .col-14
      .table__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column.title.title--small(
          @click="getDocuments"
          class=['text-color--blue-dark', 'mb-2', 'mb-xl-0', 'block-history-title', 'text-uppercase']
        ) Медицинские документы
        span.d-flex.align-items-center.ms-3
          so-icon(icon="down-arrow" :class='{rotate: isActiveIcon}')

      .profile-table
        transition(name="translucent")
          .profile-table(v-if="showDocs")
            .profile-table__header.d-none.d-xl-block
              template(v-if="incomingDocs.length || outcomingDocs.length")
                ul.text-size--normal

                  // Входящие документы
                  template(v-if="incomingDocs.length") Входящие документы
                    li.text-size--small(v-for="doc in incomingDocs.slice(0, pagination.incomingDocs.total)" :key="doc.text")
                      a(@click="download(doc)" href="#") {{ doc.text }}
                    div.mb-2
                      button(
                        v-if="pagination.incomingDocs.total < incomingDocs.length"
                        type="button"
                        @click="loadMoreIncomingDocuments"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ Math.min(pagination.incomingDocs.offset, incomingDocs.length - pagination.incomingDocs.total) }})

                      button(
                        v-if="pagination.incomingDocs.total > pagination.incomingDocs.start"
                        type="button"
                        @click="clearPagination('incomingDocs')"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть

                  // Исходящие документы
                  template(v-if="outcomingDocs.length") Исходящие документы
                    li.text-size--small(v-for="doc in outcomingDocs.slice(0, pagination.outcomingDocs.total)" :key="doc.text")
                      a(@click="download(doc)" href="#") {{ doc.text }}
                    div.mb-2
                      button(
                        v-if="pagination.outcomingDocs.total < outcomingDocs.length"
                        type="button"
                        @click="loadMoreOutcomingDocuments"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ Math.min(pagination.outcomingDocs.offset, outcomingDocs.length - pagination.outcomingDocs.total) }})

                      button(
                        v-if="pagination.outcomingDocs.total > pagination.outcomingDocs.start"
                        type="button"
                        @click="clearPagination('outcomingDocs')"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть
              div(v-else-if="isLoading") Документы загружаются

              template(v-else)
                div Документы отсутствуют

</template>

<script>
import AppTooltip from '@/components/common/Tooltip';
import patientApi from '@/api/patients';
import AppLoading from '@/components/common/Loading';
import { downloadDocs } from '@/helpers/utils';

export default {
  name: 'DoctorExamCardMedDocs',
  components: {

    AppTooltip,
    AppLoading,
  },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActiveIcon: false,
      incomingDocs: [],
      outcomingDocs: [],
      showDocs: false,
      isLoading: false,
      currentPage: 0,
      pagination: {
        incomingDocs: {
          start: 3,
          total: 3,
          offset: 10,
        },
        outcomingDocs: {
          start: 3,
          total: 3,
          offset: 10,
        },
      },
    };
  },
  methods: {
    async getDocuments() {
      try {
        this.isActiveIcon = !this.isActiveIcon;
        this.showDocs = !this.showDocs;
        if (!this.incomingDocs.length || !this.outcomingDocs.length) {
          this.isLoading = true;
        }
        const [incoming, outcoming] = await Promise.all([
          patientApi.docsForExam(this.exam.patient_code, 'incoming', this.currentPage),
          patientApi.docsForExam(this.exam.patient_code, 'outcoming', this.currentPage),
        ]);

        this.incomingDocs = incoming.data.items;
        this.outcomingDocs = outcoming.data.items;

        this.isLoading = false;
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Доступ запрещен. Пожалуйста, обновите страницу или выполните вход снова.');
        } else {
          console.error('Ошибка при получении документов:', error);
        }
        this.isLoading = false;
      }
    },
    download(data) {
      downloadDocs(data, ['file', 'protocol_file'])
    },
    async loadMoreIncomingDocuments() {
      this.pagination.incomingDocs.total += this.pagination.incomingDocs.offset;
      this.currentPage += 1;
      await this.loadDocuments('incoming');
    },

    async loadMoreOutcomingDocuments() {
      this.pagination.outcomingDocs.total += this.pagination.outcomingDocs.offset;
      this.currentPage += 1;
      await this.loadDocuments('outcoming');
    },

    async loadDocuments(documentType) {
      try {
        this.isLoading = true;
        const response = await patientApi.docsForExam(
          this.exam.patient_code,
          documentType,
          this.currentPage
        );

        if (documentType === 'incoming') {
          this.incomingDocs = [...this.incomingDocs, ...response.data.items];
        } else if (documentType === 'outcoming') {
          this.outcomingDocs = [...this.outcomingDocs, ...response.data.items];
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Ошибка при получении документов:', error);
      }
    },

    clearPagination(document_group) {
      this.pagination[document_group].total = this.pagination[document_group].start;
      this.currentPage = 0;
    },
  },
};
</script>

<style lang="scss">
.inspection-card__table {
  a {
    color: #59658d;
  }
  margin-left: auto;
  .table__header {
    height: 50px;
    border-radius: 6px;
    padding: 1rem;
    &__button {
      margin: 0 auto;
      @include mediaToDown($xl) {
        top: $spacer * 2;
      }
    }
  }
  .profile-table__header {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
  }
}
.doctor-exam-detail-modal__docs {
  a {
    display: block;
    text-decoration: underline;
  }
}
.rotate {
  transform: rotate(-180deg);
}
</style>
