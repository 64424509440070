<template lang="pug">
.profile-exams__inner
  .profile-exams__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'profile__title text-center'"
        ) Журнал осмотров

    .profile-exams__content.mt-20.position-relative
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      transition(name="translucent")
      template(v-if="columns")
        .profile-table
          .profile-table__header.d-none.d-xl-block
            .row.align-items-center.g-0
              div(v-for="column in columns" :key="column.key" :class="column.class")
                button(
                  v-if="column.caption || column.icon"
                  type="button"
                  @click="sortingExams(column.key)"
                  class=['profile-table__sort-button']
                  :class="{'is-active': sortKey === column.key}"
                )
                  span.d-flex.align-items-center
                    span(v-if="column.caption") {{ column.caption }}
                    span.flex-shrink-0(v-else-if="column.icon")
                      so-icon(:icon="column.icon")
                    span.flex-shrink-0.d-flex.ms-3
                      so-icon(icon="down-arrow")

          ul.profile-table__body
            li.profile-table__row(v-for="exam in exams" :key="exam.exams_id")
              router-link(:to="{name: 'doctor-card-revieved', params: {id: exam.exams_id}}" class=['d-block'])
                app-doctor-exam-card(
                  :data="exam"
                  :columns="columns"
                )

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )
      template(v-else)
        .text-color--blue.text-size--small Осмотров не найдено

</template>

<script>
import AppTitle from '@/components/common/Title';
import examsApi from '@/api/exams';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import is from 'is_js';
import AppDoctorExamCard from '@/components/profile/doctor/exams/DoctorExamCard';
import variables from '@/helpers/variables';
import AppNavDocs from '@/components/common/NavDocsAdmin';
import DoctorExamCardReviewed from '@/components/profile/doctor/exams/DoctorExamCardReviewed';

export default {
  name: 'AppDoctorExams',
  components: {
    AppTitle,
    AppExamCard,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    
    is,
    AppDoctorExamCard,
    variables,
    AppNavDocs,
    DoctorExamCardReviewed,
  },
  data() {
    return {
      isLoading: false,
      v: variables,
      exams: {},
      pagination: {
        limit: 10,
        total: 0,
      },
      sortKey: 'exam_number',
      columns: [
        {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата и время',
          key: 'admission_datetime',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Тип осмотра',
          key: 'exam_type_name',
          inverse: false,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-1',
          key: 'health_complaint',
          inverse: false,
        },
        {
          class: 'col-1 ',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
      error: null,
    };
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
  },
  watch: {
    currentPage() {
      this.getExams();
    },
  },
  async created() {
    this.getExams();
  },
  methods: {
    async getExams() {
      const params = 'considered';
      const sortSign = this.sortInverse ? '+' : '-';
      const order_by = `${sortSign}${this.sortKey}`;

      this.isLoading = true;

      try {
        const response = await examsApi.getExamsNewNew(this.currentPage - 1, params, order_by);

        const {total, per_page, items} = response.data;

        this.pagination.total = total;
        this.pagination.limit = per_page;
        this.exams = items;
      } catch (error) {
        console.error('Error fetching exams:', error);
      } finally {
        this.isLoading = false;
      }
    },

    sortingExams(sortKey) {
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getExams();
    },
  },
};
</script>

<style></style>
