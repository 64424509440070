
transition(name="fade")
     app-modal(v-if="isVisible" @close="closeModal('confirm')")
       template(v-slot:body)
         .row.justify-content-center
           .col-14.col-md-14
             .text-center
               app-title(:type="'large'") {{ currentRequest.type_name }}
               .mt-3
                p {{currentRequest.user_full_name}}
                 .row.flex-column.align-items-center
                   .col.mb-5
                    .responsibleListSerial.text-uppercase
                      span.my-3 Серийный номер МИ: {{currentRequest.additional_params.device_serials}}

                   .col
                     button(
                       type="submit"
                       :disabled="false"
                       class=['button', 'form__button', 'maw182', 'noBg', 'me-2']
                       @click="onSubmit(false)"
                     )
                       .button__content Клиент
                     button(
                       type="submit"
                       :disabled="false"
                       class=['button', 'button--accent', 'form__button', 'maw182']
                       @click="onSubmit(true)"
                     )
                       .button__content SafeOperator
