
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Удалить группу?

      .row.flex-column.align-items-center.g-4
        .col-14.col-md-8
          .row.align-items-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deletePrintingGroup"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
  