<template>
  <so-modal
  ok='Пауза'
  :modelValue='modelValue'
  @on-close='() => onClose()'
  @on-ok='() => onOk()'
  title-size='large'
  :can-close='false'
  >
    <template v-slot:title>
      <span class='text-color--orange'>НЕ УДАЛОСЬ ПОДПИСАТЬ ДАННЫЕ</span>
    </template>
    <template>
      <p class='text-center py-4'>Убедитесь в работоспособности сертификата</p>
    </template>
  </so-modal>
</template>

<script lang='ts'>
import SoModal from '@/components/so/modal/SoModal.vue';
import { watch } from 'vue';

export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: Boolean
  },
  components: {
    SoModal
  },
  setup(props, { emit }) {
    function onClose() {
      emit('update:modelValue', false);
      emit('on-close');
    }
    function onOk() {
      emit('on-ok');
      onClose();
    }

    let timeout: NodeJS.Timeout | null = null;

    watch(() => props.modelValue, (v) => {
      if (v) {
        clearTimeout(timeout!);
        timeout = setTimeout(onOk, 5000);
      } else {
        clearTimeout(timeout!);
        timeout = null;
      }
    });

    return {
      onOk,
      onClose
    }
  }
};
</script>
