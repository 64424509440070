
<button
:class="['so-btn', ...classes]"
:disabled="disabled"
@click.stop.prevent="handleClick"
>
  <div class="so-btn__content">
    <img src="/esia.svg" class="so-btn__icon"/>
    Войти через Госуслуги
  </div>
</button>
