
section.dashboard-card(:class="[{'is-active': goTo}, classes]")
  .dashboard-card__header
    .row.align-items-center
      .col
        app-title
          slot(name="title")

      .col-auto(v-if="goTo && Boolean($slots.button) && Boolean($slots.button[0].text)")
        router-link(
          :to="{name: goTo}"
          class=['text-color--accent link--accent', 'd-flex align-items-center', 'text-uppercase ls-lg']
        )
          span.d-none.d-md-inline
            slot(name="button")
          span.d-md-none Все
          
          span.ms-3.d-flex
            so-icon(icon="right-long-arrow")

  .dashboard-card__content.mt-2
    slot(name="body")


