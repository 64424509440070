
.modal-container
  simplebar.modal-inner
    .modal-overlay(aria-label="Close modal" @click="close")
    .modal(
      role="dialog"
      aria-describedby="modal-description"
      :class="classes"
    )
      button(
        v-if="!withoutCloseBtn"
        @click="close"
        type="button"
        class='modal__close-button d-flex'
        aria-label="Close modal"
      )
        span.d-flex
          so-icon(icon="close")

      .modal__body#modal-description
        slot(name="body")

      .modal__footer
        slot(name="footer")

