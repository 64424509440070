
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" classes="small")
    template(v-slot:body)
      app-title(:classes="'mb-4'") Смена пароля
      app-validation-errors(
        v-if='error && showError'
        :validation-errors='error'
        :classes="'mb-4'"
      )
      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
        .row.flex-column.align-items-center.g-3.g-md-4
          .col
            label.form__label
              input(
                :class="['form__field', { 'is-error': v$.password.$errors.length }]"
                :type="showPass ? 'text' : 'password'"
                v-model="v$.password.$model"
                placeholder="Старый пароль"
              )
              span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                so-icon(v-if="showPass" :icon="'eye-open'")
                so-icon(v-else :icon="'eye-close'")

            ul.form__error
              li(v-for="error of v$.password.$errors" :key="error.$uid")
                template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                template(v-if="v$.password.$model")
                  template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                  template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                  template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                  template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                  template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

          .col
            label.form__label
              input(
                :class="['form__field', { 'is-error': v$.newPassword.$errors.length }]"
                :type="showPass ? 'text' : 'password'"
                v-model="v$.newPassword.$model"
                placeholder="Новый пароль"
              )
              span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                so-icon(v-if="showPass" :icon="'eye-open'")
                so-icon(v-else :icon="'eye-close'")

            ul.form__error
              li(v-for="error of v$.newPassword.$errors" :key="error.$uid")
                template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                template(v-if="v$.newPassword.$model")
                  template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.password }}
                  template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                  template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                  template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                  template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                  template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

          .col
            label.form__label
              input(
                :class="['form__field', { 'is-error': v$.confirmPassword.$errors.length }]"
                :type="showPass ? 'text' : 'password'"
                v-model="v$.confirmPassword.$model"
                placeholder="Повторите новый пароль"
              )
              span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                so-icon(v-if="showPass" :icon="'eye-open'")
                so-icon(v-else :icon="'eye-close'")

            ul.form__error
              li(v-for="error of v$.confirmPassword.$errors" :key="error.$uid")
                template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                template(v-if="v$.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

          .col.mt-5
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Подтвердить смену пароля

