
.profile-user-detail
  .profile-user-detail__inner
    .profile-user-detail__header.mb-3.mb-md-0
      .row.align-items-center
        .col-14.col-xxl-auto.me-auto.order-last.order-md-first
          .row.align-items-center.g-5
            .col-4.col-xl-auto
              app-back-button(:prev-page="'users'")
            .col-4.col-xl-auto.me-auto
              app-title(
                :type="'large'"
                :classes="'text-center'"
              ) Страница клиента
            .col-4.col-xl-auto.d-xxl-none
              .d-flex.align-items-center.justify-content-end
                button(
                  type="button"
                  @click.prevent="openModal('changeUser')"
                  class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                )
                  span.flex-shrink-0
                    so-icon(icon="edit")

                button(
                  type="button"
                  @click.prevent="openModal('deleteUser')"
                  class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                )
                  span.flex-shrink-0
                    so-icon(icon="trash")


    .profile-user-detail__content.mt-20.position-relative
      app-loading(v-if="usersLoading")
      app-error-message(v-if="usersError" :message="usersError")

      transition(name="fade" mode="out-in")
        div(v-if="current_user")
          // client mobile card
          section.profile-user-detail__card.d-md-none
            .row.align-items-center.g-4
              .col-7
                span.text-color--blue.mb-3 ИНН:&ensp;
                span(v-if="current_user.inn") {{ current_user.inn }}
                span(v-else) &#8212;

              .col-auto.ms-auto
                span {{ current_user.creation_date }}

              .col-8
                ul
                  li.mb-2 {{ current_user.full_name }}
                  li
                    span(v-if="current_user.email") {{ current_user.email }}
                    span(v-else) &#8212;

              .col-auto.ms-auto
                app-avatar(:src="current_user.avatar")


          // client desktop card
          section.profile-user-detail__card.d-none.d-md-block
            .row.align-items-center.g-4.gy-xxl-0
              .col-7.col-xl-3
                .d-none.d-xl-block.text-color--blue.mb-3 Клиент
                .d-flex.align-items-center
                  app-avatar(:src="current_user.avatar")
                  span.text-color--blue-dark.ms-3  {{ current_user.full_name }}

              .col-7.col-xl-3
                .text-color--blue.mb-3 Дата регистрации
                span(v-if="current_user.creation_date").text-color--blue-dark {{ this.$moment(current_user.creation_date).format('DD.MM.YYYY') }}
                span(v-else) &#8212;

              .col-7.col-xl-auto.flex-grow-1
                .text-color--blue.mb-3 Контактные данные
                span(v-if="current_user.email").text-color--blue-dark {{ current_user.email }}
                span(v-else) &#8212;

              .col-7.col-xl-2
                .text-color--blue.mb-3 ИНН
                span(v-if="current_user.inn").text-color--blue-dark {{ current_user.inn }}
                span(v-else) &#8212;

              .col-auto.d-none.d-xxl-block
                .d-flex.align-items-center
                  button(
                    type="button"
                    @click="isModalVisible.changeUser = true"
                    class=['profile-card__button profile-card__button--edit', 'd-flex align-items-center justify-content-center me-3']
                  )
                    so-icon(icon="edit")

                  button(
                    type="button"
                    @click="isModalVisible.deleteUser = true"
                    class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
                  )
                    so-icon(icon="trash")


          // devices
          .profile-user-detail__devices.mt-5.position-relative
            .d-flex.align-items-center.mb-4.mb-xl-3
              span.d-xl-none.text-color--blue.me-3.flex-shrink-0
                so-icon(icon="smartwatch" view-box="0 0 17 24")
              app-title Устройства клиента

            app-loading(v-if="devicesLoading")
            app-error-message(v-if="devicesError" :message="devicesError")

            transition(name="fade" mode="out-in")
              template(v-if="user_devices")
                div(v-if="user_devices && user_devices.length")

                  .d-none.d-md-block
                    ul.profile-user-detail__devices-list
                      li.mb-3(v-for="device in user_devices.slice(0, pagination.devices.total)" :key="device.serial")
                        router-link(:to="{name:'device-detail', params: {id: device.serial}}" class=['d-block'])
                        app-admin-user-device-card(:data="device" @delete="openDeleteModal"  @change="openChangeModal(device)")

                    button(
                      v-if="pagination.devices.total < user_devices.length"
                      type="button"
                      @click="pagination.devices.total += pagination.devices.offset"
                      class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                    ) Показать еще (+{{ (user_devices.length - pagination.devices.total) >= pagination.devices.offset ? pagination.devices.offset : user_devices.length - pagination.devices.total }})

                    button(
                      v-if="pagination.devices.total > pagination.devices.start"
                      type="button"
                      @click="pagination.devices.total = pagination.devices.start"
                      class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                    ) Свернуть

                  // slider for mobile
                  .profile-modile-slider.d-md-none
                    swiper(
                      :options="Object.assign({}, slider.common, slider.devices)"
                      :auto-update="true"
                      :auto-destroy="false"
                    )
                      swiper-slide(v-for="device in user_devices" :key="device.serial")
                        router-link(:to="{name:'device-detail', params: {id: device.serial}}" class=['d-block'])
                          app-admin-user-device-card(:data="device" @delete="openDeleteModal")

                      div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-devices-pagination'])

                .text-color--blue.text-size--small(v-else) нет устройств

          //exams
          .profile-user-detail__exams.mt-5.position-relative
            .d-flex.align-items-center.mb-4.mb-xl-3
              span.d-xl-none.d-flex.text-color--blue.me-3.flex-shrink-0
                so-icon(icon="diary")
              app-title(:classes="'me-auto'") Последние зарегистрированные осмотры с устройств
              router-link(
                v-if="exams_detail.length"
                :to="{name: 'exams'}"
                class=['text-color--accent link--accent', 'd-xl-none d-flex align-items-center flex-shrink-0', 'ms-3 text-uppercase ls-lg']
              )
                span Все
                span.ms-3.d-flex.flex-shrink-0
                  so-icon(icon="right-long-arrow")

            app-loading(v-if="examsLoading")
            app-error-message(v-if="examsError" :message="examsError")

            transition(name="translucent")
              template(v-if="exams_detail")
                div(v-if="exams_detail.length")
                  .d-none.d-xl-block.position-relative
                    .profile-table.is-exams-table.flex-grow-1
                      .profile-table__header
                        .row.align-items-center.g-4
                          div(v-for="column in columns" :key="column.key" :class="column.class")
                            button(
                              type="button"
                              @click="sortingExams(column.key, column.inverse)"
                              class=['profile-table__sort-button']
                              :class="{'is-active': sortKey === column.key}"
                            )
                              span.d-flex.align-items-center
                                span(v-if="column.caption") {{ column.caption }}
                                span(v-else-if="column.icon")
                                  so-icon(:icon="column.icon")
                                span.d-flex.ms-3
                                  so-icon(icon="down-arrow")

                  ul.profile-table__body(id="user-exams")
                    li.profile-table__row(v-for="exam in exams_detail" :key="exam.id")
                      app-exam-card(
                        :data="exam"
                        :parent-id="'user-exams'"
                        :columns="columns"
                        :classes="'d-none d-xl-block'"
                        @openModal="openExamDetailModal"
                        @openCertificate="openCertificate"
                        @openGallery="openGallery"
                      )

                    router-link(
                      :to="{name: 'exams', query: {user: current_user.company_name}}"
                      class=['text-size--small text-decoration--underline link--accent', 'd-block mt-4']
                    ) Показать все

                  // slider for mobile
                  .profile-modile-slider.d-xl-none
                    swiper(
                      :options="Object.assign({}, slider.common, slider.exams)"
                      :auto-update="true"
                      :auto-destroy="false"
                    )
                      swiper-slide(v-for="exam in exams_detail" :key="exam.id")
                        app-exam-card-mobile(:data="exam" @openModal="openExamDetailMobile")

                      div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])

                .text-color--blue.text-size--small(v-else) нет осмотров

  // change user modal
  app-admin-change-user-modal(
    v-if="current_user"
    ref="changeUser"
    :is-visible="isModalVisible.changeUser"
    :user="current_user"
    :is-loading="usersLoading"
    :error="usersError"
    @submit="changeUser"
    @close="closeModal"
  )
  // delete device modal
  app-delete-device-modal(
    :data="deleteDevice"
    :is-visible="isModalVisible.deleteDevice"
    @close="closeModal('deleteDevice')"
    @success="deletedDevice"
    :currentUser="current_user"
  )

  //certificate modal
  app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
  )

  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :data="currentExam.data"
    :type="'user-detail'"
    @openModal="openExamDetailModal"
    @close="closeModal('examDetailMobile')"
  )
  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )
  change-device-modal(
    :is-visible="isModalVisible.changeDevice"
    :toEdit="toEdit"
    @close="closeModal('changeDevice')"
    @success='getDevices'
  )
  // delete user modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteUser" @close="closeModal('deleteUser')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить клиента?
          p.text-color--blue.mt-3 {{ current_user.email }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteUser')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteUser(current_user)"
              :disabled="usersLoading"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Также удалятся все связанные устройства и осмотры.<br>Отменить действие нельзя.

  // success modals
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successChanged" @close="closeModal('successChanged')" :without-close-btn="true")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Успешно изменено!

        .d-flex.justify-content-center.mt-5
          button(
            @click="closeModal('successChanged')"
            type="button"
            class=['button button--accent']
          )
            span.button__content Закрыть

  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successDeletedDevice" @close="closeModal('successDeletedDevice')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Устройство успешно удалено
          p.text-size--small.text-color--blue.mt-3
            template(v-if="userRole === 'client'")
              span Вам придет уведомление на e-mail об удалении устройства
              span.text-family--medium.text-color--blue-dark &ensp;{{ deleteDevice.serial}}

        .row.justify-content-center.mt-5
          .col-14.col-md-7
            button(
              @click="closeModal('successDeletedDevice')"
              type="button"
              class=['button button--accent', 'w-100']
            )
              span.button__content Назад


  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successDeletedUser" @close="$router.go(-1)")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Успешно удалён!
          p.text-color--blue.mt-3 {{ current_user.full_name }} будет оповещен о Вашем решении.

        .d-flex.justify-content-center.mt-5
          button(
            @click="$router.go(-1)"
            type="button"
            class=['button button--accent']
          )
            span.button__content Назад в список
