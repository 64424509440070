
.profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-devices__content.position-relative
    transition(name="translucent")
      div
        app-table(
          v-if="devices.length"
          :columns="columns"
          :sortKey="sortKey"
          @sortingRow="sortingDevices"
          :data="devices"
          :paginationData="pagination"
          :currentPage="currentPage"
          :baseUrl="baseUrl"
          :canEdit="true"
          :canRemove="false"
          @change-current-page="setCurrentPage"
          @edit-modal="editModal"
        )
          template(v-slot:cell='{ column, row }')
            span(v-if='column.key==="device_attestation_start"') {{ toLocalDate(row[column.key]) }}
            span(v-else-if='column.key==="device_attestation_end"') {{ toLocalDate(row[column.key]) }}
            span(v-else-if='column.key==="attestation"' :class="{'text-color--orange': highlightParams(row[column.key])}") {{ formatDeviceAttestation(row[column.key]) }}

        template(v-else)
          .d-flex.align-items-center.text-color--blue.text-size--small
            span.d-flex.me-3
              so-icon(icon="wrong")
            span Нет устройств

        app-edit(
          :is-visible="isModalVisible.edit"
          @close="closeModal('edit')"
          @update="getDevices"
          :toEdit="toEdit"
        )
  // filter
  app-filter-check(
    ref="filter"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="closeFilter"
    @clear="closeFilter"
    @submit="submitFilter"
    )

