<template>
<div id="app" :class="{'is-modal-open': v.state.isModalOpen}">
  <app-header></app-header>
  <main :class="[{'is-profile': isLoggedIn}]">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </main>

  <vue-headful v-if="title" :title="title"></vue-headful>
  <vue-headful v-else title="SafeOperator"></vue-headful>
</div>
</template>

<script>
import favicon from 'favicon.js';
import { mapGetters } from 'vuex';
import { gettersTypes as authGettersTypes } from '@/store/modules/auth';
import vueHeadful from 'vue-headful';
import AppLoading from '@/components/common/Loading';
import settingsApi from '@/api/settings';
import variables from '@/helpers/variables';
import AppHeader from '@/components/Header';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

export default {
  components: {
    AppHeader,
    vueHeadful,
    AppLoading,
  },
  data() {
    return {
      v: variables,
      title: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: authGettersTypes.isLoggedIn,
    }),
  },
  created() {
    this.getTitle();
    this.getFavicon();
  },
  methods: {
    async getTitle() {
      this.isLoading = true;
      let res = await settingsApi.getLastSettingsByTypeName('NAME_PROJECT');
      this.title = res.data.value;
      this.isLoading = false;
    },
    async getFavicon() {
      let res = await settingsApi.getLastSettingsByTypeName('FAVICON_PROJECT');
      favicon.change(res.data.download_link);
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/main.scss';

.v-toast__item.v-toast__item--bottom-right {
  align-self: center !important;
}

.v-toast__item {
  max-width: 470px !important;
  width: 100% !important;
  text-align: center !important;
}

.v-toast__item--success {
  background: #fff !important;
  color: #11d1a1 !important;
  box-shadow: 0px 73px 72px -31px rgba(7, 36, 136, 0.12) !important;
  border-radius: 10px 10px 0px 0px !important;
  font-size: 14px !important;
  text-transform: uppercase;
  margin: 0 !important;
}

.v-toast {
  padding: 0 !important;

  p {
    width: 100%;
  }
}

.v-toast__item .v-toast__text {
  text-align: center !important;
}
.v-toast__icon {
  display: none !important;
}
</style>
