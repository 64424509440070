
div
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showBlockHistory"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) История блокировок

  .profile-table(v-if="showInfo")
    .profile-table__header.d-none.d-xl-block(v-if="blockHistory.length")
      .row.align-items-center
        div.d-flex.align-items-center(v-for="column in columns" :key="column.key" :class="column.class")
          span.text-overflow(:title="column.caption") {{ column.caption }}
        ul.profile-table__body.ms-1
          li.profile-table__row(v-for="blockHistoryItem in blockHistory" :key="blockHistoryItem.id")
            section(class=['user-card', 'user-card-history'])
              .row.align-items-center.g-4.gy-xl-0
                .col-2
                  span {{ toLocalDateExam(blockHistoryItem.start_datetime)}} {{ toLocalTimeExam(blockHistoryItem.start_datetime)}}
                .col-2
                  span {{ toLocalDateExam(blockHistoryItem.end_datetime)}} {{ toLocalTimeExam(blockHistoryItem.end_datetime) }}
    span(v-else).text-color--blue-dark Блокировки отсутствуют

