
section(class=['patient-card', 'profile-card'] :class="classes")
  .row.align-items-center.g-4.gy-xl-0
    .col-auto
        span.idDoc {{data.id}}
    .d-none.d-xl-block.col-2
      .blue(v-if='data.document_status=="confirmed"') {{ "Подтверждено" }}
      .yellow(v-else-if='data.document_status=="awaiting"') {{ "На подтверждении" }}
      .orange(v-else-if='data.document_status=="rejected"') {{ "Отклонено" }}
      .blue(v-else) {{" В архиве "}}
    .col-auto.col-2
      // подкладываем ровно то время которое пришло с сервера
      span {{ toLocalDateExam(data.create_datetime) }}  {{ toLocalTimeExam(data.create_datetime ) }}
    .col-auto.col-2
      span {{ data.patient_full_name }}
    .col-2
      span.text-overflow {{ toLocalDateExam(data.issue_date) }}
    .col-2
      span.text-overflow(v-if='data.expiration_date') {{ toLocalDateExam(data.expiration_date) }}
    .col-2
      span.text-overflow(v-if='data.document_status!=="awaiting" && data.document_status!=="archived"') {{ toLocalDateExam(data.verification_datetime) }}  {{ toLocalTimeExam(data.verification_datetime ) }}
    .col-1.text-center
      span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='openShowModal(data)')
        span
          img(src="@/assets/img/search.svg" )
