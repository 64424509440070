
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-3
        app-title(:type="'large'") Добавить версию прошивки
        app-validation-errors(
          v-if="validationErrors"
          :validation-errors="validationErrors"
          :classes="'mb-2'"
      )
      form(@submit.prevent="onSubmit" class="form")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
            .row.g-4.posRelative
              .col
                .row.gx-4
                  .col-14.mt-3
                    input(
                      :class="['form__field form__field--animate']"
                      type="text"
                      name="id"
                      v-model="v$.form.version.$model"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                    )
                    label.textFloat(for='id') Версия прошивки
                  .col-14.mt-3
                    input(
                      :class="['form__field form__field--animate']"
                      type="text"
                      name="id"
                      v-model="v$.form.controlSum.$model"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="ids"
                    )
                    label.textFloat(for='ids') Контрольная сумма
          .col-14.col-md-10.mt-5
            button(
              type="submit"
              :disabled="v$.form.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Добавить

