<template lang="pug">
  ul.pagination.d-flex.align-items-center(v-if="pages.length > 1")
    li.pagination__item(v-if="pages.length > 5")
      router-link(
        :disabled="currentPage < 2"
        :class="['pagination__link', 'd-block', 'pe-0']"
        :to="{path: url, query: mergeQuery(1)}"
      )
        so-icon(icon="left-double-arrow")

    li.pagination__item
      router-link(
        :disabled="currentPage < 2"
        :class="['pagination__link', 'd-block']"
        :to="{path: url, query: mergeQuery(currentPage - 1)}"
      )
        so-icon(icon="left-arrow")

    template(v-if="pages.length < 5")
      li.pagination__item(v-for="page in pages" :key="page")
        router-link(
          :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
          :to="{path: url, query: mergeQuery(page)}"
        ) {{ page }}


    template(v-else)
      template(v-if="currentPage < 2")
        li.pagination__item(v-for="page in pages.slice(currentPage - 1, currentPage + 2)" :key="page")
          router-link(
            :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
            :to="{path: url, query: mergeQuery(page)}"
          ) {{ page }}

        li.pagination__item.mx-3 ...

        li.pagination__item
          router-link(
            :class="['pagination__link', 'd-block', {'is-active' : currentPage === pages.length}]"
            :to="{path: url, query: mergeQuery(pages.length)}"
          ) {{ pages.length }}

      template(v-else-if="currentPage < (pages.length - 2)")
        li.pagination__item(v-for="page in pages.slice(currentPage - 2, currentPage + 1)" :key="page")
          router-link(
            :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
            :to="{path: url, query: mergeQuery(page)}"
          ) {{ page }}

        li.pagination__item.mx-3 ...

        li.pagination__item
          router-link(
            :class="['pagination__link', 'd-block', {'is-active' : currentPage === pages.length}]"
            :to="{path: url, query: mergeQuery(pages.length)}"
          ) {{ pages.length }}


      template(v-else)
        li.pagination__item
          router-link(
            :class="['pagination__link', 'd-block', {'is-active' : currentPage === 1}]"
            :to="{path: url, query: mergeQuery(1)}"
          ) 1

        li.pagination__item.mx-3 ...

        li.pagination__item(v-for="page in pages.slice(pages.length - 4, pages.length)" :key="page")
          router-link(
            :class="['pagination__link', 'd-block', {'is-active' : currentPage === page}]"
            :to="{path: url, query: mergeQuery(page)}"
          ) {{ page }}


    li.pagination__item
      router-link(
        :disabled="currentPage === pages.length"
        :class="['pagination__link', 'd-block']"
        :to="{path: url, query: mergeQuery(currentPage + 1)}"
      )
        so-icon(icon="right-arrow")

    li.pagination__item(v-if="pages.length > 5")
      router-link(
        :disabled="currentPage === pages.length"
        :class="['pagination__link', 'd-block', 'ps-0']"
        :to="{path: url, query: mergeQuery(pages.length)}"
      )
        so-icon(icon="right-double-arrow")

</template>

<script>
import { range } from '@/helpers/utils';

export default {
  name: 'AppPagination',
  components: {
    
  },
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
    total: {
      type: Number,
      required: true,
      default: null,
    },
    limit: {
      type: Number,
      required: true,
      default: null,
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  computed: {
    pages() {
      return range(1, Math.ceil(this.total / this.limit));
    },
  },
  methods: {
    mergeQuery(value) {
      return Object.assign({}, this.$route.query, { page: value });
    },
  },
};
</script>

<style lang="scss">
.pagination {
  &__item {
    flex-shrink: 0;
  }
  &__link {
    @include transition();
    padding: $spacer * 1.2 $spacer * 1.6;
    line-height: 1;
    border-radius: $border-radius-sm;
    border: $border-width solid transparent;
    color: get($colors, blue);

    @include interaction("mouse") {
      &:hover:not(.is-active) {
        color: get($colors, blue-dark);
      }
    }
    &.is-active {
      cursor: default;
      color: get($colors, blue);
      border-color: get($colors, blue);
    }
    .icon {
      &__left-arrow,
      &__right-arrow,
      &__left-double-arrow,
      &__right-double-arrow {
        width: $spacer;
        height: $spacer;
      }
    }
    @include mediaToDown($md) {
      padding: $spacer;

      &.is-active {
        padding: $spacer $spacer * 1.4;
      }
    }
  }
}
</style>
