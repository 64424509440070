
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация
      .col.col-md-auto.ms-md-auto
        transition-group(name="translucent")
          template
      .col-14
        AppNavDocs
        so-tabs(v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex")
          so-tab(label="О здоровье" name="health_certificate")
          so-tab(label="О травме" name="injury_certificate")
          so-tab(label="Результаты ХТИ" name="hti_certificate")
          so-tab(label="Медосмотры" name="medical_exam_conclusion")
          so-tab(label="Акт МО" name="medical_exam_certificate")
          so-tab(label="Обращение клиента" name="client_request")

  .profile-patients__content.mt-2.position-relative
    transition-group(name="translucent")
      app-loading(v-if="isLoading" key="loading")
      app-error-message(v-if="error" :message="error")
      div(key="oneadad")
        div(v-if="incomingMedDocs && incomingMedDocs.length" key="hasIncomingMedDocs")
          .profile-table
            .profile-table__header.d-none.d-xl-block
              .row.align-items-center.gx-4
                div(v-for="column in columns" :key="column.key" :class="column.class")
                  button(
                    v-if="column.caption || column.icon"
                    type="button"
                    @click="sortingPatients(column.key)"
                    class=['profile-table__sort-button']
                    :class="{'is-active': sortKey === column.key}"
                  )
                    span.d-flex.align-items-center
                      span.text-overflow(:title="column.caption") {{ column.caption }}
                      span.d-flex.ms-3
                        so-icon(icon="down-arrow")

            ul.profile-table__body#incoming
              li.profile-table__row.profile-table__row--notice(v-for='item in incomingMedDocs')
                app-admin-notice-incoming(
                  :data="item"
                  @open="openModal('handlerDoc')"
                  :changeDocStatus="changeDocStatus"
                )

          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )

        div(v-else-if="!isLoading" key="NoMedDocs")
          .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-incoming(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
