
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация
      .col.col-md-auto.ms-md-auto
        transition-group(name="translucent")
          template
            button(
              v-if="outgoingMedDocs.length"
              type="button"
              key="one"
              @click="exportExamsExls()"
              class=['link--accent']
            )
              .d-flex.align-items-center(key="ones")
                span.flex-shrink-0.me-3
                  so-icon(icon="xls-doc")
                span.text-size--small Выгрузить данные .xlsx'
      .col-14
        AppNavDocs
        so-tabs(v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex")
          so-tab(label="Справки" name="med")
          so-tab(label="Направления на МО" name="alco")


  .profile-patients__content.mt-2.position-relative
    transition(name="translucent")
      div
        app-loading(v-if="isLoading" key='loading')
        app-error-message(v-if="error" :message="error")
        div(key="oneadad")
          app-table(
            v-if="outgoingMedDocs.length"
            :columns="columns"
            :sortKey="sortKey"
            @sortingRow="sortingPatients"
            :data="outgoingMedDocs"
            :paginationData="pagination"
            :currentPage="currentPage"
            @change-current-page="setCurrentPage"
            :baseUrl="baseUrl"
            :canEdit="false"
            :canRemove="false"
          )
            template(v-slot:cell='{ column, row }')
              span(v-if='column.key==="created_at"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}

              span(v-else-if='column.key==="date_birth"') {{ $moment(row[column.key]).format('DD.MM.YYYY') }}

              span(v-else-if='column.key==="doctor_full_name" && userRole === "admin"')
                router-link(
                  :to="{name: 'doctor-detail', params: {id: row.doctor_id}}"
                  class="text-decoration--underline link--accent"
                ) {{ row.doctor_full_name }}

            template(v-slot:actionTools='{row}')
              button(
                type="button"
                @click.prevent="download(row)"
              )
                img(src="@/assets/img/notice.svg")

              button(
                type="button"
                @click.prevent="openCertificate(row)"
              )
                span.doctorSign

          div(v-else)
            .text-color--blue.text-size--small Медицинская документация не найдена

  //certificate modal
  app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
  )
  // filter
  app-filter-page-outgoing(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
