import axios from '@/api/axios';

const suspendedWorkersExport = (credentials) =>
  axios.get('/api/v2/patient/export_suspended', credentials, {
    responseType: 'blob',
  });

const getSuspendedWorkers = (page) =>
  axios.get(
    `/api/v2/patient/suspended?page=${page}&per_page=10`
  );

export default {
  getSuspendedWorkers,
  suspendedWorkersExport,
};
