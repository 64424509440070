<template lang="pug">
.error-message(:class="classes") {{ errorMessage }}
</template>

<script>
import { errors } from '@/enums/validationErrors.js';

export default {
  name: 'AppValidationErrors',
  props: {
    validationErrors: {
      type: String,
      required: true,
      default: '',
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    errorMessage() {
      return errors[this.validationErrors] || `Внутренняя ошибка сервера, попробуйте позже [${this.validationErrors}]`;
    },
  },
};
</script>

<style scoped lang="scss">
.error-message {
  color: get($colors, danger);
  @include font-size(get($text-sizes, small));
}
</style>
