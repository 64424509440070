<template>
<model-table
  model="MedOrganization"
  :columns="columns"
  :canRemove="false"
>
</model-table>
</template>

<script lang='ts'>
import ModelTable from '@/components/so/table/ModelTable.vue';

export default {
  components: {
    ModelTable
  },
  data() {
    return {
      sortKey: 'creation_date',
      columns: [
        { caption: 'Наименование', key: 'name' },
        { caption: 'ОГРН', key: 'ogrn' },
        { caption: 'Лицензия', key: 'medical_license' },
        { caption: 'С', key: 'medical_license_start' },
        { caption: 'По', key: 'medical_license_end' }
      ]
    }
  }
}
</script>
