<template lang="pug">
.profile-signature(:class="[theme && `${theme}-theme`, classes]")
  button(
    v-if="localUrl || sample || instance"
    type="button"
    @click.prevent="openGallery"
    class=['profile-signature__button', 'd-flex align-items-center justify-content-center']
  )
    img(:src="signatureUrl")

  button(
    v-else
    type="button"
    @click.prevent="changeSignature"
    class=['profile-signature__button', 'd-flex align-items-center justify-content-center', 'link--accent', 'text-color--blue text-size--extra-small text-decoration--underline']
  )
    span Подпись

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import {eventBus} from '@/main';

export default {
  name: 'AppSignature',
  components: {
    
  },
  props: {
    localUrl: {
      type: String,
      required: false,
      default: null,
    },
    sample: {
      type: String,
      required: false,
      default: '',
    },
    instance: {
      type: String,
      required: false,
      default: '',
    },
    theme: {
      type: String,
      required: false,
      default: '',
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      settings: (state) => state.settings.data,
    }),
    isAdmin() {
      return this.userRole === 'admin';
    },
    isClient() {
      return this.userRole === 'client';
    },
    signatureUrl() {
      return this.localUrl ? this.localUrl : this.instance ? this.instance : this.sample;
    },
  },
  methods: {
    openGallery() {
      const data = {
        sources: [
          {
            src: this.signatureUrl,
          },
        ],
      };
      if (this.isAdmin || this.isClient) {
        data.preview = this.sample && this.instance ? this.sample : false;
        data.edit = this.instance ? false : 'changeSignature';
        data.delete = this.instance ? false : 'deleteSignature';
      }
      console.log(data);
      this.$emit('openGallery', data);
      eventBus.$emit('openGallery', data);
    },
    changeSignature() {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
.profile-signature {
  &__button,
  &__sample {
    border-radius: 50%;
    background-color: get($colors, white);

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
      object-position: center;
    }
  }
  &__button {
    @include transition();
    width: $spacer * 5.4;
    height: $spacer * 5.4;

    @include interaction('mouse') {
      &:hover {
        box-shadow: get($shadows, large);
      }
    }
  }
  &.small {
    .profile-signature {
      &__button {
        width: $spacer * 4;
        height: $spacer * 4;
      }
    }
  }
  &.large {
    .profile-signature {
      &__button {
        width: 100%;
        height: 86px;
      }
    }
  }
  &.square-theme {
    .profile-signature {
      &__button {
        border-radius: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &.shadow-theme {
    .profile-signature {
      &__button {
        box-shadow: get($shadows, default);
      }
    }
  }
}
</style>
