
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Загрузка документа
      .px-4
        form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
          .row.g-2
            .col.mb-2
              app-select(
                ref="selectStatus"
                :type="'form'"
                :placeholder="'Выберите тип документа'"
                :options="typeOpts"
                @select="selectStatus"
            )
            .col-14
              label.form__label
                input(
                  :class="['form__field']"
                  type="text"
                  v-model="form.docName"
                  placeholder="Введите номер / название документа"
                )
            .col-14.mb-2
              autocomplete(
                :search="search"
                placeholder="Введите фамилию пользователя"
                aria-label="Введите фамилию пользователя"
                :get-result-value="getResultValue"
                @submit="handleSubmit"
                )
            .col-14
              label.form__label
                input(
                  :class="['form__field']"
                  type="text"
                  v-model="form.orgName"
                  placeholder="Укажите организацию, выдавшую документ"
                )
            .col-14
                    label.form__label.mt-2
                      textarea(
                        :class="['form__field']"
                        v-model="form.rejectReason"
                        placeholder="Добавьте комментарий при необходимости"
                      )
            .col-14.mt-3
              .col-7
                label.form__label.text-color--blue Дата выдачи документа
                  div(class="mt-2")
                    date-picker(
                      prefix-class="app"
                      placeholder="Дата выдачи документа"
                      format="DD.MM.YYYY"
      								lang="ru"
                      :editable="true"
                      v-model="form.docDate"
      						  	:disabled-date="disableAfterToday"
                    )
            .col-7
              .form__file.d-flex.align-items-center
                input(
                  class="d-none"
                  type="file"
                  name="document"
                  @change="onSignatureSelected"
                  @click="v$.$touch()"
                  ref="signatureInput"
                  accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                )
                div(
                  v-if="form.signature"
                  class=['form__file-element', 'd-flex align-items-center', 'text-size--small']
                )
                  span.flex-shrink-0.me-3
                    so-icon(icon="image")

                  ul.flex-grow-1.overflow-hidden
                    li.form__file-name.text-color--black {{ form.signature.name }}
                    li.mt-1 {{ Number(form.signature.size/1000000).toFixed(4) }} mb

                  button(
                    type="button"
                    @click="clearSignatureInput"
                    class=['form__file-delete', 'flex-shrink-0 ms-3']
                  )
                    so-icon(icon="trash")

                .w-100.text-color--blue.text-size--small.text-center(v-else) Загрузите документ

                button(
                  class=['form__file-button', 'd-flex justify-content-end align-items-end align-items-md-center']
                  @click="$refs.signatureInput.click()"
                )
                  so-icon(icon="clip")
        .col-14.mt-25
          button(
            type="submit"
            @click="onSubmit"
            class=['button', 'button--accent', 'form__button']
          )
            span.button__content Отправить документ
