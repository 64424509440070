import medOrganizationsApi from '@/api/medOrganizations';
import {isValidSystemSetup} from 'crypto-pro';

const state = {
  isValidSystemSetup: null,
  data: null,

  isLoading: false,
  isSubmitting: false,

  validationErrors: null,
  error: null,
  systemError: null,
};

export const mutationTypes = {
  checkValidSystemSetupStart: '[medOrganizations] check valid system setup start',
  checkValidSystemSetupSuccess: '[medOrganizations] check valid system setup success',
  checkValidSystemSetupFailure: '[medOrganizations] check valid system setup failure',

  getMedOrganizationsStart: '[medOrganizations] get data start',
  getMedOrganizationsSuccess: '[medOrganizations] get data success',
  getMedOrganizationsFailure: '[medOrganizations] get data failure',

  createMedOrganizationStart: '[medOrganizations] create start',
  createMedOrganizationSuccess: '[medOrganizations] create success',
  createMedOrganizationFailure: '[medOrganizations] create failure',

  changeMedOrganizationStart: '[medOrganizations] change start',
  changeMedOrganizationSuccess: '[medOrganizations] change success',
  changeMedOrganizationFailure: '[medOrganizations] change failure',

  deleteMedOrganizationStart: '[medOrganizations] delete start',
  deleteMedOrganizationSuccess: '[medOrganizations] delete success',
  deleteMedOrganizationFailure: '[medOrganizations] delete failure',

  attachDoctorStart: '[medOrganizations] attachDoctor start',
  attachDoctorSuccess: '[medOrganizations] attachDoctor success',
  attachDoctorFailure: '[medOrganizations] attachDoctor failure',

  detachDoctorStart: '[medOrganizations] detachDoctor start',
  detachDoctorSuccess: '[medOrganizations] detachDoctor success',
  detachDoctorFailure: '[medOrganizations] detachDoctor failure',

  attachClientStart: '[medOrganizations] attachClient start',
  attachClientSuccess: '[medOrganizations] attachClient success',
  attachClientFailure: '[medOrganizations] attachClient failure',

  detachClientStart: '[medOrganizations] detachClient start',
  detachClientSuccess: '[medOrganizations] detachClient success',
  detachClientFailure: '[medOrganizations] detachClient failure',
};

export const actionTypes = {
  checkValidSystemSetup: '[medOrganizations] check valid system setup',
  getMedOrganizations: '[medOrganizations] get data',
  createMedOrganization: '[medOrganizations] create',
  changeMedOrganization: '[medOrganizations] change',
  deleteMedOrganization: '[medOrganizations] delete',

  attachDoctor: '[medOrganizations] attachDoctor',
  detachDoctor: '[medOrganizations] detachDoctor',
  attachClient: '[medOrganizations] attachClient',
  detachClient: '[medOrganizations] detachClient',
};

export const gettersTypes = {};

const getters = {

};

const mutations = {
  [mutationTypes.checkValidSystemSetupStart](state) {
    state.isLoading = true;
    state.isValidSystemSetup = null;
    state.systemError = null;
  },
  [mutationTypes.checkValidSystemSetupSuccess](state) {
    state.isValidSystemSetup = true;
    state.isLoading = false;
  },
  [mutationTypes.checkValidSystemSetupFailure](state, payload) {
    state.isValidSystemSetup = false;
    state.systemError = payload;
    state.isLoading = false;
  },

  [mutationTypes.getMedOrganizationsStart](state) {
    state.isLoading = true;
    state.error = null;
    state.data = null;
  },
  [mutationTypes.getMedOrganizationsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getMedOrganizationsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.createMedOrganizationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMedOrganizationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMedOrganizationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeMedOrganizationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMedOrganizationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMedOrganizationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMedOrganizationStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deleteMedOrganizationSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteMedOrganizationFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.attachDoctorStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.attachDoctorSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.attachDoctorFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.detachDoctorStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.detachDoctorSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.detachDoctorFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.attachClientStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.attachClientSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.attachClientFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.detachClientStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.detachClientSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.detachClientFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.checkValidSystemSetup](context) {
    context.commit(mutationTypes.checkValidSystemSetupStart);

    isValidSystemSetup()
      .then(() => {
        context.commit(mutationTypes.checkValidSystemSetupSuccess);
      })
      .catch((result) => {
        context.commit(mutationTypes.checkValidSystemSetupFailure, result);
      });
  },
  [actionTypes.getMedOrganizations](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMedOrganizationsStart);
      medOrganizationsApi
        .getMedOrganizations(credentials)
        .then((response) => {
          context.commit(mutationTypes.getMedOrganizationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMedOrganizationsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.createMedOrganization](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMedOrganizationStart);
      medOrganizationsApi
        .createMedOrganization(credentials)
        .then((response) => {
          context.commit(mutationTypes.createMedOrganizationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createMedOrganizationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.changeMedOrganization](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMedOrganizationStart);
      medOrganizationsApi
        .changeMedOrganization(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeMedOrganizationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMedOrganizationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.deleteMedOrganization](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMedOrganizationStart);
      medOrganizationsApi
        .deleteMedOrganization(id)
        .then((response) => {
          context.commit(mutationTypes.deleteMedOrganizationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMedOrganizationFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionTypes.attachDoctor](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.attachDoctorStart);
      medOrganizationsApi
        .attachDoctor(id)
        .then((response) => {
          context.commit(mutationTypes.attachDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.attachDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.detachDoctor](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.detachDoctorStart);
      medOrganizationsApi
        .detachDoctor(id)
        .then((response) => {
          context.commit(mutationTypes.detachDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.detachDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.attachClient](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.attachClientStart);
      medOrganizationsApi
        .attachClient(id)
        .then((response) => {
          context.commit(mutationTypes.attachClientSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.attachClientFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.detachClient](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.detachClientStart);
      medOrganizationsApi
        .detachClient(id)
        .then((response) => {
          context.commit(mutationTypes.detachClientSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.detachClientFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
