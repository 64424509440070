<template lang="pug">
.profile-doctor-detail
  .profile-doctor-detail__inner
    .profile-doctor-detail__header
      .row.align-items-center
        .col-14.col-md-auto.me-auto.order-last.order-md-first
          .row.align-items-center.g-4
            .col-auto
              app-back-button(:prev-page="'doctors'")
            .col
              app-title(:type="'large'") {{ isTouchDevice ? 'Медработник' : 'Страница медработника' }}

    .profile-doctor-detail__content.mt-20
      transition(name="translucent")
        div(v-if="doctor")
          .row.align-items-center
            .col
              section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                .row.g-4.justify-content-between
                  .col
                    .text-color--blue.mb-3 ФИО
                    span(v-if="doctor.full_name") &#32;{{ doctor.full_name }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 СНИЛС
                    span(v-if="doctor.snils") {{ doctor.snils }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 Дата регистрации
                    span(v-if="doctor.creation_date") {{ toLocalDate(doctor.creation_date) }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 Документ об образовании
                    span(v-if="doctor.series_number") {{ doctor.series_number }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 Сертификат о подготовке
                    button(
                      v-if="doctor.certificate"
                      class=['profile-card__button--document', 'link--accent']
                      type="button"
                      @click.prevent="getFile(doctor.certificate)"
                    )
                      span.d-flex.align-items-center
                        span.flex-shrink-0
                          so-icon(icon="document")
                        span.ms-2 Документ
                    span(v-else) &#8212;

                  .col.d-none.d-md-block
                    .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeDoctor')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          so-icon(icon="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteDoctor')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          so-icon(icon="trash")

              section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                .row.g-4.justify-content-between
                  .col
                    .text-color--blue.mb-3 Место работы
                    span(v-if="doctor.med_organization_name") {{ doctor.med_organization_name }}
                    span(v-else) &#8212;
                  .col
                    .text-color--blue.mb-3 ИНН организации
                    span(v-if="doctor.med_organisation_inn") {{ doctor.med_organisation_inn }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 № лицензии
                    span(v-if="doctor.licence_number") {{ doctor.licence_number }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 Дата выдачи
                    span(v-if="doctor.licence_data_reciving") {{ this.$moment(doctor.licence_data_reciving).format('DD.MM.YYYY') }}
                    span(v-else) &#8212;

                  .col
                    .text-color--blue.mb-3 Срок окончания
                    span(v-if="doctor.licence_data_ending") {{ this.$moment(doctor.licence_data_ending).format('DD.MM.YYYY') }}
                    span(v-else) &#8212;
                  .col.d-md-none
                    .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeDoctor')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          so-icon(icon="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteDoctor')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          so-icon(icon="trash")

          .position-relative
            template(v-if="!exams")
              .text-color--blue.text-size--small.mt-20 Нет осмотров
            app-error-message(v-if="error" :message="error")

          // exams tab
          so-tabs(v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex")
            so-tab(label="Осмотры" name="exams")
              app-loading(v-if="isLoading.exams")
              template(v-if="exams.length")
                .profile-table.is-exams-table
                  .profile-table__header.d-none.d-xl-block
                    .row.align-items-center.g-4
                      div(v-for="column in examsTab.columns" :key="column.key" :class="column.class")
                        button(
                          v-if="column.caption || column.icon"
                          type="button"
                          @click="sortingTable(column.key)"
                          class=['profile-table__sort-button']
                          :class="{'is-active': examsTab.sortKey === column.key}"
                        )
                          span.d-flex.align-items-center
                            span(v-if="column.caption") {{ column.caption }}
                            span.flex-shrink-0(v-else-if="column.icon")
                              so-icon(:icon="column.icon")
                            span.flex-shrink-0.d-flex.ms-3
                              so-icon(icon="down-arrow")
                ul.profile-table__body(id="doctor-exams")
                  li.profile-table__row(v-for="exam in exams" :key="exam.exams_id")
                    app-exam-card(
                      :data="exam"
                      :parent-id="'doctor-exams'"
                      :type="'doctor-detail'"
                      :columns="examsTab.columns"
                      :classes="'d-none d-xl-block'"
                      @openModal="openExamDetailModal"
                      @openCertificate="openCertificate"
                      @openGallery="openGallery"
                    )
                    app-exam-card-mobile(
                      :data="exam"
                      :classes="'d-xl-none'"
                      @openModal="openExamDetailMobile"
                    )
                .d-flex.justify-content-center.mt-5
                  app-pagination(
                    :total="pagination.examsPagination.total"
                    :limit="pagination.examsPagination.limit"
                    :current-page="currentExamsPage"
                    :url="baseUrl"
                  )
              template(v-else-if="!isLoading.exams && !exams.length")
                .text-color--blue.text-size--small Осмотры не найдены

            //- so-tab(label="Клиенты" name="users")
            //-   app-loading(v-if="isLoading.users")
            //-   template(v-if="users && users.length")
            //-     .profile-table
            //-       .profile-table__header.d-none.d-xl-block
            //-         .row.align-items-center.gx-4
            //-           div(v-for="column in usersTab.columns" :key="column.key" :class="column.class")
            //-             button(
            //-               type="button"
            //-               @click="sortingUserTable(column.key)"
            //-               class=['profile-table__sort-button']
            //-               :class="{'is-active': usersTab.sortKey === column.key}"
            //-             )
            //-               span.d-flex.align-items-center
            //-                 span.text-overflow(v-if="column.caption") {{ column.caption }}
            //-                 span(v-else-if="column.icon")
            //-                   so-icon(:icon="column.icon")
            //-                 span.d-flex.ms-3
            //-                   so-icon(icon="down-arrow")
            //-       ul.profile-table__body
            //-         li.profile-table__row(v-for="user in users" :key="user.id")
            //-           router-link(:to="{name: 'user-detail', params: {id: user.user_id}}" class=['d-block'])
            //-             app-admin-user-card(:data="user")
            //-     .d-flex.justify-content-center.mt-5
            //-       app-pagination(
            //-         :total="pagination.usersPagination.total"
            //-         :limit="pagination.usersPagination.limit"
            //-         :current-page="currentUsersPage"
            //-         :url="baseUrl"
            //-       )
            //-   template(v-else-if="!isLoading.users && !users.length")
            //-     .text-color--blue.text-size--small Клиенты не найдены

        template(v-else-if="!isLoading")
          .text-color--blue.text-size--small Медработника не существует в системе


    // change doctor modal
    app-change-doctor-modal(
      v-if="doctor"
      :doctor="doctor"
      :is-visible="isModalVisible.changeDoctor"
      @close="closeModal('changeDoctor')"
      @success="getCurrentDoctor"
    )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @close="closeModal('examDetailMobile')"
      @openCertificate="openCertificate"
      @openGallery="openGallery"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
  // delete doctor modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteDoctor" @close="closeModal('deleteDoctor')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить медработника?
          p.text-color--blue.mt-3 {{ doctor.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteDoctor')"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Не удалять
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteDoctor(doctor)"
              class=['button', 'button--border-blue', 'form__button']
            )
              span.button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // success delete doctor modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Медработник успешно удалён!
          p(v-if="doctor.full_name").text-color--blue.mt-3 ФИО медработника: {{ doctor.full_name }}.

        .d-flex.justify-content-center.mt-5
          button(
            @click="$router.go(-1)"
            type="button"
            class=['button button--accent']
          )
            span.button__content Назад в список
</template>

<script>
import examsApi from '@/api/exams';
import doctorsApi from '@/api/doctors';
import medOrganizationsApi from '@/api/medOrganizations';

import {mapState} from 'vuex';

import is from 'is_js';
import {declOfNum, toLocalDate, getFile} from '@/helpers/utils';

import AppTab from '@/components/common/Tab';
import AppTabNav from '@/components/common/TabNav';
import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppSelect from '@/components/common/Select';
import AppPagination from '@/components/common/Pagination';
import AppGallery from '@/components/common/Gallery';
import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import SoTab from '@/components/so/tabs/SoTab';
import SoTabs from '@/components/so/tabs/SoTabs';

import AppAdminUserCard from '@/components/profile/admin/users/AdminUserCard';
import AppChangeDoctorModal from '@/components/profile/admin/doctors/ChangeDoctorModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'AppAdminDoctorDetail',
  components: {
    AppBackButton,
    AppTitle,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppModal,
    AppSelect,
    AppGallery,
    AppTab,
    AppTabNav,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppAdminUserCard,
    AppChangeDoctorModal,
    SoTab,
    SoTabs,
  },
  mixins: [galleryMixin, examDetailModalMixin, examDetailMobileMixin, certificateModalMixin],
  data() {
    return {
      isLoading: {
        users: false,
        exams: false,
      },
      users: {},
      exams: {},
      doctor: {},
      doctorOrganization: {},
      pagination: {
        examsPagination: {
          limit: 5,
          total: 0,
        },
      },
      filterString: '',
      declOfNum,
      isModalVisible: {
        changeDoctor: false,
        deleteDoctor: false,
      },
      activeTabId: 'exams',
      tabs: [
        {
          id: 'exams',
          caption: 'Осмотры',
          sortKey: 'exam_number',
          columns: [
            {
              class: 'col-1',
              caption: '№',
              key: 'exam_number',
              inverse: false,
            },
            {
              class: 'col-4 col-xl-3 col-xxl-2',
              caption: 'Дата и время',
              key: 'exam_datetime',
              inverse: true,
            },
            {
              class: 'col-3',
              caption: 'ФИО пользователя',
              key: 'patient_full_name',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'pulse',
              key: 'bp_high',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'cardio',
              key: 'heart_rate',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'temp',
              key: 'body_temp',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'breathalyzer',
              key: 'alcohol',
              inverse: true,
            },
            {
              class: 'col-1',
              key: 'health_complaint',
              inverse: false,
            },
            {
              class: 'col-2 ms-auto',
              caption: 'Допуск',
              key: 'admission_to_work',
              inverse: true,
            },
          ],
        },
        {
          id: 'clients',
          caption: 'Клиенты',
          sortKey: 'creation_date',
          columns: [
            {
              class: 'col-2',
              caption: 'Дата регистрации',
              key: 'creation_date',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Тип клиента',
              key: 'user_type',
              inverse: false,
            },
            {
              class: 'col-3',
              caption: 'Клиент',
              key: 'full_name',
              inverse: false,
            },
            {
              class: 'col-4',
              caption: 'E-mail',
              key: 'email',
              inverse: false,
            },
            {
              class: 'col-3',
              caption: 'Осмотры',
              key: 'exam_count',
              inverse: true,
            },
          ],
        },
      ],
      activeIndex: 0,
    };
  },
  computed: {
    ...mapState({
      globalSettings: (state) => state.settings.data,
      error: (state) => state.doctors.error,
    }),
    currentExamsPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    doctorId() {
      return Number(this.$route.params.id);
    },
    activeTab() {
      return this.tabs.find((el) => el.id === this.activeTabId);
    },
    examsTab() {
      return this.tabs[0];
    },
    usersTab() {
      return this.tabs[1];
    },
  },
  watch: {
    currentExamsPage() {
      this.getExams();
    },
  },
  async created() {
    this.getExams();
    this.getCurrentDoctor();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({query: Object.assign({}, this.$route.query, {page: 1})});
    },
    async getExams() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.examsTab.sortKey}`;
      this.isLoading.exams = true;
      let res = await examsApi.getExamsByDoctorId(
        this.currentExamsPage - 1,
        order_by,
        this.doctorId
      );
      this.exams = res.data.items;
      this.pagination.examsPagination.total = res.data.total;
      this.pagination.examsPagination.limit = res.data.per_page;
      this.isLoading.exams = false;
    },
    async getCurrentDoctor() {
      let res = await doctorsApi.getDoctorInfo(this.doctorId);
      this.doctor = res.data;
    },
    async getDoctorOrganization() {
      let med = await medOrganizationsApi.getMedOrganizations({ medical_organization_id: this.doctor.medical_organization_id });
      this.doctorOrganization = med.data.items[0];
    },

    toLocalDate(date) {
      return toLocalDate(date);
    },
    setActiveTab(id) {
      this.getExams();
      this.goToFirstPage();
      this.activeTabId = id;
    },
    getFile(src) {
      getFile(src);
    },
    sortingTable(sortKey ) {
      this.sortInverse = !this.sortInverse;
      this.examsTab.sortKey = sortKey;
      this.getExams();
    },
    async deleteDoctor({ id }) {
        try {
          await doctorsApi.deleteDoctor(id);
          this.closeModal('deleteDoctor');
          this.openModal('success');
        } catch (error) {
          this.$toast.error('Произошла ошибка при удалении доктора:', error);
        }
    },
    changeActiveTab(index) {
      console.log(index);
      this.activeIndex = index;
    }
  },
};
</script>

<style lang="scss">
.profile-doctor-detail {
  &__card {
    border-radius: $border-radius-sm;
    padding: $spacer $spacer * 1.6;
  }
}
</style>
