<template>
<div class="profile-doctors">
  <div class="profile-doctors__header">
    <div class="col-14 d-none d-md-block">
      <div class="d-flex download-risks-groups">
        <div class="col-2 date-picker-margins">
          <date-picker
            prefix-class="app"
            placeholder="от"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="startDate"
          ></date-picker>
        </div>
        <div class="col-2 date-picker-margins">
          <date-picker
            prefix-class="app"
            placeholder="до"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="endDate"
          ></date-picker>
        </div>
        <div class="col-3 date-picker-margins" v-if="currentUser.role==='ADMIN'">
          <input
            :class="['form__field']"
            ref="multipeSelectUsers"
            v-model="examNumber"
          >
        </div>
        <div class="col-3">
          <button
            type="button"
            @click="downloadExamStats"
            class="button button--border-blue"
          >
            <span class="button__content">Сформировать отчет</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { gettersTypes as authGettersTypes } from '@/store/modules/auth';

import examsApi from '@/api/exams';

import DatePicker from 'vue2-datepicker';
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import NavChart from '@/components/profile/common/NavChart';

  import { blobToFile } from '@/helpers/utils';

  export default {
    name: 'ExamStats',
    components: {
      AppTitle,
      DatePicker,
      AppMultipleSelect,
      NavChart
    },
    computed: {
      ...mapGetters({
        currentUser: authGettersTypes.currentUser,
      }),
    },
    data() {
      return {
        startDate: null,
        endDate: null,
        examNumber: null,
        queryParams: ''
      }
    },
    methods: {
      generateQueryParams() {
        if (this.endDate !== null || this.startDate !== null || this.examNumber !== null) {
          const params = new URLSearchParams();
          if (this.endDate !== null) {
            params.append('created_at__lte', this.$moment(this.endDate).format('YYYY-MM-DD'));
          }
          if (this.startDate !== null) {
            params.append('created_at__gte', this.$moment(this.startDate).format('YYYY-MM-DD'));
          }
          if (this.examNumber !== null) {
            params.append('exam_number', this.examNumber);
          }
          this.queryParams = params.toString();
        } else {
          this.queryParams = '';
        }
      },
      async downloadExamStats() {
        console.log('downloadExamStats');
        this.generateQueryParams();
        console.log(this.queryParams);
        if (this.queryParams) {
          this.queryParams = '?' + this.queryParams
        }
        examsApi.getExamStats(this.queryParams)
          .then((response) => {
            return response.data;
          }).then((binary) => blobToFile(binary, 'xlsx'));
      }
    },
  }
</script>

<style>
.download-risks-groups {
  margin-top: 50px;
}

.date-picker-margins {
  margin-right: 20px;
  margin-top: 2px;
}

</style>
