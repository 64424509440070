
ul
  li(v-for="(el) in data")
    .mb-3(v-if="el.device") Устройство {{el.device}}
    .text-color--blue.text-size--small.mb-1 {{el.coords[0]}}, {{el.coords[1]}}
    button(
      v-if="el.count"
      type="button"
      :data-device="el.device",
      :data-coords="el.coords"
      class=['text-decoration--underline', 'text-size--small', 'link--accent']
    ) {{ el.count }} {{ declOfNum(el.count, ['осмотр', 'осмотра', 'осмотров'])}} за данный период
