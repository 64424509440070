
transition(name="fade" mode="out-in")
  app-modal(
    v-if="isVisible"
    @close="closeModal"
    :without-close-btn="true"
    :classes="'exam-detail-modal large'"
  )
    template(v-slot:body)
      .exam-detail-modal__header.mb-4
        .row.align-items-center.g-4
          .col-14.col-md-7
            .d-md-none.d-flex.align-items-center.mb-3
              ul.text-family--light
                li
                  span(v-if="data.exam_date") {{ data.exam_date }}
                  span(v-else) {{ toLocalDate(data.exam_datetime) }}
                  span.ms-3(v-if="data.exam_time") {{ data.exam_time }}
                  span.ms-3(v-else) {{ toLocalTime(data.exam_datetime) }}

              button(
                @click="closeModal"
                type="button"
                class=['d-flex', 'ms-auto']
                aria-label="Close modal"
              )
                span.d-flex
                  so-icon(icon="close")

            .d-flex.align-items-center
              app-avatar(
                :localUrl="data.patient_avatar"
                :classes="'square extra-large'"
                :viewPhoto="true"
                @openGallery="openGallery"
              )
              ul.ms-4.text-family--light
                li.mb-2.d-none.d-md-inline-block
                  span(v-if="data.exam_date") {{ data.exam_date }}
                  span(v-else) {{ toLocalDate(data.exam_datetime) }}
                  span.ms-3(v-if="data.exam_time") {{toLocalTimeExam(data.exam_date_time)}}
                li.mb-2 {{ data.exam_number }} {{ data.patient_full_name }}
                li {{ data.email }}

          .col-14.col-md-7
            .d-flex.align-items-center
              .exam-detail-modal__info.text-size--small
                transition(name="fade" mode="out-in")
                  .row.align-items-center.justify-content-center.g-3.g-sm-5(:key="data.id || data.exam_id")
                    .col-auto
                      .d-flex.align-items-center
                        span.me-2.me-sm-3
                          so-icon(icon="pulse")
                        span(v-if="data.bp_high && data.bp_low")
                          span(:class='{yellowText: data.bp_high_alarm}') {{ data.bp_high }} /
                          span(:class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
                        span(v-else) &#8212;
                    .col-auto
                      .d-flex.align-items-center
                        span.me-2.me-sm-3
                          so-icon(icon="cardio")
                        span(v-if="data.heart_rate" :class='{yellowText: data.heart_rate_alarm}') {{ data.heart_rate }}
                        span(v-else) &#8212;
                    .col-auto
                      .d-flex.align-items-center
                        span.me-2.me-sm-3
                          so-icon(icon="temp")
                        span(v-if="data.body_temp" :class='{yellowText: data.body_temp_alarm}') {{ data.body_temp + "\u2103"}};
                        span(v-else) &#8212;
                    .col-auto
                      .d-flex.align-items-center
                        span.me-2.me-sm-3
                          so-icon(icon="breathalyzer")
                        span(v-if="data.alcohol || data.alcohol === 0" :class='{yellowText: data.alcohol_alarm}') {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
                        span(v-else) &#8212;
              button(
                @click="closeModal"
                type="button"
                class=['d-none d-md-flex', 'ms-4']
                aria-label="Close modal")
                span.d-flex
                  so-icon(icon="close")

      .exam-detail-modal__content
        // map
        template(v-if="type === 'map'")
          .exam-detail-modal__map.profile-map
            app-loading(v-if="isLoading")
            transition(name="fade" mode="out-in")
              yandex-map(
                :key="data.id || data.exam_id"
                :settings="v.map.default"
                :options="map.options"
                :coords="[data.lat, data.long]"
                :scroll-zoom="false"
                :init-without-markers="false"
                :zoom="map.zoom"
                :controls="map.controls"
                :behaviors="map.behaviors"
                @map-was-initialized="initializedMap"
              )
                ymap-marker(
                  :marker-id="data.id || data.exams_id"
                  :coords="[data.lat, data.long]"
                  :icon="map.marker"
                )
                  app-map-balloon(slot="balloon" :data="[{device: data.device_serial, coords: [data.lat, data.long]}]")
        // video
        template(v-if="type === 'video'")
          .exam-detail-modal__video
            app-loading(v-if="isLoading")
            transition(name="fade" mode="out-in")
              video-player(
                :key="data.id || data.exam_id"
                :options="video"
              )
        // navigation
        .d-flex.align-items-center(v-if="isSlider")
          button(
            v-if="navigation.prev"
            type="button"
            :disabled="isLoading"
            @click.prevent="slideTo(navigation.prev)"
            class=['exam-detail-modal__prev-btn', 'me-auto d-flex align-items-center flex-xl-column align-items-xl-start', 'text-family--light link--accent'])
            span.flex-shrink-0.me-3.me-xl-0.mb-xl-3
              so-icon(icon="circle-left-arrow")
            span.d-flex.flex-column
              span(v-if="navigation.prev.exam_date") {{ navigation.prev.exam_date }}
              span(v-else) {{ toLocalDate(navigation.prev.exam_datetime) }}
              span.mt-1(v-if="navigation.prev.exam_time") {{ navigation.prev.exam_time }}
              span.mt-1(v-else) {{ toLocalTime(navigation.prev.exam_datetime) }}

          button(
            v-if="navigation.next"
            type="button"
            :disabled="isLoading"
            @click.prevent="slideTo(navigation.next)"
            class=['exam-detail-modal__next-btn', 'ms-auto d-flex align-items-center flex-row-reverse flex-xl-column align-items-xl-start', 'text-family--light link--accent'])
            span.flex-shrink-0.ms-3.ms-xl-0.mb-xl-3
              so-icon(icon="circle-right-arrow")
            span.d-flex.flex-column
              span(v-if="navigation.next.exam_date") {{ navigation.next.exam_date }}
              span(v-else) {{ toLocalDate(navigation.next.exam_datetime) }}
              span.mt-1(v-if="navigation.next.exam_time") {{ navigation.next.exam_time }}
              span.mt-1(v-else) {{ toLocalTime(navigation.next.exam_datetime) }}

