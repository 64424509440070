<template lang="pug">
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          v-if="isDirty"
          type="button"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Интервал даты регистрации:
                .row.g-3
                  .col-7
                    date-picker(
                      v-model="form.date.dateFrom"
                      prefix-class="app"
                      placeholder="от"
                      format="DD-MM-YYYY"
                      :lang="datepicker.lang"
                      :editable="false"
                      :disabled-date="notAfterDateTo"
                      @change="datepickerChange('dateFrom')"
                      @clear="datepickerClear('dateFrom')"
                    )
                      template(v-slot:icon-calendar)
                        so-icon(icon="calendar")
                  .col-7
                    date-picker(
                      v-model="form.date.dateTo"
                      prefix-class="app"
                      placeholder="до"
                      format="DD-MM-YYYY"
                      :lang="datepicker.lang"
                      :editable="false"
                      :disabled-date="notBeforeDateFrom"
                      @change="datepickerChange('dateTo')"
                      @clear="datepickerClear('dateTo')"
                    )
                      template(v-slot:icon-calendar)
                        so-icon(icon="calendar")

              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Серийный номер МИ:
                  .row.g-3
                    input(
                      :class="['form__field']"
                      type="string"
                      name="id"
                      v-model="serial_number"
                      placeholder="Введите серийный номер МИ"
                      autocomplete="off"
                      id="id"
                    )

            .row.flex-column.align-items-center.g-3.mt-3
              .col
                .form__caption.mb-3 Статус поверки:
                .row.g-3
                  app-select(
                    ref="selectAttestation"
                    :type="'form'"
                    :placeholder="'Выберите статус поверки'"
                    :options="attestationOpts"
                    @select="selectAttestation"
                  )

      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !this.collectData.length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
          @click="submit"
        )
          span.button__content
            span Показать
            transition(name="translucent" mode="out-in")

        button(
          v-if="isDirty && this.collectData.length"
          type="button"
          @click="clear"
          class=['profile-filter__clear-button', 'd-flex flex-column align-items-center']
        )
          span.d-flex.mb-2
            so-icon(icon="close")
          span.text-size--extra-small.text-decoration--underline Сбросить

</template>

<script>
import {mapState} from 'vuex';

import {toLocalDateExam} from '@/helpers/utils';
import is from 'is_js';

import simplebar from 'simplebar-vue';
import Slider from '@vueform/slider/dist/slider.vue2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppMultipleSelectFull from '@/components/common/MultipleSelectFull';
import AppSelect from '@/components/common/Select';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppFilterCheck',
  components: {
    AppTooltip,
    AppSelect,
    AppMultipleSelect,

    AppValidationErrors,
    DatePicker,
    Slider,
    simplebar,
    AppMultipleSelectFull,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      style: {
        top: null,
      },
      delay: 2000,
      anchor: '#isAnchorForBtn',
      serial_number: '',
      form: {
        isDirty: {
          dateFrom: false,
          dateTo: false,
          endFrom: false,
          endTo: false,
          serial_number: false,
          attestation: false,
          deviceOwner: false,
        },
        date: {
          dateFrom: null,
          dateTo: null,
          endFrom: null,
          endTo: null,
        },
        attestation: null,
        deviceOwner: null,
      },
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
      },
      attestationOpts: [
        {
          caption: 'Поверено',
          icon: 'allright',
          value: 'attestation',
          selected: false,
        },
        {
          caption: 'Не поверено',
          icon: 'wrong',
          value: 'not_attestation',
          selected: false,
        },
        {
          caption: 'Заканчивается',
          icon: 'allright',
          value: 'attestation_ends',
          selected: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.filters.isLoading,
      filters: (state) => state.filters.data,
      error: (state) => state.filters.error,
    }),
    isDirty() {
      return Object.values(this.form.isDirty).some((item) => item === true);
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    collectData() {
      let data = '';
      //datepicker
      if (this.form.date.dateFrom) {
        data += `&device_attestation_start_gte=${this.$moment(this.form.date.dateFrom).format(
          'YYYY-MM-DD'
        )}`;
      }
      if (this.form.date.dateTo) {
        data += `&exam_datetime_lte=${this.$moment(this.form.date.dateTo).format(
          'YYYY-MM-DD'
        )} 23:59:59`;
      }
      if (this.form.date.endFrom) {
        data += `&device_attestation_end_gte=${this.$moment(this.form.date.endFrom).format(
          'YYYY-MM-DD'
        )}`;
      }
      if (this.form.date.endTo) {
        data += `&device_attestation_end_lte=${this.$moment(this.form.date.endTo).format(
          'YYYY-MM-DD'
        )}`;
      }
      //device_owner
      if (this.form.deviceOwner) {
        data += `&device_verification_owner=${this.form.deviceOwner}`;
      }
      //serial_number
      if (this.serial_number !== '') {
        data += `&serial=${this.serial_number}`;
      }
      //attestation
      if (this.form.attestation) {
        data += `&attestation=${this.form.attestation}`;
      }
      return data;
    },
  },
  watch: {
    isDirty(value) {
      if (!value) this.$emit('clear');
    },
    deviceOwner: function () {
      this.form.isDirty.deviceOwner = true;
    },
    serial_number: function () {
      this.form.isDirty.serial_number = true;
    },
    attestation: function () {
      this.form.isDirty.attestation = true;
    },
  },
  methods: {
    datepickerChange(slug) {
      this.form.isDirty[slug] = true;
      this.collectData;
      slug === 'dateFrom'
        ? this.$emit('getDataFromFilter', {
          dateFromFilter: `${this.$moment(this.form.date.dateFrom).format(
            'YYYY-MM-DD'
          )} 00:00:00`,
        })
        : this.$emit('getDataToFilter', {
          dateToFilter: `${this.$moment(this.form.date.dateTo).format('YYYY-MM-DD')} 23:59:59`,
        });
    },
    datepickerClear(slug) {
      this.form.isDirty[slug] = false;
      this.form.isDirty[slug] = false;
      this.$emit('submit', this.collectData);

      if (slug === 'dateTo') {
        this.form.time.to = null;
        this.form.isDirty.timeTo = false;
      }
      if (slug === 'dateFrom') {
        this.form.time.from = null;
        this.form.isDirty.timeFrom = false;
      }
    },
    notAfterDateTo(date) {
      if (this.form.date.dateTo) return date > this.form.date.dateTo;
      if (this.form.date.endTo) return date > this.form.date.endTo;
    },
    notBeforeDateFrom(date) {
      if (this.form.date.dateFrom) return date < this.form.date.dateFrom;
      if (this.form.date.endFrom) return date < this.form.date.endFrom;
    },
    //select_attestation
    selectAttestation(option) {
      this.attestationOpts.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.attestation = true;
      this.form.attestation = option.value;
    },
    //common
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    setBtnPosition(anchor) {
      const el = document.querySelector(anchor || this.anchor);
      this.style.top = el ? `${el.getBoundingClientRect().y}px` : null;
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      if (this.isTouchDevice) this.submit();
    },

    async clear() {
      //datepicker
      this.form.date.dateFrom = null;
      this.form.date.dateTo = null;
      this.form.isDirty.dateFrom = false;
      this.form.isDirty.dateTo = false;
      //serial_number
      this.serial_number = '';
      this.form.serial_number = false;
      //attestation
      this.form.isDirty.attestation = false;
      this.form.attestation = null;
      this.$refs.selectAttestation.clear();
      this.$emit('submit', '');
    },
    async submit() {
      if (is.falsy(this.isDirty) || !this.collectData.length) {
        this.$emit('submit', '');
      } else {
        this.$emit('submit', this.collectData);
      }
    },
  },
};
</script>

<style lang="scss">
$bg-color: #0c1120;

.profile-filter {
  pointer-events: none;
  position: fixed;
  z-index: get($index, xl);
  top: 0;
  right: 0;
  width: 16.66667%;
  height: 100%;
  padding-top: $header-height;
  @include transition(transform);
  transform: translateX(85%);

  &:before {
    @include pseudo-wrapper();
    background-color: rgba($bg-color, 0.8);
    backdrop-filter: blur(4px);
    @include transition();
    opacity: 0;
  }
  @include mediaToDown($xxl) {
    width: 25%;
  }
  @include mediaToDown($xl) {
    width: 50%;
    transform: translateX(100%);

    &.is-open {
      .profile-filter {
        &__container {
          box-shadow: get($shadows, default);
        }
      }
    }
  }
  @include mediaToDown($md) {
    padding-top: 0;
    width: 100%;

    &.is-open {
      padding-top: $spacer;
      padding-left: $spacer;
      padding-right: $spacer;

      &:before {
        opacity: 1;
        transition-delay: #{$transition-duration}s;
      }
    }
  }
  &.is-open {
    transform: translateX(0);

    .profile-filter {
      &__content {
        opacity: 1;
        visibility: visible;
      }
      &__clear-button,
      &__open-button-mobile {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__container {
    position: relative;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: $border-radius-sm 0 0 0;
    color: get($colors, blue);
    padding-top: $spacer * 1.3;
    @include transition();

    @include mediaToDown($xl) {
      box-shadow: none;
    }
  }
  &__header {
    width: 100%;
  }
  &__content {
    position: relative;
    @include transition();
    opacity: 0;
    visibility: hidden;
    height: 90%;
  }
  &__form {
    max-height: 90%;
    overflow-x: hidden;
    padding-bottom: $spacer;
  }
  &__submit-button {
    position: relative;
    width: 105%;
    left: -5%;
    padding: $spacer * 1.2 $spacer * 1.2 $spacer * 1.2 $spacer * 2.3;
    border: $border-width solid get($colors, blue-light);
    border-radius: $border-radius-lg 0 0 0;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);

    @include mediaToDown($xl) {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
    }
    @include mediaToDown($md) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__clear-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 15%;
    background: get($gradients, orange);
    color: get($colors, white);
    padding: $spacer 0;
    @include transition();

    @include interaction('mouse') {
      &:hover {
        box-shadow: get($shadows, default);
      }
    }
  }
  &__open-button,
  &__close-button {
    @include transition(color);

    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);
      }
    }
  }
  &__open-button-mobile {
    position: absolute;
    z-index: get($index, default);
    width: $spacer * 4.4;
    height: $spacer * 4.4;
    right: 100%;
    top: 0;
    background-color: get($colors, white);
    border-radius: $spacer * 3 $spacer * 1.6 0 $spacer * 3;
    padding: $spacer;
    box-shadow: get($shadows, default);
    transition: background-color #{$transition-duration}s, color #{$transition-duration}s;
    transform: translateY($spacer);

    &.is-dirty {
      background-color: get($colors, blue-dark);
      color: get($colors, white);
    }
  }
  .icon {
    &__filter {
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
  }
}
</style>
