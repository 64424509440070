
<div class="video-container">
  <video
    v-if="videoSource !== null"
    ref="videoPlayer"
    :width="width"
    :key="videoSource"
    :height="height"
    controls
    @play="onPlay"
    @pause="onPause"
    @seeked="onSeeked"
  >
    <source :src="videoSource" type="video/mp4" />
  </video>
  <div class="buttons-container">
    <button @click="changePlaybackRate(2)">2x</button>
    <button @click="changePlaybackRate(5)">5x</button>
    <button @click="changePlaybackRate(10)">10x</button>
  </div>
</div>
