
.profile-patient-detail
  .profile-patient-detail__inner.is-filterable
    .profile-patient-detail__header.mb-3.mb-md-0
      .row.align-items-center
        .col-14.col-md-auto.me-auto.order-last.order-md-first
          .row.align-items-center.g-4
            .col-auto
              app-back-button(:prev-page="'patients'")
            .col
              app-title(:type="'large'") Страница пользователя

            .col-auto.d-md-none
              .d-flex.align-items-center.justify-content-end
                button(
                  type="button"
                  @click.prevent="openModal('changePatient')"
                  class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                )
                  span.flex-shrink-0
                    so-icon(icon="edit")

                button(
                  type="button"
                  @click.prevent="openModal('deletePatient')"
                  class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                )
                  span.flex-shrink-0
                    so-icon(icon="trash")

    .profile-patient-detail__content.mt-20.position-relative(id="isAnchorForBtn")
      transition(name="translucent")
        div(v-if="patient")
          section(class=['profile-patient-detail__card'])
            .row.g-4
              .col-7.col-md-3
                .d-md-none.text-color--blue.mb-3 Телефон и e-mail
                .d-flex.align-items-center
                  .d-none.d-md-block.me-20
                    app-avatar(
                      :type="'patient'"
                      :localUrl="patient.avatar"
                      :viewPhoto="true"
                      @openGallery="openGallery"
                      @change="openModal('changeAvatar')"
                      :classes="'middle'"
                    )
                  ul
                    li.mb-3
                      span.text-color--blue-dark(v-if="patient.email") {{ patient.email }}
                      span.text-color--blue(v-else) нет e-mail
                    li
                      span.text-color--blue-dark(v-if="patient.phone") {{ patient.phone }}
                      span.text-color--blue(v-else) нет телефона
              .col-14.col-md-3.order-first.order-md-0
                .d-flex.align-items-center
                  app-avatar(
                    :type="'patient'"
                    :localUrl="patient.avatar"
                    :viewPhoto="true"
                    @openGallery="openGallery"
                    @change="openModal('changeAvatar')"
                    :classes="'middle d-md-none'"
                  )
                  .ms-10
                    .text-color--blue.mb-3 ФИО
                    span.text-color--blue-dark {{ patient.full_name }}
              .col-7.col-md-2
                .text-color--blue.mb-3 СНИЛС
                span.text-color--blue-dark {{ patient.snils }}
              .col-7.col-md-3
                .text-color--blue.mb-3 Дата регистрации
                span.text-color--blue-dark {{ toLocalDate(patient.creation_date) }}

              .col.d-none.d-md-block
                .d-flex.align-items-center
                  .me-auto
                    .text-color--blue.mb-3 Осмотров
                    span.text-color--blue-dark {{ patientExams.total ? patientExams.total : 'нет' }}

                  .d-flex.align-items-center
                    button(
                      type="button"
                      @click.prevent="openModal('changePatient')"
                      class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                    )
                      span.flex-shrink-0
                        so-icon(icon="edit")

                    button(
                      type="button"
                      @click.prevent="openModal('deletePatient')"
                      class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-none d-md-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        so-icon(icon="trash")

            .row.gx-4.align-items-center.mt-15
              .col-auto
                app-signature(
                  :sample="patient.signature_sample"
                  :localUrl="patient.signature"
                  :theme="'shadow'"
                  @openGallery="openGallery"
                  @change="openModal('changeSignature')"
                )
              .col
                .profile-patient-detail__card-detail.profile-card.no-hover
                  .row.g-4
                    .col-14.col-md-3
                      .text-color--blue.mb-2 Водительское удостоверение
                      span.text-color--blue-dark(v-if="patient.serial_number_driving_license") {{ patient.serial_number_driving_license }} от {{ toLocalDate(patient.driving_license_receiving)}}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Карта RFID
                      span.text-color--blue-dark(v-if="patient.rfid_id") {{ patient.rfid_id }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Дата рождения
                      span.text-color--blue-dark(v-if="patient.date_birth") {{ toLocalDate(patient.date_birth) }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Пол
                      span.text-color--blue-dark(v-if="patient.gender") {{ patient.gender == '1' ? 'Мужской' : 'Женский' }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Табельный&nbsp;номер
                      span.text-color--blue-dark(v-if="patient.personal_number") {{ patient.personal_number }}
                      span.text-color--blue(v-else) &#8212;
                    .col
                      button(
                        v-if="currentUser.role === 'ADMIN'"
                        type="button"
                        @click="unlockUser()"
                        class=['button', 'button--accent', 'form__button']
                        :disabled="!patient.lock_status"
                      )
                        .button__content Разблокировать

              transition(name="fade" mode="out-in")
                .mt-15
                  ul.profile-patient-detail__exams
                    li.profile-patient-detail__exams-el
                      section(class=['patient-exams', 'profile-card'])
                          app-patient-exams(
                            :userId="$route.params.id"
                            @openModal="openExamDetailModal"
                            @openGallery="openGallery"
                            @openCertificate="openCertificate"
                          )

              //- TODO it was temp removed
              //- transition(name="fade" mode="out-in")
              //-   .mt-15
              //-     ul.profile-patient-detail__exams
              //-       li.profile-patient-detail__exams-el
              //-         section(class=['patient-exams', 'profile-card'])
              //-             app-patient-block-history(
              //-               :userId="$route.params.id"
              //-             )

              transition(name="fade" mode="out-in")
                .mt-15
                  ul.profile-patient-detail__exams
                    li.profile-patient-detail__exams-el
                      section(class=['patient-exams', 'profile-card'])
                          app-patient-personal-borders(
                            :data="patient"
                            :patientCode="patient.code"
                          )

              transition(name="fade" mode="out-in")
                .mt-15(v-if="currentUser.role !== 'CLIENT'")
                  ul.profile-patient-detail__exams
                    li.profile-patient-detail__exams-el
                      section(class=['patient-exams', 'profile-card'])
                          app-patient-med-docs(:patientCode="patient.code", :showDocInfo="showDocInfo")

              transition(name="fade" mode="out-in")
                .mt-15(v-if="currentUser.role !== 'CLIENT'")
                  ul.profile-patient-detail__exams
                    li.profile-patient-detail__exams-el
                      section(class=['patient-exams', 'profile-card'])
                          app-patient-comments(:patientCode="patient.code", :showDocInfo="showDocInfo")

  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
    @openEditModal="openModal"
    @openDeleteModal="openModal"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // change avatar modal
  app-patient-change-avatar-modal(
    v-if="patient"
    ref="changeAvatar"
    :patient="patient"
    :is-visible="isModalVisible.changeAvatar"
    @success="successChangePatient('changeAvatar')"
    @close="closeModal('changeAvatar')"
  )
  // change signature modal
  app-patient-change-signature-modal(
    v-if="patient"
    ref="changeSignature"
    :patient="patient"
    :is-visible="isModalVisible.changeSignature"
    @success="successChangePatient('changeSignature')"
    @close="closeModal('changeSignature')"
  )
  // change patient modal
  app-change-patient-modal(
    v-if="patient"
    :patient="patient"
    :is-visible="isModalVisible.changePatient"
    @success="successChangePatient('changePatient')"
    @close="closeModal('changePatient')"
  )
  // delete avatar modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Архивировать фотографию пользователя?
          p.text-color--blue.mt-3 {{ patient.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteAvatar')"
              class=['button', 'button--accent', 'form__button']
            )
              .button__content Не архивировать
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteAvatar(patient)"
              class=['button', 'button--border-blue', 'form__button']
            )
              .button__content Да, архивировать

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // delete signature modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteSignature" @close="closeModal('deleteSignature')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить подпись пользователя?
          p.text-color--blue.mt-3 {{ patient.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteSignature')"
              class=['button', 'button--accent', 'form__button']
            )
              .button__content Не удалять
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteSignature(patient)"
              class=['button', 'button--border-blue', 'form__button']
            )
              .button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // exam detail modal
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @openCertificate="openCertificate"
    @close="closeModal('examDetailMobile')"
  )
  // delete patient modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deletePatient" @close="closeModal('deletePatient')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Удалить пользователя?
          p.text-color--blue.mt-3 {{ patient.full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deletePatient')"
              class=['button', 'button--accent', 'form__button']
            )
              .button__content Не удалять
          .col-7.col-md-auto
            button(
              type="button"
              @click="deletePatient(patient)"
              class=['button', 'button--border-blue', 'form__button']
            )
              .button__content Да, удалить

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! При удалении пользователя удалятся все его осмотры.<br>Отменить действие нельзя.

  // success delete modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") Пользователь успешно удалён!
          p.text-color--blue.mt-3 ФИО пользователя: {{ full_name }}.

        .d-flex.justify-content-center.mt-5
          button(
            @click="$router.go(-1)"
            type="button"
            class=['button button--accent']
          )
            .button__content Назад в список

  // success change modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successChange" @close="closeModal('successChange')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") {{ successMessage }}
          p.text-color--blue.mt-3(v-if="patient") ФИО пользователя: {{ full_name }}

  //Handler doc
  handler-doc-modal(
      :is-visible="isVisible"
      :rowData="dataToShow"
      :showData="true"
      @close="close"
  )
