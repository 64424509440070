
.notice
  app-accordion(
      :id="String(data.id)"
      :parent-id="'incoming'"
      :classes="'profile-card'"
    )
    template(v-slot:header)
      .exam-card__header
        .row.align-items-center.g-4
          .col-auto
              span.idDoc {{ data.id }}
          .d-none.d-xl-block.col-2
            span(v-if='data.document_status=="confirmed"') {{ "Подтверждено" }}
            .yellow(v-else-if='data.document_status=="awaiting"') {{ "На подтверждении" }}
            .yellow(v-else-if='data.document_status=="rejected"') {{ "Отклонено" }}
            span(v-else) {{" В архиве "}}
          .col-auto.col-2
            span {{ toLocalDateExam(data.create_datetime) }}  {{ toLocalTimeExam(data.create_datetime ) }}
          .col-auto.col-2
            span {{ data.company_name }}

          .col-3
            span.text-overflow {{data.patient_full_name}}

          .col-7.col-md-2.col-xl-2
            span.text-overflow(v-if="data.issue_date") {{ toLocalDateExam(data.issue_date) }}
          .col-7.col-md-2.col-xl-2
            span.text-overflow(v-if="data.expiration_date") {{ toLocalDateExam(data.expiration_date) }}
          .col-7.col-md-1.col-xl-1
    template(v-slot:body)
      .incomingChild
        .row
          .col-4
            .text-color--blue.mb-2 Дата и время подтверждения
            span(v-if="data.verification_datetime") {{ toLocalDateExam(data.verification_datetime) }} {{ toLocalTimeExam(data.verification_datetime) }}
          .col-3
            .text-color--blue.mb-2 ФИО подтвердившего
            span {{ data.confirmed_user_full_name }}
          .col-4
            .text-color--blue.mb-2 Диагноз
            span {{ data.diagnosis }}
          .col-3
            .text-color--blue.mb-2 Доступные действия
            span.d-block.incomingAction.mb-3(@click='open') Редактировать
            span.d-block.incomingAction(@click='archive') Архивировать

  //Handler doc
  handler-doc-modal(
      :is-visible="isVisible"
      :rowData="data"
      @close="close"
      @open="open"
      :changeDocStatus="changeDocStatus"
  )

