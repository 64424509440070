
.patient-exams-card
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showExams"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Последние осмотры пользователя
  // patient exams
  .position-relative
    transition(name="fade" mode="out-in")
    .patient-exams-card__wrapper
    .mt-15(v-if="showInfo && exams.length")
      section(class=['patient-exams', 'profile-card'] v-if="showInfo && exams.length")

        ul.patient-exams__list(id="patient-exams")
          li.patient-exams__list-el(v-for="exam in exams" :key="exam.exam_id")
            app-patient-exam-card(
              :parent-id="'patient-exams'"
              :classes="'d-none d-xl-block'"
              :data="exam"
              @openModal="openExamDetailModal"
              @openGallery="openGallery"
              @openCertificate="openCertificate"
            )

      .mt-5.text-color--blue.text-size--small(v-else) Осмотров не найдено

  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )
