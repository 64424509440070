<template lang="pug">
.modal-container
  simplebar.modal-inner
    .modal-overlay(aria-label="Close modal" @click="close")
    .modal(
      role="dialog"
      aria-describedby="modal-description"
      :class="classes"
    )
      button(
        v-if="!withoutCloseBtn"
        @click="close"
        type="button"
        class='modal__close-button d-flex'
        aria-label="Close modal"
      )
        span.d-flex
          so-icon(icon="close")

      .modal__body#modal-description
        slot(name="body")

      .modal__footer
        slot(name="footer")

</template>

<script>
import AppTitle from '@/components/common/Title';
import simplebar from 'simplebar-vue';

export default {
  name: 'AppModal',
  components: {
    AppTitle,

    simplebar,
  },
  props: {
    classes: {
      type: String,
      required: false,
      default: null,
    },
    withoutCloseBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
$bg-color: #0c1120;

.modal-container {
  position: fixed;
  z-index: get($index, super) * 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($bg-color, 0.8);
  backdrop-filter: blur(4px);
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-inner {
  width: 100%;
  height: 100%;
  padding: $spacer * 4 0;

  .simplebar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }
}
.modal {
  position: relative;
  z-index: get($index, default);
  width: $spacer * 63;
  min-height: $spacer * 20;
  background-color: get($colors, white);
  border-radius: $border-radius-lg;
  padding: $spacer * 4 $spacer * 2;
  box-shadow: get($shadows, large);

  &.small {
    width: $spacer * 43;
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;
  }
  &.large {
    width: $spacer * 83;
  }
   &.largeNotices {
    width: $spacer * 79;
  }
  &.modal-reasons{
    width: 1100px;
  }
  @include mediaToDown($xl) {
    &.large {
      width: calc(100% - #{$grid-gutter-width});
    }
  }
  &.extra-large {
    width: $spacer * 100.3;
  }
  &.modal-detail {
    width: $spacer * 120.3;
  }
  @include mediaToDown($xl) {
    &.extra-large {
      width: calc(100% - #{$grid-gutter-width});
    }
     &.modal-detail {
    width: calc(100% - #{$grid-gutter-width});
  }
  }
  &.close-outside {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;

    @include mediaToDown($md) {
      padding-top: $spacer;
    }
    .modal {
      &__close-button {
        top: -$spacer * 2.6;
        right: 0;

        svg {
          fill: get($colors, white);
        }
      }
    }
  }
  &.gallery {
    width: auto;
    max-width: $spacer * 50;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    pointer-events: none;
    padding: $spacer 0;

    .modal {
      &__body,
      &__footer,
      &__close-button {
        pointer-events: auto;
      }
      &__close-button {
        svg {
          fill: get($colors, white);
        }
      }
    }
    img {
      image-orientation: from-image;
      border-radius: $border-radius-lg;
      box-shadow: get($shadows, large);
    }
    @include mediaToDown($md) {
      width: calc(100% - #{$grid-gutter-width});

      img {
        border-radius: $border-radius-sm;
      }
    }
  }
  @include mediaToDown($md) {
    width: calc(100% - #{$grid-gutter-width});
    border-radius: $border-radius-sm;
    padding-top: $spacer * 4;
    padding-bottom: $spacer * 2.5;

    &.small {
      width: calc(100% - #{$grid-gutter-width});
      padding-left: $spacer;
      padding-right: $spacer;
    }
  }
  &__body {
  }
  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: $spacer * 9;
    height: $spacer * 9;
    background-color: get($colors, white);
    border-radius: 50%;
    box-shadow: get($shadows, default);
    transform: translate3d(-50%, -50%, 0);

    img {
      width: 70%;
      height: 70%;
      object-fit: contain;
      object-position: center;
    }
    @include mediaToDown($sm) {
      transform: translate3d(-10%, -60%, 0);
    }
  }
  &__back-button,
  &__close-button {
    position: absolute;
    z-index: get($index, xs);
    top: $spacer * 2;
    width: $spacer * 1.6;
    height: $spacer * 1.6;
    @include transition();

    svg {
      width: $spacer * 1.6;
      height: $spacer * 1.6;
    }
    &--outside {
    }
    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);
      }
    }
  }
  &__back-button {
    left: $spacer * 2;

    @include mediaToDown($md) {
      top: $spacer * 1.6;
      left: $spacer;
    }
  }
  &__close-button {
    right: $spacer * 2;

    @include mediaToDown($md) {
      top: $spacer * 1.6;
      right: $spacer;
    }
  }
}
</style>
