<template lang="pug">
  .profile-map
    .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
      .profile-map__header
        .row.align-items-center.flex-column-reverse.flex-md-row.g-4
          .col
            .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
              app-back-button(:classes="'me-5'")
              .row.justify-content-center
                .col-8.col-sm-14
                  app-title(
                    :type="'large'"
                    :classes="'text-center'"
                  ) Перемещение устройств

          .col-auto
            router-link(:to="{name: 'map'}" class=['d-flex align-items-center', 'text-size--small link--accent'])
              span.d-flex.flex-shrink-0.me-2
                so-icon(icon="map-point")
              span.text-size--small {{ isTouchDevice ? 'Геокарта' : 'Просмотреть геокарту' }}


      .profile-map__content.position-relative.mt-30.mt-md-20
        app-loading(v-if="examsLoading")
        app-error-message(v-if="examsError" :message="examsError")

        transition(name="fade" mode="out-in")
          div(v-if="map.devices && map.devices.length")
            .profile-map__container
              app-loading(v-if="map.loading")
              yandex-map(
                :settings="v.map.default"
                :class="map.class"
                :options="map.options"
                :coords="map.center"
                :scroll-zoom="false"
                :zoom="map.zoom"
                :controls="map.controls"
                :behaviors="map.behaviors"
                @map-was-initialized="initializedMap"
              )
            // devices
            .profile-map__devices.mt-5
              ul.mb-4.text-size--small
                li.mb-1
                  span На карте отображены перемещения устройств:&ensp;
                  span.me-2.text-family--medium(v-for="device in selectedDevices" :key="device.serial" :style="{'color': device.color}") {{ device.serial }}
                li.mb-3
                  span Дата:&ensp;{{ selectedDate ? selectedDate : defaultDate }}
                li.text-family--italic *Для выбора нужной даты или устройства воспользуйтесь фильтром

              swiper(
                class=['profile-map__devices-slider', 'js-map-devices-slider']
                ref="slider"
                :options="slider.swiperOptions"
                :auto-update="true"
                :auto-destroy="false"
                @ready="initializedSlider"
              )
                swiper-slide(v-for="device in map.devices.slice(0, pagination.offset)" :key="device.serial")
                  app-map-device-card(
                    ref="device"
                    :data="device"
                    :current-day="currentDay"
                    :trigger="slider.ready"
                    @changeDate="changeDate"
                  )
                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-map-devices-slider-pagination'])

    // filter
    app-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import variables from '@/helpers/variables';
import {range, getRandomColor, uniqueArray, convertSVGtoBase64, sortBy} from '@/helpers/utils';

import {yandexMap} from 'vue-yandex-maps';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Navigation, Pagination} from 'swiper';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppSelect from '@/components/common/Select';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppMapDeviceCard from '@/components/profile/common/map/MapDeviceCard';

SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'AppMapRoutes',
  components: {
    AppSelect,
    AppMapDeviceCard,
    AppBackButton,
    AppTitle,
    AppLoading,
    AppErrorMessage,
    yandexMap,
    Swiper,
    SwiperSlide,
  },
  data() {
    const $self = this;

    return {
      v: variables,
      settings: {
        patients: {
          negative: true,
          payload: [],
        },
        users: {
          negative: false,
          payload: [],
        },
      },
      filterOptions: {
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },
      pagination: {
        limit: 5,
        offset: 5,
      },
      map: {
        ymaps: null,
        instance: null,
        class: 'map-router',
        options: {
          autoFitToViewport: 'always',
        },
        loading: true,
        center: [45.033333, 38.983333],
        zoom: 10,
        controls: ['zoomControl'],
        behaviors: ['default', 'dblClickZoom', 'scrollZoom', 'multiTouch'],
        marker: {
          layout: 'default#imageWithContent',
          imageHref:
            'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAyOCAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDE0LjcwNTlDMjggMjUuMjk0MSAxNCAzNSAxNCAzNUMxNCAzNSAwIDI1LjI5NDEgMCAxNC43MDU5QzAgNi41ODQwNSA2LjI2ODAxIDAgMTQgMEMyMS43MzIgMCAyOCA2LjU4NDA1IDI4IDE0LjcwNTlaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXIpIi8+CjxjaXJjbGUgY3g9IjE0LjAwMDIiIGN5PSIxNCIgcj0iNy44NCIgZmlsbD0id2hpdGUiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9IjE0IiB5MT0iLTIzLjQ4NjgiIHgyPSIzMi4xMzIiIHkyPSItMjEuMzEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0VDOUQyNSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNFQzg1MjUiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K',
          imageSize: [28, 35],
          imageOffset: [-14, -35],
          content: '',
          contentOffset: [6, 6],
          contentStyle:
            'background-color: #ffffff; font-size: 0.75rem; white-space: nowrap; padding: 3px 8px; border-radius: 16px; box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);',
        },
        devices: [],
        duration: 400,
      },
      slider: {
        ready: false,
        swiperOptions: {
          slidesPerView: 1,
          spaceBetween: 10,
          nested: true,
          pagination: {
            el: '.js-map-devices-slider-pagination',
            clickable: true,
            clickableClass: 'is-clickable',
            dynamicBullets: true,
          },
          observer: true,
          observeParents: true,
          breakpoints: {
            640: {
              slidesPerView: 2,
            },
            1400: {
              slidesPerView: 'auto',
            },
          },
          on: {
            sliderMove(swiper) {
              if (swiper.isEnd && $self.pagination.offset < $self.map.devices.length)
                $self.pagination.offset += $self.pagination.limit;
            },
          },
        },
      },
      defaultDate: '',
      selectedDate: '',
      selectedDevices: [],
      colors: [],
    };
  },
  computed: {
    ...mapState({
      examsLoading: (state) => state.exams.isLoading,
      exams: (state) => state.exams.data,
      examsCount: (state) => state.exams.count,
      examsError: (state) => state.exams.error,
    }),
    currentDay() {
      return this.selectedDate ? this.selectedDate : this.defaultDate;
    },
  },
  created() {
    this.getExams(this.filters, 'firstLoad');
  },
  mounted() {
    if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
  },
  methods: {
    initializedMap(instance) {
      this.map.loading = false;
      this.map.instance = instance;
      // eslint-disable-next-line no-undef
      ymaps.ready(() => {
        // eslint-disable-next-line no-undef
        this.map.ymaps = ymaps;
        this.createRoutes();
      });
    },
    initializedSlider() {
      this.slider.ready = true;
    },
    changeDate(date) {
      this.selectedDate = date;
      this.updateRoutes();
    },
    updateRoutes() {
      this.clearRoutes();

      this.$nextTick(() => {
        this.createRoutes();
      });
    },
    clearRoutes() {
      this.map.instance.geoObjects.removeAll();
    },
    createRoutes() {
      let routes = [];
      this.selectedDevices = [];

      if (this.map.devices.length) {
        this.defaultDate = this.map.devices[0].days[0].date;

        this.map.devices.forEach((device) => {
          let days = [];

          device.days.forEach((day) => {
            const coords = day.exams.map((el) => [el.lat, el.long]);

            days.push({
              date: day.date,
              coords: uniqueArray(coords),
            });
          });

          routes.push({
            serial: device.serial,
            color: device.color,
            days,
          });
        });

        routes.forEach((route) => {
          route.days.forEach((day) => {
            if (day.date === this.currentDay) {
              this.selectedDevices.push({
                serial: route.serial,
                color: route.color,
              });

              const iconImageHref = convertSVGtoBase64(
                `<svg width="18" height="23" fill="${route.color}" viewBox="0 0 18 23" xmlns="http://www.w3.org/2000/svg"><path d="M18 9.45378C18 16.2605 9 22.5 9 22.5C9 22.5 0 16.2605 0 9.45378C0 4.2326 4.02944 0 9 0C13.9706 0 18 4.2326 18 9.45378Z"/><circle cx="9.00094" cy="8.99996" r="5.04" fill="white"/></svg>`
              );

              if (day.coords.length > 1) {
                const multiRouteModel = new this.map.ymaps.multiRouter.MultiRouteModel(day.coords, {
                  results: 1,
                  viaIndexes: range(1, day.coords.length - 2),
                });

                const multiRouteView = new this.map.ymaps.multiRouter.MultiRoute(multiRouteModel, {
                  pinIconFillColor: route.color,
                  pinActiveIconFillColor: route.color,

                  wayPointStartVisible: false,

                  wayPointFinishIconLayout: this.map.marker.layout,
                  wayPointFinishIconImageHref: this.map.marker.imageHref,
                  wayPointFinishIconImageSize: this.map.marker.imageSize,
                  wayPointFinishIconImageOffset: this.map.marker.imageOffset,

                  viaPointIconRadius: 6,
                  viaPointIconFillColor: route.color,
                  viaPointActiveIconFillColor: route.color,

                  routeStrokeWidth: 2,
                  routeStrokeStyle: 'shortdash',
                  routeStrokeColor: route.color,
                  routeActiveStrokeWidth: 2,
                  routeActiveStrokeColor: route.color,
                  boundsAutoApply: true,

                  balloonLayout: this.map.ymaps.templateLayoutFactory.createClass(''),
                  balloonPanelMaxMapArea: 0,
                });

                multiRouteView.model.events.add('requestsuccess', () => {
                  multiRouteView
                    .getWayPoints()
                    .get(1)
                    .options.set({
                      iconImageHref,
                      iconContentOffset: this.map.marker.contentOffset,
                      iconContentLayout: this.map.ymaps.templateLayoutFactory.createClass(
                        `<span style="${this.map.marker.contentStyle}"> Устройство ${route.serial} </span>`
                      ),
                    });
                });

                multiRouteView.model.events.add('requestfail', (event) => {
                  console.error(
                    `Device serial ${route.serial}. Route FAIL`,
                    event.get('error').message
                  );
                });

                this.map.instance.geoObjects.add(multiRouteView);
              } else if (day.coords.length === 1) {
                const placemark = new this.map.ymaps.Placemark(
                  day.coords[0],
                  {},
                  {
                    iconLayout: this.map.marker.layout,
                    iconImageHref,
                    iconImageSize: this.map.marker.imageSize,
                    iconImageOffset: this.map.marker.imageOffset,
                    iconContent: this.map.marker.content,
                    iconContentOffset: this.map.marker.contentOffset,
                    iconContentLayout: this.map.ymaps.templateLayoutFactory.createClass(
                      `<span style="${this.map.marker.contentStyle}"> Устройство ${route.serial} </span>`
                    ),
                  }
                );

                this.map.instance.geoObjects.add(placemark);
                this.map.instance.panTo(day.coords[0], {
                  delay: 100,
                  duration: this.map.duration,
                });
              }
            }
          });
        });
      }
    },
    getExams(filters, slug) {
      /** TODO V1 to V2 */
      let data = {
        filters: Object.assign({}, this.settings, filters),
      };

      this.$store.dispatch(examsActionTypes.getExams, data).then((response) => {
        if (this.isTouchDevice) this.closeFilter('submit');

        this.sortingExams(
          response.result.filter((el) => el.lat && el.long),
          slug
        );

        if (slug !== 'firstLoad') this.updateRoutes();
        this.isFiltered = Object.keys(filters).length > 0;
      });
    },
    getCountExams(filters) {
      /** TODO V1 to V2 */
      let data = {
        filters: Object.assign({}, this.settings, filters),
      };

      this.$store.dispatch(examsActionTypes.getCountExams, data);
    },
    setColors(devices) {
      devices.forEach((device) => {
        this.colors.push({
          serial: device.serial,
          color: getRandomColor(),
        });
      });
    },
    sortingExams(allExams, slug) {
      this.map.devices = [];

      if (allExams.length) {
        Array.from(new Set(allExams.map((el) => el.device_serial))).forEach((serial) => {
          let days = [];
          let currentExams = allExams.filter((el) => el.device_serial === serial);

          Array.from(new Set(currentExams.map((el) => el.exam_date))).forEach((date) => {
            days.push({
              date,
              exams: sortBy(
                currentExams.filter((el) => el.exam_date === date),
                'exam_time'
              ),
            });
          });

          this.map.devices.push({
            serial,
            days,
          });
        });

        if (slug === 'firstLoad') this.setColors(this.map.devices);
        this.map.devices.forEach(
          (device) =>
            (device.color = this.colors.filter((el) => el.serial === device.serial)[0].color)
        );
      }

      return this.map.devices;
    }
  },
};
</script>

<style lang="scss">
.profile-map {
  &__container {
    .map-router [class*='-ground-pane'] {
      filter: grayscale(100%);
    }
    .map-router [class*='-routerRoutes-pane'] {
      opacity: 1 !important;
      pointer-events: none !important;
    }
    .map-router [class*='-routerPoints-pane'] {
      pointer-events: none !important;
    }
  }
  &__devices {
    & > .swiper-container {
      overflow: visible;

      .swiper {
        &-slide {
          width: $spacer * 37;
          height: auto;
        }
      }
    }
  }
}
</style>
