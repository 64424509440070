<template>
  <fragment>
    <div class="row justify-content-center g-4" >
      <div class="col-14">
        <div class="text-center">
          <app-title :type="'large'" :classes="'mb-3'">{{ title }}</app-title>
          <p class="text-color--blue text-size--small">{{ subtitle }}</p>
        </div>
      </div>
      <div class="col-14 col-xl-12 col-xxl-10">
        <app-validation-errors v-if="errors || authError" :classes="'mb-3'" :validation-errors="errors || authError"></app-validation-errors>
        <form @submit.prevent="onSubmit" :class="['form', {'is-submitting': isSubmitting}]">
          <div class="row flex-column align-items-center g-4">
            <div class="col">
              <label class="form__label">
                <input :class="['form__field', { 'is-error': v$.email.$errors.length }]" type="email" v-model="v$.email.$model" placeholder="E-mail">
              </label>
              <ul class="form__error">
                <li v-for="error of v$.email.$errors" :key="error.$uid">
                  <template v-if="error.$validator === 'required'">{{ v.errors.required }}</template>
                  <template v-if="error.$validator === 'email'">{{ v.errors.email }}</template>
                </li>
              </ul>
            </div>
            <div class="col">
              <label class="form__label">
                <input :class="['form__field', { 'is-error': v$.password.$errors.length }]" :type="showPass ? 'text' : 'password'" v-model="v$.password.$model" placeholder="Пароль">
                <span class="form__icon cursor-pointer" @click="showPass = !showPass">
                  <so-icon v-if="showPass" :icon="'eye-open'"></so-icon>
                  <so-icon v-else icon="eye-close"></so-icon>
                </span>
              </label>
              <ul class="form__error">
                <li v-for="error of v$.password.$errors" :key="error.$uid">
                  <template v-if="error.$validator === 'required'">{{ v.errors.required }}</template>
                </li>
              </ul>
            </div>
            <div class="col mt-5">
              <button type="submit" :disabled="v$.$invalid" class="button button--accent form__button">
                <span class="button__content">Войти</span>
              </button>
            </div>
            <div class="col mt-3">
              <so-button-esia @on-click="loginEsia"></so-button-esia>
            </div>
            <!-- <div class="col-auto">
              <router-link :to="{name: 'restore'}" class="text-center text-color--blue-dark text-decoration--underline text-size--small">Забыли пароль?</router-link>
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </fragment>

</template>

<script>
import authApi from '@/api/auth';
import {mapState} from 'vuex';
import {actionTypes as authActionTypes} from '@/store/modules/auth';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import useVuelidate from '@vuelidate/core';
import {required, email} from '@vuelidate/validators';

import queryString from 'query-string';
import variables from '@/helpers/variables';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import { Fragment } from 'vue-fragment';
import SoButton from '@/components/so/button/SoButton.vue';
import SoButtonEsia from '@/components/so/button/SoButtonEsia.vue';

export default {
  name: 'AppLogin',
  components: {
    AppValidationErrors,
    AppTitle,
    SoButton,
    Fragment,
    SoButtonEsia,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      email: '',
      password: '',
      showPass: false,
      errors: '',
      title: 'Вход в личный кабинет',
      subtitle: 'Для входа в личный кабинет введите Ваш e-mail и пароль',
    };
  },
  validations() {
    return {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
      validationErrors: (state) => state.auth.validationErrors,
      error: (state) => state.requests.error,
    }),
    approveCode() {
      return queryString.parseUrl(this.$route.fullPath).query.code || null;
    },
    authError() {
      return this.$route.query.authError ? this.$route.query.authError : '';
    },
  },
  beforeMount() {
    if (this.approveCode) this.approveEmail();
  },
  methods: {
    async onSubmit() {
      if (!this.v$.$invalid) {
        this.$store
          .dispatch(authActionTypes.login, {
            email: this.email,
            password: this.password,
          })
          .then(() => this.$router.push({name: 'dashboard'}))
          .catch(error => {
            if (JSON.parse(error.request.response).detail === 'Неверный e-mail или пароль') {
              this.errors = 'user_or_pass_incorrect'
            }
          });
      }
    },
    approveEmail() {
      if (this.approveCode) {
        this.$store
          .dispatch(requestsActionTypes.approveRequest, {
            code: this.approveCode,
            body: {},
          })
          .then(() => {
            this.title = 'Войдите с помощью нового адреса';
            this.subtitle =
              'Вы подтвердили смену e-mail на портале, авторизуйтесь с помощью новых данных';
          })
          .catch(() => {
            this.errors = this.error;
          });
      }
    },
    async loginEsia() {
      console.log('esia: clicked')
      try {
        console.log('esia: send request')
        let result = await authApi.loginEsia();
        console.log('esia: got result', result)
        let redirect_url = result.data.redirect_url;
        window.location.href = redirect_url;
      } catch (error) {
        this.$toast.error('Произошла ошибка при переадресации на Госуслуги', error);
      }
    },
  },
};
</script>
