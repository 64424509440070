
<div class="profile-doctors">
  <div class="profile-doctors__header">
    <div class="row align-items-center">
      <div class="col-14 col-md-7">
        <div class="d-flex align-items-center"></div>
      </div>
      <div class="col-14 col-md-7 d-none d-md-block">
        <div class="d-flex justify-content-end">
          <button type="button" class="button button--border-blue" @click="isModalVisible.addPdn=true">
            <span class="button__content">
              <span class="d-flex flex-shrink-0 me-3">
                <so-icon icon="plus-thin"></so-icon>
              </span>
              <span>Добавить пдн</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="profile-doctors__content position-relative mt-25">
    <transition name="translucent">
      <div>
        <app-loading v-if="isLoading" key="loading"></app-loading>
        <app-table v-if="pdns.result.length" :columns="columns" :sortKey="sortKey" :data="pdns.result" :paginationData="pagination" :currentPage="currentPage" @change-current-page="setCurrentPage" :baseUrl="baseUrl" :canEdit="false" :canRemove="false">
          <template v-slot:cell="{ column, row }">
            <span v-if='column.key === "creation_time"'>{{$moment(row[column.key]).format('DD.MM.YYYY')}} {{$moment(row[column.key]).format('HH:mm:ss')}}</span>
            <span v-else-if='column.key === "link"'>
              <button v-if="row.correctLink" class="profile-card__button--document link--accent text-overflow" type="button" @click.prevent="download(row)">
                <span v-if="row.correctLink" class="text-color--blue text-color-md-blue-dark incomingAction">скачать</span>
                <span v-else>&#8212;</span>
              </button>
            </span>
          </template>
        </app-table>
        <div v-else>
          <div class="text-color--blue text-size--small">Отстраненные работники не найдены</div>
        </div>
      </div>
    </transition>
  </div>
  <app-modal v-if="isModalVisible.addPdn" @close="closeModal('addPdn')">
    <template v-slot:body>
      <div class="row justify-content-center">
        <div class="col-14 col-md-10">
          <div class="text-center">
            <app-title :type="'large'">Редактирование ПДН</app-title>
            <div class="mt-3">
              <p>После загрузки нового соглашения, пользователям придет уведомление о подписании нового соглашения</p>
            </div>
          </div>
          <div class="mt-5">
            <form @submit.prevent :class="['form', {'is-submitting': isLoading}]">
              <div class="row flex-column align-items-center">
                <div class="col-14 col-md-13">
                  <div class="form__file d-flex align-items-center" :class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }">
                    <input class="d-none" type="file" name="document" @change="onFileSelected" @click="v$.$touch()" ref="fileInput" accept="application/pdf">
                    <div v-if="form.file" class="form__file-element d-flex align-items-center text-size--small">
                      <span class="flex-shrink-0 me-3">
                        <so-icon icon="document"></so-icon>
                      </span>
                      <ul class="flex-grow-1 overflow-hidden">
                        <li class="form__file-name text-color--black">{{ form.file.name }}</li>
                        <li class="mt-1">{{ Number(form.file.size/1000000).toFixed(2) }} mb</li>
                      </ul>
                      <button type="button" @click="clearFileInput" class="form__file-delete flex-shrink-0 ms-3">
                        <so-icon icon="trash"></so-icon>
                      </button>
                    </div>
                    <span class="text-color--blue text-size--small" v-else>Приложите документ .pdf</span>
                    <button class="form__file-button d-flex justify-content-end align-items-center" @click="$refs.fileInput.click()">
                      <so-icon icon="clip"></so-icon>
                    </button>
                  </div>
                  <div class="col">
                    <button type="submit" :disabled="v$.form.$invalid" class="button mt-5 button--accent form__button" @click="onSubmitPdnForm">
                      <span class="button__content">Добавить</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</div>
