
<div class="profile-doctors">
  <div class="profile-doctors__header">
    <div class="col-14 d-none d-md-block">
      <div class="d-flex download-risks-groups">
        <div class="col-2 date-picker-margins">
          <date-picker
            prefix-class="app"
            placeholder="от"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="startDate"
          ></date-picker>
        </div>
        <div class="col-2 date-picker-margins">
          <date-picker
            prefix-class="app"
            placeholder="до"
            format="DD.MM.YYYY"
            lang="ru"
            :editable="true"
            v-model="endDate"
          ></date-picker>
        </div>
        <div class="col-3 date-picker-margins" v-if="currentUser.role==='ADMIN'">
          <input
            :class="['form__field']"
            ref="multipeSelectUsers"
            v-model="examNumber"
          >
        </div>
        <div class="col-3">
          <button
            type="button"
            @click="downloadExamStats"
            class="button button--border-blue"
          >
            <span class="button__content">Сформировать отчет</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
