<template>
  <tr class="profile-table-row">
    <slot>
      <td
        v-for="column in columns"
        :key="column.key"
        class="profile-table-cell"
      >
        <slot name="cell" v-bind="{ column, row }">
          <span>{{ row[column.key] }}</span>
        </slot>
      </td>
      <td>
        <div class="action-buttons">
          <slot name="actionTools" v-bind="{ row }"></slot>
          <button
            v-if="canEdit"
            type="button"
            @click.prevent="editModalHandler(row)"
            class="action-buttons__button--edit"
          >
            <so-icon icon="edit"></so-icon>
          </button>
          <button
            v-if="canRemove"
            type="button"
            @click.prevent="deleteModalHandler(row)"
            class="action-buttons__button--delete"
          >
            <so-icon icon="trash"></so-icon>
          </button>
        </div>
      </td>
    </slot>
  </tr>
</template>

<script>

export default {
  name: 'AppTableRow',
  components: {
    
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    editModalHandler(data) {
      this.$emit('edit-modal', data);
    },
    deleteModalHandler(data) {
      this.$emit('delete-modal', data);
    },
    emitOpenCertificate(data) {
      this.$emit('open-certificate', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-table-row {
  display: table-row;
  position: relative;
  background-color: get($colors, white);
  @include font-size(get($text-sizes, small));
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}
.profile-table-row:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-table-cell {
  display: table-cell;
  padding: 1rem;
  min-width: 0;
  word-break: break-all;
  max-width: 100px;
}
.action-buttons {
  display: flex;
  justify-content: space-evenly;
  &__button {
    @include transition();
    width: $spacer * 3.8;
    height: $spacer * 3.8;
    border-radius: $border-radius-sm;
    color: get($colors, blue-dark);
    border: $border-width solid get($colors, blue-dark);
    .icon {
      width: 50px;
      height: 50px;
    }

    svg {
      width: $spacer * 1.8;
      height: $spacer * 2;
    }
    &--edit {
      @include interaction("mouse") {
        &:hover {
          border-color: get($colors, turq-hover);
          color: get($colors, turq-hover);
        }
      }
    }
    &--delete {
      @include interaction("mouse") {
        &:hover {
          border-color: get($colors, danger);
          color: get($colors, danger);
        }
      }
    }
  }
}
</style>
