<template>
<div class="profile-doctors">
  <div class="profile-doctors__header">
    <div class="row align-items-center">
      <div class="col-14 col-md-7">
        <div class="d-flex align-items-center"></div>
      </div>
    </div>
  </div>
  <div class="profile-doctors__content position-relative mt-25">
    <transition name="translucent">
      <div>
        <app-loading v-if="isLoading"></app-loading>
        <app-table v-if="userAgreements.length" :columns="columns" :sortKey="sortKey" :data="userAgreements" :paginationData="pagination" :currentPage="currentPage" @change-current-page="setCurrentPage" :baseUrl="baseUrl" :canEdit="false" :canRemove="false">
          <template v-slot:cell='{ column, row }'>
            <span v-if='column.key === "last_name"'>{{ getFullName(row) }}</span>
            <span v-else-if='column.key === "signed_time"'>{{$moment(row[column.key]).format('DD.MM.YYYY')}}  {{$moment(row[column.key]).format('HH:mm:ss')}}</span>
            <span v-else-if='column.key === "link"'>
              <button v-if="row.link" class="profile-card__button--document link--accent text-overflow" type="button" @click.prevent="download(row)">
                <span v-if="row.link" class="text-color--blue text-color-md-blue-dark incomingAction">скачать</span>
                <span v-else>&#8212;</span>
              </button>
            </span>
          </template>
        </app-table>
      </div>
    </transition>
  </div>
</div>
</template>

<!-- Проблема пагинаации в том, что не передаются page - нужно перевести на v2 -->

<script>
import userAgreements from '@/api/pdns';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppTable from '@/components/common/table/Table';
import { toLocalDate, toLocalTime, downloadDocs } from '@/helpers/utils';

export default {
  name: 'PdnUsers',
  components: {
    AppTitle,

    AppLoading,
    AppTable,
  },
  data() {
    return {
      isLoading: false,
      sortKey: 'signed_time',
      userAgreements: [],
      columns: [
        {
          caption: 'Дата',
          key: 'signed_time',
          inverse: false,
        },
        {
          caption: 'Пользователь',
          key: 'last_name',
          inverse: false,
        },
        {
          caption: '№ соглашения',
          key: 'agreement_id',
          inverse: false,
        },
        {
          caption: 'Ссылка',
          key: 'link',
          inverse: false,
        },
      ],

      pagination: {
        limit: 10,
        total: 0,
      },
    };
  },
  computed: {
    baseUrl() {
      return this.$route.path;
    },
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
  },
  watch: {
    async currentPage() {
      this.getUserAgreements();
    },
  },
  created() {
    this.getUserAgreements();
  },
  methods: {
    async getUserAgreements() {
      this.isLoading = true;
      let data = await userAgreements.userAgreementsToUsers();
      this.userAgreements = data.data.user_agreements_by_users;
      this.pagination.total = data.data.user_agreements_by_users.length;
      this.pagination.limit = 10;
      this.isLoading = false;
    },
    //pagination
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getUserAgreements();
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    // common
    toLocalDate(date) {
      return toLocalDate(date);
    },
    toLocalTime(time) {
      return toLocalTime(time);
    },
    getFullName(data) {
      return `${data.last_name} ${data.first_name} ${data.middle_name}`;
    },
    download(data) {
      // необходимо обновление ссылки с бэка
      downloadDocs(data, ['link'])
    },
  },
};
</script>
