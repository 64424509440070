<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14
        .profile-patients__content.mt-20.position-relative
          transition-(name="translucent")
            div
              app-loading(v-if="isLoading")
              app-error-message(v-if="error" :message="error")
              app-table(
                v-if="outgoingMedDocs.length"
                :columns="columns"
                :sortKey="sortKey"
                :data="outgoingMedDocs"
                :paginationData="pagination"
                :currentPage="currentPage"
                @change-current-page="setCurrentPage"
                @sortingRow="sortingMedDocs"
                :baseUrl="baseUrl"
                :canEdit="false"
                :canRemove="false"
              )
                template(v-slot:cell='{ column, row }')
                  span(v-if='column.key === "created_at"') {{ toLocalDateExam(row[column.key]) }}  {{ toLocalTimeExam(row[column.key]) }}
                  span(v-else-if='column.key === "doc_type"') {{ getMedDocType(row[column.key]) }}

                template(v-slot:actionTools='{ row }')
                  button(
                    type="button"
                    @click.prevent="download(row)"
                  )
                    img(src="@/assets/img/notice.svg")

              div(v-else-if="!isLoading")
                .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-outgoing(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
</template>

<script>
import { mapState } from 'vuex';
import outgoingMedDocs from '@/api/outgoingMedDocs';

import variables from '@/helpers/variables';
import useVuelidate from '@vuelidate/core';

import AppFilterPageOutgoing from '@/components/FilterPageOutgoing';
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppSelect from '@/components/common/Select';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import NavNotices from '@/components/profile/common/NavNotices';
import AppTable from '@/components/common/table/Table';

import { toLocalDateExam, toLocalTimeExam, downloadDocs, blobToFile } from '@/helpers/utils';
import { galleryMixin } from '@/mixins/gallery';

export default {
  name: 'AppPageReferences',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppLoading,
    AppErrorMessage,
    AppPatientChangeAvatarModal,
    NavNotices,
    AppFilterPageOutgoing,
    AppTable,
  },
  mixins: [galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      activeIndex: 0,
      showButton: true,
      isLoading: false,
      outgoingMedDocs: {},
      doc_type: 'all',
      filterString: '',
      resultFilterForLoad: '',
      isFilterOpen: false,
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getIncomingMedDocs'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 15,
        total: 0,
      },
      sortKey: 'exams_data_id',
      sortInverse: false,
      columns: [
        {
          caption: '№',
          key: 'exams_data_id',
          inverse: true,
        },
        {
          caption: 'Дата и время',
          key: 'created_at',
          inverse: true,
        },
        {
          caption: 'Тип документа',
          key: 'doc_type',
          inverse: true,
        },
        {
          caption: 'ФИО медработника',
          key: 'doctor_full_name',
          inverse: true,
        },
        {
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          caption: 'Предварительный диагноз',
          key: 'pre_diagnosis',
          inverse: false,
        },
      ],
      isModalVisible: {
        changeAvatar: false,
        deleteAvatar: false,
        addPdn: false,
      },
      successMessage: 'Успех!',
      filter: {},
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getIncomingMedDocs();
    },
  },
  created() {
    this.getIncomingMedDocs();
  },
  methods: {
    async getIncomingMedDocs() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      resultString = `doc_type=${this.doc_type}&page=${
        this.currentPage - 1
      }&per_page=10${this.filterString}&reports_order_by=${order_by}`;

      this.isLoading = true;
      let res = await outgoingMedDocs.getOutgoingMedDocuments(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.outgoingMedDocs = res.data.items;
      this.isLoading = false;

      for (let i of this.outgoingMedDocs) {
        if (i.doc_type !== 'alco') {
          this.showButton = false;
        } else {
          this.showButton = true;
        }
      }
    },
    async submitFilterDoc(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getIncomingMedDocs();
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },

    exportExamsExls() {
      let filterStringNoSmbl = this.filterString.substring(1);
      if (this.filterString.length) {
        this.resultFilterForLoad =
          `${filterStringNoSmbl}` + `&reports_order_by=-${this.sortKey}`;
      } else {
        this.resultFilterForLoad = `reports_order_by=-${this.sortKey}`;
      }
      outgoingMedDocs
        .exportOutcomingMeddocs(this.resultFilterForLoad, {})
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'))
        .catch(function (error) {
          if (error.request.status == '303') {
            console.log(error);
          }
        });
    },
    // download docs
    download(data) {
      downloadDocs(data, ['med_file', 'protocol_file'])
    },
    // sorting
    sortingMedDocs(sortKey, inverse) {
      if (inverse === false) {
        return;
      }
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getIncomingMedDocs();
    },
    //pagination
    setCurrentPage(newPage) {
      this.$router.push({ query: { page: newPage.toString() } });
      this.getIncomingMedDocs();
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    // formatting
    toLocalDateExam(date) {
      return toLocalDateExam(date);
    },
    toLocalTimeExam(date) {
      return toLocalTimeExam(date);
    },
    getMedDocType(type) {
      return type === 'med' ? 'Справка' : 'Направление на МО';
    },
    //tabs
    changeActiveTab(index) {
      this.activeIndex = index;
    }
  },
};
</script>
