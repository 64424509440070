<template lang="pug">
  .profile-dashboard__content
    .mb-5(v-if="notifications && notifications.length")
      transition-group(name="fade" tag="div")
        app-notification(
          v-for="(notification, index) in notifications" :key="notification.id"
          :data="notification"
          @delete="deleteNotification(notification.id, index)"
        )

    // device requests
    //- .position-relative.mb-5
      app-loading(v-if="requestsLoading")
      app-error-message(v-if="requestsError" :message="requestsError")

      transition(name="translucent")
        app-dashboard-card(
          :goTo="'requests'"
          :classes="`${requestsPending.devices.length === 0 ? 'is-empty' : ''}`"
          v-if="requests"
        )
          template(v-slot:title)
            .mb-4(v-if="requestsPending.devices.length") Последние заявки на&nbsp;регистрацию устройств&nbsp;(+{{ requestsPending.devices.length }})
            .text-color--gray-light(v-else) Здесь будут отображаться последние заявки на регистрацию устройств

          template(v-slot:body)
            template(v-if="requestsPending.devices.length")
              ul.d-none.d-xl-block
                li.mb-1(v-for="(request, index) in requestsPending.devices.slice(0, requestsPending.limit)" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

              // slider for mobile
              .profile-modile-slider.d-xl-none
                swiper(
                  ref="requestsDevicesSlider"
                  :options="Object.assign({}, slider.common, {pagination: {el: '.js-requests-devices-pagination', dynamicBullets: true}})"
                  :auto-update="true"
                  :auto-destroy="false"
                )
                  swiper-slide(v-for="(request, index) in requestsPending.devices.slice(0, 4)" :key="index")
                    app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

                  div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-requests-devices-pagination'])


            .text-size--small.text-color--gray-light(v-else) Пока нет заявок

          template(v-slot:button)
            template(v-if="requestsPending.users.length") Все заявки


    // user requests
    //- .position-relative.mb-5
      app-loading(v-if="requestsLoading")
      app-error-message(v-if="requestsError" :message="requestsError")

      transition(name="translucent")
        app-dashboard-card(
          v-if="requests"
          :goTo="'requests'"
          :classes="`${requestsPending.users.length === 0 ? 'is-empty' : ''}`"
        )
          template(v-slot:title)
            .mb-4(v-if="requestsPending.users.length") Последние заявки на&nbsp;регистрацию клиентов&nbsp;(+{{ requestsPending.users.length }})
            .text-color--gray-light(v-else) Здесь будут отображаться последние заявки на регистрацию клиентов

          template(v-slot:body)
            template(v-if="requestsPending.users.length")
              ul.d-none.d-xl-block
                li.mb-1(v-for="(request, index) in requestsPending.users.slice(0, requestsPending.limit)" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

              // slider for mobile
              .profile-modile-slider.d-xl-none
                swiper(
                  ref="requestsUsersSlider"
                  :options="Object.assign({}, slider.common, {pagination: {el: '.js-requests-users-pagination', dynamicBullets: true}})"
                  :auto-update="true"
                  :auto-destroy="false"
                )
                  swiper-slide(v-for="(request, index) in requestsPending.users.slice(0, 4)" :key="index")
                    app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

                  div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-requests-users-pagination'])


            .text-size--small.text-color--gray-light(v-else) Пока нет заявок

          template(v-slot:button)
            template(v-if="requestsPending.users.length") Все заявки



    // exams
    .position-relative.mb-5
      app-loading(v-if="examsLoading")
      app-error-message(v-if="examsError" :message="examsError")

      transition(name="translucent")
        app-dashboard-card(
          v-if="exams"
          :goTo="'exams'"
          :classes="`${exams && exams.length === 0 ? 'is-empty' : ''}`"
        )
          template(v-slot:title)
            .d-flex.align-items-center.mb-4(v-if="exams && exams.length")
              span.flex-shrink-0.text-color--blue.me-3.d-xl-none
                so-icon(icon="smartwatch" view-box="0 0 17 24")
              span Последние данные со&nbsp;всех устройств

            .text-color--gray-light(v-else) Здесь будут отображаться последние данные со всех устройств

          template(v-slot:body)
            template(v-if="exams && exams.length")
              .profile-table.d-none.d-xl-block
                .profile-table__header
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        v-if="column.caption || column.icon"
                        type="button"
                        @click="sortingExams(column.key)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            so-icon(:icon="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            so-icon(icon="down-arrow")

                ul.profile-table__body(id="dashboard-exams")
                  li.profile-table__row(v-for="exam in exams.slice(0, 3)" :key="exam.exams_id")
                    app-exam-card(
                      :data="exam"
                      :parent-id="'dashboard-exams'"
                      :columns="columns"
                      @openModal="openExamDetailModal"
                      @openCertificate="openCertificate"
                    )

              // slider for mobile
              .profile-modile-slider.d-xl-none
                swiper(
                  ref="examsSlider"
                  :options="Object.assign({}, slider.common, slider.exams)"
                  :auto-update="true"
                  :auto-destroy="false"
                )
                  swiper-slide(v-for="exam in exams.slice(0, 10)" :key="exam.exams_id")
                    app-exam-card-mobile(:data="exam" @openModal="openExamDetailMobile")

                  div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])

            .text-size--small.text-color--gray-light(v-else) Пока нет осмотров

          template(v-slot:button)
            template(v-if="exams && exams.length") Все осмотры


    // modals
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
    //Удаление
    transition(name="fade")
      app-modal(v-if="isModalVisible.reject" @close="closeModal('reject')")
        template(v-slot:body)
          .row.justify-content-center
            .col-14.col-md-10
              .text-center
                app-title(:type="'large'") Отказать в регистрации
                .mt-3.text-uppercase
                  ul(v-if="currentRequest.type_code === v.requests.types.device")
                    li(v-for="num in currentRequest.additional_params.device_serials") Серийный номер МИ {{ num }}
                  p(v-if="currentRequest.type_code === v.requests.types.user") {{ currentRequest.user_full_name }}

              .mt-5
                app-validation-errors(
                  v-if="requestsError"
                  :classes="'mb-3'"
                  :validation-errors="requestsError"
                )
                form(@submit.prevent="onSubmitRejectForm" :class="['form', {'is-submitting': requestsLoading}]")
                  .row.flex-column.align-items-center
                    .col.mb-5
                      label.form__label
                        textarea(
                          :class="['form__field', { 'is-error': v$.form.message.$errors.length }]"
                          v-model="v$.form.message.$model"
                          placeholder="Причина отказа"
                        )
                    .col
                      button(
                        type="submit"
                        :disabled="v$.$invalid"
                        class=['button', 'button--accent', 'form__button']
                      )
                        .button__content Отправить

    //Подтвержение
    transition(name="fade")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')" :classes="'d-flex flex-column justify-content-center'")
        template(v-slot:body)
          .text-center
            app-title(:type="'large'") {{ successMessage }}
            p.text-size--small.text-color--blue.mt-3 {{ currentRequest.user_full_name }} будет уведомлен о принятом решении
    app-confirm-check-modal(
      :is-visible="isModalVisible.confirm"
      :currentRequest="currentRequest"
      @openModal="openExamDetailModal"
      @close="closeModal('confirm')"
    )

</template>

<script>
import examsApi from '@/api/exams';

import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as notificationsActionTypes} from '@/store/modules/notifications';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import variables from '@/helpers/variables';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Pagination} from 'swiper';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppNotification from '@/components/common/Notification';
import AppValidationErrors from '@/components/common/ValidationErrors';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppDashboardCard from '@/components/profile/common/dashboard/DashboardCard';
import AppAdminRequestCard from '@/components/profile/admin/requests/AdminRequestCard';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

import AppConfirmCheckModal from '@/components/profile/admin/devices/AppConfirmCheckModal';

import {actionTypes} from '@/store/modules/requests';
SwiperCore.use([Pagination]);

export default {
  name: 'AppAdminDashboard',
  components: {
    AppTitle,

    AppModal,
    AppGallery,
    AppNotification,
    AppLoading,
    AppErrorMessage,
    AppValidationErrors,
    AppCertificateModal,
    AppDashboardCard,
    AppAdminRequestCard,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    Swiper,
    SwiperSlide,
    AppConfirmCheckModal,
  },
  mixins: [galleryMixin, examDetailModalMixin, examDetailMobileMixin, certificateModalMixin],
  data() {
    return {
      exams: {},
      v: variables,
      isModalVisible: {
        reject: false,
        success: false,
        confirm: false,
      },
      successMessage: '',
      currentRequest: {},
      fullName: '',
      form: {
        message: '',
      },
      sortKey: 'exam_number',
      sortInverse: false,
      columns: [
        {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата и время',
          key: 'exam_datetime',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Тип осмотра',
          key: 'exam_type_name',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-2 d-flex justify-content-end flex-row-reverse',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: true,
        },
      ],
      slider: {
        common: {
          slidesPerView: 1,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2,
            },
          },
        },
        exams: {
          pagination: {
            el: '.js-exams-pagination',
            dynamicBullets: true,
            renderBullet: function (index, className) {
              const slide = this.slides[index];
              const status = slide.querySelector('[data-status]');

              return `<span data-status="${status.dataset.status}" class="${className}"></span>`;
            },
          },
        },
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        message: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      notifications: (state) => state.notifications.data,

      requestsLoading: (state) => state.requests.isLoading,
      requests: (state) => state.requests.data,
      requestsError: (state) => state.requests.error,

      examsLoading: (state) => state.exams.isLoading,
      // exams: (state) => state.exams.data,
      examsError: (state) => state.exams.error,
    }),
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: [],
          },
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
    requestsPending() {
      if (!this.requests) return {limit: 2, devices: [], users: []};
      else
        return {
          limit: 2,
          devices: this.requests
            .filter(
              (el) =>
                el.status_code === this.v.requests.statuses.pending &&
                el.type_code === this.v.requests.types.device
            )
            .sort((a, b) => b.creation_date.localeCompare(a.creation_date)),

          users: this.requests
            .filter(
              (el) =>
                el.status_code === this.v.requests.statuses.pending &&
                el.type_code === this.v.requests.types.user
            )
            .sort((a, b) => b.creation_date.localeCompare(a.creation_date)),
        };
    },
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
  },
  watch: {
    currentPage() {
      this.getExams();
    },
  },
  async created() {
    this.getNotifications();
    this.getExams();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({query: Object.assign({}, this.$route.query, {page: 1})});
    },
    getRequests() {
      this.$store.dispatch(requestsActionTypes.getRequests);
    },
    getNotifications() {
      this.$store.dispatch(notificationsActionTypes.getNotifications);
    },
    deleteNotification(id, index) {
      this.$store
        .dispatch(notificationsActionTypes.deleteNotification, {id})
        .then(() => this.notifications.splice(index, 1));
    },
    async getExams() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.sortKey}`;

      let res = await examsApi.getExamsForAdminPanel(this.currentPage - 1, order_by);
      this.exams = res.data.items;
    },
    sortingExams(sortKey) {
      // if (sortKey === 'admission_to_work') {
      //   return;
      // }
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getExams();
    },
    openRejectModal(data) {
      this.currentRequest = data;
      this.isModalVisible.reject = true;
    },
    closeModal(slug) {
      if (slug === 'reject') {
        this.v$.$reset();
        this.form.message = '';
      }
      this.isModalVisible[slug] = false;
      this.getRequests();
    },
    successCallback(slug) {
      this.closeModal(slug);
      this.successMessage = `Заявка успешно ${slug === 'reject' ? 'отклонена' : 'одобрена'}`;
      this.isModalVisible.success = true;
      this.getRequests();
    },
    onSubmitRejectForm() {
      if (!this.v$.$invalid) {
        if (this.currentRequest.type_id === 1) {
          this.$store
            .dispatch(requestsActionTypes.changeStatusRegistration, {
              id: this.currentRequest.id,
              body: {
                reason: this.form.message,
                status_code: this.v.requests.statuses.rejected,
              },
            })
            .then(() => this.successCallback('reject'));
        } else {
          this.$store
            .dispatch(requestsActionTypes.changeStatusDevice, {
              id: this.currentRequest.id,
              body: {
                reason: this.form.message,
                status_code: this.v.requests.statuses.rejected,
              },
            })
            .then(() => this.successCallback('reject'));
        }
      }
    },
    approveRequest(data) {
      // это передать в модалку
      this.currentRequest = data;
      if (this.currentRequest.type_code != 'device') {
        if (this.currentRequest.type_id === 1) {
          this.$store
            .dispatch(actionTypes.changeStatusRegistration, {
              id: this.currentRequest.id,
              body: {
                reason: '',
                status_code: this.v.requests.statuses.approved,
              },
            })
            .then(() => this.successCallback('approve'));
        }
      } else {
        this.isModalVisible.confirm = true;
      }
      // if (this.currentRequest.type_id === 1) {
      //   this.$store
      //     .dispatch(requestsActionTypes.changeStatusRegistration, {
      //       id: this.currentRequest.id,
      //       body: {
      //         reason: '',
      //         status_code: this.v.requests.statuses.approved,
      //       },
      //     })
      //     .then(() => this.successCallback('approve'));
      // } else {
      //   this.$store
      //     .dispatch(requestsActionTypes.changeStatusDevice, {
      //       id: this.currentRequest.id,
      //       body: {
      //         reason: '',
      //         status_code: this.v.requests.statuses.approved,
      //       },
      //     })
      //     .then(() => this.successCallback('approve'));
      // }
    },
  },
};
</script>
