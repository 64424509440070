<template>
  <div class="so-table__container">
    <table class="SO-table">
      <thead>
        <tr class='flex'>
          <th
            v-for="column in columns"
            :key="column.key"
            :style="{ flex: column.flex || 1 }"
          >
            <button
              v-if="column.caption || column.icon"
              type="button"
              @click="handleSorting(column.key, column.inverse)"
              :class="[
                'SO-table__sort-button',
                { 'is-active': sortKey === column.key },
              ]"
            >
              <span
                v-if="column.caption"
              >{{ column.caption }}<span class="me-4"></span>
              </span>
              <so-icon
                v-else
                :icon="column.icon"
                class="me-4"
              ></so-icon>
              <so-icon icon="down-arrow"></so-icon>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <so-table-row
          v-for="row in data"
          :key="row.id"
          :row="row"
          :columns="columns"
          :canEdit="canEdit"
          :canRemove="canRemove"
          @edit-modal="handleEditModal"
          @delete-modal="handleDeleteModal"
        >
          <template v-slot="{ row }">
            <slot v-bind="{ row }"></slot>
          </template>

          <template v-slot:actionTools="{ row, column }">
            <slot name="actionTools" v-bind="{ row, column }"></slot>
          </template>

          <template v-slot:cell="{ column, row }">
            <slot name="cell" v-bind="{ column, row }"></slot>
          </template>
        </so-table-row>
      </tbody>
    </table>

    <div class="SO-pagination__container">
      <so-pagination
        v-if="paginationData.total"
        :total="paginationData.total"
        :limit="paginationData.limit"
        :current-page="currentPage"
        @change-current-page="handleCurrentPageChange"
      ></so-pagination>
    </div>

    <slot name="count"></slot>
  </div>
</template>

<script>
import SoPagination from './SoPagination.vue';
import SoTableRow from './SoTableRow.vue';

export default {
  name: 'SoTable',
  components: {
    SoPagination,
    SoTableRow,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    paginationData: {
      type: Object,
      default: () => ({})
    },
    sortKey: String,
    canEdit: Boolean,
    canRemove: Boolean,
  },
  methods: {
    handleSorting(key, inverse) {
      this.$emit('sortingRow', key, inverse);
    },
    handleEditModal(data) {
      this.$emit('edit-modal', data);
    },
    handleDeleteModal(data) {
      this.$emit('delete-modal', data);
    },
    handleCurrentPageChange(newPage) {
      this.$emit('change-current-page', newPage);
    }
  },
};
</script>

<style lang="scss" scoped>
.SO-table__container {
  width: 100%;
  height: 100%;
  .icon {
    &__down-arrow {
      width: $spacer;
      height: $spacer;

      @include mediaToDown($xl) {
        width: $spacer * 1.2;
        height: $spacer * 1.2;
      }
    }
  }
}
.SO-pagination__container {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
}
.SO-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
  border-radius: 8px;
  overflow: hidden;
  &__sort-button {
    @include transition();
    text-align: left;
    max-width: 100%;

    &.is-active {
      color: get($colors, turq-hover);
    }
    @include interaction("mouse") {
      &:hover {
        color: get($colors, turq-hover);
      }
    }
  }
}

thead {
  position: relative;
  z-index: 1;
  font-size: 0.75rem;
  background-color: #fff;
  color: #c5cbe1;
  box-shadow: 0 6px 20px #031c431f;
  border-radius: 6px 6px 0 0;
  th {
    padding: 1rem;
  }
}
</style>
