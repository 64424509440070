<template>
<div class="client-med-docs__container">
  <app-title :type="'large'">Медицинская документация</app-title>
  <so-tabs v-model="activeIndex" :needTitle="false" @changeTab="changeActiveTab" :selectedTab="activeIndex">
    <so-tab label="Входящая" name="client-incoming-med-docs">
      <page-references />
    </so-tab>
    <so-tab label="Исходящая" name="client-outgoing-med-docs">
      <page-notices />
    </so-tab>
  </so-tabs>
</div>
</template>

<script>
import SoTab from '@/components/so/tabs/SoTab';
import SoTabs from '@/components/so/tabs/SoTabs';
import AppTitle from '@/components/common/Title';
import PageNotices from '@/components/PageNotices';
import PageReferences from '@/components/PageReferences';

export default {
  name: 'med-docs-references',
  components: {
    SoTab,
    SoTabs,
    AppTitle,
    PageNotices,
    PageReferences
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    changeActiveTab(index) {
      this.activeIndex = index;
    }
  },
};
</script>
