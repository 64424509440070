import axios from 'axios';

export default {
    state: {
        firmware: [],
    },
    mutations: {
        firmware(state, payload) {
            state.firmware = payload.firmware;
        },
    },

    actions: {
      async actionNewFirmware(_, data) {
        try {
          const resw = await axios({
            url: '/api/v2/devices_integrity_controls/',
            method: 'POST',
            data: data,
          });
          return resw;
        } catch (error) {
          return error.response.data.detail
        }
      },
      async actionEditFirmware(_, { id, data }) {
          try {
              var resw = await axios({
                  url: `/api/v2/devices_integrity_controls/${id}`,
                  method: 'PUT',
                  data: data,
              });
              return resw;
          } catch (error) {
            return error.response.data.detail
          }
      },
      async actionDeleteFirmware(_, data) {
            try {
                var resw = await axios({
                    url: `/api/v2/devices_integrity_controls/${data}`,
                    method: 'DELETE',
                });
            } catch (error) {
              console.error('Ошибка во время actionDeleteFirmware:', error);
            }
            return resw;
        },
        async actionUploadPdn(_, data) {
            try {
                var resw = await axios({
                    url: '/api/v1/user_agreements/create',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
            }
            return resw;
        },
    },

    getters: {
        heartRate(state) {
            return state.chart.result.map((item) => item.heart_rate);
        },


    },
};
