
section(
  class=['doctor-card', 'profile-card']
  :class="classes"
)
  .row.align-items-center.g-4
    div(v-for="column in columns" :key="column.key" :class="column.class")
      template(v-if="column.key === 'medical_license_start' || column.key === 'medical_license_end'")
        span {{ toLocalDate(data[column.key]) || '—' }}

      template(v-else)
        span(v-if="data[column.key]") {{ data[column.key] }}
        span.text-color--blue(v-else) &#8212;
