import axios from '@/api/axios';

// V1

const deletePatientAvatar = (credentials) => axios.post('/api/v1/patient/avatar/delete', credentials);
const deletePatientSignature = (credentials) => axios.post('/api/v1/patient/signature/delete', credentials);
const changePatient = (credentials) => axios.post('/api/v1/patient/edit', credentials);
const deletePatientRFID = (id) => axios.post(`/api/v1/patient/rfid/delete/${id}`);
const deletePatient = (id) => axios.delete(`/api/v1/patient/${id}`);
const unlockPatient = (credentials) => axios.post('/api/v1/patient/unlocking', credentials);
const getPatientLockStatus = (code) => axios.get(`/api/v1/patient/lock_status/${code}`);
const getPatients = (credentials) => axios.post('/api/v1/patient', credentials);
// const getCountPatients = (credentials) => axios.post('/api/v1/patient/count', credentials);
// const importPatients = (credentials) => axios.post('/api/v1/patient/import_csv', credentials);

// V2

// patients
const getPatientsShort = (credentials) => axios.get(`/api/v2/patient/short_list${credentials}&activate=true`);
const getPatientsShortList = (credentials) => axios.get(`/api/v2/patient/short_list`, { params: credentials });
const createPatients = (credentials) => axios.post('/api/v2/patient/create', credentials);
const getPatientDetail = (code) => axios.get(`/api/v2/patient/detail/${code}`);
const getPatientBorders = (code) => axios.get(`/api/v2/personal_borders/${code}`);
const blockHistory = (patient_id) => axios.get(`/api/v2/patient_blocking/?code=${patient_id}&page=0&per_page=10`);
const updatePersonalBorders = (patient_code, credentials) => axios.post(`/api/v2/personal_borders/${patient_code}`, credentials);
const getPatientsSuggestionList = (urlParams) => axios.get('/api/v2/patient/suggestion', {params: urlParams});
// const getPatientsByUser = (user_id) => axios.get(`/api/v2/users/${user_id}/patients`);

// patient exam
const getPatientExams = (code) => axios.get(`/api/v2/exams/${code}`);
const getPatientAvgExamsResult = (code) => axios.get(`/api/v2/patient/avg_exams_result/${code}`);
const docsForExam = (patient_code, document_group, page) => axios.get(`/api/v2/med_doc/docs_for_exam/${patient_code}?document_group=${document_group}&page=${page}&per_page=10`);

// avatar
const getAllPatientAvatars = (patient_code) => axios.get(`/api/v2/avatar/patient/${patient_code}`);
const setPatientAvatar = (code, credentials) => axios.post(`/api/v2/avatar/patient/${code}`, credentials);
const updatePatientAvatar = (code, credentials) => axios.put(`/api/v2/patient/edit/${code}/`, credentials);
const archivePatientAvatar = (code, credentials) => axios.delete(`/api/v2/patient/avatar/${code}`, credentials);

// signature & comments
const setPatientSignature = (patient_code, credentials) => axios.post(`/api/v2/patient/signature/${patient_code}`, credentials);
const createPatientComment = (credentials) => axios.post('/api/v2/patient/comment', credentials);
const getPatientComment = (patient_code) => axios.get(`/api/v2/patient/comment?patient_code=${patient_code}&page=0&limit=50`);

// mass create
const getTemplateForLoadPatients = () => axios.get('/api/v2/patient/template');

const massCreatePatients = (credentials, user_id) => {
  let url = '/api/v2/patient/template/mass_create';
  if (user_id) {
    url += `?user_id=${user_id}`;
  }
  return axios.post(url, credentials);
};


export default {
  deletePatientAvatar,
  deletePatientSignature,
  changePatient,
  deletePatientRFID,
  deletePatient,
  unlockPatient,
  getPatientLockStatus,
  getPatients,
  // getCountPatients,
  // importPatients,
  getPatientsShort,
  getPatientsShortList,
  createPatients,
  getPatientDetail,
  getPatientBorders,
  blockHistory,
  updatePersonalBorders,
  getPatientsSuggestionList,
  // getPatientsByUser,
  getPatientExams,
  getPatientAvgExamsResult,
  docsForExam,
  getAllPatientAvatars,
  setPatientAvatar,
  updatePatientAvatar,
  archivePatientAvatar,
  setPatientSignature,
  createPatientComment,
  getPatientComment,
  getTemplateForLoadPatients,
  massCreatePatients,
};
