
section.request-card.d-flex(v-if='data')
  template(v-if="(data.status_code === v.requests.statuses.pending)")
    .row.flex-grow-1.g-4.align-items-center(:class="{'align-items-center': isUser || (devices && devices.length < 2)}")
      .col-14.col-xl-auto.col-xxl-1
        .d-flex.align-items-center
          span.flex-shrink-0.me-3.text-color--accent
            so-icon(icon="process")
          span {{ createDate }}
          
      .col-14.col-xl-2 
        .text-color--blue.text-color-xxl-gray-light.mb-2 Серийный номер МИ
        span {{ data.additional_params.device_serial }}
      .col-14.col-xl-2 
        .text-color--blue.text-color-xxl-gray-light.mb-2 Владелец
        span {{ data.user_full_name }}
      .col-xl-3
       .row
        .col-7.col-xl-7 
          .text-color--blue.text-color-xxl-gray-light.mb-2 Дата поверки
          span {{ attestation_start }}
        .col-7.col-xl-7 
          .text-color--blue.text-color-xxl-gray-light.mb-2 Действие до
          span {{ attestation_end }}
     
      .col-14.col-xl-2 
        .text-color--blue.text-color-xxl-gray-light.mb-2 Номер свидетельства
        span № 	{{ data.additional_params.device_attestation }}
      
      .col-14.col-xl-auto.ms-xl-auto
        .row.g-3.flex-xl-column.flex-sl-row
          .col-auto
            button(
              type="button"
              class=['button button--small button--white button--border-blue']
              @click="reject(data)"
            )
              .button__content.d-flex.align-items-center
                span.d-flex.me-3.flex-shrink-0
                  so-icon(icon="wrong")
                span Отказать
          .col-auto
            button(
              type="button"
              class=['button button--small button--accent']
              @click="approve(data)"
            )
              .button__content.d-flex.align-items-center
                span.d-flex.me-3.flex-shrink-0
                  so-icon(icon="allright")
                span Одобрить

  // rejected requests
  app-accordion(
    v-else
    :id="String(data.id)"
    :parent-id="'profile-requests'"
  )
    template(v-slot:header)
      .row.flex-column.flex-xl-row.gy-4.gy-xl-0(:class="{'align-items-center': isUser || (devices && devices.length < 2)}")
        .col-14.col-xl-auto
          .d-flex.align-items-center
            span.me-3.text-color--danger
              so-icon(icon="wrong")
            span {{ createDate }}
        .col
          span {{ data.user_full_name }}
        
        template(v-if="isUser")
          .col
            span(v-if="isPerson") Физ.лицо
            span(v-if="isCompany") Юр.лицо
          .col
            span {{ data.user_email }}
          .col
            span.text-color--blue ИНН&#32;
            span {{ data.user_inn }}
            
        template(v-if="isDevice")
          .col
            .d-flex
              span.text-color--blue.me-3 Устройства:
              ul
                li.mb-2(v-for="device in devices") {{ device }}
  
          .col(v-if="data.documents")
            //- button(
            //-   class=['request-card__document-button', 'link--accent']
            //-   type="button"
            //-   v-for="document in data.documents"
            //-   @click.prevent="getFile(document)"
            //- )
            //-   .d-flex.align-items-center
            //-     span.flex-shrink-0.me-3
            //-       so-icon(icon="document")
            //-     span Документ&nbsp;покупки
          
    template(v-slot:body)
      .row.justify-content-end
        .col-14.col-xxl-11
          .request-card__message
            p.text-color--danger Заявка на регистрацию отклонена {{ updateDate }}
            p.text-color--black.text-family--light {{ data.reason }}
    
