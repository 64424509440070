
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Создание группы печати
      form(@submit.prevent="onSubmit" :class="['form']")
        .row.flex-column.align-items-center.g-4
          .col-14.col-md-10
            .row.g-4.posRelative
              .col
                label.form__label
                  input(
                    v-model="v$.form.name.$model"
                    :class="['form__field']"
                    type="text"
                    name="name"
                    placeholder="Наименование группы"
                    autocomplete="off"
                    id="id"
                  )

          .col-14.col-md-10.mt-5
            button(
              type="submit"
              class=['button', 'button--accent', 'form__button']
              :disabled="v$.form.$invalid"
            )
              span.button__content Добавить

