<template>
  <so-tabs v-model="activeIndex" :needTitle="true" @changeTab="changeActiveTab" :selectedTab="activeIndex">
    <so-tab label="Пользовательские соглашения" name="pdn">
      <pdn />
    </so-tab>
    <so-tab label="ИДС" name="ids">
      <ids />
    </so-tab>
    <so-tab label="Подтверджения пользовательских соглашений" name="PdnUsers">
      <pdn-users />
    </so-tab>
  </so-tabs>
</template>


<script>
import SoTab from '@/components/so/tabs/SoTab';
import SoTabs from '@/components/so/tabs/SoTabs';

import Pdn from '@/components/profile/admin/pdns/Pdn';
import Ids from '@/components/profile/admin/pdns/Ids';
import PdnUsers from '@/components/profile/admin/pdns/PdnUsers';

export default {
  name: 'SystemSetupPdn',
  components: {
    SoTab,
    SoTabs,
    Pdn,
    Ids,
    PdnUsers
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    changeActiveTab(index) {
      this.activeIndex = index;
    }
  },
};
</script>
