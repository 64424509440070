
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" :classes="`modal-refuse`")
    template(v-slot:body)
      .row.justify-content-center.align-items-center
        .col-12
          .text-center.pt-4
            app-title(:type="'large'" ) {{ getTitle }}
            p.text-color--blue.mt-3.text-uppercase {{ data.patient_full_name }}
      .row.gx-5.justify-content-center.mb-4.mt-3
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="pulse")
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_high_alarm}') {{ data.bp_high }}/
            span(v-if="data.bp_high && data.bp_low" :class='{yellowText: data.bp_low_alarm}') {{ data.bp_low }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="cardio")
            span(v-if="data.heart_rate" :class='{yellowText: data.heart_rate_alarm}') {{ data.heart_rate }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="temp")
            span(v-if="data.body_temp" :class='{yellowText: data.body_temp_alarm}') {{ data.body_temp + "\u2103" }}
            span(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              so-icon(icon="breathalyzer")
            span(v-if="data.alcohol >= 0" :class='{yellowText: data.alcohol_alarm}') {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
            span(v-else) &#8212;

      .row.g-3
        .col-12
        .refuseCauseHeader.mt-2.mb-2.text-color--blue-dark Причина
        .col-12
          .row.g-2
            template(v-for='reason in reasons')
              template(v-if='reason.left || reason.right || reason.both')
                .col-auto(v-if='reason.both == null && reason.left && reason.right')
                  .refuseCause Травма: {{reason.title}}, слева, справа
                .col-auto(v-if='reason.both')
                  .refuseCause Травма: {{reason.title}}
                .col-auto(v-if='reason.left && !reason.right')
                  .refuseCause Травма: {{reason.title}}, слева
                .col-auto(v-if='reason.right && !reason.left')
                  .refuseCause Травма: {{reason.title}}, справа
              template(v-else)
                .col-auto(v-if='reason.title !== "Травма"')
                  .refuseCause {{reason.title}}
      .row
        .col-14
          textarea.refuseDesc(v-model="reasonText" placeholder="При необходимости укажите дополнительную причину отстранения")
      .d-flex.justify-content-center.mt-4
        button(
          @click="sendResultData('success')"
          type="button"
          class=['button button--accent w-100']
        )
          span.button__content Подтвердить
