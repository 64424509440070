<template lang="pug">
  .profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-patients__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center'"
          ) Пользователи

        .col-auto.ms-auto.d-none.d-xl-block
          button(
          type="button"
          class=['button button--border-blue']
          @click="openModal('createPatients')"
        )
            span.button__content
             span.d-flex.flex-shrink-0.me-3
              so-icon(icon="plus-thin")
             span Добавить пользователя

    .profile-patients__content.mt-20.position-relative
      transition(name="translucent")
        app-loading(v-if="isLoading")

        div(v-if="patientsLocal")
          template(v-if="patientsLocal  && patientsLocal.length")
            .profile-table.is-exams-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          so-icon(icon="down-arrow")


              ul.profile-table__body
                li.profile-table__row(v-for="patient in patientsLocal" :key="patient.code")
                  router-link(:to="{name: 'patient-detail', params: {id: patient.code}}" class=['d-block'])
                    app-admin-patient-card(
                      :data="patient"
                    )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Пользователи не найдены

    // create patients modal
    app-create-patients-modal(
      :is-visible="isModalVisible.createPatients"
      @open="openModal('createPatients')"
      @close="closeModal('createPatients')"
      @openGallery="openGallery"
      @success="getPatients()"
    )
    // filter
    admin-patients-filter(
      ref="filter"
      :is-open="isFilterOpen"
      @open="openFilter"
      @close="close"
      @clear="close"
      @submit="submitFilter"
    )
</template>

<script>
import variables from '@/helpers/variables';

import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import AppGallery from '@/components/common/Gallery';

import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';
import AdminPatientsFilter from '@/components/profile/admin/patients/AdminPatientsFilter';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import AppCreatePatientsModal from '@/components/profile/client/patients/CreatePatientsModal';
import {galleryMixin} from '@/mixins/gallery';
import useVuelidate from '@vuelidate/core';

import patientApi from '@/api/patients';

export default {
  name: 'AppAdminPatients',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppGallery,
    AppAdminPatientCard,
    AppCreatePatientsModal,
    AppPatientChangeAvatarModal,
    AdminPatientsFilter,
  },
  mixins: [galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isFilterOpen: false,
      filterString: '',
      form: {
        file: null,
      },
      v: variables,
      pagination: {
        limit: 10,
        total: 0,
      },
      sortKey: 'creation_date',
      sortInverse: false,
      patientsLocal: [],
      isLoading: false,
      columns: [
        {
          class: 'col-4',
          caption: 'ФИО Пользователя',
          key: 'first_name',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Компания',
          key: 'company_name',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата рождения',
          key: 'date_birth',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Телефон',
          key: 'phone',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Табельный номер',
          key: 'personal_number',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Кол-во осмотров',
          key: 'exam_count',
          inverse: false,
        },
      ],
      isModalVisible: {
        changeAvatar: false,
        deleteAvatar: false,
        createPatients: false,
      },
      successMessage: 'Успех!',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getPatients();
    },
  },
  created() {
    this.getPatients();
    if (this.currentUser.role === 'CLIENT') {
      this.columns = [
        {
          class: 'col-4',
          caption: 'ФИО пользователя',
          key: 'first_name',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата рождения',
          key: 'date_birth',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Телефон',
          key: 'phone',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Табельный номер',
          key: 'personal_number',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Кол-во осмотров',
          key: 'exam_count',
          inverse: true,
        },
      ];
    }
  },
  mounted() {
    if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getPatients() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultFilter = '';

      if (this.sortKey === 'company_name') {
        resultFilter =
          `?page=${this.currentPage - 1}&per_page=10${this.filterString}` + `&order_by=${order_by}`;
      } else {
        resultFilter =
          `?page=${this.currentPage - 1}&per_page=10${this.filterString}` +
          `&patient_order_by=${order_by}`;
      }

      patientApi.getPatientsShort(resultFilter).then((patients) => {
        this.patientsLocal = patients.data.items;
        this.pagination.total = patients.data.total;
        this.pagination.limit = patients.data.per_page;
      });
      this.isLoading = false;
    },
    sortingPatients(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getPatients();
      }
    },
    async submitFilter(data) {
      this.filterString = data;
      this.getPatients();
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
  },
};
</script>
