type CommonGetParams = {
  page: number,
  per_page: number
}

export class CoreModel {
  _get(params: CommonGetParams) {

  }
};
