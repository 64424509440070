<template lang="pug">
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      so-icon(icon="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        so-icon(icon="filter")

      transition(name="fade")
        button(
          type="button"
          v-if="isDirty && this.collectData.length"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            so-icon(icon="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        so-icon(icon="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3
              template
                .col
                    app-select(
                        ref="selectComplete"
                        :type="'form'"
                        :placeholder="'Завершенность осмотра'"
                        :options="examCompleteOpts"
                        @select="selectComplete"
                    )
                .col
                    app-select(
                        ref="selectAdmission"
                        :type="'form'"
                        :placeholder="'Заключение медработника'"
                        :options="admissionOpts"
                        @select="selectAdmission"
                    )
                .col
                  app-select(
                    ref="selectType"
                    :type="'form'"
                    :placeholder="'Тип осмотра'"
                    :options="typeOpts"
                    @select="selectType"
                  )

                .col
                  .form__caption.mb-3 ФИО пользователя:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="patient_full_name"
                    placeholder="ФИО пользователя"
                    autocomplete="off"
                    id="id"
                  )

                .col(v-if="$store.state.auth.userRole !== 'client'")
                  .form__caption.mb-3 Компания:
                  input(
                    :class="['form__field']"
                    type="text"
                    name="id"
                    v-model="company_name"
                    placeholder="Компания"
                    autocomplete="off"
                    id="id"
                  )
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Интервал даты регистрации:
                  .row.g-3
                    .col-7
                      date-picker(
                        v-model="form.date.dateFrom"
                        prefix-class="app"
                        placeholder="от"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableAfterToday"
                        @change="datepickerChange('dateFrom')"
                        @clear="datepickerClear('dateFrom')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")
                    .col-7
                      date-picker(
                        v-model="form.date.dateTo"
                        prefix-class="app"
                        placeholder="до"
                        format="DD-MM-YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="disableExpirationFilters"
                        @change="datepickerChange('dateTo')"
                        @clear="datepickerClear('dateTo')"
                      )
                        template(v-slot:icon-calendar)
                          so-icon(icon="calendar")
              template
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3
                    span Интервал значений&ensp;
                    app-tooltip(
                      :inline="true"
                      :withoutIcon="true"
                      :content="'Систолическое давление называют верхним. На данную величину оказывает влияние сосудистое сопротивление, а также частота и сила сердечных сокращений.'"
                    )
                      template(v-slot:body)
                        span.text-decoration--underline верхнего
                    span &ensp;давления:

                  .form__range.d-flex.align-items-end(:class="{'is-changed': bpHighOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="pulse")
                    .flex-grow-1
                      Slider(
                        v-model="bpHighOpts.value"
                        class="range"
                        :min="bpHighOpts.min"
                        :max="bpHighOpts.max"
                        @change="bpHighChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3
                    span Интервал значений&ensp;
                    app-tooltip(
                      :inline="true"
                      :withoutIcon="true"
                      :content="'В промежутке между сокращениями (фаза систолы), фиксируется величина диастолического или нижнего артериального давления. Данная величина зависит исключительно от сосудистого сопротивления.'"
                    )
                      template(v-slot:body)
                        span.text-decoration--underline нижнего
                    span &ensp;давления:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': bpLowOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="pulse")
                    .flex-grow-1
                      Slider(
                        v-model="bpLowOpts.value"
                        class="range"
                        :min="bpLowOpts.min"
                        :max="bpLowOpts.max"
                        @change="bpLowChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.mb-3 Интервал значений пульса:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': heartRateOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="cardio")
                    .flex-grow-1
                      Slider(
                        v-model="heartRateOpts.value"
                        class="range"
                        :min="heartRateOpts.min"
                        :max="heartRateOpts.max"
                        @change="heartRateChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.d-flex.mb-3 Интервал значений температуры тела,&nbsp;&#176;C:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': bodyTempOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="temp")
                    .flex-grow-1
                      Slider(
                        v-model="bodyTempOpts.value"
                        class="range"
                        :step="bodyTempOpts.step"
                        :format="bodyTempOpts.format"
                        :min="bodyTempOpts.min"
                        :max="bodyTempOpts.max"
                        @change="bodyTempChange"
                      )
              .row.flex-column.align-items-center.g-3.mt-3
                .col
                  .form__caption.d-flex.mb-3 Алкоголь:
                  .form__range.d-flex.align-items-end(:class="{'is-changed': alcoOpts.changed}")
                    span.form__range-icon.d-flex.me-3.flex-shrink-0
                      so-icon(icon="breathalyzer")
                    .flex-grow-1
                      Slider(
                        v-model="alcoOpts.value"
                        class="range"
                        :step="alcoOpts.step"
                        :format="alcoOpts.format"
                        :min="alcoOpts.min"
                        :max="alcoOpts.max"
                        @change="alcoChange"
                      )

      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || !this.collectData.length"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
        )
          span.button__content
            span Показать

</template>

<script>
import { mapState } from 'vuex';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppMultipleSelectFull from '@/components/common/MultipleSelectFull';
import AppSelect from '@/components/common/Select';
import AppTooltip from '@/components/common/Tooltip';

import is from 'is_js';
import simplebar from 'simplebar-vue';
import Slider from '@vueform/slider/dist/slider.vue2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import { disableAfterToday, disableExpirationFilters } from '@/helpers/datepicker';
import {toLocalDateExam} from '@/helpers/utils';

export default {
  name: 'AppFilterExams',
  components: {
    AppTooltip,
    AppSelect,
    AppMultipleSelect,

    AppValidationErrors,
    DatePicker,
    Slider,
    simplebar,
    AppMultipleSelectFull,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      style: {
        top: null,
      },
      delay: 2000,
      anchor: '#isAnchorForBtn',
      patient_full_name: '',
      company_name: '',
      form: {
        isDirty: {
          admission: false,
          complete: false,
          dateFrom: false,
          dateTo: false,
          bpHigh: false,
          bpLow: false,
          heartRate: false,
          bodyTemp: false,
          alco: false,
          patient_full_name: false,
          company_name: false,
          examType: false,
        },
        admission: null,
        complete: null,
        date: {
          dateFrom: null,
          dateTo: null,
        },
        bpHigh: {
          min: null,
          max: null,
        },
        bpLow: {
          min: null,
          max: null,
        },
        heartRate: {
          min: null,
          max: null,
        },
        bodyTemp: {
          min: null,
          max: null,
        },
        alco: {
          min: null,
          max: null,
        },
        examType: null,
      },
      admissionOpts: [
        {
          caption: 'Допущен',
          icon: 'allright',
          value: 'admitted',
          selected: false,
        },
        {
          caption: 'Не допущен',
          icon: 'allright',
          value: 'not_admitted',
          selected: false,
        },
        {
          caption: 'Есть заключение',
          icon: 'allright',
          value: 'considered',
          selected: false,
        },
        {
          caption: 'Нет заключения',
          icon: 'allright',
          value: 'not_considered',
          selected: false,
        },
      ],
      examCompleteOpts: [
        {
          caption: 'Завершен',
          icon: 'allright',
          value: 'complete',
          selected: false,
        },
        {
          caption: 'Не завершен/не доставлен',
          icon: 'allright',
          value: 'not_complete',
          selected: false,
        },
      ],
      bpHighOpts: {
        min: 0,
        max: 200,
        value: [0, 200],
        changed: false,
      },
      bpLowOpts: {
        min: 0,
        max: 200,
        value: [0, 200],
        changed: false,
      },
      heartRateOpts: {
        min: 0,
        max: 200,
        value: [0, 200],
        changed: false,
      },
      bodyTempOpts: {
        min: 0,
        max: 50,
        step: -1,
        format: {
          decimals: 1,
        },
        value: [0, 50],
        changed: false,
      },
      alcoOpts: {
        min: 0,
        max: 5,
        step: 0.01,
        value: [0, 5],
        format: {
          decimals: 2,
        },
        changed: false,
      },
      typeOpts: [
        {
          caption: 'Предрейсовый',
          icon: 'allright',
          value: 1,
          selected: false,
        },
        {
          caption: 'Послерейсовый',
          icon: 'allright',
          value: 2,
          selected: false,
        },
        {
          caption: 'Предсменный',
          icon: 'allright',
          value: 3,
          selected: false,
        },
        {
          caption: 'Послесменный',
          icon: 'allright',
          value: 4,
          selected: false,
        },
        {
          caption: 'Текущий',
          icon: 'allright',
          value: 5,
          selected: false,
        },
        {
          caption: 'Профилактический',
          icon: 'allright',
          value: 6,
          selected: false,
        },
      ],
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.filters.isLoading,
      filters: (state) => state.filters.data,
      error: (state) => state.filters.error,
    }),
    isDirty() {
      return Object.values(this.form.isDirty).some((item) => item === true);
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    collectData() {
      let data = '';
      //admission
      if (this.form.admission) {
        data += `&admission_to_work=${this.form.admission}`;
      }
      //complete
      if (this.form.complete) {
        data += `&exams_complete=${this.form.complete}`;
      }
      //datepicker
      if (this.form.date.dateFrom) {
        data += `&exam_datetime_gte=${this.$moment(this.form.date.dateFrom).format(
          'YYYY-MM-DD'
        )} 00:00:00`;
      }
      if (this.form.date.dateTo) {
        data += `&exam_datetime_lte=${this.$moment(this.form.date.dateTo).format(
          'YYYY-MM-DD'
        )} 23:59:59`;
      }
      //bpHigh
      if (this.form.bpHigh.min) {
        data += `&bp_high_gte=${this.form.bpHigh.min}`;
      }
      if (this.form.bpHigh.max) {
        data += `&bp_high_lte=${this.form.bpHigh.max}`;
      }
      //bplow
      if (this.form.bpLow.min) {
        data += `&bp_low_gte=${this.form.bpLow.min}`;
      }
      if (this.form.bpLow.max) {
        data += `&bp_low_lte=${this.form.bpLow.max}`;
      }
      //heartRate
      if (this.form.heartRate.min) {
        data += `&heart_rate_gte=${this.form.heartRate.min}`;
      }
      if (this.form.heartRate.max) {
        data += `&heart_rate_lte=${this.form.heartRate.max}`;
      }
      //bodyTemp
      if (this.form.bodyTemp.min) {
        data += `&body_temp_gte=${this.form.bodyTemp.min}`;
      }
      if (this.form.bodyTemp.max) {
        data += `&body_temp_lte=${this.form.bodyTemp.max}`;
      }
      //alco
      if (this.form.alco.min) {
        data += `&alcohol_gte=${this.form.alco.min}`;
      }
      if (this.form.alco.max) {
        data += `&alcohol_lte=${this.form.alco.max}`;
      }
      //patient_full_name
      if (this.patient_full_name !== '') {
        data += `&patient_full_name=${this.patient_full_name}`;
      }
      //company_name
      if (this.company_name !== '') {
        data += `&company_name=${this.company_name}`;
      }
      //type
      if (this.form.examType) {
        data += `&type_id=${this.form.examType}`;
      }
      return data;
    },
  },
  watch: {
    isDirty(value) {
      if (!value) this.$emit('clear');
    },
    admission: function () {
      this.form.isDirty.admission = true;
    },
    complete: function () {
      this.form.isDirty.complete = true;
    },
    bpHigh: function (value) {
      this.bpHighOpts.min = value;
      this.bpHighOpts.max = value;
      this.bpHighOpts.changed = true;
      this.form.isDirty.bpHigh = true;
    },
    bpLow: function (value) {
      this.bpLowOpts.min = value;
      this.bpLowOpts.max = value;
      this.bpLowOpts.changed = true;
      this.form.isDirty.bpLow = true;
    },
    heartRate: function (value) {
      this.heartRateOpts.min = value;
      this.heartRateOpts.max = value;
      this.heartRateOpts.changed = true;
      this.form.isDirty.heartRate = true;
    },
    bodyTemp: function (value) {
      this.bodyTempOpts.min = value;
      this.bodyTempOpts.max = value;
      this.bodyTempOpts.changed = true;
      this.form.isDirty.bodyTemp = true;
    },
    alco: function (value) {
      this.alcoOpts.min = value;
      this.alcoOpts.max = value;
      this.alcoOpts.changed = true;
      this.form.isDirty.alco = true;
    },
    patient_full_name: function () {
      this.form.isDirty.patient_full_name = true;
    },
    company_name: function () {
      this.form.isDirty.company_name = true;
      this.changeCompanyName();
    },
    examType: function () {
      this.form.isDirty.examType = true;
    },
  },
  methods: {
    //Admissions
    selectAdmission(option) {
      this.admissionOpts.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.admission = true;
      this.form.admission = option.value;
    },
    //Complete exams
    selectComplete(option) {
      this.examCompleteOpts.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.complete = true;
      this.form.complete = option.value;
    },
    //datepicker
    changeCompanyName() {
      this.$emit('getComanynameFilter', {
        companyName: this.company_name,
      });
    },
    datepickerChange(slug) {
      this.form.isDirty[slug] = true;
      this.collectData;
      slug === 'dateFrom'
        ? this.$emit('getDataFromFilter', {
          dateFromFilter: `${this.$moment(this.form.date.dateFrom).format(
            'YYYY-MM-DD'
          )} 00:00:00`,
        })
        : this.$emit('getDataToFilter', {
          dateToFilter: `${this.$moment(this.form.date.dateTo).format('YYYY-MM-DD')} 23:59:59`,
        });
    },
    datepickerClear(slug) {
      this.form.isDirty[slug] = false;
      this.form.isDirty[slug] = false;
      this.$emit('submit', this.collectData);

      if (slug === 'dateTo') {
        this.form.time.to = null;
        this.form.isDirty.timeTo = false;
      }
      if (slug === 'dateFrom') {
        this.form.time.from = null;
        this.form.isDirty.timeFrom = false;
      }
    },
    disableAfterToday(date) {
      return disableAfterToday(date, this.form.date.dateTo);
    },
    disableExpirationFilters(date) {
      return disableExpirationFilters(date, this.form.date.dateFrom);
    },
    //bpHigh
    bpHighChange(value) {
      if (value[0] !== this.bpHighOpts.min || value[1] !== this.bpHighOpts.max) {
        this.bpHighOpts.changed = true;
        this.form.bpHigh.min = value[0];
        this.form.bpHigh.max = value[1];
      } else {
        this.bpHighOpts.changed = false;
        this.form.bpHigh.min = null;
        this.form.bpHigh.max = null;
      }
      this.form.isDirty.bpHigh = this.bpHighOpts.changed;
    },
    //bpLow
    bpLowChange(value) {
      if (value[0] !== this.bpLowOpts.min || value[1] !== this.bpLowOpts.max) {
        this.bpLowOpts.changed = true;
        this.form.bpLow.min = value[0];
        this.form.bpLow.max = value[1];
      } else {
        this.bpLowOpts.changed = false;
        this.form.bpLow.min = null;
        this.form.bpLow.max = null;
      }
      this.form.isDirty.bpLow = this.bpLowOpts.changed;
    },
    //heartRate
    heartRateChange(value) {
      if (value[0] !== this.heartRateOpts.min || value[1] !== this.heartRateOpts.max) {
        this.heartRateOpts.changed = true;
        this.form.heartRate.min = value[0];
        this.form.heartRate.max = value[1];
      } else {
        this.heartRateOpts.changed = false;
        this.form.heartRate.min = null;
        this.form.heartRate.max = null;
      }
      this.form.isDirty.heartRate = this.heartRateOpts.changed;
    },
    //bodyTemp
    bodyTempChange(value) {
      if (value[0] !== this.bodyTempOpts.min || value[1] !== this.bodyTempOpts.max) {
        this.bodyTempOpts.changed = true;
        this.form.bodyTemp.min = value[0];
        this.form.bodyTemp.max = value[1];
      } else {
        this.bodyTempOpts.changed = false;
        this.form.bodyTemp.min = null;
        this.form.bodyTemp.max = null;
      }
      this.form.isDirty.bodyTemp = this.bodyTempOpts.changed;
    },
    //alco
    alcoChange(value) {
      if (value[0] !== this.alcoOpts.min || value[1] !== this.alcoOpts.max) {
        this.alcoOpts.changed = true;
        this.form.alco.min = value[0];
        this.form.alco.max = value[1];
      } else {
        this.alcoOpts.changed = false;
        this.form.alco.min = null;
        this.form.alco.max = null;
      }
      this.form.isDirty.alco = this.alcoOpts.changed;
    },
    //type
    selectType(option) {
      this.typeOpts.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.examType = true;
      this.form.examType = option.value;
    },
    setBtnPosition(anchor) {
      const el = document.querySelector(anchor || this.anchor);
      this.style.top = el ? `${el.getBoundingClientRect().y}px` : null;
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      if (this.isTouchDevice) this.submit();
    },
    async clear() {
      //admission
      this.form.isDirty.admission = false;
      this.form.admission = null;
      this.$refs.selectAdmission.clear();
      //complete
      this.form.isDirty.complete = false;
      this.form.complete = null;
      this.$refs.selectComplete.clear();
      //datepicker
      this.form.date.dateFrom = null;
      this.form.date.dateTo = null;
      this.form.isDirty.dateFrom = false;
      this.form.isDirty.dateTo = false;
      //bpHigh
      this.form.bpHigh.min = null;
      this.form.bpHigh.max = null;
      this.form.isDirty.bpHigh = false;
      this.bpHighOpts.changed = false;
      this.bpHighOpts.value = [this.bpHighOpts.min, this.bpHighOpts.max];
      //bpLow
      this.form.bpLow.min = null;
      this.form.bpLow.max = null;
      this.form.isDirty.bpLow = false;
      this.bpLowOpts.changed = false;
      this.bpLowOpts.value = [this.bpLowOpts.min, this.bpLowOpts.max];
      //heartRate
      this.form.heartRate.min = null;
      this.form.heartRate.max = null;
      this.form.isDirty.heartRate = false;
      this.heartRateOpts.changed = false;
      this.heartRateOpts.value = [this.heartRateOpts.min, this.heartRateOpts.max];
      //bodyTemp
      this.form.bodyTemp.min = null;
      this.form.bodyTemp.max = null;
      this.form.isDirty.bodyTemp = false;
      this.bodyTempOpts.changed = false;
      this.bodyTempOpts.value = [this.bodyTempOpts.min, this.bodyTempOpts.max];
      //alco
      this.form.alco.min = null;
      this.form.alco.max = null;
      this.form.isDirty.alco = false;
      this.alcoOpts.changed = false;
      this.alcoOpts.value = [this.alcoOpts.min, this.alcoOpts.max];
      //full name
      this.patient_full_name = '';
      this.form.isDirty.patient_full_name = false;
      //company_name
      this.company_name = '';
      this.form.isDirty.company_name = false;
      //examType
      this.form.isDirty.examType = false;
      this.form.examType = null;
      this.$refs.selectType.clear();
      this.$emit('submit', '');
    },
    //common
    toLocalDateExam(date) {
      if (date) {
        return toLocalDateExam(date);
      } else return false;
    },
    async submit() {
      if (is.falsy(this.isDirty) || !this.collectData.length) {
        this.$emit('submit', '');
      } else {
        this.$emit('submit', this.collectData);
      }
    },
  },
};
</script>

<style lang="scss">
$bg-color: #0c1120;

.profile-filter {
  pointer-events: none;
  position: fixed;
  z-index: get($index, xl);
  top: 0;
  right: 0;
  width: 16.66667%;
  height: 100%;
  padding-top: $header-height;
  @include transition(transform);
  transform: translateX(85%);

  &:before {
    @include pseudo-wrapper();
    background-color: rgba($bg-color, 0.8);
    backdrop-filter: blur(4px);
    @include transition();
    opacity: 0;
  }
  @include mediaToDown($xxl) {
    width: 25%;
  }
  @include mediaToDown($xl) {
    width: 50%;
    transform: translateX(100%);

    &.is-open {
      .profile-filter {
        &__container {
          box-shadow: get($shadows, default);
        }
      }
    }
  }
  @include mediaToDown($md) {
    padding-top: 0;
    width: 100%;

    &.is-open {
      padding-top: $spacer;
      padding-left: $spacer;
      padding-right: $spacer;

      &:before {
        opacity: 1;
        transition-delay: #{$transition-duration}s;
      }
    }
  }
  &.is-open {
    transform: translateX(0);

    .profile-filter {
      &__content {
        opacity: 1;
        visibility: visible;
      }
      &__clear-button,
      &__open-button-mobile {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__container {
    position: relative;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: $border-radius-sm 0 0 0;
    color: get($colors, blue);
    padding-top: $spacer * 1.3;
    @include transition();

    @include mediaToDown($xl) {
      box-shadow: none;
    }
  }
  &__header {
    width: 100%;
  }
  &__content {
    position: relative;
    @include transition();
    opacity: 0;
    visibility: hidden;
    height: 90%;
  }
  &__form {
    max-height: 90%;
    overflow-x: hidden;
    padding-bottom: $spacer;
  }
  &__submit-button {
    position: relative;
    width: 105%;
    left: -5%;
    padding: $spacer * 1.2 $spacer * 1.2 $spacer * 1.2 $spacer * 2.3;
    border: $border-width solid get($colors, blue-light);
    border-radius: $border-radius-lg 0 0 0;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);

    @include mediaToDown($xl) {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
    }
    @include mediaToDown($md) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__clear-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 15%;
    background: get($gradients, orange);
    color: get($colors, white);
    padding: $spacer 0;
    @include transition();

    @include interaction('mouse') {
      &:hover {
        box-shadow: get($shadows, default);
      }
    }
  }
  &__open-button,
  &__close-button {
    @include transition(color);

    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);
      }
    }
  }
  &__open-button-mobile {
    position: absolute;
    z-index: get($index, default);
    width: $spacer * 4.4;
    height: $spacer * 4.4;
    right: 100%;
    top: 0;
    background-color: get($colors, white);
    border-radius: $spacer * 3 $spacer * 1.6 0 $spacer * 3;
    padding: $spacer;
    box-shadow: get($shadows, default);
    transition: background-color #{$transition-duration}s, color #{$transition-duration}s;
    transform: translateY($spacer);

    &.is-dirty {
      background-color: get($colors, blue-dark);
      color: get($colors, white);
    }
  }
  .icon {
    &__filter {
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
  }
}
</style>
