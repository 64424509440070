
section(class=['patient-card'] :class="classes")
  app-accordion(
    :id="data.code"
    :parent-id="'patient-exams'"
    class=['profile-card', 'patient-card__accordion']
  )
    template(v-slot:header)
      .patient-card__header
        .row.align-items-center.gx-4
          .col-3
            .d-flex.align-items-center
              app-avatar(
                :src="data.avatar"
                :viewPhoto="true"
                :type="'patient'"
                @openGallery="openGallery"
                @change="changeAvatar"
              )
              .ms-3
                span.text-overflow(v-if="data.email" :title="data.email") {{ data.email }}
                span.text-color--blue(v-else) нет e-mail

          .col-2
            span(v-if="data.phone") {{ data.phone }}
            span.text-color--blue(v-else) нет телефона

          .col-2
            span {{ toLocalDate(data.creation_date) }}

          .col-3
            span.text-overflow(:title="data.first_name") {{ fullName }}

          .col-2
            .d-flex.align-items-center
              span(v-if="data.exams_count") {{ data.exams_count }} {{ declOfNum(data.exams_count, ['осмотр', 'осмотра', 'осмотров']) }}
              span.text-color--blue(v-else) нет осмотров

    template(v-slot:body)
      .patient-card__content.pb-4
        .patient-card__detail
          .row.align-items-center.gx-4
            .col-auto
              app-signature(
                :sample="data.signature"
                :theme="'shadow'"
                @openGallery="openGallery"
                @change="changeSignature"
              )
            .col
              .patient-card__detail-content
                .row.align-items-center
                  .col
                    .text-color--blue.mb-2 Водительское&nbsp;удостоверение
                    span(v-if="data.serial_number_driving_license") {{ data.serial_number_driving_license }} от {{ toLocalDate(data.driving_license_receiving) }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 СНИЛС
                    span(v-if="data.snils") {{data.snils}}
                    span.text-color--blue(v-else) &#8212;
                  .col
                    .text-color--blue.mb-2 Дата рождения
                    span(v-if="data.date_birth") {{ toLocalDate(data.date_birth) }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 Пол
                    span(v-if="data.gender") {{ data.gender == '1' ? 'Мужской' : 'Женский' }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 Табельный&nbsp;номер
                    span(v-if="data.personal_number") {{ data.personal_number }}
                    span.text-color--blue(v-else) &#8212;

                  .col
                    .text-color--blue.mb-2 Карта RFID
                    span(v-if="data.rfid_id") {{ data.rfid_id }}
                    span.text-color--blue(v-else) &#8212;

                  .col-auto
                    .d-flex.flex-column.align-items-end
                      button(
                        type="button"
                        @click.prevent="changePatient(data)"
                        class=['text-color--blue-dark', 'text-decoration--underline', 'link--accent', 'mb-2']
                      ) Изменить

                      button(
                        type="button"
                        @click.prevent="deletePatient(data)"
                        class=['text-color--danger', 'text-decoration--underline', 'link--accent']
                      ) Удалить

          ul.patient-exams__list(id="patient-exams")
            li.patient-exams__list-el(v-for="exam in data.exams" :key="exam.exams_id")
              app-patient-exam-card(
                :parent-id="'patient-exams'"
                :classes="'d-none d-xl-block'"
                :data="exam"
              )

