import doctorsApi from '@/api/doctors';
import {isValidSystemSetup} from 'crypto-pro';

const state = {
  data: null,

  exams: null,
  pendingExams: null,
  checkedExams: null,

  isValidSystemSetup: null,
  isLoading: false,
  isSubmitting: false,

  validationErrors: null,
  error: null,
  systemError: null,
};

export const mutationTypes = {
  checkValidSystemSetupStart: '[doctors] check valid system setup start',
  checkValidSystemSetupSuccess: '[doctors] check valid system setup success',
  checkValidSystemSetupFailure: '[doctors] check valid system setup failure',

  getDoctorsStart: '[doctors] get data start',
  getDoctorsSuccess: '[doctors] get data success',
  getDoctorsFailure: '[doctors] get data failure',

  createDoctorStart: '[doctors] create start',
  createDoctorSuccess: '[doctors] create success',
  createDoctorFailure: '[doctors] create failure',

  changeDoctorStart: '[doctors] change start',
  changeDoctorSuccess: '[doctors] change success',
  changeDoctorFailure: '[doctors] change failure',

  getDoctorPendingExamsStart: '[doctors] get pending exams start',
  getDoctorPendingExamsSuccess: '[doctors] get pending exams success',
  getDoctorPendingExamsFailure: '[doctors] get pending exams failure',

  getDoctorCheckedExamsStart: '[doctors] get checked exams start',
  getDoctorCheckedExamsSuccess: '[doctors] get checked exams success',
  getDoctorCheckedExamsFailure: '[doctors] get checked exams failure',

  getDoctorExamsAdminStart: '[doctors] get exams by admin start',
  getDoctorExamsAdminSuccess: '[doctors] get exams by admin success',
  getDoctorExamsAdminFailure: '[doctors] get exams by admin failure',

  setAdmissionStart: '[doctors] set admission to work start',
  setAdmissionSuccess: '[doctors] set admission to work success',
  setAdmissionFailure: '[doctors] set admission to work failure',

  deleteDoctorStart: '[doctors] delete start',
  deleteDoctorSuccess: '[doctors] delete success',
  deleteDoctorFailure: '[doctors] delete failure',
};

export const actionTypes = {
  getDoctors: '[doctors] get data',
  getDoctorPendingExams: '[doctors] get pending exams',
  getDoctorCheckedExams: '[doctors] get checked exams',
  getDoctorExamsAdmin: '[doctors] get exams by admin',
  createDoctor: '[doctors] create',
  changeDoctor: '[doctors] change',
  setAdmission: '[doctors] set admission to work',
  checkValidSystemSetup: '[doctors] check valid system setup',
  deleteDoctor: '[doctors] delete',
};

export const gettersTypes = {
  pendingExamsCount: '[doctors] pendingExamsCount',
};

const getters = {
  [gettersTypes.pendingExamsCount]: (state) => {
    if (state.pendingExams) {
      return state.pendingExams.total;
    }
  },
};

const mutations = {
  [mutationTypes.checkValidSystemSetupStart](state) {
    state.isLoading = true;
    state.isValidSystemSetup = null;
    state.systemError = null;
  },
  [mutationTypes.checkValidSystemSetupSuccess](state) {
    state.isValidSystemSetup = true;
    state.isLoading = false;
  },
  [mutationTypes.checkValidSystemSetupFailure](state, payload) {
    state.isValidSystemSetup = false;
    state.systemError = payload;
    state.isLoading = false;
  },

  [mutationTypes.getDoctorsStart](state) {
    state.isLoading = true;
    state.error = null;
    state.data = null;
  },
  [mutationTypes.getDoctorsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getDoctorsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.createDoctorStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createDoctorSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createDoctorFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeDoctorStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeDoctorSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeDoctorFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getDoctorPendingExamsStart](state) {
    state.isLoading = true;
    state.error = null;
    state.pendingExams = null;
  },
  [mutationTypes.getDoctorPendingExamsSuccess](state, payload) {
    state.isLoading = false;
    state.pendingExams = payload;
  },
  [mutationTypes.getDoctorPendingExamsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getDoctorCheckedExamsStart](state) {
    state.isLoading = true;
    state.error = null;
    state.checkedExams = null;
  },
  [mutationTypes.getDoctorCheckedExamsSuccess](state, payload) {
    state.isLoading = false;
    state.checkedExams = payload;
  },
  [mutationTypes.getDoctorCheckedExamsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getDoctorExamsAdminStart](state) {
    state.isLoading = true;
    state.error = null;
    state.exams = null;
  },
  [mutationTypes.getDoctorExamsAdminSuccess](state, payload) {
    state.isLoading = false;
    state.exams = payload;
  },
  [mutationTypes.getDoctorExamsAdminFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.setAdmissionStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setAdmissionSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setAdmissionFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteDoctorStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deleteDoctorSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteDoctorFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.checkValidSystemSetup](context) {
    context.commit(mutationTypes.checkValidSystemSetupStart);

    isValidSystemSetup()
      .then(() => {
        context.commit(mutationTypes.checkValidSystemSetupSuccess);
      })
      .catch((result) => {
        context.commit(mutationTypes.checkValidSystemSetupFailure, result);
      });
  },
  [actionTypes.getDoctors](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDoctorsStart);
      doctorsApi
        .getDoctors(credentials)
        .then((response) => {
          context.commit(mutationTypes.getDoctorsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDoctorsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.createDoctor](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createDoctorStart);
      doctorsApi
        .createDoctor(credentials)
        .then((response) => {
          context.commit(mutationTypes.createDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.changeDoctor](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDoctorStart);
      doctorsApi
        .changeDoctor(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getDoctorPendingExams](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDoctorPendingExamsStart);
      doctorsApi
        .getDoctorExams(
          Object.assign(
            {},
            {
              admission_to_work: false,
              exam_completed: true,
            },
            credentials
          )
        )
        .then((response) => {
          context.commit(mutationTypes.getDoctorPendingExamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDoctorPendingExamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getDoctorCheckedExams](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDoctorCheckedExamsStart);
      doctorsApi
        .getDoctorExams(
          Object.assign(
            {},
            {
              filter_params: {
                admission_to_work: true,
                exam_completed: true,
              },
            },
            credentials
          )
        )
        .then((response) => {
          context.commit(mutationTypes.getDoctorCheckedExamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDoctorCheckedExamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getDoctorExamsAdmin](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDoctorExamsAdminStart);
      doctorsApi
        .getDoctorExamsAdmin(credentials)
        .then((response) => {
          context.commit(mutationTypes.getDoctorExamsAdminSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDoctorExamsAdminFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.setAdmission](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setAdmissionStart);
      doctorsApi
        .setAdmission(params)
        .then((response) => {
          context.commit(mutationTypes.setAdmissionSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.setAdmissionFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.deleteDoctor](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteDoctorStart);
      doctorsApi
        .deleteDoctor(id)
        .then((response) => {
          context.commit(mutationTypes.deleteDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
