<template lang="pug">
  simplebar.home
    .container-fluid
      .row
        .col
          .d-flex.flex-column.align-items-center.flex-grow-1
          simplebar.auth
            .container-fluid
              .row.flex-column.align-items-center
                .col-14.col-xl-6.col-xxl-5
                  router-link(:to="{name: 'home'}")
                    app-logo
                  .auth__container
                    transition(name="fade" mode="out-in")
                      router-view
                      app-login

</template>

<script>
import simplebar from 'simplebar-vue';
import SvgIcon from '@/components/common/SvgIcon';
import AppLogo from '@/components/common/Logo';
import AppTooltip from '@/components/common/Tooltip';
import AppLogin from '@/components/auth/Login.vue';

export default {
  name: 'AppHome',
  components: {
    simplebar,
    AppTooltip,

    AppLogo,
    SvgIcon,
    AppLogin,
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  .logo {
    margin-bottom: $spacer * 5;
  }
  @include mediaToDown($md) {
    padding-top: $spacer * 4;
  }
}
</style>
