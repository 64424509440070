<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(
    v-if="isVisible"
    @close="closeModal"
    :classes="'certificate-modal'"
  )
    template(v-slot:body)
      .row.justify-content-center.g-4
        .col-14.col-xl-9
          .certificate-modal__content
            .certificate-modal__emblem.d-flex.align-items-center.justify-content-center
              so-icon(icon="eagle")
                template(v-slot:defs)
                  defs
                    linearGradient#gradient-eagle(x1="43.6627" y1="39.2409" x2="6.46999" y2="2.04815" gradientUnits="userSpaceOnUse")
                      stop(stop-color="#6C9EFF")
                      stop(offset="1" stop-color="#53E7BA")

            ul.text-center.text-size--small
              li.mb-4
                .text-color--blue.mb-2 Сертификат
                span {{ data.number_kep }}
              li.mb-4
                .text-color--blue.mb-2 Владелец
                span {{ data.owner_kep !== null ? data.owner_kep : data.doctor_full_name  }}
              li
                .text-color--blue.mb-2 Действителен
                span {{ data.validity_kep}}


          button(
            type="button"
            @click="getFiles(data)"
            class=['button button--accent w-100 mt-20']
          )
            span.button__content Скачать осмотр с УКЭП

</template>

<script>
import {mapState} from 'vuex';

import AppModal from '@/components/common/Modal';
import { downloadDocs } from '@/helpers/utils';

export default {
  name: 'AppCertificateModal',
  components: {
    AppModal,

  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  methods: {
    getFiles(data) {
      downloadDocs(data, ['data_file', 'sign_file'])
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.certificate-modal {
  $emblemSize: $spacer * 7.4;

  &__content {
    position: relative;
    margin-top: calc($emblemSize/2);
    padding: $spacer * 6 $spacer $spacer * 3 $spacer;
    background: get($gradients, blue-light);
    border-radius: $border-radius-lg * 1.5;
  }
  &__emblem {
    position: absolute;
    width: $emblemSize;
    height: $emblemSize;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: $border-radius-lg;
    background-color: get($colors, white);
    box-shadow: get($shadows, small), get($shadows, green-line);

    svg {
      fill: url(#gradient-eagle);
      width: 70%;
      height: 70%;
    }
  }
}
</style>
