
.profile-devices__inner.is-filterable
  .profile-devices__header.mb-4
    .row.align-items-center
      template
        .col-auto.ms-md-auto.order-1.order-md-0.ms-auto
          .d-flex.justify-content-end
            button(
              type="button"
              @click="addModal"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  so-icon(icon="plus-thin")
                span Добавить КЦ

  .profile-devices__content.mt-20.position-relative
    app-loading(v-if="isLoading")
    app-validation-errors(
      v-if="localValidationErrors"
      :validation-errors="localValidationErrors"
      :classes="'mb-3'"
    )
    transition(name="translucent")
      app-table(
        v-if="devices.length"
        :columns="columns"
        :sortKey="sortKey"
        @sortingRow="sortingDevices"
        :data="devices"
        :paginationData="pagination"
        :currentPage="currentPage"
        :baseUrl="baseUrl"
        :canEdit="true"
        :canRemove="true"
        @edit-modal="editModal"
        @delete-modal="deleteModal"
      )
        template(v-slot)


      div(v-else)
        .d-flex.align-items-center.text-color--blue.text-size--small
          span.d-flex.me-3
            so-icon(icon="wrong")
          span Нет устройств

  // delete modal
  app-delete-firmware-modal(
    :deleteFirmvareId="deleteFirmvareId"
    :is-visible="isModalVisible.deleteDevice"
    @close="closeModal('deleteDevice')"
    @success="closeModal('deleteDevice')"
    @firmwareEdited="getDevices"
  )
  // Edit modal
  app-edit-firmware-modal(
    :data="editDate"
    :is-visible="isModalVisible.editFirmware"
    @close="closeModal('editFirmware')"
    @success="closeModal('editFirmware')"
    @firmwareEdited="getDevices"
  )
   // Add modal
  app-add-firmware-modal(
    :data="deleteDevice"
    :is-visible="isModalVisible.addFirmware"
    @close="closeModal('addFirmware')"
    @success="closeModal('editFirmware')"
    @firmwareEdited="getDevices"
  )

