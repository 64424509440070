<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Редактировать поверку устройства
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")

          .row.flex-column.align-items-center.g-4
            .col-14.col-md-10
            app-validation-errors(
              v-if="localValidationErrors"
              :validation-errors="localValidationErrors"
              :classes="'mb-3'"
            )
            .col-14.col-md-10.mt-3
              .row.g-4.posRelative
                .col
                 div
                  input(
                      :class="['form__field form__field--animate']"
                      type="text"
                      name="id"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                      v-model="form.serial"
                      disabled
                    )
                  label.textFloat(for='id') Серийный номер МИ
                  input(
                      :class="['form__field form__field--animate','mt-3']"
                      type="text"
                      name="id"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                      v-model="form.device_attestation"
                    )
                  label.textFloat(for='id') Номер поверки
                  .row.gx-4
                   .col-7
                      .text-size--small.mb-2.mt-3 Дата поверки
                      date-picker(
                          prefix-class="app"
                          placeholder="Дата поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                          :disabled-date="disableAfterToday"
                          v-model='form.device_attestation_start'
                      )
                   .col-7
                     .text-size--small.mb-2.mt-3  Окончание срока поверки
                     date-picker(
                          prefix-class="app"
                          placeholder="Окончание срока поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                          v-model='form.device_attestation_end'
                          :disabled-date="disableExpiration"
                      )

            .col-14.col-md-10.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Отправить
            .col-14.col-md-9

</template>

<script>
import devicesApi from '@/api/devices';
import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import { disableAfterToday, disableExpiration } from '@/helpers/datepicker';

export default {
  name: 'AppCreateDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    DatePicker,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    toEdit: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isLoading: false,
      v: variables,
      localValidationErrors: null,
      form: {
        responsible: true,
        serial: '',
        device_attestation_start: '',
        device_attestation_end: '',
        device_attestation: '',
        number: '',
        file: null,
      },
      limit: 100,
    };
  },
  validations() {
    return {
      form: {},
    };
  },
  watch: {
    toEdit: {
      immediate: true,
      handler() {
        (this.form.responsible = this.toEdit.device_verification_owner == null ? false : true),
        (this.form.serial = this.toEdit.serial),
        (this.form.id = this.toEdit.id),
        (this.form.device_attestation = this.toEdit.device_attestation),
        (this.form.device_attestation_start = new Date(
          new Date(this.toEdit.device_attestation_start).setHours(0, 0, 0, 0)
        )),
        (this.form.device_attestation_end = new Date(
          new Date(this.toEdit.device_attestation_end).setHours(0, 0, 0, 0)
        )),
        (this.form.number = this.toEdit.serial);
      },
    },
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;
      let data =  {
        'device_attestation': this.form.device_attestation,
        'device_attestation_start': this.$moment(this.form.device_attestation_start).format('YYYY-MM-DD'),
        'device_attestation_end': this.$moment(this.form.device_attestation_end).format('YYYY-MM-DD'),
      }
      try {
        await devicesApi.updateDeviceCheck(this.form.id, data);
        this.$toast.success('Данные успешно изменены');
        this.closeModal();
      } catch (error) {
        if (error.request.statusText === 'Conflict') {
          this.validationErrors = 'a_device_serial_must_be_unique';
        } else this.localValidationErrors = error.request.statusText;
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.resetForm();
      this.$emit('update');
      this.$emit('close');
      this.$emit('success');
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    resetForm() {
      this.v$.$reset();
      this.localValidationErrors = null;
    },
    disableAfterToday(date) {
      return disableAfterToday(date, this.form.device_attestation_end);
    },
    disableExpiration(date) {
      return disableExpiration(date, this.form.device_attestation_start);
    },
  },
};
</script>
