<template lang="pug">
.profile-exams__inner
  .row.gx-3.text-size--middle
    .col-3.text-color--black
      span.flex-shrink-0.me-2 №
      span {{ exam.exam_number }} ({{ formattedTime }})
    .col-3
      span.flex-shrink-0.me-2 {{ $moment(exam.exam_datetime).format('DD.MM.YYYY') }}
      span {{ $moment(exam.exam_datetime).format('HH:mm') }}
    .col-4
      span.flex-shrink-0 {{ exam.exam_type_name }} &nbsp;
      span.text-color--orange {{ getExamRepeat(exam.exams_count) }} &nbsp;
    .col-4
      span.flex-shrink-0.me-2 {{ exam.patient_full_name }}
  .row.gx-3.mt-4.text-size--middle
    .col-6
      .d-flex
        span.flex-shrink-0.me-2 Рекомендация:&nbsp;
          span.text-color--orange(v-if="medDoctorRecomendations === 'Отстранить'") {{ medDoctorRecomendations }}
          span.text-color--success(v-if="medDoctorRecomendations === 'Допустить'") {{ medDoctorRecomendations }}
        label.form__checkbox.ms-2
          input(
            type="checkbox"
            name="user-type"
            v-model='protector'
            @change='protector ? isModalVisible.protector = true : ""'
          )
          i.form__checkbox-icon.me-3
          span.form__checkbox-caption Отключить
    .col-4
      span.flex-shrink-0.me-2 {{ exam.device_owner_full_name }}
    .col-4
      span.flex-shrink-0.me-2 Пол: {{ exam.gender === 1 ? 'Муж.' : 'Жен.' }} {{ age }} {{ agePostfix }}
  .row.mt-3
    .col-14
      .doctor-exam-detail-modal.innerExamDetailDoctor
        .row.gx-4
          .col-14
            .border-line(:class="{'allow': medDoctorRecomendations === 'Допустить', 'not_allow': medDoctorRecomendations === 'Отстранить'}")
          .col-11
            .row.gx-3
              .col-6
                .innerExamDetailDoctor__ava
                  app-avatar(
                    :type="'doctor'"
                    :localUrl="exam.patient_avatar"
                    :viewPhoto="true"
                    @openGallery="openGallery"
                    :classes="'square extra-large'"
                    :id="exam.patient_code"

                )
                  p.w-100.text-color--blue-dark.text-center.pointer.underline.mt-2.text-uppercase(v-if="exam.patient_avatar" @click='archive') Архивировать фото
              .col-8
                  template(v-if="exam")
                    transition(name="fade" mode="out-in")
                      doctor-exam-card-video(
                        :src="exam.exam_video"
                        :stopVideo="stopVideo"
                      )
                  .text-color--gray-light(v-else)
                    so-icon(icon="no-video")

            doctor-exam-card-inspection-result(
              :exam="exam"
            )
            doctor-exam-card-med-docs-history(
              :exam="exam"
              :id="exam.patient_code"
            )
            doctor-exam-card-med-docs(
              :exam="exam"
            )
          .col-3
            doctor-exam-card-signatures(
              :exam="exam"
            )
            exam-detail-comments(:patientCode="exam.patient_code")

        .row.gx-5.mt-4.pt-2
          .col-7
            .row.gx-5
              .col-7
                button(
                  v-if="exam.type_id == 3 || exam.type_id == 1"
                  type="button"
                  @click="openModal('reasons'), admissionValue = false, typeAdmission = `${exam.type_id}no`, clearCounter = clearCounter + 1"
                  :class="['button', 'button--border-blue', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="wrong")
                    span отстранить

                button(
                  v-if="exam.type_id == 2 || exam.type_id == 4|| exam.type_id == 5"
                  type="button"
                  @click="openModal('reasons'), admissionValue = false, typeAdmission = `${exam.type_id}no`, clearCounter = clearCounter + 1"
                  :class="['button', 'button--border-blue', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="wrong")
                    span не прошел

              .col-7
                button(
                type="button"
                :disabled="prolongationUsed"
                @click="addToTimer"
                :class="['button', 'button--shadow', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center продлить

          .col-7
            .row.gx-5
              .col-7
                button(
                  type="button"
                  @click="stopAfterExam"
                  :class="['button', 'button--shadow', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center.green пауза

              .col-7
                button(
                  v-if="exam.type_id == 2 || exam.type_id == 4 || exam.type_id == 5 "
                  type="button"
                  @click="openModal('reasons'), admissionValue = true, typeAdmission = `${exam.type_id}yes`, clearCounter = clearCounter + 1"
                  :class="['button', 'button--accent', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="allright")
                    span Прошел

                button(
                  v-if="exam.type_id == 3 || exam.type_id == 1"
                  type="button"
                  :disabled="!reject_reasons.approve && !protector"
                  @click="onSetAdmission('yes'), admissionValue = true, typeAdmission = `${exam.type_id}yes`"
                  :class="['button', 'button--accent', 'w-100']"
                )
                  span.button__content.d-flex.align-items-center.justify-content-center
                    span.d-flex.flex-shrink-0.me-3
                      so-icon(icon="allright")
                    span Допустить

  app-protector(
    :is-visible='isModalVisible.protector'
    @update:protector='(v) => setProtector(v)'
    @close='() => closeModal("protector")'
  )
  sign-error-modal(
    :model-value='isModalVisible.signError'
    @update:model-value='(v) => setProtector(v)'
  )
  //Reasons modal
  app-doctor-reasons(
    :is-visible="isModalVisible.reasons"
    @close="closeModal('reasons')"
    :data="exam"
    :reject_reasons_ids="reject_reasons.reject_reason_ids"
    :clearForm="clearForm"
    :type="typeAdmission"
    @refuseOpen='refuseOpen'
    @sendReasons="onSetAdmission('yes')"
    @cleanReasons='cleanReasons'
    :time='clearCounter'
    :protector="protector"
  )
  // Отказать причина
  app-doctor-reasons-refuse(
    :is-visible="isModalVisible.refuse"
    @closeReasons="closeModal('reasons')"
    @close="closeModal('refuse')"
    @setRefuseText='setRefuseText'
    @sendReasons="onSetAdmission('no')"
    :type="typeAdmission"
    :data="exam"
    :reasons="reasons"
    :time='clearCounter'
  )

  sign-error-modal(v-model='isModalVisible.signError')

    // success modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="loading" :withoutCloseBtn="true")
      template(v-slot:body)
        .text-center.pt-4.d-flex.flex-column.justify-content-center.align-items-center
          div(class="spinner-border mb-4" role="status")
          app-title(:type="'large'") Документ находится на подписании
          p.text-size--small.text-color--blue.mt-3 Не обновляйте страницу и не закрывайте данное окно

  // delete avatar modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Архивировать фотографию пользователя?
          p.text-color--blue.mt-3  {{ exam.patient_full_name }}

        .row.align-items-center.justify-content-center.g-4
          .col-7.col-md-auto
            button(
              type="button"
              @click="closeModal('deleteAvatar')"
              :class="['button', 'button--accent', 'form__button']"
            )
              .button__content Не архивировать
          .col-7.col-md-auto
            button(
              type="button"
              @click="deleteAvatar(exam.patient_code)"
              :class="['button', 'button--border-blue', 'form__button']"
            )
              .button__content Да, архивировать

        p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

  // success change modal
  transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.successChange" @close="closeModal('successChange')")
      template(v-slot:body)
        .text-center.pt-4
          app-title(:type="'large'") {{ successMessage }}
          p.text-color--blue.mt-3(v-if="exam") ФИО пользователя: {{ exam.patient_full_name }}
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { videoPlayer } from 'vue-video-player';
import { getUserCertificates, createDetachedSignature, createHash } from 'crypto-pro';

import AppGallery from '@/components/common/Gallery';
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';

import AppAvatar from '@/components/profile/common/Avatar';
import AppDoctorReasons from '@/components/profile/doctor/exams/AppDoctorReasons';
import AppDoctorReasonsRefuse from '@/components/profile/doctor/exams/AppDoctorReasonsRefuse';
import AppProtector from '@/components/profile/doctor/exams/AppProtector';
import AppSignature from '@/components/profile/common/Signature';
import DoctorExamCardInspectionResult from '@/components/profile/doctor/exams/DoctorExamCardInspectionResult';
import DoctorExamCardMedDocsHistory from '@/components/profile/doctor/exams/DoctorExamCardMedDocsHistory';
import DoctorExamCardMedDocs from '@/components/profile/doctor/exams/DoctorExamCardMedDocs';
import DoctorExamCardSignatures from '@/components/profile/doctor/exams/DoctorExamCardSignatures';
import DoctorExamCardVideo from '@/components/profile/doctor/exams/DoctorExamCardVideo';
import DoctorExamDetailModalTable from '@/components/profile/doctor/exams/DoctorExamDetailModalTable';
import ExamDetailComments from '@/components/profile/admin/patients/ExamDetailComments';
import SignErrorModal from '@/components/profile/doctor/modals/SignErrorModal.vue';

import { modalsMixin } from '@/mixins/modals';
import {galleryMixin} from '@/mixins/gallery';

import examsApi from '@/api/exams';
import filesApi from '@/api/files';
import patientApi from '@/api/patients';
// import doctorsApi from '@/api/doctors';

import { gettersTypes as authGettersTypes } from '@/store/modules/auth';
import { actionTypes as doctorsActionTypes } from '@/store/modules/doctors';

import { toLocalDate, toLocalTime } from '@/helpers/utils';

export default {
  components: {
    AppAvatar,
    AppDoctorReasons,
    AppDoctorReasonsRefuse,
    AppGallery,
    AppModal,
    AppProtector,
    AppSignature,
    AppTitle,
    DoctorExamDetailModalTable,
    DoctorExamCardSignatures,
    DoctorExamCardVideo,
    DoctorExamCardInspectionResult,
    DoctorExamCardMedDocsHistory,
    DoctorExamCardMedDocs,
    ExamDetailComments,
    SignErrorModal,
    videoPlayer
  },
  mixins: [modalsMixin, galleryMixin],
  props: {
    uuid: {
      required: true,
      type: String,
    },
    showed: {
      required: true,
      type: Boolean,
      default: false,
    },
    examProcessed: {
      required: true,
      type: Function,
    },
    examReturned: {
      required: true,
      type: Function,
    },
    sendExamAndStop: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      uuidData: '',
      loading: false,
      isModalVisible: {
        selectCertificate: false,
        error: false,
        refuse: false,
        reasons: false,
        deleteAvatar: false,
        protector: false,
        signError: false
      },
      patientSign: '',
      stopVideo: false,
      clearForm: false,
      prolongationUsed: false,
      timeLeft: null,
      stopWork: false,
      timerOnStop: false,
      timer: null,
      typeAdmission: '',
      exam: {
        exam_video: 'test',
      },
      personal_borders: {},
      avgExams: {},
      reasons: [],
      certificates: {
        all: [],
        selected: null,
      },
      result_aprove: true,
      reject_reasons: {
        reject_reason_ids: [],
        approve: true,
      },
      protector: false,
      error: {
        title: 'Произошла ошибка',
        description: 'Пожалуйста, попробуйте ещё раз через некоторое время',
      },
      video: {
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{type: 'video/mp4', src: ''}],
        poster: '',
      },
      isProcess: true,
      clearCounter: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      settings: (state) => state.settings.data,
    }),
    formattedTime() {
      let minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;
      return `${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    age() {
      const birthDate = new Date(this.exam.date_birth);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    agePostfix() {
      if (!this.age) return '';
      const lastDigit = this.age % 10;
      if (lastDigit === 1 && this.age !== 11) {
        return 'год';
      } else if (lastDigit >= 2 && lastDigit <= 4 && (this.age < 10 || this.age > 20)) {
        return 'года';
      } else {
        return 'лет';
      }
    },
    medDoctorRecomendations() {
      if (this.exam.type_id === 2 || this.exam.type_id === 4 || this.exam.type_id === 5) {
        return 'Допустить';
      }
      if (
        (this.exam.type_id == 1 && !this.reject_reasons.approve) ||
        (this.exam.type_id == 3 && !this.reject_reasons.approve)
      ) {
        return 'Отстранить';
      }
      if (
        (this.exam.type_id == 1 && this.reject_reasons.approve) ||
        (this.exam.type_id == 3 && this.reject_reasons.approve)
      ) {
        return 'Допустить';
      } else {
        return '';
      }
    },
  },
  watch: {
    showed: async function (newVal) {
      if (newVal !== true) {
        this.clearForm = true;
        this.stopVideo = true;
        this.protector = false;
        this.isModalVisible.protector = false;
        clearInterval(this.timer);
        return;
      } else {
        this.timeLeft = 80;
        this.reasons = [];
        this.reasonsText = '';
        this.video = {
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{type: 'video/mp4', src: ''}],
          poster: '',
        };
        this.isModalVisible.reasons = false;
        this.isModalVisible.refuse = false;
        this.stopWork = false;
        this.stopVideo = false;
        this.clearForm = false;
        this.timer = setInterval(() => {
          if (this.timeLeft <= 0) {
            this.prolongationUsed = false;
            clearInterval(this.timer);
            this.examReturned();
            this.timeLeft = 10;
            return;
          }
          if (!this.timerOnStop) {
            this.timeLeft = this.timeLeft - 1;
          }
        }, 1000);
        await this.getExamData();
      }
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  async created() {
    this.getExamData();
  },
  methods: {
    ...mapMutations({
      setExamBody: "SET_EXAM_BODY",
    }),
    setProtector(v) {
      this.protector = v;
    },
    //get exam data
    async getExamData() {
      if (!this.uuid) return;

      try {
        this.uuidData = JSON.parse(this.uuid);
        let data = await examsApi.getExamDetail(this.uuidData['id']);
        this.exam = data.data;
        this.exam.exam_datetime += 'Z';
        this.video['sources'][0]['src'] = this.exam.exam_video;

        let result = await patientApi.getPatientBorders(this.exam.patient_code);

        this.personal_borders = result.data;
      } catch (error) {
        console.error('Ошибка в getExamData:', error)
      }
      
      try {
        let reject_reasons = await examsApi.getExamsReasons(this.uuidData['id']);

        this.reject_reasons = {
          reject_reason_ids: reject_reasons.data.reject_reason_ids || [],
          approve: !reject_reasons.data.reject_reason_ids.length,
        };

        let avgExams = await examsApi.avgExams(this.exam.patient_code);
        this.avgExams = avgExams.data;

        let res = await patientApi.getPatientDetail(this.exam.patient_code);
        this.patientSign = res.data.signature;
      } catch (error) {
        console.error('Ошибка в getExamData:', error)
      }
    },

    //set admission to work
    onSetAdmission() {
      this.loading = true;
      this.timerOnStop = true;
      this.getCertificates();
    },
    getCertificates() {
      getUserCertificates(true)
        .then(async (certificates) => {
          this.certificates.all = certificates;
          for (let certificate of certificates) {
            let owner = await certificate.getOwnerInfo();
            let isValidLastName = false;
            let isValidFirstName = false;
            let isValidMiddleName = true;
            owner.forEach((el) => {
              if (el.title === 'Фамилия')
                isValidLastName = el.description === this.currentUser.last_name;
              if (el.title === 'Имя Отчество') {
                isValidFirstName = el.description.split(' ')[0] === this.currentUser.first_name;
                if (this.currentUser.middle_name)
                  isValidMiddleName = el.description.split(' ')[1] === this.currentUser.middle_name;
              }
            });
            let result = Boolean(isValidLastName && isValidFirstName && isValidMiddleName);
            if (result) {
              this.isProcess = true;
              this.certificates.selected = certificate;
              this.getSignature();
              return null;
            }
          }
          this.error.title = 'Ошибка при проверке сертификата';
          this.error.description =
            'Ваше ФИО не совпадает с ФИО владельца сертификата.<br>Попробуйте выбрать другой серификат.';
          this.openModal('error');
        })
        .catch((error) => {
          this.error.title = error;
          // this.openModal('error');
          this.isModalVisible.signError = true;
        });
    },
    getOwnerInfoCertificate(certificate, callback = false) {
      certificate
        .getOwnerInfo()
        .then((owner) => {
          certificate.owner = owner;
          certificate
            .getIssuerInfo()
            .then((issuer) => {
              certificate.issuer = issuer;
              if (callback) this.openModal('selectCertificate');
            })
            .catch((error) => {
              this.error.title = error;
              this.openModal('error');
            });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },
    onSelectCertificate(certificate) {
      this.certificates.selected = certificate;
    },
    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async getSignature() {
      const document = this.createDocument();

      const _validFrom = toLocalDate(this.certificates.selected.validFrom);
      const _validTo = toLocalDate(this.certificates.selected.validTo);

      const documentBase64 = await this.convertBlobToBase64(new Blob([document]));

      const form = {
        exam_id: this.exam.exam_id,
        admission_datetime: new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString(),
        admission_to_work: this.admissionValue,
        data: documentBase64,
        number_kep: this.certificates.selected.thumbprint,
        owner_kep: this.certificates.selected.name,
        validity_kep: `с ${_validFrom} по ${_validTo}`,
        exam_reasons: this.reasonsToIds(this.reasons),
        exam_comment: this.reasonText,
      };

      this.reasons = [];
      this.reasonText = '';

      createHash(document)
        .then((messageHash) => {
          createDetachedSignature(this.certificates.selected.thumbprint, messageHash)
            .then(async (signature) => {
              form.sert_thumbprint = this.certificates.selected.thumbprint;
              const sign_file = await this.convertBlobToBase64(new Blob([signature]));
              form.sign_file = sign_file;
              this.setAdmission(form);
            })
            .catch((error) => {
              this.error.title = error;
              this.openModal('error');
            });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },

    setAdmission(form) {
      this.$store
        .dispatch(doctorsActionTypes.setAdmission, form)
        .then(() => {
          this.close();
          this.$toast.success('Заключение сформировано');
          this.setProtector(false);
          this.prolongationUsed = false;
          this.$emit('success');
          if (this.stopWork) {
            this.sendExamAndStop();
          } else {
            this.examProcessed();
          }
        })
        .catch(() => {
          this.close();
          this.error.title = 'Ошибка при формировании заключения';
          this.openModal('error');
        })
        .finally(() => {
          this.loading = false;
          this.timerOnStop = false;
          this.setExamBody('');
        });
    },

    //create document for loading
    createDocument() {
      const content = {
        date: `${toLocalDate(this.exam.exam_datetime)} ${toLocalTime(this.exam.exam_datetime)}`,
        patient: {
          name: this.exam.patient_full_name,
          gender: this.exam.gender === 2 ? 'Женский' : 'Мужской',
          birthdate: toLocalDate(this.exam.date_birth),
        },
        bpHigh: this.exam.bp_high,
        bpLow: this.exam.bp_low,
        heartRate: this.exam.heart_rate,
        temperature: this.exam.body_temp,
        alcohol: this.exam.alcohol,
        healthComplaint: this.exam.health_complaint ? this.exam.health_complaint : 'Нет',
        doctorDecision: this.admissionValue === true ? 'Допущен' : 'Не допущен',
      };
      return JSON.stringify(content);
    },

    //archive
    async archive() {
      try {
        await patientApi.archivePatientAvatar(this.exam.patient_code, {
          patient_avatar: 'archived',
        });
        this.exam.patient_avatar = null;
        this.$toast.success('Фотография успешно архивирована');
      } catch (error) {
        console.error('Archive failed:', error);
        this.$toast.error('Произошла ошибка при архивации фотографии. Попробуйте еще раз');
      }
    },

    //comments
    async createComment() {
      await patientApi.createPatientComment({
        patient_code: this.data.patient_code,
        comment: this.currentComment,
      });
      this.currentComment = '';
      let comments = await patientApi.getPatientComment(this.data.patient_code);
      comments = await comments.json();
      this.comments = comments.items;
    },

    //exam
    refuseOpen(items) {
      this.reasons = [];
      this.reasons = items;
      this.openModal('refuse');
    },
    setRefuseText(reasonText) {
      this.reasonText = reasonText;
    },
    addToTimer() {
      this.timeLeft += 20;
      this.prolongationUsed = true;
    },
    stopAfterExam() {
      this.stopWork = true;
      this.prolongationUsed = false;
      this.examReturned();
    },

    //close clear
    close() {
      this.reset();
      this.$emit('close');
    },
    reset() {
      this.isProcess = false;
      this.certificates.selected = null;
      this.admissionValue = null;
      this.prolongationUsed = false;
    },
    cleanReasons() {
      this.reasons = [];
    },

    //reasons
    reasonsToIds(reasons) {
      let result = [];

      let injuries = {
        'Травма: Волосистой области, слева': 31,
        'Травма: Волосистой области, справа': 32,
        'Травма: Волосистой области': 33,
        'Травма: Околоушно-жевательной области, слева': 34,
        'Травма: Околоушно-жевательной области, справа': 35,
        'Травма: Околоушно-жевательной области, слева, справа': 36,
        'Травма: Лобной области, слева': 37,
        'Травма: Лобной области, справа': 38,
        'Травма: Лобной области': 39,
        'Травма: Уха, слева': 40,
        'Травма: Уха, справа': 41,
        'Травма: Уха, слева, справа': 42,
        'Травма: Височной области, слева': 43,
        'Травма: Височной области, справа': 44,
        'Травма: Височной области, слева, справа': 45,
        'Травма: Мягких тканей лица, слева': 46,
        'Травма: Мягких тканей лица, справа': 47,
        'Травма: Мягких тканей лица': 48,
        'Травма: Надбровной области, слева': 49,
        'Травма: Надбровной области, справа': 50,
        'Травма: Надбровной области, слева, справа': 51,
        'Травма: Верхней губы, слева': 52,
        'Травма: Верхней губы, справа': 53,
        'Травма: Верхней губы': 54,
        'Травма: Параорбитальная гематома, слева': 55,
        'Травма: Параорбитальная гематома, справа': 56,
        'Травма: Параорбитальная гематома, слева, справа': 57,
        'Травма: Нижней губы, слева': 58,
        'Травма: Нижней губы, справа': 59,
        'Травма: Нижней губы': 60,
        'Травма: Века, слева': 61,
        'Травма: Века, справа': 62,
        'Травма: Века, слева, справа': 63,
        'Травма: Подбородочной области, слева': 64,
        'Травма: Подбородочной области, справа': 65,
        'Травма: Подбородочной области': 66,
        'Травма: Воспаление века неуточненное, слева': 67,
        'Травма: Воспаление века неуточненное, справа': 68,
        'Травма: Воспаление века неуточненное, слева, справа': 69,
        'Травма: Множественные травмы головы, слева': 70,
        'Травма: Множественные травмы головы, справа': 71,
        'Травма: Множественные травмы головы': 72,
        'Травма: Подглазничной области, слева': 73,
        'Травма: Подглазничной области, справа': 74,
        'Травма: Подглазничной области, слева, справа': 75,
        'Травма: Шеи неуточненная, слева': 76,
        'Травма: Шеи неуточненная, справа': 77,
        'Травма: Шеи неуточненная': 78,
        'Травма: Скуловой области, слева': 79,
        'Травма: Скуловой области, справа': 80,
        'Травма: Скуловой области, слева, справа': 81,
        'Травма: Грудной клетки неуточненная': 82,
        'Травма: Спинки носа': 83,
        'Травма: Верхней конечности, слева': 84,
        'Травма: Верхней конечности, справа': 85,
        'Травма: Верхней конечности, слева, справа': 86,
        'Травма: Носа, слева': 87,
        'Травма: Носа, справа': 88,
        'Травма: Носа': 89,
        'Травма: Нижней конечности, слева': 90,
        'Травма: Нижней конечности, справа': 91,
        'Травма: Нижней конечности, слева, справа': 92,
      };

      for (let reason of reasons) {
        if ('checked' in reason && reason.text !== 'Травма') {
          result.push(reason.id);
        } else {
          if (reason.both) {
            result.push(injuries[`Травма: ${reason.title}`]);
          } else if (reason.both == null && reason.left && reason.right) {
            result.push(injuries[`Травма: ${reason.title}, слева, справа`]);
          } else {
            if (reason.left) {
              result.push(injuries[`Травма: ${reason.title}, слева`]);
            }
            if (reason.right) {
              result.push(injuries[`Травма: ${reason.title}, справа`]);
            }
          }
        }
      }
      return result;
    },

    //certificate video map
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    initializedMap(instance) {
      this.map.isLoading = false;
      this.map.instance = instance;
      // eslint-disable-next-line no-undef
      ymaps.ready(() => (this.map.ymaps = ymaps));
    },
    getVideo(hash) {
      if (hash) {
        this.video.isLoading = true;
        filesApi.getVideo(hash).then((url) => {
          this.video.sources[0].src = url;
          this.videoSrc = url;
          this.video.isLoading = false;
        });
      }
    },

    //loader
    handleBeforeUnload(event) {
      if (this.loading) {
        const message =
          'Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны.';
        event.returnValue = message;
        return message;
      }
    },

    //common
    getExamRepeat(exams_count) {
      return exams_count > 1 ? '(повторный)' : '';
    },
    pad(number) {
      return number < 10 ? `0${number}` : number;
    },
  },
};
</script>

<style lang="scss">
.innerExamDetailDoctor {
  background: #fff;
  padding: 20px 15px 20px;
}

.innerExamDetailDoctor__ava,
.innerExamDetailDoctor__video {
  margin-bottom: 0px;
  figure {
    width: 100% !important;
    height: 300px !important;
  }
}

.innerExamDetailDoctor__ava {
  p {
    margin-bottom: 0;
  }
}

.innerExamDetailDoctor__signs {
  text-align: center;
}

.innerExamDetailDoctor__signs-item {
  height: 125px;
  padding-top: 11px;
  margin-bottom: 7px;
  background: #ffffff;
  box-shadow: 0 6px 20px 0 rgba(3, 28, 67, 0.12);
}

.innerExamDetailDoctor__signs-item-lbl {
  text-align: center;
}

.innerExamDetailDoctor__video {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: get($gradients, blue-light);
  border-radius: 0;

  .icon__no-video {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.innerExamDetailDoctor__table {
  color: #59658d;

  thead {
    font-size: 12px;

    th {
      font-weight: 400;
      padding-bottom: 8px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #59658d;

      &:last-child {
        border: none;
      }
    }

    td {
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    td:first-child {
      font-size: 12px;
    }
  }
}

.border-line {
  width: 100%;
  height: 6px;
  background: get($gradients, orange);
}
.allow {
  background: get($gradients, turq);
}
.disabledProtector {
  background: get($gradients, grey);
}
.turq {
  color: #12b048;
}
.not_allow {
  background: get($gradients, orange);
}
.not-allow-button {
  background: get($gradients, orange);
  color: white;
}
.spinner-border {
  color: get($colors, turq);
}
</style>
