<template lang="pug">
.profile-doctors
  .profile-doctors__header
    .row.align-items-center
      .col-14.col-md-12
        .d-flex.align-items-center
          app-title(:type="'large'") Настройки системы. Управление осмотрами
    so-tabs(:tabs="tabs" :selectedTab="selectedTab" @changeTab="changeTab")

</template>

<script>
import { ref } from 'vue';
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import { gettersTypes as authGettersTypes } from '@/store/modules/auth';
import { mapGetters } from 'vuex';
import SoTabs from '@/components/so/tabs/SoTabs.vue';

export default {
  name: 'SystemSetupInspectionControl',
  components: {
    AppTitle,
    AppMultipleSelect,
    SoTabs,
  },
  setup() {
    const tabs = [{ name: '1st', label: 'about' }, { name: '2nd', label: 'what' }];
    const selectedTab = ref('1st');

    const currentUser = mapGetters({
      currentUser: authGettersTypes.currentUser,
    });

    const changeTab = (tabName) => {
      selectedTab.value = tabName;
    };

    return {
      tabs,
      selectedTab,
      ...currentUser,
      changeTab
    };
  }
};
</script>
