
header(:class="['header', 'd-flex align-items-center', {'is-profile d-none d-xl-flex': isLoggedIn}]")
  .container-fluid
    transition(name="fade" mode="out-in")
      template(v-if="isLoggedIn")
        .row.align-items-center
          .col-3
          .col
            .d-flex.justify-content-center
              router-link(:to="{name: 'dashboard'}")
                app-logo
          .col-3
            .d-flex.justify-content-end
              router-link(
                :to="{name: 'settings'}"
                class=['text-decoration--underline text-size--small link--accent', 'me-5']
              ) Настройки личного кабинета

              button(
                @click="onLogout"
                type="button"
                class=['text-decoration--underline text-size--small link--accent']
              ) Выход

      template(v-else)
        .row.justify-content-center
          .col-14.col-xl-6.col-xxl-5
            nav
              ul.d-flex.flex-column.flex-xl-row.justify-content-between.align-items-center
                template(v-if="$route.name === 'register'")
                  //- li.mb-2.mb-xl-0
                    router-link(
                      :to="{name: 'registerStatus'}"
                      class=['text-decoration--underline text-size--small']
                    ) Проверить статус моей заявки
                  li
                    router-link(
                      :to="{name: 'login'}"
                      class=['text-decoration--underline text-size--small']
                    ) Войти в личный кабинет

                template(v-if="$route.name === 'login'")
                  //- li.mb-2.mb-xl-0
                    router-link(
                      :to="{name: 'registerStatus'}"
                      class=['text-decoration--underline text-size--small']
                    ) Проверить статус моей заявки
                  //- li
                    router-link(
                      :to="{name: 'register'}"
                      class=['text-decoration--underline text-size--small']
                    ) Регистрация нового клиента


                template(v-if="$route.name === 'registerStatus'")
                  li.mb-2.mb-xl-0
                    router-link(
                      :to="{name: 'login'}"
                      class=['text-decoration--underline text-size--small']
                    ) Войти в личный кабинет
                  //- li
                    router-link(
                      :to="{name: 'register'}"
                      class=['text-decoration--underline text-size--small']
                    ) Регистрация нового клиента
