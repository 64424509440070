
<div id="app" :class="{'is-modal-open': v.state.isModalOpen}">
  <app-header></app-header>
  <main :class="[{'is-profile': isLoggedIn}]">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </main>

  <vue-headful v-if="title" :title="title"></vue-headful>
  <vue-headful v-else title="SafeOperator"></vue-headful>
</div>
