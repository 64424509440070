
.profile-dashboard__content
  .mb-5(v-if="notifications && notifications.length")
    transition-group(name="fade" tag="div")
      app-notification(
        v-for="(notification, index) in notifications" :key="notification.id"
        :data="notification"
        @delete="deleteNotification(notification.id, index)"
      )

  // device requests
  //- .position-relative.mb-5
    app-loading(v-if="requestsLoading")
    app-error-message(v-if="requestsError" :message="requestsError")

    transition(name="translucent")
      app-dashboard-card(
        :goTo="'requests'"
        :classes="`${requestsPending.devices.length === 0 ? 'is-empty' : ''}`"
        v-if="requests"
      )
        template(v-slot:title)
          .mb-4(v-if="requestsPending.devices.length") Последние заявки на&nbsp;регистрацию устройств&nbsp;(+{{ requestsPending.devices.length }})
          .text-color--gray-light(v-else) Здесь будут отображаться последние заявки на регистрацию устройств

        template(v-slot:body)
          template(v-if="requestsPending.devices.length")
            ul.d-none.d-xl-block
              li.mb-1(v-for="(request, index) in requestsPending.devices.slice(0, requestsPending.limit)" :key="index")
                app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

            // slider for mobile
            .profile-modile-slider.d-xl-none
              swiper(
                ref="requestsDevicesSlider"
                :options="Object.assign({}, slider.common, {pagination: {el: '.js-requests-devices-pagination', dynamicBullets: true}})"
                :auto-update="true"
                :auto-destroy="false"
              )
                swiper-slide(v-for="(request, index) in requestsPending.devices.slice(0, 4)" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-requests-devices-pagination'])


          .text-size--small.text-color--gray-light(v-else) Пока нет заявок

        template(v-slot:button)
          template(v-if="requestsPending.users.length") Все заявки


  // user requests
  //- .position-relative.mb-5
    app-loading(v-if="requestsLoading")
    app-error-message(v-if="requestsError" :message="requestsError")

    transition(name="translucent")
      app-dashboard-card(
        v-if="requests"
        :goTo="'requests'"
        :classes="`${requestsPending.users.length === 0 ? 'is-empty' : ''}`"
      )
        template(v-slot:title)
          .mb-4(v-if="requestsPending.users.length") Последние заявки на&nbsp;регистрацию клиентов&nbsp;(+{{ requestsPending.users.length }})
          .text-color--gray-light(v-else) Здесь будут отображаться последние заявки на регистрацию клиентов

        template(v-slot:body)
          template(v-if="requestsPending.users.length")
            ul.d-none.d-xl-block
              li.mb-1(v-for="(request, index) in requestsPending.users.slice(0, requestsPending.limit)" :key="index")
                app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

            // slider for mobile
            .profile-modile-slider.d-xl-none
              swiper(
                ref="requestsUsersSlider"
                :options="Object.assign({}, slider.common, {pagination: {el: '.js-requests-users-pagination', dynamicBullets: true}})"
                :auto-update="true"
                :auto-destroy="false"
              )
                swiper-slide(v-for="(request, index) in requestsPending.users.slice(0, 4)" :key="index")
                  app-admin-request-card(:data="request" @reject="openRejectModal" @approve="approveRequest")

                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-requests-users-pagination'])


          .text-size--small.text-color--gray-light(v-else) Пока нет заявок

        template(v-slot:button)
          template(v-if="requestsPending.users.length") Все заявки



  // exams
  .position-relative.mb-5
    app-loading(v-if="examsLoading")
    app-error-message(v-if="examsError" :message="examsError")

    transition(name="translucent")
      app-dashboard-card(
        v-if="exams"
        :goTo="'exams'"
        :classes="`${exams && exams.length === 0 ? 'is-empty' : ''}`"
      )
        template(v-slot:title)
          .d-flex.align-items-center.mb-4(v-if="exams && exams.length")
            span.flex-shrink-0.text-color--blue.me-3.d-xl-none
              so-icon(icon="smartwatch" view-box="0 0 17 24")
            span Последние данные со&nbsp;всех устройств

          .text-color--gray-light(v-else) Здесь будут отображаться последние данные со всех устройств

        template(v-slot:body)
          template(v-if="exams && exams.length")
            .profile-table.d-none.d-xl-block
              .profile-table__header
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      v-if="column.caption || column.icon"
                      type="button"
                      @click="sortingExams(column.key)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span(v-if="column.caption") {{ column.caption }}
                        span.flex-shrink-0(v-else-if="column.icon")
                          so-icon(:icon="column.icon")
                        span.flex-shrink-0.d-flex.ms-3
                          so-icon(icon="down-arrow")

              ul.profile-table__body(id="dashboard-exams")
                li.profile-table__row(v-for="exam in exams.slice(0, 3)" :key="exam.exams_id")
                  app-exam-card(
                    :data="exam"
                    :parent-id="'dashboard-exams'"
                    :columns="columns"
                    @openModal="openExamDetailModal"
                    @openCertificate="openCertificate"
                  )

            // slider for mobile
            .profile-modile-slider.d-xl-none
              swiper(
                ref="examsSlider"
                :options="Object.assign({}, slider.common, slider.exams)"
                :auto-update="true"
                :auto-destroy="false"
              )
                swiper-slide(v-for="exam in exams.slice(0, 10)" :key="exam.exams_id")
                  app-exam-card-mobile(:data="exam" @openModal="openExamDetailMobile")

                div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])

          .text-size--small.text-color--gray-light(v-else) Пока нет осмотров

        template(v-slot:button)
          template(v-if="exams && exams.length") Все осмотры


  // modals
  app-exam-detail-modal(
    :is-visible="isModalVisible.examDetail"
    :data="currentExam.data"
    :type="currentExam.type"
    @close="closeModal('examDetail')"
    @openGallery="openGallery"
  )
  // exam detail modal for mobile
  app-exam-detail-mobile(
    :is-visible="isModalVisible.examDetailMobile"
    :data="currentExam.data"
    @openModal="openExamDetailModal"
    @openCertificate="openCertificate"
    @close="closeModal('examDetailMobile')"
  )
  // certificate modal
  app-certificate-modal(
    :is-visible="isModalVisible.certificate"
    :data="certificate"
    @close="closeModal('certificate')"
  )
  // gallery
  app-gallery(
    :is-visible="isModalVisible.gallery"
    :options="gallery"
    @close="closeGallery"
  )
  //Удаление
  transition(name="fade")
    app-modal(v-if="isModalVisible.reject" @close="closeModal('reject')")
      template(v-slot:body)
        .row.justify-content-center
          .col-14.col-md-10
            .text-center
              app-title(:type="'large'") Отказать в регистрации
              .mt-3.text-uppercase
                ul(v-if="currentRequest.type_code === v.requests.types.device")
                  li(v-for="num in currentRequest.additional_params.device_serials") Серийный номер МИ {{ num }}
                p(v-if="currentRequest.type_code === v.requests.types.user") {{ currentRequest.user_full_name }}

            .mt-5
              app-validation-errors(
                v-if="requestsError"
                :classes="'mb-3'"
                :validation-errors="requestsError"
              )
              form(@submit.prevent="onSubmitRejectForm" :class="['form', {'is-submitting': requestsLoading}]")
                .row.flex-column.align-items-center
                  .col.mb-5
                    label.form__label
                      textarea(
                        :class="['form__field', { 'is-error': v$.form.message.$errors.length }]"
                        v-model="v$.form.message.$model"
                        placeholder="Причина отказа"
                      )
                  .col
                    button(
                      type="submit"
                      :disabled="v$.$invalid"
                      class=['button', 'button--accent', 'form__button']
                    )
                      .button__content Отправить

  //Подтвержение
  transition(name="fade")
    app-modal(v-if="isModalVisible.success" @close="closeModal('success')" :classes="'d-flex flex-column justify-content-center'")
      template(v-slot:body)
        .text-center
          app-title(:type="'large'") {{ successMessage }}
          p.text-size--small.text-color--blue.mt-3 {{ currentRequest.user_full_name }} будет уведомлен о принятом решении
  app-confirm-check-modal(
    :is-visible="isModalVisible.confirm"
    :currentRequest="currentRequest"
    @openModal="openExamDetailModal"
    @close="closeModal('confirm')"
  )

