<template>
<transition name="fade" mode="out-in">
  <app-modal
  v-if="modelValue"
  @close="onClose"
  :withoutCloseBtn='!canClose'
  >
    <template v-slot:body>
      <slot name='header'>
        <div class='text-center mb-2'>
          <app-title :type='titleSize'>
            <slot name='title'></slot>
          </app-title>
        </div>
      </slot>
      <slot></slot>
      <slot name='footer'>
        <div :class='`d-flex ${cancel && ok ? "justify-content-between" : "justify-content-center"}`'>
          <div v-if='cancel' class='d-flex justify-content-end'>
            <so-button
            @on-click="() => onCancel()"
            >
              <span class='button__content d-flex align-items-center'>{{ cancelText }}</span>
            </so-button>
          </div>

          <div v-if='ok' class='d-flex justify-content-end'>
            <so-button
            @on-click="() => onOk()"
            >
              <span class='button__content d-flex align-items-center'>{{ okText }}</span>
            </so-button>
          </div>
        </div>
      </slot>
    </template>
  </app-modal>
</transition>
</template>

<script lang='ts'>
import { computed } from 'vue';
import type { PropType } from 'vue';

import AppModal from '@/components/common/Modal.vue';
import AppTitle from '@/components/common/Title.vue';
import SoButton from '@/components/so/button/SoButton.vue';

export default {
  components: {
    AppModal,
    AppTitle,
    SoButton
  },
  props: {
    modelValue: Boolean,
    canClose: {
      type: Boolean,
      default: true
    },
    ok: [Boolean, String],
    cancel: [Boolean, String],
    titleSize: {
      type: String as PropType<'large' | 'normal'>,
      default: 'normal'
    },
  },
  setup(props, { emit }) {
    function onClose() {
      emit('update:modelValue', false);
      emit('on-close');
    }
    function onCancel() {
      emit('on-cancel');
      onClose();
    }
    function onOk() {
      emit('on-ok');
      onClose();
    }
    const cancelText = computed(() => {
      return typeof props.cancel === 'string' ? props.cancel : 'Отмена';
    });
    const okText = computed(() => {
      return typeof props.ok === 'string' ? props.ok : 'OK';
    });

    return {
      onClose,
      onCancel,
      onOk,
      cancelText,
      okText
    }
  }
}
</script>

<style>

</style>
