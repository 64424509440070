
.row.justify-content-center
  .col-14.col-xl-13
    template(v-if="showSuccessMessage")
      .text-center
        app-title(:type="'large'" :classes="'mb-5'") Заявка успешно отправлена на рассмотрение
        p.text-color--blue.text-size--small
          |Вы можете&#32;
          router-link(class="text-decoration--underline" :to="{name: 'registerStatus'}") проверить статус вашей заявки через форму.
          |&#32;По времени рассмотрение может занять от 1-ого до 4-х рабочих дней.

    template(v-else)
      .text-center.mb-5
        app-title(:type="'large'") Регистрация клиента
      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isSubmitting}]")
        app-validation-errors(
          v-if="errors"
          :validation-errors="errors"
          :classes="'mb-3'"
        )
        .row.flex-column.align-items-center.g-4
          .col
            app-tab-nav(
              :tabs="[{id:'company', caption:'Юридическое лицо'}, {id:'person', caption: 'Физическое лицо'}]"
              :selected="activeTab"
              :theme="'jointly'"
              @selected="setActiveTab"
            )
          .col.mb-5
            app-tab(:is-selected="activeTab === 'company'")
              .row.flex-wrap.g-4
                .col-14(v-if="v$.companyName")
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.companyName.$errors.length }]"
                      type="text"
                      v-model="v$.companyName.$model"
                      placeholder="Полное наименование юридического лица*"
                    )
                  ul.form__error(v-if="v$.companyName.$errors.length")
                    li(v-for="error of v$.companyName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                        
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.inn.$errors.length }]"
                      type="number"
                      v-model="v$.inn.$model"
                      v-mask="'##########'"
                      placeholder="ИНН*"
                    )
                  ul.form__error(v-if="v$.inn.$errors.length")
                    li(v-for="error of v$.inn.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.TIN }}
                      template(v-if="error.$validator === 'maxLength'") {{ v.errors.TIN }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.email.$errors.length }]"
                      type="email"
                      v-model="v$.email.$model"
                      placeholder="E-mail*"
                    )
                  ul.form__error(v-if="v$.email.$errors.length")
                    li(v-for="error of v$.email.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-else-if="error.$validator === 'email'") {{ v.errors.email }}

                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                      app-tooltip(
                        :classes="'text-color--accent'"
                        :placement="'left'"
                        :content="v.errors.password.requirements"
                      )
                      
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.password.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.password.$model"
                        placeholder="Пароль*"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        so-icon(v-if="showPass" :icon="'eye-open'")
                        so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.password.$errors.length")
                    li(v-for="error of v$.password.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      
                      template(v-if="v$.password.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}
                  
                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю
                
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.confirmPassword.$errors.length }]"
                      :type="showPass ? 'text' : 'password'"
                      v-model="v$.confirmPassword.$model"
                      placeholder="Повторите пароль"
                    )
                    span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                      so-icon(v-if="showPass" :icon="'eye-open'")
                      so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.confirmPassword.$errors.length")
                    li(v-for="error of v$.confirmPassword.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="v$.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}
                    
            app-tab(:is-selected="activeTab === 'person'")
              .row.flex-wrap.g-4
                .col-14.col-md-7(v-if="v$.firstName")
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.firstName.$errors.length }]"
                      type="text"
                      v-model="v$.firstName.$model"
                      placeholder="Имя*"
                    )
                  ul.form__error(v-if="v$.firstName.$errors.length")
                    li(v-for="error of v$.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="v$.firstName.$model && error.$validator === 'alphaNum'") {{ v.errors.alphaNum }}
                        
                .col-14.col-md-7(v-if="v$.lastName")
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.lastName.$errors.length }]"
                      type="text"
                      v-model="v$.lastName.$model"
                      placeholder="Фамилия*"
                    )
                  ul.form__error(v-if="v$.lastName.$errors.length")
                    li(v-for="error of v$.lastName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="v$.lastName.$model && error.$validator === 'alphaNum'") {{ v.errors.alphaNum }}
                    
                .col-14.col-md-7(v-if="v$.middleName")
                  label.form__label
                    input(
                      class="form__field"
                      type="text"
                      v-model="v$.middleName.$model"
                      placeholder="Отчество"
                    )
                  ul.form__error(v-if="v$.middleName.$errors.length")
                    li(v-for="error of v$.middleName.$errors" :key="error.$uid")
                      template(v-if="v$.middleName.$model && error.$validator === 'alphaNum'") {{ v.errors.alphaNum }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.inn.$errors.length }]"
                      type="number"
                      v-model="v$.inn.$model"
                      v-mask="'############'"
                      placeholder="ИНН*"
                    )
                  ul.form__error(v-if="v$.inn.$errors.length")
                    li(v-for="error of v$.inn.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'minLength'") {{ v.errors.TIN }}
                      template(v-if="error.$validator === 'maxLength'") {{ v.errors.TIN }}

                .col-14
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.email.$errors.length }]"
                      type="email"
                      v-model="v$.email.$model"
                      placeholder="E-mail*"
                    )
                  ul.form__error(v-if="v$.email.$errors.length")
                    li(v-for="error of v$.email.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                      app-tooltip(
                        :classes="'text-color--accent'"
                        :placement="'left'"
                        :content="v.errors.password.requirements"
                      )
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.password.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.password.$model"
                        placeholder="Пароль*"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        so-icon(v-if="showPass" :icon="'eye-open'")
                        so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.password.$errors.length")
                    li(v-for="error of v$.password.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      
                      template(v-if="v$.password.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}
                      
                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.confirmPassword.$errors.length }]"
                      :type="showPass ? 'text' : 'password'"
                      v-model="v$.confirmPassword.$model"
                      placeholder="Повторите пароль"
                    )
                    span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                      so-icon(v-if="showPass" :icon="'eye-open'")
                      so-icon(v-else :icon="'eye-close'")

                  ul.form__error(v-if="v$.confirmPassword.$errors.length")
                    li(v-for="error of v$.confirmPassword.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="v$.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}
          .col
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              .button__content Зарегистрироваться 
            <vue-recaptcha ref="recaptcha" sitekey="6LcloskhAAAAAAtV0CcUGcl8gNoLgJaYdFoO2DOE" />
          .col-14.col-xl-12.col-xxl-10
            p.text-color--blue-dark.text-size--small.text-center Нажимая на кнопку «Зарегистрироваться» вы даёте согласие на обработку персональных данных
          .col 
          

