<template lang="pug">
  .profile-map
    .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
      .profile-map__header
        .row.align-items-center.flex-column-reverse.flex-md-row.g-4
          .col
            .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
              app-back-button(:classes="'me-5'")
              app-title(
                :type="'large'"
                :classes="'text-center'"
              ) {{ isTouchDevice ? 'Геокарта' : 'Просмотр геокарты' }}

          .col-auto
            router-link(:to="{name: 'map-routes'}" class=['d-flex align-items-center', 'text-size--small link--accent'])
              span.flex-shrink-0.d-flex.me-3
                so-icon(icon="map-route")
              span Просмотреть перемещения устройств
      // map
      .profile-map__content.position-relative.mt-30.mt-md-20
        .profile-map__container
          app-loading(v-if="map.loading")
          yandex-map(
            :settings="v.map.default"
            :options="map.options"
            :coords="map.center"
            :scroll-zoom="false"
            :zoom="map.zoom"
            :controls="map.controls"
            :behaviors="map.behaviors"
            :show-all-markers="true"
            @map-was-initialized="initializedMap"
          )
            ymap-marker(
              v-for="(placemark, index) in map.placemarks"
              :key="index"
              :marker-id="index"
              :coords="placemark.coords"
              :icon="placemark.marker"
              @balloonopen="bindListener(placemark.balloon)"
              @balloonclose="unbindListener(placemark.balloon)"
            )
              app-map-balloon(slot="balloon" :data="placemark.balloon")

        // exams
        .mt-5.position-relative
          transition(name="translucent")
            app-loading(v-if="examsLoading")
            app-error-message(v-if="examsError" :message="examsError")

            div(v-if="exams")
              .d-none.d-xl-block
                .profile-table.is-exams-table(v-if="map.filteredExams.length" :key="map.filteredExams.length")
                  .profile-table__header
                    .row.align-items-center.g-4
                      div(v-for="column in columns" :key="column.key" :class="column.class")
                        button(
                          type="button"
                          @click="sortingExams(column.key, column.inverse)"
                          class=['profile-table__sort-button']
                          :class="{'is-active': sortKey === column.key}"
                        )
                          span.d-flex.align-items-center
                            span(v-if="column.caption") {{ column.caption }}
                            span.flex-shrink-0(v-else-if="column.icon")
                              so-icon(:icon="column.icon")
                            span.flex-shrink-0.d-flex.ms-3
                              so-icon(icon="down-arrow")

                  ul.profile-table__body(id="map-exams")
                    li.profile-table__row(v-for="exam in map.filteredExams.slice(0, pagination.offset)" :key="exam.id")
                      app-exam-card(
                        :data="exam"
                        :type="'map'"
                        :parent-id="'map-exams'"
                        :columns="columns"
                        @map="showToMap"
                        @openCertificate="openCertificate"
                        @openModal="openExamDetailModal"
                      )

                button(
                  v-if="pagination.offset < map.filteredExams.length"
                  type="button"
                  @click="pagination.offset += pagination.limit"
                  class=['text-size--small text-decoration--underline link--accent', 'mt-4 me-4']
                ) Показать еще (+{{ (map.filteredExams.length - pagination.offset) >= pagination.limit ? pagination.limit : map.filteredExams.length - pagination.offset}})

                button(
                  v-if="pagination.offset > pagination.limit"
                  type="button"
                  @click="pagination.offset = pagination.limit"
                  class=['text-size--small text-decoration--underline link--accent', 'mt-4']
                ) Свернуть


              // slider for mobile
              .profile-modile-slider.d-xl-none
                swiper(
                  :options="Object.assign({}, slider.common, slider.exams)"
                  :auto-update="true"
                  :auto-destroy="false"
                )
                  swiper-slide(v-for="exam in map.filteredExams.slice(0, pagination.offset)" :key="exam.id")
                    app-exam-card-mobile(
                      :data="exam"
                      @openModal="openExamDetailMobile"
                      :type="'map'"
                      @map="showToMap"
                    )
                  div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])



    // filter
    app-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :type="'map'"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import is from 'is_js';
import variables from '@/helpers/variables';
import {isItemInArray, uniqueArray} from '@/helpers/utils';

import {yandexMap, ymapMarker} from 'vue-yandex-maps';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Pagination} from 'swiper';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppGallery from '@/components/common/Gallery';

import AppMapBalloon from '@/components/profile/common/map/MapBalloon';
import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

SwiperCore.use([Pagination]);

export default {
  name: 'AppMapExams',
  components: {
    AppBackButton,
    AppTitle,
    AppGallery,

    AppLoading,
    AppErrorMessage,
    AppMapBalloon,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    yandexMap,
    ymapMarker,
    Swiper,
    SwiperSlide,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, certificateModalMixin, galleryMixin],
  data() {
    const $self = this;

    return {
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },
      sortKey: '',
      sortInverse: false,
      columns: [
        {
          class: 'col-3',
          caption: 'Дата',
          key: 'exam_date_time',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Координаты',
          key: 'lat',
          inverse: true,
        },
        {
          class: 'col',
          caption: 'Код и ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
      ],
      map: {
        ymaps: null,
        instance: null,
        options: {
          autoFitToViewport: 'always',
        },
        loading: true,
        center: [45.033333, 38.983333],
        zoom: 10,
        controls: ['zoomControl'],
        behaviors: ['default', 'dblClickZoom', 'scrollZoom', 'multiTouch'],
        marker: {
          layout: 'default#imageWithContent',
          imageHref:
            'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAyOCAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDE0LjcwNTlDMjggMjUuMjk0MSAxNCAzNSAxNCAzNUMxNCAzNSAwIDI1LjI5NDEgMCAxNC43MDU5QzAgNi41ODQwNSA2LjI2ODAxIDAgMTQgMEMyMS43MzIgMCAyOCA2LjU4NDA1IDI4IDE0LjcwNTlaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXIpIi8+CjxjaXJjbGUgY3g9IjE0LjAwMDIiIGN5PSIxNCIgcj0iNy44NCIgZmlsbD0id2hpdGUiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9IjE0IiB5MT0iLTIzLjQ4NjgiIHgyPSIzMi4xMzIiIHkyPSItMjEuMzEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0VDOUQyNSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNFQzg1MjUiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K',
          imageSize: [28, 35],
          imageOffset: [-14, -35],
          content: '',
          contentOffset: [8, 5],
          contentLayout:
            '<span style="text-align: center; font-size: 0.625rem">$[properties.iconContent]</span>',
        },
        placemarks: [],
        exams: [],
        filteredExams: [],
        duration: 400,
      },
      pagination: {
        limit: 10,
        offset: 10,
      },
      slider: {
        common: {
          slidesPerView: 1,
          observer: true,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2,
            },
          },
          on: {
            slideNextTransitionEnd(swiper) {
              if (swiper.isEnd && $self.pagination.offset < $self.map.filteredExams.length)
                $self.pagination.offset += $self.pagination.limit;
            },
          },
        },
        exams: {
          pagination: {
            el: '.js-exams-pagination',
            dynamicBullets: true,
            renderBullet: function (index, className) {
              const slide = this.slides[index];
              let status;

              if (slide) {
                status = slide.querySelector('[data-status]');
                return `<span data-status="${status.dataset.status}" class="${className}"></span>`;
              } else return false;
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      examsLoading: (state) => state.exams.isLoading,
      exams: (state) => state.exams.data,
      examsCount: (state) => state.exams.count,
      examsError: (state) => state.exams.error,
    }),
    isTouchDevice() {
      return is.touchDevice();
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: true,
            payload: [],
          },
          users: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  created() {
    this.getExams(this.filters);
  },
  mounted() {
    if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
  },
  methods: {
    initializedMap(instance) {
      this.map.loading = false;
      this.map.instance = instance;
      // eslint-disable-next-line no-undef
      ymaps.ready(() => (this.map.ymaps = ymaps));
    },
    bindListener(balloon) {
      balloon.forEach((el) => {
        const button = document.querySelector(
          `button[data-device="${el.device}"][data-coords="${el.coords}"]`
        );
        if (button) {
          button.addEventListener('click', this.selectExams);
        }
      });
    },
    unbindListener(balloon) {
      this.map.filteredExams = this.map.exams;

      balloon.forEach((el) => {
        const button = document.querySelector(
          `button[data-device="${el.device}"][data-coords="${el.coords}"]`
        );
        if (button) {
          button.removeEventListener('click', this.selectExams);
        }
      });
    },
    selectExams({target}) {
      const device = target.dataset.device,
        coords = target.dataset.coords.split(',');
      this.map.filteredExams = this.map.exams.filter(
        (el) =>
          el.device_serial === device &&
          el.lat === Number(coords[0]) &&
          el.long === Number(coords[1])
      );
    },
    createBalloon(coords) {
      let balloonContent = [];

      const filtered = this.map.exams.filter((el) => el.lat === coords[0] && el.long === coords[1]);

      Array.from(new Set(filtered.map((el) => el.device_serial))).forEach((device) => {
        const count = filtered.filter((item) => item.device_serial === device).length;

        if (count > 0) {
          balloonContent.push({
            coords,
            device,
            count,
          });
        }
      });

      return uniqueArray(balloonContent);
    },
    createPlacemarks(data) {
      this.map.exams = data.filter((el) => el.lat && el.long);
      this.map.filteredExams = this.map.exams;
      let placemarks = [];

      this.map.exams.forEach((exam) => {
        const coords = [exam.lat, exam.long];

        placemarks.push({
          coords,
          marker: Object.assign({}, this.map.marker, {
            content: isItemInArray(
              this.map.exams.map((el) => [el.lat, el.long]),
              coords
            ),
          }),
          balloon: this.createBalloon(coords),
        });
      });

      this.map.placemarks = uniqueArray(placemarks);
    },
    showToMap({lat, long}) {
      this.map.placemarks.forEach((placemark, index) => {
        if (placemark.coords[0] === lat && placemark.coords[1] === long) {
          this.map.instance
            .panTo([lat, long], {
              delay: 100,
              duration: this.map.duration,
            })
            .then(() => {
              this.map.instance
                .setCenter([lat, long], 16, {
                  checkZoomRange: true,
                  duration: this.map.duration,
                })
                .then(() => {
                  this.map.instance.geoObjects.get(0).get(index).balloon.open();
                });
            });
        }
      });
    },
    getExams(filters) {
      /** TODO V1 to V2 */
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(examsActionTypes.getExams, data).then((response) => {
        if (this.isTouchDevice) this.closeFilter('submit');

        this.isFiltered = Object.keys(filters).length > 0;
        this.reverseSorting();

        if (response && response.result) {
          this.pagination.offset = 10;
          this.createPlacemarks(response.result);
        }
      });
    },
    getCountExams(filters) {
      /** TODO V1 to V2 */
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(examsActionTypes.getCountExams, data);
    },
    sortingExams(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExams(this.filters);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    }
  },
};
</script>

<style lang="scss">
.profile-map {
  &__container {
    position: relative;
    width: 100%;
    height: $spacer * 43;
    border-radius: $border-radius-sm;
    box-shadow: get($shadows, default);
    overflow: hidden;
    background-color: get($colors, gray-hover);
  }
  @include mediaToDown($md) {
    .back-button {
      position: absolute;
      padding-top: $spacer * 1.6;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
</style>
