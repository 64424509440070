<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Замена подписи для пользователя<br>{{ patient.full_name  }}
          p.text-size--small.text-color--blue.mt-3 Фотография должна быть размером не менее 300*300 px, формата JPEG или PNG

        .row.justify-content-center
          .col-14.col-xl-10
            form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
              app-validation-errors(
                v-if="localValidationErrors"
                :validation-errors="localValidationErrors"
                :classes="'mb-3'"
              )
              .row.align-items-center.flex-wrap.g-5
                .col-14
                  .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                    input(
                      class="d-none"
                      type="file"
                      name="document"
                      @change="onFileSelected"
                      @click="v$.$touch()"
                      ref="fileInput"
                      accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                    )
                    div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                      span.flex-shrink-0.me-3
                        so-icon(icon="image")

                      ul.flex-grow-1.overflow-hidden
                        li.form__file-name.text-color--black {{ form.file.name }}
                        li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                      button(
                        type="button"
                        @click="clearFileInput"
                        class=['form__file-delete', 'flex-shrink-0 ms-3']
                      )
                        so-icon(icon="trash")

                    .text-color--blue.text-size--small(v-else) Загрузите новую<br>подпись пользователя

                    button(
                      class=['form__file-button', 'd-flex justify-content-end align-items-center']
                      @click="$refs.fileInput.click()"
                    )
                      so-icon(icon="clip")

                  ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                    li {{ v.errors.required }}

                .col-14
                  button(
                    type="submit"
                    @click="onSubmit"
                    :disabled="v$.$invalid"
                    class=['button', 'button--accent', 'form__button']
                  )
                    span.button__content Готово
</template>

<script>
import {mapState} from 'vuex';
import patientApi from '@/api/patients';
// import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {requiredIf} from '@vuelidate/validators';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

export default {
  name: 'AppPatientChangeSignatureModal',
  components: {
    AppModal,
    AppTitle,
    
    AppValidationErrors,
  },
  props: {
    patient: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        file: null,
      },
      localValidationErrors: null,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.patients.isSubmitting,
      validationErrors: (state) => state.patients.validationErrors,
    }),
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.resetForm();
      this.$emit('close');
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      if (this.$refs.fileInput) this.$refs.fileInput.value = '';
    },
    resetForm() {
      this.v$.$reset();
      this.localValidationErrors = null;
      this.clearFileInput();
    },
    async onSubmit() {
      this.patient.full_name = `${this.patient.last_name} ${this.patient.first_name} ${this.patient.middle_name}`;

      const formData = new FormData();
      formData.append('image', this.form.file, this.form.file.name);
      try {
        await patientApi.setPatientSignature(this.patient.code, formData);
        this.resetForm();
        this.$emit('success');
      } catch (err) {
        this.localValidationErrors = this.validationErrors;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &::v-deep .form__file-element {
    svg {
      width: $spacer * 2.4;
      height: $spacer * 2.4;
    }
  }
}
</style>
