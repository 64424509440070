
ul.tabs-nav.d-flex(:class="[{'is-styled': isStyled}, theme && `${theme}-theme`, classes]")
  li.tabs-nav__item.flex-shrink-0(v-for="tab in tabs" :key="tab.id")
    button(
      type="button"
      :class="['tabs-nav__button', 'button', { 'is-active': tab.id === selected }]"
      @click="setTab(tab.id)"
    )

      .button__content.d-flex.align-items-center.justify-content-center
        span.flex-shrink-0.d-flex.me-3(v-if="tab.icon")
          so-icon(:icon="tab.icon")
        span {{ tab.caption }}
        span.button__content-add(v-if="tab.add") &nbsp;{{ tab.add }}
