
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" classes="small")
    template(v-slot:body)
      app-title(:type="'large'" :classes="'text-center mb-4 mb-md-5'") Смена данных клиента
      app-validation-errors(
        v-if='error && showError'
        :validation-errors='error'
        :classes="'mb-4'"
      )
      .mb-5
        app-tab-nav(
          :tabs="tabs"
          :selected="activeTab"
          :theme="'separate'"
          @selected="setActiveTab"
        )
      app-tab(:is-selected="activeTab === 'names'")
        form(@submit.prevent="onSubmit('changeNames')" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-2.g-md-4
            template(v-if="isPerson")
              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.firstName.$errors.length }]"
                    type="text"
                    v-model="v$.firstName.$model"
                    placeholder="Имя"
                  )
                ul.form__error
                  li(v-for="error of v$.firstName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.lastName.$errors.length }]"
                    type="text"
                    v-model="v$.lastName.$model"
                    placeholder="Фамилия"
                  )
                ul.form__error
                  li(v-for="error of v$.lastName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.middleName.$errors.length }]"
                    type="text"
                    v-model="v$.middleName.$model"
                    placeholder="Отчество"
                  )
                ul.form__error
                  li(v-for="error of v$.middleName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

            template(v-if="isCompany")
              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.companyName.$errors.length }]"
                    type="text"
                    v-model="v$.companyName.$model"
                    placeholder="Полное наименование юридического лица"
                  )
                ul.form__error
                  li(v-for="error of v$.companyName.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

            .col.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Подтвердить смену {{ isPerson ? 'Ф.И.О.' : 'наименования' }}

            .col-auto
              p.text-center.text-color--blue.text-size--small Для смены {{ isPerson ? 'Ф.И.О.' : 'наименования' }} введите новые данные, клиент будет уведомлен об изменениях


      app-tab(:is-selected="activeTab === 'email'")
        form(@submit.prevent="onSubmit('changeEmail')" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-4
            .col
              label.form__label
                input(
                  class="form__field"
                  type="email"
                  v-model="user.email"
                  placeholder='Старый e-mail не обнаружен'
                  readonly
                )

            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.newEmail.$errors.length }]"
                  type="email"
                  v-model="v$.newEmail.$model"
                  placeholder="Новый E-mail"
                )
              ul.form__error
                li(v-for="error of v$.newEmail.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'email'") {{ v.errors.email }}
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.email }}

            .col.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Подтвердить смену e-mail

            .col-auto
              p.text-center.text-color--blue.text-size--small Для смены e-mail введите новые данные, клиент будет уведомлен об изменениях


